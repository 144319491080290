import { COMPANY_NAME, COMPANY_POSTAL_ADDRESS, CURRENCY } from '../../_helpers/exports';
import * as moment from 'moment';
import axios from 'axios';
import { baseURL_2 } from '../../_helpers';
import pdfMake from "pdfmake/build/pdfmake";

export function saveStatementsendEmail(params) {
    createPDFFromParams(params);
}

// // Private functions
// function onDataURLLoaded(params, fileReader) {
//     // Set imageLogo to data URI of file
//     params.imageLogo = fileReader.result;
//     createPDFFromParams(params);
// }

function createPDFFromParams(params) {
    let docDefinition = buildDocDefinition(params);
    // pdfMake.createPdf(docDefinition).open();

    const pdfGen = pdfMake.createPdf(docDefinition);

    pdfGen.getBase64((data) => {
        console.log("datafile", data)
        let formData = {
            "email": params.customer_email,
            "company_name": params.customer_name,
            "client_name": COMPANY_NAME,
            "period_date": params.rangeDate,
            "amount": params.amount1,
            "filesend": data
        }
        // alert(JSON.stringify(formData))
        let url = baseURL_2 + "sendCustomerStatement.php"

        // alert(url)
        axios.post(url, formData).then((response) => {
            // console.log("UPDATED", )

            // alert(JSON.stringify(response.data))
        }).catch(error => {
            console.log("ERROR", error)
        })
    })
}

function buildDocDefinition(params) {
    let notesAndTerms = buildNotesAndTerms(params);
    console.log('notesAndTerms', notesAndTerms);


    return {
        footer: function () {
            return [
                { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 595 - 10, y2: 10, lineWidth: 0.5 }] },
                {
                    columns: [
                        { text: `${params.footer}`, bold: true, color: 'black', fontSize: 9, alignment: 'center', marginTop: 5 }
                    ],

                },
            ];
        },

        background: [
            {
                image: localStorage.getItem("image_urls"),
                alignment: 'center',
                width: 800,
                height: 810,
                opacity: 0.1,
                borderRadius: 25,
            },

        ],
        // background: function(currentPage, pageSize) {
        //     return `page ${currentPage} with size ${pageSize.width} x ${pageSize.height}`
        //   },

        // userPassword: params.invoice_no,
        // ownerPassword: params.invoice_no,
        permissions: {
            printing: 'highResolution', //'lowResolution'
            modifying: false,
            copying: false,
            annotating: true,
            fillingForms: true,
            contentAccessibility: true,
            documentAssembly: true
        },
        content: [
            buildHeaderInformation(params),

            buildLineItemsTable(params),
            // buildTotal(params),
            ...buildNotesAndTerms(params),
        ],
    };
}

function buildHeaderInformation(params) {
    return [
        // canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1 }],
        {
            columns: [
                {
                    stack: [
                        {
                            image: localStorage.getItem("image_urls"),
                            width: 90,
                            height: 90,
                            margin: [0, 0, 0, 20],

                        },
                        {
                            text: `To ${params.customer_name}`,
                            color: "black",
                            marginTop: 20,
                            // background: '#eee',
                        },


                    ],
                },


                {
                    stack: [

                        {
                            text: COMPANY_NAME,
                            color: "black",
                            alignment: 'right',
                            margin: [0, 0, 0, 10],
                            // background: '#eee',
                        },
                        {
                            text: COMPANY_POSTAL_ADDRESS,
                            color: "black",
                            alignment: 'right',
                            margin: [0, 0, 0, 0],
                            // background: '#eee',
                        },
                        // { canvas: [{ type: 'line', lineWidth: 1 }] }
                    ],


                    alignment: 'right',
                },

            ],
        },

        {
            text: "Customer Statement",
            fontSize: 15,
            alignment: 'right',
            bold: true,
            margin: [0, 5, 10, 5],
        },
        {
            text: params.rangeDate,
            fontSize: 12,
            alignment: 'right',
            margin: [0, 0, 0, 10],
        },

        buildTotal(params),




    ];
}




function buildLineItemsTable(params) {

    // alert(JSON.stringify(params))

    let lineItemRows = params.lineItems.map(buildLineItem(params));
    return {
        table: {
            widths: ['16%', '20%', '*', '16%', '16%', '16%'],

            headerRows: 1,
            body: [
                [
                    {
                        text: 'Date',
                        color: '#FFFFFF',
                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },

                    {
                        text: 'Transactions',
                        color: '#FFFFFF',
                        alignment: 'right',
                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },
                    {
                        text: 'Details',
                        color: '#FFFFFF',
                        alignment: 'right',
                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },
                    {
                        text: 'Amount',
                        color: '#FFFFFF',
                        alignment: 'right',
                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },
                    {
                        text: 'Payment',
                        color: '#FFFFFF',
                        alignment: 'right',
                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },
                    {
                        text: 'Balance',
                        color: '#FFFFFF',
                        alignment: 'right',
                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },
                ],
                ...lineItemRows,
            ],
        },
        layout: 'lightHorizontalLines',
    };
}



function totalformatCurrency(amount) {
    return new Intl.NumberFormat("en-US", {
        style: 'currency',
        currency: "ABS",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount).replaceAll('ABS', CURRENCY);
};


function buildTotal(params) {
    return {

        table: {
            widths: ['*', '40%'],
            body: [

                [{
                    text: 'Account Details',
                    margin: [5, 5, 5, 5],
                    color: '#0000',
                    fillColor: '#CCCCCC',
                },
                {
                    text: ``,
                    margin: [5, 5, 5, 5],
                    color: '#00000',
                    fillColor: '#CCCCCC',
                    alignment: 'right',
                },
                ],

                [{
                    text: 'Opening Balance',
                    marginTop: 0,
                },
                {
                    text: totalformatCurrency(params.opening_balance),
                    marginTop: 0,
                    alignment: 'right',
                },
                ],
                [
                    {
                        text: 'Invoice Amount',
                        marginTop: 5,
                    },
                    {
                        text: `${totalformatCurrency(params.total_amount)}`,
                        marginTop: 5,
                        alignment: 'right',
                    },
                ],
                [{
                    text: 'Payment Received',
                    marginTop: 5,
                },
                {
                    text: totalformatCurrency(params.total_payment_made),
                    marginTop: 5,
                    marginBottom: 0,
                    alignment: 'right',
                },
                ],
                [{
                    text: 'Balance Due',
                    margin: [5, 5, 5, 5],
                    color: '#0000',
                    fillColor: '#CCCCCC',
                },
                {
                    text: `${totalformatCurrency(params.total_balance)}`,
                    margin: [5, 5, 5, 5],

                    color: '#00000',
                    fillColor: '#CCCCCC',
                    alignment: 'right',
                },
                ],
            ],
        },
        layout: 'headerLineOnly',
        style: 'noBorders',
        // layout: {
        //     fillColor: function (rowIndex, node, columnIndex) {
        //         return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
        //     }
        // },

        margin: [200, 20, 0, 30],
    };
}

// Returns an array
function buildNotesAndTerms(params) {
    let result = [];
    console.log('params', params);

    // alert(JSON.stringify(params))

    result = result.concat([
        {
            text: "Balance Due \t\t\t\t\t\t\t\t\t\t\t" +
                totalformatCurrency(params.total_balance), margin: [0, 40, 0, 0], bold: true, alignment: 'right'
        },
        // { text: params.statement_invoice, background: '#eee', margin: [0, 0, 0, 15] },
        // { text: params.notesAndTerms, background: '#eee', margin: [0, 0, 0, 30] },
    ]);


    return result;
}

// this.state.editedInvoice = {
//     invoice_no: this.state.invoice_no,
//     invoice_date: this.state.invoice_date,
//     terms: this.state.terms,
//     due_date: this.state.due_date,
//     notes: this.state.invoice_message,
//     stat: this.state.statement_message,
//     customer_id: this.state.customer_id,
//     lineItems: this.state.lineItems
//   }


function buildLineItem(params) {


    return function buildLineItemCurried(lineItem) {
        return [

            {
                text: lineItem.dates,
                marginTop: 10, marginLeft: 5
            },
            {
                text: `${lineItem.transaction_type}`, marginTop: 10, marginRight: 5, alignment: 'right'

            },
            {
                text: `${lineItem.details}`, marginTop: 10, alignment: 'right', marginRight: 5,

            },
            {
                text: String(lineItem.mamount), marginTop: 10, alignment: 'right', marginRight: 5,
            },
            {
                text: String(lineItem.mpayments), marginTop: 10, alignment: 'right', marginRight: 5,
            },
            {
                text: String(lineItem.mbalance), marginTop: 10, alignment: 'right', marginRight: 5,
            }
        ];
    };
}


