import React, { Component } from 'react';
import styles from './Invoice.module.scss';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { BRANCH_ID, CONFIG, CURRENCY, LOADER, PROFILE_URL, TOKEN, formatCurrency, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { baseURL, baseURL_2 } from '../../_helpers';
import Alert from '../../shared/components/Alert';
import { Link, Navigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import * as moment from 'moment';
import ReactDatatable from '@mkikets/react-datatable';
import { savePaymentMadeSend } from './sendPaymentMadeReceipt';
import { groupedOptions_two } from './paidthrough_recevied';
import { func } from 'prop-types';

// window.$ = window.jQuery = require('jquery');
// require('../../_assets/jss/sms_counter.js');

class Bill_Payment extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this._onButtonClick = this._onButtonClick.bind(this);
        this.columns = [
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "bill_no",
                TrOnlyClassName: 'tsc',
                text: "Bill#",
                className: "tsc",
                align: "left"
            },
            {
                key: "amount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "due_amount",
                TrOnlyClassName: 'tsc',
                text: "Due Amount",
                className: "tsc",
                align: "left"
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: false,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: false,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Bill no...",
                no_data_text: "There are no unpaid Bills for this vendor, that can be applied for this payment",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
    }

    locale = 'en-US';
    currency = CURRENCY;

    state = {
        taxRate: 0.00,
        invoice_no: 'IN-29333',
        payment_date: new Date(),
        due_date: new Date(),
        editedInvoice: {},
        amount_received: 0.00,
        amount_received_paid: 0.00,
        expected_amount: 0.00,
        loaded: true,
        reference: "abc",
        isPageLoad: true,
        isSubmitted: false,
        isLoadCustomer: false,
        returnHome: false,
        lineItems: [
            {
                id: 'initial', // react-beautiful-dnd unique key
                name: '',
                description: '',
                quantity: 0,
                discount_percentage: 0,
                tax_percentage: 0,
                price: 0.00,
            },
        ],


    };


    getDataNotes = () => {  // function to get receipt notes set on the settings page
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `receipt_notes?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((itemResponse) => {
            this.setState({
                notes: itemResponse.data.data,
                isLoading: false,
            }, function () {
                var data = [];
                if (this.state.notes.length > 0) {
                    this.setState({ isUpdateStatus: true, notes: this.state.notes[0].receipt_notes });
                } else {
                    this.setState({
                        notes: "",
                        isUpdateStatus: false
                    })
                }
            })
        })).catch(error => {
            console.log('bayoo', error.response)
        })
    }
    async componentDidMount() {
        // $('#message').countSms('#sms-counter');

        await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {

                this.setState({
                    footer: { footer: response.data.data[0].footer_notes }
                })
            } else {
                this.setState({
                    footer: { footer: "" }
                })
            }
        }).catch(error => {
            this.setState({
                footer: { footer: "" }
            })
        })

        this.getDataNotes();
        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = "${PROFILE_URL}?canvas=true";
        }

        const [customerResponse, paymentResponse, DepositTOResponse, bakResponse] = await Promise.all([
            axios.get(baseURL + `vendor?branch_id=${BRANCH_ID}&&page_size=1000&page_number=1&client_id=` + USER_ID, {
                headers: { Authorization: TOKEN },
            }),
            axios.get(baseURL + `payment_method`, {
                headers: { Authorization: TOKEN },
            }),
            axios.get(baseURL + `deposit_to`, {
                headers: { Authorization: TOKEN },
            })
        ]);
        this.setState({
            returnHome: false,
            customerResponse: customerResponse.data.data,
            paymentResponse: paymentResponse.data.data,
            DepositTOResponse: DepositTOResponse.data.data,
            isPageLoad: false

        }, function () {
            var data = [];
            if (this.state.DepositTOResponse.length > 0) {
                for (let i = 0; i < this.state.DepositTOResponse.length; i++) {
                    let label = { label: this.state.DepositTOResponse[i].name };
                    let value = { value: this.state.DepositTOResponse[i].name };
                    data.push(Object.assign(label, value));
                    this.setState({
                        data: data
                    })
                }
            } else {
                this.setState({
                    data: data
                })
            }
            localStorage.setItem("paidthrough", JSON.stringify(data))
        })
    }


    handleLineItemChange = elementIndex => event => {
        let lineItems = this.state.lineItems.map((item, i) => {
            if (elementIndex !== i) return item;
            return { ...item, [event.target.name]: event.target.value };
        });
        this.setState({ lineItems });
    };

    Customer() {

        return (
            this.state.customerResponse &&
            (this.state.customerResponse.length > 0 || this.state.customerResponse.length === 0) &&
            this.state.customerResponse.map((countyItem, i) => ({
                label: countyItem.vendor_display_name + " (" + countyItem.email + ")",
                customer_email: countyItem.email,
                opening_balance: countyItem.opening_balance,
                customer_nameinvoice: countyItem.vendor_display_name,
                value: countyItem.id,
            }))
        );
    }

    PaymentMethod() {
        // alert(JSON.stringify(this.state.paymentResponse))

        return (
            this.state.paymentResponse &&
            (this.state.paymentResponse.length > 0 || this.state.paymentResponse.length === 0) &&
            this.state.paymentResponse.map((countyItem, i) => ({
                label: countyItem.name,
                value: countyItem.name

            }))
        );
    }


    Depositto() {
        // console.log("Deposit", )
        return (
            this.state.DepositTOResponse &&
            (this.state.DepositTOResponse.length > 0 || this.state.DepositTOResponse.length === 0) &&
            this.state.DepositTOResponse.map((countyItem, i) => ({
                label: countyItem.name,
                value: countyItem.name
            }))
        );
    }

    handleFocusSelect = event => {
        event.target.select();
    };


    calcTaxAmount = c => {
        return c * (localStorage.getItem('valuestax') / 100);
    };

    calcLineItemsTotal = () => {
        return this.state.lineItems.reduce(
            (prev, cur) => prev + cur.quantity * cur.price,
            0
        );
    };

    calcLineItemDiscounts = () => {

        //  alert(localStorage.getItem("DATAARRAY"))

        let open_balance = this.state.out_of_balance



        return JSON.parse(localStorage.getItem("DATAARRAY")).reduce(
            (prev, cur) => prev + cur.due_amount_updated,
            0) + open_balance



            ;

    };

    calcTaxTotal = () => {
        return this.state.lineItems.reduce(
            (prev, cur) => prev + ((cur.tax_percentage / 100) * (cur.quantity * cur.price)),
            0
        );
    };

    calcGrandTotal = () => {

        let p = this.state.amount_paid_excess;

        let pd = this.state.expected_amount;

        let dfg;

        if (p === 0 || pd < 0) {
            if (p === 0) {
                dfg = Math.abs(parseFloat(this.state.amount_paid_excess))
            } else {
                dfg = Math.abs(parseFloat(this.state.expected_amount))
            }
        } else {
            dfg = Math.abs((parseFloat(this.state.amount_paid_excess) + parseFloat(this.state.amount_received)) - parseFloat(this.state.amount_received))
        }
        // else if (p > 0) {

        //     p = 0 - (this.state.amount_received - this.state.expected_amount);
        // }

        return ((Math.round((this.state.amount_received -
            this.calcLineItemDiscounts()), 2)) + parseFloat(dfg)
        )
    };


    calcGrandTotalExcess = () => {
        return (
            Math.round((this.state.amount_received -
                this.calcLineItemDiscounts()), 2))
    };

    onSelectChangesShort = value => {

        if (value != null) {

            this.getData(value.value.toString())
            this.setState(
                {
                    ...this.state.editedInvoice,
                    customer_id: value.value.toString(),
                    customer_name: value.label.toString(),
                    customer_nameinvoice2: value.customer_nameinvoice.toString(),

                    out_of_balance: value.opening_balance,
                    isLoadCustomer: true,
                    customer_email: value.customer_email != null ? value.customer_email.toString() : '',
                });


        }
    };

    handleChange = e => {
        this.setState({
            ...this.state.editedInvoice,
            [e.target.name]: e.target.value,
        });

        console.log('updates', this.state.editedInvoice);
    };

    getData = (queryString) => {
        localStorage.setItem("DATAARRAY", JSON.stringify([]))
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `billunpaid?filter_value=${queryString}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID
        let url_amount_inaccess = baseURL + `excess_amount_bill?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID

        //    alert(url_amount_inaccess)
        axios.all([
            axios.get(url, axiosConfig),
            axios.get(url_amount_inaccess, axiosConfig)
        ]).then(axios.spread((invoiceResponse, amountResponse) => {
            this.setState({
                invoice: invoiceResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    this.setState({
                        total: invoiceResponse.data.total,
                        amount_paid_excess: invoiceResponse.data.last_payment
                    })


                    var num = 0;

                    if (amountResponse.data.data.length > 0) {
                        num = parseFloat(amountResponse.data.data[0].amount_inexcess);
                    }


                    // var num = parseFloat(amountResponse.data.data[0].amount_inexcess);  // undefined


                    if (num > 0) {

                        this.setState({
                            amount_paid_excess: (amountResponse.data.data[0].amount_inexcess),
                        })

                    } else {
                        this.setState({
                            amount_paid_excess: 0
                        })

                    }


                    var names = this.state.invoice.map(function (item) {
                        return item['bill_no'];
                    });


                    var names_order = this.state.invoice.map(function (item) {
                        return item['order_no'];
                    });

                    var array_order = names_order
                    var array1_order = array_order.toString();
                    var array3_order = array1_order.replace(/,/g, ", ");

                    var array = names
                    var array1 = array.toString();
                    var array3 = array1.replace(/,/g, ", ");
                    if (this.state.invoice.length > 0) {
                        console.log("invoicetype", this.state.invoice)
                        for (let i = 0; i < this.state.invoice.length; i++) {
                            localStorage.setItem("DATAARRAY", JSON.stringify(data))
                            let due_date = moment(this.state.invoice[i].due_date);
                            let dueDateFormated = due_date.format('DD MMM, YYYY');
                            let date1 = { dates1: dueDateFormated };
                            let due_amount_updated;
                            var str = this.state.invoice[i].due_amount;
                            // s = s.replace(/^+/, "");
                            // var str = 
                            // eslint-disable-next-line
                            str = str.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                            var num = parseFloat(str);
                            due_amount_updated = { due_amount_updated: num };
                            let date = { dates: (moment.utc(this.state.invoice[i].invoice_date).format('DD MMM, YYYY')) };
                            data.push(Object.assign(date1, date, due_amount_updated, this.state.invoice[i]));
                            this.setState({
                                data: data.filter(person => person.due_amount_updated != 0),
                                bill_no: array3,
                                order_no: array3_order
                            })
                            localStorage.setItem("DATAARRAY", JSON.stringify(data))
                            this.setState({
                                expected_amount: (JSON.parse(localStorage.getItem("DATAARRAY")).reduce((prev, cur) => prev + cur.due_amount_updated, 0)) + parseFloat(this.state.invoice[0].opening_balance) - invoiceResponse.data.last_payment,
                                amount_received: ((JSON.parse(localStorage.getItem("DATAARRAY")).reduce((prev, cur) => prev + cur.due_amount_updated, 0))
                                    + parseFloat(this.state.invoice[0].opening_balance) - invoiceResponse.data.last_payment) + invoiceResponse.data.last_payment
                            }, function () {
                                // let mbalance = (this.state.amount_received - this.state.data[0].out_of_balance);
                                // alert(mbalance)
                            })
                            console.log("bugs", data);
                        }
                    } else {
                        this.setState({
                            expected_amount: this.state.out_of_balance + invoiceResponse.data.last_payment,
                            data: data,
                            amount_received: (this.state.out_of_balance - invoiceResponse.data.last_payment) + invoiceResponse.data.last_payment
                        })
                        // alert(JSON.stringify(this.state.invoice))
                        localStorage.setItem("DATAARRAY", JSON.stringify(data))

                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }


    handleChangeType = value => {

        if (value != null) {
            this.setState(
                {
                    terms: value.value.toString(),
                });
        }
        else {
            this.setState(
                {
                    terms: "",
                });
        }
    };

    handleChangePayment = value => {
        if (value != null) {
            this.setState(
                {
                    optionselected: value,
                    payment_mode: value.value.toString(),
                });
        }
        else {
            this.setState(
                {
                    payment_mode: "",
                });
        }
    };

    handleChangeDeposit = value => {

        if (value != null) {
            this.setState(
                {
                    optionselected2: value,
                    deposit_to: value.value.toString(),
                });
        }
        else {
            this.setState(
                {
                    deposit_to: "",
                });
        }
    };

    async onSubmit(e) {



        let footer = JSON.stringify(this.state.footer);

        // e.preventDefault();
        // eslint-disable-next-line
        let mbalance = ((parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess)) - parseFloat(this.state.out_of_balance));
        let invoice_no_new;
        if (mbalance > -1) {
            invoice_no_new = this.state.bill_no;
        } else {
            invoice_no_new = "Opening balance";
        }
        let formData = {
            bill_no: invoice_no_new,
            order_no: this.state.order_no,
            amount_received: mbalance,
            payment_date: this.state.payment_date,
            payment_mode: this.state.payment_mode,
            deposit_to: this.state.deposit_to,
            reference: this.state.reference,
            notes: this.state.notes,
            amount_inexcess: this.calcGrandTotal(),
            vendor_id: this.state.customer_id
        };

        let formData2 = {
            customer_id: this.state.customer_id
        };

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        this.setState({ isSubmitted: true });
        axios
            .post(baseURL + `payment_received_bill`, formData, axiosConfig)
            .then(response => {

                // alert(baseURL + `payment_status_bill?vendor_id=` + this.state.customer_id)
                if (response.data.status) {
                    axios.all([
                        axios.get(baseURL + `payment_status_bill?vendor_id=` + this.state.customer_id, axiosConfig),
                    ]).then(axios.spread((payResponse) => {
                        this.setState({
                            payResponseResult: payResponse.data.data,
                            amount_received_paid: (parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess))
                        })
                        if (payResponse.data.status) {
                            let openbalance;
                            let openbalance_received;
                            if (mbalance > 0) {
                                openbalance = 0
                                openbalance_received = ((parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess)) - parseFloat(this.state.out_of_balance))
                            } else {
                                openbalance = (parseFloat(this.state.out_of_balance) - (parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess)))
                            }
                            // alert(mbalance)
                            // alert ((parseFloat(this.state.amount_received - parseFloat(this.state.out_of_balance))))
                            let formDataOpenBalance = {
                                customer_id: this.state.customer_id,
                                payment_received_id: this.state.payResponseResult[0].id,
                                out_of_balance: openbalance,
                                client_id: USER_ID,
                                // openbalance_received
                                openbalance_received: ((parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess)) - parseFloat(this.state.out_of_balance)),
                                amount: this.state.out_of_balance,
                                // amount: this.state.out_of_balance,
                                amount_2: (parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess))
                            };
                            // alert(JSON.stringify(formDataOpenBalance))
                            axios.put(baseURL + `updatevendorbalance`, formDataOpenBalance, axiosConfig).then((statusresponse) => {
                                // alert(mbalance
                                if (mbalance > 0) {

                                    let datas = JSON.parse(localStorage.getItem("DATAARRAY"));
                                    // alert(JSON.parse(localStorage.getItem("DATAARRAY")))
                                    let data2 = [];
                                    for (let i = 0; i < datas.length; i++) {
                                        let PAYMENT = { payment_id: this.state.payResponseResult[0].id };
                                        let mclientid = { client_id: USER_ID };
                                        let mamount_paind = { amount_paid: (parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess)) };
                                        let mpayment_amount;
                                        if (this.state.amount_received_paid > datas[i].due_amount_updated) {
                                            // payment_amount_raw = amount_received - num;
                                            mpayment_amount = { payment_amount: datas[i].due_amount_updated }
                                            this.setState({
                                                amount_received_paid: (parseFloat(this.state.amount_received_paid) - (parseFloat(datas[i].due_amount_updated) + parseFloat(this.state.out_of_balance)))
                                            })
                                        } else if (this.state.amount_received_paid > 0) {
                                            mpayment_amount = { payment_amount: this.state.amount_received_paid }
                                            this.setState({
                                                amount_received_paid: 0
                                            })
                                        }
                                        else {
                                            mpayment_amount = { payment_amount: this.state.amount_received_paid }
                                        }
                                        data2.push(Object.assign(PAYMENT, mclientid, mamount_paind, mpayment_amount, datas[i]));
                                        this.setState({
                                            data2: data2,
                                        });
                                        localStorage.setItem("DATAARRAY", JSON.stringify(data2))
                                    }
                                    axios
                                        .post(baseURL_2 + 'updatebillpayment.php', data2)
                                        .then(statusresponse => {
                                            // console.log("phpinvoice", statusresponse)
                                            var names = JSON.parse(localStorage.getItem("DATAARRAY")).filter(person => person.payment_amount != 0).map(function (item) {
                                                return item['bill_no'];
                                            });
                                            var array = names
                                            var array1 = array.toString();
                                            var array3 = array1.replace(/,/g, ", ");
                                            // alert(array3)
                                            let formDataUpdated = {
                                                vendor_id: this.state.customer_id,
                                                bill_no: array3,
                                                payment_id: this.state.payResponseResult[0].id,
                                            };
                                            // alert(JSON.stringify(formDataUpdated))
                                            axios.put(baseURL + `payment_status_bill`, formDataUpdated, axiosConfig)
                                                .then((statusresponse) => {
                                                    // alert("alert")
                                                    if (statusresponse.data.status) {
                                                        let url_record = baseURL + `vendor_payment_record?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=`
                                                            + USER_ID + `&&filter_value=` + this.state.payResponseResult[0].id
                                                        axios.all([
                                                            axios.get(url_record, axiosConfig),
                                                        ]).then(axios.spread((invoiceResponse) => {
                                                            console.log("BUGggg", invoiceResponse)
                                                            this.setState({
                                                                lineItems: invoiceResponse.data.data,
                                                                amount_received_paid: (parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess)),
                                                                returnHome: false
                                                            })
                                                            let data2 = [];
                                                            for (let i = 0; i < invoiceResponse.data.data.length; i++) {
                                                                let mpayment_amount;
                                                                var str = invoiceResponse.data.data[i].amount;

                                                                let minvoice_amount_5 = { mminvoice_amount: parseFloat(num2) + parseFloat(num) }


                                                                var stfr = invoiceResponse.data.data[i].due_amount;

                                                                stfr = stfr.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                                                                var num3 = parseFloat(stfr);

                                                                let mclientid = { client_id: USER_ID };

                                                                var str3 = invoiceResponse.data.data[i].due_amount;

                                                                str3 = str3.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                                                                var num2 = parseFloat(str3);
                                                                // let minvoice_amount = { invoice_amount: num2 }
                                                                let minvoice_amount_2 = { due_amount_updated: num3 }
                                                                let mamountcompared;
                                                                // s = s.replace(/^+/, "");
                                                                // var str = 
                                                                // eslint-disable-next-line
                                                                str = str.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                                                                var num = parseFloat(str);
                                                                let minvoice_amount = { mminvoice_amount: parseFloat(num) }

                                                                if (this.state.amount_received_paid > num) {
                                                                    // payment_amount_raw = amount_received - num;
                                                                    mpayment_amount = { payment_amount: num }
                                                                    mamountcompared = num;
                                                                    this.setState({
                                                                        amount_received_paid: this.state.amount_received_paid - num
                                                                    })
                                                                } else if (this.state.amount_received_paid > 0) {
                                                                    mpayment_amount = { payment_amount: this.state.amount_received_paid }
                                                                    mamountcompared = this.state.amount_received_paid;
                                                                    this.setState({
                                                                        amount_received_paid: 0
                                                                    })
                                                                }
                                                                else {
                                                                    mpayment_amount = { payment_amount: this.state.amount_received_paid }
                                                                    mamountcompared = this.state.amount_received_paid;
                                                                }
                                                                // if (this.state.amount_received_paid > 0) {
                                                                data2.push(Object.assign(mpayment_amount,
                                                                    mclientid,
                                                                    minvoice_amount_2,
                                                                    minvoice_amount_5,
                                                                    minvoice_amount, invoiceResponse.data.data[i]));
                                                                this.setState({
                                                                    data2: data2,
                                                                });
                                                                console.log("bugs_report", data2);
                                                            }
                                                            // alert(invoiceResponse.data.length)
                                                            this.state.editedInvoice = {
                                                                reference: this.state.payResponseResult[0].reference,
                                                                payment_date: this.state.payment_date,
                                                                payment_mode: this.state.payment_mode,
                                                                paid_amount: formatCurrency(parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess)),
                                                                customer_email: this.state.customer_email,
                                                                lineItems: this.state.data2.filter(person => person.payment_amount != 0),
                                                                customer_name: this.state.customer_nameinvoice2,
                                                                paid_invoice_no: array3,
                                                                unpaid_amount: this.calcGrandTotal(),
                                                                currency: CURRENCY
                                                            };
                                                            // alert("done")
                                                            this.setState({
                                                                statusMessage: "Success",
                                                                showError: false, isShowError: true, isSubmitted: false
                                                            });
                                                            window.scrollTo(0, 0)
                                                            window.setTimeout(() => {
                                                                this.setState({ isShowError: false });
                                                                this._onButtonClick();
                                                            }, 2000);
                                                            // console.log('bayoo', this.state.editedInvoice)
                                                            if (e === 1) {
                                                                savePaymentMadeSend(Object.assign(footer, this.state.editedInvoice));
                                                            }
                                                            // savePaymentSend(this.state.editedInvoice);
                                                        })).catch(error => {
                                                            console.log('bayoo', error.response)

                                                        })

                                                    } else {
                                                        this.setState({
                                                            statusMessage: statusresponse.data.message,
                                                            showError: true, isShowError: false, isSubmitted: false
                                                        });
                                                    }
                                                }).catch((error) => {
                                                    console.log('helloss', JSON.stringify(error))

                                                    alert(JSON.stringify(error))
                                                    this.setState({ statusMessage: error.statusresponse.data.message, showError: true, isShowError: false, isSubmitted: false });
                                                })
                                        }).catch(error => {



                                            this.setState({
                                                // statusMessage: error.statusresponse === undefined
                                                //     ? (window.location.href = '/log_in')
                                                //     : 'Something went wrong',
                                                showError: true,
                                                isShowError: false,
                                                isSubmitted: false,
                                            });
                                            alert(JSON.stringify(error.response))

                                            window.scrollTo(0, 0);
                                            console.error('CHECK ERROR:', error.response);
                                        });

                                } else {
                                    let url_record = baseURL + `vendor_payment_record?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=`
                                        + USER_ID + `&&filter_value=` + this.state.payResponseResult[0].id
                                    axios.all([
                                        axios.get(url_record, axiosConfig),
                                    ]).then(axios.spread((invoiceResponse) => {
                                        console.log("BUGggg", invoiceResponse)
                                        this.setState({
                                            lineItems: invoiceResponse.data.data,
                                            amount_received_paid: (parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess)),
                                            returnHome: false
                                        })
                                        let data2 = [];
                                        for (let i = 0; i < invoiceResponse.data.data.length; i++) {

                                            let mpayment_amount;

                                            var str = invoiceResponse.data.data[i].amount;
                                            let mclientid = { client_id: USER_ID };
                                            var str3 = invoiceResponse.data.data[i].due_amount;
                                            str3 = str3.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                                            var num2 = parseFloat(str3);
                                            let minvoice_amount = { invoice_amount: num2 }
                                            let minvoice_amount_2 = { due_amount_updated: (parseFloat(num2) + (parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess))) }
                                            let mamountcompared;

                                            // s = s.replace(/^+/, "");
                                            // var str = 
                                            // eslint-disable-next-line
                                            str = str.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                                            var num = parseFloat(str);

                                            if (this.state.amount_received_paid > num) {
                                                // payment_amount_raw = amount_received - num;
                                                mpayment_amount = { payment_amount: num }
                                                mamountcompared = num;
                                                this.setState({
                                                    amount_received_paid: this.state.amount_received_paid - num
                                                })

                                            } else if (this.state.amount_received_paid > 0) {

                                                mpayment_amount = { payment_amount: this.state.amount_received_paid }

                                                mamountcompared = this.state.amount_received_paid;

                                                this.setState({
                                                    amount_received_paid: 0
                                                })
                                            }
                                            else {
                                                mpayment_amount = { payment_amount: this.state.amount_received_paid }

                                                mamountcompared = this.state.amount_received_paid;

                                            }

                                            // if (this.state.amount_received_paid > 0) {
                                            data2.push(Object.assign(mpayment_amount, mclientid, minvoice_amount_2, minvoice_amount, invoiceResponse.data.data[i]));
                                            this.setState({
                                                data2: data2,
                                            });
                                            // }

                                            // console.log("bugs", data2);
                                            // localStorage.setItem("DATAARvvRAY", JSON.stringify(data2))

                                        }



                                        // alert(invoiceResponse.data.length)
                                        this.state.editedInvoice = {

                                            reference: this.state.payResponseResult[0].reference,
                                            payment_date: this.state.payment_date,
                                            payment_mode: this.state.payment_mode,
                                            paid_amount: formatCurrency(parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess)),
                                            customer_email: this.state.customer_email,
                                            lineItems: this.state.data2.filter(person => person.payment_amount != 0),
                                            customer_name: this.state.customer_nameinvoice2,
                                            notes: this.state.notes,
                                            paid_invoice_no: "open balance",
                                            unpaid_amount: this.calcGrandTotal(),
                                            currency: CURRENCY
                                        };

                                        this.setState({
                                            statusMessage: "Success",
                                            showError: false, isShowError: true, isSubmitted: false
                                        });
                                        window.scrollTo(0, 0)
                                        window.setTimeout(() => {
                                            this.setState({ isShowError: false });
                                            this._onButtonClick();
                                        }, 2000);

                                        if (e === 1) {
                                            // alert(e)
                                            savePaymentMadeSend(Object.assign(footer, this.state.editedInvoice));
                                        }



                                    })).catch(error => {
                                        console.log('bayoo', error.response)

                                    })
                                }

                            }).catch((error) => {

                                // alert(JSON.stringify(error.response))

                            })

                        }
                        else {
                            this.setState({ statusMessage: payResponse.data.message, showError: true, isShowError: false, isSubmitted: false });
                        }
                    }
                    )).catch(error => {
                        this.setState({ statusMessage: error.payResponse.data.message, showError: true, isShowError: false, isSubmitted: false });


                        console.log('bayoo', error)

                    })

                }

                else {
                    this.setState({
                        statusMessage: response.data.message,
                        showError: true,
                        isShowError: false,
                        isSubmitted: false,
                    });
                    window.scrollTo(0, 0);
                }
            })
            .catch(error => {
                console.log('bayoo', error.response);
                this.setState({
                    statusMessage: error.response.data.message,
                    showError: true,
                    isShowError: false,
                    isSubmitted: false,
                    isLoading: false,
                });
                window.scrollTo(0, 0);
            });

        // }

        // }
    }


    _onButtonClick() {
        this.setState({
            returnHome: true
        });
    }
    loadOptions = inputValue => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                Authorization: TOKEN,
            },
        };
        // this.setState({
        //     isLoading: true,
        // });
        let url =
            baseURL +
            `customer?branch_id=${BRANCH_ID}&&client_id=` + USER_ID +
            `&&filter_value=${inputValue}`;
        axios.get(url, axiosConfig).then(itemResponse => {
            this.setState(
                {
                    item: itemResponse.data.data,
                },
                function () {
                    this.setState({
                        isLoading: false,
                        bayo: this.state.item &&
                            (this.state.item.length > 0 || this.state.item.length === 0) &&
                            this.state.item.map((countyItem, i) => ({
                                label: countyItem.vendor_display_name,
                                customer_email: countyItem.email,
                                out_of_balance: countyItem.opening_balance,
                                value: countyItem.id,
                            })),
                    });
                }
            );
        });
    };

    handleCreate = value => {
        const newValue = { value: value, label: value };
        this.setState({ optionselected: newValue, payment_mode: value })

        let formData = {
            "name": value,
            "client_id": USER_ID
        }
        let url = baseURL + `payment_method`
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            }
        }).then((response) => {
        }).catch(error => {
            console.log("ERROR")
        })

    };


    handleCreateDeposit = value => {
        const newValue = { value: value, label: value };
        this.setState({ optionselected2: newValue, deposit_to: value })

        let formData = {
            "name": value,
            "client_id": USER_ID
        }
        let url = baseURL + `deposit_to`
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            }
        }).then((response) => {
        }).catch(error => {
            console.log("ERROR")
        })

    };
    render = () => {
        console.log('ITEMS', this.state.lineItems);
        const ref = React.createRef();
        const { returnHome } = this.state;


        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            justifyContent: 'space-between',
        };
        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };

        const formatGroupLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );


        let payments;

        if (this.calcGrandTotal() > 0) {
            payments = "Over Payment"

        } else if (this.calcGrandTotal() < 0) {
            payments = "Outstanding Amount"
        } else {
            payments = "Bill paid full"
        }
        return (
            <div style={{ marginTop: "-20px" }} >
                {returnHome === true ? <Navigate to='/payment-bills' /> : null}

                <div ref={ref}>

                    <Card>
                        {this.state.isSubmitted ?
                            <div className="loader">
                                {LOADER}
                            </div> :

                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>Record Payment</b></h4>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/payment-bills">
                                            <Button className="pull-right btn-sm" onClick={this._onButtonClick}
                                                color="primary" outline><i className="fa  fa-arrow-left"></i> Payment Received
                                            </Button>
                                        </Link>

                                    </div>
                                </div>

                                {this.state.showError
                                    ? <div>

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p>
                                                <span className="bold-text">
                                                    {this.state.statusMessage}
                                                </span>
                                            </p>
                                        </Alert>

                                    </div>
                                    : null}<br />

                                {this.state.isShowError
                                    ? <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p>
                                                <span className="bold-text">
                                                    {this.state.statusMessage}
                                                </span>
                                            </p>
                                        </Alert>

                                    </div>
                                    : null}<br />


                                {this.state.isPageLoad ?
                                    <div className="loader">
                                        {LOADER}
                                    </div>
                                    :
                                    null}

                                <form className='row'>
                                    <br /><br />
                                    {this.state.isPageLoad ?
                                        <div></div>
                                        :
                                        <>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <label className="form-label">Supplier</label>
                                                        </div>
                                                        <div className="col-md-10">

                                                            <Select
                                                                isClearable
                                                                options={this.Customer()}
                                                                onInputChange={this.loadOptions}
                                                                placeholder="Search Supplier"
                                                                autosize={true}
                                                                onChange={this.onSelectChangesShort}
                                                                isLoading={this.state.isLoading}
                                                                // noOptionsMessage={() => 'nothing found'}
                                                                // loadingMessage={() => 'searching...'}   //minor type-O here
                                                                menuPortalTarget={document.body}
                                                                name="namffe"
                                                            /><br />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {this.state.isLoadCustomer ?
                                                <>

                                                    <div className="col-md-6">
                                                        <br />

                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <label className="form-label">Amount Received ({CURRENCY})</label>


                                                                </div>
                                                                <div className="col-md-10">
                                                                    <label className="form-label"><b>Previous Amount Balance </b> {formatCurrency(this.state.amount_paid_excess)}</label>

                                                                    <br />
                                                                    <label className="form-label"><b>Total Unpaid Amount </b> {formatCurrency(
                                                                        this.state.expected_amount)}</label>
                                                                    <br />
                                                                    {/* 
                                                                    <label className="form-label"><b>Current Amount Received </b> {formatCurrency(
                                                                        this.state.amount_received)}</label> */}

                                                                    <input
                                                                        id="input"
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="amount_received"
                                                                        required
                                                                        placeholder="Amount Received"
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Form.Group className="Focus-line">
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <Form.Label>Payment Date</Form.Label>
                                                                </div>
                                                                <div className="col-md-10 customDatePickerWidth">
                                                                    <DatePicker
                                                                        selected={this.state.payment_date}
                                                                        label="DatePicker Label"
                                                                        dateFormat="dd MMM yyyy"
                                                                        required
                                                                        value={this.state.payment_date}
                                                                        className="form-control"
                                                                        id="input"
                                                                        onChange={value =>
                                                                            this.setState({ payment_date: value })}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Form.Group className="Focus-line">
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <Form.Label>Payment Mode</Form.Label>
                                                                </div>
                                                                <div className="col-md-10">

                                                                    <CreatableSelect
                                                                        isClearable
                                                                        options={this.PaymentMethod()}
                                                                        // onInputChange={this.loadOptions}
                                                                        placeholder="Select or create a new one"
                                                                        autosize={true}
                                                                        onChange={this.handleChangePayment}
                                                                        onCreateOption={this.handleCreate}
                                                                        value={this.state.optionselected}
                                                                        isLoading={this.state.isLoading}
                                                                        // noOptionsMessage={() => 'nothing found'}
                                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                                        menuPortalTarget={document.body}
                                                                        name="payment_mode"
                                                                    /><br />

                                                                </div>
                                                            </div>

                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <br />
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-md-2">

                                                                    <label className="form-label">
                                                                        Notes
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <textarea
                                                                        name="notes"

                                                                        style={{
                                                                            paddingTop: '20px',
                                                                            borderRadius: '5px',
                                                                            borderColor: 'hsl(0,0%,80%)',
                                                                        }}
                                                                        value={this.state.notes}
                                                                        onChange={this.handleChange}
                                                                        className="col-md-12"
                                                                        placeholder="Hint: Internal use. Not visible to customer"
                                                                        id="message"
                                                                        cols="col-md-1"
                                                                        rows="1"
                                                                    />
                                                                </div>

                                                                <div className="col-md-2">
                                                                    <ul id="sms-counter">
                                                                        <li>Length: <span class="length" /></li>
                                                                        {/* <li>SMS: <span class="messages"></span></li> */}
                                                                        <li>
                                                                            Message:
                                                                            {' '}
                                                                            <span class="remaining" />
                                                                            /
                                                                            <span class="per_message" />
                                                                        </li>
                                                                        {/* <li>Remaining: </li> */}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                    </div>


                                                    <div className="col-md-6">
                                                        <br />
                                                        <Form.Group className="Focus-line">
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <Form.Label>Deposit to</Form.Label>
                                                                </div>
                                                                <div className="col-md-10">

                                                                    <Select
                                                                        isClearable
                                                                        // onInputChange={this.loadOptions}
                                                                        placeholder="Select one"
                                                                        autosize={true}
                                                                        options={groupedOptions_two}
                                                                        formatGroupLabel={formatGroupLabel}

                                                                        value={this.state.optionselected2}
                                                                        onChange={this.handleChangeDeposit}
                                                                        isLoading={this.state.isLoading}
                                                                        // noOptionsMessage={() => 'nothing found'}
                                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                                        menuPortalTarget={document.body}
                                                                        name="deposit_to"
                                                                    /><br />

                                                                </div>
                                                            </div>

                                                        </Form.Group>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                            <br />
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-2">
                                                                        <label className="form-label">Reference#</label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <input
                                                                            id="input"
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="reference"
                                                                            required
                                                                            placeholder="Reference#"
                                                                            value={this.state.reference}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    <div className="col-md-10 offset-1">
                                                        <br />
                                                        <h4><b>Unpaid Invoices</b></h4><br />

                                                        <b><h4>Opening Balance: {this.state.out_of_balance}</h4></b>

                                                        <ReactDatatable
                                                            config={this.config}
                                                            records={this.state.data}
                                                            columns={this.columns}
                                                            dynamic={true}
                                                            id="tsc"
                                                            loading={this.state.isLoading}
                                                            total_record={this.state.total}
                                                            onChange={this.tableChangeHandler} />
                                                    </div>

                                                    <div className="col-md-10 offset-1">

                                                        <div className="col-md-12">
                                                            <br />


                                                            <div className="col-md-12">

                                                                <div className={styles.totalContainer}>
                                                                    <form></form>
                                                                    <form>
                                                                        <div className={styles.valueTable}>
                                                                            <div className={styles.row}>
                                                                                <div className={styles.label}>Amount Received</div>
                                                                                <div
                                                                                    className={"${styles.value} ${styles.currency}"}
                                                                                >
                                                                                    {formatCurrency(
                                                                                        parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess))}

                                                                                </div>
                                                                            </div>
                                                                            <div className={styles.row}>
                                                                                <div className={styles.label}>Invoice/s Amount</div>
                                                                                <div
                                                                                    className={"${styles.value} ${styles.currency}"}
                                                                                >
                                                                                    {formatCurrency(
                                                                                        this.calcLineItemDiscounts()
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className={styles.row}>
                                                                                <div className={styles.label}>
                                                                                    Previous Amount Balance
                                                                                </div>
                                                                                <div
                                                                                    className={"${styles.value} ${styles.currency}"}
                                                                                >
                                                                                    {formatCurrency(this.calcTaxTotal() + this.state.amount_paid_excess)}
                                                                                </div>
                                                                            </div>
                                                                            <div className={styles.row}>
                                                                                <div className={styles.label}>

                                                                                    {payments}</div>
                                                                                <div
                                                                                    className={"${styles.value} ${styles.currency}"}
                                                                                >
                                                                                    {formatCurrency(Math.abs(this.calcGrandTotal()))}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </form>
                                                                </div>

                                                                <br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null}
                                        </>
                                    }

                                    <br />
                                    <br />

                                    <div className="col-md-12"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Link to="/payment-bills">
                                                        <Button outline color="primary" className="btn-sm" onClick={this._onButtonClick}>
                                                            Cancel
                                                        </Button>
                                                    </Link>

                                                </div>
                                                <div className="col-md-4">
                                                    <Button type="submit" color="primary" onClick={() => { this.onSubmit(0) }} outline className="btn-paypal btn-sm pull-right" >
                                                        {this.state.isLoading ? "Please Wait..." : "Save"}

                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                </div>
                                                <div className="col-md-4">
                                                    <Button type="submit" color="primary" onClick={() => { this.onSubmit(1) }} outline className="btn-paypal btn-sm pull-right" >
                                                        {this.state.isLoading ? "Please Wait..." : "Save and Send"}
                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <br />

                            </CardBody>}
                    </Card>
                </div>
            </div >
        );
    };
}

export default Bill_Payment;
