import { COMPANY_NAME, COMPANY_POSTAL_ADDRESS, CURRENCY } from '../../_helpers/exports';
import { baseURL_2 } from '../../_helpers';
import axios from 'axios';
import * as moment from 'moment';
import pdfMake from "pdfmake/build/pdfmake";

export function savePaymentMadeSend(params) {
    createPDFFromParams(params);
}

// // Private functions
// function onDataURLLoaded(params, fileReader) {
//     // Set imageLogo to data URI of file
//     params.imageLogo = fileReader.result;
//     createPDFFromParams(params);
// }

function createPDFFromParams(params) {
    let docDefinition = buildDocDefinition(params);

    const pdfGen = pdfMake.createPdf(docDefinition);

    pdfGen.getBase64((data) => {
        console.log("datafile", data)
        let formData = {
            "email": params.customer_email,
            "customer_name": params.customer_name,
            "company_name": COMPANY_NAME,
            "company_address": COMPANY_POSTAL_ADDRESS,
            "invoice_no": params.paid_invoice_no,
            "payment_date": moment(params.invoice_date).format('DD, MMM YYYY'),
            "amount": params.paid_amount,
            "filesend": data
        }
        let url = baseURL_2 + "paymentmade.php"
        axios.post(url, formData).then((response) => {
        }).catch(error => {
            console.log("ERROR")
        })

    })
}

function buildDocDefinition(params) {
    console.log("params", params.lineItems)



    return {
        header: {
            columns: [
                headerNotes(params)
            ]
        },
        // background: function(currentPage, pageSize) {
        //     return `page ${currentPage} with size ${pageSize.width} x ${pageSize.height}`
        //   },

        // userPassword: params.invoice_no,
        // ownerPassword: params.invoice_no,
        // permissions: {
        //     printing: 'highResolution', //'lowResolution'
        //     modifying: false,
        //     copying: false,
        //     annotating: true,
        //     fillingForms: true,
        //     contentAccessibility: true,
        //     documentAssembly: true
        // },
        footer: function () {
            return [
                { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 595 - 10, y2: 10, lineWidth: 0.5 }] },
                {
                    columns: [
                        { text: `${params.footer}`, bold: true, color: 'black', fontSize: 9, alignment: 'center', marginTop: 5 }
                    ],

                },
            ];
        },

        background: [
            {
                image: localStorage.getItem("image_urls"),
                alignment: 'center',
                width: 800,
                height: 810,
                opacity: 0.1,
                borderRadius: 25,
            },

        ],
        content: [

            buildHeaderInformation(params),
            buildLineItemsTable(params),
        ],
    };
}


// Returns an array
function headerNotes(params) {

    return [
        { text: `\ttPAYMENT MADE \t\t`, alignment: 'center', bold: true, margin: [5, 15, 0, 20] }
    ]
}


function formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: "ABS",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount).replaceAll('ABS', CURRENCY);
};



function buildHeaderInformation(params) {
    let date1 = moment(params.invoice_date).format('DD, MMM YYYY');

    let date2 = moment(params.due_date).format('DD, MMM YYYY');
    const optionalDataKeys = [];
    const optionalDataValues = [];
    // let total = params.lineItems.reduce((sum, lineItem) => {
    //     return sum + lineItem.quantity * lineItem.price;
    // }, 0);
    Object.entries({
        "Date :": date1,
        'Terms :': params.terms,
        'Due Date :': date2,
    }).forEach(([key, value]) => {
        if (value) {
            optionalDataKeys.push(key);
            optionalDataValues.push(value);
        }
    });


    let payments

    var str = params.unpaid_amount;
    // eslint-disable-next-line
    str = str.toString().replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
    var num = parseFloat(str);


    if (num > 0) {
        payments = `Over payment \n\n ${formatCurrency(Math.abs(num))}`
    } else if (num < 0) {
        payments = `Outstanding Amount \n\n ${formatCurrency(Math.abs(num))}`
    } else {
        payments = "Bill paid full"

    }


    return {
        columns: [{
            stack: [
                {
                    image: localStorage.getItem("image_urls"),
                    width: 90,
                    height: 90,
                    margin: [0, 0, 0, 10],

                },

                {
                    text: COMPANY_NAME,
                    color: "black",
                    margin: [0, 0, 0, 50],
                    // background: '#eee',
                },

                {
                    text: `Payment Date:  ${moment(params.payment_date).format('DD, MMM YYYY')}`,
                    color: "black",

                    margin: [0, 0, 0, 20],
                    // background: '#eee',
                },
                {
                    text: `Reference#: ${params.reference}`,
                    color: "black",
                    margin: [0, 0, 0, 20],

                    // background: '#eee',
                },
                {
                    text: `Payment Mode: ${params.payment_mode}`,
                    color: "black",
                    margin: [0, 0, 0, 40],
                    // background: '#eee',
                },
                {
                    text: "Bill to",
                    color: "black",

                    // background: '#eee',
                },

                {
                    text: params.customer_name,
                    color: "black",
                    bold: true,
                    margin: [0, 0, 0, 20],
                    // background: '#eee',
                },
                {
                    text: `${payments}`,
                    color: "black",

                    // background: '#eee',
                },

            ],
        },

        {
            background: '#5bc0de',

            stack: [

                {
                    text: `\t Amount Received ${params.paid_amount}\t\t`, alignment: 'left', background: '#5cb85c', color: '#FFFFFF', margin: [5, 15, 0, 5],
                    bold: true,
                    fontSize: 16
                }
            ],
            alignment: 'right',
        },
        ],
        styles: {
            subheader: {
                fontSize: 9,
                color: '#FFFFFF',
                background: '#2361AE',
                margin: [0, 15, 0, 5]
            }
        },
        // optional space between columns
        columnGap: 10,
        margin: [0, 0, 0, 30],
    };
}




function buildLineItemsTable(params) {
    var n = 0
    let params_two = params.lineItems.map(obj => (Object.assign(obj, { index: ++n })))
    let lineItemRows = params_two.map(buildLineItem(params));

    return {
        table: {
            widths: ['2%', '*', '25%', '25%', '25%'],

            headerRows: 1,
            body: [
                [{
                    text: "#",
                    color: '#FFFFFF',
                    margin: [5, 5, 5, 5],
                    fillColor: '#00000'
                },
                { text: 'Bill#' },
                { text: 'Bill Date' },
                { text: 'Bill Amount (' + params.currency + ')' },
                { text: 'Payment Amount (' + params.currency + ')' },
                ],
                ...lineItemRows,
            ],
        },
        layout: 'lightHorizontalLines',
    };
}



function buildLineItem(params) {
    return function buildLineItemCurried(lineItem) {
        return [
            { text: lineItem.index, marginTop: 10 },
            { text: lineItem.invoice_no, marginTop: 10 },
            { text: `${moment(lineItem.invoice_date).format('DD, MMM YYYY')}`, marginTop: 10 },
            { text: `${lineItem.amount}`, marginTop: 10 },
            { text: `${lineItem.payment_amount}`, marginTop: 10 },


        ];
    };
}