import React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { ACCOUNTANT, ADMIN_ROLE, BANK, BUSINESS_TYPE, COMPANY_NAME, COMPANY_POSTAL_ADDRESS, CURRENCY, CURRENCY_AGAINST_KE, DOCUMENTS, INDUSTRY, INVENTORY, INVESTMENT, PROFILE_URL, PURCHASES, REPORTS, SALES, SUBSCRIPTION, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, Navigate } from 'react-router-dom';
import { Users } from './Users';
import Alert from '../../shared/components/Alert';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import * as moment from 'moment';
import { currency } from '../Account/Settings/datacurrency';
import { RadioGroup, ReversedRadioButton, RadioButton } from 'react-radio-buttons'
import { Clients } from './clients';

window.$ = window.jQuery = require('jquery')

class AddClientUser extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeinventory = this.onChangeinventory.bind(this);
        this.onChangebank = this.onChangebank.bind(this);
        this.onChangesales = this.onChangesales.bind(this);
        this.onChangepurchase = this.onChangepurchase.bind(this);
        this.onChangeinvestment = this.onChangeinvestment.bind(this);
        this.onChangeaccount = this.onChangeaccount.bind(this);
        this.onChangedocument = this.onChangedocument.bind(this);
        this.onChangereports = this.onChangereports.bind(this);
        this.onChangeRole = this.onChangeRole.bind(this);
        this.onChangeApproval = this.onChangeApproval.bind(this);

        this.state = {
            event_name: '',
            event_description: '',
            start_time: '',
            end_time: '',
            entity_type: '4',
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            applicable_to: '',
            short_code: '',
            selectedEntity: '',
            hideEntitiy: false,
            hideBranch: false,
            transit: '',
            plans: '',
            event_date: '',
            fee: '',
            showError: false,
            account_type: false,
            inventory_allow: "1",
            investment_allow: "1",
            admin_approval: "1",
            sales_allow: "1",
            purchase_allow: "1",
            document_allow: "1",
            account_allow: "1",
            reports_allow: "1",
            account_role: "1",
            admin_role: "1",
            bank_allow: "1"
        }
    }

    async componentDidMount() {


        // alert(BUSINESS_TYPE)

    }



    handleChangeCurrency = value => {
        if (value != null) {
            this.setState(
                {
                    agnaist_ksh: value.value.toString(),
                    currencylabel: value.currency.toString(),
                });
        }
    };


    onSubmit(e) {
        e.preventDefault();

        var months = moment(SUBSCRIPTION).format('YYYY-MM-DD HH:mm:ss');

        let formData =
        {
            "first_name": this.state.first_name,
            "last_name": this.state.last_name,
            "industry": INDUSTRY,
            "email": this.state.email,
            "username": this.state.username,
            "msisdn": this.state.msisdn,
            "first_time": "1",
            "our_client": "1",
            "admin_role": "1",
            "paid": "0",
            "subscription": months,
            "client_id": USER_ID,
            "acc_id": ROLE, USER_ID_ACC,
            "postal_address": COMPANY_POSTAL_ADDRESS,
            "company_name": COMPANY_NAME,
            "url": PROFILE_URL,
            "password": "123456",
            "approval": this.state.admin_approval,
            "inventory": this.state.inventory_allow,
            "investment": this.state.investment_allow,
            "sales": this.state.sales_allow,
            "purchase": this.state.purchase_allow,
            "documents": this.state.document_allow,
            "reports": this.state.reports_allow,
            "accountant": this.state.account_allow,
            "admin_role": this.state.admin_role,
            "type": BUSINESS_TYPE,
            "bank": this.state.bank_allow,
            "currency": 'KES',
            "currency_against_kenya": '1',
            "role_id": "2"
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.post(baseURL + `user`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        })
            .then((response) => {


                console.log('bayoo', response.data)
                if (response.data.status) {
                    console.log("bayo", response)
                    this.setState({ statusMessage: "Account has been created successfully! email has been send", showError: false, isShowError: true, isLoading: false });
                    window.setTimeout(() => {
                        this._onButtonClick();
                        this.setState({ isShowError: false })
                    }, 3000);
                    window.scrollTo(0, 0)
                    let formData2 =
                    {
                        "name": this.state.first_name + " " + this.state.last_name,
                        "email": this.state.email,
                        "token": TOKEN
                    }
                    axios.post(baseURL_2 + 'emailconfirmation.php', formData2)
                        .then((response) => {

                        }).catch(error => {
                            this.setState({ statusMessage: error.response === undefined ? window.location.href = "/log_in" : "Something went wrong", showError: true, isShowError: false, isLoading: false });
                            window.scrollTo(0, 0)
                            // console.error('Error during service worker registration:', error);
                        });
                } else {
                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                    window.setTimeout(() => {
                        // this._onButtonClick();
                        this.setState({ showError: false })
                    }, 6000);
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
            })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true

        });
    }
    changeStatus() {
        this.setState({
            isShowError: false
        });
    }

    industry() {
        return (this.state.industry && (this.state.industry.length > 0 || this.state.industry.length === 0) &&
            this.state.industry.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.name })))

    }
    onSelectChanges = value => {
        this.setState({ industrys: value.value.toString() });
    };


    onChangeinventory(value) {
        console.log(value);
        if (value == 1) {
            this.setState({
                inventory_allow: 1,
                inventory_denied: 0,
            })
        } else {
            this.setState({
                inventory_allow: 0,
                inventory_denied: 1,

            })
        }
        // alert(value)
    }


    onChangesales(value) {
        console.log(value);
        if (value == 1) {
            this.setState({
                sales_allow: 1,
                sales_denied: 0,
            })
        } else {
            this.setState({
                sales_allow: 0,
                sales_denied: 1,

            })
        }
        // alert(value)
    }


    onChangepurchase(value) {
        console.log(value);
        if (value == 1) {
            this.setState({
                purchase_allow: 1,
                purchase_denied: 0,
            })
        } else {
            this.setState({
                purchase_allow: 0,
                purchase_denied: 1,

            })
        }
        // alert(value)
    }

    onChangeinvestment(value) {
        console.log(value);
        if (value == 1) {
            this.setState({
                investment_allow: 1,
                investment_denied: 0,
            })
        } else {
            this.setState({
                investment_allow: 0,
                investment_denied: 1,

            })
        }
        // alert(value)
    }

    onChangeaccount(value) {
        console.log(value);
        if (value == 1) {
            this.setState({
                account_allow: 1,
                account_denied: 0,
            })
        } else {
            this.setState({
                account_allow: 0,
                account_denied: 1,

            })
        }
        // alert(value)
    }

    onChangereports(value) {
        console.log(value);
        if (value == 1) {
            this.setState({
                reports_allow: 1,
                reports_denied: 0,
            })
        } else {
            this.setState({
                reports_allow: 0,
                reports_denied: 1,

            })
        }
        // alert(value)
    }

    onChangedocument(value) {
        console.log(value);
        if (value == 1) {
            this.setState({
                document_allow: 1,
                document_denied: 0,
            })
        } else {
            this.setState({
                document_allow: 0,
                document_denied: 1,
            })
        }
        // alert(value)
    }



    onChangebank(value) {
        console.log(value);
        if (value == 1) {
            this.setState({
                bank_allow: 1,
                bank_denied: 0,
            })
        } else {
            this.setState({
                bank_allow: 0,
                bank_denied: 1,

            })
        }
        // alert(value)
    }


    onChangeRole(value) {
        console.log(value);
        if (value == 1) {
            this.setState({
                admin_role: 1,
                approval: false,
                account_role: 2,
            })
        } else {
            this.setState({
                admin_role: 2,
                approval: true,
                account_role: 1,

            })
        }
        // alert(value)
    }



    onChangeApproval(value) {
        console.log(value);
        if (value == 1) {
            this.setState({
                admin_approval: 1,
                self_approval: 0,
            })
        } else {
            this.setState({
                admin_approval: 0,
                self_approval: 1,

            })
        }
        // alert(value)
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <Navigate to="/your-users" /> : null
                        }
                    </div>
                )}

                {!hideComponent && (
                    <div>
                        <Card>



                            <CardBody>


                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>Create User</b></h4>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/your-users">
                                            <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                Users
                                            </Button>
                                        </Link>
                                    </div>
                                </div>

                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>


                                </div> : null}<br></br>

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div>

                                ) : null}<br></br>
                                <form className="form" onSubmit={this.onSubmit}>
                                    <br /><br />
                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">FirstName</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="first_name"
                                                        required placeholder="FirstName"
                                                        value={this.state.first_name}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">LastName</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="last_name"
                                                        required placeholder="LastName"
                                                        value={this.state.last_name}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Phone No.</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <PhoneInput
                                                        flags={flags}
                                                        defaultCountry="KE"
                                                        placeholder="Enter phone number"
                                                        name="msisdn"
                                                        maxLength={15}
                                                        id="input"
                                                        value={this.state.msisdn}
                                                        onChange={value => this.setState({ msisdn: value })} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-8 offset-2">
                                <div className="form-group">
                                <div className="row">
                                <div className="col-md-2">
                                <label className="form-label">Username (One word, NO SPACES)</label>
                                </div>
                                <div className="col-md-10">
                                <input id="input" type="username" className="form-control" name="username" pattern="^\S+$"
                                required placeholder="Enter Username"
                                value={this.state.username}
                                onChange={this.handleChange} />
                                </div>
                                </div>
                                </div>
                            </div> */}

                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Email</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="email" className="form-control" name="email"
                                                        required placeholder="Enter Email"
                                                        value={this.state.email}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Role</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="col-md-6" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>
                                                        <RadioGroup onChange={this.onChangeRole} value={this.state.admin_role} horizontal>
                                                            <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                                                Admin
                                                            </RadioButton>
                                                            <RadioButton value="2" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                                                Accountant
                                                            </RadioButton>
                                                        </RadioGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    {this.state.approval == true ?

                                        <div className="col-md-8 offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Sales/Purchase Approval</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <div className="col-md-6" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>
                                                            <RadioGroup onChange={this.onChangeApproval} value={this.state.admin_approval} horizontal>
                                                                <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                                                    Admin Approval
                                                                </RadioButton>
                                                                <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                                                    Self Aproval
                                                                </RadioButton>
                                                            </RadioGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}



                                    <div className="col-md-10 offset-1">

                                        <h4><b>Assign Roles</b></h4>

                                        <div className="row">

                                            <div className="col-md-3">
                                                <div className="col-md-10" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>
                                                    <h4 style={{ marginBottom: "10px" }}><b>Inventory</b></h4>
                                                    <RadioGroup onChange={this.onChangeinventory} value={this.state.inventory_allow} horizontal>
                                                        <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                                            Allow
                                                        </RadioButton>
                                                        <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                                            Denied
                                                        </RadioButton>

                                                    </RadioGroup>
                                                </div>

                                                <div className="col-md-10" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>
                                                    <h4 style={{ marginBottom: "10px" }}><b>Banking</b></h4>
                                                    <RadioGroup onChange={this.onChangebank} value={this.state.bank_allow} horizontal>
                                                        <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                                            Allow
                                                        </RadioButton>
                                                        <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                                            Denied
                                                        </RadioButton>

                                                    </RadioGroup>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="col-md-10" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>
                                                    <h4 style={{ marginBottom: "10px" }}><b>Sales</b></h4>
                                                    <RadioGroup onChange={this.onChangesales} value={this.state.sales_allow} horizontal>
                                                        <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                                            Allow
                                                        </RadioButton>
                                                        <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                                            Denied
                                                        </RadioButton>

                                                    </RadioGroup>
                                                </div>

                                                <div className="col-md-10" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>
                                                    <h4 style={{ marginBottom: "10px" }}><b>Purchases</b></h4>
                                                    <RadioGroup onChange={this.onChangepurchase} value={this.state.purchase_allow} horizontal>
                                                        <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                                            Allow
                                                        </RadioButton>
                                                        <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                                            Denied
                                                        </RadioButton>

                                                    </RadioGroup>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="col-md-10" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>
                                                    <h4 style={{ marginBottom: "10px" }}><b>Investment</b></h4>
                                                    <RadioGroup onChange={this.onChangeinvestment} value={this.state.investment_allow} horizontal>
                                                        <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                                            Allow
                                                        </RadioButton>
                                                        <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                                            Denied
                                                        </RadioButton>

                                                    </RadioGroup>
                                                </div>

                                                <div className="col-md-10" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>
                                                    <h4 style={{ marginBottom: "10px" }}><b>Accountant</b></h4>
                                                    <RadioGroup onChange={this.onChangeaccount} value={this.state.account_allow} horizontal>
                                                        <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                                            Allow
                                                        </RadioButton>
                                                        <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                                            Denied
                                                        </RadioButton>

                                                    </RadioGroup>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="col-md-10" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>
                                                    <h4 style={{ marginBottom: "10px" }}><b>Reports</b></h4>
                                                    <RadioGroup onChange={this.onChangereports} value={this.state.reports_allow} horizontal>
                                                        <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                                            Allow
                                                        </RadioButton>
                                                        <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                                            Denied
                                                        </RadioButton>

                                                    </RadioGroup>
                                                </div>

                                                <div className="col-md-10" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>
                                                    <h4 style={{ marginBottom: "10px" }}><b>Documets</b></h4>
                                                    <RadioGroup onChange={this.onChangedocument} value={this.state.document_allow} horizontal>
                                                        <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                                            Allow
                                                        </RadioButton>
                                                        <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                                            Denied
                                                        </RadioButton>
                                                    </RadioGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 offset-2 form-group">
                                        <br />< br />
                                        <Button type="submit" style={{ color: "white" }} outline className="btn-paypal btn btn-primary btn-sm pull-right" >
                                            {this.state.isLoading ? "Please Wait..." : "Submit"}

                                        </Button> &nbsp;&nbsp;&nbsp;
                                    </div>

                                </form>
                            </CardBody>
                        </Card>
                    </div>
                )
                }
            </div >
        )
    }
}
export default withTranslation('common')(AddClientUser);
