
import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { BRANCH_ID, CURRENCY, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import VendorCreate from './createVendors';
import EditVendor from './vendor_edit';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";
import { read, utils } from "xlsx";
import { VendorStatement } from './VendorStatement';


export class Vendors extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this._onButtonClick3 = this._onButtonClick3.bind(this);
        this._onButtonClick5 = this._onButtonClick5.bind(this);
        this.onSubmitNewUpdatws = this.onSubmitNewUpdatws.bind(this);
        // this.updateData = this.updateData.bind(this)

        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnClick1 = this.handleOnClick1.bind(this);

        this.columns = [

            {
                key: "company_mail",
                TrOnlyClassName: 'tsc',
                text: "Company Name",
                className: "tsc",
                align: "left"
            },

            {
                key: "first_name",
                TrOnlyClassName: 'tsc',
                text: "COntact Person",
                className: "tsc",
                align: "left"
            },
            {
                key: "msisdn",
                TrOnlyClassName: 'tsc',
                text: "Phone No",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "email",
                TrOnlyClassName: 'tsc',
                text: "Email",
                className: "tsc",
                align: "left"
            },

            {
                key: "id",
                TrOnlyClassName: 'tsc',
                text: "Vendor id",
                className: "tsc",
                align: "left"
            },
            {

                key: "tax_info",
                TrOnlyClassName: 'tsc',
                text: "KRA PIN",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "mout_of_balance",
                TrOnlyClassName: 'tsc',
                text: "Opening Balance",
                className: "tsc",
                align: "left"
            },

            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        // eslint-disable-next-line
                        <Fragment className="center" >

                            <button className="btn btn-success  btn-sm"
                                title="Eith statement"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.onStatment(record) }} >
                                Statement
                            </button>

                            <button className="btn btn-primary  btn-sm"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.onEdit(record) }} >
                                Edit
                            </button>
                            {/* <button className="btn btn-success  btn-sm"
                                title="More"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.onEdit(record) }} >
                                More <i className="fa fa-plus"></i>
                            </button> */}
                            <button className="btn btn-danger  btn-sm"
                                title="Delete Supplier"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => {
                                        if (window.confirm('Are you sure you want to delete this supplier?'))

                                            this.onDelete(record)
                                    }
                                }>
                                Delete
                            </button>


                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [100, 200, 500],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: true,
                csv: true
            },
            filename: "suppliers",
            pagination: 'advance',
            page_size: 100,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "No Suppliers was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            showStatements: false,
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            showModals: false,
            _onShow: false,
            showEdit: false,
            type: [{
                name: 'Rating'
            }, {
                name: 'Closed-ended'
            }],

            hideComponent: false,
            data: [],
            priority: [{
                name: '1'
            }
            ],
        };
    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this._onButtonClick2(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    async handleOnClick1(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onApprove(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    onStatment(record) {

        // alert(JSON.stringify(record))
        localStorage.setItem("name_customer", record.company_mail)
        localStorage.setItem("id_client", record.id)
        localStorage.setItem("email_client", record.email)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: false,
            _onShow: false,
            showModals: false,
            showStatements: true,
        }, function () {
        });

    }



    toggleModalCreate = e => {
        this.setState({
            isOpen: true,
        });
    };

    closeModalUpload = e => {
        this.setState({
            isOpen: false,
        });
    };

    //handle profile picture upload
    handleProfile = event => {
        this.setState({
            csvfile: event.target.files[0]
        });
    };



    excelToJson(reader) {
        var fileData = reader.result;
        var wb = read(fileData, { type: 'binary' });
        var dataS = {};
        var rowString;
        var rowObj;
        wb.SheetNames.forEach(function (sheetName) {
            rowObj = utils.sheet_to_row_object_array(wb.Sheets[sheetName]);
            rowString = JSON.stringify(rowObj);
            dataS[sheetName] = rowString;
        });


        // const datas = result.data;
        let data = [];
        let mclient_id;
        let mOpening_Balance;
        let mKRA_Pin;

        for (let i = 0; i < rowObj.length; i++) {
            mclient_id = { client_id: USER_ID };

            let macc_id = { acc_id: ROLE, USER_ID_ACC };
            if (rowObj[i].Opening_Balance == null) {
                mOpening_Balance = { Opening_Balance: 0 }
            } else {
                mOpening_Balance = { Opening_Balance: rowObj[i].Opening_Balance }
            }
            if (rowObj[i].KRA_Pin == null) {
                mKRA_Pin = { KRA_Pin: "NULL" }
            } else {
                mKRA_Pin = { KRA_Pin: rowObj[i].KRA_Pin }
            }

            data.push(Object.assign(mclient_id, macc_id, rowObj[i]));
            this.setState({
                data: data
            })
        }


        console.log("DATA", JSON.stringify(data))
        console.log("brian", data)
        axios.post(baseURL_2 + 'importVendor.php', JSON.stringify(data))
            .then((response) => {
                console.log("bayo", response)
                // this.setState({
                //     statusMessage: "Success!!",
                //     isShowError: true, isLoading1: false, showError: false,
                // });

                this.setState({ statusMessage: "Success!!", isShowError: true, isLoading1: false }, function () {
                    window.setTimeout(() => {
                        this.closeModalUpload();
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });


            }).catch(error => {
                this.setState({ statusMessage: "Unable to import customers", showError: true, isShowError: false, isLoading1: false });

                console.error('Error during service worker registration:', error);
            });




        // this.setState({ excelData: data });
    }


    importCSV = event => {
        event.preventDefault();
        this.setState({ isLoading1: true, isLoading: true });
        var reader = new FileReader();
        reader.onload = this.excelToJson.bind(this, reader);
        reader.readAsBinaryString(this.state.csvfile);
    }


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {
        this.getData();
    }
    // localStorage.setItem("survey_id", record.survey_id)
    // localStorage.setItem("survey_name", record.name)

    getData = (queryString = "") => {

        // alert(USER_ID)
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `vendor?acc_id=${USER_ID_ACC}&&role=${ROLE}&&branch_id=${BRANCH_ID}&&client_id=` + USER_ID + "&&" + queryString
        console.log("query", url);

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((customerResponse) => {
            console.log("BUGggg", customerResponse)

            this.setState({
                customer: customerResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];

                    this.setState({
                        total: customerResponse.data.total
                    })

                    if (this.state.customer.length > 0) {

                        for (let i = 0; i < this.state.customer.length; i++) {
                            let mname;

                            let mcompany_name;

                            let mail;
                            if (this.state.customer[i].status === 1) {
                                mail = { mail: "Active" };
                            } else {
                                mail = { mail: "Inactive" };
                            }

                            if (this.state.customer[i].company_name === null) {
                                mcompany_name = { company_mail: "_" };
                            } else {
                                mcompany_name = { company_mail: this.state.customer[i].company_name };
                            }

                            let mstarting_balance = { mout_of_balance: this.formatCurrency(this.state.customer[i].opening_balance.toString()) };

                            let date = { dates: (moment.utc(this.state.customer[i].created_at).format('YYYY-MM-DD')) };
                            // let opening_balance = { payables: CURRENCY + this.state.customer[i].opening_balance };


                            data.push(Object.assign(date, mname, mstarting_balance, mail, mcompany_name, this.state.customer[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);

                        }
                    } else {
                        this.setState({
                            data: data
                        })

                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    formatCurrency = amount => {
        return new Intl.NumberFormat(this.locale, {
            style: 'currency',
            currency: "ABS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount).replaceAll('ABS', "");
    };


    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showEdit: false,
            showModal: false,
            showModals: false
        });
    }

    _onButtonClick5() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showEdit: false,
            showModal: false,
            showModals: false
        });
    }

    _onButtonClick2(record) {
        localStorage.setItem("customer_id_moreinfo", record.id)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: false,
            showEdit: false,
            _onShow: false,
            showModals: true
        });
    }

    _onButtonClick3() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showModal: false,
            _onShow: false,
            showEdit: false,
            showModals: false
        });
    }

    onEdit(record) {
        localStorage.setItem("email", record.email)
        localStorage.setItem("id_customer", record.id)
        localStorage.setItem("title", record.type)
        localStorage.setItem("first_name", record.first_name)
        localStorage.setItem("other_name", record.other_name)
        localStorage.setItem("msisdn", record.msisdn)
        localStorage.setItem('company_name', record.company_name)
        localStorage.setItem('vendor_display_name', record.vendor_display_name)
        localStorage.setItem('website', record.website)
        localStorage.setItem('street', record.street)
        localStorage.setItem('city_town', record.city_town)
        localStorage.setItem('state_province', record.state_province)
        localStorage.setItem('country', record.country)
        localStorage.setItem('street1', record.street1)
        localStorage.setItem('city_town1', record.city_town1)
        localStorage.setItem('state_province1', record.state_province1)
        localStorage.setItem('country1', record.country1)
        localStorage.setItem('remarks', record.remarks)
        localStorage.setItem('terms', record.terms)
        localStorage.setItem('tax_info_vendor', record.tax_info)
        localStorage.setItem('opening_balance', record.opening_balance)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: false,
            _onShow: false,
            showModals: false,
            showEdit: true,
        }, function () {
        });

    }

    onSubmitNewUpdatws(e) {
        e.preventDefault();
        let ttl_new = (this.state.ttl * 60)

        let formData = {
            "survey_id": localStorage.getItem("survey_id"),

            "ttl": ttl_new,
            "type": this.state.selectedType,
            "question": this.state.question,

        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.put(baseURL + `question/` + this.state.questionsss, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)

                if (response.data.status) {
                    this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                    window.setTimeout(() => {
                        this.setState({ isShowError: false });
                        this._onButtonClick5()
                        this.getData()
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.status_message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }


    onDelete(record) {

        // alert(record.id)
        axios.delete(baseURL + `vendor?vendor_id=` + record.id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData()
                        // this.closeModalMore();
                        this.setState({ isShowError: false })
                    }, 3000);
                });
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData("", moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                    this.setState({ isShowError: false })
                }, 4000);
            }
        }, error => {
            // alert(JSON.stringify(error))
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData("", moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                this.setState({ isShowError: false })
            }, 4000);
        });
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onApprove(record) {
        let body = {
            status: "APPROVED"
        }
        axios.put(baseURL + `question/` + record.question_id + "/approval", body, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 2000);
        });
    }


    onCustomerInfo(record) {
        localStorage.setItem("customer_id_moreinfo", record.id)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: true,
        }, function () {
        });

    }
    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };
    ReactTypes() {
        return (this.state.priority && (this.state.priority.length > 0 || this.state.priority.length === 0) &&
            this.state.priority.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.name })))
    }

    _onShow(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            editShow: true,
            acceptable_answers: record.acceptable_answers,
            answer: record.answer,
            answer_id: record.id,
            showModal: false,
        });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { showEdit, showStatements } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
                    <div>
                        <Modal
                            isOpen={this.state.isOpen}
                            onRequestClose={e => {
                                this.closeModalUpload(e);
                            }}
                            contentLabel="My dialog"
                            className="mymodal"
                            onAfterOpen={() => {
                                document.body.style.overflow = 'hidden';
                            }}
                            onAfterClose={() => {
                                document.body.removeAttribute('style');
                            }}
                            overlayClassName="myoverlay"
                            closeTimeoutMS={500}
                        >
                            <MDBCloseIcon onClick={this.closeModalUpload} />
                            <h4><b>Upload Excel file</b></h4><br />

                            <p><b>Kindly remember to follow
                                <a href="https://api.peakbooks.biz:9000/insightphp/systemFiles/1621650759_datafile.xlsx"> this format
                                </a> before import.<br />N/B> No cell should be left blank (fill it with '0' or '_')</b></p><br />

                            <b><a href="https://api.peakbooks.biz:9000/insightphp/systemFiles/1621650759_datafile.xlsx"> Click here to donwload template
                            </a></b>

                            {this.state.showError ? <div >

                                <Alert color="danger" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>


                            </div> : null}<br></br>

                            {this.state.isShowError ? (
                                <div>

                                    <Alert color="success" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>
                                </div>
                            ) : null}
                            <div className="wrapper text-center">
                                <div className="section1">
                                    <div className="contain">
                                        <input
                                            className="csv-input"
                                            type="file"
                                            required
                                            ref={input => {
                                                this.filesInput = input;
                                            }}
                                            name="file"
                                            customHeight
                                            placeholder={null}
                                            onChange={this.handleProfile}
                                        />
                                        <br />
                                        <Button color="primary" className="btn-paypal btn-lg " onClick={this.importCSV} >
                                            {this.state.isLoading1 ? "Uploading..." : "Import now!"}
                                            <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;

                                        <br />
                                    </div>
                                </div>
                            </div>

                        </Modal>


                        < Card >
                            <CardBody >
                                <div className="panel-body" >
                                    <div className="row">

                                        <div className="col-md-6">
                                            <h4><b>List of Suppliers</b></h4><br />
                                        </div>
                                        <div className="col-md-3">
                                            <Button className="pull-right btn-sm" color="primary
                                                    " onClick={this.toggleModalCreate} outline>Import Suppliers
                                            </Button>


                                        </div>


                                        <div className="col-md-3 float-right">
                                            <b>OR</b>
                                            <Link to="/create-vendors">
                                                <Button className="pull-right btn-sm" color="primary
                                                    " onClick={this._onButtonClick} outline>Create Suppliers
                                                </Button>
                                            </Link>


                                        </div>

                                    </div>

                                    <div className="col-12 float-right">
                                        <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}
                                            onClick={this.getData}>
                                            Refresh <RefreshIcon />
                                        </button>
                                    </div>

                                    <br />
                                    <br />
                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}

                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} />

                                </div>
                            </CardBody>

                        </Card>
                    </div>
                )
                }
                {
                    showComponent && (
                        <div>
                            <VendorCreate />
                        </div>
                    )
                }
                {
                    showEdit && (
                        <div>
                            <EditVendor />
                        </div>
                    )
                }
                {
                    showStatements && (
                        <div>
                            <VendorStatement />
                        </div>
                    )
                }
                {/* 
                {
                    showModal && (
                        < >
                            <CreateAddress />
                        </>
                    )
                } */}

                {/* {
                    showModals && (
                        < >
                            <EditAddress />
                        </>
                    )
                }
                {
                    showEdit && (
                        <>
                            <CustomerEdit />
                        </>
                    )
                } */}
            </div >
        )
    }
}