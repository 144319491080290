import React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Form } from "react-bootstrap";

import { baseURL } from '../../_helpers';
import { CURRENCY, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import { Customers } from './customer_view';
import { Link } from 'react-router-dom';
import Alert from '../../shared/components/Alert';
import Select from 'react-select';
import * as moment from 'moment';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import { Banking } from './banking';
window.$ = window.jQuery = require('jquery');
require('../../_assets/jss/sms_counter.js');


class CreatBank extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            showError: false,
            credit_account: false,
            item_name: "",
            agnaist_ksh: 1,
            data: [],
            banks: [],
            invoice_currency: CURRENCY,

            date2: new Date(),

            terms3: [
                {
                    label: 'Bank',
                    value: 'Bank',
                },
                {
                    label: 'Credit Card',
                    value: 'Credit Card',
                }
            ]
        }
    }



    handleChangeBank = value => {

        if (value != null) {

            this.setState(
                {
                    account_type: value.value.toString(),
                });

            if (value.value.toString() == "Credit Card") {
                this.setState(
                    {
                        credit_account: true,
                    });
            } else {
                this.setState(
                    {
                        credit_account: false,
                    });
            }
        }
        else {
            this.setState(
                {
                    account_type: "",
                });
        }
    };



    async componentDidMount() {
        // $('#message').countSms('#sms-counter');

        const [bankResponse, currency] = await Promise.all([
            axios.get(baseURL + `bank_name`, {
                headers: { Authorization: TOKEN },
            }),
            axios.get(baseURL + `currency`, {
                headers: { Authorization: TOKEN },
            }),
        ]);



        var banks = [];
        for (let i = 0; i < bankResponse.data.data.length; i++) {
            let mlabel = { label: bankResponse.data.data[i].name };
            let mvalue = { value: bankResponse.data.data[i].name };
            banks.push(Object.assign(mlabel, mvalue, bankResponse.data.data[i]));
            this.setState({
                banks: banks,
                isLoading: false,
            })
        }



        var data = [];
        for (let i = 0; i < currency.data.data.length; i++) {
            let mlabel = { label: currency.data.data[i].currency };
            let mvalue = { value: currency.data.data[i].agnaist_ksh };
            data.push(Object.assign(mlabel, mvalue, currency.data.data[i]));
            this.setState({
                data: data,
                isLoading: false,
            })
        }



    }

    onSubmit(e) {

        e.preventDefault();

        let formData = {
            "account_type": "Bank",
            "account_name": this.state.account_name,
            "account_code": this.state.account_code,
            "account_code": "0",
            "currency": this.state.invoice_currency,
            "account_number": this.state.account_number,
            "bank_name": this.state.bank_name,
            "description": this.state.description,
            "created_by": USER_ID
        }

        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.post(baseURL + `bank`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                    window.scrollTo(0, 0)
                    this.load()


                    window.setTimeout(() => {
                        this.setState({ isShowError: false, amount: "" });
                        // this._onButtonClick();
                    }, 1000);

                } else {
                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
            })
    }


    load() {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url3 = baseURL + `deposit_to`
        let url4 = baseURL + `bank?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&account_type=Bank`
        let url5 = baseURL + `bank?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&account_type=Credit Card`
        let url6 = baseURL + `expense_account?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID

        axios.all([
            axios.get(url3, axiosConfig),
            axios.get(url4, axiosConfig),
            axios.get(url5, axiosConfig),
            axios.get(url6, axiosConfig)

        ]).then(axios.spread((paymentResponse, bankResponse, creditResponse, expenseResponse) => {
            this.setState({
                paymentResponse: paymentResponse.data.data,
                bankResponse: bankResponse.data.data,
                creditResponse: creditResponse.data.data,
                expenseResponse: expenseResponse.data
            }, function () {
                var datasss = [];
                if (this.state.paymentResponse.length > 0) {

                    for (let i = 0; i < this.state.paymentResponse.length; i++) {
                        let label = { label: this.state.paymentResponse[i].name };
                        let value = { value: this.state.paymentResponse[i].name };
                        datasss.push(Object.assign(label, value));
                        this.setState({
                            datasss: datasss
                        })
                    }
                } else {
                    this.setState({
                        datasss: datasss
                    })
                }
                window.location.href = '/banking'
                localStorage.setItem("expenseaccountcustomers", JSON.stringify(this.state.expenseResponse))

                localStorage.setItem("paidthrough", JSON.stringify(datasss))
                var data_bank = [];
                if (this.state.bankResponse.length > 0) {
                    for (let j = 0; j < this.state.bankResponse.length; j++) {
                        let label = { label: this.state.bankResponse[j].account_name + " (${this.state.bankResponse[j].bank_name})-" + this.state.bankResponse[j].currency };
                        let value = { value: this.state.bankResponse[j].account_name };
                        data_bank.push(Object.assign(label, value));
                        this.setState({
                            data_bank: data_bank
                        })
                    }
                } else {
                    this.setState({
                        data_bank: data_bank
                    })
                }
                localStorage.setItem("bankaccounts", JSON.stringify(data_bank))
                var data_credit = [];
                if (this.state.creditResponse.length > 0) {
                    for (let j = 0; j < this.state.creditResponse.length; j++) {
                        let label = { label: this.state.creditResponse[j].account_name };
                        let value = { value: this.state.creditResponse[j].account_name };
                        data_credit.push(Object.assign(label, value));
                        this.setState({
                            data_credit: data_credit
                        })
                    }
                } else {
                    this.setState({
                        data_credit: data_credit
                    })
                }
                localStorage.setItem("creditaccounts", JSON.stringify(data_credit))
            })
        })).catch(error => {
            console.log('bayoo', error.response)
        })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            moreinfo: false
        });
    }

    handleChangeCurrency = value => {


        // alert(JSON.stringify(value.currency.toString()))
        // alert(JSON.stringify(<CurrencyConverter from={'USD'} to={'CAD'} value={29} precision={2} date={'2010-12-22'}/>))
        if (value != null) {
            this.setState(
                {
                    agnaist_ksh: value.value.toString(),
                    invoice_currency: value.currency.toString(),
                    currencylabel: value.currency.toString(),
                });
        } else {
            this.setState(
                {
                    agnaist_ksh: 1,
                    invoice_currency: "",
                    currencylabel: "",
                });
        }
    };



    handleChangeBank = value => {


        // alert(JSON.stringify(value.currency.toString()))
        // alert(JSON.stringify(<CurrencyConverter from={'USD'} to={'CAD'} value={29} precision={2} date={'2010-12-22'}/>))
        if (value != null) {
            this.setState(
                {
                    bank_name: value.value.toString(),
                });
        } else {
            this.setState(
                {
                    bank_name: ""
                });
        }
    };



    handleChangeDescription = event => {
        this.setState({ description: event.target.value }, function () { });
        //alert(event.target.value)
    };


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <Banking /> : null
                        }
                    </div>
                )}
                {!hideComponent && (
                    <div>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="col-4">
                                        <h4><b>Add Account</b></h4>
                                    </div>
                                    <div className="col-8">
                                        <Link to="/banking">
                                            <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                Accounts
                                            </Button>
                                        </Link>

                                    </div>
                                </div>


                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>


                                </div> : null}<br></br>

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div>

                                ) : null}<br></br>
                                <form className="form row" onSubmit={this.onSubmit}>


                                    <div className="col-8 offset-2">
                                        <br />
                                        <Form.Group className="Focus-line">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Bank Name</Form.Label>
                                                </div>
                                                <div className="col-md-10">


                                                    <Select
                                                        isClearable
                                                        options={this.state.banks}
                                                        placeholder="Search Your Bank"
                                                        noOptionsMessage={() => 'No bank found'}
                                                        autosize={true}
                                                        required
                                                        isLoading={this.state.isLoading}
                                                        onChange={this.handleChangeBank}
                                                        openOnFocus={true}
                                                        menuPortalTarget={document.body}
                                                        name="bank_name"
                                                    />
                                                    {/* <p>N/B: <b style={{ color: "red" }}>Currency is one off. You won't be able to change it again</b></p> */}

                                                </div>

                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div className="col-8 offset-2"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Account Name</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control"
                                                        name="account_name" required placeholder='Account Name'
                                                        value={this.state.account_name} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {this.state.credit_account == false ?

                                        <div className="col-8 offset-2"><br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Account Number</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control"
                                                            name="account_number" placeholder='Account Number'
                                                            value={this.state.account_number} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                    <div className="col-8 offset-2">
                                        <br />
                                        <Form.Group className="Focus-line">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Currency</Form.Label>
                                                </div>
                                                <div className="col-md-10">


                                                    <Select
                                                        isClearable
                                                        options={this.state.data}
                                                        placeholder="Search Your Currency"
                                                        noOptionsMessage={() => 'No currrency found'}
                                                        autosize={true}
                                                        required
                                                        isLoading={this.state.isLoading}
                                                        onChange={this.handleChangeCurrency}
                                                        openOnFocus={true}
                                                        menuPortalTarget={document.body}
                                                        name="currency"
                                                    />
                                                    {/* <p>N/B: <b style={{ color: "red" }}>Currency is one off. You won't be able to change it again</b></p> */}

                                                </div>

                                            </div>
                                        </Form.Group>
                                    </div>


                                    {/* <div className="col-8 offset-2">
                                        <br />

                                        <Form.Group className="Focus-line" >
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Account Type</Form.Label>
                                                </div>
                                                <div className="col-md-10">
                                                    <Select
                                                        isClearable
                                                        options={this.state.terms3}
                                                        // onInputChange={this.loadOptions}
                                                        placeholder="Select Account Type"
                                                        autosize={true}
                                                        // value={this.state.terms}
                                                        required

                                                        onChange={this.handleChangeBank}
                                                        isLoading={this.state.isLoading}
                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        menuPortalTarget={document.body}
                                                        name="investment_type"
                                                    /><br />
                                                </div>
                                            </div>

                                        </Form.Group>
                                    </div> */}


                                    {/* <div className="col-8 offset-2"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Account Code</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control"
                                                        name="account_code" placeholder='Account Code'
                                                        value={this.state.account_code} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className="col-8 offset-2"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Bank Name</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control"
                                                        name="bank_name" placeholder='Bank Name'
                                                        value={this.state.bank_name} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className="col-8 offset-2">
                                        <br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">

                                                    <label className="form-label">
                                                        Description
                                                    </label>
                                                </div>
                                                <div className="col-8">
                                                    <textarea
                                                        name="invoice_message"
                                                        
                                                        style={{
                                                            paddingTop: '20px',
                                                            borderRadius: '5px',
                                                            borderColor: 'hsl(0,0%,80%)',
                                                        }}
                                                        onChange={this.handleChangeDescription}
                                                        className="col-md-12"
                                                        placeholder="Hint: Enter your description here"
                                                        id="message"
                                                        cols="col-md-1"
                                                        rows="1" />
                                                </div>

                                                <div className="col-md-2">
                                                    <ul id="sms-counter">
                                                        <li>Length: <span class="length" /></li>
                                                        <li>
                                                            Message:
                                                            {' '}
                                                            <span class="remaining" />
                                                            /
                                                            <span class="per_message" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div> */}

                                    <br></br>
                                    <br />
                                    <br></br>
                                    <br />
                                    <div className="col-8 offset-2"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Link to="/banking">
                                                        <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                            Cancel
                                                        </Button>
                                                    </Link>

                                                </div>
                                                <div className="col-md-10">
                                                    <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                        {this.state.isLoading ? "Please Wait..." : "Save"}

                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                )
                }
            </div>
        )
    }
}
export default withTranslation('common')(CreatBank);
