
import React, { Fragment } from 'react';

import ReactDatatable from '@mkikets/react-datatable';

import axios from 'axios';
import { Button, Card, Table, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { BUSINESS_TYPE, COMPANY_NAME, CONFIG, formatCurrency, CURRENCY, LOADER, NAME, PROFILE_URL, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
// import { saveInvoiceView } from './pdfview';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import html2PDF from 'jspdf-html2canvas'
import Loader from "react-loader-spinner";
import { saveStatementView } from './customerStatementpdf';
import { saveStatementsendEmail } from './customerStatementsendEmail';


export class VendorStatement extends React.Component {
    constructor(props) {
        super(props)
        this.applyCallback = this.applyCallback.bind(this);


        this.columns = [

            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "transaction_type",
                TrOnlyClassName: 'tsc',
                text: "Transactions",
                className: "tsc",
                align: "left"
            },

            {
                key: "details",
                TrOnlyClassName: 'tsc',
                text: "Details",
                className: "tsc",
                align: "left"
            },
            {
                key: "mamount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "mpayments",
                TrOnlyClassName: 'tsc',
                text: "Payment",
                className: "tsc",
                altrueign: "left"
            },

            {
                key: "mbalance",
                TrOnlyClassName: 'tsc',
                text: "Balance",
                className: "tsc",
                align: "left"
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [50, 100, 200, 500],
            show_filter: false,
            show_info: false,
            button: {
                excel: true,
                csv: false
            },
            filename: "customer_statements",
            show_pagination: false,
            page_size: 50,
            show_length_menu: false,
            language: {
                loading_text: "Please be patient while data loads...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            download: false,
            showComponent: false,
            toCustomers: false,
            hideComponent: false,
            data: [],

        };

        let now = new Date();
        let start = moment(new Date(new Date().getFullYear(), 0, 1));
        let end = moment(new Date());
        this.state = {
            start: start,
            end: end,
            isPageLoad: true,
        }
    }
    async handleOnClick1(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onEditInvoice(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }


    onclickss = async () => {
        // alert("Done")
        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })

        let customerStatement = {
            total_amount: this.state.total_amount,
            total_payment_made: this.state.total_payment_made,
            total_balance: this.state.total_balance,
            opening_balance: this.state.opening_balance,
            customer_name: localStorage.getItem("name_customer"),
            rangeDate: this.state.startDate + " To " + this.state.endDate,
            lineItems: this.state.lineItems,
        };


        saveStatementView(Object.assign(footer, customerStatement))
        // this.setState({
        //     download: true,
        // })
        // var self = this;

        // const page = document.getElementById('page')
        // html2PDF(page, {
        //     html2canvas: {
        //         scrollX: 0,
        //         scale: 10,
        //         imageTimeout: 15000,
        //         logging: true,
        //         scrollY: -window.scrollY,
        //     },
        //     filename: 'balance_sheet.pdf',
        //     watermark({ pdf, pageNumber, totalPageNumber }) {
        //         pdf.setTextColor('#00000');
        //         pdf.text(50, pdf.internal.pageSize.height - 30, `PeakInsight`);
        //     },
        //     margin: {
        //         top: 15,
        //         right: 20,
        //         bottom: 20,
        //         left: 20,
        //     },
        //     jsPDF: {
        //         unit: 'px',
        //         format: 'a4'
        //     },
        //     imageType: 'image/jpeg',
        //     // watermark: 'PeakInsight',
        //     output: 'customer_statement.pdf',
        //     init: function () {

        //     },
        //     success: function (pdf) {
        //         pdf.save(this.output);

        //         self.setState({
        //             download: false
        //         })
        //         // alert("bayobayo")

        //     }
        // })

    }


    onclicksend = async () => {
        // aert("Done")

        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })

        let customerStatement = {
            total_amount: this.state.total_amount,
            total_payment_made: this.state.total_payment_made,
            total_balance: this.state.total_balance,
            opening_balance: this.state.opening_balance,
            customer_name: localStorage.getItem("name_customer"),
            customer_email: localStorage.getItem("email_client"),
            rangeDate: this.state.startDate + " To " + this.state.endDate,
            lineItems: this.state.lineItems,
        };
        saveStatementsendEmail(Object.assign(footer, customerStatement))

        alert("success")

    }



    formatCurrency = amount => {
        return new Intl.NumberFormat(this.locale, {
            style: 'currency',
            currency: "ABS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount).replaceAll('ABS', "");
    };


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {

        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = `${PROFILE_URL}?canvas=true`;
        }


        this.setState({
            startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
            endDate: moment(new Date()).format('DD MMM, YYYY'),
            isPageLoad: true,
            valuedate: "This Year"
        })

        this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))

        // this.getData("", moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))

    }


    getData = (queryString = "", startDate, endDate) => {

        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `vendor_statement?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&id="${localStorage.getItem("id_client")}"&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"&${queryString}`
        // alert(url)
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((paymentResponse) => {
            this.setState({
                invoice: paymentResponse.data.data.filter(person => person.amount != 0),
                isLoading: false,
            },
                function () {
                    var data = [];

                    this.setState({
                        total: paymentResponse.data.total
                    })
                    let valueAddedExpenses = 0
                    let countExpense = 0

                    let valueAddedExpenses1 = 0
                    let countExpense1 = 0

                    let countBalance = 0
                    let valueAddedBalance = 0

                    for (let i = 0; i < this.state.invoice.length; i++) {

                        let due_date = moment(this.state.invoice[i].date);
                        let dueDateFormated = due_date.format('DD/MM/YYYY');

                        let date1 = { dates: dueDateFormated };
                        //Amount
                        if (this.state.invoice[i].amount != null) {

                            let str2 = this.state.invoice[i].amount;
                            countExpense1 = parseFloat(str2.toString().replaceAll(",", ""))
                            valueAddedExpenses1 += countExpense1
                        }

                        if (this.state.invoice[i].payments != null) {

                            let str1 = this.state.invoice[i].payments;
                            countExpense = parseFloat(str1.toString().replaceAll(",", ""))
                            valueAddedExpenses += countExpense
                        }
                        let mamount;
                        if (countExpense1 > 0) {
                            mamount = { mamount: formatCurrency(countExpense1) };
                        } else {
                            mamount = { mamount: "_" };

                        }
                        let mpayments;
                        if (countExpense > 0) {
                            mpayments = { mpayments: formatCurrency(countExpense) };
                        } else {

                            mpayments = { mpayments: "_" };
                        }


                        let mbalance;

                        countBalance = countExpense1 - countExpense
                        valueAddedBalance += countBalance

                        if (this.state.invoice[i].transaction_type == "Payment Received") {
                            // alert("bato")
                            mbalance = { mbalance: formatCurrency(valueAddedBalance + 0) };
                        } else {
                            mbalance = { mbalance: formatCurrency(valueAddedBalance) };
                        }






                        data.push(Object.assign(date1, mpayments, mbalance, mamount, this.state.invoice[i]));
                        this.setState({
                            data: data,
                            lineItems: data
                        })
                    }

                    console.log("BAYO", this.state.invoice)

                    if (this.state.invoice.length > 0) {
                        this.setState({
                            total_amount: valueAddedExpenses1.toFixed(2),
                            total_payment_made: valueAddedExpenses.toFixed(2),
                            opening_balance: this.state.invoice[0].amount,
                            total_balance: valueAddedBalance.toFixed(2),
                            isPageLoad: false
                        })
                    } else {
                        this.setState({
                            total_amount: valueAddedExpenses1.toFixed(2),
                            total_payment_made: valueAddedExpenses.toFixed(2),
                            opening_balance: 0,
                            total_balance: valueAddedBalance.toFixed(2),
                            isPageLoad: false
                        })
                    }
                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }


    getData2 = (startDate, endDate, queryString = "") => {

        let Sdate = moment(startDate).format('YYYY-MM-DD');
        let Edate = moment(endDate).format('YYYY-MM-DD');



        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `vendor_statement?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&id="${localStorage.getItem("id_client")}"&startDate="2012-01-01 00:00:00"&endDate="2022-12-31 23:59:59"&${queryString}`

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((paymentResponse) => {
            this.setState({
                invoice: paymentResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];


                    this.setState({
                        total: paymentResponse.data.total
                    })
                    let valueAddedExpenses = 0
                    let countExpense = 0
                    let valueAddedExpenses1 = 0
                    let countExpense1 = 0

                    let countBalance = 0
                    let valueAddedBalance = 0

                    if (this.state.invoice.length > 0) {
                        for (let i = 0; i < this.state.invoice.length; i++) {

                            let due_date = moment(this.state.invoice[i].date);
                            let dueDateFormated = due_date.format('DD/MM/YYYY');

                            let date1 = { dates: dueDateFormated };
                            //Amount

                            if (this.state.invoice[i].amount != null) {
                                let str2 = this.state.invoice[i].amount;
                                countExpense1 = parseFloat(str2.toString().replaceAll(",", ""))
                                valueAddedExpenses1 += countExpense1
                            }

                            if (this.state.invoice[i].payments != null) {

                                let str1 = this.state.invoice[i].payments;
                                countExpense = parseFloat(str1.toString().replaceAll(",", ""))
                                valueAddedExpenses += countExpense
                            }
                            let mamount;
                            if (countExpense1 > 0) {
                                mamount = { mamount: formatCurrency(countExpense1) };
                            } else {
                                mamount = { mamount: "_" };

                            }
                            let mpayments;
                            if (countExpense > 0) {
                                mpayments = { mpayments: formatCurrency(countExpense) };
                            } else {

                                mpayments = { mpayments: "_" };
                            }

                            let mbalance;

                            countBalance = countExpense1 - countExpense
                            valueAddedBalance += countBalance


                            // if (this.state.invoice[i].transaction_type == "Payment Received") {
                            //     // alert("bato")
                            //     mbalance = { mbalance: "_" };
                            // } else {
                            mbalance = { mbalance: formatCurrency(valueAddedBalance) };









                            data.push(Object.assign(date1, mpayments, mbalance, mamount, this.state.invoice[i]));
                            this.setState({
                                data: data,
                                lineItems: data
                            })
                        }
                    }

                    this.setState({
                        total_amount: valueAddedExpenses1.toFixed(2),
                        total_payment_made: valueAddedExpenses.toFixed(2),
                        opening_balance: 0,

                        total_balance: valueAddedBalance.toFixed(2),
                        isPageLoad: false
                    })


                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');
        this.getData2(localStorage.getItem("STARTDATE"), localStorage.getItem("ENDDATE"), queryString)

    }
    getDataUpdate() {
        let queryString = ("filter_value=&page_number=0&page_size=10&sort_order=false")

        this.getData2(moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
    }


    applyCallback(startDate, endDate) {
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)
        let Sdate1 = moment(startDate).format('DD MMM, YYYY');
        let Edate2 = moment(endDate).format('DD MMM, YYYY');
        this.setState({
            valuedate: Sdate1 + " " + Edate2,
            startDate: Sdate1,
            isPageLoad: true,
            endDate: Edate2
        })

        this.getData2(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent, toCustomers, returnHome } = this.state;

        let ranges = {
            "Today Only": [moment(this.state.start), moment(this.state.end)],
            "Yesterday Only": [
                moment(this.state.start).subtract(1, "days"),
                moment(this.state.end).subtract(1, "days")
            ],
            "3 Days": [moment(this.state.start).subtract(3, "days"), moment(this.state.end)],
            "5 Days": [moment(this.state.start).subtract(5, "days"), moment(this.state.end)],
            "1 Week": [moment(this.state.start).subtract(7, "days"), moment(this.state.end)],
            "2 Weeks": [moment(this.state.start).subtract(14, "days"), moment(this.state.end)],
            "1 Month": [moment(this.state.start).subtract(1, "months"), moment(this.state.end)],
            "1 Year": [moment(this.state.start).subtract(1, "years"), moment(this.state.end)]
        };
        let local = {
            "format": "DD-MM-YYYY",
            "sundayFirst": false
        }
        return (
            <div style={{ marginTop: "-20px" }} >

                {toCustomers == true ?
                    window.location.href = "customers" : null
                }
                {!hideComponent && (
                    <div>
                        < Card >

                            {this.state.download ?
                                <div className="loader">
                                    {LOADER}
                                </div>
                                :
                                <CardBody >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    {/* <DateTimeRangeContainer
                                                        ranges={ranges}
                                                        start={moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD')}
                                                        end={this.state.end}
                                                        local={local}
                                                        applyCallback={this.applyCallback}>
                                                        <FormControl
                                                            id="formControlsTextB"
                                                            type="text"
                                                            value={this.state.valuedate}
                                                            label="Text"
                                                            placeholder="Filter by Date"
                                                        />
                                                    </DateTimeRangeContainer> */}
                                                    <div className='' p='3'>
                                                        <br />
                                                        <h4>{COMPANY_NAME}</h4>
                                                        <h4><strong>Supplier Statement</strong></h4>
                                                        <h4>From {this.state.startDate} To {this.state.endDate}</h4>
                                                        <br />
                                                    </div>
                                                    <div className="float-" p="3">
                                                        <h4 >{BUSINESS_TYPE === 'business' ? "Invoiced" : "Grant Income"} Amount <strong>{formatCurrency(this.state.total_amount)}</strong></h4>
                                                        <h4 >Amount Received <strong>{formatCurrency(this.state.total_payment_made)}</strong></h4>
                                                        <h4 >Total Balance Due <strong>{formatCurrency(this.state.total_balance)}</strong></h4>
                                                    </div>
                                                </div>
                                                <div className="col-6 float-left">

                                                    <button className=" float-left btn btn-primary btn-sm" onClick={e => {
                                                        this.getDataUpdate();
                                                        this.setState({
                                                            startDate: moment(new Date(new Date().getFullYear(), 0, 1)),
                                                            endDate: moment(new Date()).format('DD MMM, YYYY'),
                                                            valuedate: "This Year"
                                                        })
                                                    }} >Clear</button>
                                                    <button className="float-left btn btn-success btn-sm" onClick={() => this.onclickss()}>View PDF</button>

                                                    {/* <input id="input" type="text" readOnly /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <button className="float-left btn btn-primary btn-sm" onClick={() => this.onclicksend()}>Sent Email</button>

                                            <button className="float-right btn btn-primary btn-sm"
                                                onClick={e => {
                                                    this.setState({
                                                        toCustomers: true,
                                                    })
                                                }}  >Go Back</button>
                                        </div>
                                    </div>


                                    <div className="panel-body" id='page'>

                                        <br />
                                        <br />


                                        <ReactDatatable
                                            config={this.config}

                                            records={this.state.data}




                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />
                                        <br />

                                        {/* <h4 >Total Amount <strong>{formatCurrency(this.state.total_amount)}</strong></h4> */}
                                        <h4>Balance Due <strong>{formatCurrency(this.state.total_balance)}</strong></h4>



                                    </div>
                                </CardBody>
                            }
                        </Card>
                    </div>
                )
                }
            </div >
        )
    }
}
