import React from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import {
  BUSINESS_TYPE,
  COMPANY_NAME,
  CURRENCY,
  LOADER,
  ROLE, USER_ID_ACC,
  formatCurrency,
} from "../../_helpers/exports";
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
import * as moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl } from "react-bootstrap";
import { TOKEN, USER_ID, CONFIG } from "../../_helpers/exports";
import axios from "axios";
import { baseURL, baseURL_2 } from "../../_helpers";

export class TrialBalance extends React.Component {
  constructor(props) {
    super(props);
    this.onclickss = this.onclickss.bind(this);
    this.applyCallback = this.applyCallback.bind(this);

    this.state = {
      isShowError: false,

      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        download: false,
        key: "selection",
      },
    };
    let now = new Date();
    let start = moment(new Date(new Date().getFullYear(), 0, 1));
    let end = moment(new Date());
    this.state = {
      start: start,
      end: end,
      isPageLoad: true,
    };
  }
  onclickss = () => {
    // alert("Done")
    this.setState({
      download: true,
    })
    var self = this;

    const page = document.getElementById('page')
    html2canvas(page).then(canvas => {
      const pdf = new jsPDF('p', 'pt', 'a4');
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 595, 842);
      pdf.save('Trial_balance.pdf');

      self.setState({
        download: false
      })
    });

  }
  componentDidMount() {
    this.setState({
      startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format(
        "DD MMM, YYYY"
      ),
      endDate: moment(new Date()).format("DD MMM, YYYY"),
      isPageLoad: true,
      valuedate: "This year",
    });
    this.getData(
      moment(new Date(new Date().getFullYear(), 0, 1)).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD")
    );
  }

  getData(startDate, endDate) {
    this.setState({
      isLoading: true,
    });
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: TOKEN,
      },
    };
    let url = baseURL + `invoiceamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url2 = baseURL + `custom_balance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url3 = baseURL + `getbillamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url4 = baseURL + `expensesamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url5 = baseURL + `operationexpense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url6 = baseURL + `vendorcreditreport?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;

    // start of new api call
    let url7 = baseURL + `total-advancetax?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url8 = baseURL + `total-advertising-and-marketing?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url9 = baseURL + `total-automatic-expense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url10 = baseURL + `total-bad-debt?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url11 = baseURL + `total-bank-fees-and-charges?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url12 = baseURL + `total-consultant-expense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url13 = baseURL + `total-cost-of-goods-sold?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url14 = baseURL + `total-credit-card-charges?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url15 = baseURL + `total-depreciation-expense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url16 = baseURL + `total-employee-advance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url17 = baseURL + `total-employee-reimbursements?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url18 = baseURL + `total-IT-and-internet-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url19 = baseURL + `total-janitorial-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url20 = baseURL + `total-lodging-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url21 = baseURL + `total-meals-and-entertainment-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url22 = baseURL + `total-office-supplies-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url23 = baseURL + `total-other-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url24 = baseURL + `total-postage-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url25 = baseURL + `total-printing-and-stationery?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url26 = baseURL + `total-rent-expense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url27 = baseURL + `total-repair-and-maintenance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url28 = baseURL + `total-salaries-and-employees-wages?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url29 = baseURL + `total-telephone-expense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url30 = baseURL + `total-travel-expense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url31 = baseURL + `total-tax-payable?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;

    let url32 = baseURL + `total-bill-amount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url33 = baseURL + `total-unpaid-bill?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;


    let url35 = baseURL + `paid-through-advance-tax?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url36 = baseURL + `paid-through-employee-advance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url37 = baseURL + `paid-through-prepaid-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url38 = baseURL + `paid-through-petty-cash?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url39 = baseURL + `paid-through-furniture-and-fittings?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url40 = baseURL + `paid-through-employee-reimbursements?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url41 = baseURL + `paid-through-drawings?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url42 = baseURL + `paid-through-owners-equity?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;

    axios
      .all([
        axios.get(url, axiosConfig),
        axios.get(url2, axiosConfig),
        axios.get(url3, axiosConfig),
        axios.get(url4, axiosConfig),
        axios.get(url5, axiosConfig),
        axios.get(url6, axiosConfig),

        axios.get(url7, axiosConfig),
        axios.get(url8, axiosConfig),
        axios.get(url9, axiosConfig),
        axios.get(url10, axiosConfig),
        axios.get(url11, axiosConfig),
        axios.get(url12, axiosConfig),
        axios.get(url13, axiosConfig),
        axios.get(url14, axiosConfig),
        axios.get(url15, axiosConfig),
        axios.get(url16, axiosConfig),
        axios.get(url17, axiosConfig),
        axios.get(url18, axiosConfig),
        axios.get(url19, axiosConfig),
        axios.get(url20, axiosConfig),
        axios.get(url21, axiosConfig),
        axios.get(url22, axiosConfig),
        axios.get(url23, axiosConfig),
        axios.get(url24, axiosConfig),
        axios.get(url25, axiosConfig),
        axios.get(url26, axiosConfig),
        axios.get(url27, axiosConfig),
        axios.get(url28, axiosConfig),
        axios.get(url29, axiosConfig),
        axios.get(url30, axiosConfig),
        axios.get(url31, axiosConfig),

        axios.get(url32, axiosConfig),
        axios.get(url33, axiosConfig),


        axios.get(url35, axiosConfig),
        axios.get(url36, axiosConfig),
        axios.get(url37, axiosConfig),
        axios.get(url38, axiosConfig),
        axios.get(url39, axiosConfig),
        axios.get(url40, axiosConfig),
        axios.get(url41, axiosConfig),
        axios.get(url42, axiosConfig),
      ])
      .then(
        axios.spread(
          (
            invoiceResponse,
            balanceResponse,
            billResponse,
            goodsResponse,
            expenseResponse,
            vendorCreditReponse,

            advanceTaxResponse,
            advertisingnMarketingRes,
            automaticExpenseResponse,
            badDebtResponse,
            bankFeeAndChargesRes,
            consultantExpenseResponse,
            costOfGoodsSoldResponse,
            creditCardChargesResponse,
            depreciationExpenseResponse,
            employeeAdvanceResponse,
            employeeReimbursementResponse,
            ITandInternetExpensesResponse,
            JanitorialExpensesResponse,
            LodgingExpensesResponse,
            MealsnEntertainmentRes,
            OfficeSuppliesResponse,
            OtherExpensesResponse,
            PostageExpensesResponse,
            PrintingAndStationeryRes,
            RentExpenseResponse,
            RepairAndMaintenanceRes,
            SalariesNemployeeWagesRes,
            TelephoneExpenseResponse,
            TravelExpenseResponse,
            TaxPayableResponse,

            TotalBillAmountResponse,
            TotalUnpaidBillResponse,


            PaidThroughAdvanceTaxRes,
            PaidThroughEmployeeAdvanceRes,
            PaidThroughPrepaidExpensesRes,
            PaidThroughPettyCashRes,
            PaidThroughFurnitureRes,
            PaidThroughEmployeeReimbursementsRes,
            PaidThroughDrawingsRes,
            PaidThroughOwnersEquityRes,

          ) => {
            this.setState(
              {
                invoice1: invoiceResponse.data.data,
                balance1: balanceResponse.data.data,
                bills1: billResponse.data.data,
                goods1: goodsResponse.data.data,
                expense1: expenseResponse.data.data,
                vendorcredit1: vendorCreditReponse.data.data,

                advanceTax1: advanceTaxResponse.data.data[0].total_amount,
                advertisingnMarketing1: advertisingnMarketingRes.data.data[0].total_amount,
                automaticExpense1: automaticExpenseResponse.data.data[0].total_amount,
                badDebt1: badDebtResponse.data.data[0].total_amount,
                bankFeeAndCharges1: bankFeeAndChargesRes.data.data[0].total_amount,
                consultantExpense1: consultantExpenseResponse.data.data[0].total_amount,
                costOfGoodsSold1: costOfGoodsSoldResponse.data.data[0].total_amount,
                creditCardCharges1: creditCardChargesResponse.data.data[0].total_amount,
                depreciationExpense1: depreciationExpenseResponse.data.data[0].total_amount,
                employeeAdvance1: employeeAdvanceResponse.data.data[0].total_amount,
                employeeReimbursement1: employeeReimbursementResponse.data.data[0].total_amount,
                ITandInternetExpense1: ITandInternetExpensesResponse.data.data[0].total_amount,
                JanitorialExpense1: JanitorialExpensesResponse.data.data[0].total_amount,
                LodgingExpense1: LodgingExpensesResponse.data.data[0].total_amount,
                MealsEntertainmentExpense1: MealsnEntertainmentRes.data.data[0].total_amount,
                OfficeSuppliesExpense1: OfficeSuppliesResponse.data.data[0].total_amount,
                OtherExpense1: OtherExpensesResponse.data.data[0].total_amount,
                PostageExpense1: PostageExpensesResponse.data.data[0].total_amount,
                PrintingAndStationery1: PrintingAndStationeryRes.data.data[0].total_amount,
                RentExpense1: RentExpenseResponse.data.data[0].total_amount,
                RepairNMaintenance1: RepairAndMaintenanceRes.data.data[0].total_amount,
                SalariesNWages1: SalariesNemployeeWagesRes.data.data[0].total_amount,
                TelephoneExpense1: TelephoneExpenseResponse.data.data[0].total_amount,
                TravelExpense1: TravelExpenseResponse.data.data[0].total_amount,
                TaxPayable1: TaxPayableResponse.data.data[0].total_amount,

                TotalBillAmount1: TotalBillAmountResponse.data.data[0].total_amount,
                TotalUnPaidBill1: TotalUnpaidBillResponse.data.data[0].total_amount,

                PaidThAdvanceTax1: PaidThroughAdvanceTaxRes.data.data[0].total_amount,
                PaidThEmployeeAdvance1: PaidThroughEmployeeAdvanceRes.data.data[0].total_amount,
                PaidThPrepaidExpense1: PaidThroughPrepaidExpensesRes.data.data[0].total_amount,
                PaidThPettyCash1: PaidThroughPettyCashRes.data.data[0].total_amount,
                PaidThFurniture1: PaidThroughFurnitureRes.data.data[0].total_amount,
                PaidThEmployeeRe1: PaidThroughEmployeeReimbursementsRes.data.data[0].total_amount,
                PaidThDrawings: PaidThroughDrawingsRes.data.data[0].total_amount,
                PaidThOwnerEq1: PaidThroughOwnersEquityRes.data.data[0].total_amount,

                isLoading: false,
              },

              function () {
                var data = [];

                if (
                  this.state.invoice1.length === 0 &&
                  this.state.balance1.length === 0
                ) {
                  this.setState({
                    operating_cost: "0.00",
                    isPageLoad: false,
                  });
                } else {
                  let valueAdded = 0;
                  let count = 0;
                  let valueAdded1 = 0;
                  let count1 = 0;
                  let valueOutofBalance = 0;
                  let countOutofBalance = 0;

                  for (let i = 0; i < this.state.invoice1.length; i++) {
                    let str = this.state.invoice1[i].amount;
                    count = parseFloat(str.replaceAll(",", ""));
                    valueAdded += count;
                  }
                  for (let j = 0; j < this.state.balance1.length; j++) {
                    let b;
                    if (this.state.balance1[j].out_of_balance === null) {
                      b = 0;
                    } else {
                      let str1 =
                        this.state.balance1[j].out_of_balance.toString();
                      countOutofBalance = parseFloat(str1.replaceAll(",", ""));
                      valueOutofBalance += countOutofBalance;
                    }
                  }
                  this.setState({
                    operating_cost1: (countOutofBalance + valueAdded).toFixed(
                      2
                    ),
                  });
                }

                this.budget();

                if (
                  this.state.bills1.length === 0 &&
                  this.state.goods1.length === 0 &&
                  this.state.vendorcredit1.length === 0
                ) {
                  this.setState({
                    cost_of_goods1: "0.00",
                  });
                } else {
                  let valueAddedGoods = 0;
                  let countGoods = 0;
                  let valueBillsBalance = 0;
                  let countBillsBalance = 0;

                  let valueVendorCredit = 0;
                  let countVendorCredit = 0;

                  for (let i = 0; i < this.state.bills1.length; i++) {
                    let str = this.state.bills1[i].amount.toString();
                    countBillsBalance = parseFloat(str.replaceAll(",", ""));
                    valueBillsBalance += countBillsBalance;
                  }

                  for (let i = 0; i < this.state.vendorcredit1.length; i++) {
                    let str = this.state.vendorcredit1[i].due_amount;
                    countVendorCredit = parseFloat(str.replaceAll(",", ""));
                    valueVendorCredit += countVendorCredit;
                  }
                  for (let j = 0; j < this.state.goods1.length; j++) {
                    let str1;
                    if (this.state.goods1[j].amount === null) {
                      valueAddedGoods = 0;
                    } else {
                      let str1 = this.state.goods1[j].amount.toString();
                      countGoods = parseFloat(str1.replaceAll(",", ""));
                      valueAddedGoods += countGoods;
                    }
                  }

                  this.setState({
                    cost_of_goods1: (
                      valueBillsBalance +
                      valueAddedGoods -
                      valueVendorCredit
                    ).toFixed(2),
                  });
                }

                if (this.state.expense1.length === 0) {
                  this.setState({
                    cost_of_operation1: "0.00",
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.expense1.length; j++) {
                    let a;
                    if (this.state.expense1[j].amount == null) {
                      a = 0;
                    } else {
                      let str2 = this.state.expense1[j].amount.toString();

                      // alert(this.state.expense[].amount)
                      countExpense = parseFloat(str2.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }
                  }
                  this.setState({
                    cost_of_operation1: valueAddedExpenses.toFixed(2),
                  });
                }

                this.setState({
                  gross1:
                    this.state.operating_cost1 - this.state.cost_of_goods1,
                });


                //sum

                this.setState({
                  totalDebit: (parseFloat(this.state.TotalBillAmount1) - parseFloat(this.state.TotalUnPaidBill1))
                    + parseFloat(this.state.advanceTax1)
                    + parseFloat(this.state.advertisingnMarketing1)
                    + parseFloat(this.state.automaticExpense1)
                    + parseFloat(this.state.badDebt1)
                    + parseFloat(this.state.bankFeeAndCharges1)
                    + parseFloat(this.state.consultantExpense1)
                    + parseFloat(this.state.costOfGoodsSold1)
                    + parseFloat(this.state.creditCardCharges1)
                    + parseFloat(this.state.depreciationExpense1)
                    + parseFloat(this.state.employeeAdvance1)
                    + parseFloat(this.state.employeeReimbursement1)
                    + parseFloat(this.state.ITandInternetExpense1)
                    + parseFloat(this.state.JanitorialExpense1)
                    + parseFloat(this.state.LodgingExpense1)
                    + parseFloat(this.state.MealsEntertainmentExpense1)
                    + parseFloat(this.state.OfficeSuppliesExpense1)
                    + parseFloat(this.state.OtherExpense1)
                    + parseFloat(this.state.PostageExpense1)
                    + parseFloat(this.state.PrintingAndStationery1)
                    + parseFloat(this.state.RentExpense1)
                    + parseFloat(this.state.RepairNMaintenance1)
                    + parseFloat(this.state.SalariesNWages1)
                    + parseFloat(this.state.TelephoneExpense1)
                    + parseFloat(this.state.TravelExpense1)
                    + parseFloat(this.state.TaxPayable1),

                  totalCredit: parseFloat(this.state.TotalBillAmount1)
                    + parseFloat(this.state.PaidThAdvanceTax1)
                    + parseFloat(this.state.PaidThEmployeeAdvance1)
                    + parseFloat(this.state.PaidThPrepaidExpense1)
                    + parseFloat(this.state.PaidThPettyCash1)
                    + parseFloat(this.state.PaidThFurniture1)
                    + parseFloat(this.state.PaidThEmployeeRe1)
                    + parseFloat(this.state.PaidThDrawings)
                    + parseFloat(this.state.PaidThOwnerEq1),

                });
              }
            );
          }
        )
      )
      .catch((error) => {
        console.log("bayoo", error.response);
      });
  }

  applyCallback(startDate, endDate) {
    let Sdate1 = moment(startDate).format("DD MMM, YYYY");
    let Edate2 = moment(endDate).format("DD MMM, YYYY");
    this.setState({
      valuedate: Sdate1 + " " + Edate2,
      startDate: Sdate1,
      isPageLoad: true,
      endDate: Edate2,
    });

    this.getData(
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD")
    );
  }

  budget() {
    let url = baseURL + `budget?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID;
    axios
      .all([axios.get(url, CONFIG)])
      .then(
        axios.spread((budgetResponse) => {
          var data = [];
          this.setState({
            isPageLoad: false,
          });

          if (budgetResponse.data.data.length > 0) {
            this.setState({
              budget: budgetResponse.data.data,
              sales: budgetResponse.data.data[0].sales,
              cost_of_goods_2: budgetResponse.data.data[0].cost_of_goods,

              profession_expe: budgetResponse.data.data[0].profession_expe,
              admin_exp: budgetResponse.data.data[0].admin_exp,
              staff_exp: budgetResponse.data.data[0].staff_exp,
              asset_imp: budgetResponse.data.data[0].asset_imp,
              finance_cost: budgetResponse.data.data[0].finance_cost,
              est_exp: budgetResponse.data.data[0].est_exp,
              other_exp: budgetResponse.data.data[0].other_exp,

              cash_bank: budgetResponse.data.data[0].cash_bank,
              account_receivable:
                budgetResponse.data.data[0].account_receivable,

              closing_stock: budgetResponse.data.data[0].closing_stock,
              other_current: budgetResponse.data.data[0].other_current,

              furniture: budgetResponse.data.data[0].furniture,
              intangible_asset: budgetResponse.data.data[0].intangible_asset,
              other_non_current: budgetResponse.data.data[0].other_non_current,

              account_payable: budgetResponse.data.data[0].account_payable,
              short_term_loans: budgetResponse.data.data[0].short_term_loans,
              short_term_related:
                budgetResponse.data.data[0].short_term_related,

              tax_payable: budgetResponse.data.data[0].tax_payable,
              other_current_libility:
                budgetResponse.data.data[0].other_current_libility,

              long_term: budgetResponse.data.data[0].long_term,
              long_term_related: budgetResponse.data.data[0].long_term_related,
              non_current_liability:
                budgetResponse.data.data[0].non_current_liability,
              share_capital: budgetResponse.data.data[0].share_capital,
              related_earnings: budgetResponse.data.data[0].related_earnings,

              direct_account: budgetResponse.data.data[0].direct_account,
              other_equities: budgetResponse.data.data[0].other_equities,
            });
          } else {
            this.setState({
              sales: 0,
              cost_of_goods_2: 0,
              profession_expe: 0,
              admin_exp: 0,
              staff_exp: 0,
              asset_imp: 0,
              finance_cost: 0,
              other_exp: 0,
              est_exp: 0,

              cash_bank: 0,
              account_receivable: 0,
              closing_stock: 0,
              other_current: 0,
              furniture: 0,
              intangible_asset: 0,
              other_non_current: 0,
              account_payable: 0,
              short_term_loans: 0,
              short_term_related: 0,
              tax_payable: 0,
              other_current_libility: 0,
              long_term: 0,
              long_term_related: 0,
              non_current_liability: 0,

              share_capital: 0,
              related_earnings: 0,
              direct_account: 0,
              other_equities: 0,
            });
          }
        })
      )
      .catch((error) => {
        console.log("bayoo", error.response);
      });
  }
  render() {
    let ranges = {
      "Today Only": [moment(this.state.start), moment(this.state.end)],
      "Yesterday Only": [
        moment(this.state.start).subtract(1, "days"),
        moment(this.state.end).subtract(1, "days"),
      ],
      "3 Days": [
        moment(this.state.start).subtract(3, "days"),
        moment(this.state.end),
      ],
      "5 Days": [
        moment(this.state.start).subtract(5, "days"),
        moment(this.state.end),
      ],
      "1 Week": [
        moment(this.state.start).subtract(7, "days"),
        moment(this.state.end),
      ],
      "2 Weeks": [
        moment(this.state.start).subtract(14, "days"),
        moment(this.state.end),
      ],
      "1 Month": [
        moment(this.state.start).subtract(1, "months"),
        moment(this.state.end),
      ],
      "1 Year": [
        moment(this.state.start).subtract(1, "years"),
        moment(this.state.end),
      ],
    };
    let local = {
      format: "DD-MM-YYYY",
      sundayFirst: false,
    };
    return (
      <div style={{ marginTop: "-20px" }}>
        {this.state.isPageLoad ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="loader">{LOADER}</div>
          </div>
        ) : (
          <Card>
            {this.state.download ? (
              <div className="d-flex justify-content-center align-items-center">
                <div className="loader">{LOADER}</div>
              </div>
            ) : (
              <CardBody>
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-6">
                            <DateTimeRangeContainer
                              ranges={ranges}
                              start={this.state.start}
                              end={this.state.end}
                              local={local}
                              applyCallback={this.applyCallback}
                            >
                              <FormControl
                                id="formControlsTextB"
                                type="text"
                                value={this.state.valuedate}
                                label="Text"
                                placeholder="Filter by Date"
                              />
                            </DateTimeRangeContainer>
                          </div>

                          <div className="col-6 float-left">
                            <button
                              className=" float-left btn btn-primary btn-sm"
                              onClick={(e) => {
                                // this.getData();
                                this.getData(
                                  moment(
                                    new Date(new Date().getFullYear(), 0, 1)
                                  ).format("YYYY-MM-DD"),
                                  moment(new Date()).format("YYYY-MM-DD")
                                );

                                this.setState({
                                  startDate: moment(
                                    new Date(new Date().getFullYear(), 0, 1)
                                  ).format("DD MMM, YYYY"),
                                  endDate: moment(new Date()).format(
                                    "DD MMM, YYYY"
                                  ),
                                  isPageLoad: true,
                                  valuedate: "This year",
                                });
                              }}
                            >
                              Clear
                            </button>
                            {/* <input id="input" type="text" readOnly /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pull-right">
                      <button
                        className="float-right btn btn-primary btn-sm"
                        onClick={() => this.onclickss()}
                      >
                        Export PDF
                      </button>
                    </div>
                  </div>
                  <div id='page'>
                    <br />
                    <div className="text-center" p="3">
                      <h4>{COMPANY_NAME}</h4>
                      <h3>
                        <strong>Trial Balance</strong>
                      </h3>
                      {/* <h4>Basis: Accrual</h4> */}
                      <h4>
                        From {this.state.startDate} To {this.state.endDate}
                      </h4>
                      <br />
                      <br />
                      <br />
                    </div>

                    <div className="row col-11 offset-0">
                      <div className="col-md-4 col-sm-4 col-xs-4">
                        <hr style={{ marginTop: "0px" }} />
                        <h4>
                          <strong>Account</strong>
                        </h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Accounts Payable</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Accounts Receivable</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Advance Tax</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Advertising And Marketing</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Automatic Expense</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Bad Debt</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Bank Fees and Charges</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Consultant Expense</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Cost of Goods Sold</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Credit Card Charges</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Depreciation Expense</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Discount</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Drawings</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Employee Advance</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Employee Reimbursements</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Furniture and Fittings</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Income Interest</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Inventory Assets</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">IT and Internet Expenses</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Late Fee Charges</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Janitorial Expenses</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Lodging</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Meals and Entertainment</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Office and Supplies</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Other Charges</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Other Expenses</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Owner's Equity</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Petty Cash</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Postage</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Prepaid Expenses</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Printing and Stationery</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Rent Expense</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Repair and Maintenance</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Salaries and Employees wages</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Sales</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Shipping Charges</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Telephone Expense</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Travel Expense</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Tax Payable</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed">Other Income</h4>
                        <hr style={{ marginTop: "0px" }} />
                        <h4 id="subhed"><strong>TOTAL</strong></h4>
                      </div>

                      {/* debit/expense */}
                      <div className="col-md-4 col-sm-4 col-xs-4 text-right">
                        <hr style={{ marginTop: "0px" }} />
                        <h4>
                          <strong>Debit</strong>
                        </h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency((
                          parseFloat(this.state.TotalBillAmount1) -
                          parseFloat(this.state.TotalUnPaidBill1)
                        ).toFixed(2)
                        )}
                        </h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.operating_cost1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.advanceTax1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.advertisingnMarketing1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.automaticExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.badDebt1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.bankFeeAndCharges1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.consultantExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.costOfGoodsSold1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.creditCardCharges1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.depreciationExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.employeeAdvance1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.employeeReimbursement1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.ITandInternetExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.JanitorialExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.LodgingExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.MealsEntertainmentExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.OfficeSuppliesExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.OtherExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.PostageExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.PrintingAndStationery1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.RentExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.RepairNMaintenance1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.SalariesNWages1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.TelephoneExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.TravelExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.TaxPayable1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4><strong>{formatCurrency(this.state.totalDebit + parseFloat(this.state.operating_cost1))}</strong></h4>
                        <hr style={{ marginTop: "0px", backgroundColor: '#333', margin: '0px 0' }} />
                        <hr style={{ marginTop: "0px", backgroundColor: '#333', margin: '1px 0' }} />

                      </div>

                      {/* credit/income */}
                      <div className="col-md-4 col-sm-4 col-xs-4 text-right">
                        <hr style={{ marginTop: "0px" }} />
                        <h4>
                          <strong>Credit</strong>
                        </h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>
                          {formatCurrency(this.state.TotalBillAmount1)}
                        </h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.cost_of_goods_2)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.PaidThAdvanceTax1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>
                          {formatCurrency(this.state.PaidThDrawings)}
                        </h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.PaidThEmployeeAdvance1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.PaidThEmployeeRe1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.PaidThFurniture1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.PaidThOwnerEq1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.PaidThPettyCash1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.PaidThPrepaidExpense1)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(0)}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4>{formatCurrency(this.state.totalDebit + parseFloat(this.state.operating_cost1) - this.state.totalCredit + parseFloat(this.state.cost_of_goods_2))}</h4>
                        <hr style={{ marginTop: "0px" }} />

                        <h4><strong>{formatCurrency(this.state.totalDebit + parseFloat(this.state.operating_cost1))}</strong></h4>
                        <hr style={{ marginTop: "0px", backgroundColor: '#333', margin: '0px 0' }} />
                        <hr style={{ marginTop: "0px", backgroundColor: '#333', margin: '1px 0' }} />

                      </div>

                    </div>
                  </div>
                </div>
              </CardBody>
            )}
          </Card>
        )}
      </div>
    );
  }
}
