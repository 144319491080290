import React, { Component } from 'react';
import styles from './Invoice.module.scss';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Form } from 'react-bootstrap';
import LineItems from './LineItems.jsx';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { uuid } from 'uuidv4';
import { BRANCH_ID, CURRENCY, PROFILE_URL, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { baseURL } from '../../_helpers';
import Alert from '../../shared/components/Alert';
import { Link, Navigate } from 'react-router-dom';
import { RadioGroup, ReversedRadioButton, RadioButton } from 'react-radio-buttons'
import $ from 'jquery';
import * as moment from 'moment';
window.$ = window.jQuery = require('jquery');
require('../../_assets/jss/sms_counter.js');
var invNum = require('invoice-number');

window.localStorage.setItem("num", "ABC000001")

class CreditNote extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this._onButtonClick = this._onButtonClick.bind(this);
        this.onChangeOption = this.onChangeOption.bind(this);
        this.handleAddLineSubmit = this.handleAddLineSubmit.bind(this);

    }


    locale = 'en-US';
    currency = CURRENCY;

    state = {
        taxRate: 0.00,
        credit_no: invNum.InvoiceNumber.next(localStorage.getItem("num")),
        credit_date: new Date(),
        editedInvoice: {},
        tax_exclusive: 1,
        showtax: true,
        discount_amount: 0,
        addnew: false,
        returnHome: false,
        lineItems: [
            {
                id: 'initial', // react-beautiful-dnd unique key
                name: '',
                description: '',
                reference: '',
                quantity: 1,
                discount_percentage: 0,
                tax_percentage: 0,
                price: 0.00
            },
        ],
    };
    async componentDidMount() {
        $('#message').countSms('#sms-counter');
        $('#messages').countSms('#sms-counters');

        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = `${PROFILE_URL}?canvas=true`;
        }


        this.getInvoiceData()

        localStorage.setItem("num", invNum.InvoiceNumber.next(localStorage.getItem("num")))
        const [customerResponse] = await Promise.all([
            axios.get(baseURL + `customer?branch_id=${BRANCH_ID}&&filter_value=&page_number=1&page_size=1000&sort_order=false&client_id=` + USER_ID, {
                headers: { Authorization: TOKEN },
            }),
        ]);
        this.setState({
            isLoading: false,
            returnHome: false,
            credit_no: invNum.InvoiceNumber.next(this.state.credit_no),

            customerResponse: customerResponse.data.data,
        });

        console.log('customer', this.state.customerResponse);
    }
    handleLineItemChange = elementIndex => event => {

        console.log(this.state.lineItems);

        let lineItems = this.state.lineItems.map((item, i) => {
            if (elementIndex !== i) return item;
            // alert(elementIndex);
            return { ...item, [event.target.name]: event.target.value };
        });
        this.setState({ lineItems });
    };
    Customer() {
        return (
            this.state.customerResponse &&
            (this.state.customerResponse.length > 0 || this.state.customerResponse.length === 0) &&
            this.state.customerResponse.map((countyItem, i) => ({
                label: countyItem.customer_display_name + " (" + countyItem.email + ")",
                customer_email: countyItem.email,
                customer_nameinvoice: countyItem.customer_display_name,
                value: countyItem.id,
            }))
        );
    }

    handleAddLineItem = event => {

        let length = this.state.lineItems.length - 1;
        this.setState({
            addnew: true
        })

        if (length > -1) {
            if (this.state.lineItems[length].name === '') {
                alert("Name of the item cannot be empty")
                this.setState({
                    addnew: false,
                    // use optimistic uuid for drag drop; in a production app this could be a database id
                    lineItems: this.state.lineItems.concat(),
                });
            } else {
                this.setState({
                    addnew: true,
                })
                let axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        "Authorization": TOKEN
                    }
                };
                var encodeURISafe = require('encodeuri-safe');
                // alert()
                let url = `${baseURL}item?branch_id=${BRANCH_ID}&&client_id=${USER_ID}&filter_value=${encodeURISafe.encodeURIComponent(this.state.lineItems[length].name)}`

                // let url = baseURL + `item?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&filter_value=` + this.state.lineItems[length].name
                axios.all([
                    axios.get(url, axiosConfig),
                ]).then(axios.spread((itemResponse) => {
                    this.setState({
                        item: itemResponse.data.data,
                        isLoading: false,

                    },
                        function () {
                            var data = [];
                            //filter json object
                            let filter_items = this.state.lineItems.filter(item => item.name === this.state.item[0].item_name)
                            let accumulative = filter_items.reduce(
                                (prev, cur) =>
                                    (Number(prev) + Number(cur.quantity)),
                                0)
                            if ((this.state.item[0].quantity - accumulative) < 0) {
                                this.setState({
                                    addnew: false,
                                })
                                this.handleRemove(length)
                                alert(`You current stock for item ${this.state.item[0].item_name} is not enough. Kindly restock`)
                            } else {
                                this.setState({
                                    addnew: false,
                                    // use optimistic uuid for drag drop; in a production app this could be a database id
                                    lineItems: this.state.lineItems.concat([
                                        {
                                            id: uuid(), // react-beautiful-dnd unique key
                                            name: '',
                                            description: '',
                                            reference: '',
                                            quantity: 1,
                                            discount_percentage: 0,
                                            tax_percentage: 0,
                                            price: 0.00
                                        },
                                    ]),
                                })
                            }
                        })
                })).catch(error => {
                    console.log('bayoo', error.response)
                })
            }
        } else {
            this.setState({
                addnew: false,
                // use optimistic uuid for drag drop; in a production app this could be a database id
                lineItems: this.state.lineItems.concat([
                    {
                        id: uuid(), // react-beautiful-dnd unique key
                        name: '',
                        description: '',
                        reference: '',
                        quantity: 1,
                        discount_percentage: 0,
                        tax_percentage: 0,
                        price: 0.00
                    },
                ]),
            })

        }
    };


    handleAddLineSubmit = event => {

        let length = this.state.lineItems.length - 1;
        this.setState({
            addnew: true,
            isLoading: true
        })
        if (length > -1) {
            if (this.state.lineItems[length].name === '') {
                alert("Name of the item cannot be empty")
                this.setState({
                    addnew: false,
                    isLoading: false,

                    // use optimistic uuid for drag drop; in a production app this could be a database id
                    lineItems: this.state.lineItems.concat(),
                });
            } else {
                let axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": TOKEN
                    }
                };

                var encodeURISafe = require('encodeuri-safe');
                // alert()
                let url = `${baseURL}item?branch_id=${BRANCH_ID}&&client_id=${USER_ID}&filter_value=${encodeURISafe.encodeURIComponent(this.state.lineItems[length].name)}`

                // let url = baseURL + `item?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&filter_value=` + this.state.lineItems[length].name
                axios.all([
                    axios.get(url, axiosConfig),
                ]).then(axios.spread((itemResponse) => {
                    this.setState({
                        item: itemResponse.data.data,
                    },
                        function () {
                            var data = [];
                            let filter_items = this.state.lineItems.filter(item => item.name === this.state.item[0].item_name)
                            let accumulative = filter_items.reduce(
                                (prev, cur) =>
                                    (Number(prev) + Number(cur.quantity)),
                                0)
                            if ((this.state.item[0].quantity - accumulative) < 0) {
                                this.setState({
                                    addnew: false,
                                    isLoading: false,

                                })
                                this.handleRemove(length)
                                alert(`You current stock for item ${this.state.item[0].item_name} is not enough. Kindly restock`)
                            } else {
                                this.setState({
                                    addnew: false,

                                })
                                this.onSubmit(event)
                            }
                        })
                })).catch(error => {
                    console.log('bayoo', error.response)
                })
            }
        } else {
            this.setState({
                addnew: false,
                // use optimistic uuid for drag drop; in a production app this could be a database id
                lineItems: this.state.lineItems.concat([
                    {
                        id: uuid(), // react-beautiful-dnd unique key
                        name: '',
                        description: '',
                        reference: '',
                        quantity: 1,
                        discount_percentage: 0,
                        tax_percentage: 0,
                        price: 0.00

                    },
                ]),
            })

        }
    };



    // handlesubmit = event => {


    // }

    handleRemove(bayo) {
        this.setState({
            lineItems: this.state.lineItems.filter((item, i) => {
                return bayo !== i;
            }),
        });
    };
    handleRemoveLineItem = elementIndex => event => {
        this.setState({
            lineItems: this.state.lineItems.filter((item, i) => {
                return elementIndex !== i;
            }),
        });
    };

    handleReorderLineItems = newLineItems => {
        this.setState({
            lineItems: newLineItems,
        });
    };
    handleChangeInvoiceMessage = event => {
        this.setState({ customer_note: event.target.value }, function () { });
        //alert(event.target.value)
    };

    handleChangeStatementMessage = event => {
        this.setState({ terms_condition: event.target.value }, function () { });
        //alert(event.target.value)
    };

    handleFocusSelect = event => {
        event.target.select();
    };

    handlePayButtonClick = () => {
        alert('Not implemented');
    };

    formatCurrency = amount => {
        return new Intl.NumberFormat(this.locale, {
            style: 'currency',
            currency: "ABS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount).replaceAll('ABS', CURRENCY);
    };

    formatCurrency1 = amount => {
        return new Intl.NumberFormat(this.locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };
    calcTaxAmount = c => {
        return c * (localStorage.getItem('valuestax') / 100);
    };

    calcLineItemsTotal = () => {

        if (this.state.showtax == true) {
            return this.state.lineItems.reduce(
                (prev, cur) => prev + cur.quantity * cur.price,
                0
            );
        } else {

            return this.state.lineItems.reduce(
                (prev, cur) => prev + ((cur.quantity * cur.price) / (1 + (cur.tax_percentage / 100))),
                0
            );
        }

    };

    calcLineItemDiscounts = () => {
        return this.state.discount_amount;

        // return this.state.lineItems.reduce(
        //     (prev, cur) => prev + cur.discount_percentage / 100 * (cur.quantity * cur.price),
        //     0
        // );
    };


    calcTaxTotal = () => {
        if (this.state.showtax == true) {
            return this.state.lineItems.reduce(
                (prev, cur) => prev + ((cur.tax_percentage / 100) * (cur.quantity * cur.price)),
                0
            );
        } else {
            return this.state.lineItems.reduce(
                (prev, cur) => prev + ((cur.quantity * cur.price) - ((cur.quantity * cur.price) / (1 + (cur.tax_percentage / 100)))),
                0
            );
        }
    };
    calcGrandTotal = () => {


        return (
            this.calcLineItemsTotal() +
            this.calcTaxTotal() -
            this.calcLineItemDiscounts()
        );
    };

    onSelectChangesShort = value => {
        if (value != null) {

            this.setState(
                {
                    ...this.state.editedInvoice,
                    customer_id: value.value.toString(),
                    customer_name: value.label.toString(),
                    customer_nameinvoice2: value.customer_nameinvoice.toString(),
                    customer_email: value.customer_email != null ? value.customer_email.toString() : '',
                });
        }
    };

    handleChange = e => {
        this.setState({
            ...this.state.editedInvoice,
            [e.target.name]: e.target.value,
        });

        console.log('updates', this.state.editedInvoice);
    };

    onChangeOption(value) {
        console.log(value);
        if (value == 1) {
            this.setState({
                showtax: true,
                tax_inclusive: '0',
                tax_exclusive: 1,
            })
        } else {
            this.setState({
                showtax: false,
                tax_inclusive: '1',
                tax_exclusive: 0

            })
        }
        // alert(value)
    }

    onSubmit(e) {
        // e.preventDefault();
        // eslint-disable-next-line
        this.state.editedInvoice = {

            credit_date: this.state.credit_date,
            terms_condition: this.state.terms_condition,
            customer_note: this.state.customer_note,
            customer_name: this.state.customer_nameinvoice2,
            customer_email: this.state.customer_email,
            tax_exclusive: this.state.tax_exclusive,
            amount1: this.formatCurrency1(this.calcGrandTotal()),
            amount: this.formatCurrency(this.calcGrandTotal()),
            due_amount: this.formatCurrency(this.calcGrandTotal()),
            tax_amount: this.formatCurrency(this.calcTaxTotal()),
            discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
            lineItems: this.state.lineItems,
            currency: CURRENCY,
            tax: this.formatCurrency(this.calcTaxTotal()),
            subtoal: this.formatCurrency(this.calcLineItemsTotal()),
            total: this.formatCurrency(this.calcGrandTotal()),

        };

        let formData = {
            credit_date: this.state.credit_date,
            terms_condition: this.state.terms_condition,
            customer_note: this.state.customer_note,
            customer_name: this.state.customer_nameinvoice2,
            customer_email: this.state.customer_email,
            tax_exclusive: this.state.tax_exclusive,
            status: e,
            sub_total: this.formatCurrency(this.calcLineItemsTotal()),
            amount: this.formatCurrency(this.calcGrandTotal()),
            due_amount: this.formatCurrency(this.calcGrandTotal()),
            tax_amount: this.formatCurrency(this.calcTaxTotal()),
            discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
            created_by: USER_ID,
            customer_id: this.state.customer_id,
        };
        // console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios
            .post(baseURL + `credit`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: TOKEN,
                },
            })
            .then(response => {
                if (response.data.status) {
                    this.setState({
                        statusMessage: response.data.message,
                        isShowError: true,
                        isLoading: false,
                        showError: false
                    });

                    localStorage.setItem("CREDIT_SENDEMAIL", JSON.stringify(this.state.editedInvoice))

                    localStorage.setItem("CREDIT_CREATEPEAK", JSON.stringify(this.state.lineItems))
                    window.setTimeout(() => {
                        this.setState({ isShowError: false, returnHome: true });
                        this._onButtonClick();
                    }, 2000);
                    window.scrollTo(0, 0);
                } else {
                    this.setState({
                        statusMessage: response.data.message,
                        showError: true,
                        isShowError: false,
                        isLoading: false,
                    });
                    window.scrollTo(0, 0);
                }
            })
            .catch(error => {
                console.log('bayoo', error.response);
                this.setState({
                    statusMessage: error.response.data.message,
                    showError: true,
                    isShowError: false,
                    isLoading: false,
                });
                window.scrollTo(0, 0);
            });
    }


    // summar on invoices
    getInvoiceData = () => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `notes?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((itemResponse) => {
            this.setState({
                notes: itemResponse.data.data,
                isLoading: false,
            }, function () {
                var data = [];
                if (this.state.notes.length > 0) {
                    this.setState({ invoice_message: this.state.notes[0].invoice_notes });
                } else {
                    this.setState({
                        invoice_message: "",
                    })
                }
            })
        })).catch(error => {
            console.log('bayoo', error.response)
        })
    }

    _onButtonClick() {
        this.setState({
            returnHome: true
        });
    }
    loadOptions = inputValue => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                Authorization: TOKEN,
            },
        };
        this.setState({
            isLoading: true,
        });
        let url =
            baseURL +
            `customer?branch_id=${BRANCH_ID}&&client_id=` +
            USER_ID +
            `&&filter_value=${inputValue}`;
        axios.get(url, axiosConfig).then(itemResponse => {
            this.setState(
                {
                    item: itemResponse.data.data,
                },
                function () {
                    this.setState({
                        isLoading: false,
                        bayo: this.state.item &&
                            (this.state.item.length > 0 || this.state.item.length === 0) &&
                            this.state.item.map((countyItem, i) => ({
                                label: countyItem.customer_display_name,
                                customer_email: countyItem.email,
                                value: countyItem.id,
                            })),
                    });
                }
            );
        });
    };

    handleCreate = inputValue => {
        const newValue = { value: inputValue.toLowerCase(), label: inputValue };
        this.setState({ newValue })
        // setOptions([...options, newValue]);
        // setValue(newValue);

    };

    handleChangeDisco = e => {
        this.setState({
            discount_amount: e.target.value,
        });

        console.log('updates', this.state.editedInvoice);
    };


    render = () => {
        console.log('ITEMS', this.state.lineItems);
        const ref = React.createRef();
        const { editedInvoice, returnHome } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >

                {returnHome === true ? <Navigate to='/credit-note' /> : null}

                <div ref={ref}>
                    <Card>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4><b>Create Credit Note</b></h4>
                                </div>
                                <div className="col-md-6">
                                    <Link to="/credit-note">
                                        <Button className="pull-right btn-sm" onClick={this._onButtonClick}
                                            color="primary" outline><i className="fa  fa-arrow-left"></i> Credit Notes
                                        </Button>
                                    </Link>

                                </div>
                            </div>

                            {this.state.showError
                                ? <div>

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p>
                                            <span className="bold-text">
                                                {this.state.statusMessage}
                                            </span>
                                        </p>
                                    </Alert>

                                </div>
                                : null}<br />

                            {this.state.isShowError
                                ? <div>

                                    <Alert color="success" className="alert--colored" icon>
                                        <p>
                                            <span className="bold-text">
                                                {this.state.statusMessage}
                                            </span>
                                        </p>
                                    </Alert>

                                </div>
                                : null}<br />
                            <form >
                                <br /><br />
                                <div className="col-md-8 offset-2">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Customer Name</label>
                                            </div>
                                            <div className="col-md-10">
                                                <Link className='Links' to='/customer-create'>Create New Customer ?</Link>

                                                <Select
                                                    isClearable
                                                    options={this.Customer()}
                                                    onInputChange={this.loadOptions}
                                                    placeholder="Search Customer"
                                                    autosize={true}
                                                    onChange={this.onSelectChangesShort}
                                                    isLoading={this.state.isLoading}
                                                    // noOptionsMessage={() => 'nothing found'}
                                                    // loadingMessage={() => 'searching...'}   //minor type-O here
                                                    menuPortalTarget={document.body}
                                                    name="namffe"
                                                /><br />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-8 offset-2">
                                    <br />
                                    <Form.Group className="Focus-line">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <Form.Label>Credit Note date</Form.Label>
                                            </div>
                                            <div className="col-md-10 customDatePickerWidth">
                                                <DatePicker
                                                    selected={this.state.credit_date}
                                                    label="DatePicker Label"
                                                    dateFormat="dd/MM/yyyy"
                                                    required

                                                    value={editedInvoice.credit_date}
                                                    className="form-control"
                                                    id="input"
                                                    onChange={value =>
                                                        this.setState({ credit_date: value })}
                                                />

                                            </div>
                                        </div>
                                    </Form.Group>
                                </div>



                                <div className="col-md-12">


                                    <div className="col-md-12">
                                        <br />
                                        <div className="col-md-6" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>

                                            {/* <RadioGroup onChange={this.onChangeOption} value={this.state.tax_exclusive} horizontal>
                                                <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                                    Tax Exclusive
                                                    </RadioButton>
                                                <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                                    Tax Inclusive
                                                   </RadioButton>
                                            </RadioGroup> */}
                                        </div>
                                        <br />
                                        <LineItems
                                            items={this.state.lineItems}
                                            currencyFormatter={this.formatCurrency}
                                            showtax={this.state.showtax}
                                            addHandler={this.handleAddLineItem}
                                            addNew={this.state.addnew}

                                            changeHandler={this.handleLineItemChange}
                                            focusHandler={this.handleFocusSelect}
                                            deleteHandler={this.handleRemoveLineItem}
                                            reorderHandler={this.handleReorderLineItems}
                                        />


                                        <div className="col-md-12">

                                            <div className={styles.totalContainer}>

                                                <form></form>
                                                <form>
                                                    <div className={styles.valueTable}>
                                                        <div className={styles.row}>
                                                            <div className={styles.label}>Subtotal</div>
                                                            <div
                                                                className={`${styles.value} ${styles.currency}`}
                                                            >
                                                                {this.formatCurrency(
                                                                    this.calcLineItemsTotal()
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.row}>
                                                            <div className={styles.label}>Discount</div>
                                                            <div
                                                                className={`${styles.value} ${styles.currency}`}
                                                            >
                                                                <input
                                                                    id="input"
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="discount_amount"
                                                                    placeholder="Discount Amount"
                                                                    value={this.state.discount_amount}
                                                                    onChange={this.handleChangeDisco}
                                                                />
                                                                {/* {this.formatCurrency(
                                
                              )} */}
                                                            </div>
                                                        </div>

                                                        <div className={styles.row}>
                                                            <div className={styles.label}>
                                                                Tax
                                                            </div>
                                                            <div
                                                                className={`${styles.value} ${styles.currency}`}
                                                            >
                                                                {this.formatCurrency(this.calcTaxTotal())}
                                                            </div>
                                                        </div>
                                                        <div className={styles.row}>
                                                            <div className={styles.label}>Total Due</div>
                                                            <div
                                                                className={`${styles.value} ${styles.currency}`}
                                                            >
                                                                {this.formatCurrency(this.calcGrandTotal())}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 offset-2">
                                    <br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">

                                                <label className="form-label">
                                                    Customer Notes

                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <textarea
                                                    name="invoice_message"

                                                    style={{
                                                        paddingTop: '20px',
                                                        borderRadius: '5px',
                                                        borderColor: 'hsl(0,0%,80%)',
                                                    }}
                                                    onChange={this.handleChangeInvoiceMessage}
                                                    className="col-md-12"
                                                    value={this.state.invoice_message}
                                                    placeholder="Hint: This will show up on the invoice"
                                                    id="message"
                                                    cols="col-md-1"
                                                    rows="1"
                                                />
                                            </div>

                                            <div className="col-md-2">
                                                <ul id="sms-counter">
                                                    <li>Length: <span class="length" /></li>
                                                    {/* <li>SMS: <span class="messages"></span></li> */}
                                                    <li>
                                                        Message:
                                                        {' '}
                                                        <span class="remaining" />
                                                        /
                                                        <span class="per_message" />
                                                    </li>
                                                    {/* <li>Remaining: </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>

                                <div className="col-md-8 offset-2">
                                    <br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">

                                                <label className="form-label">
                                                    Terms & Conditions

                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <textarea
                                                    name="statement_message"

                                                    style={{
                                                        paddingTop: '20px',
                                                        borderRadius: '5px',
                                                        borderColor: 'hsl(0,0%,80%)',
                                                    }}
                                                    onChange={this.handleChangeStatementMessage}
                                                    className="col-md-12"
                                                    placeholder="Hint: Enter terms and condition for your business to be display on your transaction"
                                                    id="messages"
                                                    cols="col-md-1"
                                                    rows="1"
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <ul id="sms-counters">
                                                    <li>Length: <span class="length" /></li>
                                                    {/* <li>SMS: <span class="messages"></span></li> */}
                                                    <li>
                                                        Message:
                                                        {' '}
                                                        <span class="remaining" />
                                                        /
                                                        <span class="per_message" />
                                                    </li>
                                                    {/* <li>Remaining: </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />

                                <div className="col-md-12"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <Link to="/credit-note">
                                                    <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                                        Cancel
                                                    </Button>
                                                </Link>

                                            </div>
                                            <div className="col-md-4">
                                                <Button type="button"
                                                    onClick={
                                                        () => { this.handleAddLineSubmit(2) }
                                                    } color="primary" outline className="btn-paypal btn-sm pull-right" >
                                                    {this.state.isLoading ? "Please Wait..." : "Save as Draft"}

                                                </Button> &nbsp;&nbsp;&nbsp;
                                            </div>
                                            <div className="col-md-4">
                                                <Button type="button" color="primary" onClick={() => { this.handleAddLineSubmit(1) }} outline className="btn-paypal btn-sm pull-right" >
                                                    {this.state.isLoading ? "Please Wait..." : "Save and Send"}

                                                </Button> &nbsp;&nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <br />

                        </CardBody>
                    </Card>
                </div>
            </div >
        );
    };
}

export default CreditNote;