import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { BRANCH_ID, CONFIG, CURRENCY, EMAIL_ADDRESS, INVOICE_APPROVAL, LOADER, PROFILE_URL, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import { saveEstimateview } from './pdfviewestiamte';
import { saveEstimatePDF } from './PDFServiceEstimate';
import Estimates from './estimates';
import ESTIMATE_EDIT from './estimate_edit';
import imageToBase64 from 'image-to-base64/browser';
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";
import Loader from "react-loader-spinner";
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'


export class Estimate_View extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this.applyCallback = this.applyCallback.bind(this);
        this.getDataUpdate = this.getDataUpdate.bind(this);

        this.toggleModalMore = this.toggleModalMore.bind(this);

        this.submitsend = this.submitsend.bind(this);

        this.submitcreated = this.submitcreated.bind(this);


        this.submitcreatedclose = this.submitcreatedclose.bind(this);


        this.columns = [
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "nrw_invoice_no",
                TrOnlyClassName: 'tsc',
                text: "Quotation#",
                className: "tsc",
                align: "left"
            },
            {
                key: "customer_display_name",
                TrOnlyClassName: 'tsc',
                text: "Company Name",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "status",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.status === 0 ?
                                <div>
                                    {record.due_period < 0 ?
                                        <span class="badge-info" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Due in {record.due_period * -1} days
                                        </span>
                                        : null}

                                    {record.due_period > 0 ?
                                        <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Over Due by {record.due_period} days
                                        </span>
                                        : null}
                                    {record.due_period === 0 ?

                                        <span span class="badge-info" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Due Today
                                        </span>
                                        : null}
                                </div>
                                : null}
                            {record.status === 1 ?
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Paid
                                </span>
                                : null}



                        </Fragment >
                    );
                }
            },

            {
                key: "apprvoed",
                TrOnlyClassName: 'tsc',
                text: "Approved",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.approved == 1 ?
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                    YES
                                </span>
                                : null
                                // <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                //     NO
                                // </span>

                            }
                            {record.approved == 0 ?
                                <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                    NO
                                </span>
                                : null

                            }
                            {record.approved == 2 ?
                                <span class="badge-orange" style={{ borderRadius: "5px", padding: "2px" }}>
                                    DRAFT
                                </span>
                                : null

                            }
                        </Fragment >
                    );
                }
            },
            {
                key: "dates1",
                TrOnlyClassName: 'tsc',
                text: "Due Date",
                className: "tsc",
                align: "left"
            },

            {
                key: "amount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "due_amount",
                TrOnlyClassName: 'tsc',
                text: "Due Amount",
                className: "tsc",
                align: "left"
            },

            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-success  btn-sm"
                                title="View"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.toggleModalMore(record) }} >
                                More <i className="fa fa-plus"></i>
                            </button>

                        </Fragment>
                    );
                }
            },
            // {
            //     key: "action",
            //     text: "Options",
            //     TrOnlyClassName: 'cell',
            //     className: "cell",
            //     width: 250,
            //     sortable: false,
            //     cell: record => {
            //         return (
            //             <Fragment className="center" >



            //             </Fragment>
            //         );
            //     }
            // }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [100, 200, 500],
            show_filter: false,
            show_pagination: true,
            button: {
                excel: false,
                csv: true
            },
            filename: "quatation",
            pagination: 'advance',
            page_size: 100,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Invoice no...",
                no_data_text: "There is no any quotation today",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        let now = new Date();
        let start = moment(new Date(new Date().getFullYear(), 0, 1));
        let end = moment(new Date());

        this.state = {
            admins: [],
            isLoading: true,
            showComponent: false,
            onEdit: false,
            estimate_id: "",
            items: [],
            returnHome: false,
            start: start,
            isOpenMore: false,
            end: end,
            loaded: true,
            isPageLoad: true,
            _onShow: false,
            hideComponent: false,
            data: [],
        };
    }
    async handleOnClick1(record) {
        console.log("hoge!");
        if (await confirm(`Are you sure you want to convert Quotation#  ${record.nrw_invoice_no} to Invoice?`)) {
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": TOKEN
                }
            };
            let formData = {
                invoice_no: record.id,
                estimate_no: record.nrw_invoice_no,
                created_by: USER_ID
            };

            this.setState({
                convert_invoice: true
            })
            let url = baseURL + `convertestimate`
            axios.all([
                axios.put(url, formData, axiosConfig),
            ]).then(axios.spread((response) => {
                if (response.data.status) {
                    this.setState({
                        statusMessage: response.data.message,
                        isShowError: true,
                        isLoading: false,
                        convert_invoice: false,
                        showError: false
                    });
                    window.setTimeout(() => {
                        this.setState({ isShowError: false });
                        this.closeModalMore()
                        this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                    }, 2000);
                    window.scrollTo(0, 0);
                } else {
                    this.setState({
                        statusMessage: response.data.message,
                        showError: true,
                        isShowError: false,
                        isLoading: false,
                    });
                    window.scrollTo(0, 0);
                }
            })).catch(error => {
                console.log('bayoo', error.response)

            })
            // this.onEditInvoice(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }
    async handleOnClick(record) {
        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })

        this.closeModalMore();

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        this.setState({
            returnHome: true
        })
        let url = baseURL + `invoiceitem?filter_value=` + `${"Q" + record.estimate_no}` + `&client_id=` + USER_ID

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            console.log("BUGggg", invoiceResponse)
            this.setState({
                lineItems: invoiceResponse.data.data,
                returnHome: false
            })
            this.state.editedInvoice = {
                invoice_no: record.nrw_invoice_no,
                invoice_date: record.invoice_date,
                terms: record.terms,
                image_url: this.state.image_url,
                due_date: record.due_date,
                message_invoice: record.message_invoice,
                statement_invoice: record.statement_invoice,
                customer_name: record.customer_display_name,
                customer_email: record.email,
                amount: record.due_amount,
                due_amount: record.due_amount,
                tax_exclusive: record.tax_exclusive,
                lineItems: this.state.lineItems,
                tax_amount: record.tax_amount,
                due_period: record.due_period,
                status: record.status,
                discount_amount: record.discount_amount,
                currency: CURRENCY,
                tax: record.tax_amount,
                subtoal: record.sub_total,
                total: record.due_amount,
            };

            saveEstimateview(Object.assign(footer, this.state.editedInvoice));
        })).catch(error => {
            console.log('bayoo', error.response)

        })

        // console.log("hoge!");
        // if (await confirm("Y")) {
        // this._onButtonClick2(record)
        // } else {
        //     this.setState({
        //         message: "No!"
        //     });
        // }
    }
    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {
        // this.getEmergencyFoundImg()
        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = `${PROFILE_URL}?canvas=true`;
        }
        this.setState({
            startDate: moment(new Date()).format('DD MMM, YYYY'),
            endDate: moment(new Date()).format('DD MMM, YYYY'),
            isPageLoad: true,
            valuedate: "This Year"
        })

        this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))

    }



    getData = (queryString = "", startDate, endDate) => {


        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };

        let url = baseURL + `invoice?branch_id=${BRANCH_ID}&&sales_order_no=0&&estimate=1&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"&${queryString}`


        console.log("test_test ", url)

        // alert(url)
        axios.all([
            axios.get(url, axiosConfig),


        ]).then(axios.spread((invoiceResponse) => {
            console.log("BUGggg", invoiceResponse)
            this.setState({
                invoice: invoiceResponse.data.data,
            },
                function () {
                    var data = [];
                    this.setState({
                        total: invoiceResponse.data.total
                    })
                    let datas = JSON.parse(localStorage.getItem("ESTIMATE_CREATEPEAK"))
                    var data3 = [];
                    if (invoiceResponse.data.data.length > 0 && datas != null) {

                        console.log("invoiceResponse.data.data", invoiceResponse.data.data)
                        let datasinvoice = JSON.parse(localStorage.getItem("ESTIMATE_SENDEMAIL"))
                        let innvoiceNos = { invoice_no: "Q" + invoiceResponse.data.data[0].estimate_no };


                        // if (localStorage.getItem("approved_draft") == 1) {
                        // } else {
                        //     this.setState({
                        //         isOpen: false
                        //     })
                        // }

                        if (invoiceResponse.data.data[0].approved == 1) {
                            this.setState({
                                isOpen: false
                            })
                        } else {
                            this.setState({
                                isOpen: false
                            })
                        }


                        for (let i = 0; i < datas.length; i++) {
                            let innvoiceNo = { invoice_no: "Q" + invoiceResponse.data.data[0].estimate_no };
                            let client_id = { client_id: USER_ID };
                            data3.push(Object.assign(innvoiceNo, client_id, datas[i]));
                            this.setState({
                                data3: data3,
                                specialitems: data3,
                                items: [...this.state.items, invoiceResponse.data.data[0].email, EMAIL_ADDRESS],
                                loaded: false
                            });
                        }
                        axios
                            .post(baseURL_2 + 'insight.php', data3)
                            .then(response => {
                                localStorage.setItem("ESTIMATE_CREATEPEAK", null)
                                console.log("bayo", response)
                                this.setState({
                                    isLoading: false,
                                    loaded: false,
                                })
                            })
                            .catch(error => {
                                this.setState({
                                    statusMessage: error.response === undefined
                                        ? (window.location.href = '/log_in')
                                        : 'Something went wrong',
                                    showError: true,
                                    isShowError: false,
                                    loaded: false,
                                    isLoading: false,
                                });
                                window.scrollTo(0, 0);
                            });
                        for (let i = 0; i < this.state.invoice.length; i++) {
                            let due_date = moment(this.state.invoice[i].due_date);
                            let cur_date = moment();
                            // let curDateFormated = cur_date.format('DD MMM, YYYY');
                            let dueDateFormated = due_date.format('DD/MM/YYYY');
                            const diff = cur_date.diff(due_date, 'days');
                            // let mperiod = { due_period: diff }
                            let date1 = { dates1: dueDateFormated };
                            let new_quotation = { new_quotation: "Q" + this.state.invoice[i].estimate_no };

                            let mperiod = { due_period: this.state.invoice[i].period }

                            let invoice_no = { nrw_invoice_no: "Q" + this.state.invoice[i].estimate_no }

                            let innvoiceNos_Estimate = { invoice_no_estimate: invoiceResponse.data.data[i].estimate_no };




                            let date = { dates: (moment.utc(this.state.invoice[i].invoice_date).format('DD/MM/YYYY')) };
                            let date_modified = { date_modified2: (moment(this.state.invoice[i].date_modified).format('DD/MM/YYYY HH:mm')) };

                            data.push(Object.assign(date1, innvoiceNos_Estimate, date_modified, invoice_no, new_quotation, date, mperiod, this.state.invoice[i]));
                            this.setState({
                                data: data,
                                loaded: false,

                            })
                        }
                    } else {


                        if (this.state.invoice.length > 0) {
                            for (let i = 0; i < this.state.invoice.length; i++) {
                                let due_date = moment(this.state.invoice[i].due_date);
                                let cur_date = moment();
                                // let curDateFormated = cur_date.format('DD MMM, YYYY');
                                let dueDateFormated = due_date.format('DD/MM/YYYY');
                                const diff = cur_date.diff(due_date, 'days');
                                // let mperiod = { due_period: diff }
                                let date1 = { dates1: dueDateFormated };
                                let mperiod = { due_period: this.state.invoice[i].period }
                                let new_quotation = { new_quotation: "Q" + this.state.invoice[i].estimate_no };

                                let date = { dates: (moment.utc(this.state.invoice[i].invoice_date).format('DD/MM/YYYY')) };
                                let date_modified = { date_modified2: (moment(this.state.invoice[i].date_modified).format('DD/MM/YYYY HH:mm')) };
                                let invoice_no = { nrw_invoice_no: "Q" + this.state.invoice[i].estimate_no }
                                let invoice_amount = this.state.invoice[i].amount.slice(4)

                                let innvoiceNos_Estimate = { invoice_no_estimate: invoiceResponse.data.data[i].estimate_no };

                                // let amount_cahs = { new_amount: CURRENCY +" "+  (invoice_amount.replaceAll(",","") * CURRENCY_AGAINST_KE).toFixed(2) }

                                data.push(Object.assign(date1, invoice_no,
                                    innvoiceNos_Estimate, date, new_quotation, date_modified, mperiod, this.state.invoice[i]));
                                this.setState({
                                    data: data,
                                    loaded: false,
                                    isLoading: false
                                })

                            }
                        } else {
                            this.setState({
                                data: data,
                                loaded: false,
                                isLoading: false
                            })
                        }

                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }






    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).add(1, "day").format('YYYY-MM-DD'))

        // this.getData(queryString, localStorage.getItem("STARTDATE"), localStorage.getItem("ENDDATE"))

    }
    getDataUpdate() {
        let queryString = ("filter_value=&page_number=0&page_size=10&sort_order=false")

        this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
    }
    applyCallback(startDate, endDate) {
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)
        let Sdate1 = moment(startDate).format('DD MMM, YYYY');
        let Edate2 = moment(endDate).format('DD MMM, YYYY');
        this.setState({
            valuedate: Sdate1 + " " + Edate2,
            startDate: Sdate1,
            isPageLoad: true,
            endDate: Edate2
        })

        this.getData("", moment(startDate).format('YYYY-MM-DD'), moment(endDate).add(1, "day").format('YYYY-MM-DD'));
    }



    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            onEdit: false,
            showModal: false,
            showModals: false
        });
    }

    onEditInvoice(record) {
        localStorage.setItem("Invoice_No_Item", record.nrw_invoice_no)

        localStorage.setItem("Invoice_No_Item_EStimate", record.invoice_no_estimate)

        window.location.href = "edit-estimates";
        this.setState({
            showComponent: false,
            hideComponent: true,
            onEdit: true,
            estimate_id: record.invoice_no_estimate,
            showModal: false,
            showModals: false
        });
    }


    _onButtonClick2(record) {

        var byteCharacters = atob(record.pdf);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    onDelete(record) {

        // alert(record.id)

        axios.delete(baseURL + `invoice?id=` + record.id + `&filter_value=` + record.nrw_invoice_no + `&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData("", moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
                        this.closeModalMore();
                        this.setState({ isShowError: false })
                    }, 3000);
                });
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData("", moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
                    this.setState({ isShowError: false })
                }, 4000);
            }
        }, error => {
            // alert(JSON.stringify(error))
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
                this.setState({ isShowError: false })
            }, 4000);
        });
    }

    _onShow(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            onEdit: false,
            acceptable_answers: record.acceptable_answers,
            answer: record.answer,
            answer_id: record.id,
        });
    }


    toggleModalMore(record) {
        this.setState({
            isOpenMore: true,
            moreopen: record
        });

    };

    closeModalMore = e => {
        this.setState({
            isOpenMore: false,
        });
    };
    rowClickedHandler = (event, data, rowIndex) => {
        this.toggleModalMore(data)
        // alert(JSON.stringify(data))
        // console.log("event", event);
        // console.log("row data", data);
        // console.log("row index", rowIndex);
    }


    // submitsend(specialitems) {
    //     // alert(specialitems.estimate_no);
    //     let axiosConfig = {
    //         headers: {
    //             'Content-Type': 'application/json;charset=UTF-8',
    //             "Access-Control-Allow-Origin": "*",
    //             "Authorization": TOKEN
    //         }
    //     };
    //     this.setState({
    //         submit_emails: true
    //     })
    //     let url = baseURL + `invoiceitem?filter_value=` + `${"Q" + specialitems.estimate_no}` + `&client_id=` + USER_ID
    //     axios.all([
    //         axios.get(url, axiosConfig),
    //     ]).then(axios.spread((invoiceResponse) => {
    //         console.log("BUGggg", invoiceResponse)
    //         this.setState({
    //             lineItems: invoiceResponse.data.data,
    //             submit_emails: false
    //         })

    //         this.state.editedInvoice = {
    //             invoice_no: "Q" + specialitems.estimate_no,
    //             invoice_date: specialitems.invoice_date,
    //             terms: specialitems.terms,
    //             image_url: this.state.image_url,
    //             due_date: specialitems.due_date,
    //             message_invoice: specialitems.message_invoice,
    //             statement_invoice: specialitems.statement_invoice,
    //             customer_name: specialitems.customer_display_name,
    //             customer_email: specialitems.email,
    //             amount: specialitems.due_amount,
    //             invoice_id: specialitems.id,

    //             due_amount: specialitems.due_amount,
    //             tax_exclusive: specialitems.tax_exclusive,
    //             lineItems: this.state.lineItems,
    //             tax_amount: specialitems.tax_amount,
    //             due_period: specialitems.due_period,
    //             status: specialitems.status,
    //             discount_amount: specialitems.discount_amount,
    //             currency: CURRENCY,
    //             tax: specialitems.tax_amount,
    //             subtoal: specialitems.sub_total,
    //             total: specialitems.due_amount,
    //         };


    //         saveEstimatePDF(this.state.editedInvoice);

    //         alert("Success")

    //         this.getData("", moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
    //         this.closeModalMore();
    //         // alert(JSON.stringify(this.state.editedInvoice))

    //     })).catch(error => {
    //         console.log('bayoo', error.response)

    //     })


    // }


    handleKeyDown = evt => {

        // alert(evt.key)
        if (["Enter", " ", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.value.trim();

            if (value && this.isValid(value)) {
                this.setState({
                    items: [...this.state.items, this.state.value],
                    value: ""
                });
            }
        }
    };

    handleChange = evt => {
        this.setState({
            value: evt.target.value,
            error: null
        });
        // alert(evt.target.value)



    };

    handleDelete = item => {
        this.setState({
            items: this.state.items.filter(i => i !== item)
        });
    };

    handlePaste = evt => {
        evt.preventDefault();

        var paste = evt.clipboardData.getData("text");
        var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

        if (emails) {
            var toBeAdded = emails.filter(email => !this.isInList(email));

            this.setState({
                items: [...this.state.items, ...toBeAdded]
            });
        }
    };

    handleEnter = () => {

        var value = this.state.value.trim();

        // alert(value)

        if (value === '') {
            this.setState({
                error: `Atleast one Email is required`
            })
        } else {


            if (value && this.isValid(value)) {
                this.setState({
                    items: [...this.state.items, this.state.value],
                    value: ""
                });
            }
        }
    };

    isValid(email) {
        let error = null;

        // alert(email)

        if (this.isInList(email)) {
            error = `${email} has already been added.`;
        }


        if (!this.isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (error) {
            this.setState({ error });

            return false;
        }

        return true;
    }
    isInList(email) {
        return this.state.items.includes(email);
    }

    isEmail(email) {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    }

    toggleModalCreate(record) {
        this.setState({
            isOpen: true,
            specialitems: record
        });

    };


    toggleModalMore(record) {
        this.setState({
            isOpenMore: true,
            moreopen: record
        });

    };

    toggleModalEmail(record) {
        this.setState({
            isOpenemail: true,
            specialitems: record,
            items: [...this.state.items, record.email, EMAIL_ADDRESS]
        });

    };

    closeModalUpload = e => {
        this.setState({
            isOpen: false,
        });
    };


    closeModalMore = e => {
        this.setState({
            isOpenMore: false,
        });
    };
    closeModalEmail = e => {
        this.setState({
            isOpenemail: false,
            items: []
        });
    };


    async submitsend() {



        // alert("here")


        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })



        // alert(JSON.stringify());
        if (this.state.items.length > 0) {
            let s = this.state.items
            // alert(this.state.items)
            var data_2 = []
            for (var a in s) {
                let name = { email_cc: s[a] };
                data_2.push(Object.assign(name));
            }

            // alert(JSON.stringify(s))
            let email_s = JSON.stringify(this.state.items).replaceAll("[", "")
            let email_2 = email_s.replaceAll("]", "")

            let email_3 = email_2.replaceAll('"', "")
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": TOKEN
                }
            };
            this.setState({
                submit_emails: true,
                isOpenemail: false
            })
            let url = baseURL + `invoiceitem?filter_value=` + `${"Q" + this.state.specialitems.estimate_no}` + `&client_id=` + USER_ID
            axios.all([
                axios.get(url, axiosConfig),
            ]).then(axios.spread((invoiceResponse) => {
                console.log("BUGggg", invoiceResponse)
                this.setState({
                    lineItems: invoiceResponse.data.data,
                    submit_emails: false
                })

                this.state.editedInvoice = {
                    invoice_no: "Q" + this.state.specialitems.estimate_no,
                    invoice_date: this.state.specialitems.dates,
                    terms: this.state.specialitems.terms,
                    due_date: this.state.specialitems.dates1,
                    message_invoice: this.state.specialitems.message_invoice,
                    statement_invoice: this.state.specialitems.statement_invoice,
                    customer_name: this.state.specialitems.customer_display_name,
                    customer_email: this.state.specialitems.email,
                    customer_email_address: data_2,

                    // customer_email: this.state.specialitems.email,
                    amount: this.state.specialitems.due_amount,
                    tax_exclusive: this.state.specialitems.tax_exclusive,
                    due_amount: this.state.specialitems.due_amount,
                    lineItems: this.state.lineItems,
                    tax_amount: this.state.specialitems.tax_amount,
                    due_period: this.state.specialitems.due_period,
                    status: this.state.specialitems.status,
                    discount_amount: this.state.specialitems.discount_amount,
                    currency: CURRENCY,
                    invoice_id: this.state.specialitems.id,

                    tax: this.state.specialitems.tax_amount,
                    subtoal: this.state.specialitems.sub_total,
                    total: this.state.specialitems.due_amount,
                };

                this.closeModalEmail();


                saveEstimatePDF(Object.assign(footer, this.state.editedInvoice));

                alert("Success")

                this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
                this.closeModalMore();
                // alert(JSON.stringify(this.state.editedInvoice))

            })).catch(error => {
                console.log('bayoo', error.response)

            })
        } else {
            alert("Type or paste email address/addresses ")
        }

    }


    async submitcreated() {
        // alert(JSON.stringify());
        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })

        if (this.state.items.length > 0) {
            if (INVOICE_APPROVAL == 1) {
                let s = this.state.items
                var data_3 = []
                for (var a in s) {
                    let name = { email_cc: s[a] };
                    // let name_2 = { email: this.state.specialitems.email }
                    data_3.push(Object.assign(name));
                }
                // alert(JSON.stringify(data_3))
                let email_s = JSON.stringify(this.state.items).replaceAll("[", "")
                let email_2 = email_s.replaceAll("]", "")

                let email_3 = email_2.replaceAll('"', "")
                let axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": TOKEN
                    }
                };
                this.setState({
                    submit_emails: true
                })

                // alert(JSON.stringify(this.state.specialitems))
                let url = baseURL + `invoiceitem?filter_value=` + `${this.state.specialitems[0].invoice_no}` + `&client_id=` + USER_ID
                axios.all([
                    axios.get(url, axiosConfig),
                ]).then(axios.spread((invoiceResponse) => {
                    console.log("BUGggg", invoiceResponse)
                    this.setState({
                        lineItems: invoiceResponse.data.data,
                        submit_emails: false
                    })

                    this.closeModalUpload();


                    //   alert(JSON.stringify())
                    let datasinvoice = JSON.parse(localStorage.getItem("ESTIMATE_SENDEMAIL"))

                    let innvoiceNo = { invoice_no: this.state.specialitems[0].invoice_no };

                    let customer_email = { customer_email_2: this.state.specialitems.email };

                    let customer_email_address = { customer_email_address: data_3 };
                    // let innvoiceNo = { invoice_no: invoiceResponse.data.data[0].invoice_no };
                    saveEstimatePDF(Object.assign(footer, innvoiceNo, customer_email_address, datasinvoice));

                    // alert(JSON.stringify(Object.assign(innvoiceNo, customer_email_address, datasinvoice)))

                    this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
                    // saveInvoicePDF(this.state.editedInvoice);
                    alert("Success!!")





                })).catch(error => {
                    console.log('bayoo', error.response)

                })

            }
            else {
                alert("You do not have permission to send invoices");
                this.closeModalUpload();
            }

        } else {
            alert("Type or paste email address/addresses")
        }



        // this.submitcreated()
    }



    async submitcreatedclose() {
        // alert(JSON.stringify());
        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })


        if (INVOICE_APPROVAL == 1) {

            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": TOKEN
                }
            };
            let url = baseURL + `invoiceitem?filter_value=` + `${"Q" + this.state.specialitems.estimate_no}` + `&client_id=` + USER_ID
            axios.all([
                axios.get(url, axiosConfig),
            ]).then(axios.spread((invoiceResponse) => {
                console.log("BUGggg", invoiceResponse)
                this.setState({
                    lineItems: invoiceResponse.data.data,
                    submit_emails: false
                })

                this.state.editedInvoice = {
                    invoice_date: this.state.specialitems.dates,
                    terms: this.state.specialitems.terms,
                    due_date: this.state.specialitems.dates1,
                    message_invoice: this.state.specialitems.message_invoice,
                    statement_invoice: this.state.specialitems.statement_invoice,

                    customer_name: this.state.specialitems.customer_display_name,
                    customer_primary_email: this.state.specialitems.email,
                    amount: this.state.specialitems.due_amount,
                    tax_exclusive: this.state.specialitems.tax_exclusive,
                    due_amount: this.state.specialitems.due_amount,
                    lineItems: this.state.lineItems,
                    tax_amount: this.state.specialitems.tax_amount,
                    due_period: this.state.specialitems.due_period,
                    status: this.state.specialitems.status,
                    discount_amount: this.state.specialitems.discount_amount,
                    currency: CURRENCY,
                    invoice_id: this.state.specialitems.id,
                    tax: this.state.specialitems.tax_amount,
                    subtoal: this.state.specialitems.sub_total,
                    total: this.state.specialitems.due_amount,
                };


                let datasinvoice = JSON.parse(localStorage.getItem("INVOICE_SENDEMAIL"))

                // alert("Success!!")

                let innvoiceNo = { invoice_no: this.state.specialitems[0].invoice_no };
                let customer_email_address = { customer_email_address: datasinvoice.customer_email };
                // let innvoiceNo = { invoice_no: invoiceResponse.data.data[0].invoice_no };
                saveEstimatePDF(Object.assign(footer, innvoiceNo, customer_email_address, datasinvoice));

                // alert(JSON.stringify(datasinvoice));

                // alert(JSON.stringify(Object.assign(innvoiceNo, customer_email_address, datasinvoice)));
                // saveInvoicePDF(this.state.editedInvoice);

                this.closeModalUpload();



            })).catch(error => {
                console.log('bayoo', error.response)

            })

        }
        else {
            alert("You do not have permission to approve invoices");
            this.closeModalUpload();
        }

        // this.submitcreated()
    }




    render() {
        const { showComponent, onEdit } = this.state;
        const { hideComponent, returnHome } = this.state;


        let ranges = {
            "Today Only": [moment(this.state.start), moment(this.state.end)],
            "Yesterday Only": [
                moment(this.state.start).subtract(1, "days"),
                moment(this.state.end).subtract(1, "days")
            ],
            "3 Days": [moment(this.state.start).subtract(3, "days"), moment(this.state.end)],
            "5 Days": [moment(this.state.start).subtract(5, "days"), moment(this.state.end)],
            "1 Week": [moment(this.state.start).subtract(7, "days"), moment(this.state.end)],
            "2 Weeks": [moment(this.state.start).subtract(14, "days"), moment(this.state.end)],
            "1 Month": [moment(this.state.start).subtract(1, "months"), moment(this.state.end)],
            "1 Year": [moment(this.state.start).subtract(1, "years"), moment(this.state.end)]
        };
        let local = {
            "format": "DD-MM-YYYY",
            "sundayFirst": false
        }


        return (
            this.state.loaded == false ?

                <div style={{ marginTop: "-20px" }} >



                    <Modal
                        isOpen={this.state.isOpenMore}
                        onRequestClose={e => this.closeModalMore(e)}
                        contentLabel="My dialog"
                        className="mymodal"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        <MDBCloseIcon onClick={this.closeModalMore} />
                        <h4><b>Quotation# {this.state.moreopen == undefined ? null : this.state.moreopen.estimate_no}</b></h4><br />

                        {this.state.showError ? <div >

                            <Alert color="danger" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>
                        </div> : null}
                        {this.state.isShowError ? (
                            <div>

                                <Alert color="success" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>
                            </div>
                        ) : null}

                        <div>
                            {/* Type or paste email addresses and press `Add`/`Enter` to add more <br /> */}
                            <button className="btn btn-primary  btn-sm"
                                title="View "
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick(this.state.moreopen) }} >
                                View Quotation
                            </button>
                            <br />
                            <br />

                            <button className="btn btn-primary  btn-sm"
                                title="convert"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick1(this.state.moreopen) }} >
                                {this.state.convert_invoice ? "Please Wait..." : "Convert to Invoice"}
                            </button>
                            <br />
                            <br />

                            {INVOICE_APPROVAL == 1 ?
                                <button className="btn btn-primary  btn-sm"
                                    title="View"
                                    style={
                                        { marginRight: '10px' }}

                                    onClick={
                                        () => {
                                            this.toggleModalEmail(this.state.moreopen)
                                        }
                                    } >

                                    Send Emails
                                </button>
                                : null}
                            <br />
                            <br />
                            <Link to="/edit-estimates">
                                <button className="btn btn-primary btn-sm"
                                    title="Edit Invoice"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.onEditInvoice(this.state.moreopen) }} >
                                    Edit Quotation
                                </button>
                            </Link>
                            <br />
                            <br />
                            <button className="btn btn-danger  btn-sm"
                                title="Delete"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => {
                                        if (window.confirm('Are you sure you want to delete this Quatation# Q' + this.state.moreopen.estimate_no + "?")) this.onDelete(this.state.moreopen)
                                    }
                                } >

                                Delete Quotation
                            </button>

                            <br />
                            <br />

                        </div>

                    </Modal>

                    <Modal
                        isOpen={this.state.isOpen}
                        onRequestClose={false}
                        contentLabel="My dialog"
                        className="mymodal"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        {/* <MDBCloseIcon onClick={this.submitcreatedclose} /> */}
                        <h4><b>CC/BCC Email Addresses</b></h4><br />

                        {this.state.showError ? <div >

                            <Alert color="danger" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>


                        </div> : null}

                        {this.state.isShowError ? (
                            <div>

                                <Alert color="success" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>
                            </div>
                        ) : null}

                        <div>
                            Type or paste email addresses and press `Add`/`Enter` to add more <br />
                            {this.state.items.map(item => (
                                <div className="tag-item" key={item}>
                                    {item}

                                    <button
                                        type="button"
                                        className="button"
                                        onClick={() => this.handleDelete(item)}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                            <input
                                className={"input " + (this.state.error && " has-error")}
                                value={this.state.value}
                                placeholder="Type or paste email addresses and press `Enter`..."
                                onKeyDown={this.handleKeyDown}
                                onChange={this.handleChange}
                                onPaste={this.handlePaste}
                            />
                            {this.state.error && <p className="error">{this.state.error}</p>}
                            <br />
                            <div className="float-right" style={{ marginTop: '10px' }}>
                                <button className="btn btn-success  btn-sm"
                                    title="View"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleEnter() }}
                                >
                                    Add <i className="fa fa-plus"></i>
                                </button>
                            </div>
                            <br /><br />

                            <div className="row" style={{ marginTop: '20px' }}>

                                <div className="col-6  ">
                                    {/* <button className="btn btn-danger  btn-sm"
                                        title="View"
                                        style={
                                            { marginRight: '10px' }}
                                        onClick={
                                            () => { this.submitcreatedclose() }}

                                    >
                                        Close
                                </button> */}
                                </div>

                                <div className="col-6">
                                    <div className="float-right" >

                                        <button className="btn btn-primary  btn-sm"
                                            title="View"
                                            onClick={
                                                () => { this.submitcreated() }}

                                        >
                                            {this.state.submit_emails ? "Please Wait..." : "Send Email"}
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </Modal>


                    <Modal
                        isOpen={this.state.isOpenemail}
                        onRequestClose={e => this.closeModalEmail(e)}
                        contentLabel="My dialog"
                        className="mymodal"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        <MDBCloseIcon onClick={this.closeModalEmail} />
                        <h4><b>Add CC/Bcc Addresses</b></h4><br />

                        {this.state.showError ? <div >

                            <Alert color="danger" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>


                        </div> : null}

                        {this.state.isShowError ? (
                            <div>

                                <Alert color="success" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>
                            </div>
                        ) : null}

                        <div>
                            Type or paste email addresses and press `Add`/`Enter` to add more <br />


                            {

                                this.state.items.map(item => (
                                    <div className="tag-item" key={item}>
                                        {item}
                                        <button
                                            type="button"
                                            className="button"
                                            onClick={() => this.handleDelete(item)}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}




                            <input
                                className={"input " + (this.state.error && " has-error")}
                                value={this.state.value}
                                placeholder="Type or paste email addresses and press `Enter`..."
                                onKeyDown={this.handleKeyDown}
                                onChange={this.handleChange}
                                onPaste={this.handlePaste}
                            />


                            {this.state.error && <p className="error">{this.state.error}</p>}

                            <br />

                            <div className="float-right" style={{ marginTop: '10px' }}>
                                <button className="btn btn-success  btn-sm"
                                    title="View"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleEnter() }}
                                >
                                    Add <i className="fa fa-plus"></i>
                                </button>
                            </div>
                            <br /><br /><br />


                            <div className="col-6 offset-3">
                                <button className="btn btn-primary  btn-sm"
                                    title="View"
                                    onClick={
                                        () => { this.submitsend() }}
                                >
                                    {this.state.submit_emails ? "Please Wait..." : "Send Quotation"}
                                </button>
                            </div>
                        </div>


                    </Modal>


                    {!hideComponent && (
                        <div>


                            {returnHome ? "Redirecting please wait..." :

                                <CardBody >
                                    <div className="panel-body" >
                                        <div className="row">

                                            <div className="col-md-6">
                                                <h4><b>List of Quotations</b></h4><br />
                                            </div>
                                            <div className="col-md-6">
                                                <Link to="/create-estimates">
                                                    <Button className="pull-right btn-sm" color="primary
                                                    " onClick={this._onButtonClick} outline>Create Quotations   <i className="fa fa-plus"></i>
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="col-md-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <DateTimeRangeContainer
                                                                ranges={ranges}
                                                                start={moment(new Date(new Date().getFullYear(), 0, 1))}
                                                                end={this.state.end}
                                                                local={local}
                                                                applyCallback={this.applyCallback}>
                                                                <FormControl
                                                                    id="formControlsTextB"
                                                                    type="text"
                                                                    value={this.state.valuedate}
                                                                    label="Text"
                                                                    placeholder="Filter by Date"
                                                                />
                                                            </DateTimeRangeContainer>
                                                        </div>

                                                        <div className="col-6 float-left">

                                                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {
                                                                // this.getData();
                                                                this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))

                                                                this.setState({
                                                                    startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
                                                                    endDate: moment(new Date()).format('DD MMM, YYYY'),
                                                                    isPageLoad: true,
                                                                    valuedate: "This Year"
                                                                })
                                                            }} >Clear</button>
                                                            {/* <input id="input" type="text" readOnly /> */}
                                                        </div>
                                                    </div>
                                                </div><br />
                                            </div>
                                            <div className="col-md-6">
                                                <h4>Total Quotations are now at: <b>{this.state.total}</b></h4>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="col-md-10 offset-1">
                                                    <button className="pull-right" title="refresh"
                                                        style={{ background: "white", border: "0px" }} onClick={this.getDataUpdate}>
                                                        <RefreshIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        {this.state.showError ? <div >

                                            <Alert color="danger" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>


                                        </div> : null}

                                        {this.state.isShowError ? (
                                            <div>

                                                <Alert color="success" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>

                                            </div>

                                        ) : null}

                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            onRowClicked={this.rowClickedHandler}

                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />

                                    </div>
                                </CardBody>
                            }
                        </div>
                    )
                    }
                    {
                        showComponent && (
                            <div>
                                <Estimates />
                            </div>
                        )
                    }
                    {/* {
                        onEdit && (
                            // eslint-disable-next-line 
                            <ESTIMATE_EDIT />
                        )
                    } */}
                </div >
                :
                <div className="loader">
                    {LOADER}
                </div>
        )
    }
}


// import React from "react";
// import Modal from 'react-modal';
// import "./style.css";

// // import { facebookConfig, googleConfig } from "social-config";

// export class invoice_view extends React.Component {

//     constructor(props) {
//         super(props);
//         // this.toggleModal = this.toggleModal.bind(this);

//         this.state = {
//             isOpen: false,
//             error: null
//         };
//     }

//     toggleModal = e => {
//         this.setState({
//             isOpen: true
//         });
//     };

//     closeModal = e => {
//         this.setState({
//             isOpen: false
//         });
//     };
//     render() {
//         return (
//             <div>
//                 <button >Open Modal</button>
//                 <Modal
//                     isOpen={this.state.isOpen}
//                     onRequestClose={e => {
//                         this.closeModal(e);
//                     }}
//                     contentLabel="My dialog"
//                     className="mymodal"
//                     overlayClassName="myoverlay"
//                     closeTimeoutMS={500}
//                   >
//                     <div>My modal dialog.</div>
//                     <h2 >Hello</h2>
//                       <button onClick={this.closeModal}>close</button>
//                       <div>I am a modal</div>
//                       <form>
//                         <input />
//                         <button>tab navigation</button>
//                         <button>stays</button>
//                         <button>inside</button>
//                         <button>the modal</button>
//                       </form>
//                     <button >Close modal</button>
//                 </Modal>
//             </div>
//         );
//     }
// }