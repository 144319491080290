


export const othercurrentlibiality = [

    {
        label: 'Employee Reimbursements',
        value: 'Employee Reimbursements',
    },
    {
        label: 'Opening balance offset',
        value: 'Opening balance offset',
    },

];

export const optionLength = [
    { value: 1, label: 'general' },
    {
        value: 2,
        label:
            'Evil is the moment when I lack the strength to be true to the Good that compels me.',
    },
    {
        value: 3,
        label:
            "It is now an easy matter to spell out the ethic of a truth: 'Do all that you can to persevere in that which exceeds your perseverance. Persevere in the interruption. Seize in your being that which has seized and broken you.",
    },
];

export const dogOptions = [
    { id: 1, label: 'Chihuahua' },
    { id: 2, label: 'Bulldog' },
    { id: 3, label: 'Dachshund' },
    { id: 4, label: 'Akita' },
];

// let bigOptions = [];
// for (let i = 0; i < 10000; i++) {
// 	bigOptions = bigOptions.concat(colourOptions);
// }

export const groupedOptions_two = [
    {
        label: 'Cash',
        options: JSON.parse(localStorage.getItem("paidthrough")),
    },
    {
        label: 'Bank',
        options: JSON.parse(localStorage.getItem("bankaccounts")),
    },
    {
        label: 'Credit Card',
        options: JSON.parse(localStorage.getItem("creditaccounts")),
    },

    {
        label: 'Other current liability',
        options: othercurrentlibiality,
    },

];


export const groupedOptions_banks = [
    {
        label: 'Banks',
        options: JSON.parse(localStorage.getItem("bankaccounts")),
    }
]