import React, { useState } from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import { Card, CardBody, Button } from 'reactstrap';
// import { , COMPANY_POSTAL_ADDRESS } from '../../_helpers/exports';

import { BUSINESS_TYPE, ToastTable, successToast, COMPANY_NAME, errorToast, CONFIG, TOKEN, USER_ID, BRANCH_ID, PAYE, TAX_RELIEF, PAYE_ONE, NHIF, formatCurrency, PAYE_DISABILITY, PAYE_ONE_DISABILITY, PAYE_DISABILITY_CALCULATOR, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { Link, useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PostHooks from '../../_helpers/postHooks';
import Select from 'react-select';
import UseFetch from '../../_helpers/useFetch';
import PostHookPdf from '../../_helpers/postHooksPdf';
import * as moment from 'moment';
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";


import DatePicker from 'react-datepicker';
import ReactDatatable from '@mkikets/react-datatable';


const AddStaff = () => {
    const navigate = useNavigate();

    const [gross, setGross] = useState(0);
    const [other_deduction, setOtherDeductions] = useState(0);

    const [id_record, setRecordId] = useState(0);

    const [other_benefits, setOtherBenefits] = useState(0);

    const [nsisf, setNSSF] = useState(0);

    const [employee, setEmployee] = useState([]);

    const [employee_value, setEmployeeValue] = useState("")

    const [check_service, setCheckService] = useState(false)

    const [employee_label, setEmployeeLabel] = useState({ label: "Select Employee", value: "" })

    const [check, setCheck] = useState(0);


    const [check_disability, setDisability] = useState(1);

    const [gohome, setHome] = useState(false);


    const [close, setClose] = useState(false);

    const [employee_check, setCheckEmployee] = useState(1);

    const [period_value, setPeriodValue] = useState("monthly");

    const [year_value, setYearValue] = useState();

    const [month_value, setMonthValue] = useState();


    const [period, setPeriod] = useState([
        {
            label: 'Daily',
            value: 'daily'
        },
        {
            label: 'Weekly',
            value: 'weekly'
        },
        {
            label: 'Monthly',
            value: 'monthly'
        },
        {
            label: 'Yearly',
            value: 'yearly'
        }
    ]);



    const [month_data, setMonth] = React.useState([
        {
            id: 1,
            month: "January"
        },
        {
            id: 2,
            month: "February"
        },
        {
            id: 3,
            month: "March"
        },
        {
            id: 4,
            month: "April"
        },
        {
            id: 5,
            month: "May"
        },
        {
            id: 6,
            month: "June"
        },
        {
            id: 7,
            month: "July"
        },
        {
            id: 8,
            month: "August"
        },
        {
            id: 9,
            month: "September"
        },
        {
            id: 10,
            month: "October"
        },
        {
            id: 11,
            month: "November"
        },
        {
            id: 12,
            month: "December"
        }
    ]
    );


    const [year_data, setYear] = React.useState([
        {
            year: 2020
        },
        {
            year: 2021
        },
        {
            year: 2022
        },
        {
            year: 2023
        },
        {
            year: 2024
        },
        {
            year: 2025
        },
        {
            year: 2026
        }
    ]
    );
    const [total, setTotal] = React.useState();

    const [propss, setPropss] = React.useState({});

    const [load, pdf_res, postRequestPdf] = PostHookPdf(propss); // custom hook

    const [payslip_date, setPaySlipDate] = useState(new Date())
    const [props, setProps] = useState({});
    const [isLoading, res, postRequest] = PostHooks(props); // custom hook

    const [loading, fetch, callback] = UseFetch(`employee_payslip?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&branch_id=${BRANCH_ID}&page_size=10000`); // custom hook

    const [check_create, setCreate] = React.useState(false);

    const [check_year, setCheckYear] = React.useState(false);
    const [check_month, setCheckMonth] = React.useState(false);

    React.useEffect(() => {  // use effect call, detect change and call custom post hook
        if (res != null && res.status) {
            setGross(0);
            setOtherDeductions(0);

            setTimeout(() =>
                setHome(true), 2000
            )

        }
        if (fetch && fetch.data.length > 0) {
            setTotal(fetch.total);

            console.log("fetch", fetch.data)


            let data_set = []

            // alert(JSON.stringify(fetch.data))
            for (let i = 0; i < fetch.data.length; i++) {

                let disable = fetch.data[i].disability



                const gross = fetch.data[i].gross
                const other_benefits = fetch.data[i].other_benefits

                let m_date = {
                    m_date: moment(fetch.data[i].created_on).format("MMM, YYYY"),
                };
                let mgross = { mgross: formatCurrency(gross) };
                let mother_benefits = {
                    mother_benefits: formatCurrency(other_benefits),
                };

                let motherdeduction = {
                    motherdeduction: formatCurrency(fetch.data[i].other_deductions),
                };


                let nsisf = fetch.data[i].nssf

                let mnhif = formatCurrency(NHIF((parseFloat(gross) + parseFloat(other_benefits))))

                mnhif = { mnhif: formatCurrency(NHIF((parseFloat(gross) + parseFloat(other_benefits)))) }
                let mnsisf = { mnsisf: formatCurrency(nsisf) };



                let mpaye;
                let mincometax;
                let minsurance_relief;

                let mincome_before_relief;
                let mtax;
                let mnet_pay;

                let paye_one = PAYE_ONE(other_benefits + gross)

                let tax_relief = TAX_RELIEF



                if (disable === 'true') {


                    let income = (parseFloat(gross) + parseFloat(other_benefits)) > 150000 ?
                        (parseFloat(gross) + parseFloat(other_benefits) - 150000) - parseFloat(nsisf)
                        :
                        0

                    mincometax = {
                        mincometax: formatCurrency(income),
                    };

                    let income_beforerelief = PAYE_DISABILITY_CALCULATOR(((parseFloat(gross) + parseFloat(other_benefits)) - parseFloat(nsisf)), parseFloat(nsisf))


                    mincome_before_relief = { mincome_before_relief: income_beforerelief }

                    mtax = { mtax: formatCurrency(PAYE_ONE_DISABILITY(parseFloat(gross) + parseFloat(other_benefits))) };



                    minsurance_relief = {
                        minsurance_relief: (PAYE_ONE_DISABILITY(parseFloat(gross) + parseFloat(other_benefits)) > 0 ?
                            formatCurrency(
                                NHIF(parseFloat(gross) + parseFloat(other_benefits)) * 0.15) : formatCurrency(0))
                    };





                    mpaye = {
                        mpaye: PAYE_ONE_DISABILITY(parseFloat(gross) + parseFloat(other_benefits)) > 0 ?
                            formatCurrency(
                                PAYE_DISABILITY_CALCULATOR(((parseFloat(gross) + parseFloat(other_benefits)) - parseFloat(nsisf)), parseFloat(nsisf)) - parseFloat(TAX_RELIEF)
                            )
                            : formatCurrency(0)
                    };


                    let net_pay = PAYE_ONE_DISABILITY(parseFloat(gross) + parseFloat(other_benefits)) > 0 ?


                        formatCurrency(

                            (
                                (parseFloat(gross) + parseFloat(other_benefits))
                                +
                                parseFloat(TAX_RELIEF)
                                +
                                (NHIF(parseFloat(gross) + parseFloat(other_benefits)) * 0.15)
                            ) -

                            ((PAYE_DISABILITY_CALCULATOR(((parseFloat(gross) + parseFloat(other_benefits)) - parseFloat(nsisf)),
                                parseFloat(nsisf)))

                                +
                                + parseFloat(nsisf) +
                                NHIF(parseFloat(gross) + parseFloat(other_benefits)))
                        )
                        :

                        formatCurrency(

                            (
                                (parseFloat(gross) + parseFloat(other_benefits))

                            ) -
                            (parseFloat(nsisf) + parseFloat(other_deduction) + NHIF(parseFloat(gross) + parseFloat(other_benefits)))
                        )

                    mnet_pay = {
                        mnet_pay: net_pay
                    };

                } else {


                    mpaye = {
                        mpaye: PAYE_ONE(parseFloat(gross) + parseFloat(other_benefits)) > 0 ?
                            formatCurrency(PAYE(((parseFloat(gross) + parseFloat(other_benefits)) - parseFloat(nsisf)), parseFloat(nsisf))
                                -
                                parseFloat(TAX_RELIEF)
                            ) : formatCurrency(0)
                    };



                    mtax = {
                        mtax: PAYE_ONE(parseFloat(gross) + parseFloat(other_benefits)) > 0 ?
                            formatCurrency(parseFloat(TAX_RELIEF)) : formatCurrency(0)
                    }


                    let income = (parseFloat(gross) + parseFloat(other_benefits)) - parseFloat(nsisf)

                    mincometax = {
                        mincometax: formatCurrency(income),
                    };

                    let income_beforerelief = (PAYE((parseFloat(gross) + parseFloat(other_benefits)) - parseFloat(nsisf), parseFloat(nsisf)))

                    mincome_before_relief = { mincome_before_relief: income_beforerelief }

                    let nhif_incom = PAYE_ONE(parseFloat(gross) + parseFloat(other_benefits)) > 0 ? formatCurrency(
                        NHIF(parseFloat(gross) + parseFloat(other_benefits)) * 0.15) : formatCurrency(0)


                    minsurance_relief = {
                        minsurance_relief: nhif_incom
                    };


                    let net_pay = PAYE_ONE(parseFloat(gross) + parseFloat(other_benefits)) > 0 ?

                        formatCurrency(

                            (
                                (parseFloat(gross) + parseFloat(other_benefits))
                                +
                                parseFloat(TAX_RELIEF)
                                +
                                (NHIF(parseFloat(gross) + parseFloat(other_benefits)) * 0.15)
                            ) -

                            (PAYE(parseFloat(gross) + parseFloat(other_benefits) - parseFloat(nsisf), parseFloat(nsisf))
                                -
                                parseFloat(TAX_RELIEF)

                                + parseFloat(nsisf) + parseFloat(other_deduction) +
                                parseFloat(nsisf) +
                                NHIF(parseFloat(gross) + parseFloat(other_benefits)))
                        )
                        :

                        formatCurrency(

                            (
                                (parseFloat(gross) + parseFloat(other_benefits))

                            ) -
                            (parseFloat(nsisf) + parseFloat(other_deduction) + NHIF(parseFloat(gross) + parseFloat(other_benefits)))
                        )


                    mnet_pay = {
                        mnet_pay: net_pay
                    };

                }


                // let payee = (PAYE((parseFloat(fetch.data[i].gross) + parseFloat(fetch.data[i].other_benefits)) - parseFloat(nsisf), parseFloat(nsisf))) - parseFloat(TAX_RELIEF)


                data_set.push(
                    Object.assign(
                        m_date,
                        mother_benefits,
                        mincometax,
                        minsurance_relief,
                        mnhif,
                        mnsisf,
                        mtax,
                        mincome_before_relief,
                        mnet_pay,
                        mpaye,
                        motherdeduction,
                        mgross,
                        fetch.data[i]
                    )
                );
            }
            setEmployee(data_set)



        }

        // if (check_disability == 1) {
        //     setDisability(0)
        //     // callback()
        //     // setDisability(1)
        // }
        if (employee_check === 1) {
            setCheckEmployee(0)
            // alert(check_service)          
            callback()
        }
        if (props.url === 'staff_expense' && check === 1) {
            postRequest();
            setCheck(0)
        }

        // if (props.url === 'staff_expense' && check === 1) {
        //     postRequest();
        //     setCheck(0)
        // }
    }, [loading, isLoading, employee_check, props, check])



    const config = {
        key_column: "tsc",
        show_filter: false,
        show_pagination: false,
        pagination: 'advance',
        button: {
            excel: false,
            csv: false
        },
        // page_size: 100,
        show_length_menu: false,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here",
            no_data_text: "Oooops no employee found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }

    }





    // deno-lint-ignore require-await
    const saveOtherBenefits = async (e) => {
        e.preventDefault();

        // console.log("submit data", employee_value)


        // let employee_data = JSON.parse(employee_value)
        const formData = {
            other_benefits: other_benefits,
            id: id_record
        }

        const customerProps = { // post body and the url
            url: 'other_benefit',
            body: formData
        }

        setProps(customerProps)

        postRequestPdf(customerProps)


        setTimeout(e = () => {
            callback()
        }, 1000)
        await setCheck(1)

        setClose(false)

    }



    // deno-lint-ignore require-await
    const onSubmit = async (e) => {
        // e.preventDefault();


        // console.log("submit data", employee_value)


        // let employee_data = JSON.parse(employee_value)
        callback()

        for (let i = 0; i < employee.length; i++) {

            console.log("submit data", employee[i].value)

            const formData = {
                gross: employee[i].gross,
                other_deductions: employee[i].other_deductions,
                other_benefits: other_benefits,
                nsisf: employee[i].nssf,
                payslip_date: moment(month_value + "-01-" + year_value).format("YYYY-MM-DD"),
                period: period_value,
                employee_id: employee[i].id
            }

            const customerProps = { // post body and the url
                url: 'staff_expense',
                body: formData
            }

            if (e == 0) {
                const mformData = {
                    id: employee[i].id,
                    company_name: COMPANY_NAME,
                    email: employee[i].email,
                    name: employee[i].name,
                    image: localStorage.getItem("image_urls")
                }
                postRequestPdf(mformData)
            }

            setProps(customerProps)
            await setCheck(1)

        }

    }


    const toggleServiceChange = () => {
        setCheckService(!check_service)
        setCheckEmployee(1)
        // setDisability(1)
        // alert(check_service)
    }

    const columns = [
        {
            key: "name",
            TrOnlyClassName: "cell",
            text: "Employee",
            className: "cell",
            align: "left",
        },
        {
            key: "department_name",
            TrOnlyClassName: "tsc",
            text: "Department",
            className: "tsc",
            align: "left",
        },

        {
            key: "disability",
            TrOnlyClassName: "tsc",
            text: "Department",
            className: "tsc",
            align: "left",
        },
        {
            key: "mgross",
            TrOnlyClassName: "tsc",
            text: "Gross",
            className: "tsc",
            altrueign: "left",
        },
        {
            key: "mother_benefits",
            TrOnlyClassName: "tsc",
            text: "Other Benefits",
            className: "tsc",
            altrueign: "left",
        },
        {
            key: "mnsisf",
            TrOnlyClassName: "tsc",
            text: "NSSF",
            className: "tsc",
            altrueign: "left",
        },

        {
            key: "motherdeduction",
            TrOnlyClassName: "tsc",
            text: "Other Deductions ",
            className: "tsc",
            align: "left",
        },


        {
            key: "mnhif",
            TrOnlyClassName: "tsc",
            text: "NHIF",
            className: "tsc",
            altrueign: "left",
        },
        {
            key: "minsurance_relief",
            TrOnlyClassName: "tsc",
            text: "Insurance Relief",
            className: "tsc",
            altrueign: "left",
        },

        {
            key: "mincome_before_relief",
            TrOnlyClassName: "tsc",
            text: "Tax Before Relief",
            className: "tsc",
            altrueign: "left",
        },

        {
            key: "mtax",
            TrOnlyClassName: "tsc",
            text: "Personal Relief",
            className: "tsc",
            altrueign: "left",
        },
        {
            key: "mincometax",
            TrOnlyClassName: "tsc",
            text: "Income Tax",
            className: "tsc",
            altrueign: "left",
        },



        {
            key: "mpaye",
            TrOnlyClassName: "tsc",
            text: "PAYEE",
            className: "tsc",
            altrueign: "left",
        },

        {
            key: "mnet_pay",
            TrOnlyClassName: "tsc",
            text: "Net Pay",
            className: "tsc",
            altrueign: "left",
        },

        {
            key: "m_date",
            TrOnlyClassName: "tsc",
            text: "Date",
            className: "tsc",
            altrueign: "left",
        }
    ];

    const closeModalUpload = () => {
        setClose(false)
    }

    const rowClickedHandler = (event, data, rowIndex) => {

        setRecordId(data.record_id)
        setOtherBenefits(data.other_benefits)
        setClose(true)
        // alert(JSON.stringify(data.record_id))
        // console.log("event", event);
        // console.log("row data", data);
        // console.log("row index", rowIndex);
    }

    return (
        <div>
            {ToastTable()}

            <Modal
                isOpen={close}
                onRequestClose={e => {
                    closeModalUpload(e);
                }}
                contentLabel="My dialog"
                className="mymodal"
                onAfterOpen={() => {
                    document.body.style.overflow = 'hidden';
                }}
                onAfterClose={() => {
                    document.body.removeAttribute('style');
                }}
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >
                <MDBCloseIcon onClick={closeModalUpload} />
                <h4><b>Update Other Benefits</b></h4><br />

                <div className="col-md-12"><br />
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-3">
                                <label className="form-label">Other Benefits</label>
                            </div>
                            <div className="col-md-9">
                                <input id="input" type="text" className="form-control"
                                    name="other_benefits" placeholder='Other Deduction'
                                    value={other_benefits} onChange={e => setOtherBenefits(e.target.value)} />
                            </div>
                        </div>
                        <br />
                        <Button color="primary" className="btn-paypal  btn-lg " onClick={e => saveOtherBenefits(e)} >
                            {"Update"}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;

                        <br />
                    </div>
                </div>

            </Modal>


            <Card>
                {gohome ? navigate("/staff_expense") : null}
                <CardBody>
                    <div className="row">
                        <div className="col-md-6">
                            {/* <h4><b>Create PaySlip for {moment(new Date()).format('MMM, YYYY')}</b></h4><br /> */}
                            <h4><b>Create PaySlip</b></h4><br />

                        </div>
                        <div className="col-md-6">
                            <Link to="/staff_expense">
                                <Button className="pull-  btn-sm" outline color="primary" >
                                    Payroll
                                </Button>
                            </Link>

                        </div>

                        <div className="col-md-12"> <br />

                            {/* <form > */}
                            {/* <div className="col-md-12"> <br /> */}

                            {/* <div className="row col-10 offset-1">

                                <div className="col-md-4">
                                    <label>
                                        <input type="checkbox"
                                            checked={check_service}
                                            onChange={toggleServiceChange}
                                        />&nbsp;
                                        Disability?
                                    </label>
                                </div>

                                <br /><br /><br />
                         </div> */}
                            <div className="row">

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <Form.Group className="Focus-line" >
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <Form.Label>Select Month</Form.Label>
                                                </div>
                                                <div className="col-6">

                                                    <Select
                                                        options={month_data && month_data.length > 0 ?
                                                            month_data.map((countyItem, i) => ({
                                                                label: countyItem.month,
                                                                value: countyItem.id,
                                                            }))
                                                            : month_data
                                                        }
                                                        // value={employee_label}
                                                        autosize={true}
                                                        noOptionsMessage={() => 'No month found!'}
                                                        loadingMessage={() => 'Searching...'}   //minor type-O here
                                                        placeholder="Select Month"
                                                        // autosize={true}
                                                        onChange={e => {
                                                            setMonthValue(e.value)
                                                            setCheckMonth(true)
                                                        }}
                                                        isLoading={loading}
                                                        menuPortalTarget={document.body}
                                                        name="name"
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>

                                {check_month ?
                                    <>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <Form.Group className="Focus-line" >
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <Form.Label>Select Year</Form.Label>
                                                        </div>
                                                        <div className="col-md-7">

                                                            <Select
                                                                options={year_data && year_data.length > 0 ?
                                                                    year_data.map((countyItem, i) => ({
                                                                        label: countyItem.year,
                                                                        value: countyItem.year,
                                                                    }))
                                                                    : year_data
                                                                }
                                                                // value={employee_label}
                                                                autosize={true}
                                                                noOptionsMessage={() => 'No year!'}
                                                                loadingMessage={() => 'Searching...'}   //minor type-O here
                                                                placeholder="Select Year"
                                                                // autosize={true}
                                                                onChange={e => {
                                                                    setYearValue(e.value)
                                                                    setCreate(true)
                                                                }}
                                                                isLoading={loading}
                                                                menuPortalTarget={document.body}
                                                                name="name"
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>

                                        {check_create ?
                                            <div className="col-md-8">
                                                <br /><br />
                                                <Button type="button" color="primary" outline onClick={() => {
                                                    setCheckYear(true)
                                                    setCreate(false)
                                                }} className="btn-paypal btn-sm pull-right" >
                                                    {isLoading ? "Please Wait..." : "Create"}
                                                </Button> &nbsp;&nbsp;&nbsp;
                                            </div>
                                            : null}
                                    </>
                                    : null}
                            </div>

                            {check_year ?
                                <>

                                    <h4><b>All Names of employees</b></h4>
                                    <ReactDatatable
                                        config={config}
                                        records={employee}
                                        columns={columns}
                                        onRowClicked={rowClickedHandler}
                                        dynamic={true}
                                        id="tsc"
                                        loading={loading}
                                        total_record={total} />


                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Link to="/staff_expense">
                                                        <Button outline color="primary" className="btn-sm">
                                                            Cancel
                                                        </Button>
                                                    </Link>
                                                </div>
                                                <div className="col-md-4">
                                                    <Button type="submit" color="primary" outline onClick={() => { onSubmit(1) }} className="btn-paypal btn-sm pull-right" >
                                                        {isLoading ? "Please Wait..." : "Save as Draft"}
                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                </div>
                                                <div className="col-md-4">
                                                    <Button type="submit" color="primary" outline
                                                        onClick={() => {
                                                            if (window.confirm(`Are you sure you want to save and sent payslip/s`))
                                                                onSubmit(0)
                                                        }} className="btn-paypal btn-sm pull-right" >
                                                        {isLoading ? "Please Wait..." : "Approve"}
                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null}
                        </div>
                    </div>

                </CardBody >
            </Card >
        </div >
    )
}
export default AddStaff;