export const costofgoodOptions = [
    {
        label: 'Cost of Goods Sold',
        value: 'Cost of Goods Sold'

    }

];


export const expenses = [
    {
        label: 'Bad Debt',
        value: 'Bad Debt',
    },
    {
        label: 'Bank Fees and Charges',
        value: 'Bank Fees and Charges',
    },
    {
        label: 'Consultant Expense',
        value: 'Consultant Expense',
    },
    {
        label: 'Advertising And Marketing',
        value: 'Advertising And Marketing',
    },

    {
        label: 'Automatic Expense',
        value: 'Automatic Expense',
    },
    {
        label: 'Credit Card Charges',
        value: 'Credit Card Charges',
    },
    {
        label: 'Depreciation Expense',
        value: 'Depreciation Expense',
    },
    {
        label: 'IT and Internet Expenses',
        value: 'IT and Internet Expenses',
    },
    {
        label: 'Janitorial Expense',
        value: 'Janitorial Expense',
    },
    {
        label: 'Lodging',
        value: 'Lodging',
    },
    {
        label: 'Meals and Entertainment',
        value: 'Meals and Entertainment',
    },
    {
        label: 'Office and Supplies',
        value: 'Office and Supplies',
    },
    {
        label: 'Postage',
        value: 'Postage',
    },
    {
        label: 'Printing and Stationery',
        value: 'Printing and Stationery',
    },
    {
        label: 'Rent Expense',
        value: 'Rent Expense',
    },
    {
        label: 'Repair and Maintenance',
        value: 'Repair and Maintenance',
    },
    {
        label: 'Salaries and Employees wages',
        value: 'Salaries and Employees wages',
    },
    {
        label: 'Telephone Expense',
        value: 'Telephone Expense',
    },
    {
        label: 'Travel Expense',
        value: 'Travel Expense',
    },

    {
        label: "Share capital",
        value: "Share capital",
    },

    {
        label: 'Other Expenses',
        value: 'Other Expenses',
    },


];


export const othercurrentlibiality = [
    {
        label: 'Employee Reimbursement',
        value: 'Employee Reimbursement',
    },
    {
        label: 'Tax Payable',
        value: 'Tax Payable',
    },
    {
        label: "Short term loan",
        value: "Short term loan"
    },
    {
        label: "Short term related parties",
        value: "Short term related parties",
    },

    {
        label: "Other current liability",
        value: "Other current liability",
    }


];


export const stock = [
    {
        label: 'Inventory Assets',
        value: 'Inventory Assets',
    }


];


export const othernoncurrentlibiality = [
    {
        label: "Long term loan",
        value: "Long term loan",
    },

    {
        label: "Long term related parties",
        value: "Long term related parties",
    },

    {
        label: "Other non-current liability",
        value: "Other non-current liability",
    },


];

export const income = [
    {
        label: "Discount",
        value: "Discount",
    },

    {
        label: "General Income",
        value: "General Income",
    },
    {
        label: "Interest Income",
        value: "Interest Income",
    },
    {
        label: "Late Fee Charges",
        value: "Late Fee Charges",
    },
    {
        label: "Other charges",
        value: "Other charges",
    },
    {
        label: "Sales",
        value: "Sales",
    },
    {
        label: "Shipping Charges",
        value: "Shipping Charges",
    }
];



export const otherassetasset = [
    {
        label: 'Advance Tax',
        value: 'Advance Tax',
    },
    {
        label: 'Employee Advance',
        value: 'Employee Advance',
    },
    {
        label: 'Prepaid Expenses',
        value: 'Prepaid EXpenses',
    },
];
export const optionLength = [
    { value: 1, label: 'general' },
    {
        value: 2,
        label:
            'Evil is the moment when I lack the strength to be true to the Good that compels me.',
    },
    {
        value: 3,
        label:
            "It is now an easy matter to spell out the ethic of a truth: 'Do all that you can to persevere in that which exceeds your perseverance. Persevere in the interruption. Seize in your being that which has seized and broken you.",
    },
];

export const dogOptions = [
    { id: 1, label: 'Chihuahua' },
    { id: 2, label: 'Bulldog' },
    { id: 3, label: 'Dachshund' },
    { id: 4, label: 'Akita' },
];

// let bigOptions = [];
// for (let i = 0; i < 10000; i++) {
// 	bigOptions = bigOptions.concat(colourOptions);
// }

export const groupedOptions = [
    {
        label: 'Cost of Goods Sold',
        options: costofgoodOptions,
    },
    {
        label: 'Expenses',
        options: expenses,
    },
    {
        label: 'Other non-current liability',
        options: othernoncurrentlibiality,
    },
    {
        label: 'Other current liability',
        options: othercurrentlibiality,
    },

    {
        label: 'Other current assets',
        options: otherassetasset,
    },
    {
        label: 'Income',
        options: income,
    },
    {
        label: 'Stock',
        options: stock,
    },


];
