import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { isMobile } from 'react-device-detect';
import { ACCOUNTANT, ADMIN_ROLE, BANK, BUSINESS_TYPE, DOCUMENTS, INVENTORY, INVESTMENT, PAYMENT_PLAN, PURCHASES, REPORTS, ROLE, SALES, TOKEN, USERADMINCHECK, USER_ID } from '../../../_helpers/exports';

const SidebarContent = ({
  onClick, changeToLight, changeMobileSidebarVisibility, changeToDark, sidebarCollapse,
}) => {

  const [width, setWidth] = useState(false);

  const hideSidebar = () => {
    onClick();
  };

  useEffect(() => {

    if (isMobile) {
      changeMobileSidebarVisibility();
    } else {
      setWidth(false);
    }
  }, [width]);



  return (<div className="sidebar__content">
    {ROLE === '2' ?
      <div>
        <ul className="sidebar__block">
          {USERADMINCHECK === "true" ?
            <SidebarLink
              title="Switch to Admin"
              icon="exit"
              route="/log_in"
              onClick={hideSidebar}
            />
            : null}

          <SidebarLink
            title="Dashboard"
            icon="home"
            route="/dashboard"
            onClick={hideSidebar}
          />
          {BUSINESS_TYPE === 'business' ?
            INVENTORY === "1" ?
              <SidebarLink
                title="Inventory"
                icon="store"
                route="/item"
                onClick={hideSidebar}
              />
              : null
            : null}
          {PAYMENT_PLAN == 0 || PAYMENT_PLAN == 2 ?
            <div>

              {BANK === "1" ?
                <SidebarLink
                  title="Banking"
                  icon="apartment"
                  route="/banking"
                  onClick={hideSidebar}
                />
                : null}
            </div>
            : null}
        </ul>

        <ul className="sidebar__block">
          <SidebarCategory title={BUSINESS_TYPE === 'business' ? "Payroll" : "Payroll"} route="/employees" icon="briefcase" sidebarCollapse={sidebarCollapse}>
            <SidebarLink
              title="Staff Admin"
              route="/staff_admin"
              onClick={hideSidebar}
            />
            <SidebarLink
              title="Employees"
              route="/employees"
              onClick={hideSidebar}
            />
            <SidebarLink
              title="PaySlips"
              route="/staff_expense"
              onClick={hideSidebar}
            />
          </SidebarCategory>
          {SALES === "1" ?


            <SidebarCategory title={BUSINESS_TYPE === 'business' ? "Sales" : "Income"} route="/invoices" icon="cart" sidebarCollapse={sidebarCollapse}>
              <SidebarLink
                title={BUSINESS_TYPE === 'business' ? "Customers" : "Grantors"}
                route="/customers"
                onClick={hideSidebar}
              />

              {BUSINESS_TYPE === 'business' ?
                <div>
                  <SidebarLink
                    title="Quotations"
                    route="/estimates"
                    onClick={hideSidebar}
                  />

                  <SidebarLink
                    title="Sales Order"
                    route="/salesorderview"
                    onClick={hideSidebar}
                  />
                </div>
                : null
              }
              <SidebarLink
                title={BUSINESS_TYPE === 'business' ? "Invoice" : "Grant Income"}
                route="/invoices"
                onClick={hideSidebar}
              />
              {/* 
            on progress */}

              {BUSINESS_TYPE === 'business' ?
                <div>
                  <SidebarLink
                    title="Payments Received"
                    route="/payment-received"
                    onClick={hideSidebar}
                  />
                  <SidebarLink
                    title="Recurring Invoices"
                    route="/recurring-invoices"
                    onClick={hideSidebar}
                  />

                  {PAYMENT_PLAN == 0 ?
                    <div>
                      <SidebarLink
                        title="Credit Notes"
                        route="/credit-note"
                        onClick={hideSidebar}
                      />
                    </div>
                    : null}
                </div>
                :
                null
                // <SidebarLink
                //   title="Other income"
                //   route="/other-income"
                //   onClick={hideSidebar}
                // />
              }
            </SidebarCategory>
            : null}

          <SidebarCategory
            title="Purchases"
            icon="briefcase"
            route="/bills"
            sidebarCollapse={sidebarCollapse}
          >


            {PAYMENT_PLAN == 0 || PAYMENT_PLAN == 2 || PAYMENT_PLAN == 1 ?
              <div>
                {PAYMENT_PLAN == 0 || PAYMENT_PLAN == 2 ?
                  <SidebarLink
                    title="Suppliers"
                    route="/vendor"
                    onClick={hideSidebar}
                  />
                  : null}

                {PAYMENT_PLAN == 0 || PAYMENT_PLAN == 2 || PAYMENT_PLAN == 1 ?
                  <div>

                    <SidebarLink
                      title="Expenses"
                      route="/expense"
                      onClick={hideSidebar}
                    />

                    <SidebarLink
                      title="Recuring Expenses"
                      route="/recuring-expense"
                      onClick={hideSidebar}
                    />
                  </div>
                  : null}
                {PAYMENT_PLAN == 0 || PAYMENT_PLAN == 2 ?
                  <div>


                    <SidebarLink
                      title="Bills"
                      route="/bills"
                      onClick={hideSidebar}
                    />

                    <SidebarLink
                      title="Payment Made"
                      route="/payment-bills"
                      onClick={hideSidebar}
                    />

                    <SidebarLink
                      title="Recurring Bills"
                      route="/recurring-bills"
                      onClick={hideSidebar}
                    />
                    {/* {PAYMENT_PLAN == 0 ?
                    <SidebarLink
                      title="Vendor Credits"
                      route="/vendor-credit"
                      onClick={hideSidebar}
                    />
                    : null} */}
                  </div>
                  : null}
              </div>
              : null}
          </SidebarCategory>

        </ul>
        <ul className="sidebar__block">
          {PAYMENT_PLAN == 0 ?
            <div>
              {INVESTMENT === "1" ?
                <SidebarLink
                  title="Investments"
                  icon="heart-pulse"
                  route="/investment"
                  onClick={hideSidebar}
                />
                : null}

              {ACCOUNTANT === "1" ?
                <SidebarCategory
                  title="Accountant"
                  icon="user"
                  route="/journals" sidebarCollapse={sidebarCollapse}>
                  <SidebarLink
                    title="Manual Journals"
                    route="/journals"
                    onClick={hideSidebar}
                  />
                </SidebarCategory>
                : null}

            </div>
            : null}

          {PAYMENT_PLAN == 0 || PAYMENT_PLAN == 2 ?
            <div>
              {DOCUMENTS === "1" ?
                <SidebarLink
                  title="Documents"
                  icon="text-align-justify"
                  route="/documents"
                  onClick={hideSidebar}
                />
                : null}
            </div>
            : null}

          {REPORTS === "1" ?
            <div>
              <SidebarLink
                title="Reports"
                icon="list"
                route="/report"
                onClick={hideSidebar}
              // onClick={changeSidebarVisibility}
              />
              {/* <SidebarLink
                title="Business Goals"
                icon="list"
                route="/budget"
                onClick={hideSidebar}
              // onClick={changeSidebarVisibility}
              /> */}

            </div>
            : null}
          {/* {ADMIN_ROLE === "1" || ACCOUNTANT === "1" ?
            // <SidebarLink
            //   title="Account Settings"
            //   icon="cog"
            //   route="/settings"
            //   onClick={hideSidebar}
            // />
            : null}
 */}

        </ul>

        {/* <ul className="sidebar__block">

          <SidebarLink
            title="User Guide"
            icon="text-align-justify"
            route="/userguide"
            onClick={hideSidebar}
          />

        </ul>
 */}


        {/* <SidebarLink
        title="cv"
        icon="cog"
        route="/componentupload"
        onClick={hideSidebar}
      /> */}




      </div>
      : null}

    {ROLE === '1' ?
      <div>
        {/* <SidebarLink title="Dashboard" icon="home" route="/dashboard" onClick={hideSidebar} /> */}

        <SidebarLink
          title="Clients"
          icon="users"
          route="/users"
          onClick={hideSidebar}
        />

        <SidebarLink
          title="Add New Client"
          icon="file-add"
          route="/addusers"
          onClick={hideSidebar}
        />

        <SidebarLink
          title="Archived Clients"
          icon="apartment"
          route="/archived-clients"
          onClick={hideSidebar}
        />
        <SidebarLink
          title="MPESA Transactions"
          icon="briefcase"
          route="/transactions"
          onClick={hideSidebar}
        />

        <SidebarLink
          title="Currency"
          icon="briefcase"
          route="/currency"
          onClick={hideSidebar}
        />

        <SidebarLink
          title="SMS Logs"
          icon="bubble"
          route="/sms_logs"
          onClick={hideSidebar}
        />




      </div> : null
    }

    <ul className="sidebar__block">
      <SidebarCategory title="Layout" icon="layers" sidebarCollapse={sidebarCollapse}>
        <button className="sidebar__link" type="button" onClick={changeToLight}>
          <p className="sidebar__link-title">Light Theme</p>
        </button>
        <button className="sidebar__link" type="button" onClick={changeToDark}>
          <p className="sidebar__link-title">Dark Theme</p>
        </button>
      </SidebarCategory>
    </ul>
    <ul className="sidebar__block">
      <SidebarLink title="Log Out" icon="exit" route="/log_in" />
    </ul>



  </div >

  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
