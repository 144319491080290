import React, { useState } from 'react';
import '../../_assets/css/file.css';
import { Card, CardBody, Button } from 'reactstrap';
import { BRANCH_ID, ToastTable, USER_ID, } from '../../_helpers/exports';
import { Link, useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PostHooks from '../../_helpers/postHooks';


const CreateDepartment = () => {
    const [name, setName] = useState('');
    const [check, setCheck] = useState(0);


    const [props, setProps] = useState({});
    const [isLoading, res, postRequest] = PostHooks(props); // custom hook
    const [gohome, setHome] = useState(false);

    React.useEffect(() => {  // use effect call, detect change and call custom post hook
        if (res != null && res.success) {
            setName('');
            setTimeout(() =>
                setHome(true), 2000
            )
        }

    }, [isLoading, props, check])



    const submit = async (e) => {
        e.preventDefault();

        postRequest()

        setCheck(1)
    }


    const navigate = useNavigate()
    return (
        <div>
            {ToastTable()}
            <Card>
                {gohome ? navigate("/staff_admin") : null}

                <CardBody>
                    <div className="row">
                        <div className="col-md-6">
                            <h4><b>Create Department</b></h4><br />
                        </div>
                        <div className="col-md-6">
                            <Link to="/staff_admin">
                                <Button className="pull-right  btn-sm" outline color="primary" >
                                    Departments
                                </Button>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={submit}>
                        <div className="col-md-8 offset-2"><br />
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-2">
                                        <label className="form-label">Name</label>
                                    </div>
                                    <div className="col-md-10">
                                        <input id="input" required type="text" className="form-control"
                                            name="name" placeholder='Enter department name'
                                            value={name} onChange={e => {
                                                const formData = {
                                                    name: e.target.value,
                                                    branch_id: BRANCH_ID,
                                                    client_id: USER_ID
                                                }
                                                const customerProps = { // post body and the url
                                                    url: 'department',
                                                    body: formData
                                                }
                                                setProps(customerProps)
                                                setName(e.target.value)
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="col-md-8 offset-2"><br />
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-2">
                                        <Link to="/staff_admin">
                                            <Button outline color="primary" className="btn-sm">
                                                Cancel
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="col-md-10">
                                        <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                            {isLoading ? "Please Wait..." : "Save"}
                                        </Button> &nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div >
    )
}
export default CreateDepartment;