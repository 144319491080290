
import React from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col } from 'reactstrap'
import { ADMIN_ROLE, BUSINESS_TYPE, PAYMENT_PLAN } from '../../_helpers/exports'

export class Reports extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }
  render() {


    return (
      <div style={{ marginTop: "-20px" }} className='fullscreen' >
        {/* <MetaTags>
          <title>Reports</title>
          <meta name="viewport" content="width=1920"/>
        </MetaTags> */}

        <Table>
          <Card>
            <CardBody>
              <div className='col-12'>
                <h4>Reports</h4><br />

                <div className='row col-12'>


                  {PAYMENT_PLAN == 0 || PAYMENT_PLAN == 2 ?

                    <div className='col-md-4 col-sm-4 col-xs-4' p='3'>


                      <h4>Business Overview</h4><br />


                      <div className='col-8'>
                        {ADMIN_ROLE == 1 ?
                          <Link className='Links' to='/client_transaction'>Mpesa Transactions</Link> : null}
                        <hr />
                      </div>

                      <div className='col-8'>
                        <Link className='Links' to='/general-ledger'>General Ledger</Link>
                        <hr />
                      </div>
                      <div className='col-8'>
                        <Link className='Links' to='/trial-balance'>Trial Balance</Link>
                        <hr />
                      </div>

                      <div className='col-8'>
                        <Link className='Links' to='/profitloss'>{BUSINESS_TYPE === 'business' ? "Profit and Loss" : "Income Statement"}</Link>
                        <hr />
                      </div>


                      <div className='col-8'>
                        <Link className='Links' to='/netcashflow'>Cash Flow Statement</Link>
                        <hr />
                      </div>
                      <div className='col-8'>
                        <Link className='Links' to='/balancesheet'>Balance Sheet</Link>
                        <hr />
                      </div>
                      <div className='col-8'>
                        <Link className='Links' to='/businessperformanceratio'>Business Performance Ratios</Link>
                      </div>


                      <hr />
                      <div className='col-8'>
                        <Link className='Links' to='/budget'>Business Goals</Link>
                        <hr />
                      </div>

                      <br />
                      <h4>Payments Received</h4><br />
                      <div className='col-8'>
                        <Link className='Links' to='/paymentreceivedreport'>Payment Received</Link>
                        <hr />
                      </div>
                      <div className='col-8'>
                        <Link className='Links' to='/credtinotedetails'>Credit Note Details</Link>
                        <hr />
                      </div>


                    </div>
                    : null}


                  <div className='col-md-4 col-sm-4 col-xs-4' p='3'>
                    <h4>Sales</h4><br />
                    <div className='col-8'>
                      <Link className='Links' to='/customerbysales'>{BUSINESS_TYPE === 'business' ? "Sales by Customers" : "Income"}</Link>
                      <hr />
                    </div>
                    {BUSINESS_TYPE === 'business' ?
                      <div>
                        <div className='col-8'>
                          <Link className='Links' to='/salesbyitem'>Sales by Item</Link>
                          <hr />
                        </div>
                        <div className='col-8'>
                          <Link className='Links' to='/salesbysalesperson'>Sales by Sales Person</Link>
                        </div>


                      </div>
                      : null}
                    {/* <div className='col-8'>
                    <Link className='Links' to='/credit-note'>Sales by Sales Person</Link>
                  </div> */}
                    <br />
                    <br />
                    <br />
                    <br />
                    {PAYMENT_PLAN == 0 || PAYMENT_PLAN == 2 ?
                      <div>
                        <h4>Purchase and Expenses</h4><br />
                        <div className='col-8'>
                          <Link className='Links' to='/purchasebyvendor'>Purchase by Supplier</Link>
                          <hr />
                        </div>
                        {BUSINESS_TYPE === 'business' ?
                          <div>
                            <div className='col-8'>
                              <Link className='Links' to='/purchasebyitem'>Purchase by Item</Link>
                              <hr />
                            </div>

                            <div className='col-8'>
                              <Link className='Links' to='/expensevendor'>Expenses Supplier</Link>
                              <hr />
                            </div>
                          </div>
                          : null}

                        <div className='col-8'>
                          <Link className='Links' to='/expense_customer'>Expenses Customer</Link>
                          <hr />
                        </div>
                        <div className='col-8'>
                          <Link className='Links' to='/expense_category'>Expenses Category</Link>
                          <hr />
                        </div>
                      </div>
                      : null}


                  </div>
                  {PAYMENT_PLAN == 0 || PAYMENT_PLAN == 2 ?

                    <div className='col-md-4 col-sm-4 col-xs-4' p='3'>
                      <h4>Receivables</h4><br />
                      <div className='col-8'>
                        <Link className='Links' to='/customerbalance'>Customer Balances</Link>
                        <hr />
                      </div>
                      {BUSINESS_TYPE === 'business' ?
                        <div>
                          <div className='col-8'>
                            <Link className='Links' to='/agingsummary'>Aging Summary</Link>
                            <hr />
                          </div>
                          <div className='col-8'>
                            <Link className='Links' to='/invoicedetails'>Invoice Details</Link>
                            <hr />
                          </div>
                          <div className='col-8'>
                            <Link className='Links' to='/estimatedetails'>Quotation Details</Link>
                            <hr />
                          </div>
                        </div>
                        : null}
                      <div className='col-8'>
                        <Link className='Links' to='/receivables'>Receivable Summary</Link>
                        <hr />
                      </div>
                      <br />
                      <br />

                      <h4>Payables</h4><br />
                      <div className='col-8'>
                        <Link className='Links' to='/vendorbalance'>Supplier Balances</Link>
                        <hr />
                      </div>


                      <div className='col-8'>
                        <Link className='Links' to='/paymentmadereport'>Payment Made</Link>
                        <hr />
                      </div>

                      <div className='col-8'>
                        <Link className='Links' to='/agingsummarybills'>Aging Summary</Link>
                        <hr />
                      </div>
                      <div className='col-8'>
                        <Link className='Links' to='/payables'>Payable Summary</Link>
                        <hr />
                      </div>
                    </div>
                    : null}
                </div>

              </div>
            </CardBody>
          </Card>
        </Table>
      </div>
    )
  }
}
