import { CONFIG, USER_ID } from '../../_helpers/exports.jsx';
import { baseURL } from '../../_helpers';
import axios from 'axios';

export const costofgoodOptions = [
    {
        label: 'Cost of Goods Sold',
        value: 'Cost of Goods Sold'

    }

];




export const othernoncurrentlibiality = [
    {
        label: "Long term loan",
        value: "Long term loan",
    },

    {
        label: "Long term related parties",
        value: "Long term related parties",
    },

    {
        label: "Other non-current liability",
        value: "Other non-current liability",
    },


];


export const fixedasset = [
    {
        label: 'Land',
        value: 'Land',
    },
    {
        label: 'Furniture Fittings',
        value: 'Furniture Fittings',
    },
    {
        label: 'Equipments',
        value: 'Equipments',
    },
    {
        label: 'Computers & peripherals',
        value: 'Computers & peripherals',
    },
    {
        label: 'Motor Vehicles',
        value: 'Motor Vehicles',
    },
    {
        label: 'Revaluation Reserves',
        value: 'Revaluation Reserves',
    },

];


export const othernonassetasset = [
   
    {
        label: "Other non-current assets",
        value: "Other non-current assets",
    }
];

export const otherassetasset = [
    {
        label: 'Advance Tax',
        value: 'Advance Tax',
    },
    {
        label: 'Employee Advance',
        value: 'Employee Advance',
    },
    {
        label: "Other current assets",
        value: "Other current assets",
    }
];
export const optionLength = [
    { value: 1, label: 'general' },
    {
        value: 2,
        label:
            'Evil is the moment when I lack the strength to be true to the Good that compels me.',
    },
    {
        value: 3,
        label:
            "It is now an easy matter to spell out the ethic of a truth: 'Do all that you can to persevere in that which exceeds your perseverance. Persevere in the interruption. Seize in your being that which has seized and broken you.",
    },
];

export const dogOptions = [
    { id: 1, label: 'Chihuahua' },
    { id: 2, label: 'Bulldog' },
    { id: 3, label: 'Dachshund' },
    { id: 4, label: 'Akita' },
];

// let bigOptions = [];
// for (let i = 0; i < 10000; i++) {
// 	bigOptions = bigOptions.concat(colourOptions);
// }
export const groupedOptions = [
    {
        label: 'Cost of Goods Sold',
        options: JSON.parse(localStorage.getItem("expenseaccountcustomers")) == null ?JSON.parse(localStorage.getItem("expenseaccountcustomers")): JSON.parse(localStorage.getItem("expenseaccountcustomers")).filter(x => x.category == "Cost of Goods Sold"),
    },
    {
        label: 'Expenses',
        options: JSON.parse(localStorage.getItem("expenseaccountcustomers")) == null ?JSON.parse(localStorage.getItem("expenseaccountcustomers")): JSON.parse(localStorage.getItem("expenseaccountcustomers")).filter(x => x.category == "Expenses")
    },
    {
        label: 'Other non-current liability',
        options: JSON.parse(localStorage.getItem("expenseaccountcustomers")) == null ?JSON.parse(localStorage.getItem("expenseaccountcustomers")): JSON.parse(localStorage.getItem("expenseaccountcustomers")).filter(x => x.category == "Other non-current liability"),
    },
    {
        label: 'Other current liability',
        options: JSON.parse(localStorage.getItem("expenseaccountcustomers")) == null ?JSON.parse(localStorage.getItem("expenseaccountcustomers")): JSON.parse(localStorage.getItem("expenseaccountcustomers")).filter(x => x.category == "Other current liability"),
    },
    {
        label: 'Fixed Assets',
        options: JSON.parse(localStorage.getItem("expenseaccountcustomers")) == null ?JSON.parse(localStorage.getItem("expenseaccountcustomers")): JSON.parse(localStorage.getItem("expenseaccountcustomers")).filter(x => x.category == "Fixed Assets"),
    },
    {
        label: 'Other non-current assets',
        options: JSON.parse(localStorage.getItem("expenseaccountcustomers")) == null ?JSON.parse(localStorage.getItem("expenseaccountcustomers")): JSON.parse(localStorage.getItem("expenseaccountcustomers")).filter(x => x.category == "Other non-current assets"),
    },
    {
        label: 'Other current assets',
        options: JSON.parse(localStorage.getItem("expenseaccountcustomers")) == null ?JSON.parse(localStorage.getItem("expenseaccountcustomers")): JSON.parse(localStorage.getItem("expenseaccountcustomers")).filter(x => x.category == "Other current assets"),
    },
];
