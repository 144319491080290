import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { TOKEN, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { Link, Navigate } from 'react-router-dom';
import * as moment from 'moment';
import addUser from './addUser';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";

export class Users extends React.Component {
    constructor(props) {
        super(props);
        this._onButtonClick = this._onButtonClick.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);

        this.columns = [

            {
                key: "company_name",
                TrOnlyClassName: 'tsc',
                text: "Company Name",
                className: "tsc",
                align: "left"
            },
            {
                key: "row",
                TrOnlyClassName: 'tsc',
                text: "Client No",
                className: "tsc",
                align: "left"
            },
            {
                key: "msisdn",
                TrOnlyClassName: 'tsc',
                text: "Phone Number",
                className: "tsc",
                align: "left"
            },


            // {
            //     key: "username",
            //     TrOnlyClassName: 'tsc',
            //     text: "Username",
            //     className: "tsc",
            //     align: "left"
            // },

            {
                key: "email",
                TrOnlyClassName: 'tsc',
                text: "Email",
                className: "tsc",
                align: "left"
            },

            // {
            //     key: "industry",
            //     TrOnlyClassName: 'tsc',
            //     text: "Industry",
            //     className: "tsc",
            //     align: "left"
            // },

            {
                key: "payment_status",
                TrOnlyClassName: 'tsc',
                text: "Subscription Type",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.first_time === 1 ?
                                <div>
                                    {record.payment_plan === 0 ?
                                        <span class="badge-" style={{ borderRadius: "5px", padding: "2px" }}>
                                            <b> Trial </b>
                                        </span>
                                        : null}
                                </div>
                                : null}
                            {record.first_time === 0 ?
                                <div>

                                    {record.payment_plan === 0 ?
                                        <span class="badge-" style={{ borderRadius: "5px", padding: "2px" }}>
                                            <b> Enterpise </b>
                                        </span>
                                        : null}
                                    {record.payment_plan === 1 ?
                                        <span class="badge-" style={{ borderRadius: "5px", padding: "2px" }}>
                                            <b> Basic </b>
                                        </span>
                                        : null}
                                    {record.payment_plan === 2 ?
                                        <span class="badge-" style={{ borderRadius: "5px", padding: "2px" }}>
                                            <b>Standard</b>
                                        </span>
                                        : null}

                                </div>

                                : null}
                        </Fragment >
                    );
                }
            },

            {
                key: "dates",
                text: "Next Subscription Date",
                TrOnlyClassName: 'tsc',
                className: "tsc",
                align: "left"
            },

            {
                key: "status",
                TrOnlyClassName: 'tsc',
                text: "Expiry",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            <div>
                                {record.due_period < 0 ?
                                    <span class="badge-info" style={{ borderRadius: "5px", padding: "2px" }}>
                                        Due in {record.due_period * -1} days
                                    </span>
                                    : null}

                                {record.due_period > 0 ?
                                    <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                        Over Due by {record.due_period} days
                                    </span>
                                    : null}
                                {record.due_period === 0 ?

                                    <span span class="badge-info" style={{ borderRadius: "5px", padding: "2px" }}>
                                        Due Today
                                    </span>
                                    : null}
                            </div>
                        </Fragment >
                    );
                }
            },


            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >

                            {/* <button className="btn btn-primary btn-sm"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.openModal(record) }} >
                                Send Receipt
                            </button> */}

                            <button className="btn btn-primary btn-sm"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleMore(record) }} >
                                More <i className="fa fa-plus"></i>
                            </button>

                            {record.status === 0 ?
                                <button className="btn btn-primary btn-sm"
                                    title="Eith details"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleOnClick2(record) }} >
                                    Approve
                                </button>
                                : null}
                            {record.status === 1 ?

                                <button className="btn btn-danger btn-sm"
                                    title="Deactive"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleOnClick(record) }} > Deactive
                                </button>
                                : null}

                        </Fragment>
                    );
                }
            }

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [50, 100, 200],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 100,
            button: {
                excel: true,
                csv: true
            },
            filename: "clients",
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            transaction_id: '',
            email: '',
            client_id: '',
            name: '',

            moreUsers: false,
            open: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }
    componentDidMount() {
        this.getData();
    }

    async handleOnClick2(record) {
        console.log("hoge!");
        if (await confirm("Proceed in activating this account?")) {
            this.onApprove(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }


    async handleMore(record) {
        console.log("hoge!");


        localStorage.setItem("client_id_admin", record.client_id)
        this.setState({
            moreUsers: true
        })

    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Proceed in deactivating this account?")) {
            this.onDeactivate(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    getData = (queryString = "") => {


        this.setState({
            isLoading: true,
        })
        // alert(TOKEN)
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `client?status=1&` + queryString
        console.log("query", url);
        // alert(localStorage.getItem("customerID"))

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                isLoading: false
            }, function () {
                var data = [];
                console.log("BUG", this.state.admins)


                // retrieve params
                let params = new URLSearchParams(queryString)
                let id = parseInt(params.get("page_number"))
                // console.log(id)
                // alert(id)
                // peak view sequent view
                let p = branchResponse.data.total + 1;
                if (id > 1) {
                    p = 0
                }

                for (let i = 0; i < this.state.admins.length || 0; i++) {
                    this.setState({
                        total: branchResponse.data.total
                    })

                    p--;

                    let unrow = { row: 1000 + p }

                    let due_date = moment(this.state.admins[i].subscription);
                    let cur_date = moment();
                    // let curDateFormated = cur_date.format('DD MMM, YYYY');
                    // let dueDateFormated = due_date.format('DD/MM/YYYY');
                    const diff = cur_date.diff(due_date, 'days');
                    let mperiod = { due_period: diff }
                    // let date1 = { dates1: dueDateFormated };


                    // eslint-disable-next-line
                    let mail = { name: this.state.admins[i].first_name + " " + this.state.admins[i].last_name };
                    // eslint-disable-next-line
                    let date = { dates: moment(this.state.admins[i].subscription).format("YYYY-MM-DD") };
                    data.push(Object.assign(mail, unrow, date, mperiod, this.state.admins[i]));
                    this.setState({
                        data: data
                    })
                    console.log("bugs", data);
                }
            }
            );
        }))

    }
    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onApprove(record) {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };

        console.log("axios", axiosConfig)


        axios.put(baseURL + `clientactivate/` + record.client_id, {}, axiosConfig).then(response => {
            console.log("true",)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {

                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });

                let formData2 =
                {
                    "name": record.first_name + " " + record.last_name,
                    "email": record.email,
                    "token": TOKEN
                }
                axios.post(baseURL_2 + 'emailApprove.php', formData2)
                    .then((response) => {

                    }).catch(error => {
                        this.setState({ statusMessage: error.response === undefined ? window.location.href = "/log_in" : "Something went wrong", showError: true, isShowError: false, isLoading: false });
                        window.scrollTo(0, 0)
                        // console.error('Error during service worker registration:', error);
                    });
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false }, function () {
                    // window.setTimeout(() => {
                    //     this.getData();
                    //     this.setState({ isShowError: false, showError: false })
                    // }, 2000);
                });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }


    onDeactivate(record) {

        console.log("Delete Record", record);
        axios.put(baseURL + `clientdeactivate/` + record.id, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
                let formData2 =
                {
                    "name": record.first_name + " " + record.last_name,
                    "email": record.email,
                    "token": TOKEN
                }
                axios.post(baseURL_2 + 'emailReject.php', formData2)
                    .then((response) => {

                    }).catch(error => {
                        this.setState({ statusMessage: error.response === undefined ? window.location.href = "/log_in" : "Something went wrong", showError: true, isShowError: false, isLoading: false });
                        window.scrollTo(0, 0)
                        // console.error('Error during service worker registration:', error);
                    });

            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false, showError: false })
                    }, 2000);
                });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }
    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: false,

        });
    }

    closeModal = () => {
        this.setState({
            open: false
        })
    }

    openModal = (e) => {
        this.setState({
            open: true,
            client_id: e.client_id,
            name: e.company_name,
            email: e.email,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        axios.get(baseURL + `get_transaction?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${this.state.client_id}&mpesa_code=${this.state.transaction_id}`).then((response) => {
            if (response.data.status) {

                alert(JSON.stringify(response.data.data))
                this.setState({ mpese_code: response.data.data[0].TransID });
            } else {
            }
        }).catch((error) => {
            console.log('bayoo', error)
            this.setState({
                // statusMessage: error.response === undefined ? "Error" : error.response.data.message, showError: true, isShowError: false, isLoading: false
            });
        })


    }
    render() {
        const { showComponent, moreUsers } = this.state;
        const { hideComponent } = this.state;
        // console.log("Load", isLoading);
        return (
            <div style={{ marginTop: "-20px" }} >


                <Modal
                    isOpen={this.state.open}
                    onRequestClose={this.closeModal}
                    contentLabel="My dialog"
                    className="mymodal"
                    onAfterOpen={() => {
                        document.body.style.overflow = 'hidden';
                    }}
                    onAfterClose={() => {
                        document.body.removeAttribute('style');
                    }}
                    overlayClassName="myoverlay"
                    closeTimeoutMS={500}
                >
                    <MDBCloseIcon onClick={this.closeModal} />
                    <h4><b>Send Receipt</b></h4><br />

                    <div>
                        <form onSubmit={this.handleSubmit}>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="form-label">Transaction ID</label>
                                        <div className="col-md-10">
                                            <input id="input" type="text" className="form-control" name="transaction_id"
                                                required placeholder="Enter Transaction ID"
                                                value={this.state.transaction_id}
                                                onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Send Receipt</button>

                            </div>
                        </form>
                    </div>


                </Modal>


                {moreUsers == true ?
                    <Navigate to="/client-users" />
                    :
                    null
                }
                {showComponent && (
                    <div>
                        <addUser />
                    </div>
                )}
                {!hideComponent && (

                    <div>
                        < Card >
                            <CardBody >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>List of Clients</b></h4>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/addusers">
                                            <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                Add Client
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4>Clients as at Now: <b>{this.state.total}</b></h4>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-md-10 offset-1">
                                            <button className="pull-right" title="refresh" style={{ background: "white", border: "0px" }} onClick={this.getData}>
                                                <RefreshIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>

                                </div> : null}

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div>

                                ) : null}<br></br>

                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={this.state.isLoading}
                                    total_record={this.state.total}
                                    onChange={this.tableChangeHandler} />
                            </CardBody>

                        </Card>
                    </div>

                )
                }
            </div>
        )
    }
}