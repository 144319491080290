import React, { Component } from 'react';
import styles from './Invoice.module.scss';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Form } from 'react-bootstrap';
import LineItems from './LineItems.jsx';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { BRANCH_ID, BUSINESS_TYPE, CONFIG, CURRENCY, INVOICE_STATUS, LOADER, PROFILE_URL, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { baseURL, baseURL_2 } from '../../_helpers';
import Alert from '../../shared/components/Alert';
import { Link, Navigate } from 'react-router-dom';
import $ from 'jquery';
import { uuid } from 'uuidv4';

import CreatableSelect from 'react-select/creatable';
import * as moment from 'moment';
import { updateInvoicePDF } from './pdfUpdateInvoice';
import { RadioGroup, ReversedRadioButton, RadioButton } from 'react-radio-buttons'
import Loader from "react-loader-spinner";
import { updateInvoicePDF2 } from './pdfUpdateInvoicer';
// import { currency } from '../Account/Settings/datacurrency';

window.$ = window.jQuery = require('jquery');

require('../../_assets/jss/sms_counter.js');


class SalesOrderEdit extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this._onButtonClick = this._onButtonClick.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);
    this.handleAddLineSubmit = this.handleAddLineSubmit.bind(this);
  }
  locale = 'en-US';
  currency = CURRENCY;

  state = {
    taxRate: 0.00,
    invoice_no: 'IN-2399399',
    loaded: true,
    invoice_date: new Date(),
    currencyValue: [{ label: "", value: "" }],
    due_date: new Date(),
    hideComponent: false,
    discount_amount: 0,
    delivery_amount: 0,
    showComponent: false,
    editedInvoice: {},
    terms: "Due on Receipt",
    terms3: [
      {
        label: 'Due on Receipt',
        value: 'Due on Receipt',
      },
      {
        label: 'Due in 15 days',
        value: 'Due in 15 days',
      },
      {
        label: 'Due in 30 days',
        value: 'Due in 30 days',
      },
      {
        label: 'Due in 6 months',
        value: 'Due in 6 months',
      }
    ],
    returnHome: false,
    lineItems: [
      {
        id: 'initial', // react-beautiful-dnd unique key
        name: '',
        reference: '',
        description: '',
        quantity: 2,
        discount_percentage: 0,
        tax_percentage: 0,
        price: 10.00,
      }
    ],
  };
  async componentDidMount() {
    if (PROFILE_URL == "null") {
      localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
    } else {
      localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
      var img = new Image;
      img.crossOrigin = "*"
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
        canvas.toDataURL('image/jpeg', 1.0)
        var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
        localStorage.setItem("image_urls", fullQuality)
      };
      img.src = `${PROFILE_URL}?canvas=true`;
    }

    this.getData();

    $('#message').countSms('#sms-counter');
    $('#messages').countSms('#sms-counters');


    this.setState({
      isLoading: true,
      invoice_currency: CURRENCY,
      currency: CURRENCY,
    })

    const [currency] = await Promise.all([
      axios.get(baseURL + `currency`, {
        headers: { Authorization: TOKEN },
      }),
    ]);

    var data = [];
    for (let i = 0; i < currency.data.data.length; i++) {
      let mlabel = { label: currency.data.data[i].currency };
      let mvalue = { value: currency.data.data[i].agnaist_ksh };


      data.push(Object.assign(mlabel, mvalue, currency.data.data[i]));
      this.setState({
        data: data,
        isLoading: false,
      })
    }

  }

  _onButtonClick() {
    this.setState({
      returnHome: true,
      hideComponent: true,
    });
  }

  handleLineItemChange = elementIndex => event => {
    console.log(this.state.lineItems);

    let lineItems = this.state.lineItems.map((item, i) => {
      if (elementIndex !== i) return item;
      // alert(elementIndex);
      return { ...item, [event.target.name]: event.target.value };
    });
    this.setState({ lineItems });
  };


  onChangeInvoiceDate = value => {

    if (this.state.terms == "Due on Receipt") {
      this.setState({
        invoice_date: value,
        due_date: value,
      });
    }
    this.setState({
      invoice_date: value,
    });
  }
  Customer() {
    return (
      this.state.customerResponse &&
      (this.state.customerResponse.length > 0 || this.state.customerResponse.length === 0) &&
      this.state.customerResponse.map((countyItem, i) => ({
        label: countyItem.customer_display_name + " (" + countyItem.email + ")",
        customer_email: countyItem.email,
        customer_nameinvoice: countyItem.customer_display_name,
        value: countyItem.id,
      }))
    );
  }

  handleAddLineItem = event => {

    let length = this.state.lineItems.length - 1;
    this.setState({
      addnew: true
    })
    if (BUSINESS_TYPE === 'business') {
      if (length > -1) {
        if (this.state.lineItems[length].name === '') {
          alert("Name of the item cannot be empty")
          this.setState({
            addnew: false,
            // use optimistic uuid for drag drop; in a production app this could be a database id
            lineItems: this.state.lineItems.concat(),
          });
        } else {
          this.setState({
            addnew: true,
          })
          let axiosConfig = {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
              "Authorization": TOKEN
            }
          };
          var encodeURISafe = require('encodeuri-safe');
          // alert()
          let url = `${baseURL}item?branch_id=${BRANCH_ID}&&client_id=${USER_ID}&filter_value=${encodeURISafe.encodeURIComponent(this.state.lineItems[length].name)}`

          // let url = baseURL + `item?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&filter_value=` + this.state.lineItems[length].name
          axios.all([
            axios.get(url, axiosConfig),
          ]).then(axios.spread((itemResponse) => {
            this.setState({
              item: itemResponse.data.data.filter(item => item.status === 1),
              isLoading: false,

            },
              function () {
                var data = [];
                //filter json object

                //original item 
                let filter_items_original = this.state.lineItems_update.filter(item => item.name === this.state.item[0].item_name)
                let accumulative_original = filter_items_original.reduce(
                  (prev, cur) =>
                    (Number(prev) + Number(cur.quantity)),
                  0)
                // new state item
                let filter_items = this.state.lineItems.filter(item => item.name === this.state.item[0].item_name)
                let accumulative = filter_items.reduce(
                  (prev, cur) =>
                    (Number(prev) + Number(cur.quantity)),
                  0)

                if ((this.state.item[0].quantity - (accumulative - accumulative_original)) < 0) {
                  this.setState({
                    addnew: false,
                  })
                  // this.handleRemove(length)
                  alert(`You current stock for item ${this.state.item[0].item_name} is not enough. Kindly restock`)
                } else {
                  this.setState({
                    addnew: false,
                    // use optimistic uuid for drag drop; in a production app this could be a database id
                    lineItems: this.state.lineItems.concat([
                      {
                        id: 0, // react-beautiful-dnd unique key
                        name: '',
                        description: '',
                        reference: '',
                        quantity: 1,
                        client_id: USER_ID,
                        item_id: uuid(),
                        discount_percentage: 0,
                        tax_percentage: 0,
                        price: 0.00,
                      },
                    ]),
                  })
                }
              })
          })).catch(error => {
            console.log('bayoo', error.response)
          })
        }
      } else {
        this.setState({
          addnew: false,
          // use optimistic uuid for drag drop; in a production app this could be a database id
          lineItems: this.state.lineItems.concat([
            {
              id: uuid(), // react-beautiful-dnd unique key
              name: '',
              description: '',
              reference: '',
              quantity: 1,
              discount_percentage: 0,
              tax_percentage: 0,
              price: 0.00,
            },
          ]),
        })
      }
    } else {
      this.setState({
        addnew: false,
        // use optimistic uuid for drag drop; in a production app this could be a database id
        lineItems: this.state.lineItems.concat([
          {
            id: uuid(), // react-beautiful-dnd unique key
            name: '',
            description: '',
            reference: '',
            quantity: 1,
            discount_percentage: 0,
            tax_percentage: 0,
            price: 0.00,
          },
        ]),
      })
    }
  };


  handleAddLineSubmit = event => {

    // alert(event)

    let length = this.state.lineItems.length - 1;
    this.setState({
      addnew: true,
      isLoading: true
    })
    if (BUSINESS_TYPE === 'business') {

      if (length > -1) {
        if (this.state.lineItems[length].name === '') {
          alert("Name of the item cannot be empty")
          this.setState({
            addnew: false,
            isLoading: false,

            // use optimistic uuid for drag drop; in a production app this could be a database id
            lineItems: this.state.lineItems.concat(),
          });
        } else {
          let axiosConfig = {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
              "Authorization": TOKEN
            }
          };

          var encodeURISafe = require('encodeuri-safe');
          // alert()
          let url = `${baseURL}item?branch_id=${BRANCH_ID}&&client_id=${USER_ID}&filter_value=${encodeURISafe.encodeURIComponent(this.state.lineItems[length].name)}`

          // let url = baseURL + `item?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&filter_value=` + this.state.lineItems[length].name
          axios.all([
            axios.get(url, axiosConfig),
          ]).then(axios.spread((itemResponse) => {
            this.setState({
              item: itemResponse.data.data.filter(item => item.status === 1),

            },
              function () {
                var data = [];

                //original item 
                let filter_items_original = this.state.lineItems_update.filter(item => item.name === this.state.item[0].item_name)
                let accumulative_original = filter_items_original.reduce(
                  (prev, cur) =>
                    (Number(prev) + Number(cur.quantity)),
                  0)
                // new state item
                let filter_items = this.state.lineItems.filter(item => item.name === this.state.item[0].item_name)
                let accumulative = filter_items.reduce(
                  (prev, cur) =>
                    (Number(prev) + Number(cur.quantity)),
                  0)

                if ((this.state.item[0].quantity - (accumulative - accumulative_original)) < 0) {
                  this.setState({
                    addnew: false,
                    isLoading: false
                  })
                  // this.handleRemove(length)
                  alert(`You current stock for item ${this.state.item[0].item_name} is not enough. Kindly restock`)
                } else {
                  this.setState({
                    addnew: false,

                  })
                  this.onSubmit(event)
                }
              })
          })).catch(error => {
            console.log('bayoo', error.response)
          })
        }
      } else {
        this.setState({
          addnew: false,
          // use optimistic uuid for drag drop; in a production app this could be a database id
          lineItems: this.state.lineItems.concat([
            {
              id: uuid(), // react-beautiful-dnd unique key
              name: '',
              description: '',
              reference: '',
              quantity: 0,
              discount_percentage: 0,
              tax_percentage: 0,
              price: 0.00,

            },
          ]),
        })
      }
    } else {
      this.onSubmit(event)

    }
  };




  handleRemoveLineItem = elementIndex => event => {
    // alert("You cant delete. Is disabled")
    this.setState({
      lineItems: this.state.lineItems.filter((item, i) => {
        return elementIndex !== i;
      }),
    });
    // alert(this.state.lineItems.filter((item, i) => { return elementIndex === i })[0].quantity)
    // updating invoice items ahd quantity for the items'
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: TOKEN,
      },
    };
    let url =
      baseURL +
      `invoiceitemdelete?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID +
      `&&filter_value=${this.state.lineItems.filter((item, i) => { return elementIndex === i })[0].id}&&item_name=` +
      this.state.lineItems.filter((item, i) => { return elementIndex === i })[0].name.toString()
      + "&&quantity=" + this.state.lineItems.filter((item, i) => { return elementIndex === i })[0].quantity.toString();
    axios.delete(url, axiosConfig).then(itemResponse => {
    });
  };

  handleReorderLineItems = newLineItems => {
    this.setState({
      lineItems: newLineItems,
    });
  };
  handleChangeInvoiceMessage = event => {
    this.setState({ message_invoice: event.target.value }, function () { });
    //alert(event.target.value)
  };

  handleChangeStatementMessage = event => {
    this.setState({ statement_message: event.target.value }, function () { });
    //alert(event.target.value)
  };

  handleFocusSelect = event => {
    event.target.select();
  };

  handlePayButtonClick = () => {
    alert('Not implemented');
  };

  formatCurrency = amount => {
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: "ABS",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount).replaceAll('ABS', this.state.currency);
  };

  calcTaxAmount = c => {
    return c * (localStorage.getItem('valuestax') / 100);
  };
  calcLineItemsTotal = () => {

    if (this.state.showtax == true) {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + cur.quantity * cur.price,
        0
      );
    } else {

      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.quantity * cur.price) / (1 + (cur.tax_percentage / 100))),
        0
      );
    }


  };

  calcLineItemDiscounts = () => {
    return this.state.discount_amount;
  };

  calcLineItemDelivery = () => {
    return parseFloat(this.state.delivery_amount);
  };

  calcGrandTotal = () => {

    return (
      (this.calcLineItemsTotal() +
        this.calcLineItemDelivery() +
        this.calcTaxTotal()) -
      this.calcLineItemDiscounts()
    );
  };

  onSelectChangesShort = value => {
    if (value != null) {

      this.setState(
        {
          ...this.state.editedInvoice,
          customer_id: value.value.toString(),
          customer_name: value.label.toString(),
          customer_nameinvoice2: value.customer_nameinvoice.toString(),
          customer_email: value.customer_email != null ? value.customer_email.toString() : '',
        });
    }
  };

  handleChange = e => {
    this.setState({
      ...this.state.editedInvoice,
      [e.target.name]: e.target.value,
    });

    console.log('updates', this.state.editedInvoice);
  };


  handleChangeType = value => {

    if (value != null) {
      this.setState(
        {
          terms: value.value.toString()
        });

      if (value.value.toString() === "Due in 15 days") {
        this.setState({
          due_date: moment(new Date(this.state.invoice_date)).add(15, 'days').toDate()
        })
      }
      else if (value.value.toString() === "Due in 30 days") {
        this.setState({
          due_date: moment(new Date(this.state.invoice_date)).add(30, 'days').toDate()
        })
      }

      else if (value.value.toString() === "Due in 6 months") {
        this.setState({
          due_date: moment(new Date(this.state.invoice_date)).add(6, 'months').toDate()
        })
      }
      else {
        this.setState({
          due_date: new Date(this.state.invoice_date)

        })
      }
    }
    else {
      this.setState(
        {
          due_date: new Date(this.state.invoice_date),
          terms: "",
        });
    }
  };

  getData = (queryString = "") => {
    // alert( localStorage.getItem("Invoice_No_Item"))
    this.setState({
      isLoading: false,
      isPageLoad: true
    })
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": TOKEN
      }
    };
    let endDate = moment(new Date()).format('YYYY-MM-DD')

    let url = baseURL + `sales_order_edit?sales_order_no=1&&estimate=0&&created_by=${USER_ID}&startDate="2019-03-03 00:00:00"&endDate="${endDate} 23:59:59"&${queryString}&&filter_value=${localStorage.getItem("Invoice_No_Item_EStimate")}`

    // alert(url)
    let url2 = baseURL + `invoiceitem?filter_value=` + localStorage.getItem("Invoice_No_Item") + `&client_id=` + USER_ID


    axios.all([
      axios.get(url, axiosConfig),
      axios.get(url2, axiosConfig),
    ]).then(axios.spread((invoiceResponse, invoiceResponse2) => {

      console.log("invoice item", invoiceResponse2)
      // alert(JSON.stringify(invoiceResponse2))
      this.setState({
        invoice: invoiceResponse.data.data,
        lineItems: invoiceResponse2.data.data,
        lineItems_update: invoiceResponse2.data.data
      }, function () {

        // var data = [];
        // for (let i = 0; i < currency.length; i++) {
        //   let mlabel = { label: currency[i].name + " (" + currency[i].currency + ")" };
        //   let mvalue = { value: currency[i].agnaist_ksh };
        //   data.push(Object.assign(mlabel, mvalue, currency[i]));
        //   this.setState({
        //     data: data,
        //   })

        // }





        this.setState({
          returnHome: false,
          invoice_no: this.state.invoice[0].sales_order_ref,

          sales_order_id: this.state.invoice[0].invoice_id,

          sales_order_ref: localStorage.getItem("Invoice_No_Item"),

          invoice_date: this.state.invoice[0].invoice_date,
          currencyValue: [{ label: this.state.invoice[0].currency_type, value: this.state.invoice[0].currency_type }],
          // terms: [{ label: this.state.invoice[0].terms, value: this.state.invoice[0].terms }],
          terms: this.state.invoice[0].terms,
          due_date: this.state.invoice[0].due_date,
          tax_exclusive: this.state.invoice[0].tax_exclusive.toString(),

          message_invoice: this.state.invoice[0].message_invoice,
          statement_message: this.state.invoice[0].statement_invoice,
          customer_email: this.state.invoice[0].email,
          amount: this.state.invoice[0].due_amount,
          due_amount: this.state.invoice[0].due_amount,
          tax_amount: this.state.invoice[0].tax_amount,
          due_period: this.state.invoice[0].due_period,
          status: this.state.invoice[0].status,
          discount_amount: this.state.invoice[0].discount_amount.toString().substring(4),
          delivery_amount: 0,


          currency: this.state.invoice[0].currency_type,
          invoice_currency: this.state.invoice[0].currency_type,
          customer_name: [{ label: this.state.invoice[0].customer_display_name, value: this.state.invoice[0].customer_display_name }],
          customer_nameUpdate: this.state.invoice[0].customer_display_name,
          tax: this.state.invoice[0].tax_amount,
          subtoal: this.state.invoice[0].sub_total,
          total: this.state.invoice[0].due_amount,
          loaded: false,
          isPageLoad: false,


        }, function () {
          if (this.state.tax_exclusive == 1) {

            this.setState({
              showtax: true
            })
          } else {
            this.setState({
              showtax: false
            })
          }
        })
      })
    })).catch(error => {
      console.log('bayoo', error.response)
    })
    // alert(JSON.stringify(this.state.lineItems))

  }
  formatCurrency1 = amount => {
    return new Intl.NumberFormat(this.locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  async onSubmit(e) {
    let footer;
    const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
      if (response.data.data.length > 0) {
        footer = { footer: response.data.data[0].footer_notes };
      } else {
        footer = { footer: "" };
      }
    }).catch(error => {
      footer = { footer: "" };
    })

    // e.preventDefault();
    // eslint-disable-next-line
    this.state.editedInvoice = {
      invoice_no: this.state.sales_order_ref,
      invoice_date: this.state.invoice_date,
      terms: this.state.terms,
      due_date: this.state.due_date,
      approved: e,
      message_invoice: this.state.message_invoice,
      statement_invoice: this.state.statement_message,
      customer_name: this.state.customer_nameUpdate,
      tax_exclusive: this.state.tax_exclusive,
      customer_email: this.state.invoice[0].email,
      currency_type: this.state.invoice_currency,
      amount1: this.formatCurrency1(this.calcGrandTotal()),
      amount: this.formatCurrency(this.calcGrandTotal()),
      due_amount: this.formatCurrency(this.calcGrandTotal()),
      tax_amount: this.formatCurrency(this.calcTaxTotal()),
      discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
      delivery_amount: this.formatCurrency(this.calcLineItemDelivery()),
      lineItems: this.state.lineItems,
      currency: this.state.currency,
      tax: this.formatCurrency(this.calcTaxTotal()),
      subtoal: this.formatCurrency(this.calcLineItemsTotal()),
      total: this.formatCurrency(this.calcGrandTotal()),
    };


    // alert(JSON.stringify(this.state.editedInvoice) )



    let formData = {
      id: this.state.sales_order_id,
      invoice_date: this.state.invoice_date,
      terms: this.state.terms,
      due_date: this.state.due_date,
      message_invoice: this.state.message_invoice,
      statement_invoice: this.state.statement_message,
      customer_name: this.state.customer_nameUpdate,
      customer_email: this.state.invoice[0].email,
      tax_exclusive: this.state.tax_exclusive,
      currency_type: this.state.invoice_currency,
      approved: e,
      sub_total: this.formatCurrency(this.calcLineItemsTotal()),
      amount: this.formatCurrency(this.calcGrandTotal()),
      due_amount: this.formatCurrency(this.calcGrandTotal()),
      tax_amount: this.formatCurrency(this.calcTaxTotal()),
      discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
      delivery_amount: (this.calcLineItemDelivery()),
      created_by: USER_ID,
      customer_id: this.state.invoice[0].customer_id,
    };
    // alert(JSON.stringify(this.state.editedInvoice))

    console.log("DATA", JSON.stringify(formData))
    this.setState({ isLoading: true });
    axios
      .put(baseURL + `sales_order`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: TOKEN,
        },
      })
      .then(response => {
        if (response.data.status) {
          this.setState({
            statusMessage: response.data.message,
            showError: false,
            isShowError: true,
          });

          let datas = this.state.lineItems;
          let data = [];
          for (let i = 0; i < datas.length; i++) {
            let innvoiceNo = { invoice_no: this.state.sales_order_ref };
            data.push(Object.assign(innvoiceNo, datas[i]));
            this.setState({
              data: data,
            });
            // console.log("DATA", datas[i])
          }
          console.log("brian", data)

          axios
            .post(baseURL_2 + 'updateinvoiceitems.php', this.state.lineItems_update)
            .then(response => {
              axios
                .post(baseURL_2 + 'invoiceupdate.php', data)
                .then(response => {
                  // alert(JSON.stringify(response))
                  this.setState({
                    statusMessage: 'Sales Order has been updated successfully',
                    isShowError: true,
                    isLoading: false,
                    showError: false,
                  });
                  window.setTimeout(() => {
                    this.setState({ isShowError: false, returnHome: true });
                    this._onButtonClick();
                  }, 2000);
                  window.scrollTo(0, 0);
                })
                .catch(error => {
                  console.log("Vccc", error)
                  window.scrollTo(0, 0);
                  // console.error('Error during service worker registration:', error);
                });
            })
            .catch(error => {
              console.log("Vccc", error)
              window.scrollTo(0, 0);
              // console.error('Error during service worker registration:', error);
            });



        } else {
          this.setState({
            statusMessage: response.data.message,
            showError: true,
            isShowError: false,
            isLoading: false,
          });
          window.scrollTo(0, 0);
        }
      })
      .catch(error => {
        console.log('bayoo', error.response);
        this.setState({
          statusMessage: error.response.data.message,
          showError: true,
          isShowError: false,
          isLoading: false,
        });
        window.scrollTo(0, 0);
      });
  }

  loadOptions = inputValue => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: TOKEN,
      },
    };
    this.setState({
      isLoading: true,
    });
    let url =
      baseURL +
      `customer?branch_id=${BRANCH_ID}&&client_id=` +
      USER_ID +
      `&&filter_value=${inputValue}`;
    axios.get(url, axiosConfig).then(itemResponse => {
      this.setState(
        {
          item: itemResponse.data.data,
        },
        function () {
          this.setState({
            isLoading: false,
            bayo: this.state.item &&
              (this.state.item.length > 0 || this.state.item.length === 0) &&
              this.state.item.map((countyItem, i) => ({
                label: countyItem.customer_display_name,
                customer_email: countyItem.email,
                value: countyItem.id,
              })),
          });
        }
      );
    });

  };


  onChangeOption(value) {
    console.log(value);
    if (value == 1) {
      this.setState({
        showtax: true,
        tax_inclusive: '0',
        tax_exclusive: 1,
      })
    } else {
      this.setState({
        showtax: false,
        tax_inclusive: '1',
        tax_exclusive: 0

      })
    }
    // alert(value)
  }
  calcTaxTotal = () => {
    if (this.state.showtax == true) {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.tax_percentage / 100) * (cur.quantity * cur.price)),
        0
      );
    } else {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.quantity * cur.price) - ((cur.quantity * cur.price) / (1 + (cur.tax_percentage / 100)))),
        0
      );
    }
  };


  handleChangeDisco = e => {
    this.setState({
      discount_amount: e.target.value,
    });

    console.log('updates', this.state.editedInvoice);
  };


  handleChangeDelivery = e => {
    this.setState({
      delivery_amount: e.target.value,
    });
  };

  handleCreate = inputValue => {
    const newValue = { value: inputValue.toLowerCase(), label: inputValue };
    this.setState({ newValue })
    // setOptions([...options, newValue]);
    // setValue(newValue);

  };


  handleChangeCurrency = value => {


    // alert(JSON.stringify(value.value.toString()))
    // alert(JSON.stringify(<CurrencyConverter from={'USD'} to={'CAD'} value={29} precision={2} date={'2010-12-22'}/>))
    if (value != null) {
      this.setState(
        {
          agnaist_ksh: value.value.toString(),
          currency: value.currency.toString(),
          invoice_currency: value.currency.toString(),
          currencylabel: value.currency.toString(),
        });
    } else {
      this.setState({
        agnaist_ksh: '',
        currency: '',
        invoice_currency: '',
        currencylabel: '',
      });
    }
  };

  render = () => {
    // alert(JSON.stringify(this.state.editedInvoice))

    // console.log('ITEMS',);
    const ref = React.createRef();
    const { showComponent, returnHome, hideComponent } = this.state;

    return (
      <div style={{ marginTop: "-20px" }} >
        {returnHome === true ? <Navigate to='/salesorderview' /> : null}
        {!hideComponent && (
          <div>
            <div ref={ref}>

              <Card>
                {this.state.isPageLoad ?
                  <div className="loader">
                    {LOADER}
                  </div>
                  :

                  <CardBody>
                    <div className="row">
                      <div className="col-md-6">
                        <h4><b>Edit {BUSINESS_TYPE === 'business' ? "Sales Order" : ""}</b></h4>
                      </div>
                      <div className="col-md-6">
                        <Link to="/salesorderview">
                          <Button className="pull-right btn-sm" onClick={this._onButtonClick}
                            color="primary" outline><i className="fa  fa-arrow-left"></i> {BUSINESS_TYPE === 'business' ? "Sales Order" : ""}
                          </Button>
                        </Link>

                      </div>
                    </div>

                    {this.state.showError
                      ? <div>

                        <Alert color="danger" className="alert--colored" icon>
                          <p>
                            <span className="bold-text">
                              {this.state.statusMessage}
                            </span>
                          </p>
                        </Alert>

                      </div>
                      : null}<br />

                    {this.state.isShowError
                      ? <div>

                        <Alert color="success" className="alert--colored" icon>
                          <p>
                            <span className="bold-text">
                              {this.state.statusMessage}
                            </span>
                          </p>
                        </Alert>

                      </div>
                      : null}<br />
                    <form className="form" >
                      <br /><br />
                      <div className="col-md-8 offset-2">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-2">
                              <label className="form-label">{BUSINESS_TYPE === 'business' ? "Invoice" : "Grantor"} Name</label>
                            </div>
                            <div className="col-md-10">

                              <Select
                                isClearable
                                options={this.Customer()}
                                onInputChange={this.loadOptions}
                                placeholder={BUSINESS_TYPE === 'business' ? "Search customer" : "search grantor"}
                                autosize={true}
                                onChange={this.onSelectChangesShort}
                                isLoading={this.state.isLoading}
                                value={this.state.customer_name}
                                // noOptionsMessage={() => 'nothing found'}
                                // loadingMessage={() => 'searching...'}   //minor type-O here
                                menuPortalTarget={document.body}
                                name="namffe"
                              /><br />
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="col-md-8 offset-2">
                        <br />
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-2">
                              <label className="form-label">{BUSINESS_TYPE === 'business' ? "Invoice" : "Ref"} No.</label>
                            </div>
                            <div className="col-md-10">
                              <input
                                id="input"
                                type="text"
                                className="form-control"
                                readOnly
                                name="invoice_no"
                                value={this.state.invoice_no}
                                required
                                placeholder="Invoice No"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 offset-2">
                        <br />
                        <Form.Group className="Focus-line">
                          <div className="row">
                            <div className="col-md-2">
                              <Form.Label>{BUSINESS_TYPE === 'business' ? "Invoice" : ""} date</Form.Label>
                            </div>
                            <div className="col-md-10 customDatePickerWidth">
                              <DatePicker
                                // selected={this.state.invoice_date}
                                label="DatePicker Label"
                                dateFormat="DD/MM/yyyy"
                                required
                                value={moment(this.state.invoice_date).format("DD MMM yyyy")}
                                className="form-control"
                                id="input"
                                onChange={value => this.onChangeInvoiceDate(value)}
                              />

                            </div>
                          </div>
                        </Form.Group>
                      </div>
                      {BUSINESS_TYPE === 'business' ?
                        <>
                          <div className="col-md-8 offset-2">
                            <br />
                            <Form.Group className="Focus-line">
                              <div className="row">
                                <div className="col-md-2">
                                  <Form.Label>Terms</Form.Label>
                                </div>
                                <div className="col-md-10">

                                  <CreatableSelect
                                    isClearable
                                    options={this.state.terms3}
                                    // onInputChange={this.loadOptions}
                                    placeholder={this.state.terms}
                                    autosize={true}
                                    // value={this.state.terms}

                                    onChange={this.handleChangeType}
                                    isLoading={this.state.isLoading}
                                    // noOptionsMessage={() => 'nothing found'}
                                    // loadingMessage={() => 'searching...'}   //minor type-O here
                                    menuPortalTarget={document.body}
                                    name="namffe"
                                  /><br />

                                </div>
                              </div>

                            </Form.Group>
                          </div>
                          <div className="col-md-8 offset-2">
                            <br />
                            <Form.Group className="Focus-line">
                              <div className="row">
                                <div className="col-md-2">
                                  <Form.Label>Due date</Form.Label>
                                </div>
                                <div className="col-md-10 customDatePickerWidth">
                                  <DatePicker
                                    // selected={this.state.due_date}
                                    label="DatePicker Label"
                                    dateFormat="DD/MM/yyyy"
                                    value={moment(this.state.due_date).format("DD- MMM yyyy")}

                                    className="form-control"
                                    id="input"
                                    onChange={value =>
                                      this.setState({ due_date: value })}
                                  />

                                </div>
                              </div>
                            </Form.Group>

                          </div>
                        </>
                        : null}

                      <div className="col-md-8 offset-2">
                        <br />
                        <Form.Group className="Focus-line">
                          <div className="row">
                            <div className="col-md-2">
                              <Form.Label>Currency</Form.Label>
                            </div>
                            <div className="col-md-10">


                              <Select
                                isClearable
                                options={this.state.data}
                                placeholder={this.state.currency}
                                noOptionsMessage={() => 'No currrency found'}
                                autosize={true}
                                required
                                isLoading={this.state.isLoading}
                                onChange={this.handleChangeCurrency}
                                openOnFocus={true}
                                menuPortalTarget={document.body}
                                name="currency"
                              />
                              {/* <p>N/B: <b style={{ color: "red" }}>Currency is one off. You won't be able to change it again</b></p> */}

                            </div>

                          </div>
                        </Form.Group>
                      </div>

                      <div className="col-md-12">

                        <div className="col-md-12">
                          <br />
                          <div className="col-md-3" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>

                            {/* <RadioGroup onChange={this.onChangeOption} value={this.state.tax_exclusive} horizontal>
                              <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                Tax Exclusive
                              </RadioButton>
                              <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                Tax Inclusive
                              </RadioButton>

                            </RadioGroup> */}
                          </div>
                          <br />
                          <LineItems
                            items={this.state.lineItems}
                            currencyFormatter={this.formatCurrency}
                            addHandler={this.handleAddLineItem}
                            showtax={this.state.showtax}
                            changeHandler={this.handleLineItemChange}
                            focusHandler={this.handleFocusSelect}
                            deleteHandler={this.handleRemoveLineItem}
                            reorderHandler={this.handleReorderLineItems}
                          />

                          <div className="col-md-12">

                            <div className={styles.totalContainer}>
                              {/* <form>
                            <div className={styles.valueTable}>
                              <div className={styles.row}>
                                <div className={styles.label}>Tax Rate (%)</div>
                                <div className={styles.value}>
                                  <input
                                    name="taxRate"
                                    type="text"
                                    id="#js-testInputs"
                                    value={localStorage.getItem('valuestax')}
                                    onChange={this.handleInvoiceChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </form> */}
                              <form></form>
                              <form>
                                <div className={styles.valueTable}>
                                  <div className={styles.row}>
                                    <div className={styles.label}>Subtotal</div>
                                    <div
                                      className={`${styles.value} ${styles.currency}`}
                                    >
                                      {this.formatCurrency(
                                        this.calcLineItemsTotal()
                                      )}
                                    </div>
                                  </div>
                                  {BUSINESS_TYPE === 'business' ?
                                    <div>
                                      <div className={styles.row}>
                                        <div className={styles.label}>
                                          Tax
                                        </div>
                                        <div
                                          className={`${styles.value} ${styles.currency}`}
                                        >
                                          {this.formatCurrency(this.calcTaxTotal())}
                                        </div>
                                      </div>

                                      <div className={styles.row}>
                                        <div className={styles.label}>Delivery Fee</div>
                                        <div
                                          className={`${styles.value} ${styles.currency}`}
                                        >
                                          <input
                                            id="input"
                                            type="number"
                                            className="form-control"
                                            name="delivery_amount"
                                            placeholder="Delivery Amount"
                                            value={this.state.delivery_amount}
                                            onChange={this.handleChangeDelivery}
                                          />
                                        </div>
                                      </div>


                                      <div className={styles.row}>
                                        <div className={styles.label}>Discount</div>
                                        <div
                                          className={`${styles.value} ${styles.currency}`}
                                        >
                                          <input
                                            id="input"
                                            type="number"
                                            className="form-control"
                                            name="discount_amount"
                                            placeholder="Discount Amount"
                                            value={this.state.discount_amount}
                                            onChange={this.handleChangeDisco}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    : null}

                                  <div className={styles.row}>
                                    <div className={styles.label}>{BUSINESS_TYPE === 'business' ? "Total Due" : "Total Received"}</div>
                                    <div
                                      className={`${styles.value} ${styles.currency}`}
                                    >
                                      {this.formatCurrency(this.calcGrandTotal())}
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            {/* 
                  <div className={styles.pay}>
                    <button className={styles.payNow} onClick={this.handlePayButtonClick}>Pay Now</button>
                  </div> */}
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 offset-2">
                        <br />
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-2">

                              <label className="form-label">
                                Message on {BUSINESS_TYPE === 'business' ? "Invoice" : "Income Receipt"}
                              </label>
                            </div>
                            <div className="col-md-10">
                              <textarea
                                name="message_invoice"

                                value={this.state.message_invoice}
                                style={{
                                  paddingTop: '20px',
                                  borderRadius: '5px',
                                  borderColor: 'hsl(0,0%,80%)',
                                }}
                                onChange={this.handleChangeInvoiceMessage}
                                className="col-md-12"
                                placeholder={BUSINESS_TYPE === 'business' ? "Hint: This will show up on the invoice" : "Hint: This will show up on the income receipts"}
                                id="message"
                                cols="col-md-1"
                                rows="1"
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>

                      <div className="col-md-8 offset-2">
                        <br />
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-2">

                              <label className="form-label">
                                Message on Statement
                              </label>
                            </div>
                            <div className="col-md-10">
                              <textarea
                                name="statement_message"

                                u value={this.state.statement_message}

                                style={{
                                  paddingTop: '20px',
                                  borderRadius: '5px',
                                  borderColor: 'hsl(0,0%,80%)',
                                }}
                                onChange={this.handleChangeStatementMessage}
                                className="col-md-12"
                                placeholder="Hint: If you send statements to customers, this will show up as their description for this invoice"
                                id="messages"
                                cols="col-md-1"
                                rows="1"
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />

                      <div className="col-md-12"><br />
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-2">
                              <Link to="/salesorderview">
                                <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                  Cancel
                                </Button>
                              </Link>

                            </div>

                            <div className="col-md-4">
                              <Button type="button"
                                onClick={
                                  () => { this.handleAddLineSubmit(2) }
                                } color="primary" outline className="btn-paypal btn-sm pull-right" >
                                {this.state.isLoading ? "Please Wait..." : "Save as Draft"}

                              </Button> &nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="col-md-4">
                              <Button type="button" color="primary" onClick={() => { this.handleAddLineSubmit(1) }} outline className="btn-paypal btn-sm pull-right" >
                                {this.state.isLoading ? "Please Wait..." : BUSINESS_TYPE === 'business' ? "Update" : "Update"}

                              </Button> &nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                    <br />

                  </CardBody>
                }
              </Card>
            </div>
          </div>
        )
        }
        {
          showComponent && (
            <div>
              <Link to='invoices' />

              <invoice_view />
            </div>
          )
        }
      </div >

    );
  };
}

export default SalesOrderEdit;
