/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import {
  BarChart, Bar, Cell, ResponsiveContainer,
} from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { baseURL } from '../../../../_helpers';
import axios from 'axios';
import { CONFIG, formatCurrency, LOADER, LOADER_DASH, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../../../_helpers/exports';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import TrendingDownIcon from 'mdi-react/TrendingDownIcon';
import { Button, Popover, UncontrolledPopover, PopoverBody } from 'reactstrap';
import {
  Progress,
} from 'reactstrap';

const NO1 = localStorage.getItem('entity');
const NO7 = localStorage.getItem('branch');
const NO2 = localStorage.getItem('staffs');
const NO3 = localStorage.getItem('students');
const NO4 = localStorage.getItem('parents');
const NO5 = localStorage.getItem('nfc-tags');
const NO6 = localStorage.getItem('devices');


const data = [
  { name: 'Parents', pv: NO4 },
  { name: 'Branches', pv: NO7 },
  { name: 'Schools', pv: NO1 },
  { name: 'Staffs', pv: NO2 },
  { name: 'Students', pv: NO3 },
  { name: 'NFC-Tags', pv: NO5 },
  { name: 'NFC-Devices', pv: NO6 },
];


class NewUsers extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    startDate: PropTypes.func.isRequired,
    endDate: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      loading: true
    };
  }

  handleClick = (item) => {
    const index = data.indexOf(item.payload);
    this.setState({
      activeIndex: index,
    });
  };
  componentDidMount() {
    this.getData()
  }

  getData() {
    const { startDate } = this.props;
    const { endDate } = this.props;
    const { reload } = this.props;

    this.setState({
      loading: true
    })


    let url = baseURL + `vendorbalance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${moment(startDate).format('YYYY-MM-DD')} 00:00:00"&endDate="${moment(endDate).format('YYYY-MM-DD')} 23:59:59"`
    let url2 = baseURL + `getbillamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${moment(startDate).format('YYYY-MM-DD')} 00:00:00"&endDate="${moment(endDate).format('YYYY-MM-DD')} 23:59:59"`
    let url3 = baseURL + `vendorcreditreport?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${moment(startDate).format('YYYY-MM-DD')} 00:00:00"&endDate="${moment(endDate).format('YYYY-MM-DD')} 23:59:59"`
    let url4 = baseURL + `paymentamountmade?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${moment(startDate).format('YYYY-MM-DD')} 00:00:00"&endDate="${moment(endDate).format('YYYY-MM-DD')} 23:59:59"`
    let url_summary = baseURL + `agingsummarybill?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${moment(startDate).format('YYYY-MM-DD')} 00:00:00"&endDate="${moment(endDate).format('YYYY-MM-DD')} 23:59:59"`
    let url_expense = baseURL + `expensesamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${moment(startDate).format('YYYY-MM-DD')} 00:00:00"&endDate="${moment(endDate).format('YYYY-MM-DD')} 23:59:59"`


    axios.all([
      axios.get(url, CONFIG),
      axios.get(url2, CONFIG),
      axios.get(url3, CONFIG),
      axios.get(url4, CONFIG),
      axios.get(url_summary, CONFIG),

      axios.get(url_expense, CONFIG)
    ]).then(axios.spread((vendorBalance, billAmount, vendorCredit, paymentMade, agingResponse, expenseResponse) => {

      this.setState({
        vendor_balance: vendorBalance.data.data[0].opening_balance,
        bill_amount: billAmount.data.data[0].amount,
        vendor_credit: vendorCredit.data.data[0].due_amount,
        payment_made: paymentMade.data.data[0].amount,
        aging: agingResponse.data.data,
        expense: expenseResponse.data.data[0].amount,

        loading: false
      })

      // alert(vendorCredit.data.data[0].due_amount)

      this.props.payable(

        (parseFloat(vendorCredit.data.data[0].due_amount) + parseFloat(paymentMade.data.data[0].amount))
        +
        (parseFloat(billAmount.data.data[0].amount) + parseFloat(vendorBalance.data.data[0].opening_balance))
      )



      let valueAddedExpenses = 0
      let countExpense = 0

      let valueAddedExpenses1 = 0
      let countExpense1 = 0

      let valueAddedExpenses2 = 0
      let countExpense2 = 0

      let valueAddedExpenses3 = 0
      let countExpense3 = 0

      let valueAddedExpenses4 = 0
      let countExpense4 = 0

      let valueAddedExpenses5 = 0
      let countExpense5 = 0

      for (let i = 0; i < this.state.aging.length; i++) {


        let str1 = this.state.aging[i].total_amount;
        countExpense = parseFloat(str1.replaceAll(",", ""))
        valueAddedExpenses += countExpense

        let str2 = this.state.aging[i].current_amount;
        countExpense1 = parseFloat(str2.replaceAll(",", ""))
        valueAddedExpenses1 += countExpense1

        let str3 = this.state.aging[i].overdue_15;
        countExpense2 = parseFloat(str3.replaceAll(",", ""))
        valueAddedExpenses2 += countExpense2

        let str4 = this.state.aging[i].overdue_15_30;
        countExpense3 = parseFloat(str4.replaceAll(",", ""))
        valueAddedExpenses3 += countExpense3

        let str5 = this.state.aging[i].overdue_30_45;
        countExpense4 = parseFloat(str5.replaceAll(",", ""))
        valueAddedExpenses4 += countExpense4

        let str6 = this.state.aging[i].overdue_45;
        countExpense5 = parseFloat(str6.replaceAll(",", ""))
        valueAddedExpenses5 += countExpense5
      }

      this.setState({
        total_amount: valueAddedExpenses.toFixed(2),
        current_amount: valueAddedExpenses1.toFixed(2),
        overdue_15: valueAddedExpenses2.toFixed(2),
        overdue_15_30: valueAddedExpenses3.toFixed(2),
        overdue_30_45: valueAddedExpenses4.toFixed(2),
        overdue_45: valueAddedExpenses5.toFixed(2),

        isPageLoad: false
      })


    })).catch(error => {

      console.log('jwtcheck', error.response)

      // window.location.href = "/log_in";
    })
  }
  render() {
    const { activeIndex } = this.state;
    const activeItem = data[activeIndex];
    const { t } = this.props;
    const { reload } = this.props;
    return (
      <Col lg={6} xs={12} xl={6}>
        {

          reload === true ?
            <div>
              {
                this.getData()
              }
            </div>
            :
            null}
        <Card>
          <h4 className="bold-text">Payables</h4>
          <CardBody className="dashboard__card-widget">
            <div className="mobile-app-widget">
              <Link to="/payment-bills">

                <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                  <p className="mobile-app-widget__total-stat">
                    {
                      this.state.loading == true ?
                        <p>{LOADER_DASH}</p>
                        :
                        `Total Expenses: ${formatCurrency(
                          ((parseFloat(this.state.bill_amount) + parseFloat(this.state.expense))))}`
                    }
                  </p>

                  <TrendingDownIcon className="dashboard__trend-icon" />
                </div>
              </Link>
              <Link to="/bills">
                <div className="mobile-app-widget__title">
                  <h5>Total Unpaid Bills : <b>{this.state.loading == true ?
                    <p>{LOADER_DASH}</p>
                    :
                    formatCurrency(((parseFloat(this.state.current_amount)))
                      +
                      (parseFloat(this.state.total_amount)))
                  }</b></h5>
                </div>
              </Link>
              <div className="progress-wrap progress-wrap--small progress-wrap--turquoise-gradient progress-wrap--label-top"
              >
                <Progress value={(((
                  parseFloat(this.state.overdue_15) +
                  parseFloat(this.state.overdue_15_30) +
                  parseFloat(this.state.current_amount) +
                  parseFloat(this.state.overdue_30_45) + parseFloat(this.state.overdue_45))) / (parseFloat(this.state.bill_amount))) * 100}><p className="progress__label">{((((
                    parseFloat(this.state.overdue_15) +
                    parseFloat(this.state.overdue_15_30) +
                    parseFloat(this.state.current_amount) +
                    parseFloat(this.state.overdue_30_45) + parseFloat(this.state.overdue_45)) / (parseFloat(this.state.bill_amount)))) * 100).toFixed(2)}%</p></Progress>
              </div>


              <div className="row">
                <div className="col-6">
                  <Link to="/agingsummarybills">
                    <p style={{ color: "blue" }}>CURRENT</p>
                    <h4>{
                      this.state.loading == true ?
                        <p>{LOADER_DASH}</p>
                        :
                        formatCurrency(this.state.current_amount)}</h4>
                  </Link>
                </div>
                <div className="col-6">


                  <p style={{ color: "red" }}>OVERDUE</p>

                  <Button id="PopoverLegacy" visibility="hidden" toggle={false} style={{ background: "white", border: "0px", padding: "0px" }} type="button">
                    <h4>
                      {

                        this.state.loading == true ?
                          <p>{LOADER_DASH}</p>
                          :
                          `Total Expenses ${formatCurrency(this.state.total_amount)}`} <i className="arrow down"></i></h4>

                  </Button>


                  <UncontrolledPopover style={{ width: "auto" }} trigger="legacy" placement="bottom" target="PopoverLegacy">
                    <PopoverBody>
                      <Link to="/agingsummarybills">
                        <div className="row">
                          <div className="col-6">
                            <p>
                              1-15 Days
                            </p>
                          </div>
                          <div className="col-6">
                            <p style={{ color: "blue" }}>{
                              this.state.loading == true ?
                                <p>{LOADER_DASH}</p>
                                :
                                formatCurrency(this.state.overdue_15)}
                            </p>
                          </div>
                        </div>
                      </Link>

                    </PopoverBody>

                    <PopoverBody> <Link to="/agingsummarybills">
                      <div className="row">
                        <div className="col-6">
                          <p>
                            15-30 Days
                          </p>
                        </div>
                        <div className="col-6">
                          <p style={{ color: "blue" }}>{
                            this.state.loading == true ?
                              <p>{LOADER_DASH}</p>
                              :
                              formatCurrency(this.state.overdue_15_30)}
                          </p>
                        </div>
                      </div>
                    </Link>
                    </PopoverBody>


                    <PopoverBody>
                      <Link to="/agingsummarybills">
                        <div className="row">
                          <div className="col-6">
                            <p>
                              35-45 Days
                            </p>
                          </div>
                          <div className="col-6">
                            <p style={{ color: "blue" }}>{
                              this.state.loading == true ?
                                <p>{LOADER_DASH}</p>
                                :
                                formatCurrency(this.state.overdue_30_45)}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </PopoverBody>


                    <PopoverBody> <Link to="/agingsummarybills">
                      <div className="row">
                        <div className="col-6">
                          <p>
                            Above 45 Days
                          </p>
                        </div>
                        <div className="col-6">
                          <p style={{ color: "blue" }}>{
                            this.state.loading == true ?
                              <p>{LOADER_DASH}</p>
                              :
                              formatCurrency(this.state.overdue_45)}
                          </p>
                        </div>
                      </div>
                    </Link>
                    </PopoverBody>

                  </UncontrolledPopover>

                </div>
              </div>


              {/* <div className="dashboard__total pull-right" >
                  <TrendingUpIcon className="dashboard__trend-icon" />
                  <p className="dashboard__total-stat" style={{ fontSize: "12px" }}>
                  </p>
                </div> */}
            </div>
          </CardBody>
          <div className='row'>
            <div className='col-12 '>
              <Link className='Links pull-right' to='/create-expense'>CREATE EXPENSE ?</Link>
            </div>
            {/* <div className='col-5 pull-right'>
              <Link className='Links' to='/add-payment'>RECORD PAYMENT RECEIVED?</Link>
            </div>
            <div className='col-4 pull-right'>
              <Link className='L4nks' to='/add-payment'>CREATE CASH SALE?</Link>
            </div> */}

          </div>

        </Card>

      </Col >
    );
  }
}

export default withTranslation('common')(NewUsers);
