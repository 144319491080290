import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { ROLE, TOKEN, USER_ID } from '../../_helpers/exports';
import { Link, Navigate } from 'react-router-dom';
import * as moment from 'moment';
import addUser from './addUser';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import adduserclient from './adduserclient';

export class ClientsUsers extends React.Component {
    constructor(props) {
        super(props);
        this._onButtonClick = this._onButtonClick.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnCliccAudit = this.handleOnCliccAudit.bind(this);

        this.columns = [
            {
                key: "name",
                TrOnlyClassName: 'tsc',
                text: "Name",
                className: "tsc",
                align: "left"
            },
            {
                key: "msisdn",
                TrOnlyClassName: 'tsc',
                text: "Phone Number",
                className: "tsc",
                align: "left"
            },
            // {
            //     key: "username",
            //     TrOnlyClassName: 'tsc',
            //     text: "Username",
            //     className: "tsc",
            //     align: "left"
            // },

            {
                key: "email",
                TrOnlyClassName: 'tsc',
                text: "Email",
                className: "tsc",
                align: "left"
            },

            {
                key: "industry",
                TrOnlyClassName: 'tsc',
                text: "Industry",
                className: "tsc",
                align: "left"
            },

            {
                key: "admins",
                TrOnlyClassName: 'tsc',
                text: "User Role",
                className: "tsc",
                align: "left"
            },

            {
                key: "dates",
                text: "Date Created",
                TrOnlyClassName: 'tsc',
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >

                            {ROLE == 1 ?
                                <div>

                                    <button className="btn btn-primary btn-sm"
                                        title="Audit Trail"
                                        style={
                                            { marginRight: '10px' }}

                                        onClick={
                                            () => { if (window.confirm('Are you sure you want to resend email to this client?')) this.onResend(record) }
                                        } > Email
                                    </button>

                                    <button className="btn btn-primary btn-sm"
                                        title="Audit Trail"
                                        style={
                                            { marginRight: '10px' }}
                                        onClick={
                                            () => { this.handleOnCliccAudit(record) }} > Audit Trail
                                    </button>
                                    <button className="btn btn-primary btn-sm"
                                        title="Audit Trail"
                                        style={
                                            { marginRight: '10px' }}
                                        onClick={
                                            () => { this.handleOnClickDocument(record) }} > Documents
                                    </button>
                                </div>
                                : null}

                            {record.status === 0 ?
                                <button className="btn btn-primary btn-sm"
                                    title="Eith details"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleOnClick2(record) }} >
                                    Approve
                                </button>
                                : null}
                            {record.status === 1 ?

                                <button className="btn btn-danger btn-sm"
                                    title="Deactive"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleOnClick(record) }} > Deactive
                                </button>
                                : null}


                        </Fragment>
                    );
                }
            }

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            button: {
                excel: true,
                csv: true
            },
            filename: "client_users",
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            auditTrail: false,
            document:false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }
    componentDidMount() {
        this.getData();
    }

    async handleOnClick2(record) {
        console.log("hoge!");
        if (await confirm("Proceed in activating this account?")) {
            this.onApprove(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Proceed in deactivating this account?")) {
            this.onDeactivate(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }


    async handleOnCliccAudit(record) {
        localStorage.setItem("client_audit_id", record.id);
        localStorage.setItem("client_audit_name", record.name);
        this.setState({
            auditTrail: true
        })

    }

    async handleOnClickDocument(record) {
        localStorage.setItem("client_audit_id", record.id);
        localStorage.setItem("client_audit_name", record.name);
        this.setState({
            document: true
        })

    }
    getData = (queryString = "") => {

        queryString = ""
        this.setState({
            isLoading: true,
        })
        // alert(TOKEN)
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `client?filter_value=${localStorage.getItem("client_id_admin")}`
        console.log("query", url);
        // alert(localStorage.getItem("customerID"))

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                isLoading: false
            }, function () {
                var data = [];
                console.log("BUG", this.state.admins)
                for (let i = 0; i < this.state.admins.length || 0; i++) {
                    this.setState({
                        total: branchResponse.data.total
                    })
                    // eslint-disable-next-line
                    let mail = { name: this.state.admins[i].first_name + " " + this.state.admins[i].last_name };
                    let user_role = this.state.admins[i].admin_role
                    let admin_role;
                    if (user_role === "1") {
                        admin_role = { admins: "Admin" }
                    } else {
                        admin_role = { admins: "Accountant" }
                    }
                    // eslint-disable-next-line
                    let date = { dates: moment(this.state.admins[i].created_at).format("DD/MM/YYYY") };
                    data.push(Object.assign(mail, admin_role, date, this.state.admins[i]));
                    this.setState({
                        data: data
                    })
                    console.log("bugs", data);
                }
            }
            );
        }))

    }
    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onApprove(record) {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };

        console.log("axios", axiosConfig)
        axios.put(baseURL + `clientactivate/` + record.id, {}, axiosConfig).then(response => {
            console.log("true",)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });

                let formData2 =
                {
                    "name": record.first_name + " " + record.last_name,
                    "email": record.email,
                    "token": TOKEN
                }
                axios.post(baseURL_2 + 'emailApprove.php', formData2)
                    .then((response) => {

                    }).catch(error => {
                        this.setState({ statusMessage: error.response === undefined ? window.location.href = "/log_in" : "Something went wrong", showError: true, isShowError: false, isLoading: false });
                        window.scrollTo(0, 0)
                        // console.error('Error during service worker registration:', error);
                    });
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false }, function () {
                    // window.setTimeout(() => {
                    //     this.getData();
                    //     this.setState({ isShowError: false, showError: false })
                    // }, 2000);
                });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }



    onResend(record) {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };

        let formData2 =
        {
            "name": record.first_name + " " + record.last_name,
            "email": record.email,
            "token": TOKEN
        }
        axios.post(baseURL_2 + 'emailconfirmation.php', formData2)
            .then((response) => {

                alert("Success")

            }).catch(error => {
                this.setState({ statusMessage: error.response === undefined ? window.location.href = "/log_in" : "Something went wrong", showError: true, isShowError: false, isLoading: false });
                window.scrollTo(0, 0)
                // console.error('Error during service worker registration:', error);
            });
    }


    onDeactivate(record) {

        console.log("Delete Record", record);
        axios.put(baseURL + `clientdeactivate/` + record.id, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
                let formData2 =
                {
                    "name": record.first_name + " " + record.last_name,
                    "email": record.email,
                    "token": TOKEN
                }
                axios.post(baseURL_2 + 'emailReject.php', formData2)
                    .then((response) => {

                    }).catch(error => {
                        this.setState({ statusMessage: error.response === undefined ? window.location.href = "/log_in" : "Something went wrong", showError: true, isShowError: false, isLoading: false });
                        window.scrollTo(0, 0)
                        // console.error('Error during service worker registration:', error);
                    });

            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false, showError: false })
                    }, 2000);
                });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }
    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: false,

        });
    }

    render() {
        const { showComponent, hideComponent, auditTrail, document } = this.state;
        // console.log("Load", isLoading);
        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        <adduserclient />
                    </div>
                )}
                {!hideComponent && (


                    <div>
                        < Card >
                            {auditTrail == true ?
                                <Navigate to="/auditrail" />
                                : null
                            }

                            {document == true ?
                                <Navigate to="/admin-documents" />
                                : null
                            }
                            <CardBody >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>Users</b></h4>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/users">
                                            <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                                Clients
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col-md-10 offset-1">
                                            <button className="pull-right btn-sm" title="refresh"
                                                style={{ background: "white", border: "0px" }} onClick={this.getData}>
                                                <RefreshIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>

                                </div> : null}

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div>

                                ) : null}<br></br>

                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={this.state.isLoading}
                                    total_record={this.state.total}
                                    onChange={this.tableChangeHandler} />
                            </CardBody>

                        </Card>
                    </div>

                )
                }
            </div>
        )
    }
}