import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { CURRENCY, TOKEN, USER_ID, ROLE, USER_ID_ACC, formatCurrency } from '../../_helpers/exports';
import * as moment from 'moment';
// import CustomerCreate from './createItem';
import { Link } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import { Banking } from './banking';
import Alert from '../../shared/components/Alert';
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";

export class BankDetails extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick3 = this._onButtonClick3.bind(this);
        this._onButtonClick5 = this._onButtonClick5.bind(this);

        this.handleOnClick = this.handleOnClick.bind(this);

        this.handleOnClick = this.handleOnClick.bind(this);

        this.columns = [

            {
                key: "reference",
                TrOnlyClassName: 'tsc',
                text: "Reference",
                className: "tsc",
                align: "left"
            },
            {
                key: "type",
                TrOnlyClassName: 'tsc',
                text: "Account",
                className: "tsc",
                align: "left"
            },

            {
                key: "mamount_received",
                TrOnlyClassName: 'tsc',
                text: "Money In",
                className: "tsc",
                align: "left"
            },

            {
                key: "maccount_made",
                TrOnlyClassName: 'tsc',
                text: "Money Out",
                className: "tsc",
                align: "left"
            },
            {
                key: "description",
                TrOnlyClassName: 'tsc',
                text: "Description",
                className: "tsc",
                align: "left"
            },
            {
                key: "date_date",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        // eslint-disable-next-line
                        <Fragment className="center" >

                            <button className="btn btn-primary  btn-sm"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.toggleModalReset(record) }} >
                                Edit Amount
                            </button>

                            <button className="btn btn-danger  btn-sm"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}

                                onClick={
                                    () => {
                                        if (window.confirm('Are you sure you want to delete this record?'))
                                            this.onDelete(record)
                                    }} >
                                Delete
                            </button>

                        </Fragment>
                    );
                }
            }


        ];

        this.config = {
            key_column: "tsc",
            length_menu: [100, 200, 500],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: true,
                csv: true
            },
            filename: "customer",
            page_size: 100,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search here",
                no_data_text: "Oooops no customer found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            isPageLoad: true,
            isLoading: true,
            showError: false,
            isShowError: false,
            showComponent: false,
            _onShow: false,
            value: "This Year",
            showEdit: false,
            hideComponent: false,
            data: []
        }
    }

    async handleOnClick(record) {
        if (await confirm("Are your sure?")) {
            this.onDelete(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }



    onDelete(record) {
        // alert(record.id)


        let formData = {
            "account_type": record.account_type,
            "amount_received": record.amount_received,
            'open_type': record.type,
            'id': record.id,
            "created_by": USER_ID
        }
        // alert(JSON.stringify(formData))
        axios.post(baseURL + `delete_amount`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 3000);
                });
            }
            else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData("", moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                    this.setState({ isShowError: false })
                }, 4000);
            }
        }, error => {
            // alert(JSON.stringify(error))
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData("", moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                this.setState({ isShowError: false })
            }, 4000);
        });
    }


    closeModalUpload = e => {
        this.setState({
            isOpen: false,
        });
    };

    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {

        this.getData();
    }


    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = `${baseURL}banking_details?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&&${queryString}&&filter_v=${localStorage.getItem("name_account_bank")}`;
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((itemResponse) => {
            // alert(JSON.stringify(itemResponse))
            this.setState({
                item: itemResponse.data.data,
                item_total: itemResponse.data.total,
                isLoading: false,
            },
                function () {
                    var data = [];

                    this.setState({
                        total: itemResponse.data.data.length
                    })
                    if (this.state.item.length > 0) {
                        for (let i = 0; i < this.state.item.length; i++) {
                            let mamount_received = { mamount_received: formatCurrency(this.state.item[i].amount_received) };
                            let maccount_made = { maccount_made: formatCurrency(this.state.item[i].amount_made) };
                            let dates = { date_date: (moment.utc(this.state.item[i].dates).format('DD/MM/YYYY HH:mm:ss')) };
                            data.push(Object.assign(mamount_received, maccount_made, dates, this.state.item[i]));
                            this.setState({
                                data: data.filter(person => person.type != 0)
                            })
                        }
                    } else {
                        this.setState({
                            data: data
                        })
                    }

                })
        })).catch(error => {
            console.log('bayoo', error)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showEdit: false,
        });
    }

    _onButtonClick5() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showEdit: false,
        });
    }

    _onButtonClick3() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            _onShow: false,
            showModals: false
        });
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' +
                    encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    handleProfile = event => {

        this.setState({ amount: event.target.value });
    };

    toggleModalReset = e => {
        // localStorage.setItem("name_account_bank", record.account_type)

        // alert(e.id)
        this.setState({
            isOpen: true,
            open_type: 'reset',
            account_type: e.type,
            amount: e.amount_received,
            account_id: e.id
        });
    };


    submitProfile = event => {
        event.preventDefault();
        if (this.state.amount > 0) {

            let formData = {
                "account_type": this.state.account_type,
                "amount_received": this.state.amount,
                'open_type': this.state.open_type,
                'id': this.state.account_id,
                "created_by": USER_ID
            }
            console.log("DATA_AMOUNT", JSON.stringify(formData))
            this.setState({ isLoading: true });
            axios.put(baseURL + `update_amount`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': TOKEN
                },
            }).then((response) => {
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                    window.scrollTo(0, 0)
                    window.setTimeout(() => {
                        this.setState({ isShowError: false, isOpen: false });
                        this.getData(this.state.start, this.state.end);
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                }
            })
                .catch((error) => {
                    this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
                })
        } else {
            alert("Enter a valid amount")
        }
    }



    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        const { showEdit } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
                    <div>


                        {this.state.showError ? <div >

                            <Alert color="danger" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>


                        </div> : null}<br></br>

                        {this.state.isShowError ? (
                            <div>

                                <Alert color="success" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>
                            </div>
                        ) : null}
                        <br />
                        < Card >
                            <CardBody >
                                <div className="panel-body" >

                                    <Modal
                                        isOpen={this.state.isOpen}
                                        onRequestClose={e => {
                                            this.closeModalUpload(e);
                                        }}
                                        contentLabel="My dialog"
                                        className="mymodal"
                                        onAfterOpen={() => {
                                            document.body.style.overflow = 'hidden';
                                        }}
                                        onAfterClose={() => {
                                            document.body.removeAttribute('style');
                                        }}
                                        overlayClassName="myoverlay"
                                        closeTimeoutMS={500}
                                    >
                                        <MDBCloseIcon onClick={this.closeModalUpload} />
                                        <h4><b>Enter New Amount</b></h4><br />


                                        {this.state.showError ? <div >

                                            <Alert color="danger" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>


                                        </div> : null}<br></br>

                                        {this.state.isShowError ? (
                                            <div>

                                                <Alert color="success" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>
                                            </div>
                                        ) : null}
                                        <br />
                                        <input
                                            className="form-group"
                                            type="number"
                                            required
                                            value={this.state.amount}
                                            name="amount"
                                            placeholder="New Amount"
                                            onChange={this.handleProfile}
                                        />
                                        <br />
                                        <div className="col-6 offset-3">
                                            <br />
                                            <Button color="primary" className="btn-sm " onClick={this.submitProfile} >
                                                {this.state.isLoading1 ? "Updating..." : "Submit"}
                                                <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;

                                            <br />
                                        </div>

                                    </Modal>

                                    <div className="row">

                                        <div className="col-md-6">
                                            <h4>Bank Account<b> {localStorage.getItem("name_account_bank")}</b></h4><br />

                                        </div>
                                        <div className="col-md-6">
                                            <Link to="/banking">
                                                <Button className="pull-right" color="primary
                                                    " onClick={this._onButtonClick} outline>Bank Accounts
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>

                                    <br />


                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.item_total}
                                        onChange={this.tableChangeHandler} />

                                </div>
                            </CardBody>

                        </Card>
                    </div>
                )
                }
                {
                    showComponent && (
                        <div>
                            <Banking />
                        </div>
                    )
                }
            </div >
        )
    }
}