import React, { Component } from 'react';
import styles from './Invoice.module.scss';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { BRANCH_ID, CURRENCY, PROFILE_URL, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { baseURL, baseURL_2 } from '../../_helpers';
import Alert from '../../shared/components/Alert';
import { Link, Navigate } from 'react-router-dom';
import $ from 'jquery';
import CreatableSelect from 'react-select/creatable';
import * as moment from 'moment';
import ReactDatatable from '@mkikets/react-datatable';
import { savePaymentSend } from './pdfReceiptSend';
import { groupedOptions_two } from './paidthrough_recevied';
import { func } from 'prop-types';

window.$ = window.jQuery = require('jquery');
require('../../_assets/jss/sms_counter.js');

class Payment_Received_Edit extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this._onButtonClick = this._onButtonClick.bind(this);
        this.columns = [
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "invoice_no",
                TrOnlyClassName: 'tsc',
                text: "Invoice#",
                className: "tsc",
                align: "left"
            },
            {
                key: "amount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "due_amount",
                TrOnlyClassName: 'tsc',
                text: "Due Amount",
                className: "tsc",
                align: "left"
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: false,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: false,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Invoice no...",
                no_data_text: "No invoice",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
    }

    locale = 'en-US';
    currency = CURRENCY;

    state = {
        taxRate: 0.00,
        invoice_no: 'IN-29333',
        payment_date: new Date(localStorage.getItem('payment_received_date')),
        due_date: new Date(),
        editedInvoice: {},
        amount_received: 0.00,
        amount_received_paid: 0.00,
        loaded: true,
        reference: "abc",
        payment_mode: localStorage.getItem("payment_received_mode"),
        payment_id: localStorage.getItem('payment_received_id'),
        deposit_to: localStorage.getItem("payment_received_deposit"),
        optionselected: { label: localStorage.getItem("payment_received_mode"), value: localStorage.getItem("payment_received_mode") },
        optionselected2: { label: localStorage.getItem("payment_received_deposit"), value: localStorage.getItem("payment_received_deposit") },

        isPageLoad: true,
        isSubmitted: false,
        isLoadCustomer: false,
        returnHome: false,
        lineItems: [
            {
                id: 'initial', // react-beautiful-dnd unique key
                name: '',
                description: '',
                quantity: 0,
                discount_percentage: 0,
                tax_percentage: 0,
                price: 0.00,
            },
        ],


    };
    async componentDidMount() {
        $('#message').countSms('#sms-counter');

        this.getData();

        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = "${PROFILE_URL}?canvas=true";
        }

        const [customerResponse, paymentResponse, DepositTOResponse, bakResponse] = await Promise.all([
            axios.get(baseURL + `customer?branch_id=${BRANCH_ID}&&filter_value=&page_number=1&page_size=1000&sort_order=false&client_id=` + USER_ID, {
                headers: { Authorization: TOKEN },
            }),
            axios.get(baseURL + `payment_method`, {
                headers: { Authorization: TOKEN },
            }),
            axios.get(baseURL + `deposit_to`, {
                headers: { Authorization: TOKEN },
            })
        ]);
        this.setState({
            returnHome: false,
            customerResponse: customerResponse.data.data,
            paymentResponse: paymentResponse.data.data,
            DepositTOResponse: DepositTOResponse.data.data,
            isPageLoad: false

        }, function () {
            var data = [];
            if (this.state.DepositTOResponse.length > 0) {
                for (let i = 0; i < this.state.DepositTOResponse.length; i++) {
                    let label = { label: this.state.DepositTOResponse[i].name };
                    let value = { value: this.state.DepositTOResponse[i].name };
                    data.push(Object.assign(label, value));
                    this.setState({
                        datasd: data
                    })
                }
            } else {
                this.setState({
                    datasd: data
                })
            }
            localStorage.setItem("paidthrough", JSON.stringify(data))
        })
    }


    handleLineItemChange = elementIndex => event => {
        let lineItems = this.state.lineItems.map((item, i) => {
            if (elementIndex !== i) return item;
            return { ...item, [event.target.name]: event.target.value };
        });
        this.setState({ lineItems });
    };

    Customer() {

        return (
            this.state.customerResponse &&
            (this.state.customerResponse.length > 0 || this.state.customerResponse.length === 0) &&
            this.state.customerResponse.map((countyItem, i) => ({
                label: countyItem.customer_display_name,
                customer_email: countyItem.email,
                customer_nameinvoice: countyItem.customer_display_name,
                value: countyItem.id,
            }))
        );
    }

    PaymentMethod() {
        // alert(JSON.stringify(this.state.paymentResponse))

        return (
            this.state.paymentResponse &&
            (this.state.paymentResponse.length > 0 || this.state.paymentResponse.length === 0) &&
            this.state.paymentResponse.map((countyItem, i) => ({
                label: countyItem.name,
                value: countyItem.name

            }))
        );
    }


    Depositto() {
        // console.log("Deposit", )
        return (
            this.state.DepositTOResponse &&
            (this.state.DepositTOResponse.length > 0 || this.state.DepositTOResponse.length === 0) &&
            this.state.DepositTOResponse.map((countyItem, i) => ({
                label: countyItem.name,
                value: countyItem.name
            }))
        );
    }

    handleFocusSelect = event => {
        event.target.select();
    };

    formatCurrency = amount => {
        return new Intl.NumberFormat(this.locale, {
            style: 'currency',
            currency: "ABS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount).replaceAll('ABS', CURRENCY);
    }; filter_value

    calcTaxAmount = c => {
        return c * (localStorage.getItem('valuestax') / 100);
    };

    calcLineItemsTotal = () => {
        return this.state.lineItems.reduce(
            (prev, cur) => prev + cur.quantity * cur.price,
            0
        );
    };

    calcLineItemDiscounts = () => {
        if (this.state.data != undefined) {
            return this.state.data.reduce(
                (prev, cur) => prev + parseFloat(cur.amount),
                0);
        }
    };

    calcTaxTotal = () => {
        return this.state.lineItems.reduce(
            (prev, cur) => prev + ((cur.tax_percentage / 100) * (cur.quantity * cur.price)),
            0
        );
    };

    calcGrandTotal = () => {
        return (
            Math.round((this.state.amount_received -
                this.calcLineItemDiscounts()), 2)
        )

    };


    calcGrandTotalExcess = () => {
        return (
            Math.round((this.state.amount_received -
                this.calcLineItemDiscounts()), 2))
    };

    onSelectChangesShort = value => {

        if (value != null) {

            // this.getData(value.value.toString())
            // this.setState(
            //     {
            //         ...this.state.editedInvoice,
            //         customer_id: value.value.toString(),
            //         customer_name: value.label.toString(),
            //         customer_nameinvoice2: value.customer_nameinvoice.toString(),

            //         out_of_balance: value.out_of_balance,
            //         isLoadCustomer: true,
            //         customer_email: value.customer_email.toString(),
            //     });


        }
    };

    handleChange = e => {
        this.setState({
            ...this.state.editedInvoice,
            [e.target.name]: e.target.value,
        });

        console.log('updates', this.state.editedInvoice);
    };

    getData = (queryString) => {

        localStorage.setItem("DATAARRAY", JSON.stringify([]))
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        // let url_record = baseURL + `invoicepaid_record?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=`+USER_ID+`&&filter_value=`+

        let url_record = baseURL + `invoicepaid_record?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID + `&&filter_value=` + localStorage.getItem("payment_received_id")

        // alert(url_record)
        // let url = baseURL + `invoiceunpaid?filter_value=${queryString}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID
        axios.all([
            axios.get(url_record, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            this.setState({
                invoice: invoiceResponse.data.data.filter(person => person.amount > 0),
                isLoading: false,
            },
                function () {

                    var data = [];
                    this.setState({
                        total: invoiceResponse.data.total
                    })
                    var names = this.state.invoice.filter(person => person.amount > 0).map(function (item) {
                        return item['invoice_no'];
                    });

                    let valueAddedExpenses = 0
                    let countExpense = 0

                    var array = names
                    var array1 = array.toString();
                    var array3 = array1.replace(/,/g, ", ");
                    if (this.state.invoice.length > 0) {
                        console.log("invoicetype", this.state.invoice)
                        for (let i = 0; i < this.state.invoice.length; i++) {
                            localStorage.setItem("DATAARRAY", JSON.stringify(data))
                            let due_date = moment(this.state.invoice[i].due_date);
                            let dueDateFormated = due_date.format('DD MMM, YYYY');
                            let date1 = { dates1: dueDateFormated };
                            let due_amount_updated;

                            var str = this.state.invoice[i].due_amount;
                            var mslice = str.substring(0, 3);



                            let mcurrency = { currency_type: mslice };
                            // s = s.replace(/^+/, "");
                            // var str = 
                            // eslint-disable-next-line
                            str = str.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                            var num = parseFloat(str);
                            due_amount_updated = { due_amount_updated: num };

                            var str_amount = this.state.invoice[i].amount;
                            // s = s.replace(/^+/, "");
                            // var str = 
                            // eslint-disable-next-line
                            str_amount = str_amount.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                            var num_amount = parseFloat(str_amount);

                            let mclientid = { client_id: USER_ID }


                            var invoice_amount = this.state.invoice[i].invoice_amount;
                            invoice_amount = invoice_amount.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                            var invoice_amount_new = parseFloat(invoice_amount);


                            countExpense = parseFloat(invoice_amount_new)

                            valueAddedExpenses += countExpense



                            let invoice_amount_updated = { invoice_amount_updated: invoice_amount_new };


                            let previous_amount = { previous_amount: num + parseFloat(this.state.invoice[i].amount) };

                            let date = { dates: (moment.utc(this.state.invoice[i].invoice_date).format('DD MMM, YYYY')) };

                            let payment_id = { payment_id: localStorage.getItem('payment_received_id') };


                            let due_amount_original = { due_amount_original: num_amount };

                            var amount_string = this.state.invoice[i].invoice_no;

                            var mslice_amount = amount_string.substring(0, 2);

                            let minvoice_no = { minvoice_no: mslice_amount };


                            // alert(mslice_amount.toString)

                            data.push(Object.assign(date1, date, mcurrency, minvoice_no, due_amount_original, invoice_amount_updated, payment_id,
                                mclientid, previous_amount, due_amount_updated, this.state.invoice[i]));

                            this.setState({
                                data: data.filter(person => person.amount > 0),
                                invoice_no: array3
                            })
                            localStorage.setItem("DATAARRAY", JSON.stringify(data))
                            this.setState({
                                amount_received: ((data.filter(person => person.amount > 0).reduce((prev, cur) => prev + parseFloat(cur.amount), 0)))
                            }, function () {
                            })
                            console.log("bugs", data.filter(person => person.amount > 0));
                        }

                    } else {
                        this.setState({
                            data: data,
                            amount_received: this.state.out_of_balance

                        })
                        // alert(JSON.stringify(this.state.invoice))
                        localStorage.setItem("DATAARRAY", JSON.stringify(data))

                    }


                    this.setState({
                        total_amount_added: valueAddedExpenses
                    }, function () {
                        // alert(this.state.total_amount_added)
                    })


                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }


    handleChangeType = value => {

        if (value != null) {
            this.setState(
                {
                    terms: value.value.toString(),
                });
        }
        else {
            this.setState(
                {
                    terms: "",
                });
        }
    };

    handleChangePayment = value => {
        if (value != null) {
            this.setState(
                {
                    optionselected: value,
                    payment_mode: value.value.toString(),
                });
        }
        else {
            this.setState(
                {
                    payment_mode: "",
                });
        }
    };

    handleChangeDeposit = value => {

        if (value != null) {
            this.setState(
                {
                    optionselected2: value,
                    deposit_to: value.value.toString(),
                });
        }
        else {
            this.setState(
                {
                    deposit_to: "",
                });
        }
    };

    onSubmit(e) {
        // e.preventDefault();
        // eslint-disable-next-line
        let invoice_no_new;
        invoice_no_new = this.state.invoice_no;
        let formData = {
            invoice_no: invoice_no_new,
            amount_received: this.state.amount_received,
            payment_date: this.state.payment_date,
            payment_mode: this.state.payment_mode,
            deposit_to: this.state.deposit_to,
            update_amount: this.state.total_amount_added,
            reference: this.state.reference,
            notes: this.state.notes,
            id: localStorage.getItem('payment_received_id'),
            amount_inexcess: this.calcGrandTotal(),
            customer_id: this.state.customer_id
        };

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        this.setState({ isSubmitted: true });
        axios
            .put(baseURL + `payment_received`, formData, axiosConfig)
            .then(response => {

                // alert("bayo" + this.state.total_amount_added)
                if (response.data.status) {
                    // alert(JSON.stringify(formDataOpenBalance))
                    axios.post(baseURL_2 + 'updateiNVOICEPAYMENTEdit.php', this.state.data).then((statusresponse) => {
                        this.setState({
                            statusMessage: "Success",
                            showError: false, isShowError: true, isSubmitted: false
                        });
                        window.scrollTo(0, 0)
                        window.setTimeout(() => {
                            this.setState({ isShowError: false });
                            this._onButtonClick();
                        }, 2000);
                        console.log("SUBMIT_DATA", statusresponse)
                        // alert(mbalance)
                    }).catch((error) => {
                        console.log("SUBMIT_DATA", error.response)

                        // alert(JSON.stringify(error.response))

                    })
                }
                else {
                    this.setState({
                        statusMessage: response.data.message,
                        showError: true,
                        isShowError: false,
                        isSubmitted: false,
                    });
                    window.scrollTo(0, 0);
                }
            })
            .catch(error => {
                console.log('bayoo', error.response);
                this.setState({
                    statusMessage: error.response.data.message,
                    showError: true,
                    isShowError: false,
                    isSubmitted: false,
                    isLoading: false,
                });
                window.scrollTo(0, 0);
            });

        // }

        // }
    }


    _onButtonClick() {
        this.setState({
            returnHome: true
        });
    }
    loadOptions = inputValue => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                Authorization: TOKEN,
            },
        };
        this.setState({
            isLoading: true,
        });
        let url =
            baseURL +
            `customer?branch_id=${BRANCH_ID}&&client_id=` +
            USER_ID +
            `&&filter_value=${inputValue}`;
        axios.get(url, axiosConfig).then(itemResponse => {
            this.setState(
                {
                    item: itemResponse.data.data,
                },
                function () {
                    this.setState({
                        isLoading: false,
                        bayo: this.state.item &&
                            (this.state.item.length > 0 || this.state.item.length === 0) &&
                            this.state.item.map((countyItem, i) => ({
                                label: countyItem.customer_display_name,
                                customer_email: countyItem.email,
                                value: countyItem.id,
                            })),
                    });
                }
            );
        });
    };

    handleCreate = value => {
        const newValue = { value: value, label: value };
        this.setState({ optionselected: newValue, payment_mode: value })

        let formData = {
            "name": value,
            "client_id": USER_ID
        }
        let url = baseURL + `payment_method`
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            }
        }).then((response) => {
        }).catch(error => {
            console.log("ERROR")
        })

    };


    handleCreateDeposit = value => {
        const newValue = { value: value, label: value };
        this.setState({ optionselected2: newValue, deposit_to: value })

        let formData = {
            "name": value,
            "client_id": USER_ID
        }
        let url = baseURL + `deposit_to`
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            }
        }).then((response) => {
        }).catch(error => {
            console.log("ERROR")
        })

    };
    render = () => {
        console.log('ITEMS', this.state.lineItems);
        const ref = React.createRef();
        const { returnHome } = this.state;


        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            justifyContent: 'space-between',
        };
        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };

        const formatGroupLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );


        let payments;

        if (this.calcGrandTotal() > 0) {
            payments = "Over Payment"
        } else if (this.calcGrandTotal() < 0) {
            payments = "Outstanding Amount"
        } else {
            payments = "Bill paid full"
        }
        return (
            <div style={{ marginTop: "-20px" }} >
                {returnHome === true ? <Navigate to='/payment-received' /> : null}

                <div ref={ref}>

                    <Card>
                        {this.state.isSubmitted ? "Submitting ...Please wait!" :

                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>Edit Payment</b></h4>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/payment-received">
                                            <Button className="pull-right btn-sm" onClick={this._onButtonClick}
                                                color="primary" outline><i className="fa  fa-arrow-left"></i> Payment Received
                                            </Button>
                                        </Link>

                                    </div>
                                </div>

                                {this.state.showError
                                    ? <div>

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p>
                                                <span className="bold-text">
                                                    {this.state.statusMessage}
                                                </span>
                                            </p>
                                        </Alert>

                                    </div>
                                    : null}<br />

                                {this.state.isShowError
                                    ? <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p>
                                                <span className="bold-text">
                                                    {this.state.statusMessage}
                                                </span>
                                            </p>
                                        </Alert>

                                    </div>
                                    : null}<br />
                                <form className="form col-10 offset-1" >
                                    <br /><br />
                                    {/* {this.state.isPageLoad ? "Loading Customers Please wait..." :
                                        <> */}

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Customer Name</label>
                                                </div>
                                                <div className="col-md-10">

                                                    <Link className='Links' to='/customer-create'>Create New Customer ?</Link>

                                                    <h4>{localStorage.getItem("payment_received_customer")}</h4>

                                                    {/* <Select
                                                                isClearable
                                                                options={this.Customer()}
                                                                onInputChange={this.loadOptions}
                                                                placeholder="Search Customer"
                                                                autosize={true}
                                                                onChange={this.onSelectChangesShort}
                                                                isLoading={this.state.isLoading}
                                                                // noOptionsMessage={() => 'nothing found'}
                                                                // loadingMessage={() => 'searching...'}   //minor type-O here
                                                                menuPortalTarget={document.body}
                                                                name="namffe"
                                                            /><br /> */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    {/* {this.state.isLoadCustomer ?
                                                <> */}

                                    <div className="col-md-6">
                                        <br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Amount Received ({CURRENCY})</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input
                                                        id="input"
                                                        type="text"
                                                        className="form-control"
                                                        name="amount_received"
                                                        value={this.state.amount_received}
                                                        required
                                                        placeholder="Amount Received"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <br />
                                        <Form.Group className="Focus-line">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Payment Date</Form.Label>
                                                </div>
                                                <div className="col-md-10 customDatePickerWidth">
                                                    <DatePicker
                                                        selected={this.state.payment_date}
                                                        label="DatePicker Label"
                                                        dateFormat="dd MMM yyyy"
                                                        required
                                                        value={this.state.payment_date}
                                                        className="form-control"
                                                        id="input"
                                                        onChange={value =>
                                                            this.setState({ payment_date: value })}
                                                    />

                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <br />
                                        <Form.Group className="Focus-line">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Payment Mode</Form.Label>
                                                </div>
                                                <div className="col-md-10">

                                                    <CreatableSelect
                                                        isClearable
                                                        options={this.PaymentMethod()}
                                                        // onInputChange={this.loadOptions}
                                                        placeholder="Select or create a new one"
                                                        autosize={true}
                                                        onChange={this.handleChangePayment}
                                                        onCreateOption={this.handleCreate}
                                                        value={this.state.optionselected}
                                                        isLoading={this.state.isLoading}
                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        menuPortalTarget={document.body}
                                                        name="payment_mode"
                                                    /><br />

                                                </div>
                                            </div>

                                        </Form.Group>
                                    </div>

                                    <div className="col-md-6">
                                        <br />
                                        <Form.Group className="Focus-line">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Deposit to</Form.Label>
                                                </div>
                                                <div className="col-md-10">

                                                    <Select
                                                        isClearable
                                                        // onInputChange={this.loadOptions}
                                                        placeholder="Select one"
                                                        autosize={true}
                                                        options={groupedOptions_two}
                                                        formatGroupLabel={formatGroupLabel}

                                                        value={this.state.optionselected2}
                                                        onChange={this.handleChangeDeposit}
                                                        isLoading={this.state.isLoading}
                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        menuPortalTarget={document.body}
                                                        name="deposit_to"
                                                    /><br />

                                                </div>
                                            </div>

                                        </Form.Group>
                                    </div>
                                    {/* <div className="col-md-6">
                                                            <br />
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-2">
                                                                        <label className="form-label">Reference#</label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <input
                                                                            id="input"
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="reference"
                                                                            required
                                                                            placeholder="Reference#"
                                                                            value={this.state.reference}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                    <div className="col-md-10 offset-1">
                                        <br />
                                        <h4><b>Unpaid Invoices</b></h4><br />


                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data != undefined ? this.state.data.filter(person => person.minvoice_no != "IN") : this.state.data}
                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />
                                    </div>

                                    <div className="col-md-10 offset-1">

                                        <div className="col-md-12">
                                            <br />


                                            <div className="col-md-12">

                                                <div className={styles.totalContainer}>
                                                    <form></form>
                                                    <form>
                                                        <div className={styles.valueTable}>
                                                            <div className={styles.row}>
                                                                <div className={styles.label}>Amount Received</div>
                                                                <div
                                                                    className={"${styles.value} ${styles.currency}"}
                                                                >
                                                                    {this.formatCurrency(
                                                                        this.state.amount_received
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.row}>
                                                                <div className={styles.label}>Invoice/s Amount</div>
                                                                <div
                                                                    className={"${styles.value} ${styles.currency}"}
                                                                >
                                                                    {this.formatCurrency(
                                                                        this.calcLineItemDiscounts()
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.row}>
                                                                <div className={styles.label}>
                                                                    Amount Refunded
                                                                </div>
                                                                <div
                                                                    className={"${styles.value} ${styles.currency}"}
                                                                >
                                                                    {this.formatCurrency(this.calcTaxTotal())}
                                                                </div>
                                                            </div>
                                                            <div className={styles.row}>
                                                                <div className={styles.label}>

                                                                    {payments}</div>
                                                                <div
                                                                    className={"${styles.value} ${styles.currency}"}
                                                                >
                                                                    {this.formatCurrency(Math.abs(this.calcGrandTotal()))}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>

                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    {/* </>
                                                : null}
                                        </>
                                    } */}
                                    <div className="col-md-6">
                                        <br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">

                                                    <label className="form-label">
                                                        Notes
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <textarea
                                                        name="notes"

                                                        style={{
                                                            paddingTop: '20px',
                                                            borderRadius: '5px',
                                                            borderColor: 'hsl(0,0%,80%)',
                                                        }}
                                                        onChange={this.handleChange}
                                                        className="col-md-12"
                                                        placeholder="Hint: Internal use. Not visible to customer"
                                                        id="message"
                                                        cols="col-md-1"
                                                        rows="1"
                                                    />
                                                </div>

                                                <div className="col-md-2">
                                                    <ul id="sms-counter">
                                                        <li>Length: <span class="length" /></li>
                                                        {/* <li>SMS: <span class="messages"></span></li> */}
                                                        <li>
                                                            Message:
                                                            {' '}
                                                            <span class="remaining" />
                                                            /
                                                            <span class="per_message" />
                                                        </li>
                                                        {/* <li>Remaining: </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>


                                    <br />
                                    <br />

                                    <div className="col-md-12"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Link to="/payment-received">
                                                        <Button outline color="primary" className="btn-sm" onClick={this._onButtonClick}>
                                                            Cancel
                                                        </Button>
                                                    </Link>

                                                </div>
                                                <div className="col-md-8">
                                                    <Button type="submit" color="primary" onClick={() => { this.onSubmit(0) }} outline className="btn-paypal btn-sm pull-right" >
                                                        {this.state.isLoading ? "Please Wait..." : "Save"}

                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                </div>
                                                {/* <div className="col-md-4">
                                                    <Button type="submit" color="primary" onClick={() => { this.onSubmit(1) }} outline className="btn-paypal btn-sm pull-right" >
                                                        {this.state.isLoading ? "Please Wait..." : "Save and Send"}
                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                </div> */}

                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <br />

                            </CardBody>}
                    </Card>
                </div>
            </div >
        );
    };
}

export default Payment_Received_Edit;
