import React, { Fragment, useState, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { BRANCH_ID, CONFIG, errorToast, successToast, ToastTable, TOKEN, USER_ID } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import ItemEdit from './item_edit';
import CreateItem from './createItem';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import { ItemBreakDown } from './item_breakdown';

// import CustomerEdit from './customer_edit';



export const ItemView = () => {


    const [items, setItems] = useState([]);  // items object array
    // boolen hooks defined
    const [isLoading, setIsLoading] = useState(true);
    const [showComponent, setShowComponent] = useState(false);
    const [hideComponent, setHideComponent] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [_onShow, setOnShow] = useState(false);


    const [go_details, setDetails] = useState(false);


    // const [go_details, setItem] = useState(false);

    const [message, setMessage] = useState('');



    const [total_counts, setTotalCounts] = useState(0);

    const [redirect, setRedirect] = useState(false);

    const [item_record, setItemsRecord] = useState('');
    const [queryString, setQueryString] = useState("")



    useEffect(() => {
        setIsLoading(true)
        let url = baseURL + `item?branch_id=${BRANCH_ID}&client_id=${USER_ID}&&${queryString}`

        // alert(url)
        axios.get(url, CONFIG).then(itemResponse => {
            setIsLoading(true)
            var data = [];
            setTotalCounts(itemResponse.data.total)
            for (let i = 0; i < itemResponse.data.data.length; i++) {
                let date = { dates: (moment.utc(itemResponse.data.data[i].date_created).format('DD/MM/YYYY')) };
                let mquantity = { mquantity: itemResponse.data.data[i].quantity.toString() };
                let mpricerate = { mrate: itemResponse.data.data[i].rate.toString() };

                let mbuyingprice 
                
                if(itemResponse.data.data[i].buying_price === null){
                   mbuyingprice = { mbuyingprice: "0" };
                }else{
                    mbuyingprice = { mbuyingprice: itemResponse.data.data[i].buying_price.toString() };
                }

                let msurem;
                if (itemResponse.data.data[i].measurements === null) {
                    msurem = { msurem: '_' };
                } else {
                    msurem = { msurem: itemResponse.data.data[i].measurements.toString() };
                }
                let mref;
                if (itemResponse.data.data[i].reference === null) {
                    mref = { mref: '_' };
                } else {
                    mref = { mref: itemResponse.data.data[i].reference.toString() };
                }


                let expiry
                if (itemResponse.data.data[i].expiry_status === 1) {
                    expiry = { expiry: '_' };
                } else {
                    expiry = { expiry: (moment.utc(itemResponse.data.data[i].end_date).format('DD/MM/YYYY')) };
                }
                
                data.push(Object.assign(date,mbuyingprice, mquantity, expiry, msurem, mref, mpricerate, itemResponse.data.data[i]));
            }

            setIsLoading(false)
            setItems(data)

        }).catch(error => {
            console.log('bayoo', error)
        })
    }, [queryString]);

    const getData = () => {
        setQueryString(require("randomstring").generate({
            length: 1,
            charset: 'alphanumeric',
            capitalization: 'lowercase'
        }))
    }


    const columns = [
        {
            key: "item_name",
            TrOnlyClassName: 'tsc',
            text: "Item Name",
            className: "tsc",
            align: "left"
        },

        {
            key: "mquantity",
            TrOnlyClassName: 'tsc',
            text: "Quantity",
            className: "tsc",
            align: "left"
        },
        {
            key: "msurem",
            TrOnlyClassName: 'tsc',
            text: "Measurement",
            className: "tsc",
            align: "left"
        },


        {
            key: "mbuyingprice",
            TrOnlyClassName: 'tsc',
            text: "Buying Price",
            className: "tsc",
            align: "left"
        },


        {
            key: "mrate",
            TrOnlyClassName: 'tsc',
            text: "Selling Price",
            className: "tsc",
            align: "left"
        },


        {
            key: "mref",
            TrOnlyClassName: 'tsc',
            text: "Ref#",
            className: "tsc",
            align: "left"
        },

        {
            key: "status",
            TrOnlyClassName: 'tsc',
            text: "Status",
            className: "tsc",
            align: "left",
            cell: record => {
                return (
                    <Fragment className="center"  >
                        {record.status == 1 ?
                            <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                Active
                            </span>
                            : null
                        }
                        {record.status == 0 ?
                            <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                Expired
                            </span>
                            : null
                        }
                    </Fragment >
                );
            }
        },

        {
            key: "expiry",
            TrOnlyClassName: 'tsc',
            text: "Date Expiry",
            className: "tsc",
            align: "left"
        },

        {
            key: "dates",
            TrOnlyClassName: 'tsc',
            text: "Date Created",
            className: "tsc",
            align: "left"
        },
        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    // eslint-disable-next-line
                    <Fragment className="center" >
                        <button className="btn btn-primary  btn-sm"
                            title="Edit details"
                            style={
                                { marginRight: '10px' }}
                            onClick={
                                () => { onEdit(record) }} >
                            Edit
                        </button>
                        <button className="btn btn-danger  btn-sm"
                            title="Delete details"
                            style={
                                { marginRight: '10px' }}
                            onClick={
                                () => { handleOnClick(record) }} >
                            Delete
                        </button>

                        <button className="btn btn-success  btn-sm"
                            title="item breakdown"
                            style={
                                { marginRight: '10px' }}
                            onClick={
                                () => { onBreakDown(record) }} >
                            More <i className='fa fa-plus'></i>
                        </button>

                    </Fragment>
                );
            }
        }
    ];

    const config = {
        key_column: "tsc",
        length_menu: [200, 300, 400, 500],
        show_filter: true,
        button: {
            excel: true,
            pdf: true,
            csv: true
        },
        filename: "inventory",
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search.. Item Name",
            no_data_text: "No data was found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
                // next
                // previous
            }
        }

    }
    const handleOnClick = async (record) => {
        if (await confirm("Are your sure?")) {
            onDelete(record)
        } else {
            setMessage("No!")
        }
    }


    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        setQueryString(queryString)
    }


    const _onButtonClick = () => {

        setShowComponent(true);
        setShowEdit(false);
        setHideComponent(true)
    }


    const onEdit = (record) => {
        setShowComponent(false);
        setShowEdit(true);
        setHideComponent(true)
        setOnShow(false)
        setItemsRecord(record)

    }


    const onBreakDown = (record) => {
        setShowComponent(false);
        setShowEdit(false);
        setHideComponent(true)
        setOnShow(false)
        setItemsRecord(record)
        setDetails(true)

    }
    const onDelete = (record) => {

        axios.delete(baseURL + `item/` + record.id, CONFIG).then(response => {
            console.log("true", response.data)
            if (response.data.status) {

                successToast(response.data.message)
                window.setTimeout(() => {
                    getData();
                }, 2000);

            } else {
                errorToast(response.data.message)
                window.setTimeout(() => {
                    getData();
                }, 2000);
            }
        }, error => {
            errorToast(JSON.stringify(error))
            window.setTimeout(() => {
                getData();
            }, 2000);
        });
    }

    return (
        <div style={{ marginTop: "-20px" }} >
            {!hideComponent && (
                <div>
                    < Card >
                        <CardBody >
                            <div className="panel-body" >
                                <div className="row">

                                    <div className="col-md-6">
                                        <h4><b>List of Items</b></h4><br />
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/item-create">
                                            <Button className="pull-right" color="primary
                                                    " onClick={_onButtonClick} outline>Add Item
                                            </Button>
                                        </Link>
                                    </div>
                                </div>

                                <ToastTable />

                                <div className="row">
                                    <div className="col-md-6">
                                        <h4>Items as at Now: <b>{total_counts}</b></h4>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-md-10 offset-1">
                                            <button className="pull-right" title="refresh"
                                                style={{ background: "white", border: "0px" }} onClick={getData}>
                                                <RefreshIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <br />


                                <ReactDatatable
                                    config={config}
                                    records={items}
                                    columns={columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={isLoading}
                                    total_record={total_counts}
                                    onChange={tableChangeHandler} />

                            </div>
                        </CardBody>

                    </Card>
                </div>
            )
            }{
                showComponent && (
                    <div>
                        <CreateItem />
                    </div>
                )
            }

            {
                showEdit && (
                    <div>
                        <ItemEdit item_record={item_record} />
                    </div>

                )
            }

            {
                go_details && (
                    <div>
                        <ItemBreakDown item_record={item_record} />
                    </div>

                )
            }
        </div >
    )
};
