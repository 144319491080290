import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MdCancel as DeleteIcon } from 'react-icons/md';
import styles from './LineItemjorunal.module.scss';
import axios from 'axios';
import { baseURL } from '../../_helpers';
import { TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';

// import { SelectFetch } from "react-select-fetch";
// import get from "./get";
// import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import Modal from 'react-modal';
import './style.css';
// import CreatableSelect from 'react-select/creatable';
import CreatableSelect from 'react-select/creatable';
import { groupedOptions_two } from '../Expenses/paidthrough';

// import { groupedOptions } from './expense_account'
// import { Button } from 'bootstrap';
// const options = [
//   { value: '14', label: 'VAT (14%)' },
//   { value: '2', label: 'KRA (2%)' },
//   // { value: 'vanilla', label: 'Vanilla' }
// ];
import { costofgoodOptions, groupedOptions } from '../Expenses/data';


const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);



class LineItemJouranal extends Component {
  constructor(props) {
    super(props);
    this._onButtonClick = this._onButtonClick.bind(this);
    this.handleCreate = this.handleCreate.bind(this);

    this.state = {
      itemValue: true,
      isLoading: false,
      isOpen1: false,
      isOpenAccount: false,
      showComponent: false,
      isOpenItem: false,
      customer: false,
      selectedValue: '',
      selectedValue2: '',
      taxValue: [{ label: 'Select Tax', value: '0' }],
      accountValue: [{ label: 'Select Account', value: '0' }]

    };
    localStorage.setItem('valuestax', 0);
    this.inputRef = React.createRef();
  }

  formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  handleChange = value => {


    if (value != null) {
      // alert(JSON.stringify(value))
      this.setState(
        {
          selectedValue: value.label.toString(),
          selectedValueQuantity: value.quantity === undefined ? "1" : value.quantity.toString(),
          isOpenItem: false,
          rate: value.rate.toString(),
          itemvaluess: { value: value.value.toString(), label: value.label.toString() },
          isOpen: false
        },
        function () {
          this.setNativeValue(this.myinput, this.state.selectedValue);
          this.myinput.dispatchEvent(new Event('input', { bubbles: true }));
        }

      );


      this.setNativeValue(this.myinput2, value.rate.toString());
      this.myinput2.dispatchEvent(new Event('input', { bubbles: true }));
    }
    else {
      this.setState(
        {
          itemvaluess: { value: "", label: "Select item" },
        },
        function () {
          this.setNativeValue(this.myinput, '');
          this.myinput.dispatchEvent(new Event('input', { bubbles: true }));
        });

    }
  };



  handleChangeCustomers = value => {
    if (value != null) {
      this.setState(
        {
          selectedValueCustomer: value.label.toString(),
          customer: false
        },
        function () {
          this.setNativeValue(this.myinputcustomers, this.state.selectedValueCustomer);
          this.myinputcustomers.dispatchEvent(new Event('input', { bubbles: true }));
        }
      );

    }
    else {
      this.setState(
        {
          selectedValueCustomer: value.label.toString(),
          customer: false

        },
        function () {
          this.setNativeValue(this.myinputcustomers, '');
          this.myinputcustomers.dispatchEvent(new Event('input', { bubbles: true }));
        }
      );

    }
  };

  handleChange2 = value => {
    if (value != null) {
      this.setState(
        {
          selectedValue2: value.label.toString(),
          selectedValue23: value.value.toString(),
          isOpen1: false,
          taxValue: [{ label: value.label.toString(), value: value.value.toString() }]
        },
        function () {

          this.setNativeValue(this.myinput1, this.state.selectedValue23);
          this.myinput1.dispatchEvent(new Event('input', { bubbles: true }));
        }
      );
    } else {

      this.setState({
        taxValue: [{ label: 'Select Tax', value: '0' }],
      })
      this.setNativeValue(this.myinput1, '');
      this.myinput1.dispatchEvent(new Event('input', { bubbles: true }));

    }
  };





  handleChangeAccount = value => {
    if (value != null) {
      this.setState(
        {
          selectedAccountValued2: value.label.toString(),
          selectedAccountValue_v2: value.value.toString(),
          // amountselected22: value.expense_id.toString(),

          isOpenAccount: false,
          accountValue: [{ label: value.label.toString(), value: value.value.toString() }]
        },
        function () {

          this.setNativeValue(this.myinputAccount, this.state.selectedAccountValue_v2);
          this.myinputAccount.dispatchEvent(new Event('input', { bubbles: true }));
        }
      );

      this.setNativeValue(this.myinputid, value.expense_id);
      this.myinputid.dispatchEvent(new Event('input', { bubbles: true }));


    } else {

      this.setState({
        accountValue: [{ label: 'Select Account', value: '0' }],
      })
      this.setNativeValue(this.myinput1, '');
      this.myinputAccount.dispatchEvent(new Event('input', { bubbles: true }));

    }
  };



  setNativeValue(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value);
    } else {
      valueSetter.call(element, value);
    }
  }

  toggleModal = e => {
    this.setState({
      isOpenItem: true,
    });
  };

  toggleModal1 = e => {
    this.setState({
      isOpen1: true,
    });
  };

  toggleModalAccount = e => {
    this.setState({
      isOpenAccount: true,
    });
  };


  toggleModalCustomer = e => {
    this.setState({
      customer: true,
    });
  };

  closeModal = e => {
    this.setState({
      isOpenItem: false,
    });
  };


  closeModalCustomers = e => {
    this.setState({
      customer: false,
    });
  };

  check = e => {
    // eslint-disable-next-line
    if (this.state.selectedValue == '') {
      alert('Please select an item');
    } else {
      this.setState({
        isOpen: false,
      });
    }
  };


  check2 = e => {
    // eslint-disable-next-line
    if (this.state.selectedValue2 == '') {
      alert("You haven't select any tax");
    } else {
      this.setState({
        isOpen1: false,
      });
    }
  };
  closeModal1 = e => {
    this.setState({
      isOpen1: false,
    });
  };

  closeModalAccount = e => {
    this.setState({
      isOpenAccount: false
    });
  };

  componentDidMount() {
    // alert (this.props.name);
    this.setState({
      selectedValue: this.props.name,
      isLoading: true,
    });
    if (this.state.isOpen) {
      document.body.style.overflow = 'hidden';
    }
    Modal.setAppElement('body');
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: TOKEN,
      },
    };
    let url2 = baseURL + `expensejournal?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID;
    let urltax = baseURL +`tax?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID;
    axios
      .all([
        axios.get(url2, axiosConfig),
        axios.get(urltax, axiosConfig),
      ])
      .then(
        axios.spread((itemResponse, taxResponse) => {

          // alert(JSON.stringify(taxResponse))
          this.setState(
            {
              item: itemResponse.data.data,
              tax: taxResponse.data.data
            },
            function () {
              this.setState({
                isLoading: false,
                bayo: this.state.item &&
                  (this.state.item.length > 0 ||
                    this.state.item.length === 0) &&
                  this.state.item.map((countyItem, i) => ({
                    label: countyItem.paid_through,
                    mamount: countyItem.amount,
                    expense_id: countyItem.id,
                    value: countyItem.paid_through,
                  })),

                taxparams: this.state.tax &&
                  (this.state.tax.length > 0 ||
                    this.state.tax.length === 0) &&
                  this.state.tax.map((countyItem, i) => ({
                    label: countyItem.tax_name + "(" + countyItem.tax_value + "%)",
                    value: countyItem.tax_value
                  })),


              });


              localStorage.setItem(
                'DATAARRAY',
                JSON.stringify(this.state.item)
              );
            }
          );
        })
      )
      .catch(error => {
        console.log('bayoo', error.response);
      });

    axios.get(url2, axiosConfig).then(customerResponse => {
      this.setState(
        {
          customerdata: customerResponse.data.data,
        },
        function () {
          this.setState({
            isLoading: false,
            customers: this.state.customerdata &&
              (this.state.customerdata.length > 0 || this.state.customerdata.length === 0) &&
              this.state.customerdata.map((countyItem, i) => ({
                label: countyItem.customer_display_name,
                value: countyItem.id,
              })),
          });
        }
      );
    });
  }


  handleCreate = value => {
    const newValue = { value: value, label: value };

    this.setState(
      {
        selectedValue: value.toString(),
        selectedValueQuantity: "1",
        isOpenItem: false,
        itemvaluess: newValue,
        isOpen: false
      },
      function () {
        this.setNativeValue(this.myinput, this.state.selectedValue);
        this.myinput.dispatchEvent(new Event('input', { bubbles: true }));
      }
    );


    let formData = {
      "client_id": USER_ID,
      "item_name": value,
      "rate": 1000,
      "quantity": 10000,
    }
    let url = baseURL + `item`
    axios.post(url, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': TOKEN
      }
    }).then((response) => {


    }).catch(error => {
      console.log("ERROR")
    })

  };


  _onButtonClick() {
    // window.location.href = "/log_in";
  }

  render = () => {
    const {
      index,
      account,
      description,
      tax_percentage,
      debit,
      credit,
      expense_account_id
    } = this.props;

    let credit_new = debit > 0 ? 0 : credit;
    // let debit_new = credit > 0 ?  0 : debit;


    // name = this.state.selectedValue;
    // let item = JSON.parse(localStorage.getItem("DATAARRAY"))
    return (
      <div>


        <div className={styles.lineItem}>

          <div>{index + 1}</div>

          <input
            name="expense_account_id"
            type="number"
            ref={input => (this.myinputid = input)}
            style={{ fontSize: "14px" }}
            hidden={true}
            visibility="hidden"
            hidden={true}
            value={expense_account_id}
            onChange={this.props.changeHandler(index)}
          // onFocus={this.props.focusHandler}
          />

          <div>
            <Select

              placeholder="Search Account"
              autosize={true}
              className="form-control"
              visibility="hidden"
              options={groupedOptions}
              formatGroupLabel={formatGroupLabel}

              onChange={this.handleChangeAccount}
              value={this.state.accountValue}
              isLoading={this.state.isLoading}
              menuPortalTarget={document.body}
              name="name"
            />
            <input
              name="account"
              placeholder="Select Account"
              className="form-control"
              type="text"
              hidden={true}
              style={{ fontSize: "14px" }}
              ref={input => (this.myinputAccount = input)}
              value={account}
              readOnly
              onClick={e => {
                this.toggleModalAccount(e);
              }}
              onChange={this.props.changeHandler(index)}
            />


          </div>

          <div className={styles.currency}>
            <input
              name="description"
              placeholder="Description"
              className="form-control"
              type="text"
              style={{ fontSize: "14px" }}
              value={description}
              onChange={this.props.changeHandler(index)}
              onFocus={this.props.focusHandler}
            />
          </div>

          <div>
            <Select
              options={this.state.taxparams}

              placeholder="Search Tax"
              autosize={true}
              onChange={this.handleChange2}
              value={this.state.taxValue}
              isLoading={this.state.isLoading}
              menuPortalTarget={document.body}
              name="name"
            />

            <input
              name="tax_percentage"
              placeholder="Tax %"
              className="form-control"
              type="text"
              visibility="hidden"
              hidden={true}
              ref={input => (this.myinput1 = input)}
              value={tax_percentage}
              readOnly

              style={{ fontSize: "14px" }}
              onClick={e => {
                this.toggleModal1(e);
              }}
              onChange={this.props.changeHandler(index)}
            />

          </div>

          {/* <div className={styles.currency}>
            <input
              name="debit"
              className="form-control"
              type="number"
              hidden={true}
              placeholder="0"
              ref={input => (this.myinput2 = input)}
              max={34}
              style={{ fontSize: "14px" }}
              value={expense_account_id}
              onChange={this.props.changeHandler(index)}
            // onFocus={this.props.focusHandler}
            />
          </div> */}


          <div className={styles.currency}>
            <input

              name="credit"
              className="form-control"
              type="number"
              step="0.01"
              placeholder="0"
              ref={input => (this.myinput2 = input)}
              min="0.00"
              style={{ fontSize: "14px" }}
              max="9999999.99"
              value={credit_new}
              onChange={this.props.changeHandler(index)}
              onFocus={this.props.focusHandler}

            // onFocus={this.props.focusHandler}
            />
          </div>







          <div className={styles.currency}>
            <input
              name="debit"
              className="form-control"
              type="number"
              placeholder="0"
              ref={input => (this.myinput2 = input)}
              max={34}
              style={{ fontSize: "14px" }}
              value={debit}
              onChange={this.props.changeHandler(index)}
            // onFocus={this.props.focusHandler}
            />
          </div>
          {/* <div className={styles.currency}>
            {this.props.showtax === true ?
              this.props.currencyFormatter(
                ((quantity * price) - (discount_percentage / 100 * (quantity * price))) + (tax_percentage / 100 * (quantity * price))
              )
              : this.props.currencyFormatter((quantity * price) - (discount_percentage / 100 * (quantity * price)))}
          </div> */}
          <div>
            <button
              type="button"
              className={styles.deleteItem}
              onClick={this.props.deleteHandler(index)}
            >
              <DeleteIcon size="1.25em" />
            </button>
          </div>
        </div>

      </div>
    );
  };
}

export default LineItemJouranal;

LineItemJouranal.propTypes = {
  index: PropTypes.number.isRequired,
  account: PropTypes.string,
  selected: PropTypes.string,
  description: PropTypes.string,
  tax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  debit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  credit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
