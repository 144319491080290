import React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { TOKEN } from '../../_helpers/exports';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router';

import {Link} from 'react-router-dom';
import { Users } from './Users';
import Alert from '../../shared/components/Alert';
import Input from 'react-phone-number-input/input'
import * as moment from 'moment';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { currency } from '../../containers/Account/Settings/datacurrency';
window.$ = window.jQuery = require('jquery')

class AddUser extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            event_name: '',
            event_description: '',
            start_time: '',
            end_time: '',
            entity_type: '4',
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            applicable_to: '',
            short_code: '',
            selectedEntity: '',
            hideEntitiy: false,
            hideBranch: false,
            transit: '',
            plans: '',
            event_date: '',
            fee: '',
            showError: false,
            industry: [{
                name: 'Professional activities'
            }, {
                name: 'Wholesale & Retail'
            }, {
                name: 'Manufacturing'
            }, {
                name: 'Hospitality'
            }, {
                name: 'Financial services'
            }, {
                name: 'Education'
            }, {
                name: 'Not for profit'
            }, {
                name: 'Agri-Business'
            }, {
                name: 'Transportation'
            }, {
                name: 'Health services'
            }, {
                name: 'ICT'
            }, {
                name: 'Construction'
            }, {
                name: 'Creative & Arts'
            }, {
                name: 'Other'
            }
            ]
        }
    }

    async componentDidMount() {

        var data = [];
        for (let i = 0; i < currency.length; i++) {
            let mlabel = { label: currency[i].name + " (" + currency[i].currency + ")" };
            let mvalue = { value: currency[i].agnaist_ksh };
            data.push(Object.assign(mlabel, mvalue, currency[i]));
            this.setState({
                data: data
            })
        }


    }
    handleChangeMessage = (event) => {
        this.setState({ selectedMessage: event.target.value }, function () {
        });
        //alert(event.target.value)
    };


    ShortCodes() {
        return (this.state.BreakingNews && this.state.BreakingNews.length > 0 &&
            this.state.BreakingNews.map((countyItem, i) =>
                ({ label: countyItem.account_name, value: countyItem.account_id })))

    }


    handleChangeCurrency = value => {
        if (value != null) {
            this.setState(
                {
                    agnaist_ksh: value.value.toString(),
                    currencylabel: value.currency.toString(),
                });
        }
    };

    ShortCodes2() {
        return (this.state.shortResponse && this.state.shortResponse.length > 0 &&
            this.state.shortResponse.map((countyItem, i) =>
                ({ label: countyItem.shortcode, value: countyItem.shortcode_id })))

    }

    onSelectChangesShort = value => {
        this.setState({ shortcode_id: value.value.toString() });
    };

    onSelectChanges = value => {
        this.setState({ account_id: value.value.toString() });
    };

    services() {
        return (this.state.groupResponse && (this.state.groupResponse.length > 0 || this.state.groupResponse.length === 0) &&
            this.state.groupResponse.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.id })))
    }

    onSelectService = value => {
        this.setState({ role_id: value.value.toString() })
    }

    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    onSubmit(e) {
        e.preventDefault();

        var months = moment(new Date(new Date().setDate(new Date().getDate() + 15))).format('YYYY-MM-DD HH:mm:ss');

        let formData =
        {
            "first_name": this.state.first_name,
            "last_name": this.state.last_name,
            "industry": this.state.industrys,
            "email": this.state.email,
            "msisdn": this.state.msisdn,
            "username": this.state.username,
            "first_time": "0",
            "our_client": "0",
            "admin_role": "1",
            "approval": "1",
            "paid": "1",
            "subscription": months,
            "type": "business",
            "shortcode_id": this.state.shortcode_id,
            "postal_address": this.state.postal_address,
            "company_name": this.state.company_name,
            "password": "123456",
            "inventory": "1",
            "investment": "1",
            "sales": "1",
            "purchase": "1",
            "documents": "1",
            "reports": "1",
            "accountant": "1",
            "bank": "1",
            "currency": 'KES',
            "currency_against_kenya": '1',
            "role_id": "2"
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.post(baseURL + `user`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)
                if (response.data.status) {

                    console.log("bayo", response)
                    this.setState({ statusMessage: "Created Successfully! email has been send", showError: false, isShowError: true, isLoading: false });
                    window.setTimeout(() => {
                        this._onButtonClick();
                        this.setState({ isShowError: false })
                    }, 3000);
                    window.scrollTo(0, 0)
                    let formData2 =
                    {
                        "name": this.state.first_name + " " + this.state.last_name,
                        "email": this.state.email,
                        "token": TOKEN
                    }
                    axios.post(baseURL_2 + 'emailconfirmation.php', formData2)
                        .then((response) => {

                        }).catch(error => {
                            this.setState({ statusMessage: error.response === undefined ? window.location.href = "/log_in" : "Something went wrong", showError: true, isShowError: false, isLoading: false });
                            window.scrollTo(0, 0)
                            // console.error('Error during service worker registration:', error);
                        });



                } else {
                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                    window.setTimeout(() => {
                        // this._onButtonClick();
                        this.setState({ showError: false })
                    }, 6000);
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
            })

    }


    onSelectChange = value => {
        this.setState({ entity_id: value.value.toString() });
    };

    handleChangeEntityChange = (event) => {
        this.setState({ values: event.target.value });
    };

    handleChangeEntityType = (event) => {
        this.setState({ entity_type: event.target.value });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeCountry = (event) => {
        this.setState({ selectedCountry: event.target.value });
    };

    handleChangeCounty = (event) => {
        this.setState({ selectedCounty: event.target.value });
    };

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true

        });
    }
    changeStatus() {
        this.setState({
            isShowError: false
        });
    }

    industry() {
        return (this.state.industry && (this.state.industry.length > 0 || this.state.industry.length === 0) &&
            this.state.industry.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.name })))

    }
    onSelectChanges = value => {
        this.setState({ industrys: value.value.toString() });
    };

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <Navigate to = "/users" /> : null
                        }
                    </div>
                )}

                {!hideComponent && (
                    <div>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>Create Client</b></h4>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/users">
                                            <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                Clients
                                            </Button>
                                        </Link>

                                    </div>
                                </div>

                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>


                                </div> : null}<br></br>

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div>

                                ) : null}<br></br>
                                <form className="form" onSubmit={this.onSubmit}>
                                    <br /><br />
                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">FirstName</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="first_name"
                                                        required placeholder="FirstName"
                                                        value={this.state.first_name}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">LastName</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="last_name"
                                                        required placeholder="LastName"
                                                        value={this.state.last_name}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Phone No.</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <PhoneInput
                                                        flags={flags}
                                                        defaultCountry="KE"
                                                        placeholder="Enter phone number"
                                                        name="msisdn"
                                                        maxLength={15}
                                                        id="input"
                                                        value={this.state.msisdn}
                                                        onChange={value => this.setState({ msisdn: value })} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Username (NO SPACES)</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="username" className="form-control" name="username" pattern="^\S+$"
                                                        required placeholder="Enter Username"
                                                        value={this.state.username}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Email</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="email" className="form-control" name="email"
                                                        required placeholder="Enter Email"
                                                        value={this.state.email}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Company Name</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="company_name"
                                                        required placeholder="Enter Company Name"
                                                        value={this.state.company_name}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Postal Address</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control" name="postal_address"
                                                        required placeholder="Enter Postal Address"
                                                        value={this.state.postal_address}
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Industry</label>
                                                </div>
                                                <div className="col-md-10">
                                                    {/* <label className="form-label">Select Industry</label> */}
                                                    <Select
                                                        options={this.industry()}
                                                        onChange={this.onSelectChanges}
                                                        placeholder="Select Industry"
                                                        tabSelectsValue={false}
                                                        value={this.state.onSelectChanges}
                                                        className='drop'
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {/* 
                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Select Currency</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <p><b style={{ color: "red" }}>N/B you won't be able to change it</b></p>
                                                    <Select
                                                        isClearable
                                                        options={this.state.data}
                                                        placeholder="Search Your Currency"
                                                        noOptionsMessage={() => 'No currrency found'}
                                                        autosize={true}
                                                        onChange={this.handleChangeCurrency}
                                                        openOnFocus={true}
                                                        menuPortalTarget={document.body}
                                                        name="currency"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div> */}



                                    <div className="col-md-8 offset-2 form-group">
                                        <br />
                                        <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                            {this.state.isLoading ? "Please Wait..." : "Submit"}

                                        </Button> &nbsp;&nbsp;&nbsp;
                                    </div>

                                </form>
                            </CardBody>
                        </Card>
                    </div>
                )
                }
            </div >
        )
    }
}
export default withTranslation('common')(AddUser);
