
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Button } from 'reactstrap'
import { BUSINESS_TYPE, COMPANY_NAME, CURRENCY, formatCurrency, LOADER, NAME, ROLE, USER_ID_ACC } from '../../_helpers/exports'
import html2PDF from 'jspdf-html2canvas'
import * as moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import { TOKEN, USER_ID } from '../../_helpers/exports';
import axios from 'axios';
import { baseURL, baseURL_2 } from '../../_helpers';

export class ProfitLoss extends React.Component {
  constructor(props) {
    super(props)
    this.onclickss = this.onclickss.bind(this);
    this.applyCallback = this.applyCallback.bind(this);

    this.state = {
      isShowError: false,

      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        download: false,
        key: 'selection',

      },
    }
    let now = new Date();
    let start = moment(new Date(new Date().getFullYear(), 0, 1));
    let end = moment(new Date());
    this.state = {
      start: start,
      end: end,
      isPageLoad: true,
    }


  }
  onclickss = () => {
    // alert("Done")
    this.setState({
      download: true,
    })
    var self = this;

    const page = document.getElementById('page')
    html2PDF(page, {
      html2canvas: {
        scrollX: 0,
        scale: 10,
        imageTimeout: 15000,
        logging: true,
        scrollY: -window.scrollY,
      },
      filename: BUSINESS_TYPE === 'business' ? "profitloss.pdf" : "incomestatement.pdf",

      watermark({ pdf, pageNumber, totalPageNumber }) {
        pdf.setTextColor('#00000');
        pdf.text(50, pdf.internal.pageSize.height - 30, "PeakInsight");
      },
      margin: {
        top: 15,
        right: 0,
        bottom: 0,
        left: 0,
      },
      jsPDF: {
        unit: 'px',
        format: 'a4'
      },
      imageType: 'image/jpeg',
      // watermark: 'PeakInsight',
      output: 'profitloss.pdf',
      init: function () {

      },
      success: function (pdf) {
        pdf.save(this.output);

        self.setState({
          download: false
        })
        // alert("bayobayo")

      }
    })

  }
  componentDidMount() {

    this.setState({
      startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('DD MMM, YYYY'),
      isPageLoad: true,
      valuedate: "This Year"
    })
    this.getData(moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
  }

  getData(startDate, endDate) {

    this.setState({
      isLoading: true,
    })
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": TOKEN
      }
    };
    let url = baseURL + `invoiceamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url2 = baseURL + `custom_balance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url3 = baseURL + `getbillamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url4 = baseURL + `expensesamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url5 = baseURL + `operationexpense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url6 = baseURL + `vendorcreditreport?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`

    axios.all([
      axios.get(url, axiosConfig),
      axios.get(url2, axiosConfig),
      axios.get(url3, axiosConfig),
      axios.get(url4, axiosConfig),
      axios.get(url5, axiosConfig),
      axios.get(url6, axiosConfig),

    ]).then(axios.spread((invoiceResponse, balanceResponse, billResponse, goodsResponse, expenseResponse, vendorCreditReponse) => {
      console.log("balance", invoiceResponse)
      this.setState({
        invoice: invoiceResponse.data.data,
        balance: balanceResponse.data.data,
        bills: billResponse.data.data,
        goods: goodsResponse.data.data,
        expense: expenseResponse.data.data,
        vendorcredit: vendorCreditReponse.data.data,

        isLoading: false
      }, function () {
        var data = [];
        // let editedInvoice = [];

        if (this.state.invoice.length === 0 && this.state.balance.length === 0) {
          this.setState({
            operating_cost: "0.00",
            isPageLoad: false
          })
        }
        else {
          let valueAdded = 0
          let count = 0
          let valueAdded1 = 0
          let count1 = 0
          let valueOutofBalance = 0
          let countOutofBalance = 0

          for (let i = 0; i < this.state.invoice.length; i++) {
            let str = this.state.invoice[i].amount;
            count = parseFloat(str.replaceAll(",", ""))
            valueAdded += count
          }
          for (let j = 0; j < this.state.balance.length; j++) {
            let b;
            if (this.state.balance[j].out_of_balance === null) {
              b = 0;
            } else {
              let str1 = this.state.balance[j].out_of_balance.toString();
              countOutofBalance = parseFloat(str1.replaceAll(",", ""))
              valueOutofBalance += countOutofBalance
            }
          }
          this.setState({
            operating_cost: (countOutofBalance + valueAdded).toFixed(2),
            isPageLoad: false
          })
        }


        if (this.state.bills.length === 0 && this.state.goods.length === 0 && this.state.vendorcredit.length === 0) {

          this.setState({
            cost_of_goods: "0.00",
            isPageLoad: false
          })
        }

        else {
          let valueAddedGoods = 0
          let countGoods = 0
          let valueBillsBalance = 0
          let countBillsBalance = 0

          let valueVendorCredit = 0
          let countVendorCredit = 0

          for (let i = 0; i < this.state.bills.length; i++) {
            let str = this.state.bills[i].amount.toString();
            countBillsBalance = parseFloat(str.replaceAll(",", ""))
            valueBillsBalance += countBillsBalance
          }

          for (let i = 0; i < this.state.vendorcredit.length; i++) {
            let str = this.state.vendorcredit[i].due_amount;
            countVendorCredit = parseFloat(str.replaceAll(",", ""))
            valueVendorCredit += countVendorCredit
          }
          for (let j = 0; j < this.state.goods.length; j++) {
            let str1
            if (this.state.goods[j].amount === null) {
              valueAddedGoods = 0;
            } else {
              let str1 = this.state.goods[j].amount.toString();
              countGoods = parseFloat(str1.replaceAll(",", ""))
              valueAddedGoods += countGoods
            }
          }
          console.log('bayoo', (valueAddedGoods + valueBillsBalance).toFixed(2))

          this.setState({
            cost_of_goods: ((valueBillsBalance + valueAddedGoods) - (valueVendorCredit)).toFixed(2),
            isPageLoad: false
          })
        }

        if (this.state.expense.length === 0) {

          this.setState({
            cost_of_operation: "0.00",
            isPageLoad: false
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.expense.length; j++) {
            let a;
            if (this.state.expense[j].amount == null) {
              a = 0;
            } else {
              let str2 = this.state.expense[j].amount.toString()

              // alert(this.state.expense[].amount)
              countExpense = parseFloat(str2.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
          }
          this.setState({
            cost_of_operation: valueAddedExpenses.toFixed(2),
            isPageLoad: false
          })
        }


        this.setState({
          gross: (this.state.operating_cost - this.state.cost_of_goods),
          isPageLoad: false
        })

      })
    })).catch(error => {
      console.log('bayoo', error.response)
    })
  }

  locale = 'en-US';
  currency = CURRENCY;



  applyCallback(startDate, endDate) {
    // localStorage.setItem("STARTDATE", startDate)
    // localStorage.setItem("ENDDATE", endDate)
    let Sdate1 = moment(startDate).format('DD MMM, YYYY');
    let Edate2 = moment(endDate).format('DD MMM, YYYY');
    this.setState({
      valuedate: Sdate1 + " " + Edate2,
      startDate: Sdate1,
      isPageLoad: true,
      endDate: Edate2
    })

    this.getData(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
  }


  render() {

    let ranges = {
      "Today Only": [moment(this.state.start), moment(this.state.end)],
      "Yesterday Only": [
        moment(this.state.start).subtract(1, "days"),
        moment(this.state.end).subtract(1, "days")
      ],
      "3 Days": [moment(this.state.start).subtract(3, "days"), moment(this.state.end)],
      "5 Days": [moment(this.state.start).subtract(5, "days"), moment(this.state.end)],
      "1 Week": [moment(this.state.start).subtract(7, "days"), moment(this.state.end)],
      "2 Weeks": [moment(this.state.start).subtract(14, "days"), moment(this.state.end)],
      "1 Month": [moment(this.state.start).subtract(1, "months"), moment(this.state.end)],
      "1 Year": [moment(this.state.start).subtract(1, "years"), moment(this.state.end)]
    };
    let local = {
      "format": "DD-MM-YYYY",
      "sundayFirst": false
    }
    return (
      <div style={{ marginTop: "-20px" }} >
        {this.state.isPageLoad ? <div className='d-flex justify-content-center align-items-center'>
          <div className="loader">
            {LOADER}
          </div>
        </div> :
          <Card>
            {this.state.download ? <div className='d-flex justify-content-center align-items-center'>
              <div className="loader">
                {LOADER}
              </div>
            </div> :
              <CardBody>
                <div className='col-12'>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-6">
                            <DateTimeRangeContainer
                              ranges={ranges}
                              start={this.state.start}
                              end={this.state.end}
                              local={local}
                              applyCallback={this.applyCallback}>
                              <FormControl
                                id="formControlsTextB"
                                type="text"
                                value={this.state.valuedate}
                                label="Text"
                                placeholder="Filter by Date"
                              />
                            </DateTimeRangeContainer>
                          </div>

                          <div className="col-6 float-left">

                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {
                              // this.getData();
                              this.getData(moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))

                              this.setState({
                                startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
                                endDate: moment(new Date()).format('DD MMM, YYYY'),
                                isPageLoad: true,
                                valuedate: "This Year"
                              })
                            }} >Clear</button>
                            {/* <input id="input" type="text" readOnly /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pull-right">
                      <button className="float-right btn btn-primary btn-sm" onClick={() => this.onclickss()}>Export PDF</button>

                    </div>
                  </div>
                  <div id="page">

                    <br />
                    <div className='text-center' p='3'>
                      <h4>{COMPANY_NAME}</h4>
                      <h3><strong>{BUSINESS_TYPE === 'business' ? "Profit and Loss" : "Income Statement"}</strong></h3>
                      <h4>Basis: Accrual</h4>
                      <h4>From {this.state.startDate} To {this.state.endDate}</h4>
                      <br /><br /><br />
                    </div>

                    <div className='row col-10 offset-0'>

                      <div className='col-5'>
                        <hr style={{ marginTop: '0px' }} />
                        <h4>Account</h4>
                        <hr style={{ marginTop: '0px' }} />
                        <h4 id='subhed'>Operation Income</h4><br />
                        <h4> <b>Total Operation Income</b></h4>
                        <hr style={{ marginTop: '0px' }} />
                        <h4 id='subhed'>Cost of Goods Sold</h4><br />
                        <h4> <b>Total Cost of Goods Sold</b></h4>
                        <hr style={{ marginTop: '0px' }} />
                        <br />
                        <h4 id='subhed'>Operating Expense</h4><br />

                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> Professional Fees</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> Administration Expenses</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> Staff Expenses</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> Allowances for Asset Impairements</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> Finance Costs</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> Establishment Expenses </h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> Other Expenses</h4>
                        <br />

                        <h4> <b>Total Expenses</b></h4>
                        <hr style={{ marginTop: '0px' }} />


                      </div>
                      <div className='col-7 text-right'>
                        <hr style={{ marginTop: '0px' }} />
                        <h4><strong>Total</strong></h4>
                        <hr style={{ marginTop: '0px' }} />
                        <h4 style={{ visibility: 'hidden' }}>space</h4>
                        <br />
                        <h4>{formatCurrency(this.state.operating_cost)}</h4>
                        <hr style={{ marginTop: '0px' }} />
                        <h4 style={{ visibility: 'hidden' }}>space</h4>
                        <br />
                        <h4>{formatCurrency(this.state.cost_of_goods)}</h4>
                        <hr style={{ marginTop: '0px' }} />
                        <h4><strong>Gross Profit</strong><i style={{ visibility: 'hidden' }}>______________</i>
                          {formatCurrency((parseFloat(this.state.operating_cost) - parseFloat(this.state.cost_of_goods)).toFixed(2))} </h4>
                        <br /><br />

                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}>{formatCurrency(this.state.expense[2].amount)}</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> {formatCurrency(this.state.expense[5].amount)}</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> {formatCurrency(this.state.expense[0].amount)}</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}>{formatCurrency(this.state.expense[3].amount)}</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> {formatCurrency(this.state.expense[4].amount)}</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}>{formatCurrency(this.state.expense[1].amount)} </h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> {formatCurrency(this.state.expense[6].amount)}</h4>
                        <br />
                        <h4>{formatCurrency(this.state.cost_of_operation)}</h4>
                        <hr style={{ marginTop: '0px' }} />

                        <br />

                        {((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods))) - parseFloat(this.state.cost_of_operation)) < 0 ?
                          <div>
                            <h4><strong>Net Loss</strong><i style={{ visibility: 'hidden' }}>______________</i>{
                              formatCurrency((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                - parseFloat(this.state.cost_of_operation))}</h4>
                          </div>
                          :
                          <div>
                            <h4><strong>Net Loss</strong><i style={{ visibility: 'hidden' }}>______________</i>{formatCurrency("0.00")}</h4>

                          </div>

                        }

                        <br />
                        {((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods))) - parseFloat(this.state.cost_of_operation)) > 0 ?
                          <div>
                            <h4 style={{ marginBottom: '10px' }}><strong>Net Profit Before Tax</strong><i
                              style={{ visibility: 'hidden', marginBottom: '5px' }}>______________</i>

                              {formatCurrency((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                - parseFloat(this.state.cost_of_operation))
                              }
                            </h4>

                            <h4 style={{ marginBottom: '10px' }}><strong>Net Tax</strong><i style={{ visibility: 'hidden', marginBottom: '5px' }}>______________</i>
                              {formatCurrency(0.3 * ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                - parseFloat(this.state.cost_of_operation)))
                              }
                            </h4>


                            <h4 style={{ marginBottom: '10px' }}><strong>Net Profit After Tax</strong><i style={{ visibility: 'hidden' }}>______________</i>
                              {formatCurrency(

                                ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                  - parseFloat(this.state.cost_of_operation))
                                -
                                (0.3 * ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                  - parseFloat(this.state.cost_of_operation))))
                              }
                            </h4>
                          </div>
                          :
                          <div>
                            <h4><strong>Net Profit Before Tax</strong><i style={{ visibility: 'hidden' }}>______________</i>

                              {formatCurrency("0.00")}
                            </h4>
                          </div>
                        }






                      </div>

                    </div>
                  </div>
                </div>


              </CardBody>
            }

          </Card>
        }

      </div>

    )
  }
}
