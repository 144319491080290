import { useState, useCallback, useEffect } from "react";

import axios from "axios";
import { CONFIG } from "./exports";
import { baseURL } from ".";

const UseFetchCallBack = () => {

  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true)

  const getData = useCallback((url) => {
    setLoad(true)
    axios.get(`${baseURL + url}`, CONFIG).then(res => {
      setData(res.data);
      setLoad(false)
    });
  })

  return [load, data, getData];

};

export default UseFetchCallBack;