import React, { useState } from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import { Card, CardBody, Button } from 'reactstrap';
import { ToastTable, USER_ID, BRANCH_ID, errorToast, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { Link, useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import PostHooks from '../../_helpers/postHooks';
import CreatableSelect from 'react-select/creatable';
import UseFetch from '../../_helpers/useFetch';
import UseFetchCallBack from '../../_helpers/useFetchCallBack';


const CreateEmployee = () => {

    const navigate = useNavigate()

    const [gross, setGross] = useState(0);
    const [other_deduction, setOtherDeductions] = useState(0);

    const [other_benefits, setOtherBenefits] = useState(0);


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [msisdn, setMSISDN] = useState('');
    const [job_type, setJobType] = useState([
        {
            label: 'temporary',
            value: 'temporary'
        },
        {
            label: 'permanent',
            value: 'permanent'
        }
    ]);
    const [period_value, setPeriodValue] = useState("monthly");
    const [period, setPeriod] = useState([
        {
            label: 'Daily',
            value: 'daily'
        },
        {
            label: 'Weekly',
            value: 'weekly'
        },
        {
            label: 'Monthly',
            value: 'monthly'
        },
        {
            label: 'Yearly',
            value: 'yearly'
        }
    ]);

    const [job_type_selected, setJobTypeSelected] = useState('temporary');

    // const [job_title, setJobTitle] = useState('');
    const [staff_number, setStaffNumber] = useState('');
    const [kra_pin, setKRAPIN] = useState('');
    const [nssf_no, setNSSF] = useState(0);

    const [nhif_no, setNHIF] = useState('');
    const [payment_mode, setPaymentMode] = useState([
        {
            label: 'cash',
            value: 'cash'
        },
        {
            label: 'bank',
            value: 'bank'
        },
        {
            label: 'mpesa',
            value: 'mpesa'
        }
    ]);



    const [payment_p, setPaymentP] = useState(null);


    const [bank_name, setBankName] = useState('');
    const [account_no, setAccountName] = useState('');
    const [bank_open, setOPenBank] = useState(false);

    const [department, setDepartment] = useState([]);

    const [department_value, setDepartmentValue] = useState(0)
    const [department_label, setDepartmentLabel] = useState({ label: "Select or type to add Department", value: "" })


    // job title
    const [job_title, setJobTitle] = useState([]);
    const [job_value, setJobValue] = useState("")
    const [job_label, setJobLabel] = useState({ label: "Select or type to add Job Title", value: "" })



    const [id_no, setIDNo] = useState(0);

    const [check_service, setCheckService] = useState(false)

    const [check, setCheck] = useState(0);
    const [dept_check, setCheckDep] = useState(1);

    const [pay_check, setCheckPay] = useState(1);

    const [props, setProps] = useState({});
    const [isLoading, res, postRequest] = PostHooks(props); // custom hook
    const [loading, fetch, callback] = UseFetchCallBack(); // custom hook


    const [loading_dep, fetch_dept, callbackdept] = UseFetch(`department?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&branch_id=${BRANCH_ID}`); // custom hook



    const [home, setHome] = useState(false)
    React.useEffect(() => {  // use effect call, detect change and call custom post hook
        if (res != null && res.status) {
            setName('');
            setEmail('');
            setMSISDN('');
            setJobTitle('');
            setStaffNumber('');
            setKRAPIN('');
            setNSSF('');
            setNHIF('');
            setBankName('');
            setAccountName('');
            setTimeout(() =>
                setHome(true), 2000
            )
        }

        if (fetch && fetch.data.length > 0) {
            setJobTitle(fetch.data)
        }
        if (fetch_dept && fetch_dept.data.length > 0) {
            setDepartment(fetch_dept.data)
        }

        if (dept_check === 1) {
            setCheckDep(0)
            callbackdept()
        }
        if (pay_check === 1) {
            setCheckPay(0)
            callback(`job_title?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&branch_id=${BRANCH_ID}`)
        }

        if (props.url === 'employee' && check === 1) {
            postRequest();
            setCheck(0)
        }

    }, [loading, loading_dep, fetch_dept, isLoading, dept_check, pay_check, props, check])



    // deno-lint-ignore require-await
    const submit = async (e) => {
        e.preventDefault();


        if (gross > 0) {



            const formData = {
                name: name,
                email: email,
                department_id: department_value,
                msisdn: msisdn,
                job_title: job_value,
                job_type: job_type_selected,
                staff_number: staff_number,
                client_id: USER_ID,
                branch_id: BRANCH_ID,
                kra_pin: kra_pin,
                id_no: id_no,
                nssf_no: nssf_no,
                nhif_no: nhif_no,
                payment_mode: payment_p,
                bank_name: bank_name,
                disable: check_service,
                account_number: account_no,
                period: period_value,
                gross: gross,
                other_deductions: other_deduction,
                other_benefits: other_benefits
            }
            const customerProps = { // post body and the url
                url: 'employee',
                body: formData
            }
            setCheck(1)
            setProps(customerProps)
            if (name !== '') {
                setOPenBank(false);
            }
            else {
                errorToast("Please Re-enter employee name")
                setOPenBank(false);
            }
        } else {
            errorToast("Gross is complusory")
        }
    }



    //create department
    const handleCreate = value => {
        const newValue = { value: value, label: value };
        setDepartmentLabel(newValue)
        postRequest();
        setOPenBank(false);
        setCheckDep(1)
        setDepartmentLabel({ label: "Select Department you have just created", value: "" })
        // callback()
    }




    //create department
    const handleCreateJob = value => {
        const newValue = { value: value, label: value };
        setJobLabel(newValue)
        postRequest();
        setCheckPay(1)
        setJobLabel({ label: "Select Department you have just created", value: "" })
        // callback()
    }

    const toggleServiceChange = () => {
        setCheckService(!check_service)
        // alert(check_service)
    }


    return (
        <div>
            {ToastTable()}
            <Card>
                <CardBody>
                    {home ? navigate("/employees") : null}
                    <div className="row">
                        <div className="col-md-6">
                            <h4><b>Create Employee</b></h4><br />
                        </div>
                        <div className="col-md-6">
                            <Link to="/employees">
                                <Button className="pull-right  btn-sm" outline color="primary" >
                                    Employees
                                </Button>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={submit} className="col-10 offset-1">

                        <div className="row">

                            <div className="col-md-6"><br />
                                <div className="col-md-12">
                                    <label>
                                        <input type="checkbox"
                                            checked={check_service}
                                            onChange={toggleServiceChange}
                                        />&nbsp;
                                        Has Any Disability?

                                    </label>

                                </div>
                            </div>

                            <div className="col-md-6"> <br />
                                <Form.Group className="Focus-line" >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Label>Job Title</Form.Label>
                                        </div>
                                        <div className="col-md-9">

                                            <CreatableSelect
                                                options={job_title && job_title.length > 0 ?
                                                    job_title.map((countyItem, i) => ({
                                                        label: countyItem.name,
                                                        value: countyItem.id,
                                                    }))
                                                    : job_title
                                                }
                                                value={job_label}
                                                onInputChange={e => {
                                                    const formData = {
                                                        name: e,
                                                        client_id: USER_ID,
                                                        branch_id: BRANCH_ID
                                                    }
                                                    const customerProps = { // post body and the url
                                                        url: 'job_title',
                                                        body: formData
                                                    }
                                                    setProps(customerProps)
                                                    setOPenBank(false)
                                                    // setName('')
                                                }}
                                                autosize={true}
                                                noOptionsMessage={() => 'No Job Title! Start type to create'}
                                                loadingMessage={() => 'Searching...'}   //minor type-O here
                                                placeholder="Select or type to add Job Title"
                                                autosize={true}
                                                onChange={e => {
                                                    // callback()
                                                    setCheckPay(1)
                                                    setJobValue(e.value)
                                                    const newValue = { value: e.value, label: e.label };
                                                    setJobLabel(newValue)

                                                }}
                                                onCreateOption={handleCreateJob}
                                                isLoading={loading}
                                                menuPortalTarget={document.body}
                                                name="namffe"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                            {/* 
                        <div className="col-md-6"><br />
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">Job Title</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input id="input" type="text" className="form-control"
                                            name="job_title" placeholder='Job Title'
                                            value={job_title} onChange={e => setJobTitle(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                            <div className="col-md-6"> <br />
                                <Form.Group className="Focus-line" >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Label>Job Type</Form.Label>
                                        </div>
                                        <div className="col-md-9">
                                            <Form.Control
                                                as="select"
                                                className="form-control input-md"
                                                placeholder="Select Job Type"
                                                value={job_type_selected}
                                                onChange={e => setJobTypeSelected(e.target.value)}
                                                id="job_type_selected"
                                            >
                                                {/* <option>Job Type</option> */}
                                                {
                                                    job_type && job_type.length > 0 &&
                                                    job_type.map((regionItem, i) =>
                                                        <option key={i}
                                                            value={regionItem.value}>{regionItem.value}</option>)
                                                }
                                            </Form.Control>
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>


                            <div className="col-md-6"> <br />
                                <Form.Group className="Focus-line" >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Label>Department</Form.Label>
                                        </div>
                                        <div className="col-md-9">

                                            <CreatableSelect
                                                options={department && department.length > 0 ?
                                                    department.map((countyItem, i) => ({
                                                        label: countyItem.name,
                                                        value: countyItem.id,
                                                    }))
                                                    : department
                                                }
                                                value={department_label}
                                                onInputChange={e => {
                                                    const formData = {
                                                        name: e,
                                                        client_id: USER_ID,
                                                        branch_id: BRANCH_ID
                                                    }
                                                    const customerProps = { // post body and the url
                                                        url: 'department',
                                                        body: formData
                                                    }
                                                    setProps(customerProps)
                                                    setOPenBank(false)
                                                    setName('')
                                                }}
                                                autosize={true}
                                                noOptionsMessage={() => 'No department! Start type to create'}
                                                loadingMessage={() => 'Searching...'}   //minor type-O here
                                                placeholder="Select or type to add Department"
                                                autosize={true}
                                                onChange={e => {
                                                    // callback()
                                                    setCheckDep(1)
                                                    setDepartmentValue(e.value)
                                                    const newValue = { value: e.value, label: e.label };
                                                    setDepartmentLabel(newValue)

                                                }}
                                                onCreateOption={handleCreate}
                                                isLoading={loading_dep}
                                                menuPortalTarget={document.body}
                                                name="namffe"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>


                            <div className="col-md-6"><br />
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">Employee Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input id="input" type="text" className="form-control"
                                                name="name" required placeholder='Name'
                                                value={name} onChange={e => {

                                                    setName(e.target.value)
                                                }
                                                } />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6"><br />
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">Staff Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input id="input" type="text" className="form-control"
                                                name="staff_number" required placeholder='Staff Number'
                                                value={staff_number} onChange={e => setStaffNumber(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6"><br />
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">Email Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input id="input" type="email" className="form-control"
                                                name="email" placeholder='Email Address'
                                                value={email} onChange={e => {
                                                    setEmail(e.target.value)
                                                    // alert("here")
                                                }
                                                } />
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-md-6">
                                <br />
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">Phone No.</label>
                                        </div>

                                        <div className="col-md-9">
                                            <PhoneInput
                                                flags={flags}
                                                defaultCountry="KE"
                                                placeholder="Enter phone number"
                                                name="msisdn"
                                                maxLength={15}
                                                id="input"
                                                value={msisdn}
                                                onChange={value => setMSISDN(value)} />

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6"><br />
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">National ID No.</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input id="input" type="text" className="form-control"
                                                name="id_no" placeholder='ID No.'
                                                value={id_no} onChange={e => setIDNo(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6"><br />
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">KRA PIN</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input id="input" type="text" className="form-control"
                                                name="tax_pin" placeholder='KRA PIN'
                                                value={kra_pin} onChange={e => setKRAPIN(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6"><br />
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">NHIF No.</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input id="input" type="text" className="form-control"
                                                name="nhif_no" placeholder='NHIF No.'
                                                value={nhif_no} onChange={e => setNHIF(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6"><br />
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">NSSF No.</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input id="input" type="text" className="form-control"
                                                name="nssf_no" placeholder='NSSF No.'
                                                value={nssf_no} onChange={e => setNSSF(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6"><br />
                                <Form.Group className="Focus-line" >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Label>Payment Mode</Form.Label>
                                        </div>
                                        <div className="col-md-9">
                                            <Form.Control
                                                as="select"
                                                className="form-control input-md"
                                                onChange={e => {
                                                    setPaymentP(e.target.value)
                                                    if (e.target.value === 'bank') {
                                                        setOPenBank(true)
                                                    } else {
                                                        setOPenBank(false)
                                                    }
                                                }}
                                                value={payment_p}
                                                selected={payment_p}
                                                id="payment_p"
                                            >
                                                <option value={null}>Payment Mode</option>
                                                {
                                                    payment_mode && payment_mode.length > 0 &&
                                                    payment_mode.map((regionItem, i) =>
                                                        <option key={i}
                                                            value={regionItem.value}>
                                                            {regionItem.value}
                                                        </option>)
                                                }
                                            </Form.Control>
                                        </div>
                                    </div>
                                </Form.Group>
                                {bank_open ?
                                    <div><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label className="form-label">Bank Name</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input id="input" type="text" className="form-control"
                                                        name="bank_name" placeholder='Bank Name'
                                                        value={bank_name} onChange={e => setBankName(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label className="form-label">Account No</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <input id="input" type="text" className="form-control"
                                                        name="acccount_no" placeholder='Account Number'
                                                        value={account_no} onChange={e => setAccountName(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>

                            <div className="col-md-6"><br />
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">Gross Income</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input id="input" type="text" className="form-control"
                                                name="gross" placeholder='Set Gross Income'
                                                value={gross} onChange={e => setGross(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6"><br />
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">Other Benefits</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input id="input" type="text" className="form-control"
                                                name="other_benefits" placeholder='Other Deduction'
                                                value={other_benefits} onChange={e => setOtherBenefits(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-6"><br />
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label">Other deductions</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input id="input" type="text" className="form-control"
                                                name="other_deduction" placeholder='Other Deduction'
                                                value={other_deduction} onChange={e => setOtherDeductions(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6"> <br />
                                <Form.Group className="Focus-line" >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Label>Period</Form.Label>
                                        </div>
                                        <div className="col-md-9">
                                            <Form.Control
                                                as="select"
                                                className="form-control input-md"
                                                placeholder="Select Period"
                                                value={period_value}
                                                onChange={e => setPeriodValue(e.target.value)}
                                                id="period_value"
                                            >
                                                {/* <option>Period</option> */}
                                                {
                                                    period && period.length > 0 &&
                                                    period.map((regionItem, i) =>
                                                        <option key={i}
                                                            value={regionItem.value}>{regionItem.value}</option>)
                                                }
                                            </Form.Control>
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>

                        </div>
                        <div className="col-8 offset-2"><br />
                            <br />

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <Link to="/employees">
                                            <Button outline color="primary" className="btn-sm">
                                                Cancel
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="col-md-9">
                                        <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                            {isLoading ? "Please Wait..." : "Save"}
                                        </Button> &nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div >
    )
}
export default CreateEmployee;