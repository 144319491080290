import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import addUser from './addUser';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import adduserclient from './adduserclient';
import { ClientsUsers } from './clientusers';

export class AuditUser extends React.Component {
    constructor(props) {
        super(props);
        this._onButtonClick = this._onButtonClick.bind(this);

        this.columns = [
            {
                key: "date_modified2",
                TrOnlyClassName: 'tsc',
                text: "Last Login",
                className: "tsc",
                align: "left"
            }

        ];
        this.config = {
            key_column: "tsc",
            // length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 100,
            show_length_menu: false,
            button: {
                excel: true,
                csv: true
            },
            filename: "audit_trail",
            language: {
                loading_text: "Please be patient while data loads...",
                no_data_text: "No Data Found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }
    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "") => {

        queryString = ""
        this.setState({
            isLoading: true,
        })
        // alert(TOKEN)
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `audit_user?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${localStorage.getItem("client_audit_id")}`
        console.log("query", url);
        // alert(localStorage.getItem("customerID"))

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                isLoading: false
            }, function () {
                var data = [];
                // console.log("BUG", this.state.admins)
                for (let i = 0; i < this.state.admins.length || 0; i++) {
                    let date_modified = { date_modified2: (moment(this.state.admins[i].modified).format('DD/MM/YYYY HH:mm:ss')) };
                    data.push(Object.assign(date_modified, this.state.admins[i]));
                    this.setState({
                        data: data
                    })
                    console.log("bugs", data);
                }
            }
            );
        }))

    }
    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: false,

        });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        // console.log("Load", isLoading);
        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        <ClientsUsers />
                    </div>
                )}
                {!hideComponent && (


                    <div>

                        < Card >
                            <CardBody >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>Audit Trail ({localStorage.getItem("client_audit_name")})</b></h4>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/client-users">
                                            <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                                Client Users
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col-md-10 offset-1">
                                            <button className="pull-right btn-sm" title="refresh"
                                                style={{ background: "white", border: "0px" }} onClick={this.getData}>
                                                <RefreshIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={this.state.isLoading}
                                    total_record={100}
                                    onChange={this.tableChangeHandler} />
                            </CardBody>

                        </Card>
                    </div>

                )
                }
            </div>
        )
    }
}