import React, { useEffect, Fragment } from "react";
import AOS from "aos";
import $ from "jquery";

import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Pricing from "./components/Pricing";
// import { useGeolocated } from "react-geolocated";
import axios from "axios";

import "aos/dist/aos.css";
import "./assets/styles/main.scss";

const App = () => {
  const [pos, setPos] = React.useState('');

  useEffect(() => {
    // alert(JSON.stringify(coords))
    window.localStorage.clear(); //try this to clear all local storage

    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setPos(data.country_name);
      })
      .catch((error) => {
        console.log(error);
      });

    AOS.init({ once: true });

    let navElement = $("nav");

    $(function () {
      $(window).scrollTop() > navElement.innerHeight()
        ? navElement.addClass("sticky")
        : navElement.removeClass("sticky");
    });
    $(window).on("scroll", function () {
      $(window).scrollTop() > navElement.innerHeight()
        ? navElement.addClass("sticky")
        : navElement.removeClass("sticky");
    });
  }, [pos]);

  return (
    <Fragment>


      {pos && pos === "Nigeria"
        ?
        window.location.href = "https://ng.peakbooks.biz/"
        :
        window.location.href = "https://ke.peakbooks.biz/"
      }


    </Fragment>
  );
};

export default App;
