import React, { Fragment, useState, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { CONFIG, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import { ItemView } from './item_view';

// import CustomerEdit from './customer_edit';



export const ItemBreakDown = (props) => {


    const [items, setItems] = useState([]);  // items object array
    // boolen hooks defined
    const [isLoading, setIsLoading] = useState(true);
    const [showComponent, setShowComponent] = useState(false);
    const [hideComponent, setHideComponent] = useState(false);


    const [total_counts, setTotalCounts] = useState(0);

    const [queryString, setQueryString] = useState("")



    useEffect(() => {
        setIsLoading(true)

        let url = baseURL + `item_breakdown?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&&item_id=${props.item_record.id}&&item_name=${props.item_record.item_name}&&${queryString}`

        axios.get(url, CONFIG).then(itemResponse => {
            var data = [];
            setTotalCounts(itemResponse.data.total)

            for (let i = 0; i < itemResponse.data.data.length; i++) {


                let date = { dates: (moment.utc(itemResponse.data.data[i].created_at).format('DD/MM/YYYY')) };

                let mquantity = { mquantity: itemResponse.data.data[i].quantity.toString() };

                let mpricerate = { mrate: itemResponse.data.data[i].price.toString() };

                let mtax_percentage = { mtax_percentage: itemResponse.data.data[i].tax_percentage.toString() };

                let mdiscount_percentage = { mdiscount_percentage: itemResponse.data.data[i].discount_percentage.toString() };

                data.push(Object.assign(date, mquantity, mdiscount_percentage, mtax_percentage, mpricerate, itemResponse.data.data[i]));
            }

            setIsLoading(false)
            setItems(data)

        }).catch(error => {
            console.log('bayoo', error)
        })
    }, [queryString]);

    const getData = () => {
        setQueryString(require("randomstring").generate({
            length: 1,
            charset: 'alphanumeric',
            capitalization: 'lowercase'
        }))
    }


    const columns = [
        {
            key: "name",
            TrOnlyClassName: 'tsc',
            text: "Item Name",
            className: "tsc",
            align: "left"
        },

        {
            key: "mquantity",
            TrOnlyClassName: 'tsc',
            text: "Quantity",
            className: "tsc",
            align: "left"
        },

        {
            key: "mrate",
            TrOnlyClassName: 'tsc',
            text: "Price",
            className: "tsc",
            align: "left"
        },

        {
            key: "mtax_percentage",
            TrOnlyClassName: 'tsc',
            text: "% Tax",
            className: "tsc",
            align: "left"
        },

        {
            key: "mdiscount_percentage",
            TrOnlyClassName: 'tsc',
            text: "Discount",
            className: "tsc",
            align: "left"
        },

        {
            key: "dates",
            TrOnlyClassName: 'tsc',
            text: "Date Created",
            className: "tsc",
            align: "left"
        }
    ];

    const config = {
        key_column: "tsc",
        length_menu: [200, 300, 400, 500],
        show_filter: true,
        button: {
            excel: true,
            pdf: true,
            csv: true
        },
        filename: "inventory",
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search.. Item Name",
            no_data_text: "No data was found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
                // next
                // previous
            }
        }

    }

    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        setQueryString(queryString)
    }


    const _onButtonClick = () => {

        setShowComponent(true);
        setHideComponent(true)
    }

    return (
        <div style={{ marginTop: "-20px" }} >
            {!hideComponent && (
                <div>
                    < Card >
                        <CardBody >
                            <div className="panel-body" >
                                <div className="row">

                                    <div className="col-md-6">
                                        <h4><b>{props.item_record.item_name}</b></h4><br />
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/item">
                                            <Button className="pull-right" color="primary
                                                    " onClick={_onButtonClick} outline>Back to Items
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4>Totals: <b>{total_counts}</b></h4>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-md-10 offset-1">
                                            <button className="pull-right" title="refresh"
                                                style={{ background: "white", border: "0px" }} onClick={getData}>
                                                <RefreshIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <br />


                                <ReactDatatable
                                    config={config}
                                    records={items}
                                    columns={columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={isLoading}
                                    total_record={total_counts}
                                    onChange={tableChangeHandler} />

                            </div>
                        </CardBody>

                    </Card>
                </div>
            )
            }{
                showComponent && (
                    <div>
                        <ItemView />
                    </div>
                )
            }

        </div >
    )
};
