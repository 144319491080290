import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarSearch from './TopbarSearch';
import { withTranslation } from 'react-i18next';
import { UserProps } from '../../../shared/prop-types/ReducerProps';
import SidebarContent from '../sidebar/SidebarContent';
import TopbarSearch1 from './Expiry';
import { formatCurrency, OUR_CLIENT, ToastTable, USERADMINCHECK } from '../../../_helpers/exports';
import { Settings } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";
import { BALANCE } from '../../../_helpers/exports';
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { Button } from 'reactstrap';
import { errorToast, NAME, successToast, ROLE, TOKEN, USERNAME, USER_ID } from '../../../_helpers/exports';
import axios from 'axios';
import { baseURL_2 } from '../../../_helpers';

class Topbar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isOPenDeposit: false,
    };
  }

  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    user: UserProps.isRequired,
    t: PropTypes.func.isRequired,

  };
  closeModal = () => {
    this.setState({
      isOpen: false
    })
  }


  openModal = () => {
    this.setState({
      isOpen: true
    })
  }

  closeModalDeposit = () => {
    this.setState({
      isOPenDeposit: false
    })
  }


  openModalDeposit = () => {
    this.setState({
      isOPenDeposit: true
    })
  }

  onSubmit = async e => {
    e.preventDefault();

    this.setState({ isLoading: true });

    if (this.state.msisdn == "" || this.state.msisdn == "" || this.state.amount == null) {

      errorToast("Check your inputs ");

    } else {


      let formData =
      {
        "amount": this.state.amount,
        "username": {
          "plan": -3,
          "subscription": "",
          "name": USERNAME,
          "id": USER_ID
        },
        "msisdn": this.state.msisdn.replaceAll("+", ""),
      }
      this.setState({ isLoading: true });
      axios.post(baseURL_2 + 'mpesa.php', formData, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': TOKEN
        },
      }).then((response) => {

        successToast("Success!! Check your phone to complete the transaction ");
        // console.log('bayoo', response.data)
        window.setTimeout(() => {
          this.setState({ isShowError: false, isLoading: false })
          // window.location.href = "/log_in";
        }, 8000);
        window.scrollTo(0, 0)
      }).catch((error) => {
        console.log('bayoo', error)
        this.setState({ statusMessage: "Error", showError: true, isShowError: false, isLoading: false });
      })
    }
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility, user } = this.props;

    return (
      <div className="topbar">

        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <h4 className="topbar__log" style={{ verticalAlign: 'center !important', marginTop: '20px', color: "white" }} to="/dashboard" >PeakBooks({USER_ID}) {USERADMINCHECK === 'true' ? 'Accountant-' + NAME : ""}</h4>
            {/* <Link className="topbar__logo topbar__log_desk" to="/online_marketing_dashboard" /> */}

          </div>
          <ToastTable />

          {/* option menu */}
          <Modal
            isOpen={this.state.isOpen}
            onRequestClose={e => {
              this.closeModal(e);
            }}
            contentLabel="My dialog"
            className="mymodal_outside"
            onAfterOpen={() => {
              document.body.style.overflow = 'hidden';
            }}
            onAfterClose={() => {
              document.body.removeAttribute('style');
            }}
            overlayClassName="myoverlay"
            closeTimeoutMS={500} >

            <MDBCloseIcon onClick={this.closeModal} />


            {/* <h4><b>Contact Us via</b></h4><br /> */}

            <b style={{ fontSize: '15px' }}>Call or WhatsApp us on  <a href="tel:0736105920" >0736105920 </a></b> <br /><br />
            <b style={{ fontSize: '15px' }}>Email us on  <a href='mailto: peakbooks@peakbooks.biz'>peakbooks@peakbooks.biz</a> </b>
            <br />
            <br />
            <Link to="/userguide"> <b style={{ fontSize: '15px' }}>User Guide</b></Link>



          </Modal>


          <Modal
            isOpen={this.state.isOPenDeposit}
            onRequestClose={e => {
              this.closeModalDeposit(e);
            }}
            contentLabel="My dialog"
            className="mymodal_outside"
            onAfterOpen={() => {
              document.body.style.overflow = 'hidden';
            }}
            onAfterClose={() => {
              document.body.removeAttribute('style');
            }}
            overlayClassName="myoverlay"
            closeTimeoutMS={500} >


            <MDBCloseIcon onClick={this.closeModalDeposit} />

            <h4><b style={{ fontSize: '15px' }}>Top Up </b></h4>

            <form className="form col-12" onSubmit={this.onSubmit}>

              <div className="col-md-12">


                <div className="col-md-12"><br />
                  <div className="form-group">
                    <label className="form-label">Amount Top Up</label>

                    <div className="row">
                      {/* <div className="col-md-2"> */}
                    </div>
                    <div className="col-md-12">
                      <input id="input" type="number" className="form-control"
                        name="amount" placeholder='Enter Amount to top up'
                        value={this.state.amount} onChange={this.handleChange} />
                    </div>
                  </div>
                </div>
                <br />
                <div className="form-group">
                  <div className="col-md-12">
                    <label className="form-label">Phone No.</label>
                  </div>

                  <div className="row">

                    <div className="col-md-12">
                      <PhoneInput
                        flags={flags}
                        defaultCountry="KE"
                        placeholder="Enter phone number (07XX)"
                        name="msisdn"
                        maxLength={15}
                        id="input"
                        value={this.state.msisdn}
                        onChange={value => this.setState({ msisdn: value })} />
                    </div>
                  </div>
                </div>
              </div>

              <br></br>
              <br />
              <br></br>
              <br />

              <div className="col-md-12"><br />
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-2">

                      <Button outline color="primary" className="btn-sm" onClick={this.closeModalDeposit}>
                        Cancel
                      </Button>

                    </div>
                    <div className="col-md-10">
                      <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                        {this.state.isLoading ? "Please Wait..." : "Top Up"}

                      </Button> &nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>


            </form>

          </Modal>


          <div className="topbar__right">
            {ROLE !== '1' ?
              <>

                <div className="topbar__search" style={{ marginRight: '60px' }}>


                  <h4 className="toolbar_help_message" onClick={this.openModalDeposit} style={{ color: "white", cursor: 'pointer', marginRight: '60px' }}>Top Up?</h4>

                  <h4 className="toolbar_help_message" onClick={this.openModal} style={{ color: "white", cursor: 'pointer', marginRight: '60px' }}>Need Help?</h4>

                  {OUR_CLIENT == 1 ?
                    <div>
                      {/* <TopbarSearch1 /> */}
                      <TopbarSearch />
                    </div>
                    :
                    null}

                </div>

                <div className="toolbar_help_message topbar__search" >
                  <Link to="settings">
                    <h4 className="toolbar_help_message" style={{ fontSize: "20px", display: 'block', textAlign: 'center', color: "white" }}>
                      <i className='fa fa-gear'></i></h4>
                    <h4 className="toolbar_help_message" style={{ display: 'block', textAlign: 'center', color: "white" }}>Settings</h4>
                  </Link>
                </div>

                <div className="toolbar_help_message topbar__search" style={{ marginLeft: '20px' }}>
                  <h4 className="toolbar_help_message" style={{ display: 'block', textAlign: 'center', color: "white" }}>Balance: <b>{formatCurrency(BALANCE)}</b></h4>

                </div>

              </>
              : null}

            <TopbarProfile user={user} />


          </div>
        </div>
      </div >
    );
  }
}
export default withTranslation('common')(Topbar);
