import { useState, useCallback, useEffect } from "react";

import axios from "axios";
import { CONFIG } from "./exports";
import { baseURL } from ".";

const UseFetch = (url) => {

  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true)

  const getData = useCallback(() => {
    setLoad(true)
    // alert(url)
    axios.get(`${baseURL + url}`, CONFIG).then(res => {
      setData(res.data);
      setLoad(false)
    }, [url]);
  })

  return [load, data, getData];

};

export default UseFetch;