import React, { Component } from 'react';
import styles from './Invoice.module.scss';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Form } from 'react-bootstrap';
import LineItems from './LineItems.jsx';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import {uuid} from 'uuidv4';
import { BRANCH_ID, CURRENCY, PROFILE_URL, TOKEN, USER_ID } from '../../_helpers/exports';
import { baseURL } from '../../_helpers';
import Alert from '../../shared/components/Alert';
import { Link, Navigate } from 'react-router-dom';
import $ from 'jquery';
import * as moment from 'moment';
import LineItemsBills from './LineItemsBills';
import { RadioGroup, ReversedRadioButton, RadioButton } from 'react-radio-buttons'
import LineItemsBillsCredit from './LineItemsBillsCredit';
// import LineItemsBillCredits from './LineItemsBillCredits';

window.$ = window.jQuery = require('jquery');
require('../../_assets/jss/sms_counter.js');
var invNum = require('invoice-number');

window.localStorage.setItem("num", "ABC000001")

class CreditNoteBill extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this._onButtonClick = this._onButtonClick.bind(this);
        this.onChangeOption = this.onChangeOption.bind(this);

    }


    locale = 'en-US';
    currency = CURRENCY;

    state = {
        taxRate: 0.00,
        credit_no: invNum.InvoiceNumber.next(localStorage.getItem("num")),
        credit_date: new Date(),
        editedInvoice: {},
        returnHome: false,
        tax_exclusive: 1,
        showtax: true,
        lineItems: [
            {
                id: 'initial', // react-beautiful-dnd unique key
                name: '',
                description: '',
                quantity: 1,
                discount_percentage: 0,
                tax_percentage: 0,
                price: 0.00,
            },
        ],
    };
    async componentDidMount() {
        $('#message').countSms('#sms-counter');

        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = `${PROFILE_URL}?canvas=true`;
        }

        localStorage.setItem("num", invNum.InvoiceNumber.next(localStorage.getItem("num")))
        const [customerResponse] = await Promise.all([
            axios.get(baseURL + `vendor?branch_id=${BRANCH_ID}&&page_size=1000&page_number=1&client_id=` + USER_ID, {
                headers: { Authorization: TOKEN },
            }),
        ]);
        this.setState({
            isLoading: false,
            returnHome: false,
            credit_no: invNum.InvoiceNumber.next(this.state.credit_no),

            customerResponse: customerResponse.data.data,
        });

        console.log('customer', this.state.customerResponse);
    }
    handleLineItemChange = elementIndex => event => {

        console.log(this.state.lineItems);

        let lineItems = this.state.lineItems.map((item, i) => {
            if (elementIndex !== i) return item;
            // alert(elementIndex);
            return { ...item, [event.target.name]: event.target.value };
        });
        this.setState({ lineItems });
    };

    formatCurrency = amount => {
        return new Intl.NumberFormat(this.locale, {
            style: 'currency',
            currency: "ABS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount).replaceAll('ABS', CURRENCY);
    };
    Customer() {
        return (
            this.state.customerResponse &&
            (this.state.customerResponse.length > 0 || this.state.customerResponse.length === 0) &&
            this.state.customerResponse.map((countyItem, i) => ({
                label: countyItem.vendor_display_name + " (" + countyItem.email + ")",
                customer_email: countyItem.email,
                customer_nameinvoice: countyItem.vendor_display_name,
                value: countyItem.id,
            }))
        );
    }

    handleAddLineItem = event => {
        // alert(JSON.stringify(this.state.lineItems))
        for (let i = 0; i < this.state.lineItems.length; i++) {

            if (this.state.lineItems[i].name === '') {
                alert("Name of the item can not be null")
                this.setState({
                    // use optimistic uuid for drag drop; in a production app this could be a database id
                    lineItems: this.state.lineItems.concat([]),
                });
            } else {
                this.setState({
                    // use optimistic uuid for drag drop; in a production app this could be a database id
                    lineItems: this.state.lineItems.concat([
                        {
                            id: uuid(), // react-beautiful-dnd unique key
                            name: '',
                            description: '',
                            quantity: 1,
                            discount_percentage: 0,
                            tax_percentage: 0,
                            price: 0.00,
                        },
                    ]),
                });
            }
        }


    };

    handleRemoveLineItem = elementIndex => event => {
        this.setState({
            lineItems: this.state.lineItems.filter((item, i) => {
                return elementIndex !== i;
            }),
        });
    };

    handleReorderLineItems = newLineItems => {
        this.setState({
            lineItems: newLineItems,
        });
    };
    handleChangeInvoiceMessage = event => {
        this.setState({ customer_note: event.target.value }, function () { });
        //alert(event.target.value)
    };

    handleChangeStatementMessage = event => {
        this.setState({ terms_condition: event.target.value }, function () { });
        //alert(event.target.value)
    };

    handleFocusSelect = event => {
        event.target.select();
    };

    handlePayButtonClick = () => {
        alert('Not implemented');
    };
    formatCurrency1 = amount => {
        return new Intl.NumberFormat(this.locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };
    calcTaxAmount = c => {
        return c * (localStorage.getItem('valuestax') / 100);
    };

    calcLineItemsTotal = () => {
        return this.state.lineItems.reduce(
            (prev, cur) => prev + cur.quantity * cur.price,
            0
        );
    };

    calcLineItemDiscounts = () => {
        return this.state.lineItems.reduce(
            (prev, cur) => prev + cur.discount_percentage / 100 * (cur.quantity * cur.price),
            0
        );
    };

    calcTaxTotal = () => {
        if (this.state.showtax == true) {
            return this.state.lineItems.reduce(
                (prev, cur) => prev + ((cur.tax_percentage / 100) * (cur.quantity * cur.price)),
                0
            );
        } else {
            return this.state.lineItems.reduce(
                (prev, cur) => 0 + (0 * 0),
                0
            );
        }
    };


    calcGrandTotal = () => {
        return (
            this.calcLineItemsTotal() +
            this.calcTaxTotal() -
            this.calcLineItemDiscounts()
        );
    };

    onSelectChangesShort = value => {
        if (value != null) {

            this.setState(
                {
                    ...this.state.editedInvoice,
                    customer_id: value.value.toString(),
                    customer_name: value.label.toString(),
                    customer_nameinvoice2: value.customer_nameinvoice.toString(),
                    customer_email: value.customer_email != null ?  value.customer_email.toString(): '',
                });
        }
    };

    handleChange = e => {
        this.setState({
            ...this.state.editedInvoice,
            [e.target.name]: e.target.value,
        });

        console.log('updates', this.state.editedInvoice);
    };





    onSubmit(e) {
        e.preventDefault();
        
        // eslint-disable-next-line
        this.state.editedInvoice = {

            credit_date: this.state.credit_date,
            notes: this.state.customer_note,
            customer_name: this.state.customer_nameinvoice2,
            customer_email: this.state.customer_email,
            tax_exclusive: this.state.tax_exclusive,
            amount1: this.formatCurrency1(this.calcGrandTotal()),
            amount: this.formatCurrency(this.calcGrandTotal()),
            due_amount: this.formatCurrency(this.calcGrandTotal()),
            tax_amount: this.formatCurrency(this.calcTaxTotal()),
            discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
            lineItems: this.state.lineItems,
            currency: CURRENCY,
            tax: this.formatCurrency(this.calcTaxTotal()),
            subtoal: this.formatCurrency(this.calcLineItemsTotal()),
            total: this.formatCurrency(this.calcGrandTotal()),

        };
        let formData = {
            credit_date: this.state.credit_date,
            notes: this.state.customer_note,
            customer_name: this.state.customer_nameinvoice2,
            customer_email: this.state.customer_email,
            tax_exclusive: this.state.tax_exclusive,
            sub_total: this.formatCurrency(this.calcLineItemsTotal()),
            amount: this.formatCurrency(this.calcGrandTotal()),
            due_amount: this.formatCurrency(this.calcGrandTotal()),
            tax_amount: this.formatCurrency(this.calcTaxTotal()),
            discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
            created_by: USER_ID,
            vendor_id: this.state.customer_id,
        };
        // alert(JSON.stringify(formData))

        // console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios
            .post(baseURL + `creditvendor`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: TOKEN,
                },
            })
            .then(response => {
                if (response.data.status) {
                    this.setState({
                        statusMessage: response.data.message,
                        isShowError: true,
                        isLoading: false,
                        showError: false
                    });

                    localStorage.setItem("CREDIT_SENDEMAILD", JSON.stringify(this.state.editedInvoice))

                    localStorage.setItem("CREDIT_CREATEPEAKD", JSON.stringify(this.state.lineItems))
                    window.setTimeout(() => {
                        this.setState({ isShowError: false, returnHome: true });
                        this._onButtonClick();
                    }, 2000);
                    window.scrollTo(0, 0);
                } else {
                    this.setState({
                        statusMessage: response.data.message,
                        showError: true,
                        isShowError: false,
                        isLoading: false,
                    });
                    window.scrollTo(0, 0);
                }
            })
            .catch(error => {
                console.log('bayoo', error.response);
                this.setState({
                    statusMessage: error.response.data.message,
                    showError: true,
                    isShowError: false,
                    isLoading: false,
                });
                window.scrollTo(0, 0);
            });
    }


    _onButtonClick() {
        this.setState({
            returnHome: true
        });
    }
    loadOptions = inputValue => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                Authorization: TOKEN,
            },
        };
        this.setState({
            isLoading: true,
        });
        let url =
            baseURL +
            `customer?branch_id=${BRANCH_ID}&&client_id=` +
            USER_ID +
            `&&filter_value=${inputValue}`;
        axios.get(url, axiosConfig).then(itemResponse => {
            this.setState(
                {
                    item: itemResponse.data.data,
                },
                function () {
                    this.setState({
                        isLoading: false,
                        bayo: this.state.item &&
                            (this.state.item.length > 0 || this.state.item.length === 0) &&
                            this.state.item.map((countyItem, i) => ({
                                label: countyItem.customer_display_name,
                                customer_email: countyItem.email,
                                value: countyItem.id,
                            })),
                    });
                }
            );
        });
    };
    onChangeOption(value) {
        console.log(value);
        if (value == 1) {
            this.setState({
                showtax: true,
                tax_inclusive: '0',
                tax_exclusive: 1,
            })
        } else {
            this.setState({
                showtax: false,
                tax_inclusive: '1',
                tax_exclusive: 0

            })
        }
        // alert(value)
    }
    handleCreate = inputValue => {
        const newValue = { value: inputValue.toLowerCase(), label: inputValue };
        this.setState({ newValue })
        // setOptions([...options, newValue]);
        // setValue(newValue);

    };
    render = () => {
        console.log('ITEMS', this.state.lineItems);
        const ref = React.createRef();
        const { editedInvoice, returnHome } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {returnHome === true ? <Navigate to='/vendor-credit' /> : null}

                <div ref={ref}>

                    <Card>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4><b>Create Credit Note</b></h4>
                                </div>
                                <div className="col-md-6">
                                    <Link to="/vendor-credit">
                                        <Button className="pull-right" onClick={this._onButtonClick}
                                            color="primary" outline><i className="fa  fa-arrow-left"></i> Credit Notes
                                      </Button>
                                    </Link>

                                </div>
                            </div>

                            {this.state.showError
                                ? <div>

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p>
                                            <span className="bold-text">
                                                {this.state.statusMessage}
                                            </span>
                                        </p>
                                    </Alert>

                                </div>
                                : null}<br />

                            {this.state.isShowError
                                ? <div>

                                    <Alert color="success" className="alert--colored" icon>
                                        <p>
                                            <span className="bold-text">
                                                {this.state.statusMessage}
                                            </span>
                                        </p>
                                    </Alert>

                                </div>
                                : null}<br />
                            <form onSubmit={this.onSubmit}>
                                <br /><br />
                                <div className="col-md-8 offset-2">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Supplier</label>
                                            </div>
                                            <div className="col-md-10">

                                                <Select
                                                    isClearable
                                                    options={this.Customer()}
                                                    onInputChange={this.loadOptions}
                                                    placeholder="Search Supplier"
                                                    autosize={true}
                                                    onChange={this.onSelectChangesShort}
                                                    isLoading={this.state.isLoading}
                                                    // noOptionsMessage={() => 'nothing found'}
                                                    // loadingMessage={() => 'searching...'}   //minor type-O here
                                                    menuPortalTarget={document.body}
                                                    name="namffe"
                                                /><br />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-8 offset-2">
                                    <br />
                                    <Form.Group className="Focus-line">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <Form.Label>Credit Note date</Form.Label>
                                            </div>
                                            <div className="col-md-10 customDatePickerWidth">
                                                <DatePicker
                                                    selected={this.state.credit_date}
                                                    label="DatePicker Label"
                                                    dateFormat="dd MMM yyyy"
                                                    required
                                                    minDate={moment().toDate()}

                                                    value={editedInvoice.credit_date}
                                                    className="form-control"
                                                    id="input"
                                                    onChange={value =>
                                                        this.setState({ credit_date: value })}
                                                />

                                            </div>
                                        </div>
                                    </Form.Group>
                                </div>



                                <div className="col-md-12">

                                    <div className="col-md-12">
                                        <br />
                                        <div className="col-md-3" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>

                                            <RadioGroup onChange={this.onChangeOption} value='1' horizontal>
                                                <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                                    Tax Exclusive
                                                    </RadioButton>
                                                <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                                    Tax Inclusive
                                                   </RadioButton>

                                            </RadioGroup>
                                        </div>
                                        <br />
                                        <LineItemsBillsCredit
                                            items={this.state.lineItems}
                                            currencyFormatter={this.formatCurrency}
                                            addHandler={this.handleAddLineItem}
                                            showtax={this.state.showtax}
                                            changeHandler={this.handleLineItemChange}
                                            focusHandler={this.handleFocusSelect}
                                            deleteHandler={this.handleRemoveLineItem}
                                            reorderHandler={this.handleReorderLineItems}
                                        />

                                        <div className="col-md-12">

                                            <div className={styles.totalContainer}>
                                                {/* <form>
                            <div className={styles.valueTable}>
                              <div className={styles.row}>
                                <div className={styles.label}>Tax Rate (%)</div>
                                <div className={styles.value}>
                                  <input
                                    name="taxRate"
                                    type="text"
                                    id="#js-testInputs"
                                    value={localStorage.getItem('valuestax')}
                                    onChange={this.handleInvoiceChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </form> */}
                                                <form></form>
                                                <form>
                                                    <div className={styles.valueTable}>
                                                        <div className={styles.row}>
                                                            <div className={styles.label}>Subtotal</div>
                                                            <div
                                                                className={`${styles.value} ${styles.currency}`}
                                                            >
                                                                {this.formatCurrency(
                                                                    this.calcLineItemsTotal()
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.row}>
                                                            <div className={styles.label}>Discount</div>
                                                            <div
                                                                className={`${styles.value} ${styles.currency}`}
                                                            >
                                                                {this.formatCurrency(
                                                                    this.calcLineItemDiscounts()
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.row}>
                                                            <div className={styles.label}>
                                                                Tax
                                                                    </div>
                                                            <div
                                                                className={`${styles.value} ${styles.currency}`}
                                                            >
                                                                {this.formatCurrency(this.calcTaxTotal())}
                                                            </div>
                                                        </div>

                                                        <div className={styles.row}>
                                                            <div className={styles.label}>Total Due</div>
                                                            <div
                                                                className={`${styles.value} ${styles.currency}`}
                                                            >
                                                                {this.formatCurrency(this.calcGrandTotal())}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            {/* 
                  <div className={styles.pay}>
                    <button className={styles.payNow} onClick={this.handlePayButtonClick}>Pay Now</button>
                  </div> */}
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 offset-2">
                                    <br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">

                                                <label className="form-label">
                                                    Notes

                          </label>
                                            </div>
                                            <div className="col-md-8">
                                                <textarea
                                                    name="invoice_message"
                                                    
                                                    style={{
                                                        paddingTop: '20px',
                                                        borderRadius: '5px',
                                                        borderColor: 'hsl(0,0%,80%)',
                                                    }}
                                                    onChange={this.handleChangeInvoiceMessage}
                                                    className="col-md-12"
                                                    placeholder="Hint: Notes"
                                                    id="message"
                                                    cols="col-md-1"
                                                    rows="1"
                                                />
                                            </div>

                                            <div className="col-md-2">
                                                <ul id="sms-counter">
                                                    <li>Length: <span class="length" /></li>
                                                    {/* <li>SMS: <span class="messages"></span></li> */}
                                                    <li>
                                                        Message:
                              {' '}
                                                        <span class="remaining" />
                              /
                              <span class="per_message" />
                                                    </li>
                                                    {/* <li>Remaining: </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>


                                <br />
                                <br />
                                <br />
                                <br />

                                <div className="col-md-12"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <Link to="/credit-note">
                                                    <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                                        Cancel
                              </Button>
                                                </Link>

                                            </div>
                                            <div className="col-md-10">
                                                <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                                    {this.state.isLoading ? "Please Wait..." : "Save"}

                                                </Button> &nbsp;&nbsp;&nbsp;
                                                    </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <br />

                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    };
}

export default CreditNoteBill;
