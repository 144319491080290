import React from 'react';
import '../../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../../_helpers';
import { TOKEN, USER_ID } from '../../../_helpers/exports';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Alert from '../../../shared/components/Alert';
import { SalesPersonView } from './SalesPerson';
import { TaxView } from './Tax';


class EditTax extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            showError: false,
            item_name: "",
            tax_name: localStorage.getItem("tax_name"),
            tax_value: localStorage.getItem("tax_value")
        }
    }



    onSubmit(e) {

        e.preventDefault();

        let formData = {
            "created_by": USER_ID,
            "id": localStorage.getItem("tax_id"),
            "tax_name": this.state.tax_name,
            "tax_value": this.state.tax_value
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.put(baseURL + `tax`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                    window.scrollTo(0, 0)
                    window.setTimeout(() => {
                        this.setState({ isShowError: false, sales_person: "" });
                        this._onButtonClick();
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
            })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            moreinfo: false
        });
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <TaxView /> : null
                        }
                    </div>
                )}


                {!hideComponent && (
                    <div>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>Edit Tax</b></h4><br />
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/tax">
                                            <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                Tax
                                            </Button>
                                        </Link>

                                    </div>
                                </div>


                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>


                                </div> : null}<br></br>

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div>

                                ) : null}<br></br>
                                <form className="form" onSubmit={this.onSubmit}>

                                    <div className="col-md-8 offset-2"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Tax Name</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control"
                                                        name="tax_name" required placeholder='Tax Name'
                                                        value={this.state.tax_name} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Tax Value (Percentage)</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="number" className="form-control"
                                                        name="tax_value" required placeholder='Tax Value(in percentage)'
                                                        value={this.state.tax_value} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br></br>
                                    <br />
                                    <br></br>
                                    <br />
                                    <div className="col-md-8 offset-2"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Link to="/tax">
                                                        <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                                            Cancel
                                                          </Button>
                                                    </Link>

                                                </div>
                                                <div className="col-md-10">
                                                    <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                                        {this.state.isLoading ? "Please Wait..." : "Save"}

                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                )
                }
            </div>
        )
    }
}
export default withTranslation('common')(EditTax);
