import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App/App'

import * as serviceWorker from './serviceWorker'

ReactDOM.render(<React.StrictMode>
  <App />

</React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()


// //  doesn't work since v15.6.0
// var ev = new Event('input', { bubbles: true});
// document.querySelector('form:first-child input').value = 'Not working';
// document.querySelector('form:first-child input').dispatchEvent(ev);

// var input = document.querySelector('form:last-child input');
// var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
// nativeInputValueSetter.call(input, '');
// var ev2 = new Event('input', { bubbles: true});
// input.dispatchEvent(ev2);
