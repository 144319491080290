import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Visits from './components/Visits.jsx';
import Panel from '../../../shared/components/Panel';
import { Pie } from '@reactchartjs/react-chart.js'
import axios from 'axios';

import TotalPageViews from './components/TotalPageViews';
import NewUsers from './components/NewUsers';
import BounceRate from './components/BounceRate';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import NFCTags from './components/NFCTags';
import Survey from './components/Breaking';
import Questions from './components/Qustions';
import TotalCount from './components/TotalCount';
import { Bar } from 'react-chartjs-2';
// import MessageTermination from '../messsagetermination';
import { CURRENCY, TOKEN, USER_ID, ROLE, USER_ID_ACC, formatCurrency } from '../../../_helpers/exports';
import * as moment from 'moment';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import { baseURL } from '../../../_helpers';
import { Navigate } from 'react-router';

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
}); // Change locale according to your currency and country

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: (label, index, labels) => {
            return formatCurrency(label);
          }
        }
      },
    ],
  },
}

function DefaultDash({ t, rtl }) {

  let now = new Date();
  let start = moment(new Date(new Date().getFullYear(), 0, 1));

  let end = moment(moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))).add(1, "days").subtract(1, "seconds");
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end)
  const [valuedate, setValueDate] = useState('This year')
  const [reload, setReload] = useState(false)
  const [sales, setSales] = useState()
  const [expenses, setExpenses] = useState()
  const [receivables, setReceivables] = useState()
  const [payables, setPayables] = useState()
  const [labels, setLabel] = useState([]);
  const [labelPie, setLabelPie] = useState([]);
  const [success, setSuccess] = useState([]);




  const [profession, setProffession] = useState([]);
  const [administration, setAdministration] = useState([]);
  const [staff, setStaff] = useState([]);
  const [allowances, setAllowances] = useState([]);
  const [finance, setFInance] = useState([]);
  const [establishment, setEstablishment] = useState([]);
  const [others, setOthers] = useState([]);

  const [year, setYear] = useState(start);

  const [expense, setExpense] = useState([]);
  const [salesgr, setSlaesGr] = useState([]);

  useEffect(
    () => {
      setStartDate(startDate)
      setEndDate(endDate)
      setReload(false)

      setLabelPie(["Professional Fees", "Administration Expenses", "Staff Expenses", "Allowances for Asset Impairements", "Finance Costs",
        "Establishment Expenses", "Other Expenses"])


      setYear(startDate)


      let myear = moment(year).format('YYYY')
      // alert()

      let url = baseURL + `operationexpense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${moment(startDate).format('YYYY-MM-DD')} 00:00:00"&endDate="${moment(endDate).format('YYYY-MM-DD')} 23:59:59"`
      let url_sales = baseURL + `invoiceamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${moment(startDate).format('YYYY-MM-DD')} 00:00:00"&endDate="${moment(endDate).format('YYYY-MM-DD')} 23:59:59"`
      let url_bar = baseURL + `operationexpense_bar?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${moment(startDate).format('YYYY-MM-DD')} 00:00:00"&endDate="${moment(endDate).format('YYYY-MM-DD')} 23:59:59"`



      axios.all([
        axios.get(url, { headers: { "Authorization": `${TOKEN}` } }),

        axios.get(url_sales, { headers: { "Authorization": `${TOKEN}` } }),
        axios.get(url_bar, { headers: { "Authorization": `${TOKEN}` } }),

      ]).then(axios.spread((expenseResponse, salesResponses, barResponses) => {




        let salesArray = [];
        let expenseArray = [];
        let labelArray = [];

        const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

        let data = []

        for (let i = 0; i < labels.length; i++) {

          if (barResponses.data.data.length > 0) {
            console.log("testtest ", barResponses.data.data)

            barResponses.data.data.map((d) => {
              if (d.month === labels[i]) {
                expenseArray.push(d.expense)
                salesArray.push(d.sales)
                labelArray.push(d.month)
              }
            })
          }
        }


        setLabel(labelArray)
        setExpense(expenseArray)
        setSlaesGr(salesArray)


        setProffession(expenseResponse.data.data[2].amount)

        setAdministration(expenseResponse.data.data[5].amount)

        setStaff(expenseResponse.data.data[0].amount)

        setAllowances(expenseResponse.data.data[3].amount)

        setFInance(expenseResponse.data.data[4].amount)

        setEstablishment(expenseResponse.data.data[1].amount)

        setOthers(expenseResponse.data.data[6].amount)

        setSales(salesResponses.data.data[0].amount)

        if (expenseResponse.data.data === 0) {

          setExpenses("0.00")
          // this.setState({
          //   cost_of_operation: "0.00",
          //   isPageLoad: false
          // })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < expenseResponse.data.data.length; j++) {
            let a;
            if (expenseResponse.data.data[j].amount == null) {
              a = 0;
            } else {

              let str2 = expenseResponse.data.data[j].amount.toString()
              // alert(this.state.expense[].amount)
              countExpense = parseFloat(str2.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
          }

          setExpenses(valueAddedExpenses)
        }


        // setExpenses(
        //   expenseResponse.data.data[2].amount + expenseResponse.data.data[5].amount +
        //   expenseResponse.data.data[0].amount + expenseResponse.data.data[4].amount +
        //   expenseResponse.data.data[1].amount + expenseResponse.data.data[6].amount
        // )


        // alert(JSON.stringify(salesResponses.data.data[0].amount))

      })).catch(e => {
        console.log("jnerjbnjrbjebrbejbj", e);
      })

      // alert(sales)

    }, [startDate, endDate]);


  const graph_data = {
    labels,
    datasets: [
      {
        label: 'Sales',
        data: salesgr,
        backgroundColor: 'green',
      },


      {
        label: 'Expenses',
        data: expense,
        backgroundColor: 'red',
      },
    ],
  };



  const applyCallback = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)
    let Sdate1 = moment(startDate).format('DD MMM, YYYY');
    let Edate2 = moment(endDate).format('DD MMM, YYYY');
    setValueDate(Sdate1 + " " + Edate2)
    setReload(true)
  }

  let locale = 'en-US';
  let currency = CURRENCY;


  const formatCurrency = amount => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: "ABS",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount).replaceAll('ABS', CURRENCY);
  };

  let ranges = {

    "Today Only": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)), moment(endDate)],
    "Yesterday Only": [
      moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(1, "days"),
      moment(endDate).subtract(1, "days")
    ],
    "3 Days": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(3, "days"), moment(endDate)],
    "5 Days": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(5, "days"), moment(endDate)],
    "1 Week": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(7, "days"), moment(endDate)],
    "2 Weeks": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(14, "days"), moment(endDate)],
    "1 Month": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(1, "months"), moment(endDate)],
    "6 Months": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(1, "months"), moment(endDate)],

    "1 Year": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(1, "years"), moment(endDate)]
  };
  let local = {
    "format": "DD-MM-YYYY",
    "sundayFirst": false
  }


  const handleSales = (langValue) => {
    setSales(langValue.toString())
  }

  const handleExpenses = (langValue) => {
    setExpenses(langValue.toString())
  }

  const handleReceivable = (langValue) => {
    setReceivables(langValue.toString())
  }

  const handlePayables = (langValue) => {
    setPayables(langValue.toString())
  }

  return (
    <Col className="dashboard" style={{ height: "100vh" }}>
      <Row>
        <Col md={12}>
          <div className="row">
            <div className="col-md-4">
              <h3 className="page-title">Dashboard</h3>
            </div>
            <div className="col-md-8">
              <div className="col-12 pull-right">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-6">
                      <DateTimeRangeContainer
                        ranges={ranges}
                        start={startDate}
                        end={endDate}
                        local={local}
                        applyCallback={applyCallback}>
                        <FormControl
                          id="formControlsTextB"
                          type="text"
                          label="Text"
                          value={valuedate}

                          placeholder="Filter by Date"
                        />
                      </DateTimeRangeContainer>
                    </div>

                    <div className="col-6 float-left">

                      <button className=" float-left btn btn-primary btn-sm" onClick={e => {

                        setStartDate(moment(new Date(new Date().getFullYear(), 0, 1)))
                        setEndDate(moment(new Date()))
                        setValueDate("This Year")
                        setReload(true)


                      }} >Clear</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row >
        {/* {startDate} */}
        {ROLE === '2' ?
          <>
            {/* <Subscribers expenses={handleExpenses} reload={reload} startDate={startDate} endDate={endDate} /> */}

            <Visits recevaibles={handleReceivable} reload={reload} startDate={startDate} endDate={endDate} />

            <NewUsers payable={handlePayables} reload={reload} startDate={startDate} endDate={endDate} />
            {/* //sales */}
            {/* <BreakingAdminnews sales={handleSales} reload={reload} startDate={startDate} endDate={endDate} /> */}

            <>


              <Col xs={12} md={12} lg={12} xl={6}>
                <Bar options={options} data={graph_data}
                />

                <h5 className="text-center">Total Income: <b>{formatCurrency(sales)} </b></h5>

                <h5 className="text-center">Total Expenses: <b>{formatCurrency(expenses)}</b></h5>
              </Col>

              <Col xs={12} md={12} lg={12} xl={6}>

                <h4 className='title'>Total Expenses:<b> {formatCurrency(expenses)}</b></h4><br />

                <Pie data={{
                  labels: labelPie,
                  datasets: [
                    {
                      label: 'Expenses',
                      data: [profession, administration, staff, allowances, finance, establishment, others],
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                      ],
                      borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                      ],
                      borderWidth: 1,
                    }
                  ],
                }} options={options}
                  width={90}
                  height={45} />
              </Col>
            </>
          </>
          : null}


        {ROLE === '1' ?
          <>
            <Navigate to="/users" />
          </>
          : null}


        {ROLE === '3' ?
          <>
            <TotalCount />

            <BounceRate />
            <Survey />
            <Questions />


          </>
          : null}

      </Row>
      {/* <Row>
      <ABTestingAnalytics dir={rtl.direction} />
      <BestSellingRegions />
      <GoalsCompletion />
    </Row> */}
    </Col >
  )
}

DefaultDash.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired,
};

export default compose(withTranslation('common'), connect(state => ({
  rtl: state.rtl,
})))(DefaultDash);