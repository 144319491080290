import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Table, Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { BRANCH_ID, CONFIG, CURRENCY, PROFILE_URL, formatCurrency, successToast, ToastTable, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
// import { saveInvoiceView } from './pdfview';
import Payment_Received from './payment_received';
import { savePaymentView } from './pdfReceipt.jsx';
import { savePaymentSend } from './pdfReceiptSend.jsx';
import { groupedOptions } from './paidthrough';


export class ViewPayments extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);


        this.columns = [
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Payment Date",
                className: "cell",
                altrueign: "left"
            },
            {
                key: "reference",
                TrOnlyClassName: 'tsc',
                text: "Reference#",
                className: "cell",
                align: "left"
            },
            {
                key: "customer_display_name",
                TrOnlyClassName: 'tsc',
                text: "Company Name",
                className: "cell",
                altrueign: "left"
            },
            {
                key: "invoice_no",
                TrOnlyClassName: 'tsc',
                text: "Invoice#",
                className: "tsc",
                align: "left"
            },

            // {
            //     key: "payment_mode",
            //     TrOnlyClassName: 'tsc',
            //     text: "Mode",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "paid_amountss",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },

            // {
            //     key: "outstanding",
            //     TrOnlyClassName: 'tsc',
            //     text: "Outstanding Balance",
            //     className: "tsc",
            //     align: "left"
            // },
            // {
            //     key: "overpayment",
            //     TrOnlyClassName: 'tsc',
            //     text: "Over Payment",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-success  btn-sm"
                                title="View"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick(record) }} >
                                View
                            </button>
                            <button className="btn btn-primary  btn-sm"
                                title="View"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => {
                                        if (window.confirm('Are you sure you want edit this receipt'))
                                            this.handleOnClickEdit(record)
                                    }
                                } >
                                Edit
                            </button>

                            <button className="btn btn-primary  btn-sm"
                                title="View"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => {
                                        if (window.confirm('Are you sure you want deliver this receipt via email'))
                                            this.handleOnClickEmail(record)
                                    }
                                } >
                                Send Email
                            </button>
                            <button className="btn btn-danger btn-sm"
                                title="Delete"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick1(record) }} >
                                Delete
                            </button>

                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [100, 200, 500],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 100,

            button: {
                excel: true,
                csv: true
            },
            filename: "payment_received",
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Invoice no...",
                no_data_text: "No payment record was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showComponent: false,
            onEdit: false,
            _onShow: false,
            goEdit: false,
            returnHome: false,
            hideComponent: false,
            data: [],
        };

    }
    locale = 'en-US';
    currency = CURRENCY;
    async handleOnClick1(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onDelete(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }



    async handleOnClickEdit(record) {

        // alert(record.deposit_to)
        localStorage.setItem('payment_received_mode', record.payment_mode);
        localStorage.setItem('payment_received_deposit', record.deposit_to);

        localStorage.setItem('payment_received_date', record.payment_date);

        localStorage.setItem('payment_received_id', record.id);

        localStorage.setItem('payment_received_customer', record.customer_display_name);



        // alert(record.id)

        window.location.href = "edit-payment"

    }

    async handleOnClickEmail(record) {
        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })

        // alert(record.id)
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };


        this.setState({
            returnHome: true
        })

        // let url = baseURL + `invoicepaid?filter_value=` + record.id
        let url_record = baseURL + `invoicepaid_record?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID + `&filter_value=` + record.id
        axios.all([
            axios.get(url_record, axiosConfig)
        ]).then(axios.spread((invoiceResponse, invoiceRecordResponse) => {
            this.setState({
                lineItems: invoiceResponse.data.data,
                amount_received_paid: record.paid_amount,
                returnHome: false
            })
            let data2 = [];
            for (let i = 0; i < invoiceResponse.data.data.length; i++) {
                let mpayment_amount;
                var str = invoiceResponse.data.data[i].amount;
                var str3 = invoiceResponse.data.data[i].due_amount;
                str3 = str3.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                var num2 = parseFloat(str3);

                let mamountcompared;
                // s = s.replace(/^+/, "");
                // var str = 
                // eslint-disable-next-line
                str = str.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                var num = parseFloat(str);

                let minvoice_amount = { mminvoice_amount: parseFloat(num) }

                if (this.state.amount_received_paid > num) {
                    // payment_amount_raw = amount_received - num;
                    mpayment_amount = { payment_amount: num }
                    mamountcompared = num;
                    this.setState({
                        amount_received_paid: this.state.amount_received_paid - num
                    })
                } else if (this.state.amount_received_paid > 0) {
                    mpayment_amount = { payment_amount: this.state.amount_received_paid }
                    mamountcompared = this.state.amount_received_paid;

                    this.setState({
                        amount_received_paid: 0
                    })
                }
                else {
                    mpayment_amount = { payment_amount: this.state.amount_received_paid }
                    mamountcompared = this.state.amount_received_paid;
                }
                // if (this.state.amount_received_paid > 0) {
                data2.push(Object.assign(mpayment_amount, minvoice_amount, invoiceResponse.data.data[i]));
                this.setState({
                    data2: data2,
                });
                // localStorage.setItem("DATAARvvRAY", JSON.stringify(data2))
            }

            // alert(invoiceResponse.data.length)
            this.state.editedInvoice = {
                reference: record.reference,
                payment_date: record.dates,
                payment_mode: record.payment_mode,
                paid_amount: formatCurrency(record.paid_amount),
                lineItems: this.state.data2.filter(person => person.payment_amount != 0),
                customer_name: record.customer_display_name,
                customer_email: record.email,
                notes: record.notes,
                unpaid_amount: record.unpaid_amount,
                currency: CURRENCY,
            };
            // savePaymentView(this.state.editedInvoice);
            savePaymentSend(Object.assign(footer, this.state.editedInvoice));

            successToast("Email sent successfully!");
            // alert("Email has been send")

        })).catch(error => {
            console.log('bayoo', error.response)

        })

        // // console.log("hoge!");
        // // if (await confirm("Y")) {
        // this._onButtonClick2(record)
        // } else {
        //     this.setState({
        //         message: "No!"
        //     });
        // }
    }


    async handleOnClick(record) {
        // alert(record.id)
        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        this.setState({
            returnHome: true
        })
        // let url = baseURL + `invoicepaid?filter_value=` + record.id
        let url_record = baseURL + `invoicepaid_record?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID + `&filter_value=` + record.id
        axios.all([
            axios.get(url_record, axiosConfig)
        ]).then(axios.spread((invoiceResponse, invoiceRecordResponse) => {
            this.setState({
                lineItems: invoiceResponse.data.data.filter(person => person.amount > 0),

                amount_received_paid: record.paid_amount,
                returnHome: false
            })
            let data2 = [];
            for (let i = 0; i < invoiceResponse.data.data.length; i++) {
                let mpayment_amount;
                var str = invoiceResponse.data.data[i].amount;
                var str3 = invoiceResponse.data.data[i].due_amount;
                str3 = str3.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                var num2 = parseFloat(str3);
                let mamountcompared;
                // s = s.replace(/^+/, "");
                // var str = 
                // eslint-disable-next-line
                str = str.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                var num = parseFloat(str);
                let minvoice_amount = { mminvoice_amount: parseFloat(num2) + parseFloat(num) }
                if (this.state.amount_received_paid > num) {
                    // payment_amount_raw = amount_received - num;
                    mpayment_amount = { payment_amount: num }
                    mamountcompared = num;
                    this.setState({
                        amount_received_paid: this.state.amount_received_paid - num
                    })
                } else if (this.state.amount_received_paid > 0) {
                    mpayment_amount = { payment_amount: this.state.amount_received_paid }
                    mamountcompared = this.state.amount_received_paid;
                    this.setState({
                        amount_received_paid: 0
                    })
                }
                else {
                    mpayment_amount = { payment_amount: this.state.amount_received_paid }
                    mamountcompared = this.state.amount_received_paid;
                }
                // if (this.state.amount_received_paid > 0) {
                data2.push(Object.assign(mpayment_amount, minvoice_amount, invoiceResponse.data.data[i]));
                this.setState({
                    data2: data2,
                });
                // localStorage.setItem("DATAARvvRAY", JSON.stringify(data2))
            }


            // alert(invoiceResponse.data.length)
            this.state.editedInvoice = {
                reference: record.reference,
                payment_date: record.dates,
                payment_mode: record.payment_mode,
                paid_amount: formatCurrency(record.paid_amount),
                lineItems: this.state.data2.filter(person => person.payment_amount != 0),
                customer_name: record.customer_display_name,
                customer_email: record.email,
                notes: record.notes,
                unpaid_amount: record.unpaid_amount,
                currency: CURRENCY,
            };
            savePaymentView(Object.assign(footer, this.state.editedInvoice));
            // savePaymentSend(this.state.editedInvoice);
        })).catch(error => {
            console.log('bayoo', error.response)
        })
        // // console.log("hoge!");
        // // if (await confirm("Y")) {
        // this._onButtonClick2(record)
        // } else {
        //     this.setState({
        //         message: "No!"
        //     });
        // }
    }
    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {

        localStorage.setItem("DATAARRAY", JSON.stringify([]))

        // $('.main').scroll(function () {
        //     $(this).find('.sticky').css('left', $(this).scrollLeft());
        // });

        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = `${PROFILE_URL}?canvas=true`;
        }

        this.getData();
        this.load()
    }


    load() {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url3 = baseURL + `deposit_to`
        let url4 = baseURL + `bank?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&account_type=Bank`
        let url5 = baseURL + `bank?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&account_type=Credit Card`
        let url6 = baseURL + `expense_account?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID

        axios.all([
            axios.get(url3, axiosConfig),
            axios.get(url4, axiosConfig),
            axios.get(url5, axiosConfig),
            axios.get(url6, axiosConfig)

        ]).then(axios.spread((paymentResponse, bankResponse, creditResponse, expenseResponse) => {
            this.setState({
                paymentResponse: paymentResponse.data.data,
                bankResponse: bankResponse.data.data,
                creditResponse: creditResponse.data.data,
                expenseResponse: expenseResponse.data
            }, function () {
                var datasss = [];
                if (this.state.paymentResponse.length > 0) {
                    for (let i = 0; i < this.state.paymentResponse.length; i++) {
                        let label = { label: this.state.paymentResponse[i].name };
                        let value = { value: this.state.paymentResponse[i].name };
                        datasss.push(Object.assign(label, value));
                        this.setState({
                            datasss: datasss
                        })
                    }
                } else {
                    this.setState({
                        datasss: datasss
                    })
                }

                localStorage.setItem("expenseaccountcustomers", JSON.stringify(this.state.expenseResponse))

                localStorage.setItem("paidthrough", JSON.stringify(datasss))
                var data_bank = [];
                if (this.state.bankResponse.length > 0) {
                    for (let j = 0; j < this.state.bankResponse.length; j++) {
                        let label = { label: this.state.bankResponse[j].account_name + ` (${this.state.bankResponse[j].bank_name})-` + this.state.bankResponse[j].currency };
                        let value = { value: this.state.bankResponse[j].account_name };
                        data_bank.push(Object.assign(label, value));
                        this.setState({
                            data_bank: data_bank
                        })
                    }
                } else {
                    this.setState({
                        data_bank: data_bank
                    })
                }
                localStorage.setItem("bankaccounts", JSON.stringify(data_bank))
                var data_credit = [];
                if (this.state.creditResponse.length > 0) {
                    for (let j = 0; j < this.state.creditResponse.length; j++) {
                        let label = { label: this.state.creditResponse[j].account_name };
                        let value = { value: this.state.creditResponse[j].account_name };
                        data_credit.push(Object.assign(label, value));
                        this.setState({
                            data_credit: data_credit
                        })
                    }
                } else {
                    this.setState({
                        data_credit: data_credit
                    })
                }
                localStorage.setItem("creditaccounts", JSON.stringify(data_credit))
            })
        })).catch(error => {
            console.log('bayoo', error.response)
        })
    }
    getData = (queryString) => {

        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `payment_received?branch_id=${BRANCH_ID}&&created_by=` + USER_ID + "&&" + queryString

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((paymentResponse) => {

            console.log('bayoo', paymentResponse.data.data)
            this.setState({
                Payment_Received: paymentResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];

                    this.setState({
                        total: paymentResponse.data.total
                    })


                    // retrieve params
                    let params = new URLSearchParams(queryString)
                    let id = parseInt(params.get("page_number"))
                    // console.log(id)
                    // alert(id)
                    // peak view sequent view
                    let p = paymentResponse.data.total + 1;
                    if (id > 1) {
                        p = 0
                    }



                    if (this.state.Payment_Received.length > 0) {

                        for (let i = 0; i < this.state.Payment_Received.length; i++) {

                            p--;

                            let unrow = { row: "R" + p }

                            let unpaid = { unpaid_amount: this.state.Payment_Received[i].amount_inexcess.toString() }
                            let paid;
                            if (this.state.Payment_Received[i].paid_amount != null) {
                                paid = { paid_amountss: formatCurrency(this.state.Payment_Received[i].paid_amount) };
                            }

                            let outstanding;
                            let overpayment;

                            var str = this.state.Payment_Received[i].amount_inexcess;
                            // eslint-disable-next-line
                            str = str.toString().replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                            var num = parseFloat(str);


                            if (num > 0) {

                                overpayment = { overpayment: formatCurrency(Math.abs(num)) };

                                outstanding = { outstanding: formatCurrency(0) };

                            } else {
                                overpayment = { overpayment: formatCurrency(0) };

                                outstanding = { outstanding: formatCurrency(Math.abs(num)) };

                            }


                            let date = { dates: (moment.utc(this.state.Payment_Received[i].payment_date).format('DD MMM, YYYY')) };

                            data.push(Object.assign(date, unrow, outstanding, overpayment, unpaid, paid, this.state.Payment_Received[i]));
                            this.setState({
                                data: data
                            })

                            console.log("bugs", data);
                        }
                    } else {
                        this.setState({
                            data: data
                        })
                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        // this.setState({
        //     showComponent: true,
        //     hideComponent: true,
        //     onEdit: false,
        //     showModal: false,
        //     showModals: false
        // });

        window.location.href = "add-payment"

    }

    onEditInvoice(record) {
        localStorage.setItem("Invoice_No_Item", record.invoice_no)
        this.setState({
            showComponent: false,
            hideComponent: true,
            onEdit: true,
            showModal: false,
            showModals: false
        });
    }


    _onButtonClick2(record) {

        var byteCharacters = atob(record.pdf);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    onDelete(record) {

        // alert(record.id)
        this.setState({ statusMessage: "Success!", isShowError: true, showError: false, isLoading: false }, function () {
            window.setTimeout(() => {
                // this.getData();
                // this.setState({ isShowError: false })
            }, 2000);
        });
        axios.delete(baseURL + `payment_received?id=${record.id}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&role=${ROLE}&&created_by=` + USER_ID + "&id=" + record.id + `&&customer_id=` + record.customer_id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, isShowError: true, showError: false, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 1000);
                });
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 1000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 1000);
        });
    }

    _onShow(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            onEdit: false,
            acceptable_answers: record.acceptable_answers,
            answer: record.answer,
            answer_id: record.id,
        });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent, returnHome } = this.state;
        return (
            <div style={{ marginTop: "-20px" }}  >

                <ToastTable />
                {!hideComponent && (
                    <div>
                        < Card >
                            {returnHome ? "Redirecting please wait..." :
                                <CardBody >
                                    <div className="panel-body" >
                                        <div className="row">

                                            <div className="col-md-6">
                                                <h4><b>List of Received Payments</b></h4><br />
                                            </div>
                                            <div className="col-md-6">
                                                {/* <Link to="add-payment"> */}
                                                <Button className="pull-right btn-sm" color="primary
                                                    " onClick={this._onButtonClick} outline>Add-Payment   <i className="fa fa-plus"></i>
                                                </Button>
                                                {/* </Link> */}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Total Payment Received are now at: <b>{this.state.total}</b></h4>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="col-md-10 offset-1">
                                                    <button className="pull-right" title="refresh" style={{ background: "white", border: "0px" }} onClick={this.getData}>
                                                        <RefreshIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        {this.state.showError ? <div >

                                            <Alert color="danger" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>


                                        </div> : null}

                                        {this.state.isShowError ? (
                                            <div>

                                                <Alert color="success" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>

                                            </div>

                                        ) : null}

                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />
                                        {/* </div> */}

                                    </div>
                                </CardBody >
                            }
                        </Card>
                    </div>
                )
                }
                {
                    showComponent && (
                        // eslint-disable-next-line
                        <Payment_Received />
                    )
                }
                {/* {
                    onEdit && (
                    // eslint-disable-next-line 
                        // <Invoice_Edit />
                    )
                } */}
            </div >
        )
    }
}


// import React from "react";
// import Modal from 'react-modal';
// import "./style.css";

// // import { facebookConfig, googleConfig } from "social-config";

// export class invoice_view extends React.Component {

//     constructor(props) {
//         super(props);
//         // this.toggleModal = this.toggleModal.bind(this);

//         this.state = {
//             isOpen: false,
//             error: null
//         };
//     }

//     toggleModal = e => {
//         this.setState({
//             isOpen: true
//         });
//     };

//     closeModal = e => {
//         this.setState({
//             isOpen: false
//         });
//     };
//     render() {
//         return (
//             <div>
//                 <button >Open Modal</button>
//                 <Modal
//                     isOpen={this.state.isOpen}
//                     onRequestClose={e => {
//                         this.closeModal(e);
//                     }}
//                     contentLabel="My dialog"
//                     className="mymodal"
//                     overlayClassName="myoverlay"
//                     closeTimeoutMS={500}
//                   >
//                     <div>My modal dialog.</div>
//                     <h2 >Hello</h2>
//                       <button onClick={this.closeModal}>close</button>
//                       <div>I am a modal</div>
//                       <form>
//                         <input />
//                         <button>tab navigation</button>
//                         <button>stays</button>
//                         <button>inside</button>
//                         <button>the modal</button>
//                       </form>
//                     <button >Close modal</button>
//                 </Modal>
//             </div>
//         );
//     }
// }