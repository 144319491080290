import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL, baseURL_PDF } from '.';
import { CONFIG, errorToast, successToast } from './exports';
import { useCallback } from 'react';
const PostHookPdf = () => {  // custom hook post request

    const [data, setData] = useState(null);
    const [isLoading, setLoad] = useState(false);

    const callAPI = useCallback((props) => {
        setLoad(true)
        // alert("here")
        if (props && props.url) {
            axios.post(`${baseURL + props.url}`, props.body, CONFIG).then(res => {
                setData(res.data);
                successToast(res.data.message);
                setLoad(false);
            }).catch(err => {
                setData(err);
                setLoad(false)
                errorToast(err.response.data.message);
            });
        } else {
            axios.post(`${baseURL_PDF}payslip`, props).then(res => {
                console.log("ffjfjfjf ", JSON.stringify(res))

                setData(res.data);
                successToast(res.data.message);
                setLoad(false);
            }).catch(err => {
                console.log("ffjfjfjfffjfjfjf ", JSON.stringify(err))

                setData(err);
                setLoad(false)
                errorToast(err.response);
            });
        }
    })

    return [isLoading, data, callAPI];
}
export default PostHookPdf;