import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { CONFIG, CURRENCY, PROFILE_URL, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link, Navigate } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import Manual_Journal from './create_journal';
import { viewJournal } from './pdfJournals';


export class JournalView extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);

        this.columns = [

            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                align: "left"
            },

            {
                key: "status",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.status == 1 ?
                                <div>
                                    <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                        Published
                                    </span>
                                </div>
                                :
                                <div>
                                    <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                        Unpublished
                                    </span>
                                </div>
                            }

                        </Fragment >
                    );
                }
            },
            {
                key: "journal_no",
                TrOnlyClassName: 'tsc',
                text: "Journal#",
                className: "tsc",
                align: "left"
            },
            {
                key: "mamount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-primary  btn-sm"
                                title="View"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick(record) }} >
                                View
                            </button>

                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: true,
                csv: true
            },
            filename: "journal",
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "No journal was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            returnHome: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            showModals: false,
            hideComponent: false
        };
    }



    // submitQuery(record) {

    //     let datas = currency
    //     // let editedInvoice = [];

    //     alert(currency)
    //     axios
    //         .post('http://localhost:8005/currency.php',  currency)
    //         .then(response => {
    //             // localStorage.setItem("JOURNAL_CREATEPEAKD", null)
    //             console.log("bayo", response)
    //             this.setState({
    //                 isLoading: false,
    //             })
    //         })
    //         .catch(error => {
    //             this.setState({
    //                 // statusMessage: error.response === undefined
    //                 //     ? (window.location.href = '/log_in')
    //                 //     : 'Something went wrong',
    //                 showError: true,
    //                 isShowError: false,
    //                 isLoading: false,
    //             });
    //             window.scrollTo(0, 0);
    //             console.error('bayo', error.response);
    //         });
    // }


    async handleOnClick(record) {

        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })


        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        this.setState({
            returnHome: true
        })
        let url = baseURL + `journals_items?filter_value=` + record.journal_no
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((journalResponse) => {

            this.setState({
                lineItems: journalResponse.data.data,
                returnHome: false
            })
            this.state.editedInvoice = {
                journal_no: record.journal_no,
                date: record.date,
                notes: record.notes,
                lineItems: this.state.lineItems,
                amount: record.mamount,
                status: record.status,
                currency: CURRENCY,
            };
            viewJournal(Object.assign(footer, this.state.editedInvoice));

        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    componentDidMount() {
        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = `${PROFILE_URL}?canvas=true`;
        }

        this.getData();
    }


    formatCurrency = amount => {
        return new Intl.NumberFormat(this.locale, {
            style: 'currency',
            currency: "ABS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount).replaceAll('ABS', CURRENCY);
    };


    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `journals?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + "&&" + queryString
        console.log("query", url);
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((journalResponse) => {
            this.setState({
                journal: journalResponse.data.data,
                isLoading: false,
            }, function () {
                var data = [];
                this.setState({
                    total: journalResponse.data.total
                })
                let datas = JSON.parse(localStorage.getItem("JOURNAL_CREATEPEAKD"))
                // let editedInvoice = [];
                var data3 = [];
                if (datas != null) {
                    for (let i = 0; i < datas.length; i++) {
                        let innvoiceNo = { journal_no: journalResponse.data.data[0].journal_no };
                        let client_id = { client_id: USER_ID };
                        data3.push(Object.assign(innvoiceNo, client_id, datas[i]));
                        this.setState({
                            data3: data3,
                        });
                    }
                    axios
                        .post(baseURL_2 + 'journal.php', data3)
                        .then(response => {
                            localStorage.setItem("JOURNAL_CREATEPEAKD", null)
                            console.log("bayo", response)
                            this.setState({
                                isLoading: false,
                            })
                        })
                        .catch(error => {
                            this.setState({
                                statusMessage: error.response === undefined
                                    ? (window.location.href = '/log_in')
                                    : 'Something went wrong',
                                showError: true,
                                isShowError: false,
                                isLoading: false,
                            });
                            window.scrollTo(0, 0);
                            // console.error('Error during service worker registration:', error);
                        });

                    for (let i = 0; i < this.state.journal.length; i++) {

                        let date = { dates: (moment.utc(this.state.journal[i].date).format('DD/MM/YYYY')) };

                        let mamount = { mamount: this.formatCurrency(this.state.journal[i].amount) }

                        data.push(Object.assign(date, mamount, this.state.journal[i]));
                        this.setState({
                            data: data
                        })
                    }
                } else {

                    if (this.state.journal.length > 0) {

                        for (let i = 0; i < this.state.journal.length; i++) {
                            let date = { dates: (moment.utc(this.state.journal[i].date).format('DD/MM/YYYY')) };
                            let mamount = { mamount: this.formatCurrency(this.state.journal[i].amount) }

                            data.push(Object.assign(date, mamount, this.state.journal[i]));
                            this.setState({
                                data: data
                            })
                        }
                    }
                    else {
                        this.setState({
                            data: data,
                            isLoading: false
                        })
                    }
                }

            })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,

        });
    }

    render() {
        const { hideComponent, returnHome, showComponent } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
                    <div>
                        < Card >
                            {returnHome ? "Navigateing please wait..." :
                                <CardBody >
                                    <div className="panel-body" >
                                        <div className="row">

                                            <div className="col-md-6">
                                                <h4><b>List of Journals</b></h4><br />
                                            </div>
                                            <div className="col-md-6">
                                                <Link to="/manual_journal">
                                                    <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>

                                                        Create Journal <i className="fa fa-plus"></i>
                                                    </Button>
                                                </Link>

                                            </div>
                                            <div className="col-12 float-right">
                                                <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}
                                                    onClick={this.getData}>
                                                    <RefreshIcon />
                                                </button>
                                            </div>
                                        </div>
                                        <br />
                                        {this.state.showError ? <div >

                                            <Alert color="danger" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>


                                        </div> : null}

                                        {this.state.isShowError ? (
                                            <div>

                                                <Alert color="success" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>

                                            </div>

                                        ) : null}

                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />

                                    </div>
                                </CardBody>
                            }

                        </Card>
                    </div>
                )
                }
                {
                    showComponent && (
                        <div>
                            <Navigate to="/manual_journal" />
                            <Manual_Journal />
                        </div>
                    )
                }
                {/* 
                {
                    showModal && (
                        <div>
                            <CreateAddress />
                        </div>
                    )
                } */}

                {/* {
                    showModals && (
                        <div>
                            <EditAddress />
                        </div>
                    )
                }
                {
                    showEdit && (
                        <>
                            <CustomerEdit />
                        </div>
                    )
                } */}
            </div >
        )
    }
}