import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { BUSINESS_TYPE, COMPANY_NAME, CONFIG, CURRENCY, INVOICE_STATUS, EMAIL_ADDRESS, INVOICE_APPROVAL, LOADER, NAME, PROFILE_URL, TOKEN, USER_ID, successToast, ToastTable, BRANCH_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import Invoice from './Invoice';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import { saveInvoiceView } from './pdfview';
import Invoice_Edit from './Invoice edit';
import { saveInvoicePDF } from './PDFService';
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";

import Loader from "react-loader-spinner";
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'


import './style.css'
import { saveInvoiceViewr } from './pdfview_r';
import { saveInvoicePDF2 } from './PDFServicer';
import { saveInvoicePDFReminders } from './PDFServicerReminders';
import { saveInvoicePDFRemind } from './PDFServiceRemind';
import { saveDeliveryView } from './pdfviewdelivery';

// var Loader = require('react-loader');

export class InvoiceView extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this.toggleModalCreate = this.toggleModalCreate.bind(this);
        this.applyCallback = this.applyCallback.bind(this);

        this.toggleModalEmail = this.toggleModalEmail.bind(this);
        this.submitsend = this.submitsend.bind(this);
        this.submitcreated = this.submitcreated.bind(this);

        this.getDataUpdate = this.getDataUpdate.bind(this);

        this.toggleModalMore = this.toggleModalMore.bind(this);
        this.submitcreatedclose = this.submitcreatedclose.bind(this);
        this.columns = [
            {
                key: "minvoice_date",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                altrueign: "left"
            },

            {
                key: "invoice_no",
                TrOnlyClassName: 'tsc',
                text: "Invoice#",
                TrOnlyClassName: 'cell',

                className: "cell",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            <span style={{ borderRadius: "5px", color: "blue", padding: "2px" }}>
                                {record.invoice_no}
                            </span>
                        </Fragment >
                    );
                }
            },
            {
                key: "customer_display_name",
                TrOnlyClassName: 'tsc',
                text: "Company Name",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "status",
                TrOnlyClassName: 'tsc',
                text: "Status",
                TrOnlyClassName: 'cell',

                className: "cell",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.status === 0 ?
                                <div>
                                    {record.due_period < 0 ?
                                        <span class="badge-info" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Due in {record.due_period * -1} days
                                        </span>
                                        : null}

                                    {record.due_period > 0 ?
                                        <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Overdue by {record.due_period} days
                                        </span>
                                        : null}
                                    {record.due_period === 0 ?

                                        <span span class="badge-info" style={{ borderRadius: "5px", padding: "2px", }}>
                                            Due Today
                                        </span>
                                        : null}
                                </div>
                                : null}
                            {record.status === 1 ?
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Paid
                                </span>
                                : null}

                            {record.status === 3 ?
                                <span class="badge-" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Sent
                                </span>
                                : null}
                        </Fragment >
                    );
                }
            },
            {
                key: "recurring",
                TrOnlyClassName: 'tsc',
                text: "Recurring",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.recurring == 1 ?
                                <span style={{ borderRadius: "5px", padding: "2px" }}>
                                    YES
                                </span>
                                : null
                                // <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                //     NO
                                // </span>

                            }
                            {record.recurring == 0 ?
                                <span style={{ borderRadius: "5px", padding: "2px" }}>
                                    NO
                                </span>
                                : null

                            }
                        </Fragment >
                    );
                }
            },




            {
                key: "apprvoed",
                TrOnlyClassName: 'tsc',
                text: "Approved",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.approved == 1 ?
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                    YES
                                </span>
                                : null
                                // <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                //     NO
                                // </span>

                            }
                            {record.approved == 0 ?
                                <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                    NO
                                </span>
                                : null

                            }
                            {record.approved == 2 ?
                                <span class="badge-orange" style={{ borderRadius: "5px", padding: "2px" }}>
                                    DRAFT
                                </span>
                                : null

                            }
                        </Fragment >
                    );
                }
            },

            {

                key: "dates1",
                TrOnlyClassName: 'tsc',
                text: "Due Date",
                className: "tsc",
                align: "left"
            },
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date Created",
                className: "tsc",
                altrueign: "left"
            },

            {
                key: "amount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            // {
            //     key: "mbalance",
            //     TrOnlyClassName: 'tsc',
            //     text: "Due Amount",
            //     className: "tsc",
            //     align: "left"
            // },

            // {
            //     key: "lock_reminder",
            //     TrOnlyClassName: 'tsc',
            //     text: "Reminder",
            //     className: "tsc",
            //     align: "left",
            //     cell: record => {
            //         return (
            //             <Fragment className="center"  >
            //                 {record.recurring == 1 ?
            //                     <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
            //                         Sent
            //                     </span>

            //                     : null
            //                     // <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
            //                     //     NO
            //                     // </span>

            //                 }
            //                 {record.recurring == 0 ?
            //                     <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
            //                         Not Sent
            //                     </span>

            //                     : null

            //                 }
            //             </Fragment >
            //         );
            //     }
            // },



            // {
            //     key: "date_modified2",
            //     TrOnlyClassName: 'tsc',
            //     text: "Last Updated",
            //     className: "tsc",
            //     align: "left"
            // },

            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-success  btn-sm"
                                title="View"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.toggleModalMore(record) }} >
                                More <i className="fa fa-plus"></i>
                            </button>

                        </Fragment>
                    );
                }
            }
        ];



        this.columns_ngo = [

            {
                key: "invoice_no",
                TrOnlyClassName: 'tsc',
                text: "Ref#",
                TrOnlyClassName: 'cell',

                className: "cell",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            <span style={{ borderRadius: "5px", color: "blue", padding: "2px" }}>
                                {record.invoice_no}
                            </span>


                        </Fragment >
                    );
                }
            },


            {
                key: "customer_display_name",
                TrOnlyClassName: 'tsc',
                text: "Grantor Name",
                className: "tsc",
                altrueign: "left"
            },

            // {
            //     key: "apprvoed",
            //     TrOnlyClassName: 'tsc',
            //     text: "Approved",
            //     className: "tsc",
            //     align: "left",
            //     cell: record => {
            //         return (
            //             <Fragment className="center"  >
            //                 {record.approved == 1 ?
            //                     <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
            //                         YES
            //                     </span>
            //                     : null
            //                     // <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
            //                     //     NO
            //                     // </span>

            //                 }
            //                 {record.approved == 0 ?
            //                     <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
            //                         NO
            //                     </span>
            //                     : null

            //                 }
            //                 {record.approved == 2 ?
            //                     <span class="badge-orange" style={{ borderRadius: "5px", padding: "2px" }}>
            //                         DRAFT
            //                     </span>
            //                     : null

            //                 }
            //             </Fragment >
            //         );
            //     }
            // },

            // {
            //     key: "amount",
            //     TrOnlyClassName: 'tsc',
            //     text: "Amount",
            //     className: "tsc",
            //     align: "left"
            // },



            // {
            //     key: "date_modified2",
            //     TrOnlyClassName: 'tsc',
            //     text: "Last Updated",
            //     className: "tsc",
            //     align: "left"
            // },

            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-success  btn-sm"
                                title="View"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.toggleModalMore(record) }} >
                                More <i className="fa fa-plus"></i>
                            </button>

                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            key_column: "tsc",
            length_menu: [100, 200, 500],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 100,
            button: {
                excel: false,
                csv: true
            },
            filename: "invoice",
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while invoice data loads...",
                filter: "Invoice no...",
                no_data_text: "There is no invoice for this month",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }


        let now = new Date();

        // new Date().
        let start = moment(new Date(new Date().getFullYear(), 0, 1));
        let end = moment(new Date());

        this.state = {
            admins: [],
            showComponent: false,
            onEdit: false,
            isOpen: false,
            isOpenMore: false,
            items: [],
            // isOpen: true,
            submit_emails: false,
            isOpenemail: false,
            value: "",
            error: null,
            loaded: true,
            returnHome: false,
            start: start,
            end: end,
            isPageLoad: true,
            _onShow: false,
            hideComponent: false,
            data: [],
        };


    }



    rowClickedHandler = (event, data, rowIndex) => {
        this.toggleModalMore(data)
        // alert(JSON.stringify(data))
        // console.log("event", event);
        // console.log("row data", data);
        // console.log("row index", rowIndex);
    }




    // marked as paid
    // paymentReceived = () => {


    //     let mbalance = this.calcGrandTotal();
    //     let invoice_no_new;
    //     if (mbalance > -1) {
    //         invoice_no_new = this.state.invoice_no2 - 1;
    //     } else {
    //         invoice_no_new = "Opening balance";
    //     }
    //     let formData = {
    //         invoice_no: invoice_no_new,
    //         amount_received: mbalance,
    //         payment_date: this.state.invoice_date,
    //         reference: this.state.invoice_message,
    //         notes: this.state.statement_message,
    //         amount_inexcess: 0,
    //         customer_id: this.state.customer_id,
    //         payment_mode: "cash",
    //         deposit_to: this.state.deposit_to,
    //     };

    //     console.log("payment received", formData)

    //     let axiosConfig = {
    //         headers: {
    //             'Content-Type': 'application/json;charset=UTF-8',
    //             "Access-Control-Allow-Origin": "*",
    //             "Authorization": TOKEN
    //         }
    //     };
    //     this.setState({ isSubmitted: true });

    //     axios
    //         .post(baseURL + `payment_received`, formData, axiosConfig)
    //         .then(response => {
    //             if (response.data.status) {
    //                 axios.all([
    //                     axios.get(baseURL + `payment_status?customer_id=` + this.state.customer_id, axiosConfig),
    //                 ]).then(axios.spread((payResponse) => {
    //                     this.setState({
    //                         payResponseResult: payResponse.data.data,
    //                         amount_received_paid: mbalance
    //                     })
    //                     if (payResponse.data.status) {
    //                         let openbalance;
    //                         let openbalance_received;

    //                         if (mbalance > 0) {
    //                             openbalance = 0
    //                             openbalance_received = ((parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess)) - parseFloat(this.state.out_of_balance))
    //                         } else {
    //                             openbalance = (parseFloat(this.state.out_of_balance) - (parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess)))
    //                         }

    //                         let datas = [
    //                             {
    //                                 invoice_no: invoice_no_new, // react-beautiful-dnd unique key
    //                                 due_amount_updated: mbalance,
    //                                 amount_paid: mbalance,
    //                             }
    //                         ]
    //                         let data2 = [];
    //                         for (let i = 0; i < datas.length; i++) {
    //                             let PAYMENT = { payment_id: this.state.payResponseResult[0].id };
    //                             let mclientid = { client_id: USER_ID };
    //                             let mamount_paind = { amount_paid: mbalance };
    //                             let currency_type = { currency_type: this.state.invoice_currency }
    //                             let mpayment_amount;
    //                             mpayment_amount = { payment_amount: mbalance }

    //                             data2.push(Object.assign(PAYMENT, currency_type, mclientid, mamount_paind, mpayment_amount, datas[i]));
    //                             this.setState({
    //                                 data2: data2,
    //                             });
    //                         }

    //                         axios
    //                             .post(baseURL_2 + 'updateiNVOICEPAYMENTStatus.php', data2)
    //                             .then(statusresponse => {
    //                                 // console.log("phpinvoice", statusresponse)
    //                                 var names = data2.filter(person => person.payment_amount != 0).map(function (item) {
    //                                     return item['invoice_no'];
    //                                 });
    //                                 var array = names
    //                                 var array1 = array.toString();
    //                                 var array3 = array1.replace(/,/g, ", ");
    //                                 // alert(array3)
    //                                 let formDataUpdated = {
    //                                     customer_id: this.state.customer_id,
    //                                     payment_id: this.state.payResponseResult[0].id,
    //                                     invoice_no: array3
    //                                 };
    //                                 // alert(JSON.stringify(formDataUpdated))
    //                                 axios.put(baseURL + `payment_status`, formDataUpdated, axiosConfig)
    //                                     .then((statusresponse) => {
    //                                         // alert("alert")
    //                                         if (statusresponse.data.status) {
    //                                             let url_record = baseURL + `invoicepaid_record?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=`
    //                                                 + USER_ID + `&filter_value=` + this.state.payResponseResult[0].id
    //                                             axios.all([
    //                                                 axios.get(url_record, axiosConfig),
    //                                             ]).then(axios.spread((invoiceResponse) => {
    //                                                 console.log("BUGggg", invoiceResponse)
    //                                                 this.setState({
    //                                                     lineItems: invoiceResponse.data.data,
    //                                                     amount_received_paid: mbalance,
    //                                                     returnHome: false
    //                                                 })
    //                                                 let data2 = [];
    //                                                 for (let i = 0; i < invoiceResponse.data.data.length; i++) {
    //                                                     let mpayment_amount;
    //                                                     var str = invoiceResponse.data.data[i].amount;

    //                                                     let minvoice_amount_5 = { mminvoice_amount: parseFloat(num2) + parseFloat(num) }


    //                                                     var stfr = invoiceResponse.data.data[i].due_amount;

    //                                                     stfr = stfr.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
    //                                                     var num3 = parseFloat(stfr);

    //                                                     let mclientid = { client_id: USER_ID };

    //                                                     var str3 = invoiceResponse.data.data[i].due_amount;

    //                                                     str3 = str3.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
    //                                                     var num2 = parseFloat(str3);
    //                                                     // let minvoice_amount = { invoice_amount: num2 }
    //                                                     let minvoice_amount_2 = { due_amount_updated: num3 }
    //                                                     let mamountcompared;
    //                                                     // s = s.replace(/^+/, "");
    //                                                     // var str = 
    //                                                     // eslint-disable-next-line
    //                                                     str = str.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
    //                                                     var num = parseFloat(str);
    //                                                     let minvoice_amount = { mminvoice_amount: parseFloat(num) }

    //                                                     mpayment_amount = { payment_amount: mbalance }
    //                                                     mamountcompared = mbalance;

    //                                                     // if (this.state.amount_received_paid > 0) {
    //                                                     data2.push(Object.assign(mpayment_amount,
    //                                                         mclientid,
    //                                                         minvoice_amount_2,
    //                                                         minvoice_amount_5,
    //                                                         minvoice_amount, invoiceResponse.data.data[i]));
    //                                                     this.setState({
    //                                                         data2: data2,
    //                                                     });
    //                                                     console.log("bugs_report", data2);
    //                                                 }
    //                                                 successToast("success!")
    //                                                 this.setState({
    //                                                     isLoading: false,
    //                                                     loaded: false,
    //                                                 })
    //                                                 // });
    //                                                 window.scrollTo(0, 0)
    //                                                 window.setTimeout(() => {
    //                                                     this.setState({ isShowError: false });
    //                                                     // window.location.reload()
    //                                                     this._onButtonClick();
    //                                                 }, 1000);

    //                                             })).catch(error => {
    //                                                 console.log('bayoo', error.response)

    //                                             })

    //                                         } else {


    //                                             successToast(statusresponse.data.message)
    //                                             this.setState({
    //                                                 isLoading: false,
    //                                                 loaded: false,
    //                                             })
    //                                             // this.setState({
    //                                             //   statusMessage: statusresponse.data.message,
    //                                             //   showError: true, isShowError: false, isSubmitted: false
    //                                             // });
    //                                         }
    //                                     }).catch((error) => {
    //                                         console.log('helloss', JSON.stringify(error))
    //                                         this.setState({
    //                                             isLoading: false,
    //                                             loaded: false,
    //                                         })
    //                                         errorToast(JSON.stringify(error))
    //                                     })
    //                             }).catch(error => {

    //                                 errorToast("error!")
    //                                 this.setState({
    //                                     isLoading: false,
    //                                     loaded: false,
    //                                 })
    //                                 window.scrollTo(0, 0);
    //                                 console.error('CHECK ERROR:', error.response);
    //                             });

    //                     }
    //                 }))
    //             }
    //         })
    // }


    onDelete(record) {

        // alert(record.id)

        // alert(baseURL + `invoice?id=` + record.id + `&filter_value=` + record.invoice_no + `&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID)

        axios.delete(baseURL + `invoice?id=` + record.id + `&filter_value=` + record.invoice_no + `&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {


                axios.delete(baseURL + `recurringinvoice?id=` + record.customer_id, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': TOKEN
                    },
                }).then(response => {
                    console.log("true", response.data)
                    if (response.data.status) {
                        this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                            window.setTimeout(() => {
                                this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))

                                this.setState({ isShowError: false })
                            }, 3000);
                        });
                    } else {
                        this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                        window.setTimeout(() => {
                            this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))

                            this.setState({ isShowError: false })
                        }, 4000);
                    }
                });


                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
                        this.closeModalMore();
                        this.setState({ isShowError: false })
                    }, 3000);
                });
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
                    this.setState({ isShowError: false })
                }, 4000);
            }
        }, error => {
            // alert(JSON.stringify(error))
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
                this.setState({ isShowError: false })
            }, 4000);
        });
    }


    onMarkAsSend(record) {
        // alert(record.id)
        let formData = {
            filter_value: record.id,
            created_by: USER_ID
        }
        axios.put(baseURL + `invoicesent`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {

                // retrieve params
                let params = new URLSearchParams(this.state.queryString)
                let id = parseInt(params.get("page_number"))
                // console.log(id)
                // alert(id)
                this.setState({ statusMessage: response.data.message, isOpenMore: false, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {

                        let queryString = `filter_value=&page_number=${id}&page_size=${parseInt(params.get("page_size"))}&sort_order=false`

                        this.getData(queryString, moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))

                        this.setState({ isShowError: false })
                    }, 3000);
                });
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
                    this.setState({ isShowError: false })
                }, 4000);
            }
        }, error => {
            // alert(JSON.stringify(error))
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
                this.setState({ isShowError: false })
            }, 4000);
        });
    }

    handleKeyDown = evt => {

        // alert(evt.key)
        if (["Enter", " ", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.value.trim();

            if (value && this.isValid(value)) {
                this.setState({
                    items: [...this.state.items, this.state.value],
                    value: ""
                });
            }
        }
    };

    handleChange = evt => {
        this.setState({
            value: evt.target.value,
            error: null
        });
        // alert(evt.target.value)



    };

    handleDelete = item => {
        this.setState({
            items: this.state.items.filter(i => i !== item)
        });
    };

    handlePaste = evt => {
        evt.preventDefault();

        var paste = evt.clipboardData.getData("text");
        var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

        if (emails) {
            var toBeAdded = emails.filter(email => !this.isInList(email));

            this.setState({
                items: [...this.state.items, ...toBeAdded]
            });
        }
    };

    handleEnter = () => {

        var value = this.state.value.trim();

        // alert(value)

        if (value === '') {
            this.setState({
                error: `Atleast one Email is required`
            })
        } else {


            if (value && this.isValid(value)) {
                this.setState({
                    items: [...this.state.items, this.state.value],
                    value: ""
                });
            }
        }
    };

    isValid(email) {
        let error = null;

        // alert(email)

        if (this.isInList(email)) {
            error = `${email} has already been added.`;
        }


        if (!this.isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (error) {
            this.setState({ error });

            return false;
        }

        return true;
    }
    isInList(email) {
        return this.state.items.includes(email);
    }

    isEmail(email) {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    }

    toggleModalCreate(record) {
        this.setState({
            isOpen: true,
            specialitems: record
        });

    };


    toggleModalMore(record) {
        this.setState({
            isOpenMore: true,
            moreopen: record
        });

    };

    toggleModalEmail(record) {
        this.setState({
            isOpenemail: true,
            specialitems: record,
            items: [...this.state.items, record.email, EMAIL_ADDRESS],

        });

    };

    closeModalUpload = e => {
        this.setState({
            isOpen: false,
        });
    };


    closeModalMore = e => {
        this.setState({
            isOpenMore: false,
        });
    };
    closeModalEmail = e => {
        this.setState({
            isOpenemail: false,
            items: []
        });
    };


    async submitsend() {
        // alert(JSON.stringify());
        if (this.state.items.length > 0) {

            let footer;
            const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
                if (response.data.data.length > 0) {
                    footer = { footer: response.data.data[0].footer_notes };
                } else {
                    footer = { footer: "" };
                }
            }).catch(error => {
                footer = { footer: "" };
            })

            let s = this.state.items
            // alert(this.state.items)
            var data_2 = []
            for (var a in s) {
                let name = { email_cc: s[a] };
                data_2.push(Object.assign(name));
            }

            let email_s = JSON.stringify(this.state.items).replaceAll("[", "")
            let email_2 = email_s.replaceAll("]", "")

            let email_3 = email_2.replaceAll('"', "")
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": TOKEN
                }
            };


            // alert(JSON.stringify(data_2))

            this.setState({
                submit_emails: true
            })
            let url = baseURL + `invoiceitem?filter_value=` + this.state.specialitems.invoice_no + `&client_id=` + USER_ID
            axios.all([
                axios.get(url, axiosConfig),
            ]).then(axios.spread((invoiceResponse) => {
                console.log("BUGggg", invoiceResponse)
                this.setState({
                    lineItems: invoiceResponse.data.data,
                    submit_emails: false
                })

                this.state.editedInvoice = {
                    invoice_no: this.state.specialitems.invoice_no,
                    invoice_date: this.state.specialitems.minvoice_date,
                    terms: this.state.specialitems.terms,
                    due_date: this.state.specialitems.dates1,
                    message_invoice: this.state.specialitems.message_invoice,
                    statement_invoice: this.state.specialitems.statement_invoice,
                    customer_name: this.state.specialitems.customer_display_name,
                    customer_email: this.state.specialitems.email,
                    customer_email_address: data_2,

                    // customer_email: this.state.specialitems.email,
                    amount: this.state.specialitems.due_amount,
                    tax_exclusive: this.state.specialitems.tax_exclusive,
                    due_amount: this.state.specialitems.due_amount,
                    lineItems: this.state.lineItems,
                    tax_amount: this.state.specialitems.tax_amount,
                    due_period: this.state.specialitems.due_period,
                    status: this.state.specialitems.status,
                    discount_amount: this.state.specialitems.discount_amount,
                    delivery_amount: this.formatCurrency(this.state.specialitems.delivery_amount),
                    currency: this.state.specialitems.currency_type,
                    invoice_id: this.state.specialitems.id,
                    tax: this.state.specialitems.tax_amount,
                    subtoal: this.state.specialitems.sub_total,
                    total: this.state.specialitems.due_amount,
                };



                this.closeModalEmail();

                if (INVOICE_STATUS === '1') {
                    saveInvoicePDF2(Object.assign(footer, this.state.editedInvoice));
                } else {
                    saveInvoicePDF(Object.assign(footer, this.state.editedInvoice));
                }



                this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
                this.closeModalMore();
                // alert(JSON.stringify(this.state.editedInvoice))

            })).catch(error => {
                console.log('bayoo', error.response)


            })
        } else {
            alert("Type or paste email address/addresses ")
        }

    }


    async submitcreated() {
        // alert(JSON.stringify());
        if (this.state.items.length > 0) {
            if (INVOICE_APPROVAL == 1) {
                let footer;
                const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
                    if (response.data.data.length > 0) {
                        footer = { footer: response.data.data[0].footer_notes };
                    } else {
                        footer = { footer: "" };
                    }
                }).catch(error => {
                    footer = { footer: "" };
                })

                let s = this.state.items
                var data_3 = []
                for (var a in s) {
                    let name = { email_cc: s[a] };
                    // let name_2 = { email: this.state.specialitems.email }
                    data_3.push(Object.assign(name));
                }

                // alert(JSON.stringify(data_3))
                let email_s = JSON.stringify(this.state.items).replaceAll("[", "")
                let email_2 = email_s.replaceAll("]", "")

                let email_3 = email_2.replaceAll('"', "")
                let axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": TOKEN
                    }
                };
                this.setState({
                    submit_emails: true
                })
                let url = baseURL + `invoiceitem?filter_value=` + this.state.specialitems.invoice_no + `&client_id=` + USER_ID
                axios.all([
                    axios.get(url, axiosConfig),
                ]).then(axios.spread((invoiceResponse) => {
                    console.log("BUGgggss", invoiceResponse)
                    this.setState({
                        lineItems: invoiceResponse.data.data,
                        submit_emails: false
                    })


                    //  alert(JSON.stringify())


                    this.state.editedInvoice = {
                        // invoice_no: ,
                        invoice_date: this.state.specialitems.minvoice_date,
                        terms: this.state.specialitems.terms,
                        due_date: this.state.specialitems.dates1,
                        message_invoice: this.state.specialitems.message_invoice,
                        statement_invoice: this.state.specialitems.statement_invoice,
                        customer_name: this.state.specialitems.customer_display_name,
                        customer_primary_email: this.state.specialitems.email,
                        amount: this.state.specialitems.due_amount,
                        tax_exclusive: this.state.specialitems.tax_exclusive,
                        due_amount: this.state.specialitems.due_amount,
                        lineItems: this.state.lineItems,
                        tax_amount: this.state.specialitems.tax_amount,
                        due_period: this.state.specialitems.due_period,
                        status: this.state.specialitems.status,
                        discount_amount: this.state.specialitems.discount_amount,
                        delivery_amount: this.formatCurrency(this.state.specialitems.delivery_amount),
                        currency: this.state.specialitems.currency_type,
                        invoice_id: this.state.specialitems.id,
                        tax: this.state.specialitems.tax_amount,
                        subtoal: this.state.specialitems.sub_total,
                        total: this.state.specialitems.due_amount,
                    };

                    this.closeModalUpload();

                    let datasinvoice = JSON.parse(localStorage.getItem("INVOICE_SENDEMAIL"))

                    let innvoiceNo = { invoice_no: this.state.specialitems[0].invoice_no };
                    let customer_email = { customer_email_2: this.state.specialitems.email };

                    let customer_email_address = { customer_email_address: data_3 };
                    // let innvoiceNo = { invoice_no: invoiceResponse.data.data[0].invoice_no };

                    if (INVOICE_STATUS === '1') {
                        saveInvoicePDF2(Object.assign(footer, innvoiceNo, customer_email_address, datasinvoice));
                    } else {
                        saveInvoicePDF(Object.assign(footer, innvoiceNo, customer_email_address, datasinvoice));
                    }


                    this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))

                    // alert(datasinvoice)


                    // saveInvoicePDF(this.state.editedInvoice);

                    successToast("Successfully")


                })).catch(error => {
                    console.log('bayoo', error.response)

                })

            }
            else {
                alert("You do not have permission to send " + BUSINESS_TYPE === 'business' ? "Invoice" : "Income Receipt");
                this.closeModalUpload();
            }

        } else {
            alert("Type or paste email address/addresses")
        }



        // this.submitcreated()
    }



    submitcreatedclose = async () => {
        // alert(JSON.stringify());


        if (INVOICE_APPROVAL == 1) {


            let footer;
            const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
                if (response.data.data.length > 0) {
                    footer = { footer: response.data.data[0].footer_notes };
                } else {
                    footer = { footer: "" };
                }
            }).catch(error => {
                footer = { footer: "" };
            })

            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": TOKEN
                }
            };
            let url = baseURL + `invoiceitem?filter_value=` + this.state.specialitems.invoice_no + `&client_id=` + USER_ID
            axios.all([
                axios.get(url, axiosConfig),
            ]).then(axios.spread((invoiceResponse) => {
                console.log("BUGggg", invoiceResponse)
                this.setState({
                    lineItems: invoiceResponse.data.data,
                    submit_emails: false
                })

                this.state.editedInvoice = {
                    invoice_date: this.state.specialitems.minvoice_date,
                    terms: this.state.specialitems.terms,
                    due_date: this.state.specialitems.dates1,
                    message_invoice: this.state.specialitems.message_invoice,
                    statement_invoice: this.state.specialitems.statement_invoice,

                    customer_name: this.state.specialitems.customer_display_name,
                    customer_primary_email: this.state.specialitems.email,
                    amount: this.state.specialitems.due_amount,
                    tax_exclusive: this.state.specialitems.tax_exclusive,
                    due_amount: this.state.specialitems.due_amount,
                    lineItems: this.state.lineItems,
                    tax_amount: this.state.specialitems.tax_amount,
                    due_period: this.state.specialitems.due_period,
                    status: this.state.specialitems.status,
                    discount_amount: this.state.specialitems.discount_amount,
                    delivery_amount: this.formatCurrency(this.state.specialitems.delivery_amount),
                    currency: CURRENCY,
                    invoice_id: this.state.specialitems.id,
                    tax: this.state.specialitems.tax_amount,
                    subtoal: this.state.specialitems.sub_total,
                    total: this.state.specialitems.due_amount,
                };


                let datasinvoice = JSON.parse(localStorage.getItem("INVOICE_SENDEMAIL"))

                // alert("Success!!")

                let innvoiceNo = { invoice_no: this.state.specialitems[0].invoice_no };
                let customer_email_address = { customer_email_address: datasinvoice.customer_email };

                // let innvoiceNo = { invoice_no: invoiceResponse.data.data[0].invoice_no };



                if (INVOICE_STATUS === '1') {
                    saveInvoicePDF2(Object.assign(innvoiceNo, footer, customer_email_address, datasinvoice));
                }
                else {
                    saveInvoicePDF(Object.assign(innvoiceNo, footer, customer_email_address, datasinvoice));
                }

                // alert(JSON.stringify(datasinvoice));

                // alert(JSON.stringify(Object.assign(innvoiceNo, customer_email_address, datasinvoice)));
                // saveInvoicePDF(this.state.editedInvoice);

                this.closeModalUpload();



            })).catch(error => {
                console.log('bayoo', error.response)

            })

        }
        else {
            alert("You do not have permission to approve invoices");
            this.closeModalUpload();
        }

        // this.submitcreated()
    }



    async handleOnClick1(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onEditInvoice(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    async handleOnClick2(record) {

        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })


        this.setState({
            items: [...this.state.items, record.email, EMAIL_ADDRESS],
            specialitems: record,
        })


        let s = this.state.items
        // alert(this.state.items)
        var data_2 = []
        for (var a in s) {
            let name = { email_cc: s[a] };
            data_2.push(Object.assign(name));
        }

        let email_s = JSON.stringify(this.state.items).replaceAll("[", "")
        let email_2 = email_s.replaceAll("]", "")

        let email_3 = email_2.replaceAll('"', "")
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        // alert(JSON.stringify(data_2))

        this.setState({
            submit_emails: true
        })
        let url = baseURL + `invoiceitem?filter_value=` + this.state.specialitems.invoice_no + `&client_id=` + USER_ID
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            console.log("BUGggg", invoiceResponse)
            this.setState({
                lineItems: invoiceResponse.data.data,
                submit_emails: false
            })

            this.state.editedInvoice = {
                invoice_no: this.state.specialitems.invoice_no,
                invoice_date: this.state.specialitems.minvoice_date,
                terms: this.state.specialitems.terms,
                due_date: this.state.specialitems.dates1,
                message_invoice: this.state.specialitems.message_invoice,
                statement_invoice: this.state.specialitems.statement_invoice,
                customer_name: this.state.specialitems.customer_display_name,
                customer_email: this.state.specialitems.email,
                customer_email_address: data_2,
                due_amount: this.state.specialitems.due_amount,


                // customer_email: this.state.specialitems.email,
                amount: this.state.specialitems.due_amount,
                tax_exclusive: this.state.specialitems.tax_exclusive,
                due_amount: this.state.specialitems.due_amount,
                lineItems: this.state.lineItems,
                tax_amount: this.state.specialitems.tax_amount,
                due_period: this.state.specialitems.due_period,
                status: this.state.specialitems.status,
                discount_amount: this.state.specialitems.discount_amount,
                delivery_amount: this.formatCurrency(this.state.specialitems.delivery_amount),
                currency: this.state.specialitems.currency_type,
                invoice_id: this.state.specialitems.id,
                tax: this.state.specialitems.tax_amount,
                subtoal: this.state.specialitems.sub_total,
                total: this.state.specialitems.due_amount,
            };



            this.closeModalEmail();

            if (INVOICE_STATUS === '1') {
                saveInvoicePDFReminders(Object.assign(footer, this.state.editedInvoice));
            } else {
                saveInvoicePDFRemind(Object.assign(footer, this.state.editedInvoice));
            }




            let formData = {
                filter_value: record.id,
                created_by: USER_ID,
                client_email: EMAIL_ADDRESS,
                first_name: record.customer_display_name,
                invoice_no: record.invoice_no,
                invoice_date: record.minvoice_date,
                company_name: COMPANY_NAME,
                msisdn: localStorage.getItem("phonemsisdn_msisdn"),
                due_date: record.dates1,
                message_invoice: record.message_invoice,
                statement_invoice: record.statement_invoice,
                customer_name: record.customer_display_name,
                customer_email: record.email,
                amount: record.due_amount,
                tax_exclusive: record.tax_exclusive,
                due_amount: record.due_amount,
                lineItems: this.state.lineItems,
                tax_amount: record.tax_amount,
                due_period: record.due_period,
                status: record.status,
                discount_amount: record.discount_amount,
                delivery_amount: this.formatCurrency(record.delivery_amount),
                currency: record.currency_type,
                tax: record.tax_amount,
                subtoal: record.sub_total,
                total: record.due_amount,
            }
            axios.post(baseURL + `sendReminders`, formData, CONFIG)

            this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))

            successToast("Email reminder sent successfully!")
            this.closeModalMore();
            // alert(JSON.stringify(this.state.editedInvoice))

        })).catch(error => {
            console.log('bayoo', error.response)


        })


    }

    formatCurrency = amount => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: CURRENCY,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    };



    async handleOnClickDelivery(record) {
        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })


        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        this.setState({
            returnHome: true
        })
        let url = baseURL + `invoiceitem?filter_value=` + record.invoice_no + `&client_id=` + USER_ID

        // alert(url)
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            console.log("invoice items record ", invoiceResponse.data.data)
            this.setState({
                lineItems: invoiceResponse.data.data,
                returnHome: false
            })
            this.state.editedInvoice = {
                invoice_no: record.invoice_no,
                invoice_date: record.minvoice_date,
                terms: record.terms,
                due_date: record.dates1,
                message_invoice: record.message_invoice,
                statement_invoice: record.statement_invoice,
                customer_name: record.customer_display_name,
                customer_email: record.email,
                amount: record.due_amount,
                tax_exclusive: record.tax_exclusive,
                due_amount: record.due_amount,
                lineItems: this.state.lineItems,
                tax_amount: record.tax_amount,
                due_period: record.due_period,
                status: record.status,
                discount_amount: record.discount_amount,
                delivery_amount: this.formatCurrency(record.delivery_amount),
                currency: record.currency_type,
                tax: record.tax_amount,
                subtoal: record.sub_total,
                total: record.due_amount,
            };

            // alert(JSON.stringify(footer, this.state.editedInvoice))
            saveDeliveryView(Object.assign(footer, this.state.editedInvoice));


        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    async handleOnClick(record) {
        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })


        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        this.setState({
            returnHome: true
        })
        let url = baseURL + `invoiceitem?filter_value=` + record.invoice_no + `&client_id=` + USER_ID

        // alert(url)
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            console.log("invoice items record ", invoiceResponse.data.data)
            this.setState({
                lineItems: invoiceResponse.data.data,
                returnHome: false
            })
            this.state.editedInvoice = {
                invoice_no: record.invoice_no,
                invoice_date: record.minvoice_date,
                terms: record.terms,
                due_date: record.dates1,
                message_invoice: record.message_invoice,
                statement_invoice: record.statement_invoice,
                customer_name: record.customer_display_name,
                customer_email: record.email,
                amount: record.due_amount,
                tax_exclusive: record.tax_exclusive,
                due_amount: record.due_amount,
                lineItems: this.state.lineItems,
                tax_amount: record.tax_amount,
                due_period: record.due_period,
                status: record.status,
                discount_amount: record.discount_amount,
                delivery_amount: this.formatCurrency(record.delivery_amount),
                currency: record.currency_type,
                tax: record.tax_amount,
                subtoal: record.sub_total,
                total: record.due_amount,
            };

            if (INVOICE_STATUS === '1') {
                saveInvoiceViewr(Object.assign(footer, this.state.editedInvoice));
            } else {
                saveInvoiceView(Object.assign(footer, this.state.editedInvoice));
            }


        })).catch(error => {
            console.log('bayoo', error)

        })
    }
    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };




    componentDidMount() {
        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = `${PROFILE_URL}?canvas=true`;
        }


        this.setState({
            startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
            endDate: moment(new Date()).format('YYYY-MM-DD'),
            isPageLoad: true,
            valuedate: "This Year"
        })

        // var d = new Date();

        // alert(d)

        this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))

    }



    getData = (queryString = "", startDate, endDate) => {

        this.setState({
            isLoading: true
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };

        // let endDate = moment(new Date()).format('YYYY-MM-DD')
        let url = baseURL + `invoice?branch_id=${BRANCH_ID}&&sales_order_no=0&&estimate=0&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"&${queryString}`


        //  alert(url)
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            this.setState({
                invoice: invoiceResponse.data.data,
            },
                function () {
                    var data = [];

                    this.setState({
                        total: invoiceResponse.data.total
                    })

                    let datas = JSON.parse(localStorage.getItem("INVOICE_CREATEPEAK"))


                    console.log("ffjjjf ", datas)
                    // alert(JSON.stringify(data3));

                    // alert(JSON.stringify(localStorage.getItem("INVOICE_CREATEPEAK")))
                    // let editedInvoice = [];

                    var data3 = [];


                    if (datas != null) {

                        console.log("FIRST DATA SET")

                        if (BUSINESS_TYPE === 'business') {

                            if (localStorage.getItem("approved_draft") == 1) {

                                this.setState({
                                    isOpen: true
                                })
                            } else {
                                this.setState({
                                    isOpen: false
                                })
                            }
                        }
                        let innvoiceNo = { invoice_no: invoiceResponse.data.data[0].invoice_no };
                        for (let i = 0; i < datas.length; i++) {
                            let innvoiceNo = { invoice_no: invoiceResponse.data.data[0].invoice_no };
                            let client_id = { client_id: USER_ID };
                            data3.push(Object.assign(innvoiceNo, client_id, datas[i]));
                            this.setState({
                                data3: data3,
                                items: [...this.state.items, invoiceResponse.data.data[0].email, EMAIL_ADDRESS],
                                // isOpen: true,
                                specialitems: data3,
                                isLoading: false,
                                loaded: false,
                            });

                            localStorage.setItem("INVOICE_CREATEPEAK", null)
                        }
                        // axios
                        //     .post(baseURL_2 + 'insight.php', data3)
                        //     .then(response => {
                        //         console.log("bayo", response)
                        //         this.setState({
                        //             isLoading: false,
                        //             loaded: false,
                        //         })
                        //     })
                        //     .catch(error => {
                        //         this.setState({
                        //             statusMessage: error.response === undefined
                        //                 ? (window.location.href = '/log_in')
                        //                 : 'Something went wrong',
                        //             showError: true,
                        //             isShowError: false,
                        //             isLoading: false,
                        //             loaded: false,
                        //         });
                        //         window.scrollTo(0, 0);
                        //         // console.error('Error during service worker registration:', error);
                        //     });



                        for (let i = 0; i < this.state.invoice.length; i++) {
                            let due_date = moment(this.state.invoice[i].due_date);
                            let cur_date = moment();
                            // let curDateFormated = cur_date.format('DD MMM, YYYY');
                            let dueDateFormated = due_date.format('DD/MM/YYYY');
                            const diff = cur_date.diff(due_date, 'days');
                            let mperiod = { due_period: this.state.invoice[i].period }
                            let date1 = { dates1: dueDateFormated };

                            let mstatus = this.state.invoice[i].status

                            let mstatus_2;

                            if (mstatus == 0 || mstatus == 3) {
                                mstatus_2 = { mbalance: this.state.invoice[i].due_amount }

                            } else {
                                mstatus_2 = { mbalance: this.state.invoice[i].currency_type + " 0.00" }
                            }

                            let date = { dates: (moment.utc(this.state.invoice[i].created_at).format('DD/MM/YYYY')) };
                            let date_modified = { date_modified2: (moment(this.state.invoice[i].date_modified).format('DD/MM/YYYY HH:mm')) };
                            let invoice_date = { minvoice_date: (moment.utc(this.state.invoice[i].invoice_date).format('DD/MM/YYYY')) };

                            data.push(Object.assign(date1, invoice_date, mstatus_2, date_modified, date, mperiod, this.state.invoice[i]));
                            this.setState({
                                data: data,
                                isLoading: false,
                                loaded: false
                            })
                        }
                    } else {

                        console.log("SECOND DATA SET")


                        if (this.state.invoice.length > 0) {

                            for (let i = 0; i < this.state.invoice.length; i++) {
                                let due_date = moment(this.state.invoice[i].due_date);
                                let cur_date = moment.utc();
                                // let curDateFormated = cur_date.format('DD MMM, YYYY');
                                let dueDateFormated = due_date.format('DD/MM/YYYY');
                                const diff = cur_date.diff(due_date, 'days');
                                // let mperiod = { due_period: diff }
                                let mperiod = { due_period: this.state.invoice[i].period }
                                let mstatus = this.state.invoice[i].status

                                let mstatus_2;
                                if (mstatus == 0) {
                                    mstatus_2 = { mbalance: this.state.invoice[i].due_amount }

                                } else {
                                    mstatus_2 = { mbalance: this.state.invoice[i].currency_type + " 0.00" }
                                }

                                let date1 = { dates1: dueDateFormated };
                                let date_modified = { date_modified2: (moment(this.state.invoice[i].date_modified).format('DD/MM/YYYY HH:mm')) };

                                let invoice_date = { minvoice_date: (moment.utc(this.state.invoice[i].invoice_date).format('DD/MM/YYYY')) };

                                let date = { dates: (moment.utc(this.state.invoice[i].created_at).format('DD/MM/YYYY')) };
                                data.push(Object.assign(date1, invoice_date, date, mstatus_2, date_modified, mperiod, this.state.invoice[i]));
                                this.setState({
                                    data: data,
                                    isLoading: false,
                                    loaded: false,
                                })
                            }

                            this.setState({
                                data: data,
                                isLoading: false,
                                loaded: false,
                            })

                        }
                        else {
                            this.setState({
                                data: data,
                                loaded: false,
                                isLoading: false
                            })
                        }
                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        // alert(localStorage.getItem("STARTDATE"))

        this.setState({ queryString: queryString })
        this.getData(queryString, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).add(1, 'day').format('YYYY-MM-DD'))

    }
    getDataUpdate() {
        let queryString = ("filter_value=&page_number=0&page_size=10&sort_order=false")

        this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD').add(1, 'day'), moment(new Date()).format('YYYY-MM-DD'))
    }


    applyCallback(startDate, endDate) {
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)
        let Sdate1 = moment(startDate).format('DD MMM, YYYY');
        let Edate2 = moment(endDate).format('DD MMM, YYYY');
        this.setState({
            valuedate: Sdate1 + " " + Edate2,
            startDate: Sdate1,
            isPageLoad: true,
            endDate: Edate2
        })

        this.getData("", moment(startDate).format('YYYY-MM-DD'), moment(endDate).add(1, 'day').format('YYYY-MM-DD'));
    }




    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            onEdit: false,
            showModal: false,
            showModals: false
        });
    }

    onEditInvoice(record) {
        localStorage.setItem("Invoice_No_Item", record.invoice_no)
        this.setState({
            showComponent: false,
            hideComponent: true,
            onEdit: true,
            showModal: false,
            showModals: false
        });
    }


    _onButtonClick2(record) {

        var byteCharacters = atob(record.pdf);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    // onDelete(record) {

    //     console.log("Delete Record", record);
    //     axios.delete(baseURL + `question/` + record.question_id, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json',
    //             'Authorization': 'Bearer ' + TOKEN
    //         },
    //     }).then(response => {
    //         console.log("true", response.data)
    //         if (response.data.status) {
    //             this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
    //                 window.setTimeout(() => {
    //                     this.getData();
    //                     this.setState({ isShowError: false })
    //                 }, 2000);
    //             });
    //         } else {
    //             this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: true, isLoading: false });
    //             window.setTimeout(() => {
    //                 this.getData();
    //                 this.setState({ isShowError: false })
    //             }, 2000);
    //         }
    //     }, error => {
    //         this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
    //         window.setTimeout(() => {
    //             this.getData();
    //             this.setState({ isShowError: false })
    //         }, 2000);
    //     });
    // }

    _onShow(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            onEdit: false,
            acceptable_answers: record.acceptable_answers,
            answer: record.answer,
            answer_id: record.id,
        });
    }

    render() {


        const { showComponent, onEdit } = this.state;
        // alert(this.state.items);
        const { hideComponent, returnHome } = this.state;


        let ranges = {
            "Today Only": [moment(this.state.start), moment(this.state.end)],
            "Yesterday Only": [
                moment(this.state.start).subtract(1, "days"),
                moment(this.state.end).subtract(1, "days")
            ],
            "3 Days": [moment(this.state.start).subtract(3, "days"), moment(this.state.end)],
            "5 Days": [moment(this.state.start).subtract(5, "days"), moment(this.state.end)],
            "1 Week": [moment(this.state.start).subtract(7, "days"), moment(this.state.end)],
            "2 Weeks": [moment(this.state.start).subtract(14, "days"), moment(this.state.end)],
            "1 Month": [moment(this.state.start).subtract(1, "months"), moment(this.state.end)],
            "1 Year": [moment(this.state.start).subtract(1, "years"), moment(this.state.end)]
        };
        let local = {
            "format": "DD-MM-YYYY",
            "sundayFirst": false
        }

        return (
            this.state.loaded == false ?

                <div style={{ marginTop: "-20px" }} >

                    {ToastTable()}
                    <Modal
                        isOpen={this.state.isOpenMore}
                        onRequestClose={e => this.closeModalMore(e)}
                        contentLabel="My dialog"
                        className="mymodal"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        <MDBCloseIcon onClick={this.closeModalMore} />
                        <h4><b>{BUSINESS_TYPE === 'business' ? "Invoice" : "Reference"}# {this.state.moreopen == undefined ? null : this.state.moreopen.invoice_no}</b></h4><br />

                        {this.state.showError ? <div >

                            <Alert color="danger" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>
                        </div> : null}
                        {this.state.isShowError ? (
                            <div>

                                <Alert color="success" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>
                            </div>
                        ) : null}

                        <div>
                            {/* Type or paste email addresses and press `Add`/`Enter` to add more <br /> */}
                            {BUSINESS_TYPE === 'business' ?
                                <button className="btn btn-primary  btn-sm"
                                    title="View "
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.onMarkAsSend(this.state.moreopen) }} >
                                    Mark as sent
                                </button>
                                : null}
                            <br />
                            <br />

                            {/* {BUSINESS_TYPE === 'business' ?
                                <button className="btn btn-primary  btn-sm"
                                    title="View "
                                    style={
                                        { marginRight: '10px' }}

                                    onClick={
                                        () => { this.onMarkAsSend(this.state.moreopen) }}
                                >
                                    Mark as paid
                                </button>
                                : null}
                            <br />
                            <br /> */}


                            <button className="btn btn-primary  btn-sm"
                                title="View "
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick(this.state.moreopen) }} >
                                View {BUSINESS_TYPE === 'business' ? "Invoice" : "Income Receipt"}
                            </button>
                            <br />
                            <br />

                            {BUSINESS_TYPE === 'business' ?
                                <button className="btn btn-primary  btn-sm"
                                    title="View "
                                    style={
                                        { marginRight: '10px' }}

                                    onClick={
                                        () => { this.handleOnClickDelivery(this.state.moreopen) }}
                                >
                                    Delivery Note
                                </button>
                                : null}
                            <br />
                            <br />


                            {INVOICE_APPROVAL == 1 ?
                                <button className="btn btn-primary  btn-sm"
                                    title="View"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.toggleModalEmail(this.state.moreopen) }} >
                                    Send Emails
                                </button>
                                : null}
                            <br />
                            <br />
                            {BUSINESS_TYPE === 'business' ?

                                <button className="btn btn-primary  btn-sm"
                                    title="View "
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleOnClick2(this.state.moreopen) }} >
                                    Send Reminders
                                </button>
                                : null
                            }
                            <br />
                            <br />

                            <Link to="/invoice-edit">
                                <button className="btn btn-primary btn-sm"
                                    title="Edit Invoice"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.onEditInvoice(this.state.moreopen) }} >
                                    Edit {BUSINESS_TYPE === 'business' ? "Invoice" : "Income Receipt"}
                                </button>
                            </Link>
                            <br />
                            <br />
                            <button className="btn btn-danger  btn-sm"
                                title="Delete"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => {
                                        if (window.confirm(`Are you sure you want to delete this ${BUSINESS_TYPE === 'business' ? "Invoice" : "Income Receipt"}#` +
                                            this.state.moreopen.invoice_no + "?")) this.onDelete(this.state.moreopen)
                                    }
                                } >
                                Delete {BUSINESS_TYPE === 'business' ? "Invoice" : "Income Receipt"}
                            </button>

                        </div>


                    </Modal>


                    <Modal
                        isOpen={this.state.isOpen}
                        onRequestClose={false}
                        contentLabel="My dialog"
                        className="mymodal"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        <MDBCloseIcon onClick={this.submitcreatedclose} />
                        <h4><b>CC/BCC Email Addresses</b></h4><br />
                        {this.state.showError ? <div >
                            <Alert color="danger" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>


                        </div> : null}

                        {this.state.isShowError ? (
                            <div>

                                <Alert color="success" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>
                            </div>
                        ) : null}

                        <div>
                            Type or paste email addresses and press `Add`/`Enter` to add more <br />
                            {this.state.items.map(item => (
                                <div className="tag-item" key={item}>
                                    {item}

                                    <button
                                        type="button"
                                        className="button"
                                        onClick={() => this.handleDelete(item)}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                            <input
                                className={"input " + (this.state.error && " has-error")}
                                value={this.state.value}
                                placeholder="Type or paste email addresses and press `Enter`..."
                                onKeyDown={this.handleKeyDown}
                                onChange={this.handleChange}
                                onPaste={this.handlePaste}
                            />
                            {this.state.error && <p className="error">{this.state.error}</p>}
                            <br />
                            <div className="float-right" style={{ marginTop: '10px' }}>
                                <button className="btn btn-success  btn-sm"
                                    title="View"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleEnter() }}
                                >
                                    Add <i className="fa fa-plus"></i>
                                </button>
                            </div>
                            <br /><br />

                            <div className="row" style={{ marginTop: '20px' }}>

                                <div className="col-6  ">
                                    <button className="btn btn-danger  btn-sm"
                                        title="View"
                                        style={
                                            { marginRight: '10px' }}
                                        onClick={
                                            () => { this.submitcreatedclose() }}

                                    >
                                        Close
                                    </button>
                                </div>

                                <div className="col-6">
                                    <div className="float-right" >

                                        <button className="btn btn-primary  btn-sm"
                                            title="View"
                                            onClick={
                                                () => { this.submitcreated() }}

                                        >
                                            {this.state.submit_emails ? "Please Wait..." : "Send Email"}
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </Modal>


                    <Modal
                        isOpen={this.state.isOpenemail}
                        onRequestClose={e => this.closeModalEmail(e)}
                        contentLabel="My dialog"
                        className="mymodal"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        <MDBCloseIcon onClick={this.closeModalEmail} />
                        <h4><b>Add CCC/BCC</b></h4><br />

                        {this.state.showError ? <div >

                            <Alert color="danger" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>


                        </div> : null}

                        {this.state.isShowError ? (
                            <div>

                                <Alert color="success" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>
                            </div>
                        ) : null}

                        <div>
                            Type or paste email addresses and press `Add`/`Enter` to add more <br />


                            {

                                this.state.items.map(item => (
                                    <div className="tag-item" key={item}>
                                        {item}
                                        <button
                                            type="button"
                                            className="button"
                                            onClick={() => this.handleDelete(item)}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}




                            <input
                                className={"input " + (this.state.error && " has-error")}
                                value={this.state.value}
                                placeholder="Type or paste email addresses and press `Enter`..."
                                onKeyDown={this.handleKeyDown}
                                onChange={this.handleChange}
                                onPaste={this.handlePaste}
                            />


                            {this.state.error && <p className="error">{this.state.error}</p>}

                            <br />

                            <div className="float-right" style={{ marginTop: '10px' }}>
                                <button className="btn btn-success  btn-sm"
                                    title="View"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleEnter() }}
                                >
                                    Add <i className="fa fa-plus"></i>
                                </button>
                            </div>
                            <br /><br /><br />


                            <div className="col-6 offset-3">
                                <button className="btn btn-primary  btn-sm"
                                    title="View"
                                    onClick={
                                        () => { this.submitsend() }}
                                >
                                    {this.state.submit_emails ? "Please Wait..." : BUSINESS_TYPE === 'business' ? "Send Invoice" : "Send    Receipt"}
                                </button>
                            </div>
                        </div>


                    </Modal>

                    {!hideComponent && (
                        <div>
                            < Card >
                                {returnHome ? "Redirecting please wait..." :

                                    <CardBody >
                                        <div className="panel-body" >
                                            <div className="row">

                                                <div className="col-md-6">
                                                    <h4><b>List of {BUSINESS_TYPE === 'business' ? "Invoice" : "Income Receipt"}</b></h4><br />
                                                </div>
                                                <div className="col-md-6">
                                                    <Link to="/create-invoice">
                                                        <Button className="pull- btn-sm" color="primary
                                                    " onClick={this._onButtonClick} outline>Create {BUSINESS_TYPE === 'business' ? "Invoice" : "Income Receipt"}   <i className="fa fa-plus"></i>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className="col-md-8">
                                                        <div className="row">
                                                            <div className="col-6">

                                                                <DateTimeRangeContainer
                                                                    ranges={ranges}
                                                                    start={moment(new Date(new Date().getFullYear(), 0, 1))}
                                                                    end={this.state.end}
                                                                    local={local}
                                                                    applyCallback={this.applyCallback}>
                                                                    <FormControl
                                                                        id="formControlsTextB"
                                                                        type="text"
                                                                        value={this.state.valuedate}
                                                                        label="Text"
                                                                        placeholder="Filter by Date"
                                                                    />
                                                                </DateTimeRangeContainer>
                                                            </div>

                                                            <div className="col-6 float-left">

                                                                <button className=" float-left btn btn-primary btn-sm" onClick={e => {
                                                                    // this.getData();
                                                                    this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))

                                                                    this.setState({
                                                                        startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('DD MMM, YYYY'),
                                                                        endDate: moment(new Date()).format('DD MMM, YYYY'),
                                                                        isPageLoad: true,
                                                                        valuedate: "This year"
                                                                    })
                                                                }} >Clear</button>
                                                                {/* <input id="input" type="text" readOnly /> */}
                                                            </div>
                                                        </div>
                                                    </div><br />
                                                </div>
                                                <div className="col-md-6">
                                                    <h4>Total {BUSINESS_TYPE === 'business' ? "Invoices" : "Income Receipts"} are: <b>{this.state.total}</b></h4>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="col-md-10 offset-1">
                                                        <button className="pull-right" title="refresh"
                                                            style={{ background: "white", border: "0px" }} onClick={this.getDataUpdate}>
                                                            <RefreshIcon />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            {this.state.showError ? <div >

                                                <Alert color="danger" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>


                                            </div> : null}

                                            {this.state.isShowError ? (
                                                <div>

                                                    <Alert color="success" className="alert--colored" icon>
                                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                                        </p>
                                                    </Alert>

                                                </div>

                                            ) : null}

                                            <ReactDatatable
                                                config={this.config}
                                                records={this.state.data}
                                                columns={BUSINESS_TYPE === 'business' ? this.columns : this.columns_ngo}
                                                dynamic={true}
                                                id="tsc"
                                                loading={this.state.isLoading}
                                                total_record={this.state.total}
                                                onRowClicked={this.rowClickedHandler}
                                                onChange={this.tableChangeHandler} />

                                        </div>
                                    </CardBody>
                                }
                            </Card>
                        </div>
                    )
                    }
                    {
                        showComponent && (
                            <div>
                                <Invoice />
                            </div>
                        )
                    }
                    {
                        onEdit && (
                            // eslint-disable-next-line 
                            <Invoice_Edit />
                        )
                    }

                </div >

                :
                <div className="loader">
                    {LOADER}


                </div>
        )
    }

}


// import React from "react";
// import Modal from 'react-modal';
// import "./style.css";

// // import { facebookConfig, googleConfig } from "social-config";

// export class invoice_view extends React.Component {

//     constructor(props) {
//         super(props);
//         // this.toggleModal = this.toggleModal.bind(this);

//         this.state = {
//             isOpen: false,
//             error: null
//         };
//     }

//     toggleModal = e => {
//         this.setState({
//             isOpen: true
//         });
//     };

//     closeModal = e => {
//         this.setState({
//             isOpen: false
//         });
//     };
//     render() {
//         return (
//             <div>
//                 <button >Open Modal</button>
//                 <Modal
//                     isOpen={this.state.isOpen}
//                     onRequestClose={e => {
//                         this.closeModal(e);
//                     }}
//                     contentLabel="My dialog"
//                     className="mymodal"
//                     overlayClassName="myoverlay"
//                     closeTimeoutMS={500}
//                   >
//                     <div>My modal dialog.</div>
//                     <h2 >Hello</h2>
//                       <button onClick={this.closeModal}>close</button>
//                       <div>I am a modal</div>
//                       <form>
//                         <input />
//                         <button>tab navigation</button>
//                         <button>stays</button>
//                         <button>inside</button>
//                         <button>the modal</button>
//                       </form>
//                     <button >Close modal</button>
//                 </Modal>
//             </div>
//         );
//     }
// }