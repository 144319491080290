import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../../../Layout/index";
import DefaultDash from "../../../Dashboards/Default/index";
import CustomerCreate from "../../../Customers/createCustomer";
import CreateAddress from "../../../Address/createCustomerAddress";

import AddUser from "../../../Users/addUser";
import { Users } from "../../../Users/Users";
import Invoice from "../../../Invoice_Estimates/Invoice";
import Estimates from "../../../Invoice_Estimates/estimates";
import { Customers } from "../../../Customers/customer_view";
import CustomerEdit from "../../../Customers/customer_edit";
import EditAddress from "../../../Address/editcustomer";
import { InvoiceView } from "../../../Invoice_Estimates/invoice_view";
import { Estimate_View } from "../../../Invoice_Estimates/estimate_view";

import ItemCreate from "../../../Items/createItem";
import { ItemView } from "../../../Items/item_view";
import Invoice_Edit from "../../../Invoice_Estimates/Invoice edit";
import Payment_Received from "../../../Invoice_Estimates/payment_received";
import Payment_Received_Edit from "../../../Invoice_Estimates/payment_receivedEdit";

import { ViewPayments } from "../../../Invoice_Estimates/paymwent_receive_view";
import ESTIMATE_EDIT from "../../../Invoice_Estimates/estimate_edit";
import Recurring_Invoice from "../../../Invoice_Estimates/recurring_invoice";
import RecurringView from "../../../Invoice_Estimates/recurring_invoiceview";
import CreditNote from "../../../Invoice_Estimates/credit_note";
import { Credit_note_view } from "../../../Invoice_Estimates/credit_note_view";
import Edit_Credit from "../../../Invoice_Estimates/edit_credit";
import VendorCreate from "../../../Vendors/createVendors";
import { Vendors } from "../../../Vendors/vendor_view";
import CreateExpense from "../../../Expenses/createExpense";
import Recurring_expenses from "../../../Expenses/recurring_expenses";
import { Expenses_View } from "../../../Expenses/expense_view";
import { Recurring_viewexpense } from "../../../Expenses/recurring_expensesview";
import Bills from "../../../Invoice_Estimates/bills";
import { Bill_view } from "../../../Invoice_Estimates/bill_view";
import Bill_Recurring from "../../../Invoice_Estimates/bill_recurring";
import { Billrecurring_view } from "../../../Invoice_Estimates/bill_recurring_view";
import Bill_Payment from "../../../Invoice_Estimates/bill_payment";
import { ViewPaymentsBills } from "../../../Invoice_Estimates/paymwent_receive_viewbill";
import CreditNoteBill from "../../../Invoice_Estimates/credit_bills";
import { Credit_note_bill } from "../../../Invoice_Estimates/credit_note_bill";
import { Reports } from "../../../Reports/reports";
import { ProfitLoss } from "../../../Reports/profitloss";
import { NetCashFlow } from "../../../Reports/netcashflow";
import { BalanceSheet } from "../../../Reports/balancesheet";
import { PaymentReceivedReport } from "../../../Reports/payment_received";
import { CreditNoteDetails } from "../../../Reports/credit_note_details";
import { CustomerBySales } from "../../../Reports/customer_by_sales";
import { ItemBySales } from "../../../Reports/sales_by_items";
import { CustomerBalance } from "../../../Reports/customerbalance";
import { AgingSummary } from "../../../Reports/agingsummary";
import { InvoiceDetails } from "../../../Reports/invoicedetails";
import { EstimateDetails } from "../../../Reports/estimatedetails";
import { Receivables } from "../../../Reports/receivables";
import { VendorBalance } from "../../../Reports/vendorbalance";
import { PaymentMadeReport } from "../../../Reports/paymentmade";
import { Payables } from "../../../Reports/payables";
import { PurchaseVendor } from "../../../Reports/purchasebyvendor";
import { ItemByPurchase } from "../../../Reports/purchasebyitem";
import Createinvestment from "../../../investing_activities/createinvestment";
import { InvestmentView } from "../../../investing_activities/investment_view";
import { SalesByPerson } from "../../../Reports/salesbysalesperson";
import { BusinessPerformanceRatio } from "../../../Reports/businessperformanceratio";
import { Credit_Received } from "../../../Invoice_Estimates/credit_noteapply";
import Payments from "../../../Account/Register/payment";
import { Credit_ReceivedBills } from "../../../Invoice_Estimates/creditnotepaybill";
// import roles from '../../../Groups/roles';
import Edit_CreditVendor from "../../../Invoice_Estimates/edit_creditvendor";
import CreatBank from "../../../bank/createBankAccount";
import { Banking } from "../../../bank/banking";
import { Settings } from "../../../Account/Settings/settings";
import AddSalesPerson from "../../../Account/Settings/AddSalesPerson";
import { SalesPersonView } from "../../../Account/Settings/SalesPerson";
import { TaxView } from "../../../Account/Settings/Tax";
import AddTax from "../../../Account/Settings/AddTax";
import { Clients } from "../../../Users/clients";
import Adduserclient from "../../../Users/adduserclient";
import { ClientsUsers } from "../../../Users/clientusers";
import { UsersArchived } from "../../../Users/UsersArchived";
import EditClientUser from "../../../Users/editclient";
import { Documents } from "../../../Documents/documents";
import { Transactions } from "../../../Users/transactions";
import Manual_Journal from "../../../Invoice_Estimates/create_journal";
import { JournalView } from "../../../Invoice_Estimates/journal_view";
import { AuditUser } from "../../../Users/audituser";
import FileUploadDefault from "../../../CSV/components/FileUploadDefault";
import { AgingSummaryBills } from "../../../Reports/agingsummarybills";
import EditTax from "../../../Account/Settings/EditTax";
import { CustomerStatement } from "../../../Customers/CustomerStatement";
import EditExpense from "../../../Expenses/editExpense";
import SalesOrder from "../../../Invoice_Estimates/salesorders";
import { Sales_OrdersView } from "../../../Invoice_Estimates/salesorderview";
import { CurrencyView } from "../../../Account/Settings/Currencies";
import InvoiceNotes from "../../../Account/Settings/invoiceNotes.jsx";
import { ExpenseVendor } from "../../../Reports/ExpenseVendor.jsx";
import { BankDetails } from "../../../bank/bankDetails.jsx";
import { ExpenseCustomer } from "../../../Reports/ExpenseCustomer";
import SMSLogs from "../../../SMSLogs/SMSlogs";
import ReceiptNotes from "../../../Account/Settings/receiptNotes";
import FooterNotes from "../../../Account/Settings/footerNotes";

import { VendorStatement } from "../../../Vendors/VendorStatement";
import UserGuide from "../../../Account/Settings/user_guide";
import { Budget } from "../../../Reports/budget";
import AddBudget from "../../../Account/Settings/AddBudget";
import ExtendPayments from "../../../Account/Register/extendPayments";
import CreateEmployee from "../../../Employee/createEmployee";
import EditEmployee from "../../../Employee/editEmployee";

import { Employees } from "../../../Employee/employee_view";
import AddStaff from "../../../Employee/addStaff";
import { StaffExpenses } from "../../../Employee/staff_expense";
import EditStaff from "../../../Employee/editStaff";
import { Departments } from "../../../Employee/departments";
import CreateDepartment from "../../../Employee/createDepartment";
import EditDepartment from "../../../Employee/editDepartment";
import { JobTitle } from "../../../Employee/job_title";
import CreateJobTitle from "../../../Employee/createJobTitle";
import EditJobTitle from "../../../Employee/editJobTitle";
import SalesOrderEdit from "../../../Invoice_Estimates/sales_order edit";
import { GeneralLedger } from "../../../Reports/GeneralLedger";
import { TrialBalance } from "../../../Reports/TrialBalance";
import { TransactionClients } from "../../../Users/transactions_client";
import { ExpenseCategory } from "../../../Reports/ExpenseCategory.jsx";
import { Expenses_Specific } from "../../../Expenses/expense_specific.jsx";
import { AdminDocuments } from "../../../Documents/adminDocuments.jsx";
import { ClientDocuments } from "../../../Documents/clientDocuments.jsx";

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Routes>
        <Route path="/dashboard" element={<DefaultDash />} />

        <Route path="/sales_order_edit" element={<SalesOrderEdit />} />

        <Route path="/bank_details" element={<BankDetails />} />

        {/* //expense account */}
        <Route path="/expense_specific" element={<Expenses_Specific />} />

        <Route path="/expense_customer" element={<ExpenseCustomer />} />
        
        <Route path="/employee_create" element={<CreateEmployee />} />
        <Route path="/employee_edit" element={<EditEmployee />} />

        <Route path="/expense_category" element={<ExpenseCategory />} />

        <Route path="/employees" element={<Employees />} />

        <Route path="/customer-create" element={<CustomerCreate />} />
        <Route path="/customer-edit" element={<CustomerEdit />} />
        <Route path="/address-edit" element={<EditAddress />} />
        <Route path="/invoice-edit" element={<Invoice_Edit />} />
        <Route path="/estimates" element={<Estimate_View />} />
        <Route path="/payment-received" element={<ViewPayments />} />
        <Route path="/add-payment" element={<Payment_Received />} />
        <Route path="/edit-payment" element={<Payment_Received_Edit />} />

        <Route path="/item" element={<ItemView />} />
        <Route path="/customer-edit" element={<CustomerEdit />} />
        <Route path="/item-create" element={<ItemCreate />} />
        <Route path="/create-invoice" element={<Invoice />} />
        <Route path="/invoices" element={<InvoiceView />} />
        <Route path="/credit_apply" element={<Credit_ReceivedBills />} />
        <Route path="/edit-credit-vendor" element={<Edit_CreditVendor />} />
        <Route path="/create-bank" element={<CreatBank />} />
        <Route path="/banking" element={<Banking />} />
        <Route path="/admin-documents" element={<AdminDocuments />} />
        <Route path="/client-documents" element={<ClientDocuments />} />
        
        <Route path="/customerstatement" element={<CustomerStatement />} />

        <Route
          path="/create-recurring-invoice"
          element={<Recurring_Invoice />}
        />
        <Route path="/recurring-invoices" element={<RecurringView />} />
        <Route path="/edit-estimates" element={<ESTIMATE_EDIT />} />
        <Route path="/create-estimates" element={<Estimates />} />
        <Route path="/credit-note-add" element={<CreditNote />} />
        <Route path="/credit-note" element={<Credit_note_view />} />
        <Route path="/credit-note-edit" element={<Edit_Credit />} />
        <Route path="/create-vendors" element={<VendorCreate />} />
        <Route path="/vendor" element={<Vendors />} />
        <Route path="/create-expense" element={<CreateExpense />} />
        <Route path="/expense" element={<Expenses_View />} />
        <Route path="/create-bills" element={<Bills />} />
        <Route path="/bills" element={<Bill_view />} />
        <Route path="/create-recurring-bills" element={<Bill_Recurring />} />
        <Route path="/recurring-bills" element={<Billrecurring_view />} />
        <Route path="/payment-bills" element={<ViewPaymentsBills />} />


        <Route path="/client_transaction" element={<TransactionClients />} />
        
        <Route path="/create-payment-bills" element={<Bill_Payment />} />
        <Route
          path="/create-recuring-expense"
          element={<Recurring_expenses />}
        />
        <Route path="/recuring-expense" element={<Recurring_viewexpense />} />
        <Route path="/vendor-credit" element={<Credit_note_bill />} />
        <Route path="/vendor-credit-create" element={<CreditNoteBill />} />
        <Route path="/report" element={<Reports />} />
        <Route path="/profitloss" element={<ProfitLoss />} />
        <Route path="/netcashflow" element={<NetCashFlow />} />
        <Route path="/balancesheet" element={<BalanceSheet />} />
        <Route
          path="/paymentreceivedreport"
          element={<PaymentReceivedReport />}
        />
        <Route path="/credtinotedetails" element={<CreditNoteDetails />} />
        <Route path="/customerbysales" element={<CustomerBySales />} />
        <Route path="/salesbysalesperson" element={<SalesByPerson />} />
        <Route path="/salesbyitem" element={<ItemBySales />} />
        <Route path="/customerbalance" element={<CustomerBalance />} />
        <Route path="/agingsummary" element={<AgingSummary />} />
        <Route path="/invoicedetails" element={<InvoiceDetails />} />
        <Route path="/estimatedetails" element={<EstimateDetails />} />
        <Route path="/receivables" element={<Receivables />} />
        <Route path="/vendorbalance" element={<VendorBalance />} />
        <Route path="/paymentmadereport" element={<PaymentMadeReport />} />
        <Route path="/payables" element={<Payables />} />
        <Route path="/purchasebyvendor" element={<PurchaseVendor />} />
        <Route path="/purchasebyitem" element={<ItemByPurchase />} />
        <Route
          path="/businessperformanceratio"
          element={<BusinessPerformanceRatio />}
        />
        <Route path="/creditnoteapply" element={<Credit_Received />} />
        <Route path="/investment" element={<InvestmentView />} />
        <Route path="/create-investment" element={<Createinvestment />} />
        <Route path="/users" element={<Users />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/address-create" element={<CreateAddress />} />
        <Route path="/addusers" element={<AddUser />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/addsalesperson" element={<AddSalesPerson />} />
        <Route path="/salesperson" element={<SalesPersonView />} />
        <Route path="/addtax" element={<AddTax />} />
        <Route path="/tax" element={<TaxView />} />
        <Route path="/your-users" element={<Clients />} />
        <Route path="/add-new-users" element={<Adduserclient />} />
        <Route path="/client-users" element={<ClientsUsers />} />
        <Route path="/archived-clients" element={<UsersArchived />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/manual_journal" element={<Manual_Journal />} />
        <Route path="/edit-user" element={<EditClientUser />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/journals" element={<JournalView />} />
        <Route path="/auditrail" element={<AuditUser />} />

        <Route path="/edittax" element={<EditTax />} />

        <Route path="/editexpense" element={<EditExpense />} />

        <Route path="/receipt_notes" element={<ReceiptNotes />} />

        <Route path="/agingsummarybills" element={<AgingSummaryBills />} />
        <Route path="/componentupload" element={<FileUploadDefault />} />

        <Route path="/salesorder" element={<SalesOrder />} />

        <Route path="/salesorderview" element={<Sales_OrdersView />} />

        <Route path="/currency" element={<CurrencyView />} />

        <Route path="/notes" element={<InvoiceNotes />} />

        <Route path="/expensevendor" element={<ExpenseVendor />} />

        <Route path="/sms_logs" element={<SMSLogs />} />

        <Route path="/vendor_statement" element={<VendorStatement />} />

        <Route path="/userguide" element={<UserGuide />} />

        <Route path="/budget" element={<Budget />} />

        <Route path="/add_budget" element={<AddBudget />} />

        <Route path="/footer" element={<FooterNotes />} />

        <Route path="/add_staff" element={<AddStaff />} />

        <Route path="/staff_expense" element={<StaffExpenses />} />

        <Route path="/staff_edit" element={<EditStaff />} />

        <Route path="/staff_admin" element={<Departments />} />

        <Route path="/create_deparment" element={<CreateDepartment />} />

        <Route path="/edit_department" element={<EditDepartment />} />

        <Route path="/job_title" element={<JobTitle />} />
        <Route path="/create_job_title" element={<CreateJobTitle />} />

        <Route path="/edit_job_title" element={<EditJobTitle />} />

        <Route path="/general-ledger" element={<GeneralLedger />} />
        <Route path="/trial-balance" element={<TrialBalance />} />
      </Routes>
    </div>
  </div>
);
