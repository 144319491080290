
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Button } from 'reactstrap'
import { COMPANY_NAME, formatCurrency, LOADER, NAME, ROLE, USER_ID_ACC } from '../../_helpers/exports'
import html2PDF from 'jspdf-html2canvas'
import * as moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import { TOKEN, USER_ID } from '../../_helpers/exports';
import axios from 'axios';
import { baseURL, baseURL_2 } from '../../_helpers';
import ReactDatatable from '@mkikets/react-datatable';

export class BusinessPerformanceRatio extends React.Component {
  constructor(props) {
    super(props)
    this.applyCallback = this.applyCallback.bind(this);

    this.state = {
      isShowError: false,

      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        download: false,
        key: 'selection',

      },
    }

    this.columns = [

      {
        key: "grossprofit",
        TrOnlyClassName: 'tsc',
        text: "Gross Profit",
        className: "cell",
        altrueign: "left"
      },
      {
        key: "netsales",
        TrOnlyClassName: 'tsc',
        text: "Net Sales",
        className: "tsc",
        align: "left"
      },
      {
        key: "ratio",
        TrOnlyClassName: 'tsc',
        text: "Ratio",
        className: "tsc",
        align: "left"
      },

    ];


    this.columns_operation = [

      {
        key: "operating_costss",
        TrOnlyClassName: 'tsc',
        text: "Operation Cost",
        className: "cell",
        altrueign: "left"
      },
      {
        key: "netsales2",
        TrOnlyClassName: 'tsc',
        text: "Net Sales",
        className: "tsc",
        align: "left"
      },
      {
        key: "ratioss",
        TrOnlyClassName: 'tsc',
        text: "Ratio",
        className: "tsc",
        align: "left"
      },


    ];



    this.columns_net_profit = [

      {
        key: "netprofitw2",
        TrOnlyClassName: 'tsc',
        text: "Net Profite",
        className: "cell",
        altrueign: "left"
      },
      {
        key: "netsales13c",
        TrOnlyClassName: 'tsc',
        text: "Net Sales",
        className: "tsc",
        align: "left"
      },
      {
        key: "ratio44",
        TrOnlyClassName: 'tsc',
        text: "Ratio",
        className: "tsc",
        align: "left"
      },

    ];
    this.columns_operation_profit = [

      {
        key: "operating_costss12",
        TrOnlyClassName: 'tsc',
        text: "Operation Profit",
        className: "cell",
        altrueign: "left"
      },
      {
        key: "netsales12",
        TrOnlyClassName: 'tsc',
        text: "Net Sales",
        className: "tsc",
        align: "left"
      },
      {
        key: "ratioss1",
        TrOnlyClassName: 'tsc',
        text: "Ratio",
        className: "tsc",
        align: "left"
      },

    ];
    this.config = {
      key_column: "tsc",
      // length_menu: [100, 150, 200, 500],
      show_filter: false,
      show_info: false,
      show_pagination: false,
      pagination: 'advance',
      show_length_menu: false,
      language: {
        loading_text: "Please be patient while data loads...",
        no_data_text: "Ooops it seems that there is no data. Please select the range",
        pagination: {
          next: <span>&#9658;</span>,
          previous: <span>&#9668;</span>
          // next
          // previous
        }
      }

    }
    let now = new Date();
    let start = moment(new Date(new Date().getFullYear(), 0, 1));
    let end = moment(new Date());
    this.state = {
      start: start,
      end: end,
      isPageLoad: true,
    }
  }
  componentDidMount() {

    this.setState({
      startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('DD MMM, YYYY'),
      isPageLoad: true,
      valuedate: "This Year"
    })
    this.getData(moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
  }

  getData(startDate, endDate) {
    this.setState({
      isLoading: true,
    })
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": TOKEN
      }
    };
    let url = baseURL + `invoiceamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url2 = baseURL + `custom_balance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url3 = baseURL + `getbillamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url4 = baseURL + `expensesamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url5 = baseURL + `operationexpense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url6 = baseURL + `vendorcreditreport?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`

    axios.all([
      axios.get(url, axiosConfig),
      axios.get(url2, axiosConfig),
      axios.get(url3, axiosConfig),
      axios.get(url4, axiosConfig),
      axios.get(url5, axiosConfig),
      axios.get(url6, axiosConfig),

    ]).then(axios.spread((invoiceResponse, balanceResponse, billResponse, goodsResponse, expenseResponse, vendorCreditReponse) => {
      console.log("balance", balanceResponse)
      this.setState({
        invoice: invoiceResponse.data.data,
        balance: balanceResponse.data.data,
        bills: billResponse.data.data,
        goods: goodsResponse.data.data,
        expense: expenseResponse.data.data,
        vendorcredit: vendorCreditReponse.data.data,

        isLoading: false
      }, function () {
        var data = [];
        // let editedInvoice = [];

        if (this.state.invoice.length === 0 && this.state.balance.length === 0) {
          this.setState({
            operating_cost: "0.00",
            isPageLoad: false
          })
        }
        else {
          let valueAdded = 0
          let count = 0
          let valueAdded1 = 0
          let count1 = 0
          let valueOutofBalance = 0
          let countOutofBalance = 0

          for (let i = 0; i < this.state.invoice.length; i++) {
            let str = this.state.invoice[i].amount;
            count = parseFloat(str.replaceAll(",", ""))
            valueAdded += count
          }
          for (let j = 0; j < this.state.balance.length; j++) {
            let b;
            if (this.state.balance[j].out_of_balance === null) {
              b = 0;
            } else {
              let str1 = this.state.balance[j].out_of_balance.toString();
              countOutofBalance = parseFloat(str1.replaceAll(",", ""))
              valueOutofBalance += countOutofBalance
            }
          }
          this.setState({
            operating_cost: (countOutofBalance + valueAdded).toFixed(2),
            isPageLoad: false
          })
        }


        if (this.state.bills.length === 0 && this.state.goods.length === 0 && this.state.vendorcredit.length === 0) {

          this.setState({
            cost_of_goods: "0.00",
            isPageLoad: false
          })
        }

        else {
          let valueAddedGoods = 0
          let countGoods = 0
          let valueBillsBalance = 0
          let countBillsBalance = 0

          let valueVendorCredit = 0
          let countVendorCredit = 0

          for (let i = 0; i < this.state.bills.length; i++) {
            let str = this.state.bills[i].amount.toString();
            countBillsBalance = parseFloat(str.replaceAll(",", ""))
            valueBillsBalance += countBillsBalance
          }

          for (let i = 0; i < this.state.vendorcredit.length; i++) {
            let str = this.state.vendorcredit[i].due_amount;
            countVendorCredit = parseFloat(str.replaceAll(",", ""))
            valueVendorCredit += countVendorCredit
          }
          for (let j = 0; j < this.state.goods.length; j++) {
            let str1
            if (this.state.goods[j].amount === null) {
              valueAddedGoods = 0;
            } else {
              let str1 = this.state.goods[j].amount.toString();
              countGoods = parseFloat(str1.replaceAll(",", ""))
              valueAddedGoods += countGoods
            }
          }
          console.log('bayoo', (valueAddedGoods + valueBillsBalance).toFixed(2))

          this.setState({
            cost_of_goods: ((valueBillsBalance + valueAddedGoods) - (valueVendorCredit)).toFixed(2),
            isPageLoad: false
          })
        }

        if (this.state.expense.length === 0) {

          this.setState({
            cost_of_operation: "0.00",
            isPageLoad: false
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.expense.length; j++) {
            let a;
            if (this.state.expense[j].amount == null) {
              a = 0;
            } else {
              let str2 = this.state.expense[j].amount.toString()

              // alert(this.state.expense[].amount)
              countExpense = parseFloat(str2.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
          }


          this.setState({
            cost_of_operation: valueAddedExpenses.toFixed(2),
            isPageLoad: false
          })
        }

        this.setState({
          gross: (this.state.operating_cost - this.state.cost_of_goods),
          isPageLoad: false
        })
      })

      var data = [];



      let ratio = {
        ratio: ((parseFloat(this.state.operating_cost) - parseFloat(this.state.cost_of_goods))
          /
          parseFloat(this.state.operating_cost)).toFixed(2).toString()
      }

      let netsales = {
        netsales: formatCurrency((parseFloat(this.state.operating_cost)))
      }


      let grossprofit = { grossprofit: formatCurrency((parseFloat(this.state.operating_cost) - parseFloat(this.state.cost_of_goods)).toFixed(1)) }
      data.push(Object.assign(grossprofit, netsales, ratio));
      console.log("bugs", data);
      this.setState({
        data: data
      })





      var data_operation = [];
      let ratioss = {
        ratioss: ((parseFloat(this.state.cost_of_operation) - parseFloat(0))
          /
          parseFloat(this.state.operating_cost)).toFixed(2).toString()
      }

      let netsales2 = {
        netsales2: formatCurrency(

          parseFloat(this.state.operating_cost))
      }


      let operating_costss = { operating_costss: formatCurrency((parseFloat(this.state.cost_of_operation) - parseFloat(0)).toFixed(2)) }
      data_operation.push(Object.assign(operating_costss, netsales2, ratioss));
      console.log("bugs", data_operation);
      this.setState({
        data_operation: data_operation
      })




      var data_operation_profit = [];
      let ratioss1 = {
        ratioss1: ((((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
          - parseFloat(this.state.cost_of_operation)))
          /
          parseFloat(this.state.operating_cost)).toFixed(2).toString()
      }

      let netsales12 = {
        netsales12: formatCurrency(

          parseFloat(this.state.operating_cost))
      }


      let operating_costss12 = {
        operating_costss12: formatCurrency((

          ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
            - parseFloat(this.state.cost_of_operation))

        ).toFixed(2))
      }
      data_operation_profit.push(Object.assign(operating_costss12, netsales12, ratioss1));
      console.log("bugs", data_operation);
      this.setState({
        data_operation_profit: data_operation_profit
      })



      var data_netprofit = [];

      if (((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods))) - parseFloat(this.state.cost_of_operation)) < 0) {

        let ratio44 = {
          ratio44: ((
            ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
              - parseFloat(this.state.cost_of_operation)))

            /

            parseFloat(this.state.operating_cost)).toFixed(2).toString()


        }

        let netprofitw2 = {
          netprofitw2: formatCurrency((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
            - parseFloat(this.state.cost_of_operation))
        }
        let netsales13c = { netsales13c: formatCurrency((parseFloat(this.state.operating_cost) - parseFloat(0)).toFixed(2)) }
        data_netprofit.push(Object.assign(netsales13c, netprofitw2, ratio44));
        console.log("bugs", data);
        this.setState({
          data_netprofit: data_netprofit
        })
      } else {


        let ratio44 = {
          ratio44: ((
            (

              ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                - parseFloat(this.state.cost_of_operation))
              -
              (0.3 * ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                - parseFloat(this.state.cost_of_operation))))
            /
            parseFloat(this.state.operating_cost)


          )).toFixed(2).toString()
        }
        let netprofitw2 = {
          netprofitw2: formatCurrency(

            (

              ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                - parseFloat(this.state.cost_of_operation))
              -
              (0.3 * ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                - parseFloat(this.state.cost_of_operation))))

          )
        }
        let netsales13c = { netsales13c: formatCurrency((parseFloat(this.state.operating_cost) - parseFloat(0)).toFixed(2)) }


        let operating_costss = { operating_costss: formatCurrency((parseFloat(this.state.operating_cost) - parseFloat(0)).toFixed(2)) }
        data_netprofit.push(Object.assign(netsales13c, netprofitw2, ratio44));
        console.log("bugs", data_operation);
        this.setState({
          data_netprofit: data_netprofit
        })


      }









    })).catch(error => {
      console.log('bayoo', error.response)
    })
  }



  applyCallback(startDate, endDate) {
    // localStorage.setItem("STARTDATE", startDate)
    // localStorage.setItem("ENDDATE", endDate)
    let Sdate1 = moment(startDate).format('DD MMM, YYYY');
    let Edate2 = moment(endDate).format('DD MMM, YYYY');
    this.setState({
      valuedate: Sdate1 + " " + Edate2,
      startDate: Sdate1,
      isPageLoad: true,
      endDate: Edate2
    })

    this.getData(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
  }


  render() {

    let ranges = {
      "Today Only": [moment(this.state.start), moment(this.state.end)],
      "Yesterday Only": [
        moment(this.state.start).subtract(1, "days"),
        moment(this.state.end).subtract(1, "days")
      ],
      "3 Days": [moment(this.state.start).subtract(3, "days"), moment(this.state.end)],
      "5 Days": [moment(this.state.start).subtract(5, "days"), moment(this.state.end)],
      "1 Week": [moment(this.state.start).subtract(7, "days"), moment(this.state.end)],
      "2 Weeks": [moment(this.state.start).subtract(14, "days"), moment(this.state.end)],
      "1 Month": [moment(this.state.start).subtract(1, "months"), moment(this.state.end)],
      "1 Year": [moment(this.state.start).subtract(1, "years"), moment(this.state.end)]
    };
    let local = {
      "format": "DD-MM-YYYY",
      "sundayFirst": false
    }
    return (
      <div style={{ marginTop: "-20px" }} >
        {this.state.isPageLoad ? LOADER :
          <Card>
            {this.state.download ? "Downloading Report...Please wait" :
              <CardBody>
                <div className='col-12'>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-6">
                            <DateTimeRangeContainer
                              ranges={ranges}
                              start={this.state.start}
                              end={this.state.end}
                              local={local}
                              applyCallback={this.applyCallback}>
                              <FormControl
                                id="formControlsTextB"
                                type="text"
                                value={this.state.valuedate}
                                label="Text"
                                placeholder="Filter by Date"
                              />
                            </DateTimeRangeContainer>
                          </div>

                          <div className="col-6 float-left">

                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {
                              // this.getData();
                              this.getData(moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))

                              this.setState({
                                startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
                                endDate: moment(new Date()).format('DD MMM, YYYY'),
                                isPageLoad: true,
                                valuedate: "This Year"
                              })
                            }} >Clear</button>
                            {/* <input id="input" type="text" readOnly /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pull-right">
                      <button className="float-right btn btn-primary btn-sm" onClick={() => this.onclickss()}>Export PDF</button>

                    </div>
                  </div>
                  <div id="page">

                    <br />
                    <div className='text-center' p='3'>
                      <h4>{COMPANY_NAME}</h4>
                      <h3><strong>Business Performance Ratio</strong></h3>
                      <h4>Basis: Accrual</h4>
                      <h4>From {this.state.startDate} To {this.state.endDate}</h4>
                      <br /><br /><br />
                    </div>


                    <div className="col-12 row">

                      <div className="col-6">
                        <h4><b>Gross Profit Ratio</b></h4>

                        <ReactDatatable
                          config={this.config}

                          records={this.state.data}
                          columns={this.columns}
                          dynamic={true}
                          loading={this.state.isLoading}
                          total_record={this.state.total}
                          onChange={this.tableChangeHandler} />
                      </div>
                      <div className="col-6">
                        <h4><b>Operating Profit Ratio	</b></h4>

                        <ReactDatatable
                          config={this.config}

                          records={this.state.data_operation}
                          columns={this.columns_operation}
                          dynamic={true}
                          loading={this.state.isLoading}
                          total_record={this.state.total}
                          onChange={this.tableChangeHandler} />
                      </div>
                      <br />
                      <div className="col-6">
                        <br /><br /><br />
                        <h4><b>Operating Profit Ratio</b></h4>

                        <ReactDatatable
                          config={this.config}
                          records={this.state.data_operation_profit}
                          columns={this.columns_operation_profit}
                          dynamic={true}
                          loading={this.state.isLoading}
                          total_record={this.state.total}
                          onChange={this.tableChangeHandler} />
                      </div>
                      <div className="col-6">
                        <br /><br /><br />
                        <h4><b>Net Profit Ratio</b></h4>

                        <ReactDatatable
                          config={this.config}

                          records={this.state.data_netprofit}
                          columns={this.columns_net_profit}
                          dynamic={true}
                          loading={this.state.isLoading}
                          total_record={this.state.total}
                          onChange={this.tableChangeHandler} />
                      </div>


                    </div>


                    <br />
                  </div>


                </div>


              </CardBody>
            }

          </Card>
        }

      </div>

    )
  }
}
