import React from "react";
import { PricingTable, PricingSlot, PricingDetail } from 'react-pricing-tables';

import styled from "styled-components";

const Section = styled.section`
  padding: 30px 0 225px;
`;
const SectionTitle = styled.h2`
  font-size: 26px;
  font-weight: 300;
  line-height: normal;
  color: #1b5cce;
  text-align: center;
  margin-bottom: 2px;
  color: #00194B !important;
  letter-spacing: 1.5px
`;

const SubTitle = styled.h5`
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: #aeaeae;
  margin-bottom: 25px;
  color: #00194B !important;
  letter-spacing: 1.5px
  
  @media (min-width: 992px) {
    margin-bottom: 50px;
    color: #00194B !important;
    letter-spacing: 1.5px
  }
`;

const Pricing = () => {
  const width = window.innerWidth;

  const plans = [
    { name: '3 users + 2 accountants' },
    { name: 'Unlimited invoices' },
    { name: 'Receipts' },
    { name: 'Quotations' },
    { name: 'Recurring invoices' },
    { name: 'Recurring expenses' },
    { name: 'Payment reminders' },
    { name: 'Financial reports' },
    { name: 'Bank reconciliations' },
    { name: 'Bills' },
    { name: 'Documents scan' },
    { name: 'Multi-currency' },
    { name: 'Sales orders' },
    { name: 'Sales approval' },
    { name: 'Payroll' },
    { name: 'Purchase orders' },
    { name: 'Purchase approval' },
    { name: 'Budgeting' },
    { name: 'Reporting tags' },
    { name: 'Inventory' },
    { name: 'Stock tracking' },
  ];
  return (
    <Section id="pricing">
      {/* <div className="col-md-8 offset-2">
        <SectionTitle>Pricing</SectionTitle>
        <SubTitle>PeakBooks is powering thousands of businesses in Kenya and the region, switch to smart accounting today and save time and find peace of mind. <br />
        Get your books done for only 10,000 Kenya shillings annually or 1,250 Kenya shillings monthly. <br />
        Ignite the spark in your business with PeakBooks. <a href="/register">Sign up</a> today and enjoy 30 day trial free of charge. </SubTitle><br />
      </div> */}
      <SectionTitle>Our Plans</SectionTitle>
      <PricingTable highlightColor='#00194c'>
        <PricingSlot onClick={event => window.location.href = '/register'} buttonClass='button primary' highlighted buttonText='SIGN UP' title='BASIC' priceText='KES 1,000 monthly or KES 10,000 annually'>
          <PricingDetail className="price_detail">1 Admin + 1 Accountant</PricingDetail>
          <PricingDetail className="price_detail">Unlimited invoices</PricingDetail>
          <PricingDetail className="price_detail">Receipts</PricingDetail>
          <PricingDetail className="price_detail">Quotations</PricingDetail>
          <PricingDetail className="price_detail">Recurring invoices</PricingDetail>
          <PricingDetail className="price_detail">Recurring expenses</PricingDetail>
          <PricingDetail className="price_detail">Payment reminders</PricingDetail>
          <PricingDetail className="price_detail">Sales reports</PricingDetail><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </PricingSlot>
        <PricingSlot onClick={event => window.location.href = '/register'} 
        
        highlighted buttonClass='button primary' buttonText='SIGN UP' title='STANDARD' 
        
        priceText='KES 1,500 monthly or KES 15,000 annually'>
          <PricingDetail className="price_detail">2 Admins + 3 Accountants</PricingDetail>
          <PricingDetail className="price_detail">Unlimited invoices</PricingDetail>
          <PricingDetail className="price_detail">Receipts</PricingDetail>
          <PricingDetail className="price_detail">Quotations</PricingDetail>
          <PricingDetail className="price_detail">Recurring invoices</PricingDetail>
          <PricingDetail className="price_detail">Recurring expenses</PricingDetail>
          <PricingDetail className="price_detail">Payment reminders</PricingDetail>
          <PricingDetail className="price_detail">Financial reports</PricingDetail>
          <PricingDetail className="price_detail">Bank reconciliation</PricingDetail>
          <PricingDetail className="price_detail">Bills</PricingDetail>
          <PricingDetail className="price_detail">Documents scan</PricingDetail>
          <PricingDetail className="price_detail">Multi-currency</PricingDetail>
          <PricingDetail className="price_detail">Sales orders</PricingDetail>
          <PricingDetail className="price_detail">Sales approval</PricingDetail><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </PricingSlot>
        <PricingSlot onClick={event => window.location.href = '/register'} highlighted buttonClass='button' buttonText='SIGN UP' title='ENTERPRISE' priceText='KES 2,000 monthly or KES 20,000 annually'>
          <PricingDetail className="price_detail">3 Admins + 6 Accountants</PricingDetail>
          <PricingDetail className="price_detail">Unlimited invoices</PricingDetail>
          <PricingDetail className="price_detail">Receipts</PricingDetail>
          <PricingDetail className="price_detail">Quotations</PricingDetail>
          <PricingDetail className="price_detail">Recurring invoices</PricingDetail>
          <PricingDetail className="price_detail">Recurring expenses</PricingDetail>
          <PricingDetail className="price_detail">Payment reminders</PricingDetail>
          <PricingDetail className="price_detail">Financial reports</PricingDetail>
          <PricingDetail className="price_detail">Bank reconciliation</PricingDetail>
          <PricingDetail className="price_detail">Bills</PricingDetail>
          <PricingDetail className="price_detail">Documents scan</PricingDetail>
          <PricingDetail className="price_detail">Multi-currency</PricingDetail>
          <PricingDetail className="price_detail">Sales orders</PricingDetail>
          <PricingDetail className="price_detail">Sales approval</PricingDetail>
          <PricingDetail className="price_detail">Purchase orders</PricingDetail>
          <PricingDetail className="price_detail">Purchase approval</PricingDetail>
          <PricingDetail className="price_detail">Budgeting</PricingDetail>
          <PricingDetail className="price_detail">Reporting tags</PricingDetail>
          <PricingDetail className="price_detail">Inventory</PricingDetail>
          <PricingDetail className="price_detail">Stock tracking</PricingDetail>
        </PricingSlot>
      </PricingTable>
      {/* <div className="col-md-8 offset-2">
        <SectionTitle>The Plan</SectionTitle>
        <ul className="plans-list">
          {plans.map(plan => (
            <li>{plan.name}</li>
          ))}
        </ul>
      </div> */}
    </Section>
  );
};

export default Pricing;
