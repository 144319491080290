
import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, Table, CardBody, Col } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { BRANCH_ID, BUSINESS_TYPE, formatCurrency, PROFILE_URL, ROLE, TOKEN, USER_ID } from '../../_helpers/exports';
import * as moment from 'moment';
import CustomerCreate from './createCustomer';
import { Link } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import CreateAddress from '../Address/createCustomerAddress';
import EditAddress from '../Address/editcustomer';
import CustomerEdit from './customer_edit';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";
import { read, utils } from "xlsx";

import { CustomerStatement } from './CustomerStatement';
import { ClientDocuments } from '../Documents/clientDocuments';



export class Customers extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this._onButtonClick3 = this._onButtonClick3.bind(this);
        this._onButtonClick5 = this._onButtonClick5.bind(this);
        this.onSubmitNewUpdatws = this.onSubmitNewUpdatws.bind(this);
        // this.updateData = this.updateData.bind(this)
        this.toggleModalMore = this.toggleModalMore.bind(this);

        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnClick1 = this.handleOnClick1.bind(this);

        this.columns = [
            {
                key: "company_mail",
                TrOnlyClassName: 'tsc',
                text: BUSINESS_TYPE === 'business' ? "Company Name" : "Name",
                className: "tsc",
                align: "left"
            },
            // {
            //     key: "customer_type",
            //     TrOnlyClassName: 'tsc',
            //     text: "Customer Type",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "first_name",
                TrOnlyClassName: 'tsc',
                text: "Contact Person",
                className: "tsc",
                align: "left"
            },

            {
                key: "customer_unique",
                TrOnlyClassName: 'tsc',
                text: BUSINESS_TYPE === 'business' ? "customer id" : "grantor id",
                className: "tsc",
                align: "left"
            },

            {
                key: "msisdn",
                TrOnlyClassName: 'tsc',
                text: "Phone No",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "email",
                TrOnlyClassName: 'tsc',
                text: "Email",
                className: "tsc",
                align: "left"
            },

            {

                key: "tax_info",
                TrOnlyClassName: 'tsc',
                text: "KRA PIN",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "mout_of_balance",
                TrOnlyClassName: 'tsc',
                text: "Opening Balance",
                className: "tsc",
                align: "left"
            },

            // {
            //     key: "ttl",
            //     TrOnlyClassName: 'tsc',
            //     text: "TTL",
            //     className: "tsc",
            //     align: "left"
            // },
            // {
            //     key: "mail",
            //     TrOnlyClassName: 'tsc',
            //     text: "Status",
            //     className: "tsc",
            //     align: "left",
            //     cell: record => {
            //         return (
            //             <Fragment className="center" >
            //                 {record.status === 1 ?
            //                     <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
            //                         Active
            //                     </span>
            //                     : null}

            //                 {record.mail === 0 ?
            //                     <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
            //                         Inactive
            //                     </ span>
            //                     : null}

            //             </Fragment >
            //         );
            //     }
            // },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        // eslint-disable-next-line
                        <Fragment className="center" >
                            {BUSINESS_TYPE === 'business' ?
                                <button className="btn btn-success  btn-sm"
                                    title="Eith statement"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.onStatment(record) }} >
                                    Statement
                                </button>
                                : null}

                            <button className="btn btn-primary  btn-sm"
                                title="View"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.toggleModalMore(record) }} >
                                More <i className="fa fa-plus"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [5, 10, 20],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: true,
                csv: true
            },
            filename: BUSINESS_TYPE === 'business' ? "customer" : "grantor",
            page_size: 100,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search here",
                no_data_text: "Oooops no customer found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            showStatements: false,
            isShowError: false,
            showComponent: false,
            clientDocumentStatus: false,
            showModals: false,
            _onShow: false,
            showEdit: false,
            type: [{
                name: 'Rating'
            }, {
                name: 'Closed-ended'
            }],

            hideComponent: false,
            data: [],
            priority: [{
                name: '1'
            }
            ],
            isOpenMore: false
        };
    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this._onButtonClick2(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    async handleOnClick1(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onApprove(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }


    toggleModalMore(record) {
        this.setState({
            isOpenMore: true,
            moreopen: record
        });

    };


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {

        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = `${PROFILE_URL}?canvas=true`;
        }

        this.getData();
    }
    // localStorage.setItem("survey_id", record.survey_id)
    // localStorage.setItem("survey_name", record.name)

    getData = (queryString = "") => {



        // alert(queryString)

        // alert(USER_ID)
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {

                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };

        const urlParams = new URLSearchParams(queryString);

        let myParam = urlParams.get('page_size');

        let pageNumber = urlParams.get('page_number');

        if (myParam === null) {

            myParam = 1000

            pageNumber = 1

        }


        // alert()






        let url = baseURL + `customer?branch_id=${BRANCH_ID}&&client_id=${USER_ID}` + "&&" + queryString



        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((customerResponse) => {


            // alert(JSON.stringify(customerResponse));

            this.setState({
                customer: customerResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];

                    this.setState({
                        total: customerResponse.data.total
                    })

                    if (this.state.customer.length > 0) {

                        let x = myParam * pageNumber;

                        for (let i = 0; i < this.state.customer.length; i++) {
                            let mnameindex;

                            mnameindex = x++


                            // alert(mnameindex)

                            let number = { mindex: mnameindex }
                            let mcompany_name;

                            let mail;
                            if (this.state.customer[i].status_more === 1) {
                                mail = { mail: "Active" };
                            } else {
                                mail = { mail: "Inactive" };
                            }

                            if (this.state.customer[i].company_name === null) {
                                mcompany_name = { company_mail: "_" };
                            } else {
                                mcompany_name = { company_mail: this.state.customer[i].company_name };
                            }
                            let mstarting_balance = { mout_of_balance: formatCurrency(this.state.customer[i].out_of_balance.toString()) };
                            let date = { dates: (moment.utc(this.state.customer[i].created_at).format('DD/MM/YYYY')) };
                            data.push(Object.assign(date, number, mstarting_balance, mail, mcompany_name, this.state.customer[i]));
                            this.setState({
                                data: data
                            })
                        }
                    } else {
                        this.setState({
                            data: data
                        })
                    }
                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' +
                    encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showEdit: false,
            showModal: false,
            showModals: false
        });
    }

    _onButtonClick5() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showEdit: false,
            showModal: false,
            showModals: false
        });
    }

    _onButtonClick2(record) {
        localStorage.setItem("customer_id_moreinfo", record.id)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: false,
            showEdit: false,
            _onShow: false,
            showModals: true
        });
    }

    _onButtonClick3() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showModal: false,
            _onShow: false,
            showEdit: false,
            showModals: false
        });
    }

    onEdit(record) {
        localStorage.setItem("email_customer", record.email)
        localStorage.setItem("id_customer", record.id)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: false,
            _onShow: false,
            showModals: false,
            showEdit: true,
        }, function () {
        });

    }

    onStatment(record) {

        // alert(JSON.stringify(record))
        localStorage.setItem("name_customer", record.company_mail)
        localStorage.setItem("id_client", record.id)
        localStorage.setItem("email_client", record.email)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: false,
            _onShow: false,
            showModals: false,
            showStatements: true,
        }, function () {
        });

    }


    onSubmitNewUpdatws(e) {
        e.preventDefault();
        let ttl_new = (this.state.ttl * 60)

        let formData = {
            "survey_id": localStorage.getItem("survey_id"),

            "ttl": ttl_new,
            "type": this.state.selectedType,
            "question": this.state.question,

        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.put(baseURL + `question/` + this.state.questionsss, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)

                if (response.data.status) {
                    this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                    window.setTimeout(() => {
                        this.setState({ isShowError: false });
                        this._onButtonClick5()
                        this.getData()
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.status_message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }


    onDelete(record) {

        // alert(record.id)
        axios.delete(baseURL + `customer?customer_id=` + record.id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {


                axios.delete(baseURL + `recurringinvoice?id=` + record.id, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': TOKEN
                    },
                }).then(response => {
                    console.log("true", response.data)
                    if (response.data.status) {
                        this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                            window.setTimeout(() => {
                                this.getData();
                                this.setState({ isShowError: false })
                            }, 3000);
                        });
                    } else {
                        this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                        window.setTimeout(() => {
                            this.getData();
                            this.setState({ isShowError: false })
                        }, 4000);
                    }
                });

                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData()
                        // this.closeModalMore();
                        this.setState({ isShowError: false })


                    }, 3000);
                });
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData("", moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                    this.setState({ isShowError: false })
                }, 4000);
            }
        }, error => {
            // alert(JSON.stringify(error))
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData("", moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                this.setState({ isShowError: false })
            }, 4000);
        });
    }



    toggleModalCreate = e => {
        this.setState({
            isOpen: true,
        });
    };

    closeModalUpload = e => {
        this.setState({
            isOpen: false,
        });
    };

    //handle profile picture upload
    handleProfile = event => {
        this.setState({
            csvfile: event.target.files[0]
        });
    };

    excelToJson(reader) {
        var fileData = reader.result;
        var wb = read(fileData, { type: 'binary' });
        var dataS = {};
        var rowString;
        var rowObj;
        wb.SheetNames.forEach(function (sheetName) {
            rowObj = utils.sheet_to_row_object_array(wb.Sheets[sheetName]);
            rowString = JSON.stringify(rowObj);
            dataS[sheetName] = rowString;
        });


        // const datas = result.data;
        let data = [];
        let mclient_id;
        let mOpening_Balance;
        let mKRA_Pin;


        for (let i = 0; i < rowObj.length; i++) {
            mclient_id = { client_id: USER_ID };

            if (rowObj[i].Opening_Balance == null) {
                mOpening_Balance = { Opening_Balance: 0 }
            } else {
                mOpening_Balance = { Opening_Balance: rowObj[i].Opening_Balance }
            }
            if (rowObj[i].KRA_Pin == null) {
                mKRA_Pin = { KRA_Pin: "__" }
            } else {
                mKRA_Pin = { KRA_Pin: rowObj[i].KRA_Pin }
            }


            data.push(Object.assign(mclient_id, mKRA_Pin, mOpening_Balance, rowObj[i]));

            this.setState({
                data: data
            })

        }


        console.log("DATA", JSON.stringify(data))

        console.log("brian", data)
        axios.post(baseURL_2 + 'importCustomer.php', JSON.stringify(data))
            .then((response) => {
                console.log("bayo", response)
                // this.setState({
                //     statusMessage: "Success!!",
                //     isShowError: true, isLoading1: false, showError: false,
                // });

                this.setState({ statusMessage: "Success!!", isShowError: true, isLoading1: false }, function () {
                    window.setTimeout(() => {
                        this.closeModalUpload();
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });


            }).catch(error => {
                this.setState({ statusMessage: "Unable to import", showError: true, isShowError: false, isLoading1: false });

                console.error('Error during service worker registration:', error);
            });




        // this.setState({ excelData: data });
    }



    importCSV = event => {
        event.preventDefault();
        this.setState({ isLoading1: true, isLoading: true });
        var reader = new FileReader();
        reader.onload = this.excelToJson.bind(this, reader);
        reader.readAsBinaryString(this.state.csvfile);
        // var Papa = require("papaparse/papaparse.min.js");
        // Papa.parse(this.state.csvfile, {
        //     header: true,
        //     download: true,
        //     skipEmptyLines: true,
        //     // Here this is also available. So we can call our custom class method
        //     complete: this.updateData
        // });
    }





    // uploadProfile = event => {
    //     event.preventDefault();
    //     var s = localStorage.getItem("profile_url");
    //     var final = s.substr(s.lastIndexOf('/') + 1);
    //     if (this.state.imagefile.size < 5242880) {
    //         this.setState({ loading: true });
    //         var data = new FormData();
    //         data.append("filename", this.state.imagefile);
    //         data.append("profile_url", final);
    //         data.append("user_id", USER_ID);
    //         this.setState({ isLoading1: true });
    //         axios.post(baseURL_2 + 'uploaddocument.php', data, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Accept': 'application/json',
    //             },
    //         }).then((response) => {
    //             // eslint-disable-next-line
    //             if (response.data.status = true) {
    //                 this.setState({ statusMessage: response.data.message, isShowError: true, loading: false, isLoading1: false },
    //                     function () {
    //                         console.log("bayo")
    //                         setTimeout(() => {
    //                             this.setState({ statusMessage: "", isShowError: false, loading: false, isLoading1: false })
    //                             this.closeModalUpload();

    //                             this.getData();
    //                         }, 2000);
    //                     }
    //                 );
    //             } else {
    //                 this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, loading: false, isLoading1: false },
    //                     function () {
    //                         console.log("bayoddd", data)
    //                         setTimeout(() => {
    //                             this.setState({ statusMessage: "", isShowError: false, showError: false, isLoading1: false })
    //                         }, 2000);
    //                     }
    //                 );
    //             }
    //         }).catch((error) => {
    //             console.log('bayoo', error.response)
    //             this.setState({ isShowError: false, showError: false, statusMessage: error.response.data.message, showError: true, isLoading1: false },
    //                 function () {
    //                     setTimeout(() => {
    //                         this.setState({ statusMessage: "", isShowError: false, showError: false, isLoading1: false })
    //                     }, 2000);
    //                 }
    //             );
    //         })
    //     } else {
    //         alert("Your logo should not be more than 5mbs")
    //     }
    // }



    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onCustomerInfo(record) {
        localStorage.setItem("customer_id_moreinfo", record.id)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: true,
        }, function () {
        });

    }
    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };
    ReactTypes() {
        return (this.state.priority && (this.state.priority.length > 0 || this.state.priority.length === 0) &&
            this.state.priority.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.name })))
    }


    closeModalMore = e => {
        this.setState({
            isOpenMore: false,
        });
    };


    showDocument = e => {
        console.log("test testfvkvkv")
        this.setState({
            clientDocumentStatus: true,
            showComponent: false,
            hideComponent: true,
            showModal: false,
            _onShow: false,
            showModals: false,
            showStatements: false
        });
    };

    _onShow(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            editShow: true,
            acceptable_answers: record.acceptable_answers,
            answer: record.answer,
            answer_id: record.id,
            showModal: false,
        });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { showModal } = this.state;
        const { showModals } = this.state;
        const { showEdit, showStatements, clientDocumentStatus } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
                    <div>

                        <Modal
                            isOpen={this.state.isOpenMore}
                            onRequestClose={e => this.closeModalMore(e)}
                            contentLabel="My dialog"
                            className="mymodal"
                            onAfterOpen={() => {
                                document.body.style.overflow = 'hidden';
                            }}
                            onAfterClose={() => {
                                document.body.removeAttribute('style');
                            }}
                            overlayClassName="myoverlay"
                            closeTimeoutMS={500}
                        >
                            <MDBCloseIcon onClick={this.closeModalMore} />
                            <h4><b>More+</b></h4><br />

                            <div>
                                <button className="btn btn-primary  btn-sm"
                                    title="Eith details"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.onEdit(this.state.moreopen) }} >
                                    Edit
                                </button>
                            </div><br />
                            <div>
                                <button className="btn btn-danger  btn-sm"
                                    title="Delete customer"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => {
                                            if (window.confirm(`Are you sure you want to delete this ${BUSINESS_TYPE === 'business' ? "customer" : "grantor"}? You will loose all his/her data`))
                                                this.onDelete(this.state.moreopen)
                                        }
                                    }>
                                    Delete
                                </button>
                            </div><br />
                            <div>
                                <button className="btn btn-primary  btn-sm"
                                    title="Eith details"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.showDocument() }} >
                                    Documents
                                </button>
                            </div><br />
                            <br />



                        </Modal>
                        <Modal
                            isOpen={this.state.isOpen}
                            onRequestClose={e => {
                                this.closeModalUpload(e);
                            }}
                            contentLabel="My dialog"
                            className="mymodal"
                            onAfterOpen={() => {
                                document.body.style.overflow = 'hidden';
                            }}
                            onAfterClose={() => {
                                document.body.removeAttribute('style');
                            }}
                            overlayClassName="myoverlay"
                            closeTimeoutMS={500}
                        >
                            <MDBCloseIcon onClick={this.closeModalUpload} />
                            <h4><b>Upload Excel file</b></h4><br />

                            <p><b>Kindly remember to follow
                                <a href="https://api.peakbooks.biz:9000/insightphp/systemFiles/1621650759_datafile.xlsx"> this format
                                </a> before import.<br />N/B> No cell should be left blank (fill it with '0' or '_')</b></p><br />

                            <b><a href="https://api.peakbooks.biz:9000/insightphp/systemFiles/1621650759_datafile.xlsx"> Click here to donwload template
                            </a></b>


                            {this.state.showError ? <div >

                                <Alert color="danger" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>


                            </div> : null}<br></br>

                            {this.state.isShowError ? (
                                <div>

                                    <Alert color="success" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>
                                </div>
                            ) : null}
                            <div className="wrapper text-center">
                                <div className="section1">
                                    <div className="contain">
                                        <input
                                            className="csv-input"
                                            type="file"
                                            required
                                            ref={input => {
                                                this.filesInput = input;
                                            }}
                                            name="file"
                                            customHeight
                                            placeholder={null}
                                            onChange={this.handleProfile}
                                        />
                                        <br />
                                        <Button color="primary" className="btn-paypal btn-lg " onClick={this.importCSV} >
                                            {this.state.isLoading1 ? "Uploading..." : "Import now!"}
                                            <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;

                                        <br />
                                    </div>
                                </div>
                            </div>

                        </Modal>

                        < Card >
                            <CardBody >
                                <div className="panel-body" >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <h4><b>List of {BUSINESS_TYPE === 'business' ? "Customers" : "Grantors"}</b></h4><br />
                                        </div>
                                        <div className="col-md-3 ">
                                            <Button className="pull- btn-sm" color="primary
                                                    " onClick={this.toggleModalCreate} outline>Import {BUSINESS_TYPE === 'business' ? "Customers" : "Grantors"}
                                            </Button>

                                        </div>
                                        <div className="col-md-3">

                                            <b>OR</b>
                                            <Link to="/customer-create">
                                                <Button className="pull- btn-sm" color="primary
                                                    " onClick={this._onButtonClick} outline>Create {BUSINESS_TYPE === 'business' ? "Customer" : "Grantor"}
                                                </Button>
                                            </Link>

                                        </div>

                                    </div>

                                    <div className="col-12 float-right">
                                        <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}
                                            onClick={this.getData}>
                                            Refresh <RefreshIcon />
                                        </button>
                                    </div>
                                    <br />
                                    <br />

                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}
                                    <p><br />


                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />
                                    </p>

                                </div>
                            </CardBody>

                        </Card>
                    </div>
                )
                }
                {
                    showComponent && (
                        <div>
                            <CustomerCreate />
                        </div>
                    )
                }

                {
                    showModal && (
                        <div>
                            <CreateAddress />
                        </div>
                    )
                }

                {
                    showModals && (
                        <div>
                            <EditAddress />
                        </div>
                    )
                }
                {
                    showEdit && (
                        <div>
                            <CustomerEdit />
                        </div>
                    )
                }
                {
                    showStatements && (
                        <div>
                            <CustomerStatement />
                        </div>
                    )
                }
                {
                    clientDocumentStatus && (
                        <div>
                            <ClientDocuments state={this.state.moreopen}/>
                        </div>
                    )
                }
            </div >
        )
    }
}