import React from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { BRANCH_ID, BUSINESS_TYPE, TOKEN, USER_ID } from '../../_helpers/exports';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Customers } from './customer_view';
import Input from 'react-phone-number-input/input'
import { Link } from 'react-router-dom';
import Alert from '../../shared/components/Alert';
import CreateAddress from '../Address/createCustomerAddress';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'

class CustomerCreate extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
            moreinfo: false,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onMoreInfo = this._onMoreInfo.bind(this);

        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            showError: false,
            out_of_balance: 0,

            company_name: "",
            other_name: "",
            first_name: "",
            type: [{
                name: 'Mr.'
            }, {
                name: 'Mrs.'
            },
            {
                name: 'Ms.'
            },
            {
                name: 'Miss.'
            },
            {
                name: 'Dr.'
            }],

            priority: [{
                name: 'Business'
            }, {
                name: 'Individual'
            }],
            avenue: '',
            assigned_to: '',
            selectedCounty: 'Select County/State Name',
        }
    }

    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    onSubmit(e) {

        // if(!isValidPhoneNumber){
        //     this.setState({ statusMessage: "Invalid Phone", showError: true, isShowError: false, isLoading: false });
        // }

        e.preventDefault();

        let formData = {
            "client_id": USER_ID,
            "customer_type": "_",
            "title": "_",
            "first_name": this.state.first_name,
            "other_name": this.state.other_name,
            "email": this.state.email,
            "msisdn": this.state.msisdn,
            "tax_info": this.state.tax_info,
            "branch_id": BRANCH_ID,
            "out_of_balance": this.state.out_of_balance,
            "company_name": this.state.company_name,
            "customer_display_name": this.state.company_name,
            "website": this.state.website,
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.post(baseURL + `customer`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                    window.scrollTo(0, 0)
                    window.setTimeout(() => {
                        this.setState({ isShowError: false });
                        this._onButtonClick();
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
            })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            moreinfo: false
        });
    }

    _onMoreInfo() {
        this.setState({
            showComponent: false,
            hideComponent: true,
            moreinfo: true
        });
    }

    changeStatus() {
        this.setState({
            isShowError: false
        });
    }
    window
    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { moreinfo } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <Customers /> : null
                        }
                    </div>
                )}
                {moreinfo && (
                    <div>
                        {this.state.moreinfo ?
                            <CreateAddress /> : null
                        }
                    </div>
                )}

                {!hideComponent && (
                    <div>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>Create New {BUSINESS_TYPE === 'business' ? "Customer" : "Grantor"}</b></h4><br />
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/customers">
                                            <Button className="pull-right  btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                                {BUSINESS_TYPE === 'business' ? "Customers" : "Grantors"}
                                            </Button>
                                        </Link>

                                    </div>
                                </div>


                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>


                                </div> : null}<br></br>

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div>

                                ) : null}<br></br>
                                <form className="form col-12" onSubmit={this.onSubmit}>
                                    {/* <div className="col-md-6">
                                        <Form.Group className="Focus-line" >
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Customer Type</Form.Label>
                                                </div>
                                                <div className="col-md-10">
                                                    <Form.Control
                                                        as="select"
                                                        className="form-control input-md"
                                                        placeholder="Select Customer Type"
                                                        value={this.state.selectedPriority}
                                                        onChange={this.handleChangeRegion}
                                                        id="county_name"
                                                        option={this.selectedPriority}>
                                                        <option>Select Customer Type</option>
                                                        {
                                                            this.state.priority && this.state.priority.length > 0 &&
                                                            this.state.priority.map((regionItem, i) =>
                                                                <option key={i}
                                                                    value={regionItem.name}>{regionItem.name}</option>)
                                                        }
                                                    </Form.Control>
                                                </div>
                                            </div><br />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="Focus-line" >
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Title</Form.Label>
                                                </div>
                                                <div className="col-md-10">
                                                    <Form.Control
                                                        as="select"
                                                        className="form-control input-md"
                                                        placeholder="Select Title"
                                                        value={this.state.selectedType}
                                                        onChange={this.handleChangeType}
                                                        id="county_name"
                                                        option={this.selectedType}>
                                                        <option>Select Title</option>
                                                        {
                                                            this.state.type && this.state.type.length > 0 &&
                                                            this.state.type.map((regionItem, i) =>
                                                                <option key={i}
                                                                    value={regionItem.name}>{regionItem.name}</option>)
                                                        }
                                                    </Form.Control>
                                                </div>
                                            </div>

                                        </Form.Group>
                                    </div> */}
                                    <div className="col-md-6"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Company Name</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control"
                                                        name="company_name" required placeholder='Company Name'
                                                        value={this.state.company_name} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">{BUSINESS_TYPE === 'business' ? "Customers" : "Grantors"} Display Name</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control"
                                                        name="customer_display_name" disabled placeholder={BUSINESS_TYPE === 'business' ? "Customers Display Name" : "Grantors Display Name" + " "}
                                                        value={this.state.company_name === "" ? this.state.first_name + " " + this.state.other_name : this.state.company_name} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Contact Person</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="first_name" className="form-control"
                                                        name="first_name" required placeholder='Full name'
                                                        value={this.state.first_name} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-6"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Other Names</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="first_name" className="form-control"
                                                        name="other_name" required placeholder='Other Names'
                                                        value={this.state.other_name} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-md-6"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Email Address</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="email" className="form-control"
                                                        name="email" placeholder='Email Address'
                                                        value={this.state.email} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div className="col-md-6">
                                        <br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Phone No.</label>
                                                </div>

                                                <div className="col-md-10">
                                                    <PhoneInput
                                                        flags={flags}
                                                        defaultCountry="KE"
                                                        placeholder="Enter phone number"
                                                        name="msisdn"
                                                        maxLength={15}
                                                        id="input"
                                                        value={this.state.msisdn}
                                                        onChange={value => this.setState({ msisdn: value })} />

                                                    {/* <Input
                                                        country="KE"
                                                        international
                                                        withCountryCallingCode
                                                        className="form-control"
                                                        name="msisdn"
                                                        id="input"
                                                        value={this.state.msisdn}
                                                        onChange={value => this.setState({ msisdn: value })} /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">KRA PIN</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control"
                                                        name="tax_info" placeholder='KRA PIN'
                                                        value={this.state.tax_info} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Opening Balance</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control"
                                                        name="out_of_balance" placeholder='Supplier Balance'
                                                        value={this.state.out_of_balance} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <br></br>
                                    <br />
                                    <br></br>
                                    <br />

                                    <div className="col-md-12"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Link to="/customers">
                                                        <Button outline color="primary" className="btn-sm" onClick={this._onButtonClick}>
                                                            Cancel
                                                     </Button>
                                                    </Link>

                                                </div>
                                                <div className="col-md-10">
                                                    <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                                        {this.state.isLoading ? "Please Wait..." : "Save"}

                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                    </div>
                                            </div>
                                        </div>
                                    </div>


                                </form>
                            </CardBody>
                        </Card>
                    </div>
                )
                }
            </div>
        )
    }
}
export default withTranslation('common')(CustomerCreate);
