
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Button } from 'reactstrap'
import { COMPANY_NAME, formatCurrency, LOADER, NAME, ROLE, USER_ID_ACC } from '../../_helpers/exports'
import html2PDF from 'jspdf-html2canvas'
import * as moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import { TOKEN, USER_ID } from '../../_helpers/exports';
import axios from 'axios';
import { baseURL, baseURL_2 } from '../../_helpers';

export class BalanceSheet extends React.Component {
  constructor(props) {
    super(props)
    this.onclickss = this.onclickss.bind(this);
    this.applyCallback = this.applyCallback.bind(this);


    let now = new Date();
    let start = moment(new Date(new Date().getFullYear(), 0, 1));
    let end = moment(new Date());
    this.state = {
      start: start,
      download: false,
      end: end,
      isPageLoad: true,
    }
  }


  onclickss = () => {
    // alert("Done")
    this.setState({
      download: true,
    })
    var self = this;

    const page = document.getElementById('page')
    html2PDF(page, {
      html2canvas: {
        scrollX: 0,
        scale: 10,
        imageTimeout: 15000,
        logging: true,
        scrollY: -window.scrollY,
      },
      filename: 'balance_sheet.pdf',
      watermark({ pdf, pageNumber, totalPageNumber }) {
        pdf.setTextColor('#00000');
        pdf.text(50, pdf.internal.pageSize.height - 30, `PeakInsight`);
      },
      margin: {
        top: 15,
        right: 0,
        bottom: 0,
        left: 0,
      },
      jsPDF: {
        unit: 'px',
        format: 'a4'
      },
      imageType: 'image/jpeg',
      // watermark: 'PeakInsight',
      output: 'balance_sheet.pdf',
      init: function () {

      },
      success: function (pdf) {
        pdf.save(this.output);

        self.setState({
          download: false
        })
        // alert("bayobayo")

      }
    })

  }




  componentDidMount() {
    this.setState({
      startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('DD MMM, YYYY'),
      isPageLoad: true,
      valuedate: "This Year"
    })
    this.getDataLossProfit(moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))

    this.getData(moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
  }

  getData(startDate, endDate) {
    this.setState({
      isLoading: true,
    })
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": TOKEN
      }
    };
    let url = baseURL + `pettycashdebit?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url2 = baseURL + `pettycashcredit?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url32 = baseURL + `paymentamountmade?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url3 = baseURL + `undepositeddebit?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url4 = baseURL + `undepositedcredit?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url5 = baseURL + `operationexpense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url6 = baseURL + `vendorbalance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url7 = baseURL + `getamountreceived?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url9 = baseURL + `taxpayable?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url11 = baseURL + `rembursement?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url12 = baseURL + `prepaid?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url13 = baseURL + `payablepaid?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url14 = baseURL + `furtinure?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url15 = baseURL + `invoiceamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url16 = baseURL + `custom_balance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url19 = baseURL + `furniture2?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url21 = baseURL + `getbillamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url22 = baseURL + `rembursementcredit?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url23 = baseURL + `taxamountexpense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url24 = baseURL + `invoicetaxamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url25 = baseURL + `drawings?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url26 = baseURL + `offsetbalance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url27 = baseURL + `othercurrentassets?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url28 = baseURL + `intangibleasset?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url29 = baseURL + `othernoncurrent?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url30 = baseURL + `vendorcreditreport?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url34 = baseURL + `creditvendortaxamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url35 = baseURL + `credittaxamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url36 = baseURL + `liabilities?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`


    axios.all([
      axios.get(url, axiosConfig),
      axios.get(url2, axiosConfig),
      axios.get(url32, axiosConfig),
      axios.get(url3, axiosConfig),
      axios.get(url4, axiosConfig),
      axios.get(url5, axiosConfig),
      axios.get(url6, axiosConfig),
      axios.get(url7, axiosConfig),
      axios.get(url9, axiosConfig),
      axios.get(url11, axiosConfig),
      axios.get(url12, axiosConfig),
      axios.get(url13, axiosConfig),
      axios.get(url14, axiosConfig),
      axios.get(url15, axiosConfig),
      axios.get(url16, axiosConfig),
      axios.get(url19, axiosConfig),
      axios.get(url21, axiosConfig),
      axios.get(url22, axiosConfig),
      axios.get(url23, axiosConfig),
      axios.get(url24, axiosConfig),
      axios.get(url25, axiosConfig),
      axios.get(url26, axiosConfig),
      axios.get(url27, axiosConfig),
      axios.get(url28, axiosConfig),
      axios.get(url29, axiosConfig),
      axios.get(url30, axiosConfig),
      axios.get(url34, axiosConfig),
      axios.get(url35, axiosConfig),
      axios.get(url36, axiosConfig)





    ]).then(axios.spread((
      pettydebitResponse,
      pettycreditResponse,
      pettydepositResponse,
      undepositedfunddebbitResponse,
      undepositedfundcreditResponse,
      expenseResponse,
      vendorBalance,
      amountResponse,
      payableResponse,
      rembursementResponse,
      prepaidResponse,
      prepaidpaidResponse,
      furnitureResponse,
      invoiceResponse,
      balanceResponse,
      furnitureResponse2,
      billResponse,
      rembursementCreditResponse,
      taxExpenseCreditResponse,
      invoiceTacResponse,
      drawingResponse,
      offsetbalanceResponse,
      othercurrentResponse,
      intangibleResponse,
      othernoncurrentResponse,
      vendorCreditReponse,
      vendorCreditTaxReponse,
      invoiceCreditTaxReponse,
      liabilityReponse

    ) => {
      this.setState({
        pettydebit: pettydebitResponse.data.data,
        pettycredit: pettycreditResponse.data.data,
        pettycreditpayment: pettydepositResponse.data.data,
        undepositeddebit: undepositedfunddebbitResponse.data.data,
        undepositedcredit: undepositedfundcreditResponse.data.data,
        expense: expenseResponse.data.data,
        vendor: vendorBalance.data.data,
        amount: amountResponse.data.data,
        payable: payableResponse.data.data,
        rembursement: rembursementResponse.data.data,
        prepaid: prepaidResponse.data.data,
        payablepaid: prepaidpaidResponse.data.data,
        furniture: furnitureResponse.data.data,
        invoice: invoiceResponse.data.data,
        balance: balanceResponse.data.data,
        furniture2: furnitureResponse2.data.data,
        bills: billResponse.data.data,
        rembursementcredit: rembursementCreditResponse.data.data,
        expensetax: taxExpenseCreditResponse.data.data,
        invoicetax: invoiceTacResponse.data.data,
        drawings: drawingResponse.data.data,
        offsetbalance: offsetbalanceResponse.data.data,
        otherassets: othercurrentResponse.data.data,
        intangibleassets: intangibleResponse.data.data,
        othernonassets: othernoncurrentResponse.data.data,
        vendorcredit: vendorCreditReponse.data.data,
        vendortaxcredit: vendorCreditTaxReponse.data.data,
        invoicetaxcredit: invoiceCreditTaxReponse.data.data,
        liability: liabilityReponse.data.data,

        isLoading: false
      }, function () {
        var data = [];
        // let editedInvoice = [];
        // alert(JSON.stringify(vendorCreditReponse.data.data))
        this.setState({
          isPageLoad: false,
        })





        if (this.state.liability.length === 0) {
          this.setState({
            total_liablity: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.liability.length; j++) {
            let a;
            if (this.state.liability[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.liability[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            total_liablity: valueAddedExpenses,
          })
        }




        if (this.state.vendortaxcredit.length === 0) {
          this.setState({
            tax_vendor_credit: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.vendortaxcredit.length; j++) {
            let a;
            if (this.state.vendortaxcredit[j].tax_amount === null) {
              a = 0;
            } else {
              let str1 = this.state.vendortaxcredit[j].tax_amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            tax_vendor_credit: valueAddedExpenses,
          })
        }


        if (this.state.invoicetaxcredit.length === 0) {
          this.setState({
            tax_credit_note: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.invoicetaxcredit.length; j++) {
            let a;
            if (this.state.invoicetaxcredit[j].tax_amount === null) {
              a = 0;
            } else {
              let str1 = this.state.invoicetaxcredit[j].tax_amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            tax_credit_note: valueAddedExpenses,
          })
        }


        if (this.state.intangibleassets.length === 0) {
          this.setState({
            intangible_cost: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.intangibleassets.length; j++) {
            let a;
            if (this.state.intangibleassets[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.intangibleassets[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            intangible_cost: valueAddedExpenses,
          })
        }



        if (this.state.othernonassets.length === 0) {
          this.setState({
            non_asset_cost: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.othernonassets.length; j++) {
            let a;
            if (this.state.othernonassets[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.othernonassets[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            non_asset_cost: valueAddedExpenses,
          })
        }





        if (this.state.otherassets.length === 0) {
          this.setState({
            other_current_asset: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.otherassets.length; j++) {
            let a;
            if (this.state.otherassets[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.otherassets[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            other_current_asset: valueAddedExpenses,
          })
        }


        if (this.state.offsetbalance.length === 0) {
          this.setState({
            offsetbalance_cost: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.offsetbalance.length; j++) {
            let a;
            if (this.state.offsetbalance[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.offsetbalance[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            offsetbalance_cost: valueAddedExpenses,
          })
        }


        if (this.state.drawings.length === 0) {
          this.setState({
            drawing_cost: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.drawings.length; j++) {
            let a;
            if (this.state.drawings[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.drawings[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            drawing_cost: valueAddedExpenses,
          })
        }


        if (this.state.furniture.length === 0) {
          this.setState({
            furniture_cost: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.furniture.length; j++) {
            let a;
            if (this.state.furniture[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.furniture[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            furniture_cost: valueAddedExpenses,
          })
        }



        if (this.state.furniture2.length === 0) {
          this.setState({
            furniture_cost2: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.furniture2.length; j++) {
            let a;
            if (this.state.furniture2[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.furniture2[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            furniture_cost2: valueAddedExpenses,
          })
        }



        if (this.state.pettycredit.length === 0) {
          this.setState({
            petty_credit: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.pettycredit.length; j++) {
            let a;
            if (this.state.pettycredit[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.pettycredit[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            petty_credit: valueAddedExpenses,
          })
        }

        if (this.state.pettydebit.length === 0) {
          this.setState({
            petty_debit: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.pettydebit.length; j++) {
            let a;
            if (this.state.pettydebit[j].amount_received === null) {
              a = 0;
            } else {
              let str1 = this.state.pettydebit[j].amount_received.toString();
              console.log("bayo2", str1)
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            petty_debit: valueAddedExpenses,
          })
        }


        if (this.state.pettycreditpayment.length === 0) {
          this.setState({
            petty_paymenymade: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.pettycreditpayment.length; j++) {
            let a;
            if (this.state.pettycreditpayment[j].amount === null) {
              a = 0;
            } else {

              let str1 = this.state.pettycreditpayment[j].amount;
              console.log("bayo2", str1)
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            petty_paymenymade: valueAddedExpenses,
          })
        }



        if (this.state.invoice.length === 0 && this.state.balance.length === 0) {
          this.setState({
            operating_cost: 0,
          })
        }
        else {
          let valueAdded = 0
          let count = 0
          let valueAdded1 = 0
          let count1 = 0
          let valueOutofBalance = 0
          let countOutofBalance = 0

          for (let i = 0; i < this.state.invoice.length; i++) {
            let str = this.state.invoice[i].amount;
            count = parseFloat(str.replaceAll(",", ""))
            valueAdded += count
          }
          for (let j = 0; j < this.state.balance.length; j++) {
            let b;
            if (this.state.balance[j].out_of_balance === null) {
              b = 0;
            } else {
              let str1 = this.state.balance[j].out_of_balance.toString();
              countOutofBalance = parseFloat(str1.replaceAll(",", ""))
              valueOutofBalance += countOutofBalance
            }
          }
          this.setState({
            operating_cost: (valueAdded + valueOutofBalance),
          })
        }





        if (this.state.undepositedcredit.length === 0) {
          this.setState({
            undeposited_credit: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.undepositedcredit.length; j++) {
            let a;
            if (this.state.undepositedcredit[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.undepositedcredit[j].amount.toString();
              console.log("bayo2", str1)
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            undeposited_credit: valueAddedExpenses,
          })
        }

        if (this.state.undepositeddebit.length === 0) {
          this.setState({
            undeposited_debit: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.undepositeddebit.length; j++) {
            let a;
            if (this.state.undepositeddebit[j].amount_received === null) {
              a = 0;
            } else {
              let str1 = this.state.undepositeddebit[j].amount_received.toString();
              console.log("bayo2", str1)
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            undeposited_debit: valueAddedExpenses,
          })
        }


        if (this.state.expense.length === 0) {
          this.setState({
            cost_of_operation: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.expense.length; j++) {
            let a;
            if (this.state.expense[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.expense[j].amount.toString();
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
          }
          this.setState({
            cost_of_operation: valueAddedExpenses,
          })
        }

        if (this.state.vendor.length === 0 || this.state.bills.length === 0 || this.state.vendorcredit.length === 0 || this.state.pettycreditpayment.length === 0) {
          this.setState({
            vendor_balance: 0,
          })
        }
        else {
          let valueAddedVendor = 0
          let countVendor = 0
          let valueBillsBalance = 0
          let countBillsBalance = 0
          let valueVendorCredit = 0
          let countVendorCredit = 0

          let valueAddedExpenses = 0
          let countExpense = 0



          for (let i = 0; i < this.state.bills.length; i++) {
            let str = this.state.bills[i].amount;
            countBillsBalance = parseFloat(str.replaceAll(",", ""))
            valueBillsBalance += countBillsBalance


          }


          for (let i = 0; i < this.state.vendorcredit.length; i++) {
            let str = this.state.vendorcredit[i].due_amount;
            countVendorCredit = parseFloat(str.replaceAll(",", ""))
            valueVendorCredit += countVendorCredit


          }
          for (let j = 0; j < this.state.vendor.length; j++) {
            let a;
            if (this.state.vendor[j].opening_balance === null) {
              a = 0;
            } else {
              let str1 = this.state.vendor[j].opening_balance.toString();
              countVendor = parseFloat(str1.replaceAll(",", ""))
              valueAddedVendor += countVendor

            }
          }


          for (let j = 0; j < this.state.pettycreditpayment.length; j++) {
            let a;
            if (this.state.pettycreditpayment[j].amount === null) {
              a = 0;
            } else {

              let str1 = this.state.pettycreditpayment[j].amount;
              console.log("bayo2", str1)
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
          }
          this.setState({
            vendor_balance: ((valueAddedExpenses + valueVendorCredit) - (valueBillsBalance + valueAddedVendor)),
          })


        }


        if (this.state.amount.length === 0) {
          this.setState({
            amount_received: 0.00,
          })
        }
        else {
          let valueAddedAmount = 0
          let countAmount = 0
          for (let j = 0; j < this.state.amount.length; j++) {
            let a;
            if (this.state.amount[j].amount_received === null) {
              a = 0;
            } else {
              let str1 = this.state.amount[j].amount_received;
              valueAddedAmount += countAmount
            }
          }
          this.setState({
            amount_received: valueAddedAmount,
          })
        }





        if (this.state.payable.length === 0) {
          this.setState({
            payable_tax: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.payable.length; j++) {
            let a;
            if (this.state.payable[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.payable[j].amount.toString();
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
          }
          this.setState({
            payable_tax: valueAddedExpenses,
          })
        }

        if (this.state.expensetax.length === 0) {
          this.setState({
            expense_tax_amount: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.expensetax.length; j++) {
            let a;
            if (this.state.expensetax[j].tax_amount === null) {
              a = 0;
            } else {
              let str1 = this.state.expensetax[j].tax_amount.toString();
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense

            }
          }
          this.setState({
            expense_tax_amount: valueAddedExpenses,
          })

        }



        if (this.state.invoicetax.length === 0) {
          this.setState({
            invoice_tax_amount: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.invoicetax.length; j++) {
            let a;
            if (this.state.invoicetax[j].tax_amount === null) {
              a = 0;
            } else {
              let str1 = this.state.invoicetax[j].tax_amount.toString();
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
          }
          this.setState({
            invoice_tax_amount: valueAddedExpenses,
          })
        }






        if (this.state.rembursement.length === 0) {
          this.setState({
            rembursement_cost: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.rembursement.length; j++) {
            let a;
            if (this.state.rembursement[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.rembursement[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)


          }
          this.setState({
            rembursement_cost: valueAddedExpenses,
          })
        }


        if (this.state.rembursementcredit.length === 0) {
          this.setState({
            rembursement_credit_cost: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.rembursementcredit.length; j++) {
            let a;
            if (this.state.rembursementcredit[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.rembursementcredit[j].amount.toString();
              console.log("bayo2", str1)
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            rembursement_credit_cost: valueAddedExpenses,
          })
        }

        if (this.state.prepaid.length === 0) {
          this.setState({
            prepaid_cost: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.prepaid.length; j++) {
            let a;
            if (this.state.prepaid[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.prepaid[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)


          }
          this.setState({
            prepaid_cost: valueAddedExpenses,
          })
        }




        if (this.state.payablepaid.length === 0) {
          this.setState({
            payablepaid_cost: 0,
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.payablepaid.length; j++) {
            let a;
            if (this.state.payablepaid[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.payablepaid[j].amount.toString();
              console.log("bayo2", str1)
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
            // console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            payablepaid_cost: valueAddedExpenses,
            isPageLoad: false
          })
        }

        this.setState({
          gross: (this.state.operating_cost - this.state.cost_of_goods),
        })

      })
    })).catch(error => {
      // alert(JSON.stringify(error))
      console.log('bayoo', error)
    })
  }

  applyCallback(startDate, endDate) {
    // localStorage.setItem("STARTDATE", startDate)
    // localStorage.setItem("ENDDATE", endDate)
    let Sdate1 = moment(startDate).format('DD MMM, YYYY');
    let Edate2 = moment(endDate).format('DD MMM, YYYY');
    this.setState({
      valuedate: Sdate1 + " " + Edate2,
      startDate: Sdate1,
      isPageLoad: true,
      endDate: Edate2
    })
    this.getDataLossProfit(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    this.getData(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
  }


  // getDataLossProfit(startDate, endDate) {

  // }

  getDataLossProfit(startDate, endDate) {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": TOKEN
      }
    };
    let url = baseURL + `invoiceamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url2 = baseURL + `custom_balance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url3 = baseURL + `getbillamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url4 = baseURL + `expensesamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url5 = baseURL + `operationexpense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url6 = baseURL + `vendorcreditreport?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`

    axios.all([
      axios.get(url, axiosConfig),
      axios.get(url2, axiosConfig),
      axios.get(url3, axiosConfig),
      axios.get(url4, axiosConfig),
      axios.get(url5, axiosConfig),
      axios.get(url6, axiosConfig),

    ]).then(axios.spread((invoiceResponse, balanceResponse, billResponse, goodsResponse, expenseResponse, vendorCreditReponse) => {
      // console.log("balance", balanceResponse)
      this.setState({
        invoice1: invoiceResponse.data.data,
        balance1: balanceResponse.data.data,
        bills1: billResponse.data.data,
        goods1: goodsResponse.data.data,
        expense1: expenseResponse.data.data,
        vendorcredit1: vendorCreditReponse.data.data,
      }, function () {
        var data = [];
        // let editedInvoice = [];

        if (this.state.invoice1.length === 0 && this.state.balance1.length === 0) {
          this.setState({
            operating_cost1: "0.00",
          })
        }
        else {
          let valueAdded = 0
          let count = 0
          let valueAdded1 = 0
          let count1 = 0
          let valueOutofBalance = 0
          let countOutofBalance = 0

          for (let i = 0; i < this.state.invoice1.length; i++) {
            let str = this.state.invoice1[i].amount;
            count = parseFloat(str.replaceAll(",", ""))
            valueAdded += count
          }
          for (let j = 0; j < this.state.balance1.length; j++) {
            let b;
            if (this.state.balance1[j].out_of_balance === null) {
              b = 0;
            } else {
              let str1 = this.state.balance1[j].out_of_balance.toString();
              countOutofBalance = parseFloat(str1.replaceAll(",", ""))
              valueOutofBalance += countOutofBalance
            }
          }
          this.setState({
            operating_cost1: (countOutofBalance + valueAdded),
          })
        }


        if (this.state.bills1.length === 0 && this.state.goods1.length === 0 && this.state.vendorcredit1.length === 0) {

          this.setState({
            cost_of_goods1: "0.00",
          })
        }

        else {
          let valueAddedGoods = 0
          let countGoods = 0
          let valueBillsBalance = 0
          let countBillsBalance = 0
          let valueVendorCredit = 0
          let countVendorCredit = 0

          for (let i = 0; i < this.state.bills1.length; i++) {
            let str = this.state.bills1[i].amount.toString();
            countBillsBalance = parseFloat(str.replaceAll(",", ""))
            valueBillsBalance += countBillsBalance
          }

          for (let i = 0; i < this.state.vendorcredit1.length; i++) {
            let str = this.state.vendorcredit1[i].due_amount;
            countVendorCredit = parseFloat(str.replaceAll(",", ""))
            valueVendorCredit += countVendorCredit
          }
          for (let j = 0; j < this.state.goods1.length; j++) {
            let str1
            if (this.state.goods1[j].amount === null) {
              valueAddedGoods = 0;
            } else {
              let str1 = this.state.goods1[j].amount.toString();
              countGoods = parseFloat(str1.replaceAll(",", ""))
              valueAddedGoods += countGoods
            }
          }
          console.log('bayoo', (valueAddedGoods + valueBillsBalance))

          this.setState({
            cost_of_goods1: ((valueBillsBalance + valueAddedGoods) - (valueVendorCredit)),
          })
        }

        if (this.state.expense1.length === 0) {

          this.setState({
            cost_of_operation1: "0.00",
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.expense1.length; j++) {
            let a;
            if (this.state.expense1[j].amount == null) {
              a = 0;
            } else {
              let str2 = this.state.expense1[j].amount.toString()

              // alert(this.state.expense[].amount)
              countExpense = parseFloat(str2.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
          }
          this.setState({
            cost_of_operation1: valueAddedExpenses,
          })
        }

      }, function () {

        let netcashflow;


        // this.setState({
        //   netcashflow: netcashflow
        // })

        // alert(netcashflow)



      })
    })).catch(error => {
      console.log('bayoo', error.response)
    })
  }


  render() {
    let ranges = {
      "Today Only": [moment(this.state.start), moment(this.state.end)],
      "Yesterday Only": [
        moment(this.state.start).subtract(1, "days"),
        moment(this.state.end).subtract(1, "days")
      ],
      "3 Days": [moment(this.state.start).subtract(3, "days"), moment(this.state.end)],
      "5 Days": [moment(this.state.start).subtract(5, "days"), moment(this.state.end)],
      "1 Week": [moment(this.state.start).subtract(7, "days"), moment(this.state.end)],
      "2 Weeks": [moment(this.state.start).subtract(14, "days"), moment(this.state.end)],
      "1 Month": [moment(this.state.start).subtract(1, "months"), moment(this.state.end)],
      "1 Year": [moment(this.state.start).subtract(1, "years"), moment(this.state.end)]
    };


    let local = {
      "format": "DD-MM-YYYY",
      "sundayFirst": false
    }
    return (
      <div style={{ marginTop: "-20px" }} >
        {this.state.isPageLoad ?
          <div className='d-flex justify-content-center align-items-center'>
            <div className="loader">
              {LOADER}
            </div>
          </div>
          :

          <Card>

            {this.state.download ? <div className='d-flex justify-content-center align-items-center'>
              <div className="loader">
                {LOADER}
              </div>
            </div> :
              <CardBody>
                <div className='col-12'>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-6">
                            <DateTimeRangeContainer
                              ranges={ranges}
                              start={this.state.start}
                              end={this.state.end}
                              local={local}
                              applyCallback={this.applyCallback}>
                              <FormControl
                                id="formControlsTextB"
                                type="text"
                                value={this.state.valuedate}
                                label="Text"
                                placeholder="Filter by Date"
                              />
                            </DateTimeRangeContainer>
                          </div>
                          <div className="col-6 float-left">

                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {
                              // this.getData();
                              this.getData(moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                              this.getDataLossProfit(moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))

                              this.setState({
                                startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
                                isPageLoad: true,
                                endDate: moment(new Date()).format('DD MMM, YYYY'),
                                valuedate: "This Year"
                              })
                            }} >Clear</button>
                            {/* <input id="input" type="text" readOnly /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pull-right">
                      <button className="float-right btn btn-primary btn-sm" onClick={() => this.onclickss()}>Export PDF</button>
                    </div>
                  </div>
                </div>

                <div id='page'>
                  <br />

                  <div className='text-center' p='3'>
                    <h4>{COMPANY_NAME}</h4>
                    <h3><strong>Balance Sheet</strong></h3>
                    <h4>Basis: Accrual</h4>
                    <h4>Date</h4>
                    <h4>From {this.state.startDate} To {this.state.endDate}</h4>

                    <br /><br /><br />
                  </div>

                  <div className='row col-8 offset-2'>

                    <div className='col-5'>
                      <hr style={{ marginTop: '0px' }} />
                      <h4>Account</h4>
                      <hr style={{ marginTop: '0px' }} />

                      <h4>ASSETS</h4><br />
                      <h4>Current Assets	</h4><br />

                      <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> Cash and Bank Balances</h4>


                      <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> Accounts Receivable</h4>

                      <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> Closing Stock</h4>

                      <br />

                      <h4>Other Current Assets</h4>
                      <hr style={{ marginTop: '0px' }} />
                      <br />

                      <h4>Non Current Assets	</h4><br />
                      <h4 style={{ color: "#9BDDFF" }}> Property Furniture and Equipment</h4><br />
                      <h4 style={{ color: "#9BDDFF" }}>Intangible Assets</h4><br />

                      <h4>Other Non-Current Assets</h4>
                      <hr style={{ marginTop: '0px' }} />
                      <br />



                      <br /><br />
                      <h4>LIABILITIES & EQUITIES</h4><br />

                      <h4>Liabilities </h4><br />
                      <h4>Current Liabilities		</h4><br />

                      <h4 style={{ color: "#9BDDFF" }}><div> Accounts Payable</div></h4><br />

                      <h4 style={{ color: "#9BDDFF" }}><div> Short term loans</div></h4><br />

                      <h4 style={{ color: "#9BDDFF" }}><div> Short term related parties</div></h4><br />

                      <h4 style={{ color: "#9BDDFF" }}><div>Tax Payable</div></h4><br />

                      <h4 ><div>Other current Liabilities</div></h4><br />


                      <h4>Non Current Liabilities		</h4><br />

                      <h4 style={{ color: "#9BDDFF" }}><div> Long term loans</div></h4><br />

                      <h4 style={{ color: "#9BDDFF" }}><div> Long term related parties</div></h4><br />

                      <h4 ><div>Other non current Liabilities</div></h4><br />

                      <br />

                      <h4 style={{ marginTop: '6px' }}> <b>Total Liabilities</b></h4>
                      <hr style={{ marginTop: '0px' }} />
                      <br />
                      <h4>Equities</h4><br />
                      <h4><> Share Capital</></h4><br />

                      <h4><Link to="/profitloss">Retained Earnings</Link></h4><br />
                      <h4><> Direct Account </></h4><br />

                      <h4><div> Other Equities</div></h4><br />
                      <h4>Total Equities </h4>
                      <hr style={{ marginTop: '0px' }} />

                    </div>
                    <div className='col-7 text-right'>
                      <hr style={{ marginTop: '0px' }} />
                      <h4><strong>Total</strong></h4>
                      <hr style={{ marginTop: '0px' }} />
                      <h4 style={{ visibility: 'hidden' }}>space</h4><br />
                      <h4 style={{ visibility: 'hidden' }}>space</h4>
                      <br />
                      <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}>
                        {formatCurrency(parseFloat(this.state.petty_debit)
                          -

                          (parseFloat(this.state.petty_credit) +
                            parseFloat(this.state.petty_paymenymade)))}
                      </h4>


                      <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}>

                        {formatCurrency(parseFloat(this.state.operating_cost)

                          - parseFloat(this.state.amount_received))}</h4>

                      <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}>

                        {
                          formatCurrency(
                            parseFloat(this.state.undeposited_debit)
                            -
                            parseFloat(this.state.undeposited_credit))}</h4>

                      <br />


                      <h4 style={{ color: "#9BDDFF" }}>
                        {
                          formatCurrency(
                            parseFloat(this.state.other_current_asset)
                          )}</h4>


                      <hr style={{ marginTop: '0px' }} />
                      <br />
                      <h4 style={{ visibility: 'hidden' }}>space</h4>
                      <br />

                      <h4 style={{ color: "#9BDDFF" }}> {formatCurrency((parseFloat(this.state.furniture_cost)
                        - parseFloat(this.state.furniture_cost2)))}</h4><br />
                      <h4 style={{ color: "#9BDDFF" }}> {formatCurrency(parseFloat(this.state.intangible_cost))}</h4><br />
                      <h4 style={{ color: "" }}> {formatCurrency(parseFloat(this.state.non_asset_cost))}</h4>
                      <hr style={{ marginTop: '0px' }} />
                      <br />
                      <br />
                      <h4><strong>TOTAL ASSETS</strong><i style={{ visibility: 'hidden' }}>______________</i>

                        {
                          formatCurrency(
                            (parseFloat(this.state.petty_debit) -
                              (parseFloat(this.state.petty_credit) +
                                parseFloat(this.state.petty_paymenymade)))
                            +
                            (parseFloat(this.state.undeposited_debit) - parseFloat(this.state.undeposited_credit))
                            +
                            (parseFloat(this.state.operating_cost) - parseFloat(this.state.amount_received))
                            +
                            (parseFloat(this.state.furniture_cost) - parseFloat(this.state.furniture_cost2))
                            +
                            (parseFloat(this.state.other_current_asset))
                            +
                            (parseFloat(this.state.intangible_cost))
                            +
                            (parseFloat(this.state.non_asset_cost))
                          )
                        }

                      </h4>

                      <h4 style={{ visibility: 'hidden' }}>space</h4><br />
                      <h4 style={{ visibility: 'hidden' }}>space</h4> <br />
                      <h4 style={{ visibility: 'hidden' }}>space</h4><br />

                      <h4 style={{ color: "#9BDDFF" }}><div>{formatCurrency(this.state.vendor_balance)}</div></h4><br />

                      <h4 style={{ color: "#9BDDFF" }}><div>{formatCurrency(parseFloat(this.state.liability[0].amount))}</div></h4><br />

                      <h4 style={{ color: "#9BDDFF" }}>{formatCurrency(parseFloat(this.state.liability[2].amount))}</h4><br />

                      <h4 style={{ color: "#9BDDFF" }}><div>{

                        formatCurrency(

                          (parseFloat(this.state.payable_tax)
                            +
                            parseFloat(this.state.expense_tax_amount)
                            +
                            parseFloat(this.state.tax_vendor_credit)
                          )
                          -
                          (
                            parseFloat(this.state.invoice_tax_amount)
                            +
                            parseFloat(this.state.tax_credit_note)
                          )

                        )}</div></h4>

                      <br />

                      <h4>{formatCurrency(parseFloat(this.state.liability[4].amount))}</h4>
                      <hr style={{ marginTop: '0px' }} />
                      <h4 style={{ visibility: 'hidden' }}>space</h4>
                      <h4 style={{ visibility: 'hidden' }}>space</h4>

                      <h4 style={{ color: "#9BDDFF" }}><div>{formatCurrency(parseFloat(this.state.liability[1].amount))}</div></h4><br />
                      <h4 style={{ color: "#9BDDFF" }}><div>{formatCurrency(parseFloat(this.state.liability[3].amount))}</div></h4><br />
                      <h4 ><div> {formatCurrency(parseFloat(this.state.liability[5].amount))}</div></h4>
                      <hr style={{ marginTop: '0px' }} />

                      <h4 style={{ visibility: 'hidden' }}>space</h4>

                      <h4>{
                        formatCurrency(
                          parseFloat(this.state.vendor_balance)
                          +
                          parseFloat(this.state.liability[0].amount)
                          +
                          parseFloat(this.state.liability[2].amount)
                          +
                          (
                            (parseFloat(this.state.payable_tax)
                              +
                              parseFloat(this.state.expense_tax_amount)
                              +
                              parseFloat(this.state.tax_vendor_credit)
                            )
                            -
                            (
                              parseFloat(this.state.invoice_tax_amount)
                              +
                              parseFloat(this.state.tax_credit_note)
                            )
                          )
                          +
                          parseFloat(this.state.liability[4].amount)
                          +
                          parseFloat(this.state.liability[1].amount)
                          +
                          parseFloat(this.state.liability[3].amount)
                          +
                          parseFloat(this.state.liability[5].amount)

                        )
                      }

                      </h4>

                      <hr style={{ marginTop: '0px' }} />
                      <h4 style={{ visibility: 'hidden' }}>space</h4>
                      <br /><br />
                      <h4>< >{formatCurrency(parseFloat(this.state.offsetbalance_cost))}</></h4><br />

                      <h4><Link to="/profitloss">
                        {
                          ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1))) - parseFloat(this.state.cost_of_operation1)) < 0 ?

                            (formatCurrency((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1)))
                              - parseFloat(this.state.cost_of_operation1)))
                            :
                            formatCurrency(
                              (
                                (((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1)))
                                  - parseFloat(this.state.cost_of_operation1))
                                  -
                                  (0.3 * ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1)))
                                    - parseFloat(this.state.cost_of_operation1))))
                              )
                            )
                        }
                      </Link></h4><br />
                      <h4>< >{formatCurrency(parseFloat(this.state.drawing_cost))}</></h4><br />

                      <h4><div>{


                        ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1))) - parseFloat(this.state.cost_of_operation1)) < 0 ?
                          formatCurrency(
                            (
                              (parseFloat(this.state.petty_debit) -
                                (parseFloat(this.state.petty_credit) +
                                  parseFloat(this.state.petty_paymenymade)))
                              +
                              (parseFloat(this.state.undeposited_debit) - parseFloat(this.state.undeposited_credit))
                              +
                              (parseFloat(this.state.operating_cost) - parseFloat(this.state.amount_received))
                              +
                              (parseFloat(this.state.furniture_cost) - parseFloat(this.state.furniture_cost2))
                              +
                              (parseFloat(this.state.other_current_asset))
                              +
                              (parseFloat(this.state.intangible_cost))
                              +
                              (parseFloat(this.state.non_asset_cost))
                            )
                            -
                            (
                              (
                                parseFloat(this.state.vendor_balance)
                                +
                                parseFloat(this.state.liability[0].amount)
                                +
                                parseFloat(this.state.liability[2].amount)
                                +
                                (
                                  (parseFloat(this.state.payable_tax)
                                    +
                                    parseFloat(this.state.expense_tax_amount)
                                    +
                                    parseFloat(this.state.tax_vendor_credit)
                                  )
                                  -
                                  (
                                    parseFloat(this.state.invoice_tax_amount)
                                    +
                                    parseFloat(this.state.tax_credit_note)
                                  )
                                )
                                +
                                parseFloat(this.state.liability[4].amount)
                                +
                                parseFloat(this.state.liability[1].amount)
                                +
                                parseFloat(this.state.liability[3].amount)
                                +
                                parseFloat(this.state.liability[5].amount)
                              )
                              +
                              ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1))
                                - parseFloat(this.state.cost_of_operation1))
                                +

                                parseFloat(this.state.drawing_cost)

                                +

                                parseFloat(this.state.offsetbalance_cost)
                              )
                            )
                          )
                          :
                          formatCurrency(

                            (
                              (parseFloat(this.state.petty_debit) -
                                (parseFloat(this.state.petty_credit) +
                                  parseFloat(this.state.petty_paymenymade)))
                              +
                              (parseFloat(this.state.undeposited_debit) - parseFloat(this.state.undeposited_credit))
                              +
                              (parseFloat(this.state.operating_cost) - parseFloat(this.state.amount_received))
                              +
                              (parseFloat(this.state.furniture_cost) - parseFloat(this.state.furniture_cost2))
                              +
                              (parseFloat(this.state.other_current_asset))
                              +
                              (parseFloat(this.state.intangible_cost))
                              +
                              (parseFloat(this.state.non_asset_cost))
                            )
                            -
                            (
                              (
                                parseFloat(this.state.vendor_balance)
                                +
                                parseFloat(this.state.liability[0].amount)
                                +
                                parseFloat(this.state.liability[2].amount)
                                +
                                (
                                  (parseFloat(this.state.payable_tax)
                                    +
                                    parseFloat(this.state.expense_tax_amount)
                                    +
                                    parseFloat(this.state.tax_vendor_credit)
                                  )
                                  -
                                  (
                                    parseFloat(this.state.invoice_tax_amount)
                                    +
                                    parseFloat(this.state.tax_credit_note)
                                  )
                                )
                                +
                                parseFloat(this.state.liability[4].amount)
                                +
                                parseFloat(this.state.liability[1].amount)
                                +
                                parseFloat(this.state.liability[3].amount)
                                +
                                parseFloat(this.state.liability[5].amount)

                              )
                              +
                              (
                                (((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1)))
                                  - parseFloat(this.state.cost_of_operation1))
                                  -
                                  (0.3 * ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1)))
                                    - parseFloat(this.state.cost_of_operation1))))
                                +
                                parseFloat(this.state.drawing_cost)
                                +
                                parseFloat(this.state.offsetbalance_cost)
                              )
                            )
                          )
                      }</div></h4><br />

                      <h4>

                        {


                          ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1))) - parseFloat(this.state.cost_of_operation1)) < 0 ?

                            formatCurrency
                              (
                                (
                                  (
                                    (parseFloat(this.state.petty_debit) -
                                      (parseFloat(this.state.petty_credit) +
                                        parseFloat(this.state.petty_paymenymade)))
                                    +
                                    (parseFloat(this.state.undeposited_debit) - parseFloat(this.state.undeposited_credit))
                                    +
                                    (parseFloat(this.state.operating_cost) - parseFloat(this.state.amount_received))
                                    +
                                    (parseFloat(this.state.furniture_cost) - parseFloat(this.state.furniture_cost2))
                                    +
                                    (parseFloat(this.state.other_current_asset))
                                    +
                                    (parseFloat(this.state.intangible_cost))
                                    +
                                    (parseFloat(this.state.non_asset_cost))
                                  )
                                  -
                                  (
                                    (
                                      parseFloat(this.state.vendor_balance)
                                      +
                                      parseFloat(this.state.liability[0].amount)
                                      +
                                      parseFloat(this.state.liability[2].amount)
                                      +
                                      (
                                        (parseFloat(this.state.payable_tax)
                                          +
                                          parseFloat(this.state.expense_tax_amount)
                                          +
                                          parseFloat(this.state.tax_vendor_credit)
                                        )
                                        -
                                        (
                                          parseFloat(this.state.invoice_tax_amount)
                                          +
                                          parseFloat(this.state.tax_credit_note)
                                        )
                                      )
                                      +
                                      parseFloat(this.state.liability[4].amount)
                                      +
                                      parseFloat(this.state.liability[1].amount)
                                      +
                                      parseFloat(this.state.liability[3].amount)
                                      +
                                      parseFloat(this.state.liability[5].amount)
                                    )
                                    +
                                    ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1))
                                      - parseFloat(this.state.cost_of_operation1))
                                      +

                                      parseFloat(this.state.drawing_cost)

                                      +

                                      parseFloat(this.state.offsetbalance_cost)
                                    )
                                  )
                                )
                                +
                                ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1))
                                  - parseFloat(this.state.cost_of_operation1))
                                  +
                                  parseFloat(this.state.drawing_cost)
                                  +
                                  parseFloat(this.state.offsetbalance_cost)
                                )
                                // +
                                // parseFloat(this.state.drawing_cost)
                                // +
                                // parseFloat(this.state.offsetbalance_cost)
                                // )
                              )
                            :
                            formatCurrency(
                              (
                                (
                                  (
                                    (parseFloat(this.state.petty_debit) -
                                      (parseFloat(this.state.petty_credit) +
                                        parseFloat(this.state.petty_paymenymade)))
                                    +
                                    (parseFloat(this.state.undeposited_debit) - parseFloat(this.state.undeposited_credit))
                                    +
                                    (parseFloat(this.state.operating_cost) - parseFloat(this.state.amount_received))
                                    +
                                    (parseFloat(this.state.furniture_cost) - parseFloat(this.state.furniture_cost2))
                                    +
                                    (parseFloat(this.state.other_current_asset))
                                    +
                                    (parseFloat(this.state.intangible_cost))
                                    +
                                    (parseFloat(this.state.non_asset_cost))
                                  )
                                  -
                                  (
                                    (
                                      parseFloat(this.state.vendor_balance)
                                      +
                                      parseFloat(this.state.liability[0].amount)
                                      +
                                      parseFloat(this.state.liability[2].amount)
                                      +
                                      (
                                        (parseFloat(this.state.payable_tax)
                                          +
                                          parseFloat(this.state.expense_tax_amount)
                                          +
                                          parseFloat(this.state.tax_vendor_credit)
                                        )
                                        -
                                        (
                                          parseFloat(this.state.invoice_tax_amount)
                                          +
                                          parseFloat(this.state.tax_credit_note)
                                        )
                                      )
                                      +
                                      parseFloat(this.state.liability[4].amount)
                                      +
                                      parseFloat(this.state.liability[1].amount)
                                      +
                                      parseFloat(this.state.liability[3].amount)
                                      +
                                      parseFloat(this.state.liability[5].amount)

                                    )
                                    +
                                    (
                                      (((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1)))
                                        - parseFloat(this.state.cost_of_operation1))
                                        -
                                        (0.3 * ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1)))
                                          - parseFloat(this.state.cost_of_operation1))))
                                      +
                                      parseFloat(this.state.drawing_cost)
                                      +
                                      parseFloat(this.state.offsetbalance_cost)
                                    )
                                  )
                                )
                                +
                                (((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1)))
                                  - parseFloat(this.state.cost_of_operation1))
                                  -
                                  (0.3 * ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1)))
                                    - parseFloat(this.state.cost_of_operation1))))
                                +
                                parseFloat(this.state.drawing_cost)
                                +
                                parseFloat(this.state.offsetbalance_cost)
                              )
                            )
                        }



                      </h4>
                      <hr style={{ marginTop: '0px' }} />
                      <h4><strong>TOTAL LIABILITIES & EQUITIES</strong><i style={{ visibility: 'hidden' }}>______________</i>

                        {
                          ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1))) - parseFloat(this.state.cost_of_operation1)) < 0 ?

                            formatCurrency(

                              (
                                parseFloat(this.state.vendor_balance)
                                +
                                parseFloat(this.state.liability[0].amount)
                                +
                                parseFloat(this.state.liability[2].amount)
                                +
                                (
                                  (parseFloat(this.state.payable_tax)
                                    +
                                    parseFloat(this.state.expense_tax_amount)
                                    +
                                    parseFloat(this.state.tax_vendor_credit)
                                  )
                                  -
                                  (
                                    parseFloat(this.state.invoice_tax_amount)
                                    +
                                    parseFloat(this.state.tax_credit_note)
                                  )
                                )
                                +
                                parseFloat(this.state.liability[4].amount)
                                +
                                parseFloat(this.state.liability[1].amount)
                                +
                                parseFloat(this.state.liability[3].amount)
                                +
                                parseFloat(this.state.liability[5].amount)

                              )
                              +
                              (
                                (
                                  (parseFloat(this.state.petty_debit) -
                                    (parseFloat(this.state.petty_credit) +
                                      parseFloat(this.state.petty_paymenymade)))
                                  +
                                  (parseFloat(this.state.undeposited_debit) - parseFloat(this.state.undeposited_credit))
                                  +
                                  (parseFloat(this.state.operating_cost) - parseFloat(this.state.amount_received))
                                  +
                                  (parseFloat(this.state.furniture_cost) - parseFloat(this.state.furniture_cost2))
                                  +
                                  (parseFloat(this.state.other_current_asset))
                                  +
                                  (parseFloat(this.state.intangible_cost))
                                  +
                                  (parseFloat(this.state.non_asset_cost))
                                )
                                -
                                (
                                  (
                                    parseFloat(this.state.vendor_balance)
                                    +
                                    parseFloat(this.state.liability[0].amount)
                                    +
                                    parseFloat(this.state.liability[2].amount)
                                    +
                                    (
                                      (parseFloat(this.state.payable_tax)
                                        +
                                        parseFloat(this.state.expense_tax_amount)
                                        +
                                        parseFloat(this.state.tax_vendor_credit)
                                      )
                                      -
                                      (
                                        parseFloat(this.state.invoice_tax_amount)
                                        +
                                        parseFloat(this.state.tax_credit_note)
                                      )
                                    )
                                    +
                                    parseFloat(this.state.liability[4].amount)
                                    +
                                    parseFloat(this.state.liability[1].amount)
                                    +
                                    parseFloat(this.state.liability[3].amount)
                                    +
                                    parseFloat(this.state.liability[5].amount)
                                  )
                                  +
                                  ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1))
                                    - parseFloat(this.state.cost_of_operation1))
                                    +
                                    parseFloat(this.state.drawing_cost)
                                    +
                                    parseFloat(this.state.offsetbalance_cost)
                                  )
                                )
                                +
                                ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1))
                                  - parseFloat(this.state.cost_of_operation1))
                                  +
                                  parseFloat(this.state.drawing_cost)
                                  +
                                  parseFloat(this.state.offsetbalance_cost)
                                )
                                // +
                                // parseFloat(this.state.drawing_cost)
                                // +
                                // parseFloat(this.state.offsetbalance_cost)
                              )
                            )
                            :
                            formatCurrency(

                              (
                                parseFloat(this.state.vendor_balance)
                                +
                                parseFloat(this.state.liability[0].amount)
                                +
                                parseFloat(this.state.liability[2].amount)
                                +
                                (
                                  (parseFloat(this.state.payable_tax)
                                    +
                                    parseFloat(this.state.expense_tax_amount)
                                    +
                                    parseFloat(this.state.tax_vendor_credit)
                                  )
                                  -
                                  (
                                    parseFloat(this.state.invoice_tax_amount)
                                    +
                                    parseFloat(this.state.tax_credit_note)
                                  )
                                )
                                +
                                parseFloat(this.state.liability[4].amount)
                                +
                                parseFloat(this.state.liability[1].amount)
                                +
                                parseFloat(this.state.liability[3].amount)
                                +
                                parseFloat(this.state.liability[5].amount)
                              )
                              +
                              (
                                (
                                  (
                                    (parseFloat(this.state.petty_debit) -
                                      (parseFloat(this.state.petty_credit) +
                                        parseFloat(this.state.petty_paymenymade)))
                                    +
                                    (parseFloat(this.state.undeposited_debit) - parseFloat(this.state.undeposited_credit))
                                    +
                                    (parseFloat(this.state.operating_cost) - parseFloat(this.state.amount_received))
                                    +
                                    (parseFloat(this.state.furniture_cost) - parseFloat(this.state.furniture_cost2))
                                    +
                                    (parseFloat(this.state.other_current_asset))
                                    +
                                    (parseFloat(this.state.intangible_cost))
                                    +
                                    (parseFloat(this.state.non_asset_cost))
                                  )
                                  -
                                  (
                                    (
                                      parseFloat(this.state.vendor_balance)
                                      +
                                      parseFloat(this.state.liability[0].amount)
                                      +
                                      parseFloat(this.state.liability[2].amount)
                                      +
                                      (
                                        (parseFloat(this.state.payable_tax)
                                          +
                                          parseFloat(this.state.expense_tax_amount)
                                          +
                                          parseFloat(this.state.tax_vendor_credit)
                                        )
                                        -
                                        (
                                          parseFloat(this.state.invoice_tax_amount)
                                          +
                                          parseFloat(this.state.tax_credit_note)
                                        )
                                      )
                                      +
                                      parseFloat(this.state.liability[4].amount)
                                      +
                                      parseFloat(this.state.liability[1].amount)
                                      +
                                      parseFloat(this.state.liability[3].amount)
                                      +
                                      parseFloat(this.state.liability[5].amount)

                                    )
                                    +
                                    (
                                      (((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1)))
                                        - parseFloat(this.state.cost_of_operation1))
                                        -
                                        (0.3 * ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1)))
                                          - parseFloat(this.state.cost_of_operation1))))
                                      +
                                      parseFloat(this.state.drawing_cost)
                                      +
                                      parseFloat(this.state.offsetbalance_cost)
                                    )
                                  )
                                )
                                +
                                (((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1)))
                                  - parseFloat(this.state.cost_of_operation1))
                                  -
                                  (0.3 * ((parseFloat(this.state.operating_cost1) - (parseFloat(this.state.cost_of_goods1)))
                                    - parseFloat(this.state.cost_of_operation1))))
                                +
                                parseFloat(this.state.drawing_cost)
                                +
                                parseFloat(this.state.offsetbalance_cost)


                              )
                            )
                        }

                      </h4>
                    </div>

                  </div>
                </div>


              </CardBody>
            }

          </Card>
        }

      </div>


    )
  }
}
