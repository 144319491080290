import React from 'react';
import { baseURL, baseURL_2 } from '../../../_helpers';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import Input from 'react-phone-number-input/input'
import { CONFIG, CURRENCY, errorToast, successToast, ToastTable, TOKEN, USERNAME, USER_ID, ROLE, USER_ID_ACC } from '../../../_helpers/exports';
import logo from '../../../shared/img/logo/3.png';
import * as moment from 'moment';
import { RadioGroup, ReversedRadioButton, RadioButton } from 'react-radio-buttons'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { Link } from 'react-router-dom';
import { savePaymentSend } from './sendPaymentReceipt';
import GooglePayButton from "@google-pay/button-react";

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitConfirm = this.onSubmitConfirm.bind(this);
    this.onChangeMeans = this.onChangeMeans.bind(this);
    this.getmpesa = this.getmpesa.bind(this);

    this.onClickback = this.onClickback.bind(this);
    this.onClickconfirm = this.onClickconfirm.bind(this);

    this.state = {
      email_address: "",
      showPassword: false,
      setShowPassword: false,
      channel_type: "portal",
      error: "",
      errorMsg: "",
      role: "",
      id: "",
      user: [],
      statusMessage: "",
      basic: "1",
      isShowSuccess: false,
      showpay: true,
      submitted: false,
      alert_error_color: "",
      isLoggedIn: false,
      showpaymentmethod: false,
      isgooglePay: false,
      isChecked: false,
      errorShow: false,
      type_plan: "Basic",
      msisdn: "",
      successShow: false,
      selectedGender: '',
      branch_name: '',
      branch_description: '',
      // postal_address: '',
      role_id: '4',
      alert_color: '',
      isLoading: false,
      isShowError: false,
      entity_id: '',
      password: '',
      entity_count: '',
      countryList: [],
      countiesList: [],
      showError: false,
      statis: [],
      industry: [{
        name: 'Monthly',
        amount: '500',
        value: moment(new Date(new Date().setDate(new Date().getDate() + 31))).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        name: 'Annually',
        amount: '10000',
        value: moment(new Date(new Date().setDate(new Date().getDate() + 366))).format('YYYY-MM-DD HH:mm:ss')
      }
      ]
    };
  }


  componentDidMount() {

    // if(1 === 1.00){
    //   alert("name")
    // }else{
    //   alert("hello")
    // }


  }



  Companies() {
    return (this.state.admins && this.state.admins.length > 0 &&
      this.state.admins.map((countyItem, i) =>
        ({ label: countyItem.corporate_name, value: countyItem.corporate_name })))

    // console.log("recrd", this.state.admins);
  }

  handleChangeComanies = (value: { value: { toString: () => any; }; }) => {
    this.setState({ corporate_name: value.value.toString() });
  };


  onClickconfirm() {
    this.setState({ showpay: false })

    this.getmpesa()
    // console.log("recrd", this.state.admins);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeMeans(value) {
    console.log(value);

    //basic config
    if (value === "1") {
      this.setState({
        basic: "1",
        standard: "2",
        amount: "",
        type_plan: "Basic",
        enterprise: "0",
        onselect: [{ label: "Please select your plan" }, { value: "0" }],
        showpaymentmethod: false,
        isgooglePay: false,
        industry: [{
          name: 'Monthly',
          amount: '500',
          value: moment(new Date(new Date().setDate(new Date().getDate() + 30))).format('YYYY-MM-DD HH:mm:ss')
        },
        {
          name: 'Annually',
          amount: '10000',
          value: moment(new Date(new Date().setDate(new Date().getDate() + 366))).format('YYYY-MM-DD HH:mm:ss')
        }
        ]
      })
    }
    //standard config
    else if (value === "2") {
      this.setState({
        basic: "2",
        standard: "0",
        type_plan: "Standard",
        amount: "",
        onselect: [{ label: "Please select your plan" }, { value: "0" }],
        showpaymentmethod: false,
        isgooglePay: false,
        enterprise: "1",
        industry: [{
          name: 'Monthly',
          amount: '1000',
          value: moment(new Date(new Date().setDate(new Date().getDate() + 30))).format('YYYY-MM-DD HH:mm:ss')
        },
        {
          name: 'Annually',
          amount: '15000',
          value: moment(new Date(new Date().setDate(new Date().getDate() + 366))).format('YYYY-MM-DD HH:mm:ss')
        }
        ]
      })
    }
    // enterprise config 
    else {
      this.setState({
        basic: "0",
        standard: "2",
        amount: "",
        type_plan: "Enterprise",
        onselect: [{ label: "Please select your plan" }, { value: "0" }],
        showpaymentmethod: false,
        isgooglePay: false,
        enterprise: "1",
        industry: [{
          name: 'Monthly',
          amount: '1500',
          value: moment(new Date(new Date().setDate(new Date().getDate() + 30))).format('YYYY-MM-DD HH:mm:ss')
        },
        {
          name: 'Annually',
          amount: '20000',
          value: moment(new Date(new Date().setDate(new Date().getDate() + 366))).format('YYYY-MM-DD HH:mm:ss')
        }
        ]
      })
    }
    // alert(value)
  }

  onClickback() {

    this.setState({ showpay: true })

    // console.log("recrd", this.state.admins);
  }
  handleChangeCounty = (event) => {
    this.setState({ selectedRole: event.target.value });
  };


  onSubmit = async e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    var months = moment(new Date(new Date().setDate(new Date().getDate() + 30))).format('YYYY-MM-DD HH:mm:ss');



    if (this.state.msisdn == "" || this.state.msisdn == "" || this.state.amount == null) {

      successToast("Success!! Check your phone ");
      // alert("Check your inputs")
      this.setState({
        isLoading: false,
        error: " is required",
        errorShow: true,
        submitted: true,

        alert_error_color: "alert alert-danger"
      });
    } else {

      let formData =
      {
        "amount": this.state.amount,
        "username": {
          "plan": this.state.basic,
          "date": this.state.subscription,
          "name": USERNAME,
          "id": USER_ID
        },
        "msisdn": this.state.msisdn.replaceAll("+", ""),
      }
      this.setState({ isLoading: true });
      axios.post(baseURL_2 + 'mpesa.php', formData, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': TOKEN
        },
      }).then((response) => {
        console.log('bayoo', response.data)
        successToast("Success!! Check your phone ");
        this.setState({ statusMessage: "Success! Check Your Phone", showError: false, isShowError: true, isLoading: false });
        window.setTimeout(() => {
          this.setState({ isShowError: false })
          // window.location.href = "/log_in";
        }, 8000);
        window.scrollTo(0, 0)
      })
        .catch((error) => {
          console.log('bayoo', error)
          this.setState({ statusMessage: "Error", showError: true, isShowError: false, isLoading: false });
        })
    }

    let footer;
    const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + localStorage.getItem("user_id_client"), CONFIG).then((response) => {
      if (response.data.data.length > 0) {
        footer = { footer: response.data.data[0].footer_notes };
      } else {
        footer = { footer: "" };
      }
    }).catch(error => {
      footer = { footer: "" };
    })


    this.state.editedInvoice = {
      payment_status: this.state.basic,
      subscription: this.state.subscription,
      client_id: localStorage.getItem("user_id_client"),
      amount: this.state.amount,
      email: localStorage.getItem("clientemail"),
      name: localStorage.getItem("company_name_client"),
      plan_type: this.state.type_plan,
      msisdn: localStorage.getItem("phonemsisdn_msisdn"),
      currency: CURRENCY
    };

    savePaymentSend(Object.assign(footer, this.state.editedInvoice));

  };

  industry() {
    return (this.state.industry && (this.state.industry.length > 0 || this.state.industry.length === 0) &&
      this.state.industry.map((countyItem, i) =>
        ({ label: countyItem.name, amount: countyItem.amount, value: countyItem.value })))

  }
  onSelectChanges = value => {

    this.setState({
      subscription: value.value.toString(),
      onselect: [{ label: value.label.toString() }, { value: value.value.toString() }],

      isgooglePay: true,
      // showpaymentmethod: true, 

      amount: value.amount.toString()
    });
  };


  isMpesa = e => {
    this.setState({
      showpaymentmethod: true,
      isgooglePay: false
    })
  }
  onSubmitConfirm = async e => {

    e.preventDefault();
    this.setState({ isLoading: true });

    let footer;
    const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + localStorage.getItem("user_id_client"), CONFIG).then((response) => {
      if (response.data.data.length > 0) {
        footer = { footer: response.data.data[0].footer_notes };
      } else {
        footer = { footer: "" };
      }
    }).catch(error => {
      footer = { footer: "" };
    })


    // alert(localStorage.getItem("phonemsisdn"))
    var months = moment(new Date(new Date().setDate(new Date().getDate() + 30))).format('YYYY-MM-DD HH:mm:ss')
    if (this.state.mpese_code == "" || this.state.mpese_code == "") {

      errorToast("Check your inputs!");
    } else {


      var str = localStorage.getItem("phonemsisdn_msisdn");
      var res = str.substring(0, 6);
      var res_2 = str.substring(0, 7);

      // if (res == +25470 || res == +25471 || res == +25472 || res == +25479) {
      // if(this.state.company_name === "" || this.state.postal_address == ""){
      let formData =
      {
        "payment_status": this.state.basic,
        "subscription": this.state.subscription,
        "client_id": localStorage.getItem("user_id_client"),
        "amount": this.state.amount,
        "email": localStorage.getItem("clientemail"),
        "name": localStorage.getItem("company_name_client"),
        "plan_type": this.state.type_plan,
        "mpesa_code": this.state.mpese_code,
        "msisdn": localStorage.getItem("phonemsisdn_msisdn")
      }
      this.setState({ isLoading: true });
      // eslint-disable-next-line
      this.state.editedInvoice = {
        payment_status: this.state.basic,
        subscription: this.state.subscription,
        client_id: localStorage.getItem("user_id_client"),
        amount: this.state.amount,
        email: localStorage.getItem("clientemail"),
        name: localStorage.getItem("company_name_client"),
        plan_type: this.state.type_plan,
        mpesa_code: this.state.mpese_code,
        msisdn: localStorage.getItem("phonemsisdn_msisdn"),
        currency: CURRENCY
      };

      console.log(JSON.stringify(this.state.editedInvoice))





      savePaymentSend(Object.assign(footer, this.state.editedInvoice));




      //   axios.post(baseURL + `mpesa_update', formData).then((response) => {
      //     if (response.data.status) {
      //       this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
      //       window.setTimeout(() => {
      //         // this._onButtonClick();
      //         this.setState({ isShowError: false })
      //       }, 6000);
      //       window.scrollTo(0, 0)
      //     } else {
      //       window.scrollTo(0, 0)
      //       this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
      //       window.setTimeout(() => {
      //         this.setState({ showError: false })
      //       }, 6000);
      //     }
      //   }).catch((error) => {
      //     console.log('bayoo', error)
      //     this.setState({
      //       statusMessage: error.response === undefined ? "Error" : error.response.data.message, showError: true, isShowError: false, isLoading: false
      //     });
      //   })
      // } else if (res_2 == +254112 || res_2 == +254113 || res_2 == +254114 || res_2 == +254115 ||
      //   res_2 == +254740 || res_2 == +254741 || res_2 == +254742 || res_2 == +254743 || res_2 == +254745 || res_2 == +254746
      //   || res_2 == +254748 || res_2 == +254757 || res_2 == +254758 || res_2 == +254759 || res_2 == +254768 || res_2 == +254769) {
      //   // if(this.state.company_name === "" || this.state.postal_address == ""){
      //   let formData =
      //   {
      //     "payment_status": this.state.basic,
      //     "subscription": this.state.subscription,
      //     "client_id": localStorage.getItem("user_id_client"),
      //     "amount": this.state.amount,
      //     "email": localStorage.getItem("clientemail"),
      //     "name": localStorage.getItem("company_name_client"),
      //     "plan_type": this.state.type_plan,
      //     "mpesa_code": this.state.mpese_code,
      //     "msisdn": localStorage.getItem("phonemsisdn")
      //   }
      //   this.setState({ isLoading: true });

      //   axios.post(baseURL + `mpesa_update', formData).then((response) => {
      //     if (response.data.status) {
      //       this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
      //       window.setTimeout(() => {
      //         // this._onButtonClick();
      //         window.location.href = "/log_in";
      //         this.setState({ isShowError: false })
      //       }, 6000);
      //       window.scrollTo(0, 0)

      //     } else {
      //       window.scrollTo(0, 0)
      //       this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
      //       window.setTimeout(() => {
      //         this.setState({ showError: false })
      //       }, 6000);
      //     }

      //   })
      //     .catch((error) => {
      //       console.log('bayoo', error)
      //       this.setState({
      //         statusMessage: error.response === undefined ? "Error" : error.response.data.message, showError: true, isShowError: false, isLoading: false
      //       });
      //     })
      // } else {

      //   var str = localStorage.getItem("phonemsisdn");
      //   var res = str.substring(1);

      //   // if(this.state.company_name === "" || this.state.postal_address == ""){
      //   let formData =
      //   {
      //     "payment_status": this.state.basic,
      //     "subscription": this.state.subscription,
      //     "client_id": localStorage.getItem("user_id_client"),
      //     "amount": this.state.amount,
      //     "email": localStorage.getItem("clientemail"),
      //     "name": localStorage.getItem("company_name_client"),
      //     "plan_type": this.state.type_plan,
      //     "mpesa_code": this.state.mpese_code,
      //     "msisd": res
      //   }
      //   this.setState({ isLoading: true });

      //   axios.post(baseURL + `mpesa_update_other_no', formData).then((response) => {
      //     if (response.data.status) {
      //       this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
      //       window.setTimeout(() => {
      //         // this._onButtonClick();
      //         window.location.href = "/log_in";
      //         this.setState({ isShowError: false })
      //       }, 6000);
      //       window.scrollTo(0, 0)

      //     } else {
      //       window.scrollTo(0, 0)
      //       this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
      //       window.setTimeout(() => {
      //         this.setState({ showError: false })
      //       }, 6000);
      //     }

      //   })
      //     .catch((error) => {
      //       console.log('bayoo', error)
      //       this.setState({
      //         statusMessage: error.response === undefined ? "Error" : error.response.data.message, showError: true, isShowError: false, isLoading: false
      //       });
      //     })
      // }
    }

  };



  getmpesa() {

    axios.get(baseURL + `get_mpesa?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USERNAME).then((response) => {

      if (response.data.status) {
        this.setState({ mpese_code: response.data.data[0].TransID });
      } else {
      }
    }).catch((error) => {
      console.log('bayoo', error)
      this.setState({
        // statusMessage: error.response === undefined ? "Error" : error.response.data.message, showError: true, isShowError: false, isLoading: false
      });
    })
  }



  render() {

    return (
      <div className="account account">
        <div className="account__wrapper">

          {ToastTable()}

          {this.state.showpay == true ?
            <div className="account__card" >
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
              <h4>Your trial has expired, please select a plan and follow instruction on payment<br /> <b>Find more about our plans <a href="/">Click here</a></b></h4>
              <br />
              {/* <h4><b>N/B</b> If you have already made a transaction just choose the plan then period to proceed for payment confirmation</h4> */}

              <Form className="form login-form" onSubmit={this.onSubmit}>
                {this.state.showError ?
                  <div style={{ color: 'red' }}>
                    {this.state.statusMessage}
                  </div> : null} <br></br>
                {this.state.isShowError ? (
                  <div
                    color="success"
                    style={{ fontSize: "13px", color: "green" }}>
                    {this.state.statusMessage}
                  </div>
                ) : null}


                <div className="col-md-12" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>
                  <label className="form-label">Select your plan</label>
                  <RadioGroup onChange={this.onChangeMeans} value={this.state.basic} horizontal>
                    <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                      Basic
                    </RadioButton>
                    <RadioButton value="2" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                      Standard
                    </RadioButton>
                    <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                      Enterprise
                    </RadioButton>
                  </RadioGroup>
                </div>

                <div className="col-md-12">
                  <br />

                  <div className="form-group">
                    <label>Select Plan Period</label>
                    <Select
                      options={this.industry()}
                      onChange={this.onSelectChanges}
                      placeholder="Select Plan Period"
                      tabSelectsValue={false}
                      value={this.state.onselect}
                      className='drop'
                    />
                    <br />
                  </div>
                </div>
                {this.state.isgooglePay == true ?
                  <div>

                    <p>Amount</p><h4><b>{this.state.amount} KES </b>  OR <b>10</b> USD</h4>

                    <p>Pay via</p>

                    <Button className="account__btn primary btn-primary" onClick={this.isMpesa} color='white' type='submit'>
                      M-PESA
                    </Button>
                    <h4><b>OR</b></h4>
                    <br />
                    <GooglePayButton
                      environment="TEST"
                      paymentRequest={{
                        apiVersion: 2,
                        apiVersionMinor: 0,
                        allowedPaymentMethods: [
                          {
                            type: 'CARD',
                            parameters: {
                              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                              allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "MIR", "VISA"],
                            },
                            tokenizationSpecification: {
                              type: 'PAYMENT_GATEWAY',
                              parameters: {
                                gateway: 'example',
                                gatewayMerchantId: 'exampleGatewayMerchantId',
                              },
                            },
                          },
                        ],
                        merchantInfo: {
                          merchantId: '12345678901234567890',
                          merchantName: 'Demo Merchant',
                        },
                        transactionInfo: {
                          totalPriceStatus: 'FINAL',
                          totalPriceLabel: 'Total',
                          totalPrice: '100.00',
                          currencyCode: 'USD',
                          countryCode: 'US',
                        },
                      }}
                      onLoadPaymentData={paymentRequest => {
                        console.log('load payment data', paymentRequest);
                      }}
                    />
                  </div>
                  : null}

                {this.state.showpaymentmethod == true ?
                  <div>



                    <div className="col-md-12">
                      <div className="form-group">
                        <br />
                        <label className="form-label"><b>Enter Phone Number</b></label>
                        <div className="col-md-12">
                          <PhoneInput
                            flags={flags}
                            defaultCountry="KE"
                            placeholder="Enter phone number"
                            name="msisdn"
                            maxLength={15}
                            id="input"
                            required
                            value={this.state.msisdn}
                            onChange={value => this.setState({ msisdn: value })} />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group"><br />
                        <div className="col-md-6 offset-3">
                          {
                            <Button className="account__btn btn-primary" style={{ color: "white" }} type='submit'> {
                              this.state.isLoading ? "Please wait..." : "Submit"
                            }</Button>
                          }
                        </div>
                      </div>
                    </div>

                    <h3><b>N/B</b></h3>
                    <h4><b>Payment Instructions </b></h4>


                    <ol>
                      {/* 
                      <li>Go to M-Pesa menu</li>
                      <li>Click on <b>Lipa na M-Pesa</b></li>
                      <li>Click on Paybill Option</li>
                      <li>Enter Businness No. <b>864035</b></li>
                      <li>Enter Account Name: Your username <b>{USERNAME}</b></li>
                      <li>Enter amount <b>{this.state.amount} KES </b></li>
                      <li>Wait for the M-Pesa message</li> */}
                      {/* <li><b>Confirm your payment</b> (Link is available below the page)</li> */}
                      <br />
                      {/* <b>OR</b> */}
                      <li>Enter your phone number in the input above to receive the pop up from MPESA <b>Make sure you sufficient fund on your mpesa account</b></li>
                      <li>Then Enter your MPESA PIN and thats all! </li>
                      {/* <li><b>Confirm your payment</b>  (Link is available below the page)</li> */}
                    </ol>
                    {/* <div className="account__have-account" style={{ marginBottom: "20px", fontStyle: "bold" }}>
                      <h4 ><b>Confirm payment?</b> <a style={{ cursor: "pointer", color: "blue" }}
                        onClick={this.onClickconfirm} ><b>Click here</b> </a></h4>
                    </div> */}

                  </div>
                  : null}



              </Form>

              <div className="account__have-" >
                <p className="text-center">Our contact</p>
                <p className="text-center"><b style={{ color: "blue" }}>+254736105920</b></p>
                <p className="text-center"><b style={{ color: "blue" }}>peakbooks@peakbooks.biz</b></p>
              </div>

            </div>
            :
            <div className="account__card" >
              <h3 className="text-center"><b>Payment Confirmation</b></h3>
              <br />
              <img src={logo} alt="" />
              <Form className="form login-form" onSubmit={this.onSubmitConfirm}>
                {this.state.showError ?
                  <div style={{ color: 'red' }}>
                    {this.state.statusMessage}
                  </div> : null} <br></br>
                {this.state.isShowError ? (
                  <div
                    color="success"
                    style={{ fontSize: "13px", color: "green" }}>
                    {this.state.statusMessage}
                  </div>
                ) : null}
                <div className="col-md-12">
                  <div className="form-group">

                    <label className="form-label">MPESA CODE</label>

                    <div className="col-md-12">
                      <input id="input" type="text" className="form-control" name="mpese_code"
                        required placeholder="Enter transaction ID"
                        style={{ textTransform: "uppercase" }}
                        maxLength={10}
                        value={this.state.mpese_code}
                        onChange={this.handleChange} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group"><br />
                    <div className="col-md-12">
                      {
                        <Button className="account__btn" type='submit' outline color="primary"> {
                          this.state.isLoading ? "Please wait..." : "Confirm"
                        }</Button>
                      }
                    </div>
                  </div>

                  <div className="account__have-account" style={{ marginBottom: "20px" }}>
                    <p>Go back? <a style={{ cursor: "pointer", color: "lightblue" }} onClick={this.onClickback}>Click here</a></p>
                  </div>
                </div>
              </Form>


            </div>
          }

        </div>


      </div >
    );
  }
}

export default Payments;
