import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL, baseURL_2, currentUserSubject } from '../../_helpers';
import { FIRST_TIME, PAYMENT_PLAN, USERNAME, TOKEN, USER_ID, EMAIL_ADDRESS, ROLE, USER_ID_ACC, successToast, errorToast, NAME } from '../../_helpers/exports';
import { Link, Navigate } from 'react-router-dom';
import * as moment from 'moment';
import addUser from './addUser';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import adduserclient from './adduserclient';
import editclient from './editclient';

export class Clients extends React.Component {
    constructor(props) {
        super(props);
        this._onButtonClick = this._onButtonClick.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);

        this.columns = [
            {
                key: "name",
                TrOnlyClassName: 'tsc',
                text: "Name",
                className: "tsc",
                align: "left"
            },
            {
                key: "msisdn",
                TrOnlyClassName: 'tsc',
                text: "Phone Number",
                className: "tsc",
                align: "left"
            },

            {
                key: "email",
                TrOnlyClassName: 'tsc',
                text: "Email",
                className: "tsc",
                align: "left"
            },

            // {
            //     key: "username",
            //     TrOnlyClassName: 'tsc',
            //     text: "Username",
            //     className: "tsc",
            //     align: "left"
            // },

            {
                key: "industry",
                TrOnlyClassName: 'tsc',
                text: "Industry",
                className: "tsc",
                align: "left"
            },

            {
                key: "admins",
                TrOnlyClassName: 'tsc',
                text: "User Role",
                className: "tsc",
                align: "left"
            },

            {
                key: "admins_approval",
                TrOnlyClassName: 'tsc',
                text: "Sales/Purchase Permission",
                className: "tsc",
                align: "left"
            },

            {
                key: "dates",
                text: "Date Created",
                TrOnlyClassName: 'tsc',
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >

                            {record.admin_role !== "1" ?
                                <button className="btn btn-primary btn-sm"
                                    title="Eith details"
                                    style={
                                        { marginRight: '10px' }}

                                    onClick={
                                        () => { if (window.confirm(`Are you sure you want to visit ${record.first_name + " " + record.last_name}'s profile?`)) this.handleProfile(record) }
                                    } >
                                    Profile
                                </button>
                                : null}
                            <button className="btn btn-success btn-sm"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleEdit(record) }} >
                                Edit
                            </button>

                            {record.status === 0 ?
                                <button className="btn btn-primary btn-sm"
                                    title="Eith details"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleOnClick2(record) }} >
                                    Approve
                                </button>
                                : null}
                            {record.status === 1 && record.email !== EMAIL_ADDRESS ?

                                <button className="btn btn-danger btn-sm"
                                    title="Deactive"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleOnClick(record) }} > Deactive
                                </button>
                                : null}

                            {record.email !== EMAIL_ADDRESS ?
                                <button className="btn btn-danger btn-sm"
                                    title="Deactive"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleOnClickDelete(record) }} > Delete
                                </button>
                                : null}

                        </Fragment>
                    );
                }
            }

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            button: {
                excel: true,
                csv: true
            },
            filename: "your_users",
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                no_data_text: "No client was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showEdit: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }
    componentDidMount() {
        this.setState({
            showEdit: false
        })
        this.getData();
    }


    async handleEdit(record) {
        console.log("hoge!");

        localStorage.setItem("one_user", record.id)

        this.setState({
            showEdit: true
        })
    }


    async handleProfile(record) {
        console.log("hoge!");

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };

        // e.preventDefault();
        this.setState({ isLoading: true });
        // eslint-disable-next-line
        let formData = {
            "email": record.email,
            "account_id": record.id,
        }
        // console.log("DATA", formData)

        this.setState({ isLoading: true });
        axios.post(baseURL + `loginaccountant`, formData, axiosConfig
        ).then((response) => {
            console.log("token", response.data);
            // eslint-disable-next-line
            if (response.data.status) {
                localStorage.setItem("name", response.data.user.first_name + " " + response.data.user.last_name)
                localStorage.setItem("user_role", response.data.user.role_id)
                localStorage.setItem("phonemsisdn_msisdn", response.data.user.msisdn)

                localStorage.setItem("clientemail", response.data.user.email)


                localStorage.setItem("user_id_client", response.data.user.client_id)
                localStorage.setItem("user_id_user", response.data.user.user_id)
                localStorage.setItem("company_name_client", response.data.user.company_name)

                localStorage.setItem("address_client", response.data.user.postal_address)
                localStorage.setItem("useradminrole", response.data.user.adminaccountlogin)


                localStorage.setItem("physical_address", response.data.user.physical_address)


                localStorage.setItem("our_client", response.data.user.our_client)
                localStorage.setItem("currency", response.data.user.currency)
                localStorage.setItem("payment_plan_user", response.data.user.payment_plan)
                localStorage.setItem("username", response.data.user.username)
                localStorage.setItem("invoice_status", response.data.user.invoice_format)
                localStorage.setItem("balance", response.data.user.balance)
                localStorage.setItem("business_pin", response.data.user.business_pin)
                localStorage.setItem("profile_url", response.data.user.url)
                localStorage.setItem("financial_year", response.data.user.financial_year)
                localStorage.setItem("currency_against", response.data.user.agnaist_ksh)
                localStorage.setItem("admin_role", response.data.user.admin_role)
                localStorage.setItem("inventory", response.data.user.inventory)
                localStorage.setItem("bank", response.data.user.bank)
                localStorage.setItem("sales", response.data.user.sales)
                localStorage.setItem("purchase", response.data.user.purchase)
                localStorage.setItem("investment", response.data.user.investment)
                localStorage.setItem("accountant", response.data.user.accountant)
                localStorage.setItem("reports", response.data.user.reports)
                localStorage.setItem("documents", response.data.user.documents)
                localStorage.setItem("industry", response.data.user.industry)
                localStorage.setItem("first_time", response.data.user.first_time)
                localStorage.setItem("approvals_owner", response.data.user.approval)
                localStorage.setItem("type_business", response.data.user.type)
                localStorage.setItem("subscription_expiry", response.data.user.subscription)
                window.localStorage.setItem("num", "ABC000001")
                currentUserSubject.next(response.data);
                window.user = response.data;
                window.scrollTo(0, 0);




                successToast("Login Success! Redirecting....")
                this.setState({
                    isShowError: true,
                    errorShow: false,
                    submitted: true,
                    isLoggedIn: true
                });

                console.log(JSON.stringify(response.data))
                localStorage.setItem("token", response.data.token)
                window.setTimeout(function () {
                    window.location.href = "/dashboard";
                    this.setState({ isLoading: false });
                }, 100);
            }
            else {

                errorToast(response.data.message)
                this.setState({
                    // alert_error_color: "alert alert-danger",
                    errorShow: true,
                    submitted: true,
                    isOpen: false,
                    isLoading: false
                }, function () {
                    console.log('data', response.data.message)
                });
            }
        }).catch(error => {
            errorToast(error.response === undefined ? "Check your internet" : error.response.data.message)
            this.setState({
                errorShow: true,
                submitted: true,
                isOpen: false,
                isLoading: false
            });
            this.setState({ password: "", email_address: this.state.email_address });
        })
    }




    async handleOnClick2(record) {
        console.log("hoge!");
        if (await confirm("Proceed in activating this account?")) {
            this.onApprove(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Proceed in deactivating this account?")) {
            this.onDeactivate(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    async handleOnClickDelete(record) {
        console.log("hoge!");
        if (await confirm("Proceed in deleting this account?")) {
            this.onDelete(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    getData = (queryString = "") => {
        queryString = ""
        this.setState({
            isLoading: true,
        })

        // alert(USER_ID)
        // alert(TOKEN)
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `client?acc_id=${USER_ID_ACC}&&role=${ROLE}&&filter_value=${USER_ID}`
        console.log("query", url);
        // alert(localStorage.getItem("customerID"))
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                isLoading: false
            }, function () {
                var data = [];
                console.log("BUG", this.state.admins)
                for (let i = 0; i < this.state.admins.length || 0; i++) {
                    this.setState({
                        total: this.state.admins.length
                    })
                    // eslint-disable-next-line
                    let mail = { name: this.state.admins[i].first_name + " " + this.state.admins[i].last_name };

                    let user_role = this.state.admins[i].admin_role
                    let approval = this.state.admins[i].approval

                    let admin_role;
                    if (user_role === "1") {
                        admin_role = { admins: "Admin" }
                    } else {
                        admin_role = { admins: "Accountant" }
                    }

                    let admin_approval;


                    // alert(approval)




                    if (approval == 1) {
                        admin_approval = { admins_approval: "Admin Approval" }
                    } else {
                        admin_approval = { admins_approval: "Self Approval" }
                    }
                    // eslint-disable-next-line
                    let date = { dates: moment(this.state.admins[i].created_at).format("DD/MM/YYYY") };
                    data.push(Object.assign(mail, admin_role, admin_approval, date, this.state.admins[i]));
                    this.setState({
                        data: data
                    })
                    console.log("bugs", data);
                }
            }
            );
        }))

    }


    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onApprove(record) {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };

        console.log("axios", axiosConfig)
        axios.put(baseURL + `useractivate/` + record.id, {}, axiosConfig).then(response => {
            console.log("true",)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });

                let formData2 =
                {
                    "name": record.first_name + " " + record.last_name,
                    "email": record.email,
                    "token": TOKEN
                }
                axios.post(baseURL_2 + 'emailApprove.php', formData2)
                    .then((response) => {



                    }).catch(error => {
                        this.setState({ statusMessage: error.response === undefined ? window.location.href = "/log_in" : "Something went wrong", showError: true, isShowError: false, isLoading: false });
                        window.scrollTo(0, 0)
                        // console.error('Error during service worker registration:', error);
                    });
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false }, function () {
                    // window.setTimeout(() => {
                    //     this.getData();
                    //     this.setState({ isShowError: false, showError: false })
                    // }, 2000);
                });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }


    onDelete(record) {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };

        console.log("axios", axiosConfig)
        axios.delete(baseURL + `client/` + record.id, axiosConfig).then(response => {
            console.log("true",)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });

            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false }, function () {
                });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }


    onDeactivate(record) {

        console.log("Delete Record", record);
        axios.put(baseURL + `userdeactivate/` + record.id, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
                let formData2 =
                {
                    "name": record.first_name + " " + record.last_name,
                    "email": record.email,
                    "token": TOKEN
                }
                axios.post(baseURL_2 + 'emailReject.php', formData2)
                    .then((response) => {

                    }).catch(error => {
                        this.setState({ statusMessage: error.response === undefined ? window.location.href = "/log_in" : "Something went wrong", showError: true, isShowError: false, isLoading: false });
                        window.scrollTo(0, 0)
                        // console.error('Error during service worker registration:', error);
                    });

            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false, showError: false })
                    }, 2000);
                });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }
    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: false,

        });
    }

    render() {
        const { showComponent, showEdit, total } = this.state;
        const { hideComponent } = this.state;
        // console.log("Load", isLoading);
        return (
            <div style={{ marginTop: "-20px" }} >
                {showEdit == true ?
                    <Navigate to="/edit-user" />
                    : null
                }
                {showEdit && (
                    <div>
                        <editclient />
                    </div>
                )}
                {showComponent && (
                    <div>
                        <adduserclient />
                    </div>
                )}
                {!hideComponent && (


                    <div>
                        < Card >
                            <CardBody >
                                <div className="row">
                                    <div className="col-md-4">
                                        <Link to="/settings">
                                            <Button className="pull-left btn-sm" color="primary" outline><i className="fa fa-arrow"></i> Back to reports
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="col-md-4">
                                        <h4><b>Your Users</b></h4>
                                    </div>
                                    <div className="col-md-4">

                                        {PAYMENT_PLAN == 1 ?
                                            total < 3 ?
                                                <Link to="/add-new-users">
                                                    <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                        Add User
                                                    </Button>
                                                </Link>
                                                : null
                                            : null}


                                        {PAYMENT_PLAN == 2 ?
                                            total < 6 ?
                                                <Link to="/add-new-users">
                                                    <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                        Add User
                                                    </Button>
                                                </Link>
                                                : null
                                            : null}

                                        {PAYMENT_PLAN == 0 ?

                                            FIRST_TIME == 1 ?
                                                total < 100 ?
                                                    <Link to="/add-new-users">
                                                        <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                            Add User
                                                        </Button>
                                                    </Link>
                                                    : null
                                                :
                                                total < 10 ?
                                                    <Link to="/add-new-users">
                                                        <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                            Add User
                                                        </Button>
                                                    </Link>
                                                    : null
                                            : null}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <br />
                                        {/* <h4>Total Users: <b>{this.state.total}</b></h4> */}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-md-10 offset-1">
                                            <button className="pull-right btn-sm" title="refresh"
                                                style={{ background: "white", border: "0px" }} onClick={this.getData}>
                                                <RefreshIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>

                                </div> : null}

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div>

                                ) : null}<br></br>

                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={this.state.isLoading}
                                    // total_record={this.state.total}
                                    onChange={this.tableChangeHandler} />
                            </CardBody>

                        </Card>
                    </div>

                )
                }
            </div>
        )
    }
}