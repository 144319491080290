
// export const currency = executeFucntion();
   


// function executeFucntion() {
//     let axiosConfig = {
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             "Access-Control-Allow-Origin": "*",
//             "Authorization": TOKEN
//         }
//     };
//     let url = baseURL + `currency"
//     axios.all([
//         axios.get(url, axiosConfig),
//     ]).then(axios.spread((itemResponse) => {
//         this.setState({
//             item: itemResponse.data.data,
//             isLoading: false,
//         },
//             function () {
//                 return itemResponse.data.data

//             })
//     })).catch(error => {
//         console.log('bayoo', error.response)
//     }) 
// }


export const currency = [
    {
        "name": "Kenya",
        "currency": "KES",
        "agnaist_ksh": '1'
    },
    {
        "name": "USA",
        "currency": "USD",
        "agnaist_ksh": '0.0093'
    }
    ,
    {
        "name": "Belgium",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    },
    {
        "name": "Afghanistan",
        "currency": "AFN",
        "agnaist_ksh": '0.00780'
    },
    {
        "name": "Albania",
        "currency": "ALL",
        "agnaist_ksh": '0.96'
    },
    {
        "name": "Algeria",
        "currency": "DZD",
        "agnaist_ksh": '1.23'
    },
   
    {
        "name": "Andorra",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    },
    {
        "name": "Angola",
        "currency": "AOA",
        "agnaist_ksh": '5.82'
    },
    {
        "name": "Anguilla",
        "currency": "XCD",
        "agnaist_ksh": '0.025'
    },
    {
        "name": "Antigua and Barbuda",
        "currency": "XCD",
        "agnaist_ksh": '0.025'
    },
    {
        "name": "Argentina",
        "currency": "ARS",
        "agnaist_ksh": '0.85'
    },
    {
        "name": "Armenia",
        "currency": "AMD",
        "agnaist_ksh": '4.94'
    },
    {
        "name": "Aruba",
        "currency": 'AWG',
        "agnaist_ksh": '0.017'
    },
    {
        "name": "Australia",
        "currency": "AUD",
        "agnaist_ksh": '0.012'
    },
    {
        "name": "Austria",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    },
    {
        "name": "Azerbaijan",
        "currency": "AZN",
        "agnaist_ksh": '0.016'
    },
    {
        "name": "Bahamas",
        "currency": "BSD",
        "agnaist_ksh": '0.0092'
    },
    {
        "name": "Bahrain",
        "currency": "BHD",
        "agnaist_ksh": '0.0035'
    },
    {
        "name": "Bangladesh",
        "currency": "BDT",
        "agnaist_ksh": '0.78'
    },
    {
        "name": "Barbados",
        "currency": "BBD",
        "agnaist_ksh": '0.019'
    },
    {
        "name": "Belarus",
        "currency": 'BYN',
        "agnaist_ksh": '0.024'
    }, {
        "name": "Belize",
        "currency": 'BZD',
        "agnaist_ksh": '0.019'
    }, {
        "name": "Benin",
        "currency": "XOF",
        "agnaist_ksh": '5.09'
    }, {
        "name": "Bermuda",
        "currency": "BMD",
        "agnaist_ksh": '0.0092'
    }, {
        "name": "Bhutan",
        "currency": "BTN",
        "agnaist_ksh": '0.69'
    }, {
        "name": "Bolivia, Plurinational State of",
        "currency": "BOB",
        "agnaist_ksh": '0.063'
    }, {
        "name": "Bosnia and Herzegovina",
        "currency": "BAM",
        "agnaist_ksh": '0.015'
    }, {
        "name": "Botswana",
        "currency": "BWP",
        "agnaist_ksh": '0.10'
    }, {
        "name": "Brazil",
        "currency": "BRL",
        "agnaist_ksh": '0.052'
    }, {
        "name": "British Indian Ocean Territory",
        "currency": "USD",
        "agnaist_ksh": '0.0093'
    }, {
        "name": "Bulgaria",
        "currency": "BGN",
        "agnaist_ksh": '0.015'
    }, {
        "name": "Burkina Faso",
        "currency": "XOF",
        "agnaist_ksh": '5.09'
    }, {
        "name": "Burundi",
        "currency": 'BIF',
        "agnaist_ksh": '18.04'
    }, {
        "name": "Cambodia",
        "currency": "KHR",
        "agnaist_ksh": '37.17'
    }, {
        "name": "Cameroon",
        "currency": "XAF",
        "agnaist_ksh": '5.09'
    }, {
        "name": "Canada",
        "currency": "CAD",
        "agnaist_ksh": '0.012'
    }, {
        "name": "Cape Verde",
        "currency": 'CVE',
        "agnaist_ksh": '0.86'
    }, {
        "name": "Cayman Islands",
        "currency": "KYD",
        "agnaist_ksh": '0.0077'
    }, {
        "name": "Central African Republic",
        "currency": "XAF",
        "agnaist_ksh": '5.10'
    }, {
        "name": "Chad",
        "currency": 'XAF',
        "agnaist_ksh": '5.10'
    }, {
        "name": "Chile",
        "currency": "CLP",
        "agnaist_ksh": '6.63'
    }, {
        "name": "China",
        "currency": "CNY",
        "agnaist_ksh": '0.060'
    }, {
        "name": "Colombia",
        "currency": "COP",
        "agnaist_ksh": '33.59'
    }, {
        "name": "Comoros",
        "currency": "KMF",
        "agnaist_ksh": '3.87'
    }, {
        "name": "Congo",
        "currency": "XAF",
        "agnaist_ksh": '18.46'
    }, {
        "name": "Democratic Republic of the Congo",
        "currency": "CDF",
        "agnaist_ksh": '18.46'
    }, {
        "name": "Cook Islands",
        "currency": "NZD",
        "agnaist_ksh": '0.013'
    }, {
        "name": "Costa Rica",
        "currency": "CRC",
        "agnaist_ksh": '5.64'
    }, {
        "name": "Côte d'Ivoire",
        "currency": "XOF",
        "agnaist_ksh": '5.09'
    }, {
        "name": "Croatia",
        "currency": "HRK",
        "agnaist_ksh": '0.059'
    }, {
        "name": "Cuba",
        "currency": "CUP",
        "agnaist_ksh": '0.22'
    }, {
        "name": "Curaçao",
        "currency": "ANG",
        "agnaist_ksh": '0.016'
    }, {
        "name": "Cyprus",
        "currency": 'EUR',
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Czech Republic",
        "currency": "CZK",
        "agnaist_ksh": '0.20'
    }, {
        "name": "Denmark",
        "currency": "DKK",
        "agnaist_ksh": '0.058'
    }, {
        "name": "Djibouti",
        "currency": "DJF",
        "agnaist_ksh": '1.64'
    }, {
        "name": "Dominica",
        "currency": "XCD",
        "agnaist_ksh": '0.025'
    }, {
        "name": "Dominican Republic",
        "currency": "DOP",
        "agnaist_ksh": '0.52'
    }, {
        "name": "Ecuador",
        "currency": "USD",
        "agnaist_ksh": '0.0092'
    }, {
        "name": "Egypt",
        "currency": "EGP",
        "agnaist_ksh": '0.15'
    }, {
        "name": "El Salvador",
        "currency": "SVC",
        "agnaist_ksh": '0.0092'
    },
    {
        "name": "Equatorial Guinea",
        "currency": "XAF",
        "agnaist_ksh": '5.10'
    }, {
        "name": "Eritrea",
        "currency": "ERN",
        "agnaist_ksh": '0.1377'
    }, {
        "name": "Estonia",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Ethiopia",
        "currency": "ETB",
        "agnaist_ksh": '0.38'
    }, {
        "name": "Falkland Islands (Malvinas)",
        "currency": 'FKP',
        "agnaist_ksh": '0.0066'
    }, {
        "name": "Faroe Islands",
        "currency": "DKK",
        "agnaist_ksh": '0.058'
    }, {
        "name": "Fiji",
        "currency": "FJD",
        "agnaist_ksh": '0.019'
    }, {
        "name": "Finland",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "France",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "French Polynesia",
        "currency": 'XPF',
        "agnaist_ksh": '0.94'
    }, {
        "name": "Gabon",
        "currency": "XAF",
        "agnaist_ksh": '5.10'
    }, {
        "name": "Gambia",
        "currency": "GMD",
        "agnaist_ksh": '0.47'
    }, {
        "name": "Georgia",
        "currency": "GEL",
        "agnaist_ksh": '0.032'
    }, {
        "name": "Germany",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Ghana",
        "currency": "GHS",
        "agnaist_ksh": '0.053'
    }, {
        "name": "Gibraltar",
        "currency": "GIP",
        "agnaist_ksh": '0.0067'
    }, {
        "name": "Greece",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Greenland",
        "currency": "DKK",
        "agnaist_ksh": '0.058'
    }, {
        "name": "Grenada",
        "currency": "XCD",
        "agnaist_ksh": '0.025'
    }, {
        "name": "Guam",
        "currency": "USD",
        "agnaist_ksh": '0.0092'
    }, {
        "name": "Guatemala",
        "currency": "GTQ",
        "agnaist_ksh": '0.071'
    }, {
        "name": "Guernsey",
        "currency": "GBP",
        "agnaist_ksh": '0.0067'
    }, {
        "name": "Guinea",
        "currency": "GNF",
        "agnaist_ksh": '91.83'
    }, {
        "name": "Guinea-Bissau",
        "currency": "XOF",
        "agnaist_ksh": '5.13'
    }, {
        "name": "Haiti",
        "currency": "HTG",
        "agnaist_ksh": '0.75'
    }, {
        "name": "Honduras",
        "currency": "HNL",
        "agnaist_ksh": '0.22'
    }, {
        "name": "Hong Kong",
        "currency": "HKD",
        "agnaist_ksh": '0.072'
    }, {
        "name": "Hungary",
        "currency": 'HUF',
        "agnaist_ksh": 'Ft'
    }, {
        "name": "Iceland",
        "currency": 'ISK',
        "agnaist_ksh": '1.17'
    }, {
        "name": "India",
        "currency": "INR",
        "agnaist_ksh": '0.69'
    }, {
        "name": "Indonesia",
        "currency": "IDR",
        "agnaist_ksh": '134.58'
    }, {
        "name": "Iran, Islamic Republic of",
        "currency": 'IRR',
        "agnaist_ksh": '388.96'
    }, {
        "name": "Iraq",
        "currency": "IQD",
        "agnaist_ksh": "13.41"
    }, {
        "name": "Ireland",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Isle of Man",
        "currency": "GBP",
        "agnaist_ksh": '0.0067'
    }, {
        "name": "Israel",
        "currency": "ILS",
        "agnaist_ksh": '0.030'
    }, {
        "name": "Italy",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Jamaica",
        "currency": "JMD",
        "agnaist_ksh": '1.34'
    }, {
        "name": "Japan",
        "currency": "JPY",
        "agnaist_ksh": '1.10'
    }, {
        "name": "Jersey",
        "currency": "GBP",
        "agnaist_ksh": '0.0067'
    }, {
        "name": "Jordan",
        "currency": "JOD",
        "agnaist_ksh": '0.0065'
    }, {
        "name": "Kazakhstan",
        "currency": "KZT",
        "agnaist_ksh": '3.95'
    }, 
    {
        "name": "Kiribati",
        "currency": "AUD",
        "agnaist_ksh": '0.012'
    }, {
        "name": "North Korea",
        "currency": "KPW",
        "agnaist_ksh": '10.33'
    }, {
        "name": "South Korea",
        "currency": "KRW",
        "agnaist_ksh": '10.33'
    }, {
        "name": "Kuwait",
        "currency": "KWD",
        "agnaist_ksh": '0.0028'
    }, {
        "name": "Kyrgyzstan",
        "currency": "KGS",
        "agnaist_ksh": '0.78'
    }, {
        "name": "Lao People's Democratic Republic",
        "currency": "LAK",
        "agnaist_ksh": '86.38'
    }, {
        "name": "Latvia",
        "currency": "LVL",
        "agnaist_ksh": '0.0078'

    }, {
        "name": "Lebanon",
        "currency": "LBP",
        "agnaist_ksh": '13.89'
    }, {
        "name": "Lesotho",
        "currency": "LSL",
        "agnaist_ksh": '0.14'
    }, {
        "name": "Liberia",
        "currency": "LRD",
        "agnaist_ksh": '1.60'
    }, {
        "name": "Libya",
        "currency": "LYD",
        "agnaist_ksh": '0.042'
    }, {
        "name": "Liechtenstein",
        "currency": 'CHF',
        "agnaist_ksh": '0.0086'
    }, {
        "name": "Lithuania",
        "currency": "LTL",
        "agnaist_ksh": '0.0269'
    }, {
        "name": "Luxembourg",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Macao",
        "currency": "MOP",
        "agnaist_ksh": '0.074'
    }, {
        "name": "Republic of Macedonia",
        "currency": "MKD",
        "agnaist_ksh": '0.48'
    }, {
        "name": "Madagascar",
        "currency": "MGA",
        "agnaist_ksh": '35.00'
    }, {
        "name": "Malawi",
        "currency": "MWK",
        "agnaist_ksh": '7.20'
    }, {
        "name": "Malaysia",
        "currency": "MYR",
        "agnaist_ksh": '0.038'
    }, {
        "name": "Maldives",
        "currency": "MVR",
        "agnaist_ksh": '0.14'
    }, {
        "name": "Mali",
        "currency": "XOF",
        "agnaist_ksh": '5.09'
    }, {
        "name": "Malta",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Marshall Islands",
        "currency": "USD",
        "agnaist_ksh": '0.0092'
    }, {
        "name": "Martinique",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Mauritania",
        "currency": "MRU",
        "agnaist_ksh": '0.332'
    }, {
        "name": "Mauritius",
        "currency": "MUR",
        "agnaist_ksh": '0.3776'
    }, {
        "name": "Mexico",
        "currency": "MXN",
        "agnaist_ksh": '0.1866'
    }, {
        "name": "Micronesia, Federated States of",
        "currency": "USD",
        "agnaist_ksh": '0.0092'
    }, {
        "name": "Republic of Moldova",
        "currency": "MDL",
        "agnaist_ksh": '0.1668'
    }, {
        "name": "Monaco",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Mongolia",
        "currency": "MNT",
        "agnaist_ksh": '26.43'
    }, {
        "name": "Montenegro",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Montserrat",
        "currency": "XCD",
        "agnaist_ksh": '0.025'
    }, {
        "name": "Morocco",
        "currency": "MAD",
        "agnaist_ksh": '0.083'
    }, {
        "name": "Mozambique",
        "currency": "MZN",
        "agnaist_ksh": '0.6047'
    }, {
        "name": "Myanmar",
        "currency": "MMK",
        "agnaist_ksh": '13.00'
    }, {
        "name": "Namibia",
        "currency": "NAD",
        "agnaist_ksh": '0.1344'
    }, {
        "name": "Nauru",
        "currency": "AUD",
        "agnaist_ksh": '0.0121'
    }, {
        "name": "Nepal",
        "currency": "NPR",
        "agnaist_ksh": '1.105'
    }, {
        "name": "Netherlands",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "New Zealand",
        "currency": "NZD",
        "agnaist_ksh": '0.0131'
    }, {
        "name": "Nicaragua",
        "currency": "NIO",
        "agnaist_ksh": '0.3221'
    }, {
        "name": "Niger",
        "currency": "XOF",
        "agnaist_ksh": '5.094'
    }, {
        "name": "Nigeria",
        "currency": "NGN",
        "agnaist_ksh": '3.519'
    }, {
        "name": "Niue",
        "currency": "NZD",
        "agnaist_ksh": '0.0131'
    }, {
        "name": "Norfolk Island",
        "currency": "AUD",
        "agnaist_ksh": '0.0121'
    }, {
        "name": "Northern Mariana Islands",
        "currency": "USD",
        "agnaist_ksh": '0.0092'
    }, {
        "name": "Norway",
        "currency": "NOK",
        "agnaist_ksh": '0.0781'
    }, {
        "name": "Oman",
        "currency": "OMR",
        "agnaist_ksh": '0.0036'
    }, {
        "name": "Pakistan",
        "currency": "PKR",
        "agnaist_ksh": '1.414'
    }, {
        "name": "Palau",
        "currency": "USD",
        "agnaist_ksh": '0.0092'
    }, {
        "name": "Palestinian Territory",
        "currency": "ILS",
        "agnaist_ksh": '0.0305'
    }, {
        "name": "Panama",
        "currency": "PAB",
        "agnaist_ksh": '0.0092'
    }, {
        "name": "Papua New Guinea",
        "currency": "PGK",
        "agnaist_ksh": '0.0324'
    }, {
        "name": "Paraguay",
        "currency": "PYG",
        "agnaist_ksh": '59.36'
    }, {
        "name": "Peru",
        "currency": "PEN",
        "agnaist_ksh": '0.0336'
    }, {
        "name": "Philippines",
        "currency": "PHP",
        "agnaist_ksh": '0.4523'
    }, {
        "name": "Pitcairn",
        "currency": "NZD",
        "agnaist_ksh": '0.0131'
    }, {
        "name": "Poland",
        "currency": "PLN",
        "agnaist_ksh": '0.0356'
    }, {
        "name": "Portugal",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Puerto Rico",
        "currency": "USD",
        "agnaist_ksh": '0.0092'
    }, {
        "name": "Qatar",
        "currency": "QAR",
        "agnaist_ksh": '0.0336'
    }, {
        "name": "Romania",
        "currency": "RON",
        "agnaist_ksh": '0.0382'
    }, {
        "name": "Russian",
        "currency": "RUB",
        "agnaist_ksh": '0.7179'
    }, {
        "name": "Rwanda",
        "currency": "RWF",
        "agnaist_ksh": '9.17'
    }, {
        "name": "Saint Kitts and Nevis",
        "currency": "XCD",
        "agnaist_ksh": '0.025'
    }, {
        "name": "Samoa",
        "currency": "WST",
        "agnaist_ksh": '0.0234'
    }, {
        "name": "San Marino",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Sao Tome and Principe",
        "currency": "STN",
        "agnaist_ksh": '0.1916'
    }, {
        "name": "Saudi Arabia",
        "currency": "SAR",
        "agnaist_ksh": '0.0346'
    }, {
        "name": "Senegal",
        "currency": "XOF",
        "agnaist_ksh": '5.099'
    }, {
        "name": "Serbia",
        "currency": "RSD",
        "agnaist_ksh": '0.914'
    }, {
        "name": "Seychelles",
        "currency": "SCR",
        "agnaist_ksh": '0.1762'
    }, {
        "name": "Sierra Leone",
        "currency": "SLL",
        "agnaist_ksh": '94.2'
    }, {
        "name": "Singapore",
        "currency": "SGD",
        "agnaist_ksh": '0.0124'
    }, {
        "name": "Sint Maarten",
        "currency": "ANG",
        "agnaist_ksh": '0.0165'
    }, {
        "name": "Slovakia",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Slovenia",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Solomon Islands",
        "currency": "SBD",
        "agnaist_ksh": '0.0738'
    }, {
        "name": "Somalia",
        "currency": "SOS",
        "agnaist_ksh": '5.315'
    }, {
        "name": "South Africa",
        "currency": "ZAR",
        "agnaist_ksh": '0.1343'
    }, {
        "name": "South Sudan",
        "currency": "SSP",
        "agnaist_ksh": '1.874'
    }, {
        "name": "Spain",
        "currency": "EUR",
        "agnaist_ksh": '0.0078'
    }, {
        "name": "Sri Lanka",
        "currency": "LKR",
        "agnaist_ksh": '1.8534'
    }, {
        "name": "Sudan",
        "currency": "SDG",
        "agnaist_ksh": '3.5115'
    }, {
        "name": "Suriname",
        "currency": "SRD",
        "agnaist_ksh": '0.1306'
    }, {
        "name": "Swaziland",
        "currency": "SZL",
        "agnaist_ksh": '0.1344'
    }, {
        "name": "Sweden",
        "currency": "SEK",
        "agnaist_ksh": '0.0796'
    }, {
        "name": "Switzerland",
        "currency": "CHF",
        "agnaist_ksh": '0.0086'
    }, {
        "name": "Syria",
        "currency": "SYP",
        "agnaist_ksh": '11.62'
    }, {
        "name": "Taiwan, Province of China",
        "currency": "TWD",
        "agnaist_ksh": '0.2626'
    }, {
        "name": "Tajikistan",
        "currency": "TJS",
        "agnaist_ksh": '0.1051'
    }, {
        "name": "Tanzania",
        "currency": "TZS",
        "agnaist_ksh": '21.42'
    }, {
        "name": "Thailand",
        "currency": "THB",
        "agnaist_ksh": '0.29'
    }, {
        "name": "Togo",
        "currency": "XOF",
        "agnaist_ksh": '5.1'
    }, {
        "name": "Tokelau",
        "currency": "NZD",
        "agnaist_ksh": '0.0131'
    }, {
        "name": "Tonga",
        "currency": "TOP",
        "agnaist_ksh": '0.0209'
    }, {
        "name": "Trinidad and Tobago",
        "currency": "TTD",
        "agnaist_ksh": '0.0627'
    }, {
        "name": "Tunisia",
        "currency": "TND",
        "agnaist_ksh": '0.0254'
    }, {
        "name": "Turkey",
        "currency": "TRY",
        "agnaist_ksh": '0.0757'
    }, {
        "name": "Turkmenistan",
        "currency": "TMT",
        "agnaist_ksh": '0.0324'
    }, {
        "name": "Turks and Caicos Islands",
        "currency": "USD",
        "agnaist_ksh": '0.0092'
    }, {
        "name": "Tuvalu",
        "currency": "AUD",
        "agnaist_ksh": '0.0121'
    }, {
        "name": "Uganda",
        "currency": "UGX",
        "agnaist_ksh": '33.74'
    }, {
        "name": "Ukraine",
        "currency": "UAH",
        "agnaist_ksh": '0.2581'
    }, {
        "name": "United Arab Emirates",
        "currency": "AED",
        "agnaist_ksh": '0.0339'
    }, {
        "name": "United Kingdom",
        "currency": "GBP",
        "agnaist_ksh": '0.0067'
    }, {
        "name": "United States",
        "currency": "USD",
        "agnaist_ksh": '0.0092'
    }, {
        "name": "Uruguay",
        "currency": "UYU",
        "agnaist_ksh": '0.4088'
    }, {
        "name": "Uzbekistan",
        "currency": "UZS",
        "agnaist_ksh": '96.82'
    }, {
        "name": "Vanuatu",
        "currency": "VUV",
        "agnaist_ksh": '1.01'
    }, {
        "name": "Venezuela, Bolivarian Republic of",
        "currency": "VEF",
        "agnaist_ksh": '1,914,707,433.48'
    }, {
        "name": "Viet Nam",
        "currency": "VND",
        "agnaist_ksh": '213.9336'
    },
    {
        "name": "Virgin Islands",
        "currency": "USD",
        "agnaist_ksh": '0.0092'
    },
    {
        "name": "Yemen",
        "currency": "YER",
        "agnaist_ksh": '2.310'
    }, {
        "name": "Zambia",
        "currency": "ZMW",
        "agnaist_ksh": '0.2045'
    }, {
        "name": "Zimbabwe",
        "currency": "ZWD",
        "agnaist_ksh": '3.343'
    }
]