import React from "react";

import guide from '../assets/documents/user-guide.pdf';
import styled from "styled-components";

import Demo from "./Icons/Demo";

const Section = styled.section`
  padding: 30px 0 225px;
`;
const SectionTitle = styled.h2`
  font-size: 26px;
  font-weight: 300;
  line-height: normal;
  color: #1b5cce;
  text-align: center;
  margin-bottom: 2px;
  letter-spacing: 1.5px
`;

const SubTitle = styled.h5`
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: #aeaeae;
  margin-bottom: 25px;
  letter-spacing: 1.5px
  
  @media (min-width: 992px) {
    margin-bottom: 50px;
    letter-spacing: 1.5px
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  &:hover {
    transform: translateY(-5px) !important;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

const IconWrap = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 150px;
  border: solid 1px #eff2f9;
  background-color: #f8faff;
  margin-bottom: 30px;
  position: relative;
  > svg {
    transition: all 0.3s ease-in;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40px
  }
  &:hover {
    > svg {
      transform: translate(-50%, -50%) rotateY(360deg);
      height: 40px
    }
  }
`;

const BoxTitle = styled.h4`
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  color: #5273c7;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.58;
  color: #8f8f8f;
  margin-bottom: 0;
  max-width: 350px;
`;

const About = () => {
  const width = window.innerWidth;
  return (
    <Section id="about" >
      <div className="container">
        <SectionTitle>About us</SectionTitle>
        <SubTitle style={{fontSize: "25px"}}>PeakBooks is an online Accounting system designed for small businesses that makes book keeping easy, and can be managed by anyone. This simple to use Accounting system is helping thousands of small business owners in Kenya and the region transform the way they manage their businesses and is significantly igniting a spark in their business and helping them thrive.<br />
          PeakBooks is helping businesses achieve more than they could alone, since they found a dependable partner with PeakBooks. </SubTitle><br />
        <SectionTitle>Why us</SectionTitle>
        <div className="row">
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-right" : "fade-up"}>
              <IconWrap>
                <Demo />
              </IconWrap>
              <BoxTitle style={{fontSize: "25px"}}>Make invoicing effortless</BoxTitle>
              <Text style={{fontSize: "25px"}}>
                PeakBooks takes away the pain of manual invoicing. Create and send invoices online as well as set up reminders to track your receivables. Customize your invoices, as you want them to appear.
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos="fade-up">
              <IconWrap>
                <Demo />
              </IconWrap>
              <BoxTitle style={{fontSize: "25px"}}>Track your expenses </BoxTitle>
              <Text style={{fontSize: "25px"}}>
                Track your expenses and and ensure future sustainability of the business.With PeakBooks, you can categorize expenses for easy and send bills to your clients effortlessly.
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-left" : "fade-up"}>
              <IconWrap>
                <Demo />
              </IconWrap>
              <BoxTitle style={{fontSize: "25px"}}>Insightfull reports in an instant</BoxTitle>
              <Text style={{fontSize: "25px"}}>
                PeakBooks saves you long hours of preparing financial reports. With a click, generate P&L, balance sheets, cash flow statements and other business report.
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-left" : "fade-up"}>
              <IconWrap>
                <Demo />
              </IconWrap>
              <BoxTitle style={{fontSize: "25px"}}>Manage Inventory</BoxTitle>
              <Text style={{fontSize: "25px"}}> 
                You are able to manage the flow of stock in and out of your business without sweat.
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-left" : "fade-up"}>
              <IconWrap>
                <Demo />
              </IconWrap>
              <BoxTitle style={{fontSize: "25px"}}>Dependable Support</BoxTitle>
              <Text style={{fontSize: "25px"}}>
                Reach out to our support team, for the right solution any time. The good thing is, the support is free. .
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-left" : "fade-up"}>
              <IconWrap>
                <Demo />
              </IconWrap>
              <BoxTitle style={{fontSize: "25px"}}>Security of your data</BoxTitle>
              <Text style={{fontSize: "25px"}}>
                PeakBooks has employed rigorous safeguards to protect and safeguard your data. With different security access levels and types, we guarantee you best in class security for your books.
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-left" : "fade-up"}>
              <IconWrap>
                <Demo />
              </IconWrap>
              <BoxTitle style={{fontSize: "25px"}}>Data Backup</BoxTitle>
              <Text style={{fontSize: "25px"}}>
                PeakBooks keeps and stores your data safely, and you are able to access your data even after your subscription is over, further PeakBooks allows you to export your complete organizational data as a single compressed file.
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-left" : "fade-up"}>
              <IconWrap>
                <Demo />
              </IconWrap>
              <BoxTitle style={{fontSize: "25px"}}>Transaction approval</BoxTitle>
              <Text style={{fontSize: "25px"}}>
                PeakBooks allows you to set up approval levels on the different user roles before sending them out, this allows you the chance to verify your transactions before you send them out and ensure they are free of errors.
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-left" : "fade-up"}>
              <IconWrap>
                <Demo />
              </IconWrap>
              <BoxTitle style={{fontSize: "25px"}}>Painless setup</BoxTitle>
              <Text style={{fontSize: "25px"}}>
                Just plug and play. PeakBooks set up is easy and is very easy to use. Just click on sign up below and in under 2 minutes, you should be on the homepage of your favourite Accounting system and managing your business.
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-left" : "fade-up"}>
              <IconWrap>
                <Demo />
              </IconWrap>
              <BoxTitle style={{fontSize: "25px"}}>Convert quotations to invoices easily</BoxTitle>
              <Text style={{fontSize: "25px"}}>
                PeakBooks enables you to send out clear quotations to your customers, and easily turn accepted estimates into professional invoices; and you can also follow up payments with automated reminders and record payments received which will send receipts to your clients.
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-left" : "fade-up"}>
              <IconWrap>
                <Demo />
              </IconWrap>
              <BoxTitle style={{fontSize: "25px"}}>Convenient m-pesa payments</BoxTitle>
              <Text style={{fontSize: "25px"}}>
                Integrate PeakBooks with m-pesa payments and send out automated receipts from this integration. With additional integration, you are also able to send automated text notifications to your clients when payments are received on m-pesa pay bill or till numbers.
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-left" : "fade-up"}>
              <IconWrap>
                <Demo />
              </IconWrap >
              <BoxTitle style={{fontSize: "25px"}}>PeakBooks is very affordable</BoxTitle>
              <Text style={{fontSize: "25px"}}>
                We understand times are hard and we got your back. We have made PeakBooks affordable with you in mind, take advantage of the superior system features at very affordable price.
              </Text>
            </Box>
          </div>
        </div><br></br>
        <SectionTitle style={{fontSize: "25px"}}>
          <a href={guide} target="_blank" class="btn btn-primary">Download User Guide</a>
        </SectionTitle>
      </div>
    </Section>
  );
};

export default About;
