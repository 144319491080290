import { CURRENCY, errorToast, successToast } from '../../../_helpers/exports';
import { baseURL, baseURL_2 } from '../../../_helpers';
import axios from 'axios';
import * as moment from 'moment';
import pdfMake from "pdfmake/build/pdfmake";

export function savePaymentSend(params) {
    createPDFFromParams(params);
}

// // Private functions
// function onDataURLLoaded(params, fileReader) {
//     // Set imageLogo to data URI of file
//     params.imageLogo = fileReader.result;
//     createPDFFromParams(params);
// }

function createPDFFromParams(params) {
    let docDefinition = buildDocDefinition(params);

    const pdfGen = pdfMake.createPdf(docDefinition);

    console.log("datafile", params)

    pdfGen.getBase64((data) => {
        let formData = {

            "payment_status": params.payment_status,
            "subscription": params.subscription,
            "client_id": params.client_id,
            "amount": params.amount,
            "email": params.email,
            "name": params.name,
            "plan_type": params.plan_type,
            // "mpesa_code": params.mpesa_code,
            "filesend": data,
            "msisdn": params.msisdn
        }

        console.log("formData", JSON.stringify(formData))
        let url = baseURL+ "mpesa_update"
        axios.post(url, formData).then((response) => {
            successToast("Payment Successful")
            window.setTimeout(() => {
                // this._onButtonClick();
                window.location.href = "/log_in";
            }, 6000);


        }).catch(error => {

            console.log(error)


            console.log("ERROR")
        })

    })
}

function buildDocDefinition(params) {
    console.log("params", params.lineItems)



    return {
        header: {
            columns: [
                headerNotes(params)
            ]
        },
        // background: function(currentPage, pageSize) {
        //     return `page ${currentPage} with size ${pageSize.width} x ${pageSize.height}`
        //   },

        // userPassword: params.invoice_no,
        // ownerPassword: params.invoice_no,
        // permissions: {
        //     printing: 'highResolution', //'lowResolution'
        //     modifying: false,
        //     copying: false,
        //     annotating: true,
        //     fillingForms: true,
        //     contentAccessibility: true,
        //     documentAssembly: true
        // },
        footer: function () {
            return [
                { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 595 - 10, y2: 10, lineWidth: 0.5 }] },
                {
                    columns: [
                        { text: `${params.footer}`, bold: true, color: 'black', fontSize: 9, alignment: 'center', marginTop: 5 }
                    ],

                },
            ];
        },

        content: [

            buildHeaderInformation(params),
            buildLineItemsTable(params),
        ],
    };
}


// Returns an array
function headerNotes(params) {

    return [
        { text: `\tPAYMENT RECEIPT \t\t`, alignment: 'center', bold: true, margin: [5, 15, 0, 20] }
    ]
}


function formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: "ABS",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount).replaceAll('ABS', CURRENCY);
};



function buildHeaderInformation(params) {

    let date1 = moment(new Date()).format('DD, MMM YYYY');
    const optionalDataKeys = [];
    const optionalDataValues = [];
    // let total = params.lineItems.reduce((sum, lineItem) => {
    //     return sum + lineItem.quantity * lineItem.price;
    // }, 0);
    Object.entries({
        "Date :": date1,
    }).forEach(([key, value]) => {
        if (value) {
            optionalDataKeys.push(key);
            optionalDataValues.push(value);
        }
    });

    return {
        columns: [{
            stack: [

                {
                    text: "PeakInsight",
                    color: "black",
                    margin: [0, 0, 0, 50],
                    // background: '#eee',
                },

                {
                    text: `Payment Date:  ${moment(new Date()).format('DD, MMM YYYY')}`,
                    color: "black",

                    margin: [0, 0, 0, 20],
                    // background: '#eee',
                },
                {
                    text: `Plan#: ${params.plan_type} Plan`,
                    color: "black",
                    margin: [0, 0, 0, 20],

                    // background: '#eee',
                },

                {
                    text: "Bill to",
                    color: "black",
                    // background: '#eee',
                },

                {
                    text: params.name,
                    color: "black",
                    bold: true,
                    margin: [0, 0, 0, 20],
                    // background: '#eee',
                },

            ],
        },

        {
            background: '#5bc0de',

            stack: [

                {
                    text: `\t Amount Received ${params.amount}\t\t`, alignment: 'left', background: '#5cb85c', color: '#FFFFFF', margin: [5, 15, 0, 5],
                    bold: true,
                    fontSize: 16
                }
            ],
            alignment: 'right',
        },
        ],
        styles: {
            subheader: {
                fontSize: 9,
                color: '#FFFFFF',
                background: '#2361AE',
                margin: [0, 15, 0, 5]
            }
        },
        // optional space between columns
        columnGap: 10,
        margin: [0, 0, 0, 30],
    };
}




function buildLineItemsTable(params) {
    return {
        table: {
            widths: ['*', '25%', '25%', '25%'],

            headerRows: 1,
            body: [
                [
                    { text: 'Bill#' },
                    { text: 'Bill Date' },
                    { text: 'Bill Amount (' + params.currency + ')' },
                ],

                [
                    { text: params.plan_type },
                    { text: moment(new Date()).format('DD, MMM YYYY') },
                    { text: formatCurrency(params.amount) },
                ],

            ],
        },
        layout: 'lightHorizontalLines',
    };
}


