import axios from 'axios';
import React from 'react';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Card, CardBody, Button } from 'reactstrap';
import { baseURL } from '../../../_helpers';
import { CONFIG, errorToast, successToast, ToastTable, USER_ID, ROLE, USER_ID_ACC } from '../../../_helpers/exports';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import * as moment from 'moment';

const AddBudget = () => {

    const [sales, setSales] = useState(0);
    const [cost_of_goods, setCostOfGoods] = useState(0);

    let now = new Date();

    // new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('DD MMM, YYYY')
    let start_D = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end_d = moment(start_D).add(1, "days").subtract(1, "seconds");


    // alert(start_D.format('YYYY-MM-DD'));
    // eslint-disable-next-line
    const [start, setStart] = useState(start_D)
    // eslint-disable-next-line
    const [end, setEnd] = useState(end_d)


    const [startDate, setStartDate] = useState(start_D)
    const [endDate, setEndDate] = useState(moment().startOf('year').format('YYYY-MM-DD'))


    const [date_value, setDateValue] = useState("This year");

    // total expense
    const [profession_expe, setProfExp] = useState(0);
    const [admin_exp, setAdminExp] = useState(0);
    const [staff_exp, setStaffExp] = useState(0);
    const [asset_imp, setAssetImp] = useState(0);
    const [finance_cost, setFinanceCost] = useState(0);
    const [est_exp, setEstablishCost] = useState(0);
    const [other_exp, setOtherExpe] = useState(0);

    //current expense
    const [cash_bank, setCashBank] = useState(0);
    const [account_receivable, setAmountReiceived] = useState(0);
    const [closing_stock, setClosingStock] = useState(0);

    // other current expense
    const [other_current, setOtherCurrent] = useState(0);

    // non current expense
    const [furniture, setFurniture] = useState(0);
    const [intangible_asset, setIntangibleAsset] = useState(0);

    // other non current expense
    const [other_non_current, setOtherNonCurrent] = useState(0);



    // current liability
    const [account_payable, setAccountPayable] = useState(0);
    const [short_term_loans, setShortTerm] = useState(0);
    const [short_term_related, setShortTermRelated] = useState(0);
    const [tax_payable, setTaxPayable] = useState(0);

    // other current liability
    const [other_current_libility, setOtherCurrentLiability] = useState(0);


    // non current liability
    const [long_term, setLongTerm] = useState(0);
    const [long_term_related, setLongTermRelated] = useState(0);


    // other non current liability
    const [non_current_liability, setNonCurrentLiability] = useState(0);


    // share capital
    const [share_capital, setShareCapital] = useState(0);
    const [related_earnings, setRelatedEarnings] = useState(0);
    const [direct_account, setDirectAccount] = useState(0);



    const [other_equities, setOtherEqueities] = useState(0);

    const [load, setLoad] = useState(false);

    const [updated_status, setUpdateStatus] = useState(false);

    const [queryString, setQueryString] = useState("")



    useEffect(() => {
        let url = baseURL + `budget?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID;
        axios.all([
            axios.get(url, CONFIG),
        ]).then(axios.spread((budgetResponse) => {
            setLoad(false);
            var data = [];
            if (budgetResponse.data.data.length > 0) {
                setUpdateStatus(true);

                setSales(budgetResponse.data.data[0].sales);
                setCostOfGoods(budgetResponse.data.data[0].cost_of_goods);

                setProfExp(budgetResponse.data.data[0].profession_expe);
                setAdminExp(budgetResponse.data.data[0].admin_exp);
                setStaffExp(budgetResponse.data.data[0].staff_exp);
                setAssetImp(budgetResponse.data.data[0].asset_imp);
                setFinanceCost(budgetResponse.data.data[0].finance_cost);
                setEstablishCost(budgetResponse.data.data[0].est_exp);
                setOtherExpe(budgetResponse.data.data[0].other_exp);

                setCashBank(budgetResponse.data.data[0].cash_bank);
                setAmountReiceived(budgetResponse.data.data[0].account_receivable);
                setClosingStock(budgetResponse.data.data[0].closing_stock);

                setOtherCurrent(budgetResponse.data.data[0].other_current);

                setFurniture(budgetResponse.data.data[0].furniture);
                setIntangibleAsset(budgetResponse.data.data[0].intangible_asset);
                setOtherNonCurrent(budgetResponse.data.data[0].other_non_current);
                setAccountPayable(budgetResponse.data.data[0].account_payable);
                setShortTerm(budgetResponse.data.data[0].short_term_loans);
                setShortTermRelated(budgetResponse.data.data[0].short_term_related);
                setTaxPayable(budgetResponse.data.data[0].tax_payable);
                setOtherCurrentLiability(budgetResponse.data.data[0].other_current_libility);
                setLongTerm(budgetResponse.data.data[0].long_term);
                setLongTermRelated(budgetResponse.data.data[0].long_term_related);
                setNonCurrentLiability(budgetResponse.data.data[0].non_current_liability);
                setShareCapital(budgetResponse.data.data[0].share_capital);
                setRelatedEarnings(budgetResponse.data.data[0].related_earnings);
                setDirectAccount(budgetResponse.data.data[0].direct_account);
                setOtherEqueities(budgetResponse.data.data[0].other_equities);

                setStartDate(moment(budgetResponse.data.data[0].start_date).format('YYYY-MM-DD'));
                setEndDate(moment(budgetResponse.data.data[0].end_date).format('YYYY-MM-DD'));
                setDateValue(moment(budgetResponse.data.data[0].start_date).format('YYYY-MM-DD') + " " + moment(budgetResponse.data.data[0].end_date).format('YYYY-MM-DD'));



            } else {
                setUpdateStatus(false);
            }

        })).catch(error => {
            console.log('bayoo', error.response)
        })
    }, [queryString]);


    const onSubmitUpdate = (e) => {
        e.preventDefault();
        setLoad(true)

        let formData = {
            'sales': sales,
            'cost_of_goods': cost_of_goods,
            'profession_expe': profession_expe,
            'admin_exp': admin_exp,
            'staff_exp': staff_exp,
            'asset_imp': asset_imp,
            'finance_cost': finance_cost,
            'est_exp': est_exp,
            'other_exp': other_exp,
            'cash_bank': cash_bank,
            'account_receivable': account_receivable,
            'closing_stock': closing_stock,
            'other_current': other_current,
            'furniture': furniture,
            'intangible_asset': intangible_asset,
            'other_non_current': other_non_current,
            'account_payable': account_payable,
            'short_term_loans': short_term_loans,
            'short_term_related': short_term_related,
            'tax_payable': tax_payable,
            'other_current_libility': other_current_libility,
            'long_term': long_term,
            'long_term_related': long_term_related,
            'non_current_liability': non_current_liability,
            'share_capital': share_capital,
            'related_earnings': related_earnings,
            'direct_account': direct_account,
            'other_equities': other_equities,
            'start_date': startDate,
            'end_date': endDate,
            'client_id': USER_ID
        }

        axios.put(baseURL + `budget`, formData, CONFIG)
            .then((response) => {
                if (response.data.status) {
                    setLoad(false)

                    successToast("Budget Updated Successfully");
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                } else {
                    setLoad(false)

                    errorToast(response.data.message);
                }
            })
            .catch((error) => {
                setLoad(false)

                errorToast(error.response.data.message);
                console.log('bayoo', error)
            })
    }


    const onSubmit = (e) => {
        e.preventDefault();
        setLoad(true)



        let formData = {
            'sales': sales,
            'cost_of_goods': cost_of_goods,
            'profession_expe': profession_expe,
            'admin_exp': admin_exp,
            'staff_exp': staff_exp,
            'asset_imp': asset_imp,
            'finance_cost': finance_cost,
            'est_exp': est_exp,
            'other_exp': other_exp,
            'cash_bank': cash_bank,
            'account_receivable': account_receivable,
            'closing_stock': closing_stock,
            'other_current': other_current,
            'furniture': furniture,
            'intangible_asset': intangible_asset,
            'other_non_current': other_non_current,
            'account_payable': account_payable,
            'short_term_loans': short_term_loans,
            'short_term_related': short_term_related,
            'tax_payable': tax_payable,
            'other_current_libility': other_current_libility,
            'long_term': long_term,
            'long_term_related': long_term_related,
            'non_current_liability': non_current_liability,
            'share_capital': share_capital,
            'related_earnings': related_earnings,
            'direct_account': direct_account,
            'other_equities': other_equities,
            'start_date': startDate,
            'end_date': endDate,
            'client_id': USER_ID
        }

        axios.post(baseURL + `budget`, formData, CONFIG)
            .then((response) => {
                if (response.data.status) {
                    successToast("Budget Added Successfully");
                    setLoad(false)
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                } else {
                    setLoad(false)

                    errorToast(response.data.message);
                }
            })
            .catch((error) => {
                setLoad(false)

                errorToast(error.response.data.message);
                console.log('bayoo', error)
            })
    }


    let ranges = {
        "Today Only": [moment(start), moment(end)],
        "Yesterday Only": [
            moment(start).subtract(1, "days"),
            moment(end).subtract(1, "days")
        ],
        "3 Days": [moment(start).subtract(3, "days"), moment(end)],
        "5 Days": [moment(start).subtract(5, "days"), moment(end)],
        "1 Week": [moment(start).subtract(7, "days"), moment(end)],
        "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
        "1 Month": [moment(start).subtract(1, "months"), moment(end)],
        "1 Year": [moment(start).subtract(1, "years"), moment(end)]
    };
    let local = {
        "format": "DD-MM-YYYY",
        "sundayFirst": false
    }

    const applyCallback = (startDate, endDate) => {
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)
        let Sdate1 = moment(startDate).format('DD MMM, YYYY');
        let Edate2 = moment(endDate).format('DD MMM, YYYY');


        setStartDate(moment(startDate).format('YYYY-MM-DD'));
        setEndDate(moment(endDate).format('YYYY-MM-DD'));
        setDateValue(Sdate1 + " to " + Edate2)
    }


    return (
        <div style={{ marginTop: "-20px" }} >

            <Card>
                <ToastTable />
                <CardBody>


                    <Form className="form login-form" onSubmit={updated_status ? onSubmitUpdate : onSubmit} >
                        <h5><b>Add Budget</b></h5>
                        <div className="form__form-group">


                            <div className="col-md-10 offset-1"><br />
                                <label><b>Set Period</b></label>

                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-6">
                                            <DateTimeRangeContainer
                                                ranges={ranges}
                                                start={moment().startOf('year')}
                                                end={end}
                                                local={local}
                                                applyCallback={applyCallback}>
                                                <FormControl
                                                    id="formControlsTextB"
                                                    type="text"
                                                    value={date_value}
                                                    label="Text"
                                                    placeholder="Filter by Date"
                                                />
                                            </DateTimeRangeContainer>
                                        </div>


                                    </div>
                                </div><br />

                                <h4><b>Income Statement
                                </b></h4><br />
                                <div className='row'>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Set Sales</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="sales" placeholder='Total Sales'
                                                    value={sales} onChange={e => setSales(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Set Cost of Goods</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="cost_of_goods" placeholder='Cost of Goods'
                                                    value={cost_of_goods} onChange={e => setCostOfGoods(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Set Professsional Expenses</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="profession_expe" placeholder='Professsional Expenses'
                                                    value={profession_expe} onChange={e => setProfExp(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Set Administration Expenses</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="admin_exp" placeholder='Administration Expenses'
                                                    value={admin_exp} onChange={e => setAdminExp(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Set Staff Expenses</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="staff_exp" placeholder='Staff Expenses'
                                                    value={staff_exp} onChange={e => setStaffExp(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Allowances for Asset Impairements</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="asset_imp" placeholder='Allowances for Asset Impairements'
                                                    value={asset_imp} onChange={e => setAssetImp(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Set Finance Cost</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="finance_cost" placeholder='Finance Cost'
                                                    value={finance_cost} onChange={e => setFinanceCost(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Set Establishment Expenses</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="est_exp" placeholder='Establishment Expenses                                                 '
                                                    value={est_exp} onChange={e => setEstablishCost(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Set Other Expenses</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="other_exp" placeholder='Other Expenses'
                                                    value={other_exp} onChange={e => setOtherExpe(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                {/* <h4><b>Balance sheet
                                </b></h4>
                                <h4><b>Current Expense
                                </b></h4><br />
                                <div className='row'>

                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Cash and Bank Balances</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="cash_bank" placeholder='Cash and Bank Balances                                                    '
                                                    value={cash_bank} onChange={e => setCashBank(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Accounts Receivables</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="account_receivable" placeholder='Accounts Receivables'
                                                    value={account_receivable} onChange={e => setAmountReiceived(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Set Closing Stock</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="closing_stock" placeholder='Closing Stock                                                    '
                                                    value={closing_stock} onChange={e => setClosingStock(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Other Current Assets</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="other_current" placeholder='Other Current Assets                                                    '
                                                    value={other_current} onChange={e => setOtherCurrent(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h4><b>Non current Assets</b></h4>
                                <div className='row'>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Set Property Furniture and Equipment</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="furniture" placeholder='Property Furniture and Equipment                                                    '
                                                    value={furniture} onChange={e => setFurniture(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Set Intangible Assets</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="intangible_asset" placeholder='Intangible Assets                                                    '
                                                    value={intangible_asset} onChange={e => setIntangibleAsset(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Other Non Current Assets</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="other_non_current" placeholder='Other Non Current Assets                                                    '
                                                    value={other_non_current} onChange={e => setOtherNonCurrent(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <h4><b>LIABILITIES and EQUITIES</b></h4>

                                <h4><b>Liabilities</b></h4><br />

                                <h4><b>Current Liabilities</b></h4><br />
                                <div className='row'>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Accounts Payable</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="other_current" placeholder='Accounts Payable                                                    '
                                                    value={account_payable} onChange={e => setAccountPayable(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Short Term Loans</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="other_current" placeholder='Short Term Loans                                                    '
                                                    value={short_term_loans} onChange={e => setShortTerm(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Short term related parties</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="short_term_related" placeholder='Short Term Related                                                    '
                                                    value={short_term_related} onChange={e => setShortTermRelated(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Tax Payable</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="tax_payable" placeholder='Tax Payable                                                    '
                                                    value={tax_payable} onChange={e => setTaxPayable(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Other current Liabilities</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="other_current_libility" placeholder='Other Current Liabilities                                                    '
                                                    value={other_current_libility} onChange={e => setOtherCurrentLiability(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />

                                <h4><b>Non Current Liabilities</b></h4><br />

                                <div className='row'>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Long term loans</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="long_term" placeholder='Long term loans                                                    '
                                                    value={long_term} onChange={e => setLongTerm(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Long term related parties</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="long_term_related" placeholder='Long Term Related                                                    '
                                                    value={long_term_related} onChange={e => setLongTermRelated(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Other non current Liabilities</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="non_current_liability" placeholder='Other non current Liabilities                                                    '
                                                    value={non_current_liability} onChange={e => setNonCurrentLiability(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <h4><b>Equities</b></h4><br />

                                <div className='row'>

                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Share Capital</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="share_capital" placeholder='Share Capital                                                    '
                                                    value={share_capital} onChange={e => setShareCapital(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Retained Earnings</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="related_earnings" placeholder='Retained Earnings                                                    '
                                                    value={related_earnings} onChange={e => setRelatedEarnings(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Directors Account</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="direct_account" placeholder='Directors Account                                                    '
                                                    value={direct_account} onChange={e => setDirectAccount(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="form-label">Other Equities</label>
                                            </div>
                                            <div className="col-md-12">
                                                <input id="input" type="number" className="form-control"
                                                    required
                                                    name="other_equities" placeholder='Other Equities                                                    '
                                                    value={other_equities} onChange={e => setOtherEqueities(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <br />
                                <div className="col-3 offset-4 float-right" >
                                    <Button className="account__btn" type='submit' color="success"> {
                                        load ? "Please wait..." : "Save"
                                    }</Button>
                                </div>

                            </div>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
}
export default AddBudget;