import React, { Fragment } from 'react';

import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { CURRENCY, TOKEN, USER_ID, ROLE, USER_ID_ACC, formatCurrency } from '../../_helpers/exports';
import * as moment from 'moment';
// import CustomerCreate from './createItem';
import { Link, Navigate } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import createItem from './createBankAccount';
import Select from 'react-select';

import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import Alert from '../../shared/components/Alert';
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";

import createinvestment from './createBankAccount';
import createBankAccount from './createBankAccount';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'

// import CustomerEdit from './customer_edit';



export class Banking extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick3 = this._onButtonClick3.bind(this);
        this._onButtonClick5 = this._onButtonClick5.bind(this);
        this.applyCallback = this.applyCallback.bind(this);

        this.handleOnClick = this.handleOnClick.bind(this);

        this.columns = [
            {
                key: "account_type",
                TrOnlyClassName: 'tsc',
                text: "Account",
                className: "tsc",
                align: "left"
            },

            {
                key: "maccount_balance",
                TrOnlyClassName: 'tsc',
                text: "Amount in Bank",
                className: "tsc",
                align: "left"
            },

            {
                key: "mpeak_amount",
                TrOnlyClassName: 'tsc',
                text: "Amount in PeakBooks Account",
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        // eslint-disable-next-line
                        <Fragment className="center" >


                            <button className="btn btn-primary  btn-sm"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.toggleModalCreate(record) }} >
                                Create Transaction
                            </button>

                            <button className="btn btn-success  btn-sm"
                                title="Eith statement"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.onStatment(record) }} >
                                View Details
                            </button>

                        </Fragment>
                    );
                }
            }

        ];

        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 30, 40, 50],
            show_filter: false,
            show_pagination: false,
            pagination: 'advance',
            page_size: 300,
            show_length_menu: false,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search.. Item Name",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }

        let now = new Date();



        let start = moment(new Date(new Date().getFullYear(), 0, 1));

        let end = moment(new Date());

        // let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        this.state = {
            start: start,
            end: end,
            isPageLoad: true,
            isLoading: true,
            showError: false,
            isShowError: false,
            showAccount: false,
            showComponent: false,
            open_type: 'update',
            isOpen: false,
            _onShow: false,
            value: "This Year",
            showEdit: false,
            hideComponent: false,
            data: [],
            transaction_type: "money_in",
            terms3: [
                {
                    label: 'Money In',
                    value: 'money_in',
                },
                {
                    label: 'Money Out',
                    value: 'money_out',
                }
            ],

            category_type: "Customer Payment",
            category_itemsMoneyIn: [
                {
                    label: 'Customer Payment',
                    value: 'Customer Payment'
                },
                {
                    label: 'Capital',
                    value: 'Capital'
                },
                {
                    label: 'Advance Payment',
                    value: 'Advance Payment'
                }
            ],


            category_MoneyOut: [
                {
                    label: 'Supplier Payment',
                    value: 'Supplier Payment',
                },
                {
                    label: 'Salaries & Wages',
                    value: 'Salaries & Wages',
                },
                {
                    label: 'Capital Withdrawal',
                    value: 'Capital Withdrawal',
                }
            ],

        }
    }

    async handleOnClick(record) {
        if (await confirm("Are your sure?")) {
            this.onDelete(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }


    onStatment(record) {

        // alert(JSON.stringify(record))
        localStorage.setItem("name_account_bank", record.account_type)

        localStorage.setItem("name_account_bakance", record.account_balance)

        this.setState({
            showAccount: true
        }, function () {
        });

    }



    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {

        this.getData(this.state.start, this.state.end);
    }


    getData = (startDate, endDate) => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = `${baseURL}banking?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&&startDate="${moment(startDate).format('YYYY-MM-DD')} 00:00:00"&&endDate="${moment(endDate).format('YYYY-MM-DD')} 23:59:59"`;
        //  alert(url)
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((itemResponse) => {
            // alert(JSON.stringify(itemResponse))
            this.setState({
                item: itemResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];

                    this.setState({
                        total: itemResponse.data.data.length
                    })
                    if (this.state.item.length > 0) {
                        for (let i = 0; i < this.state.item.length; i++) {

                            let amountn = { mpeak_amount: formatCurrency(this.state.item[i].peak_amount) };
                            let maccount_balance = { maccount_balance: formatCurrency(this.state.item[i].account_balance) };

                            data.push(Object.assign(amountn, maccount_balance, this.state.item[i]));
                            this.setState({
                                data: data.filter(person => person.account_type != null)
                            })

                            // data.filter(person => person.due_amount_updated != 0)
                        }
                    } else {
                        this.setState({
                            data: data
                        })
                    }

                })
        })).catch(error => {
            console.log('bayoo', error)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showEdit: false,
        });
    }

    _onButtonClick5() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showEdit: false,
        });
    }

    _onButtonClick3() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            _onShow: false,
            showModals: false
        });
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }




    applyCallback(startDate, endDate) {


        let Sdate1 = moment(startDate).format('DD MMM, YYYY');
        let Edate2 = moment(endDate).format('DD MMM, YYYY');

        this.setState({
            start: startDate,
            end: endDate,
            value: Sdate1 + " " + Edate2
        })


        // alert(endDate)
        this.getData(startDate, endDate);


    }


    toggleModalCreate = e => {
        // localStorage.setItem("name_account_bank", record.account_type)

        this.setState({
            isOpen: true,
            open_type: 'update',
            account_type: e.account_type
        });
    };

    toggleModalReset = e => {
        // localStorage.setItem("name_account_bank", record.account_type)

        this.setState({
            isOpen: true,
            open_type: 'reset',
            account_type: e.account_type
        });
    };

    closeModalUpload = e => {
        this.setState({
            isOpen: false,
        });
    };


    handleProfile = event => {

        this.setState({ amount: event.target.value });
    };

    handleCategory = event => {

        this.setState({ category: event.target.value });
    };

    submitProfile = event => {
        event.preventDefault();
        if (this.state.amount > 0) {

            let formData = {
                "account_type": this.state.account_type,
                "amount_received": this.state.amount,
                "category_type": this.state.category_type,
                "transaction_type": this.state.transaction_type,
                'open_type': this.state.open_type,
                "created_by": USER_ID
            }
            console.log("DATA_AMOUNT", JSON.stringify(formData))
            this.setState({ isLoading: true });
            axios.put(baseURL + `bank_amount_update`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': TOKEN
                },
            }).then((response) => {
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                    window.scrollTo(0, 0)
                    window.setTimeout(() => {
                        this.setState({ isShowError: false, isOpen: false });
                        this.getData(this.state.start, this.state.end);
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                }
            })
                .catch((error) => {
                    this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
                })
        } else {
            alert("Enter a valid amount")
        }
    }


    handleChangeCategory = value => {

        if (value != null) {
            this.setState(
                {
                    category_type: value.value.toString()
                });
        }
        else {
            this.setState(
                {
                    category_type: "Advance Payment",
                });
        }
    };


    handleChangeType = value => {

        if (value != null) {
            this.setState(
                {
                    transaction_type: value.value.toString()
                });
        }
        else {
            this.setState(
                {
                    transaction_type: "money_in",
                });
        }
    };



    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        const { showEdit } = this.state;
        let now = new Date();


        let ranges = {

            "Today Only": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)), moment(this.state.end)],
            "Yesterday Only": [
                moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(1, "days"),
                moment(this.state.end).subtract(1, "days")
            ],
            "3 Days": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(3, "days"), moment(this.state.end)],
            "5 Days": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(5, "days"), moment(this.state.end)],
            "1 Week": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(7, "days"), moment(this.state.end)],
            "2 Weeks": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(14, "days"), moment(this.state.end)],
            "1 Month": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(1, "months"), moment(this.state.end)],
            "6 Months": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(1, "months"), moment(this.state.end)],
            "1 Year": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(1, "years"), moment(this.state.end)]
        };
        let local = {
            "format": "DD-MM-YYYY",
            "sundayFirst": false
        }
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
                    <div>
                        {this.state.showAccount == true ?
                            <Navigate to="/bank_details" />
                            : null}
                        < Card >

                            <Modal
                                isOpen={this.state.isOpen}
                                onRequestClose={e => {
                                    this.closeModalUpload(e);
                                }}
                                contentLabel="My dialog"
                                className="mymodal"
                                onAfterOpen={() => {
                                    document.body.style.overflow = 'hidden';
                                }}
                                onAfterClose={() => {
                                    document.body.removeAttribute('style');
                                }}
                                overlayClassName="myoverlay"
                                closeTimeoutMS={500}
                            >
                                <MDBCloseIcon onClick={this.closeModalUpload} />
                                <h5><b>Create Tranasaction</b></h5><br />


                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>


                                </div> : null}

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>
                                    </div>
                                ) : null}


                                <div className="col-md-12">

                                    <div className="col-md-12">
                                        <label>Trnsaction Type</label>
                                    </div>
                                    <div className="col-md-12">


                                        <Select
                                            isClearable
                                            // onInputChange={this.loadOptions}
                                            placeholder="Select transaction type"
                                            options={this.state.terms3}
                                            // onInputChange={this.loadOptions}
                                            autosize={true}
                                            onChange={this.handleChangeType}
                                            menuPortalTarget={document.body}
                                            name="transaction_type"
                                        />
                                        <br />

                                    </div>


                                    <div className="col-md-12">
                                        <label>Category Type</label>
                                    </div>
                                    <div className="col-md-12">


                                        <Select
                                            isClearable
                                            // onInputChange={this.loadOptions}
                                            placeholder="Select Category type"
                                            options={this.state.transaction_type == 'money_in' ? this.state.category_itemsMoneyIn : this.state.category_MoneyOut}
                                            // onInputChange={this.loadOptions}
                                            autosize={true}
                                            onChange={this.handleChangeCategory}
                                            menuPortalTarget={document.body}
                                            name="category_type"
                                        />
                                        <br />

                                    </div>
                                    <br />

                                    <input
                                        className="form-group"
                                        type="number"
                                        required
                                        name="amount"
                                        placeholder="Enter Amount"
                                        onChange={this.handleProfile}
                                    />
                                    <br />
                                    <div className="col-6 offset-3">
                                        <br />
                                        <Button color="primary" className="btn-sm " onClick={this.submitProfile} >
                                            {this.state.isLoading1 ? "Updating..." : "Submit"}
                                            <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;

                                        <br />
                                    </div>

                                </div>
                            </Modal>

                            <CardBody >
                                <div className="panel-body" >
                                    <div className="row">

                                        <div className="col-md-6">
                                            <h4><b>List of Accounts</b></h4><br />
                                        </div>
                                        <div className="col-md-6">
                                            <Link to="/create-bank">
                                                <Button className="pull-right" color="primary
                                                    " onClick={this._onButtonClick} outline>Add Bank Account
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}
                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}
                                    <br />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="col-12 pull-right">
                                                <div className="col-md-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <DateTimeRangeContainer
                                                                ranges={ranges}
                                                                start={this.state.start}
                                                                end={this.state.end}
                                                                local={local}
                                                                smartMode
                                                                applyCallback={this.applyCallback}>
                                                                <FormControl
                                                                    id="formControlsTextB"
                                                                    type="text"
                                                                    label="Text"
                                                                    value={this.state.value}

                                                                    placeholder="Filter by Date"
                                                                />
                                                            </DateTimeRangeContainer>
                                                        </div>

                                                        <div className="col-6 ">

                                                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {

                                                                this.getData(moment().subtract(6, 'months'), moment(new Date()));

                                                                this.setState({
                                                                    start: moment().subtract(6, 'months'),
                                                                    end: moment(new Date()),
                                                                    value: "This Year"

                                                                })


                                                            }} >Clear</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            {/* <div className="col-md-10 offset-1">
                                                <button className="pull-right" title="refresh" style={{ background: "white", border: "0px" }} onClick={this.getData}>
                                                    <RefreshIcon />
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>

                                    <br />


                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} />

                                </div>
                            </CardBody>

                        </Card>
                    </div>
                )
                }
                {
                    showComponent && (
                        <div>
                            <createBankAccount />
                        </div>
                    )
                }
                {/*

                {
                    showEdit && (
                        <>
                            <ItemEdit />
                        </>

                    )
                } */}
            </div >
        )
    }
}