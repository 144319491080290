import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { TOKEN } from '../../_helpers/exports';
import { Link, Navigate } from 'react-router-dom';
import * as moment from 'moment';
import addUser from './addUser';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';

export class Transactions extends React.Component {
    constructor(props) {
        super(props);
        this._onButtonClick = this._onButtonClick.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);

        this.columns = [

            {
                key: "TransID",
                TrOnlyClassName: 'tsc',
                text: "Transaction ID",
                className: "tsc",
                align: "left"
            },
            {
                key: "phoneNumber",
                TrOnlyClassName: 'tsc',
                text: "Phone Number",
                className: "tsc",
                align: "left"
            },

            {
                key: "TransAmount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "status",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.Status === 0 ?
                                <span span class="badge-danger" style={{ borderRadius: "5px", padding: "2px", }}>
                                    Pending
                                </span>
                                : null}
                            {record.Status === 1 ?
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Processed
                                </span>
                                : null}
                        </Fragment >
                    );
                }
            },
            {
                key: "subscription_due",
                text: "Subscription Due Date",
                TrOnlyClassName: 'tsc',
                className: "tsc",
                align: "left"
            },


            {
                key: "payment_status",
                TrOnlyClassName: 'tsc',
                text: "Subscription Type",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.Status === 0 ?
                                <div>
                                    {record.payment_status === 0 ?
                                        <span class="badge-info" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Trial
                                          </span>
                                        : null}
                                </div>
                                : null}
                            {record.Status === 1 ?
                                <div>
                                    {record.payment_status === 4 ?
                                        <span class="badge-info" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Wallet Top Up
                                          </span>
                                    : null}
                                   
                                    {record.payment_status === 0 ?
                                        <span class="badge-info" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Enterpise
                                          </span>
                                        : null}
                                    {record.payment_status === 1 ?
                                        <span class="badge-info" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Basic
                                          </span>
                                        : null}
                                    {record.payment_status === 2 ?
                                        <span class="badge-info" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Standard
                                          </span>
                                        : null}
                                </div>

                                : null}
                        </Fragment >
                    );
                }
            },
            {
                key: "dates",
                text: "Date Created",
                TrOnlyClassName: 'tsc',
                className: "tsc",
                align: "left"
            },
        ];
        this.config = {
            key_column: "tsc",
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 100,
            button: {
                excel: true,
                csv: true
            },
            filename: "transactions",
            show_length_menu: false,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Transaction ID...",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            moreUsers: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }
    componentDidMount() {
        this.getData();
    }

    async handleOnClick2(record) {
        console.log("hoge!");
        if (await confirm("Proceed in activating this account?")) {
            this.onApprove(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }


    async handleMore(record) {
        console.log("hoge!");


        localStorage.setItem("client_id_admin", record.client_id)
        this.setState({
            moreUsers: true
        })

    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Proceed in deactivating this account?")) {
            this.onDeactivate(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `transactions?` + queryString
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                isLoading: false
            }, function () {
                var data = [];
                console.log("BUG", this.state.admins)
                for (let i = 0; i < this.state.admins.length || 0; i++) {
                    this.setState({
                        total: branchResponse.data.total
                    })
                    // eslint-disable-next-line
                    let mail = { name: this.state.admins[i].first_name + " " + this.state.admins[i].last_name };
                    // eslint-disable-next-line
                    let date = { dates: moment(this.state.admins[i].date_created).format("DD-MM-YYYY HH:mm:ss") };
                    let subscription_due = { subscription_due: moment(this.state.admins[i].subscription_date).format("DD-MM-YYYY HH:mm:ss") };
                    data.push(Object.assign(mail, date, subscription_due, this.state.admins[i]));
                    this.setState({
                        data: data
                    })
                    console.log("bugs", data);
                }
            }
            );
        }))

    }
    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: false,

        });
    }

    render() {
        const { showComponent, moreUsers } = this.state;
        const { hideComponent } = this.state;
        // console.log("Load", isLoading);
        return (
            <div style={{ marginTop: "-20px" }} >

                {moreUsers == true ?
                    <Navigate to="/client-users" />
                    :
                    null
                }
                {showComponent && (
                    <div>
                        <addUser />
                    </div>
                )}
                {!hideComponent && (


                    <div>
                        < Card >
                            <CardBody >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>MPESA Transactions</b></h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <br />
                                        <h4>Total Transactions: <b>{this.state.total}</b></h4>
                                        <br />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-md-10 offset-1">
                                            <button className="pull-right" title="refresh" style={{ background: "white", border: "0px" }} onClick={this.getData}>
                                                <RefreshIcon />
                                            </button>
                                        </div>
                                    </div>
                                    <br />
                                </div>



                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={this.state.isLoading}
                                    total_record={this.state.total}
                                    onChange={this.tableChangeHandler} />
                            </CardBody>

                        </Card>
                    </div>

                )
                }
            </div>
        )
    }
}