function getBaseUrl() {
    if (process.env.REACT_APP_ENV === "local") {
        // In local environment, return the base URL for the local environment
        return process.env.REACT_APP_LOCAL_BASE_URL;
    } else {
        // otherwise(ie live server)
        return process.env.REACT_APP_BASE_URL;
    }
}

function getBaseUrl2() {
    if (process.env.REACT_APP_ENV === "local") {
        // In local environment, return the base URL2 for the local environment
        return process.env.REACT_APP_LOCAL_BASE_URL2;
    } else {
        
        // otherwise(ie live server)
        return process.env.REACT_APP_BASE_URL2;
    }
}
// export const baseURL = getBaseUrl();
// export const baseURL_2 = getBaseUrl2();
export const baseURL = "https://api.peakbooks.biz/";
export const baseURL_2 = "https://api.peakbooks.biz:9000/insightphp/";
// export const baseURL = "http://localhost:8091/";
// export const baseURL_2 = "http://localhost:8000/";
// Warning std versions prefixed with 'v' will be deprecated on October 1st 2020. Please change your import to  (at https://deno.land/std@v0.63.0/fmt/colors.ts)
//peakinsight
// export const baseURL_PDF = "http://localhost:3005/"
export const baseURL_PDF = "https://api.peakbooks.biz:2042/";
// peakna