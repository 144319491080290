import React, { Component, Fragment } from 'react';
import { connect, Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import 'bootstrap/dist/css/bootstrap.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import '../../scss/app.scss';
import PropTypes from 'prop-types';
import Router from './Router';
import { Table } from 'reactstrap'
import store from './store';
import { config as i18nextConfig } from '../../translations';
// import Auth0Provider from '../../shared/components/auth/withAuth0';
// import auth0Config from '../../config/auth0';

import {useIdleTimer} from 'react-idle-timer'

import { BRANCH_ID, CONFIG, CURRENCY, INVOICE_STATUS, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { baseURL } from '../../_helpers';
import { saveInvoicePDF } from '../../containers/Invoice_Estimates/PDFService';
import axios from 'axios';
import { saveInvoicePDF2 } from '../Invoice_Estimates/PDFServicer';
import * as moment from 'moment';


i18n.init(i18nextConfig);
// const idleTimerP = useIdleTimer({
//   crossTab: true
// })

const ThemeComponent = ({ children, themeName }) => {
  const theme = createMuiTheme({
    palette: {
      type: themeName === 'theme-dark' ? 'dark' : 'light',
    },
  });
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

ThemeComponent.propTypes = {
  children: PropTypes.shape().isRequired,
  themeName: PropTypes.string.isRequired,
};

const ConnectedThemeComponent = connect(state => ({ themeName: state.theme.className }))(ThemeComponent);
class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }

  componentDidMount() {
    // axios.get(baseURL).then(res => { })
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });

    let url = baseURL + `item?branch_id=${BRANCH_ID}&&client_id=` + USER_ID
    // alert(url)
    axios.all([axios.get(url, CONFIG),
    ]).then(axios.spread((invoiceResponse) => {
    })).catch(error => {
      // alert("sss")
      // if (localStorage.getItem('token') !== null) {
      //   // window.location.href = "/log_in";
      //   // localStorage.removeItem('token'); 
      // }
    })


    setInterval(function () {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
          "Authorization": TOKEN
        }
      };

      const weekly = moment(new Date(new Date(Date.now()))).format('YYYY-MM-DD HH:mm:ss');

      console.log("timestamp", weekly)
      let url = baseURL + `invoiceEmail?estimate=0&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID

      // alert(url)
      axios.all([axios.get(url, axiosConfig),
      ]).then(axios.spread((invoiceResponse) => {
        if (invoiceResponse.data.status) {
          let url2 = baseURL + `invoiceitem?filter_value=` + invoiceResponse.data.data[0].invoice_no + `&client_id=` + USER_ID
          axios.all([
            axios.get(url2, axiosConfig),
          ]).then(axios.spread((invoiceResponse2) => {
            let p = invoiceResponse.data.data[0].due_amount;
            // eslint-disable-next-line
            let editedInvoice = {
              invoice_no: invoiceResponse.data.data[0].invoice_no,
              invoice_date: invoiceResponse.data.data[0].invoice_date,
              terms: invoiceResponse.data.data[0].terms,
              due_date: invoiceResponse.data.data[0].due_date,
              message_invoice: invoiceResponse.data.data[0].message_invoice,
              statement_invoice: invoiceResponse.data.data[0].statement_invoice,
              customer_name: invoiceResponse.data.data[0].customer_display_name,
              customer_email: invoiceResponse.data.data[0].email,
              amount: invoiceResponse.data.data[0].due_amount,
              due_amount: invoiceResponse.data.data[0].due_amount,
              lineItems: invoiceResponse2.data.data,
              amount1: p.toString().substring(4),
              tax_amount: invoiceResponse.data.data[0].tax_amount,
              status: invoiceResponse.data.data[0].status,
              discount_amount: invoiceResponse.data.data[0].discount_amount,
              currency: CURRENCY,
              invoice_id: invoiceResponse.data.data[0].id,

              tax: invoiceResponse.data.data[0].tax_amount,
              subtoal: invoiceResponse.data.data[0].sub_total,
              subtoal: invoiceResponse.data.data[0].sub_total,
              // let innvoiceNo = { invoice_no: this.state.specialitems.invoice_no };
              customer_email_address: invoiceResponse.data.data[0].email,

              total: invoiceResponse.data.data[0].due_amount,
              footer: ""
            };
            console.log('invoice', editedInvoice)

            // console.log('timestamps', (new Date(Date.now()).getTime()) / 1000)

            // console.log("timestamp", (new Date(invoiceResponse.data.data[0].invoice_date).getTime()) / 1000)

            if (((new Date(Date.now()).getTime()) / 1000) > ((new Date(invoiceResponse.data.data[0].invoice_date).getTime()) / 1000)) {
              console.log("timestamp", moment(new Date(new Date(Date.now()))).format('YYYY-MM-DD HH:mm:ss'))
              if (INVOICE_STATUS === '1') {
                saveInvoicePDF2(editedInvoice);
              } else {
                saveInvoicePDF(editedInvoice);
              }
            }
          })).catch(error => {
            console.log('bayoo', error.response)
          })
        } else {
          console.log("invoice", "null")
        }
      })).catch(error => {

        // alert("sss")
        console.log('bayoo', error.response)
      })
    }, 60000);

    // clearInterval(this.intervalId)
  }

  onRedirectCallbackAuth0 = (appState) => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  }



  render() {
    return (
      <Provider store={store}>
        {/* <Auth0Provider
          domain={auth0Config.domain}
          clientId={auth0Config.clientId}
          // redirectUri={`${window.location.origin}/luckybox/dashboard_default`}
          // returnTo={`${window.location.origin}/luckybox/dashboard_default`}
          onRedirectCallback={this.onRedirectCallbackAuth0}
        > */}
          <BrowserRouter >
            <I18nextProvider>
              <ConnectedThemeComponent>
                {/* <idleTimerP
                  ref={ref => { this.idleTimer = ref }}
                  timeout={1000 * 60 * 15}
                  onActive={this.handleOnActive}
                  onIdle={this.handleOnIdle}
                  onAction={this.handleOnAction}
                  debounce={250}
                /> */}

                <Table className="scrollView" >
                  <p>
                    <Router />
                  </p>
                </Table>
              </ConnectedThemeComponent>
            </I18nextProvider>
          </BrowserRouter>
        {/* </Auth0Provider> */}
      </Provider>
    );
  }

  handleOnAction(event) {
    console.log('user did something', event)
  }

  handleOnActive(event) {
    console.log('user is active', event)
    console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle(event) {
    console.log('user is idle', event)
    console.log('last active', this.idleTimer.getLastActiveTime())
    window.location.href = "/log_in";
    // localStorage.removeItem('token');
  }
}


export default App;

