import { COMPANY_NAME, COMPANY_POSTAL_ADDRESS } from '../../_helpers/exports';
import * as moment from 'moment';
import pdfMake from "pdfmake/build/pdfmake";

export function billpdf(params) {
    createPDFFromParams(params);
}

// // Private functions
// function onDataURLLoaded(params, fileReader) {
//     // Set imageLogo to data URI of file
//     params.imageLogo = fileReader.result;
//     createPDFFromParams(params);
// }

function createPDFFromParams(params) {
    let docDefinition = buildDocDefinition(params);
    pdfMake.createPdf(docDefinition).open();
}

function buildDocDefinition(params) {
    let notesAndTerms = buildNotesAndTerms(params);
    console.log('notesAndTerms', notesAndTerms);
    return {
        header: {
            columns: [
                headerNotes(params)
            ]
        },


        footer: function () {
            return [
                { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 595 - 10, y2: 10, lineWidth: 0.5 }] },
                {
                    columns: [
                        { text: `${params.footer}`, bold: true, color: 'black', fontSize: 14, alignment: 'center', marginTop: 5 }
                    ],

                },
            ];
        },

        background: [
            {
                image: localStorage.getItem("image_urls"),
                alignment: 'center',
                width: 270,
                height: 280,
                marginLeft: 100,
                marginTop: 360,
                opacity: 0.1,
                borderRadius: 25,
            },
        ],
        // background: function(currentPage, pageSize) {
        //     return `page ${currentPage} with size ${pageSize.width} x ${pageSize.height}`
        //   },

        // userPassword: params.bill_no,
        // ownerPassword: params.bill_no,
        permissions: {
            printing: 'highResolution', //'lowResolution'
            modifying: false,
            copying: false,
            annotating: true,
            fillingForms: true,
            contentAccessibility: true,
            documentAssembly: true
        },
        content: [
            buildHeaderInformation(params),
            buildLineItemsTable(params),
            buildTotal(params),
            ...buildNotesAndTerms(params),
        ],
    };
}


// Returns an array
function headerNotes(params) {
    if (params.status === 0) {
        if (params.due_period < 0) {
            return [
                { text: `\t Due in ${params.due_period * -1} days\t\t`, alignment: 'left', background: '#5bc0de', color: '#FFFFFF', margin: [5, 15, 0, 5] }
            ]
        }
        if (params.due_period > 0) {
            return [
                { text: `\t Overdue by ${params.due_period} days\t\t`, alignment: 'left', background: '#FF0000', color: '#FFFFFF', margin: [5, 15, 0, 5] }
            ]
        }   
        if (params.due_period === 0) {
            return [
                { text: '\t Due today\t\t', alignment: 'left', background: '#5bc0de', color: '#FFFFFF', margin: [5, 15, 0, 5] }
            ]
        }
    } else if (params.status === 1) {
        return [
            { text: `\tPAID\t\t`, alignment: 'left', background: '#5cb85c', color: '#FFFFFF', margin: [5, 15, 0, 5] }
        ]
    }
}

function buildHeaderInformation(params) {
    let date1 = moment(params.bill_date).format('DD, MMM YYYY');

    let date2 = moment(params.due_date).format('DD, MMM YYYY');
    const optionalDataKeys = [];
    const optionalDataValues = [];

    let tax_exclusive


    if(params.tax_exclusive === 1){

        tax_exclusive =  "Tax Exclusive"

    }else{
        tax_exclusive = "Tax Inclusive"

    }
    // let total = params.lineItems.reduce((sum, lineItem) => {
    //     return sum + lineItem.quantity * lineItem.price;
    // }, 0);
    Object.entries({
        "Date :": date1,
        'Terms :': params.terms,
        'Due Date :': date2,
    }).forEach(([key, value]) => {
        if (value) {
            optionalDataKeys.push(key);
            optionalDataValues.push(value);
        }
    });

    return {
        columns: [{
            stack: [
                 {
                    image: localStorage.getItem("image_urls"),
                    width: 200,
                    height: 150,
                    margin: [-30, -30, 0, 0],

                },

                {
                    text: COMPANY_NAME,
                    color: "black",
                    margin: [0, 0, 0, 10],
                    // background: '#eee',
                },
                {
                    text: COMPANY_POSTAL_ADDRESS,
                    color: "black",
                    margin: [0, 0, 0, 50],
                    // background: '#eee',
                },

                {
                    text: "Bill to",
                    color: "black",

                    // background: '#eee',
                },

                {
                    text: params.vendor_no,
                    color: "black",
                    margin: [0, 0, 0, 20],
                    bold: true
                    // background: '#eee',
                },

                // {
                //     text: tax_exclusive,
                //     color: "black",
                //     bold: true
                //     // background: '#eee',
                // },


            ],
        },
        {
            stack: [{
                text: 'BILL',
                bold: true,
                fontSize: 20,
            },
            {
                text: `# ${params.bill_no}`,
                fontSize: 12,
                bold: true,
                margin: [0, 0, 0, 10],
            },

            {
                text: `Balance Due`,
                fontSize: 10,
                bold: true,
            },
            {
                text: params.total,
                fontSize: 12,
                bold: true,
                margin: [0, 0, 0, 10],
            },
            {
                columns: [{
                    width: 50,
                    text: '',
                },
                {
                    width: '*',
                    columns: [{
                        stack: optionalDataKeys,
                        alignment: 'right',
                    },
                    {
                        stack: optionalDataValues,
                        alignment: 'right',
                    },
                    ],
                },
                ],
            },
            ],
            alignment: 'right',
        },
        ],
        styles: {
            subheader: {
                fontSize: 9,
                color: '#FFFFFF',
                background: '#2361AE',
                margin: [0, 15, 0, 5]
            }
        },
        // optional space between columns
        columnGap: 10,
        margin: [0, 0, 0, 30],
    };
}




function buildLineItemsTable(params) {
    let lineItemRows = params.lineItems.map(buildLineItem(params));
    return {
        table: {
            widths: ['*', '15%', '11%', '11%', '21%'],

            headerRows: 1,
            body: [
                [
                    { text: 'Items' },
                    { text: 'Quantity' },
                    { text: 'Price (' + params.currency + ')' },
                    { text: 'Tax(%)' },
                    { text: 'Total (' + params.currency + ')' },
                ],
                ...lineItemRows,
            ],
        },
        layout: 'lightHorizontalLines',
    };
}

function buildTotal(params) {
    // let total = params.lineItems.reduce((sum, lineItem) => {
    //     return sum + lineItem.quantity * lineItem.price;
    // }, 0);

    // let subtotal = params.lineItems.reduce((sum, lineItem) => {
    //     return sum + (lineItem.quantity * lineItem) - params.tax;
    // }, 0);
    return {
        table: {
            widths: ['*', '40%'],
            body: [
                [{
                    text: 'Tax Amount',
                    marginTop: 20,
                    alignment: 'right',
                },
                {
                    text: `${params.tax}`,
                    marginTop: 20,
                    alignment: 'right',
                },
                ],
                [{
                    text: 'Subtotal',
                    marginTop: 20,
                    alignment: 'right',
                },
                {
                    text: params.subtoal,
                    marginTop: 20,
                    alignment: 'right',
                },
                ],
                [{
                    text: 'Discount',
                    marginTop: 20,
                    alignment: 'right',
                },
                {
                    text: params.discount_amount,
                    marginTop: 20,
                    alignment: 'right',
                },
                ],
                [{
                    text: 'Total',
                    marginTop: 20,
                    alignment: 'right',
                },
                {
                    text: `${params.total}`,
                    marginTop: 20,
                    alignment: 'right',
                },
                ],
            ],
        },
        layout: 'noBorders',
        margin: [0, 0, 0, 30],
    };
}

// Returns an array
function buildNotesAndTerms(params) {
    let result = [];
    console.log('params', params);

    result = result.concat([
        { text: 'Notes', background: '#eee', },
        { text: "Thank you for doing business with us", background: '#eee', margin: [0, 0, 0, 30] },
    ]);

    // if (params.terms) {
    //     result = result.concat([
    //         { text: '' },
    //         { text: params.stat, margin: [0, 0, 0, 30] },
    //     ]);
    // }
    return result;
}

// this.state.editedInvoice = {
//     invoice_no: this.state.invoice_no,
//     invoice_date: this.state.invoice_date,
//     terms: this.state.terms,
//     due_date: this.state.due_date,
//     notes: this.state.invoice_message,
//     stat: this.state.statement_message,
//     customer_id: this.state.customer_id,
//     lineItems: this.state.lineItems
//   }


function buildLineItem(params) {
    return function buildLineItemCurried(lineItem) {
        return [
          
            { text: lineItem.name, marginTop: 10 },
            { text: String(lineItem.quantity), marginTop: 10 },
            { text: `${lineItem.price}`, marginTop: 10 },
            { text: `${lineItem.tax_percentage}`, marginTop: 10 },
            {
                text: `${Math.round((((lineItem.quantity * lineItem.price) -
                    (lineItem.discount_percentage / 100 * (lineItem.quantity * lineItem.price))) + (lineItem.tax_percentage / 100 * (lineItem.quantity * lineItem.price))), 2)}`, marginTop: 10
            },

        ];
    };
}