import React, { useState, Fragment, useEffect } from 'react';
import { Navigate } from 'react-router';


const UserGuide = () => {


    useEffect(() => {


        
        window.open('/static/media/user-guide.e4996fd4.pdf')
        //  alert("name")

    }, []);


    return (
        <Navigate to="/dashboard" />
    )
}
export default UserGuide;