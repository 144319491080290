import React, { useEffect, useState } from 'react';
import '../../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../../_helpers';
import { CONFIG, errorToast, successToast, ToastTable, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../../_helpers/exports';
import { withTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';



const FooterNotes = () => {

    const [showComponent, setShowComponent] = useState(false);
    const [hideComponent, setHideComponent] = useState(false);
    const [notes, setNotes] = useState('');
    const [isUpdateStatus, setIsUpdateStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        // $('#message').countSms('#sms-counter');
        getData();

        setIsLoading(true);
        let url = baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID
        axios.all([
            axios.get(url, CONFIG),
        ]).then(axios.spread((itemResponse) => {
            if (itemResponse.data.data.length > 0) {
                setNotes(itemResponse.data.data[0].footer_notes)
                setIsUpdateStatus(true)
                setIsLoading(false);
            } else {
                setNotes('')
                setIsUpdateStatus(false)
                setIsLoading(false);
            }
        })).catch(error => {

            errorToast("Something went wrong");
            setIsLoading(false);
            console.log('bayoo', error)
        })

    }, []);


    const onSubmit = (e) => {
        e.preventDefault();
        let formData = {
            "created_by": USER_ID,
            "invoice_notes": notes,
        }


        setIsLoading(true);
        axios.post(baseURL + `footer_ads`, formData, CONFIG)
            .then((response) => {
                // console.log('bayoo', response.data)
                if (response.data.status) {
                    successToast(response.data.message);
                    setIsLoading(false);
                    window.scrollTo(0, 0)
                    window.setTimeout(() => {
                        setShowComponent(true);
                        setHideComponent(true);
                    }, 2000);
                } else {
                    errorToast(response.data.message);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                errorToast("Something went wrong");
                setIsLoading(false);
            })
    }

    const onSubmitEdit = (e) => {
        e.preventDefault();
        // alert("Gg")
        let formData = {
            "created_by": USER_ID,
            "invoice_notes": notes,
        }
        setIsLoading(true);
        axios.put(baseURL + `footer_ads`, formData, CONFIG)
            .then((response) => {
                // console.log('bayoo', response.data)
                if (response.data.status) {
                    successToast(response.data.message);
                    setIsLoading(false);
                    window.scrollTo(0, 0)
                    window.setTimeout(() => {
                        setShowComponent(true);
                        setHideComponent(true);
                    }, 2000);
                } else {
                    errorToast(response.data.message);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                errorToast("Something went wrong");
                setIsLoading(false);
            })
    }


    const _onButtonClick = () => {
        setShowComponent(true);
        setHideComponent(true);
    }

    const getData = () => {

    }


    const checkStatus = (e) => {
        if (isUpdateStatus === true) {
            onSubmitEdit(e);
            // alert("gg")
        } else {
            alert("submit")
            onSubmit(e);
        }

    }

    return (
        <div style={{ marginTop: "-20px" }} >
            {showComponent && (
                <div>
                    {showComponent ?
                        <Navigate to="/settings" /> : null
                    }
                </div>
            )}


            {!hideComponent && (
                <div>
                    <Card>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4><b>Add/Edit Footer Ads</b></h4><br />
                                </div>
                                <div className="col-md-6">
                                    <Link to="/settings">
                                        <Button className="pull-right" outline color="primary" onClick={_onButtonClick}>
                                            Back to reports
                                        </Button>
                                    </Link>

                                </div>
                            </div>

                            <ToastTable />
                            <br></br>
                            <form className="form" onSubmit={isUpdateStatus ? onSubmitEdit : onSubmit}>

                                <div className="col-md-8 offset-2">
                                    <br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">

                                                <label className="form-label">
                                                    Footer Ads
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <textarea
                                                    name="notes"

                                                    style={{
                                                        paddingTop: '20px',
                                                        borderRadius: '5px',
                                                        borderColor: 'hsl(0,0%,80%)',
                                                    }}
                                                    onChange={e => setNotes(e.target.value)}
                                                    className="col-md-12"
                                                    placeholder="Footer Ads"
                                                    value={notes}
                                                    id="message"
                                                    cols="col-md-1"
                                                    rows="1"
                                                />
                                            </div>

                                            <div className="col-md-2">
                                                {/* <ul id="sms-counter">
                                                        <li>Length: <span class="length" /></li>
                                                        <li>
                                                            Message:
                                                            {' '}
                                                            <span class="remaining" />
                                                            /
                                                            <span class="per_message" />
                                                        </li>
                                                    </ul> */}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                                <br></br>
                                <br />
                                <br></br>
                                <br />
                                <div className="col-md-8 offset-2"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <Link to="/settings">
                                                    <Button className="pull-right btn-sm" outline color="primary" onClick={_onButtonClick}>
                                                        Cancel
                                                    </Button>
                                                </Link>

                                            </div>
                                            <div className="col-md-10">
                                                <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                                    {isLoading ? "Please Wait..." : "Save"}

                                                </Button> &nbsp;&nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            )
            }
        </div>
    )
}

export default withTranslation('common')(FooterNotes);
