import React, { useState } from 'react';

import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import axios from 'axios';
import { Card, CardBody, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { BUSINESS_TYPE, ToastTable, successToast, errorToast, CONFIG, TOKEN, USER_ID, BRANCH_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { Link, useLocation, Navigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { useEffect } from 'react';
import PutHooks from '../../_helpers/putHooks';
import CreatableSelect from 'react-select/creatable';
import UseFetch from '../../_helpers/useFetch';


const EditEmployee = () => {

    const location = useLocation();


    const [name, setName] = useState(location.state.name);
    const [email, setEmail] = useState(location.state.email);


    const [msisdn, setMSISDN] = useState(location.state.msisdn.toString());
    const [job_type, setJobType] = useState([
        {
            label: 'temporary',
            value: 'temporary'
        },
        {
            label: 'permanent',
            value: 'permanent'
        }
    ]);

    const [job_type_selected, setJobTypeSelected] = useState(location.state.job_type);

    const [job_title, setJobTitle] = useState(location.state.job_title);
    const [staff_number, setStaffNumber] = useState(location.state.staff_number);
    const [kra_pin, setKRAPIN] = useState(location.state.kra_pin);
    const [nssf_no, setNSSF] = useState(location.state.nssf_no);

    const [props, setProps] = useState({});

    const [isLoading, res, postRequest] = PutHooks(props); // custom hook



    const [nhif_no, setNHIF] = useState(location.state.nhif_no);
    const [payment_mode, setPaymentMode] = useState([
        {
            label: 'cash',
            value: 'cash'
        },
        {
            label: 'bank',
            value: 'bank'
        },
        {
            label: 'mpesa',
            value: 'mpesa'
        }
    ]);
    const [payment_p, setPaymentP] = useState(location.state.payment_mode);
    const [department, setDepartment] = useState([]);
    const [dept_check, setCheckDep] = useState(1);

    const [department_value, setDepartmentValue] = useState(location.state.department_id)
    const [department_label, setDepartmentLabel] = useState({ label: "Select or type to add Department", value: "" })


    const [bank_name, setBankName] = useState(location.state.bank_name);
    const [account_no, setAccountName] = useState(location.state.account_number);
    const [bank_open, setOPenBank] = useState(false);

    const [id_no, setIDNo] = useState(location.state.id_no);

    const [check, setCheck] = useState(0);

    const [gohome, setHome] = useState(false);
    const [loading, fetch, callback] = UseFetch(`department?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&branch_id=${BRANCH_ID}`); // custom hook

    useEffect(() => {
        if (res != null && res.status) {
            setTimeout(() =>
                setHome(true), 2000
            )
        }
        if (props.url === 'employee' && check === 1) {
            postRequest();
            setCheck(0)
        }

        if (fetch && fetch.data.length > 0) {
            setDepartment(fetch.data)
        }

        if (dept_check === 1) {
            setCheckDep(0)
            callback()
        }

    }, [isLoading, fetch, dept_check, loading, check])
    const submit = (e) => {
        e.preventDefault();

        const formData = {
            name: name,
            email: email,
            id: location.state.id,
            department_id: department_value,
            msisdn: msisdn,
            job_title: job_title,
            job_type: job_type_selected,
            staff_number: staff_number,
            client_id: USER_ID,
            branch_id: BRANCH_ID,
            kra_pin: kra_pin,
            id_no: id_no,
            nssf_no: nssf_no,
            nhif_no: nhif_no,
            payment_mode: payment_p,
            bank_name: bank_name,
            account_number: account_no
        }

        const customerProps = { // post body and the url
            url: 'employee',
            body: formData
        }
        setCheck(1)
        setProps(customerProps)
    }

    //create department
    const handleCreate = value => {
        const newValue = { value: value, label: value };
        setDepartmentLabel(newValue)
        postRequest();
        setOPenBank(false);
        setCheckDep(1)
        setDepartmentLabel({ label: "Select Department you have just created", value: "" })
        // callback()
    }


    return (
        <div>
            {ToastTable()}
            <Card>
                {gohome ?
                    <Navigate to='/employees' /> : null}
                <CardBody>
                    <div className="row">
                        <div className="col-md-6">
                            <h4><b>Edit Employee  ({location.state.name})</b></h4><br />
                        </div>
                        <div className="col-md-6">
                            <Link to="/employees">
                                <Button className="pull-right  btn-sm" outline color="primary" >
                                    Employees
                                </Button>
                            </Link>

                        </div>
                    </div>
                    <form className="form col-10 offset-1 row" onSubmit={submit}>


                        <div className="col-md-6"><br />
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">Job Title</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input id="input" type="text" className="form-control"
                                            name="job_title" placeholder='Job Title'
                                            value={job_title} onChange={e => setJobTitle(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6"> <br />
                            <Form.Group className="Focus-line" >
                                <div className="row">
                                    <div className="col-md-3">
                                        <Form.Label>Job Type</Form.Label>
                                    </div>
                                    <div className="col-md-9">
                                        <Form.Control
                                            as="select"
                                            className="form-control input-md"
                                            placeholder="Select Job Type"
                                            value={job_type_selected}
                                            onChange={e => setJobTypeSelected(e.target.value)}
                                            id="job_type_selected"
                                        >
                                            <option>Job Type</option>
                                            {
                                                job_type && job_type.length > 0 &&
                                                job_type.map((regionItem, i) =>
                                                    <option key={i}
                                                        value={regionItem.value}>{regionItem.value}</option>)
                                            }
                                        </Form.Control>
                                    </div>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-6"> <br />
                            <Form.Group className="Focus-line" >
                                <div className="row">
                                    <div className="col-md-3">
                                        <Form.Label>Department</Form.Label>
                                    </div>
                                    <div className="col-md-9">

                                        <CreatableSelect
                                            options={department && department.length > 0 ?
                                                department.map((countyItem, i) => ({
                                                    label: countyItem.name,
                                                    value: countyItem.id,
                                                }))
                                                : department
                                            }
                                            value={department_label}
                                            onInputChange={e => {
                                                const formData = {
                                                    name: e,
                                                    client_id: USER_ID,
                                                    branch_id: BRANCH_ID
                                                }
                                                const customerProps = { // post body and the url
                                                    url: 'department',
                                                    body: formData
                                                }
                                                setProps(customerProps)
                                                setOPenBank(false)
                                            }}
                                            autosize={true}
                                            noOptionsMessage={() => 'No department! Start type to create'}
                                            loadingMessage={() => 'Searching...'}   //minor type-O here
                                            placeholder="Select or type to add Department"
                                            autosize={true}
                                            onChange={e => {
                                                // callback()
                                                setCheckDep(1)
                                                setDepartmentValue(e.value)
                                                const newValue = { value: e.value, label: e.label };
                                                setDepartmentLabel(newValue)

                                            }}
                                            onCreateOption={handleCreate}
                                            isLoading={loading}
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                        </div>



                        <div className="col-md-6"><br />
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">Employee Name</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input id="input" type="text" className="form-control"
                                            name="name" required placeholder='Name'
                                            value={name} onChange={e => setName(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6"><br />
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">Staff Number</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input id="input" type="text" className="form-control"
                                            name="staff_number" required placeholder='Staff Number'
                                            value={staff_number} onChange={e => setStaffNumber(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6"><br />
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">Email Address</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input id="input" type="email" className="form-control"
                                            name="email" placeholder='Email Address'
                                            value={email} onChange={e => setEmail(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-md-6">
                            <br />
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">Phone No.</label>
                                    </div>

                                    <div className="col-md-9">
                                        <PhoneInput
                                            flags={flags}
                                            defaultCountry="KE"
                                            placeholder="Enter phone number"
                                            name="msisdn"
                                            maxLength={15}
                                            id="input"
                                            value={msisdn}
                                            onChange={value => setMSISDN(value)} />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6"><br />
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">National ID No.</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input id="input" type="text" className="form-control"
                                            name="id_no" placeholder='ID No.'
                                            value={id_no} onChange={e => setIDNo(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6"><br />
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">KRA PIN</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input id="input" type="text" className="form-control"
                                            name="tax_pin" placeholder='KRA PIN'
                                            value={kra_pin} onChange={e => setKRAPIN(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6"><br />
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">NHIF No.</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input id="input" type="text" className="form-control"
                                            name="nhif_no" placeholder='NHIF No.'
                                            value={nhif_no} onChange={e => setNHIF(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6"><br />
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">NSSF No.</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input id="input" type="text" className="form-control"
                                            name="nssf_no" placeholder='NSSF No.'
                                            value={nssf_no} onChange={e => setNSSF(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6"><br />
                            <Form.Group className="Focus-line" >
                                <div className="row">
                                    <div className="col-md-3">
                                        <Form.Label>Payment Mode</Form.Label>
                                    </div>
                                    <div className="col-md-9">
                                        <Form.Control
                                            as="select"
                                            className="form-control input-md"
                                            placeholder="Select Title"
                                            value={payment_p}
                                            onChange={e => {
                                                setPaymentP(e.target.value)
                                                if (e.target.value === 'bank') {
                                                    setOPenBank(true)
                                                } else {
                                                    setOPenBank(false)
                                                }
                                            }}
                                            id="payment_p"
                                        // option={this.selectedType}
                                        >
                                            <option>Payment Mode</option>
                                            {
                                                payment_mode && payment_mode.length > 0 &&
                                                payment_mode.map((regionItem, i) =>
                                                    <option key={i}
                                                        value={regionItem.value}>
                                                        {regionItem.value}
                                                    </option>)
                                            }
                                        </Form.Control>
                                    </div>
                                </div>
                            </Form.Group>
                            {bank_open ?
                                <div><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="form-label">Bank Name</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input id="input" type="text" className="form-control"
                                                    name="bank_name" placeholder='Bank Name'
                                                    value={bank_name} onChange={e => setBankName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="form-label">Account No</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input id="input" type="text" className="form-control"
                                                    name="acccount_no" placeholder='Account Number'
                                                    value={account_no} onChange={e => setAccountName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                        </div>



                        <br />
                        <br />
                        <div className="col-md-8 offset-2"><br />
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <Link to="/employees">
                                            <Button outline color="primary" className="btn-sm">
                                                Cancel
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="col-md-9">
                                        <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                            {isLoading ? "Please Wait..." : "Save"}
                                        </Button> &nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    )
}
export default EditEmployee;