import React from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { BRANCH_ID, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Input from 'react-phone-number-input/input'
import { Link } from 'react-router-dom';
import Alert from '../../shared/components/Alert';
import { Vendors } from './vendor_view';
import CreatableSelect from 'react-select/creatable';
import * as moment from 'moment';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';

class EditVendor extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
            moreinfo: false,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onMoreInfo = this._onMoreInfo.bind(this);

        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            showError: false,
            first_name: localStorage.getItem("first_name"),
            other_name: localStorage.getItem("other_name"),
            email: localStorage.getItem("email"),
            selectedType: localStorage.getItem('title'),
            msisdn: localStorage.getItem('msisdn'),

            vendor_id: localStorage.getItem('id_customer'),
            company_name: localStorage.getItem('company_name'),
            vendor_display_name: localStorage.getItem('vendor_display_name'),
            website: localStorage.getItem('website'),
            street: localStorage.getItem('street'),
            city_town: localStorage.getItem('city_town'),
            state_province: localStorage.getItem('state_province'),
            country: localStorage.getItem('country'),
            street1: localStorage.getItem('street1'),
            city_town1: localStorage.getItem('city_town1'),
            state_province1: localStorage.getItem('state_province1'),
            country1: localStorage.getItem('country1'),
            remarks: localStorage.getItem('remarks'),
            terms: localStorage.getItem('terms'),
            tax_info: localStorage.getItem('tax_info_vendor'),

            opening_balance: localStorage.getItem('opening_balance'),
            // other_name: "",
            // first_name: "",
            type: [{
                name: 'Mr.'
            }, {
                name: 'Mrs.'
            },
            {
                name: 'Ms.'
            },
            {
                name: 'Miss.'
            },
            {
                name: 'Dr.'
            }],

            priority: [{
                name: 'Print Later'
            }, {
                name: 'Send Later'
            },
            {
                name: 'None'
            }],
            terms3: [
                {
                    label: 'Due on Receipt',
                    value: 'Due on Receipt',
                },
                {
                    label: 'Due in 15 days',
                    value: 'Due in 15 days',
                },
                {
                    label: 'Due in 30 days',
                    value: 'Due in 30 days',
                },
            ],

            avenue: '',
            assigned_to: '',
            selectedCounty: 'Select County/State Name',
        }
    }

    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    handleChangeTerms = value => {

        if (value != null) {
            this.setState(
                {
                    terms: value.value.toString(),
                });
        }
        else {
            this.setState(
                {
                    terms: "",
                });
        }
    };
    onSubmit(e) {
        e.preventDefault();
        let formData = {
            "client_id": USER_ID,
            "acc_id": ROLE, USER_ID_ACC,
            "title": this.state.selectedType,
            "first_name": this.state.first_name,
            "other_name": this.state.other_name,
            "email": this.state.email,
            "msisdn": this.state.msisdn,
            "company_name": this.state.company_name,
            "vendor_display_name": this.state.company_name,
            "website": this.state.website,
            "street": this.state.street,
            "city_town": this.state.city_town,
            "branch_id": BRANCH_ID,
            "state_province": this.state.state_province,
            "country": this.state.country,
            "street1": this.state.street1,
            "id": this.state.vendor_id,
            "city_town1": this.state.city_town1,
            "state_province1": this.state.state_province1,
            "country1": this.state.country1,
            "remarks": this.state.selectedMessage,
            "terms": this.state.terms,
            "tax_info": this.state.tax_info,
            "opening_balance": this.state.opening_balance
        }
        // alert(JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.put(baseURL + `vendor`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then((response) => {
            console.log('bayoo', response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                window.scrollTo(0, 0)
                window.setTimeout(() => {
                    this.setState({ isShowError: false });
                    this._onButtonClick();
                }, 2000);
            } else {
                window.scrollTo(0, 0)

                this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
            }
        })
            .catch((error) => {
                console.log('bayoo', error)
                window.scrollTo(0, 0)

                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
            })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            moreinfo: false
        });
    }
    changeStatus() {
        this.setState({
            isShowError: false
        });
    }

    handleChangeMessage = (event) => {
        this.setState({ selectedMessage: event.target.value }, function () {

        });
        //alert(event.target.value)
    };

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        }, function () {
            if (this.state.isChecked === true) {
                this.setState({
                    street1: this.state.street,
                    city_town1: this.state.city_town,
                    state_province1: this.state.state_province,
                    country1: this.state.country
                })
            } else {
                this.setState({
                    street1: "",
                    city_town1: "",
                    state_province1: "",
                    country1: ""
                })
            }
        });
    }

    _onMoreInfo() {
        this.setState({
            showComponent: false,
            hideComponent: true,
            moreinfo: true
        });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        return (
            <div>
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <Vendors /> : null
                        }
                    </div>
                )}
                {/* {moreinfo && (
                            <div>
                            {this.state.moreinfo ?
                                <CreateAddress /> : null
                            }
                            </div>
                        )} */}

                {!hideComponent && (
                    <div>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4><b>Edit Supplier</b></h4><br />
                                        </div>
                                        <div className="col-md-6">
                                            <Link to="/vendor">
                                                <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                    Suppliers
                                                </Button>
                                            </Link>

                                        </div>
                                    </div>


                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>
                                    <form className="form col-10 offset-1 row" onSubmit={this.onSubmit}>

                                        {/* <div className="col-md-6">
                                    <Form.Group className="Focus-line" >
                                    <div className="row">
                                    <div className="col-md-2">
                                    <Form.Label>Title</Form.Label>
                                    </div>
                                    <div className="col-md-10">
                                    <Form.Control
                                    as="select"
                                    className="form-control input-md"
                                    placeholder="Select Title"
                                    value={this.state.selectedType}
                                    onChange={this.handleChangeType}
                                    id="county_name"
                                    option={this.selectedType}>
                                    <option>Select Title</option>
                                    {
                                        this.state.type && this.state.type.length > 0 &&
                                        this.state.type.map((regionItem, i) =>
                                        <option key={i}
                                        value={regionItem.name}>{regionItem.name}</option>)
                                    }
                                    </Form.Control>
                                    </div>
                                    </div>
                                    
                                    </Form.Group>
                                </div> */}
                                        <div className="col-md-6"><br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Contact Person</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="first_name" className="form-control"
                                                            name="first_name" required placeholder='Contact Person'
                                                            value={this.state.first_name} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6"><br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Email Address</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="email" className="form-control"
                                                            name="email" required placeholder='Email Address'
                                                            value={this.state.email} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6"><br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Company Name</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control"
                                                            name="company_name" required placeholder='Company Name'
                                                            value={this.state.company_name} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6"><br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Supplier Display Name</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control"
                                                            name="vendor_display_name" disabled required placeholder='Supplier Display Name'
                                                            value={this.state.company_name} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Phone No.</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <PhoneInput
                                                            flags={flags}
                                                            defaultCountry="KE"
                                                            placeholder="Enter phone number"
                                                            name="msisdn"
                                                            maxLength={15}
                                                            id="input"
                                                            value={this.state.msisdn}
                                                            onChange={value => this.setState({ msisdn: value })} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />


                                        {/* <div className="col-md-6"><br />
                                <div className="form-group">
                                <div className="row">
                                <div className="col-md-2">
                                <label className="form-label">Website</label>
                                </div>
                                <div className="col-md-10">
                                <input id="input" type="text" className="form-control"
                                name="website" placeholder='Website'
                                value={this.state.website} onChange={this.handleChange} />
                                </div>
                                </div>
                                </div>
                                </div>
                                <div className="col-md-12">
                                <h4><b>Billing Address</b></h4>
                                </div>
                                <div className="col-md-12 row">
                                <div className="col-md-6">
                                <div className="form-group">
                                <label className="form-label">Street</label>
                                <input id="input" type="text" className="form-control input-md"
                                name="street" placeholder="Street"
                                value={this.state.street} onChange={this.handleChange} />
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="form-group">
                                <label className="form-label">City/Town</label>
                                <input id="input" type="text" className="form-control"
                                name="city_town" placeholder="City/Town"
                                value={this.state.city_town} onChange={this.handleChange} />
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="form-group">
                                <label className="form-label">State/County/Province</label>
                                <input id="input" type="text" className="form-control" name="state_province"
                                placeholder="State/County"
                                value={this.state.state_province}
                                onChange={this.handleChange} />
                                </div>
                                </div>
                                
                                <div className="col-md-6">
                                <div className="form-group">
                                <label className="form-label">Country</label>
                                <input id="input" type="text" className="form-control"
                                name="country" placeholder="Country"
                                value={this.state.country} onChange={this.handleChange} />
                                <br />
                                </div>
                                </div>
                                
                                <div className="col-md-12 ">
                                
                                <h4><b>Shipping Address</b></h4> <br />
                                <label>
                                <input type="checkbox"
                                checked={this.state.isChecked}
                                onChange={this.toggleChange}
                                />&nbsp;
                                Same as the billing address
                                
                                </label>
                                
                                </div>
                                <div className="col-md-6">
                                <div className="form-group">
                                <label className="form-label">Street</label>
                                <input id="input" type="text" className="form-control input-md"
                                name="street1" placeholder="Street"
                                value={this.state.street1} onChange={this.handleChange} />
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="form-group">
                                <label className="form-label">City/Town</label>
                                <input id="input" type="text" className="form-control"
                                name="city_town1" placeholder="City/Town"
                                value={this.state.city_town1} onChange={this.handleChange} />
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="form-group">
                                <label className="form-label">State/County</label>
                                <input id="input" type="text" className="form-control" name="state_province1"
                                placeholder="State/County"
                                value={this.state.state_province1}
                                onChange={this.handleChange} />
                                </div>
                                </div>
                                
                                <div className="col-md-6">
                                <div className="form-group">
                                <label className="form-label">Country</label>
                                <input id="input" type="text" className="form-control"
                                name="country1" placeholder="Country"
                                value={this.state.country1} onChange={this.handleChange} />
                                </div>
                                </div>
                                </div>
                                
                                <div className="col-md-12">
                                <br />
                                <h4 ><b>Other Details</b></h4>
                            </div> */}

                                        {/* <div className="col-md-6">
                            <Form.Group className="Focus-line" >
                            <div className="row">
                            <div className="col-md-2">
                            <Form.Label>Preferred Method of Payment</Form.Label>
                            </div>
                            <div className="col-md-10">
                            <Form.Control
                            as="select"
                            className="form-control input-md"
                            placeholder="Select Payment Method"
                            value={this.state.payment_method}
                            onChange={this.handleChangeRegion}
                            id="county_name"
                            option={this.payment_method}>
                            <option>Select Payment Method</option>
                            {
                                this.state.type && this.state.type.length > 0 &&
                                this.state.type.map((regionItem, i) =>
                                <option key={i}
                                value={regionItem.name}>{regionItem.name}</option>)
                            }
                            </Form.Control>
                            </div>
                            </div><br />
                            </Form.Group>
                        </div> */}

                                        <div className="col-md-6"><br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Opening Balance <br />{moment.utc(Date.now()).format('DD MMM, YYYY')}</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control"
                                                            name="opening_balance" placeholder='Opening Balance'
                                                            value={this.state.opening_balance} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6"><br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">KRA PIN</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control"
                                                            name="tax_info" placeholder='KRA PIN'
                                                            value={this.state.tax_info} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 
                        <div className="col-md-6">
                        <Form.Group className="Focus-line" >
                        <div className="row">
                        <div className="col-md-2">
                        <Form.Label>Payment Terms</Form.Label>
                        </div>
                        <div className="col-md-10">
                        <CreatableSelect
                        isClearable
                        options={this.state.terms3}
                        // onInputChange={this.loadOptions}
                        placeholder="Select terms or enter custom date"
                        autosize={true}
                        // value={this.state.terms}
                        
                        onChange={this.handleChangeTerms}
                        isLoading={this.state.isLoading}
                        // noOptionsMessage={() => 'nothing found'}
                        // loadingMessage={() => 'searching...'}   //minor type-O here
                        menuPortalTarget={document.body}
                        name="namffe"
                        /><br />
                        </div>
                        </div>
                        
                        </Form.Group>
                        </div>
                        
                        <div className="col-md-12">
                        <br />
                        <div className="form-group">
                        <div className="row">
                        <div className="col-md-2">
                        
                        <h4><b>Remarks</b></h4>
                        </div>
                        <div className="col-md-8">
                        <textarea name="message"  style={{
                            paddingTop: '20px',
                            borderRadius: "5px", borderColor: "hsl(0,0%,80%)"
                        }}
                        onChange={this.handleChangeMessage} className="col-md-12"
                        placeholder="Hint: Enter Remarks here"
                        id="message" cols="col-md-1" rows="10"></textarea>
                        </div>
                        <div className="col-md-2">
                        <ul id="sms-counter">
                    <li>Length: <span class="length"></span></li> */}
                                        {/* <li>SMS: <span class="messages"></span></li> */}
                                        {/* <li>Characters: <span class="remaining"></span>/<span class="per_message"></span></li>
                    <li>Remaining: </li>
                </ul> */}
                                        {/* </div>
                </div>
                </div>
                <br />
            </div> */}


                                        <br></br>
                                        <br />

                                        <div className="col-md-12"><br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <Link to="/vendor">
                                                            <Button outline color="primary" onClick={this._onButtonClick}>
                                                                Cancel
                                                            </Button>
                                                        </Link>

                                                    </div>
                                                    <div className="col-md-8">
                                                        <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                            {this.state.isLoading ? "Please Wait..." : "Update"}

                                                        </Button> &nbsp;&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </form>
                                </CardBody>
                            </Card>
                        </Col >
                    </div>
                )
                }
            </div>
        )
    }
}
export default withTranslation('common')(EditVendor);