import React from 'react';
import '../../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../../_helpers';
import { TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../../_helpers/exports';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, Navigate } from 'react-router-dom';
import Alert from '../../../shared/components/Alert';
import { SalesPersonView } from './SalesPerson';
import { Settings } from './settings.jsx';
import $ from 'jquery';


class InvoiceNotes extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitEdit = this.onSubmitEdit.bind(this);

        this.state = {
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            isUpdateStatus: false,
            showError: false,
            notes: "",
        }
    }



    onSubmit(e) {
        e.preventDefault();
        let formData = {
            "created_by": USER_ID,
            "invoice_notes": this.state.notes,
        }
        this.setState({ isLoading: true });
        axios.post(baseURL + `notes`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                    window.scrollTo(0, 0)
                    window.setTimeout(() => {
                        this.setState({ isShowError: false, sales_person: "" });
                        this._onButtonClick();
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
            })
    }

    onSubmitEdit(e) {
        e.preventDefault();

        // alert("Gg")
        let formData = {
            "created_by": USER_ID,
            "invoice_notes": this.state.notes,
        }
        this.setState({ isLoading: true });
        axios.put(baseURL + `notes`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        })
            .then((response) => {
                // console.log('bayoo', response.data)
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                    window.scrollTo(0, 0)
                    window.setTimeout(() => {
                        this.setState({ isShowError: false, sales_person: "" });
                        this._onButtonClick();
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
            })
    }

    async componentDidMount() {
        $('#message').countSms('#sms-counter');

        this.getData()
    }


    getData = () => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `notes?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((itemResponse) => {
            this.setState({
                notes: itemResponse.data.data,
                isLoading: false,
            }, function () {
                var data = [];
                if (this.state.notes.length > 0) {
                    this.setState({ isUpdateStatus: true, notes: this.state.notes[0].invoice_notes });
                } else {
                    this.setState({
                        notes: "",
                        isUpdateStatus: false
                    })
                }
            })
        })).catch(error => {
            console.log('bayoo', error.response)
        })
    }


    checkStatus() {
        if (this.state.isUpdateStatus === true) {
            this.onSubmitEdit();
            // alert("gg")
        } else {
            alert("submit")
            this.onSubmit();
        }

    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            moreinfo: false
        });
    }
    handleNotes = event => {
        this.setState({ notes: event.target.value }, function () { });
        //alert(event.target.value)
    };


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <Navigate to="/settings" /> : null
                        }
                    </div>
                )}


                {!hideComponent && (
                    <div>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>Add Notes</b></h4><br />
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/settings">
                                            <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                Back to reports
                                            </Button>
                                        </Link>

                                    </div>
                                </div>


                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>


                                </div> : null}<br></br>

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div>

                                ) : null}<br></br>
                                <form className="form" onSubmit={this.state.isUpdateStatus ? this.onSubmitEdit : this.onSubmit}>

                                    <div className="col-md-8 offset-2">
                                        <br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">

                                                    <label className="form-label">
                                                        Notes
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <textarea
                                                        name="notes"
                                                        style={{
                                                            paddingTop: '20px',
                                                            borderRadius: '5px',
                                                            borderColor: 'hsl(0,0%,80%)',
                                                        }}
                                                        onChange={this.handleNotes}
                                                        className="col-md-12"
                                                        placeholder="Notes"
                                                        value={this.state.notes}
                                                        id="message"
                                                        cols="col-md-1"
                                                        rows="1"
                                                    />
                                                </div>

                                                <div className="col-md-2">
                                                    {/* <ul id="sms-counter">
                                                        <li>Length: <span class="length" /></li>
                                                        <li>
                                                            Message:
                                                            {' '}
                                                            <span class="remaining" />
                                                            /
                                                            <span class="per_message" />
                                                        </li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                    <br></br>
                                    <br />
                                    <br></br>
                                    <br />
                                    <div className="col-md-8 offset-2"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Link to="/settings">
                                                        <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                                            Cancel
                                                        </Button>
                                                    </Link>

                                                </div>
                                                <div className="col-md-10">
                                                    <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                                        {this.state.isLoading ? "Please Wait..." : "Save"}

                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                )
                }
            </div>
        )
    }
}
export default withTranslation('common')(InvoiceNotes);
