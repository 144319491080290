
import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import PropTypes from 'prop-types';
import { BRANCH_ID, CURRENCY, LOADER, PROFILE_URL, TOKEN, USER_ID } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import Invoice from './Invoice';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import { saveInvoiceView } from './pdfview';
import Invoice_Edit from './Invoice edit';
import { saveInvoicePDF } from './PDFService';
import Bills from './bills';
import { billpdf } from './pdfbill';
import Loader from "react-loader-spinner";

export class Bill_view extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);

        this.columns = [
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "bill_no",
                TrOnlyClassName: 'tsc',
                text: "Bill#",
                className: "tsc",
                align: "left"
            },
            {
                key: "vendor_display_name",
                TrOnlyClassName: 'tsc',
                text: "Company Name",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "recurring",
                TrOnlyClassName: 'tsc',
                text: "Recurring",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.recurring == 1 ?
                                <span style={{ borderRadius: "5px", padding: "2px" }}>
                                    YES
                                </span>
                                : null
                                // <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                //     NO
                                // </span>

                            }
                            {record.recurring == 0 ?
                                <span style={{ borderRadius: "5px", padding: "2px" }}>
                                    NO
                                </span>
                                : null

                            }
                        </Fragment >
                    );
                }
            },

            {
                key: "status",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.status === 0 ?
                                <div>
                                    {record.due_period < 0 ?
                                        <span class="badge-info" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Due in {record.due_period * -1} days
                                        </span>
                                        : null}

                                    {record.due_period > 0 ?
                                        <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Over Due by {record.due_period} days
                                        </span>
                                        : null}
                                    {record.due_period === 0 ?

                                        <span span class="badge-info" style={{ borderRadius: "5px", padding: "2px" }}>
                                            Due Today
                                        </span>
                                        : null}
                                </div>
                                : null}
                            {record.status === 1 ?
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Paid
                                </span>
                                : null}



                        </Fragment >
                    );
                }
            },
            {
                key: "dates1",
                TrOnlyClassName: 'tsc',
                text: "Due Date",
                className: "tsc",
                align: "left"
            },

            {
                key: "amount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "mbalance",
                TrOnlyClassName: 'tsc',
                text: "Due Amount",
                className: "tsc",
                align: "left"
            },

            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-success  btn-sm"
                                title="View"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick(record) }} >
                                View
                            </button>
                            {/* <Link to="invoice-edit">
                                <button className="btn btn-primary btn-sm"
                                    title="Edit Invoice"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.onEditInvoice(record) }} >
                                    Edit
                            </button>
                            </Link> */}

                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            loaded: true,
            pagination: 'advance',
            page_size: 10,
            button: {
                excel: true,
                csv: true
            },
            filename: "bills",
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Invoice no...",
                no_data_text: "No bills currently",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showComponent: false,
            onEdit: false,
            returnHome: false,
            _onShow: false,
            hideComponent: false,
            data: [],
        };
    }



    static propTypes = {
        changeSidebarVisibility: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,

    };



    async handleOnClick1(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onEditInvoice(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }
    async handleOnClick(record) {

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        this.setState({
            returnHome: true
        })
        let url = baseURL + `billitems?filter_value=` + record.bill_no
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            console.log("BUGggg", invoiceResponse)
            this.setState({
                lineItems: invoiceResponse.data.data,
                returnHome: false
            })
            this.state.editedInvoice = {
                bill_no: record.bill_no,
                bill_date: record.dates,
                terms: record.terms,
                due_date: record.dates1,
                vendor_name: record.vendor_display_name,
                customer_email: record.email,
                amount: record.amount,
                due_amount: record.due_amount,
                lineItems: this.state.lineItems,
                tax_amount: record.tax_amount,
                due_period: record.due_period,
                status: record.status,
                discount_amount: record.discount_amount,
                currency: CURRENCY,
                tax: record.tax_amount,
                subtoal: record.sub_total,
                total: record.due_amount,
            };
            billpdf(this.state.editedInvoice);

        })).catch(error => {
            console.log('bayoo', error.response)

        })

        // console.log("hoge!");
        // if (await confirm("Y")) {
        // this._onButtonClick2(record)
        // } else {
        //     this.setState({
        //         message: "No!"
        //     });
        // }
    }
    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {
        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = `${PROFILE_URL}?canvas=true`;
        }
        this.getData();

    }
    getData = (queryString = "") => {


        // alert(localStorage.getItem("BILL_CREATEPEAK"));

        this.setState({
            isLoading: false,
            // loaded: false

        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `bill?branch_id=${BRANCH_ID}&&created_by=` + USER_ID + "&&" + queryString
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            console.log("BUGggg", invoiceResponse)
            this.setState({
                invoice: invoiceResponse.data.data,
                isLoading: false
            },
                function () {
                    var data = [];

                    this.setState({
                        total: invoiceResponse.data.total,
                        loaded: false,

                    })

                    let datas = JSON.parse(localStorage.getItem("BILL_CREATEPEAK"))
                    // let editedInvoice = [];

                    var data3 = [];
                    if (datas != null) {
                        for (let i = 0; i < datas.length; i++) {
                            let innvoiceNo = { bill_no: invoiceResponse.data.data[0].bill_no };
                            let client_id = { client_id: USER_ID };
                            data3.push(Object.assign(innvoiceNo, client_id, datas[i]));
                            this.setState({
                                data3: data3,
                                loaded: false
                            });
                        }
                        axios
                            .post(baseURL_2 + 'bill.php', data3)
                            .then(response => {

                                // alert(JSON.stringify(response.data));
                                localStorage.setItem("BILL_CREATEPEAK", null)
                                console.log("bayo", response)

                                this.setState({
                                    isLoading: false,
                                }, function () {
                                    axios
                                        .post(baseURL_2 + 'addquantityonpurchase.php', data3)
                                        .then(response => {
                                            // localStorage.setItem("INVOICE_CREATEPEAK", null)
                                            console.log("update_items", response)
                                        })
                                        .catch(error => {
                                        });
                                })
                            })
                            .catch(error => {
                                this.setState({
                                    statusMessage: error.response === undefined
                                        ? (window.location.href = '/log_in')
                                        : 'Something went wrong',
                                    showError: true,
                                    isShowError: false,
                                    loaded: false,
                                    isLoading: false,
                                });
                                window.scrollTo(0, 0);
                                // console.error('Error during service worker registration:', error);
                            });

                        if (this.state.invoice.length > 0) {
                            for (let i = 0; i < this.state.invoice.length; i++) {
                                let due_date = moment(this.state.invoice[i].due_date);
                                let cur_date = moment();
                                // let curDateFormated = cur_date.format('DD MMM, YYYY');
                                let dueDateFormated = due_date.format('DD MMM, YYYY');
                                const diff = cur_date.diff(due_date, 'days');
                                let mperiod = { due_period: diff }
                                let date1 = { dates1: dueDateFormated };
                                let date = { dates: (moment.utc(this.state.invoice[i].bill_date).format('DD/MM/YYYY')) };
                                let date_modified = { date_modified2: (moment(this.state.invoice[i].date_modified).format('DD/MM/YYYY HH:mm')) };
                                let mstatus_2;
                                let mstatus = this.state.invoice[i].status

                                if (mstatus == 0) {
                                    mstatus_2 = { mbalance: this.state.invoice[i].due_amount }

                                } else {
                                    mstatus_2 = { mbalance: CURRENCY + " 0.00" }
                                }
                                data.push(Object.assign(date1, date_modified, date, mstatus_2, mperiod, this.state.invoice[i]));
                                this.setState({
                                    data: data,
                                    loaded: false,
                                    isLoading: false
                                })
                            }
                        } else {
                            this.setState({
                                data: data,
                                loaded: false,
                                isLoading: false
                            })
                        }
                    } else {
                        if (this.state.invoice.length > 0) {
                            for (let i = 0; i < this.state.invoice.length; i++) {
                                let due_date = moment(this.state.invoice[i].due_date);
                                let cur_date = moment();
                                // let curDateFormated = cur_date.format('DD MMM, YYYY');
                                let dueDateFormated = due_date.format('DD MMM, YYYY');
                                const diff = cur_date.diff(due_date, 'days');
                                let mperiod = { due_period: diff }
                                let date1 = { dates1: dueDateFormated };
                                let mstatus_2;
                                let mstatus = this.state.invoice[i].status

                                if (mstatus == 0) {
                                    mstatus_2 = { mbalance: this.state.invoice[i].due_amount }

                                } else {
                                    mstatus_2 = { mbalance: CURRENCY + " 0.00" }
                                }
                                let date_modified = { date_modified2: (moment(this.state.invoice[i].date_modified).format('DD MMM, YYYY HH:mm')) };
                                let date = { dates: (moment.utc(this.state.invoice[i].bill_date).format('DD MMM, YYYY')) };
                                data.push(Object.assign(date1, date, mstatus_2, date_modified, mperiod, this.state.invoice[i]));
                                this.setState({
                                    data: data,
                                    loaded: false,
                                    isLoading: false,

                                })
                            }
                        } else {
                            this.setState({
                                data: data,
                                loaded: false,
                                isLoading: false,

                            })
                        }
                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)
            this.setState({
                loaded: false
            })

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            onEdit: false,
            showModal: false,
            showModals: false
        });
    }

    onEditInvoice(record) {
        localStorage.setItem("Invoice_No_Item", record.invoice_no)
        this.setState({
            showComponent: false,
            hideComponent: true,
            onEdit: true,
            showModal: false,
            showModals: false
        });
    }


    _onButtonClick2(record) {

        var byteCharacters = atob(record.pdf);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    onDelete(record) {

        console.log("Delete Record", record);
        axios.delete(baseURL + `question/` + record.question_id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 2000);
        });
    }

    _onShow(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            onEdit: false,
            acceptable_answers: record.acceptable_answers,
            answer: record.answer,
            answer_id: record.id,
        });
    }

    render() {
        const { showComponent, onEdit } = this.state;
        const { hideComponent, returnHome } = this.state;

        const { changeSidebarVisibility } = this.props;
        return (

            this.state.loaded == false ?

                <div style={{ marginTop: "-20px" }} >

                    {!hideComponent && (
                        <div>
                            < Card >
                                {returnHome ? "Redirecting please wait..." :

                                    <CardBody >
                                        <div className="panel-body" >
                                            <div className="row">

                                                <div className="col-md-6">
                                                    <h4><b>Bills</b></h4><br />
                                                </div>
                                                <div className="col-md-6">
                                                    <Link to="/create-bills">
                                                        <Button className="pull-right" color="primary
                                                    " onClick={this._onButtonClick} outline>Add Bill   <i className="fa fa-plus"></i>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h4>Total Bills are now at: <b>{this.state.total}</b></h4>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="col-md-10 offset-1">
                                                        <button className="pull-right" title="refresh" style={{ background: "white", border: "0px" }} onClick={this.getData}>
                                                            <RefreshIcon />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            {this.state.showError ? <div >

                                                <Alert color="danger" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>


                                            </div> : null}

                                            {this.state.isShowError ? (
                                                <div>

                                                    <Alert color="success" className="alert--colored" icon>
                                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                                        </p>
                                                    </Alert>

                                                </div>

                                            ) : null}

                                            <ReactDatatable
                                                config={this.config}
                                                records={this.state.data}
                                                columns={this.columns}
                                                dynamic={true}
                                                id="tsc"
                                                loading={this.state.isLoading}
                                                total_record={this.state.total}
                                                onChange={this.tableChangeHandler} />

                                        </div>
                                    </CardBody>
                                }
                            </Card>
                        </div>
                    )
                    }
                    {
                        showComponent && (
                            <div>
                                <Bills />
                            </div>
                        )
                    }
                    {
                        onEdit && (
                            // eslint-disable-next-line 
                            <Invoice_Edit />
                        )
                    }
                </div >
                :
                <div className="loader">

                    {LOADER}

                </div>
        )
    }
}