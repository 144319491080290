import React, { useEffect, useState } from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { BRANCH_ID, CONFIG, errorToast, successToast, ToastTable, TOKEN, USER_ID } from '../../_helpers/exports';
import { withTranslation } from 'react-i18next';
// import { Customers } from './customer_view';
import { Link } from 'react-router-dom';
import { ItemView } from './item_view';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';


const ItemEdit = (props) => {

    // alert(JSON.stringify(props))
    //handle input states
    const [state, setState] = useState({
        item_name: props.item_record.item_name,
        quantity: props.item_record.quantity,
        reference: props.item_record.reference,
        rate: props.item_record.rate,
        buying_price: props.item_record.buying_price,
        id: props.item_record.id
    })

    //handle boolens
    const [measurement_data, setMeasurement] = useState([])
    const [hideComponent, setHideComponent] = useState(false)
    const [showComponent, setShowComponent] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const [measurement_value, setMeasurementValue] = useState(props.item_record.measurements)
    const [check, setCheck] = useState(false)
    const [check_expiry, setCheckExpiry] = useState(props.item_record.expiry_status)
    const [expiry_date, setExpirty] = useState(new Date(props.item_record.end_date))


    useEffect(() => {
        let url = baseURL + `item_measurement`;
        setIsLoading(true)
        axios.get(url, CONFIG).then(response => {
            setIsLoading(false)
            setMeasurement(response.data.data)
        })
    }, [])

    const onSubmit = (e) => {
        e.preventDefault();

        var checkString = state.item_name.replaceAll("'", "");
        if (checkString != "") {
            var regex = new RegExp("^[0-9a-zA-Z\b ]+$");
            if (regex.exec(checkString) == null) {
                errorToast("Special characters are not allowed")
            } else {

                let formData = {
                    "client_id": USER_ID,
                    "quantity": state.quantity,
                    "rate": state.rate,
                    "measurements": measurement_value,
                    "reference": state.reference,
                    "item_id": state.id,
                    "branch_id": BRANCH_ID,
                    "check_expiry": check_expiry,
                    "buying_price": state.buying_price,
                    "expiry_date": moment(expiry_date).format("YYYY-MM-DD HH:mm:ss"),

                    "item_name": state.item_name.replaceAll("'", ""),

                }
                setIsLoading(true)

                axios.put(baseURL + `item`, formData, CONFIG)
                    .then((response) => {
                        setIsLoading(false)
                        if (response.data.status) {
                            successToast(response.data.message)
                            window.setTimeout(() => {
                                setShowComponent(true)
                                setHideComponent(true)
                            }, 3000);

                        } else {
                            errorToast(response.data.message)
                        }
                    })
                    .catch((error) => {

                        setIsLoading(false)
                        console.log('bayoo', error)
                        errorToast(error.response.data.message)
                    })
            }
        }

        // let item_name_first = ;
        // let item_name_second = this.state.item_name.replaceAll("‘", "");
    }



    // handle input changes 
    const handleChange = (e) => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        })
    }
    const _onButtonClick = () => {

        setShowComponent(true)
        setHideComponent(true)
    }

    const onSelectChangesShort = value => {
        if (value != null) {
            setMeasurementValue(value.label.toString())
        } else {
            setMeasurementValue("")
        }
    };


    const toggleChange = () => {
        setCheck(!check)
        setCheckExpiry(!check_expiry)
    }

    return (
        <div style={{ marginTop: "-20px" }} >
            {showComponent && (
                <div>
                    {showComponent ?
                        <ItemView /> : null
                    }
                </div>
            )}


            {!hideComponent && (
                <div>
                    <Card>
                        <CardBody>
                            <div className="row ">
                                <div className="col-md-6">
                                    <h4><b>Edit Item</b></h4><br />
                                </div>
                                <div className="col-md-6">
                                    <Link to="/item">
                                        <Button className="pull-right btn-sm" outline color="primary" onClick={_onButtonClick}>
                                            Back to Items
                                        </Button>
                                    </Link>

                                </div>
                            </div>

                            <ToastTable />
                            <form className="form row col-10 offset-1" onSubmit={onSubmit}>


                                <div className="col-md-6"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Item Name</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input id="input" type="item_name" className="form-control"
                                                    name="item_name" required placeholder='Item Name'
                                                    value={state.item_name} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Quantity</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input id="input" type="number" className="form-control"
                                                    name="quantity" required placeholder='Quantity'
                                                    value={state.quantity} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Measurement</label>
                                            </div>
                                            <div className="col-md-10">
                                                <Select
                                                    isClearable
                                                    options={measurement_data}
                                                    placeholder={"Select Measurement"}
                                                    autosize={true}
                                                    onChange={onSelectChangesShort}
                                                    isLoading={isLoading}
                                                    menuPortalTarget={document.body}
                                                    name="name"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Buying Price</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input id="input" type="number" className="form-control"
                                                    name="buying_price" placeholder='Buying Price'
                                                    value={state.buying_price} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Price Rate</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input id="input" type="number" className="form-control"
                                                    name="rate" required placeholder='Price Rate'
                                                    value={state.rate} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Reference</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input id="input" type="text" className="form-control"
                                                    name="reference" placeholder='Reference'
                                                    value={state.reference} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 offset-3"><br />
                                    <div className="col-md-12">
                                        <label>
                                            <input type="checkbox"
                                                checked={check}
                                                onChange={toggleChange}
                                            />&nbsp;
                                            Has Expiry Date

                                        </label>

                                    </div>
                                </div>
                                {check ?
                                    <div className="col-md-6">

                                        <br />
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Date of Expiry</label>
                                            </div>
                                            <div className="col-md-10 customDatePickerWidth">
                                                <DatePicker
                                                    selected={expiry_date}
                                                    label="DatePicker Label"
                                                    dateFormat="dd MMM yyyy"
                                                    required
                                                    value={moment(expiry_date).format("DD MMM yyyy")}
                                                    className="form-control"
                                                    id="input"
                                                    onChange={value =>
                                                        setExpirty(value)}
                                                />

                                            </div>
                                        </div>

                                    </div>
                                    : null
                                }


                                <br></br>
                                <br />
                                <br></br>
                                <br />
                                <div className="col-md-6"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <Link to="/item">
                                                    <Button className="pull-right btn-sm" outline color="primary" onClick={_onButtonClick}>
                                                        Cancel
                                                    </Button>
                                                </Link>

                                            </div>
                                            <div className="col-md-10">
                                                <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                                    {isLoading ? "Please Wait..." : "Save"}
                                                </Button> &nbsp;&nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            )
            }
        </div>
    )
}
export default withTranslation('common')(ItemEdit);
