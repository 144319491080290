import React from "react";
import data from './social.js'

const Contact = () => {
  return <section id="contact">
    <div className="col-md-12 text-center">
      View Park Towers, 14th Floor
      Utalii Lane – Nairobi<br />
      Cell phone – 0736 105920<br />
      {Object.keys(data.socials).map((key) => (
        <span className="social-link pr-4">
          <a href={data.socials[key].link} target='_blank'>
            {data.socials[key].icon}
          </a>
        </span>
      ))}
    </div>
  </section>;
};


export default Contact;