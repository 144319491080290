import { COMPANY_NAME, COMPANY_POSTAL_ADDRESS } from '../../_helpers/exports';
import * as moment from 'moment';
import pdfMake from "pdfmake/build/pdfmake";

export function viewJournal(params) {
    createPDFFromParams(params);
}

function createPDFFromParams(params) {
    let docDefinition = buildDocDefinition(params);

    pdfMake.createPdf(docDefinition).open();
}

function buildDocDefinition(params) {
    console.log("params", params.lineItems)



    return {
        footer: function () {
            return [
                { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 595 - 10, y2: 10, lineWidth: 0.5 }] },
                {
                    columns: [
                        { text: `${params.footer}`, bold: true, color: 'black', fontSize: 9, alignment: 'center', marginTop: 5 }
                    ],

                },
            ];
        },

        background: [
            {
                image: localStorage.getItem("image_urls"),
                alignment: 'center',
                width: 270,
                height: 280,
                marginLeft: 100,
                marginTop: 360,
                opacity: 0.1,
                borderRadius: 25,
            },
        ],

        header: {
            columns: [
                headerNotes(params)
            ]
        },
        content: [

            buildHeaderInformation(params),
            buildLineItemsTable(params),
            buildTotal(params)
        ],
    };
}


// Returns an array
function headerNotes(params) {
    if (params.status === 1) {
        return [
            { text: `\tPublished\t\t`, alignment: 'left', background: '#5cb85c', color: '#FFFFFF', margin: [5, 15, 0, 5] }
        ]

    } else {
        return [
            { text: `\tUnpublished\t\t`, alignment: 'left', background: '#5bc0de', color: '#FFFFFF', margin: [5, 15, 0, 5] }
        ]
    }
}

function buildHeaderInformation(params) {
    let date1 = moment(params.date).format('DD, MMM YYYY');

    const optionalDataKeys = [];
    const optionalDataValues = [];
    // let total = params.lineItems.reduce((sum, lineItem) => {
    //     return sum + lineItem.quantity * lineItem.price;
    // }, 0);
    Object.entries({
        "Date :": date1,
        'Amount :': params.amount,
    }).forEach(([key, value]) => {
        if (value) {
            optionalDataKeys.push(key);
            optionalDataValues.push(value);
        }
    });

    return {
        columns: [{
            stack: [
                {
                    text: "Notes",
                    color: "black",
                    margin: [0, 90, 0, 10],
                    // background: '#eee',
                },

                {
                    text: params.notes,
                    color: "black",
                    bold: true,
                    margin: [0, 0, 0, 10],
                    // background: '#eee',
                },

            ],
        },

        {

            stack: [
                {
                    text: 'JOURNAL',
                    bold: true,
                    fontSize: 25,
                },
                {
                    text: `# ${params.journal_no}`,
                    fontSize: 12,
                    bold: true,
                    margin: [0, 0, 0, 20],
                },
                {
                    columns: [{
                        width: 50,
                        text: '',
                    },
                    {
                        width: '*',
                        columns: [
                            {
                                stack: optionalDataKeys,
                                alignment: 'right',
                            },
                            {
                                stack: optionalDataValues,
                                alignment: 'right',

                            },
                        ],
                    },
                    ],
                },

            ],
            alignment: 'right',
        },
        ],

        styles: {
            subheader: {
                fontSize: 9,
                color: '#FFFFFF',
                background: '#2361AE',
                margin: [0, 15, 0, 5]
            }
        },
        // optional space between columns
        columnGap: 10,
        margin: [0, 0, 0, 30],
    };
}




function buildLineItemsTable(params) {
    var n = 0
    let params_two = params.lineItems.map(obj => (Object.assign(obj, { index: ++n })))
    let lineItemRows = params_two.map(buildLineItem(params));

    return {
        table: {
            widths: ['2%', '*', '25%', '25%', '25%'],

            headerRows: 1,
            body: [
                [{
                    text: "#",
                    color: '#FFFFFF',
                    margin: [5, 5, 5, 5],
                    fillColor: '#00000'
                },
                { text: 'Account' },
                { text: 'Tax (%)' },
                { text: 'Debit (' + params.currency + ')' },
                { text: 'Credit (' + params.currency + ')' },
                ],
                ...lineItemRows,
            ],
        },
        layout: 'lightHorizontalLines',
    };
}



function buildLineItem(params) {
    return function buildLineItemCurried(lineItem) {
        return [
            { text: lineItem.index, marginTop: 10 },

            { text: lineItem.account, marginTop: 10 },
            { text: `${lineItem.tax_percentage}`, marginTop: 10 },
            { text: `${lineItem.debit}`, marginTop: 10 },
            { text: `${lineItem.credit}`, marginTop: 10 },

        ];
    };
}



// function buildTotal(params) {
//     // let total = params.lineItems.reduce((sum, lineItem) => {
//     //     return sum + lineItem.quantity * lineItem.price;
//     // }, 0);

//     // let subtotal = params.lineItems.reduce((sum, lineItem) => {
//     //     return sum + (lineItem.quantity * lineItem) - params.tax;
//     // }, 0);
//     return {
//         table: {
//             widths: ['*', '40%'],
//             body: [
//                 [{
//                     text: 'Tax Amount',
//                     marginTop: 20,
//                     alignment: 'right',
//                 },
//                 {
//                     text: `${params.tax}`,
//                     marginTop: 20,
//                     alignment: 'right',
//                 },
//                 ],
//                 [{
//                     text: 'Subtotal',
//                     marginTop: 20,
//                     alignment: 'right',
//                 },
//                 {
//                     text: params.subtoal,
//                     marginTop: 20,
//                     alignment: 'right',
//                 },
//                 ],
//                 [{
//                     text: 'Discount',
//                     marginTop: 20,
//                     alignment: 'right',
//                 },
//                 {
//                     text: params.discount_amount,
//                     marginTop: 20,
//                     alignment: 'right',
//                 },
//                 ],
//                 [{
//                     text: 'Total',
//                     marginTop: 20,
//                     alignment: 'right',
//                 },
//                 {
//                     text: `${params.total}`,
//                     marginTop: 20,
//                     alignment: 'right',
//                 },
//                 ],
//             ],
//         },
//         layout: 'noBorders',
//         margin: [0, 0, 0, 30],
//     };
// }


function buildTotal(params) {
    return {
        table: {
            widths: ['*', '25%', '25%'],
            body: [
                [
                    {
                        text: 'Subtotal',
                        marginTop: 20,
                        alignment: 'right',
                    },
                    {
                        text: `${params.amount}`,
                        marginTop: 20,
                        alignment: 'right',
                    },
                    {
                        text: `${params.amount}`,
                        marginTop: 20,
                        alignment: 'right',
                    },
                ],
                [
                    {
                        text: 'Total',
                        marginTop: 20,
                        alignment: 'right',
                    },
                    {
                        text: `${params.amount}`,
                        marginTop: 20,
                        alignment: 'right',
                    },
                    {
                        text: `${params.amount}`,
                        marginTop: 20,
                        alignment: 'right',
                    },
                ],
            ],
        },
        layout: 'noBorders',
        margin: [0, 0, 0, 30],
    };
}