/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';

import { Button, Popover, UncontrolledPopover, PopoverHeader } from 'reactstrap';

import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { baseURL } from '../../../../_helpers';
import axios from 'axios';
import { BUSINESS_TYPE, CURRENCY, formatCurrency, LOADER_DASH, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../../../_helpers/exports';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import TrendingDownIcon from 'mdi-react/TrendingDownIcon';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

class Visits extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      loading: true,
      popoverOpen: false
    };
  }




  componentDidMount() {
    this.getData()
  }

  toggle = () => {
    this.setState({ popoverOpen: true })
  }

  getData() {
    const { startDate } = this.props;
    const { endDate } = this.props;
    const { reload } = this.props;

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": TOKEN
      }
    };
    this.setState({
      loading: true
    })


    let url = baseURL + `invoiceamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${moment(startDate).format('YYYY-MM-DD')} 00:00:00"&endDate="${moment(endDate).format('YYYY-MM-DD')} 23:59:59"`

    let url2 = baseURL + `getamountreceived?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${moment(startDate).format('YYYY-MM-DD')} 00:00:00"&endDate="${moment(endDate).format('YYYY-MM-DD')} 23:59:59"`

    let url_summary = baseURL + `agingsummary?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${moment(startDate).format('YYYY-MM-DD')} 00:00:00"&endDate="${moment(endDate).format('YYYY-MM-DD')} 23:59:59"`

    // alert(url_summary)

    axios.all([axios.get(url, axiosConfig), axios.get(url2, axiosConfig), axios.get(url_summary, axiosConfig)]).
      then(axios.spread((branchResponse, receivableResponse, agingResponse) => {
        this.setState({
          total: (branchResponse.data.data[0].amount + receivableResponse.data.data[0].amount_received),
          recevaibles: receivableResponse.data.data[0].amount_received,
          aging: agingResponse.data.data,
          loading: false
        })

        let valueAddedExpenses = 0
        let countExpense = 0

        let valueAddedExpenses1 = 0
        let countExpense1 = 0

        let valueAddedExpenses2 = 0
        let countExpense2 = 0

        let valueAddedExpenses3 = 0
        let countExpense3 = 0

        let valueAddedExpenses4 = 0
        let countExpense4 = 0

        let valueAddedExpenses5 = 0
        let countExpense5 = 0

        // alert(this.state.aging[0].current_amount)

        for (let i = 0; i < this.state.aging.length; i++) {


          let str1 = this.state.aging[i].total_amount;
          countExpense = parseFloat(str1.replaceAll(",", ""))
          valueAddedExpenses += countExpense

          let str2 = this.state.aging[i].current_amount;
          countExpense1 = parseFloat(str2.replaceAll(",", ""))
          valueAddedExpenses1 += countExpense1

          let str3 = this.state.aging[i].overdue_15;
          countExpense2 = parseFloat(str3.replaceAll(",", ""))
          valueAddedExpenses2 += countExpense2

          let str4 = this.state.aging[i].overdue_15_30;
          countExpense3 = parseFloat(str4.replaceAll(",", ""))
          valueAddedExpenses3 += countExpense3

          let str5 = this.state.aging[i].overdue_30_45;
          countExpense4 = parseFloat(str5.replaceAll(",", ""))
          valueAddedExpenses4 += countExpense4

          let str6 = this.state.aging[i].overdue_45;
          countExpense5 = parseFloat(str6.replaceAll(",", ""))
          valueAddedExpenses5 += countExpense5
        }

        this.setState({
          total_amount: valueAddedExpenses.toFixed(2),
          current_amount: valueAddedExpenses1.toFixed(2),
          overdue_15: valueAddedExpenses2.toFixed(2),
          overdue_15_30: valueAddedExpenses3.toFixed(2),
          overdue_30_45: valueAddedExpenses4.toFixed(2),
          overdue_45: valueAddedExpenses5.toFixed(2),

          isPageLoad: false
        })

        // alert(JSON.stringify(branchResponse.data.data[0].amount))
        this.props.recevaibles(parseFloat(branchResponse.data.data[0].amount) + parseFloat(receivableResponse.data.data[0].amount_received))
        // alert(JSON.stringify(branchResponse.data.data[0].amount))
      })).catch(error => {

        console.log('jwtcheck', error.response)

        // window.location.href = "/log_in";
      })
  }
  render() {
    const { activeIndex } = this.state;
    const { t } = this.props;
    const { reload } = this.props;

    return (

      <Col lg={6} xs={12} xl={6} >
        {

          reload === true ?
            <div>
              {
                this.getData()
              }
            </div>
            :
            null}
        <Card>
          <h4 className="bold-text">Receivables</h4>

          <CardBody className="dashboard__card-widget">
            <div className="mobile-app-widget">
              <Link to="/payment-received">
                <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                  <p className="mobile-app-widget__total-stat"> {this.state.loading == true ?
                    <p>{LOADER_DASH}</p>
                    :
                    `${BUSINESS_TYPE === 'business' ? "Total Sales" : "Total Income"} ${formatCurrency(parseFloat(this.state.total))}`
                  }

                  </p>

                  <TrendingDownIcon className="dashboard__trend-icon" />
                </div>
              </Link>
              <Link to="/invoices">

                <div className="mobile-app-widget__title">
                  <h5>Total Unpaid {BUSINESS_TYPE === 'business' ? "Invoice" : "Grant Income"}: <b>{this.state.loading == true ?
                    <p>{LOADER_DASH}</p>
                    :
                    formatCurrency(parseFloat(this.state.overdue_15) +
                      parseFloat(this.state.overdue_15_30) +
                      parseFloat(this.state.current_amount) +
                      parseFloat(this.state.overdue_30_45) + parseFloat(this.state.overdue_45))}
                  </b>
                  </h5>

                </div>

              </Link>
              <div className="progress-wrap progress-wrap--small progress-wrap--turquoise-gradient progress-wrap--label-top"
              >
                <Progress value={((
                  (this.state.overdue_15) +
                  parseFloat(this.state.overdue_15_30) +
                  parseFloat(this.state.current_amount) +
                  parseFloat(this.state.overdue_30_45) + parseFloat(this.state.overdue_45)) / this.state.total) * 100}>
                  <p className="progress__label">

                    {(((parseFloat(this.state.overdue_15) +
                      parseFloat(this.state.overdue_15_30) +
                      parseFloat(this.state.current_amount) +
                      parseFloat(this.state.overdue_30_45) + parseFloat(this.state.overdue_45)) / parseFloat(this.state.total)) * 100).toFixed(2)}%


                  </p></Progress>
              </div>

              <div className="row">
                <div className="col-6">
                  <Link to="/agingsummary">

                    <p style={{ color: "blue" }}>CURRENT</p>
                    <h4>{
                      this.state.loading == true ?
                        <p>{LOADER_DASH}</p>
                        :
                        formatCurrency(this.state.current_amount)}</h4>
                  </Link>
                </div>
                <div className="col-6">

                  <Link to="/agingsummary">
                    <p style={{ color: "red" }}>OVERDUE</p>
                    <Button id="PopoverFocus" visibility="hidden" toggle={this.popoverOpen} style={{ background: "white", border: "0px", padding: "0px" }} type="button">
                      <h4>
                        {

                          this.state.loading == true ?
                            <p>{LOADER_DASH}</p>
                            :
                            formatCurrency(parseFloat(this.state.overdue_15) + parseFloat(this.state.overdue_15_30) + parseFloat(this.state.overdue_30_45) + parseFloat(this.state.overdue_45))} <i className="arrow down"></i></h4>

                    </Button>
                  </Link>


                  <UncontrolledPopover style={{ width: "auto" }} trigger="legacy" placement="bottom" target="PopoverFocus">
                    <PopoverHeader>
                      <Link to="/agingsummary">
                        <div className="row">
                          <div className="col-6">
                            <p>
                              1-15 Days
                            </p>
                          </div>
                          <div className="col-6">
                            <p style={{ color: "blue" }}>{
                              this.state.loading == true ?
                                <p>{LOADER_DASH}</p>
                                :
                                formatCurrency(this.state.overdue_15)}
                            </p>
                          </div>
                        </div>
                      </Link>

                    </PopoverHeader><br />

                    <PopoverHeader> <Link to="/agingsummary">
                      <div className="row">
                        <div className="col-6">
                          <p>
                            15-30 Days
                          </p>
                        </div>
                        <div className="col-6">
                          <p style={{ color: "blue" }}>{
                            this.state.loading == true ?
                              <p>{LOADER_DASH}</p>
                              :
                              formatCurrency(this.state.overdue_15_30)}
                          </p>
                        </div>
                      </div>
                    </Link>
                    </PopoverHeader>

                    <br />

                    <PopoverHeader>
                      <Link to="/agingsummary">
                        <div className="row">
                          <div className="col-6">
                            <p>
                              35-45 Days
                            </p>
                          </div>
                          <div className="col-6">
                            <p style={{ color: "blue" }}>{
                              this.state.loading == true ?
                                <p>{LOADER_DASH}</p>
                                :
                                formatCurrency(this.state.overdue_30_45)}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </PopoverHeader>

                    <br />

                    <Link to="/agingsummary"><PopoverHeader>
                      <div className="row">
                        <div className="col-6">
                          <p>
                            Above 45 Days
                          </p>
                        </div>
                        <div className="col-6">
                          <p style={{ color: "blue" }}>{
                            this.state.loading == true ?
                              <p>{LOADER_DASH}</p>
                              :
                              formatCurrency(this.state.overdue_45)}
                          </p>
                        </div>
                      </div>
                    </PopoverHeader>
                    </Link>

                  </UncontrolledPopover>

                </div>
              </div>
            </div>
          </CardBody>
          <div className='row'>
            <div className='col-3'>
              <Link className='Links' to='/create-invoice'>CREATE INVOICE ?</Link>
            </div>
            <div className='col-5 pull-right'>
              <Link className='Links' to='/add-payment'>RECORD PAYMENT RECEIVED?</Link>
            </div>
            <div className='col-4 pull-right'>
              <Link className='L4nks' to='/add-payment'>CREATE CASH SALE?</Link>
            </div>

          </div>
        </Card>


      </Col >
    );
  }
}

export default withTranslation('common')(Visits);
