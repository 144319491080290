import React, { Component } from 'react';
import styles from './Invoice.module.scss';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Form } from 'react-bootstrap';
import LineItems from './LineItems.jsx';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { BRANCH_ID, CURRENCY, PROFILE_URL, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { baseURL, baseURL_2 } from '../../_helpers';
import Alert from '../../shared/components/Alert';
import { Link, Navigate } from 'react-router-dom';
import $ from 'jquery';
import * as moment from 'moment';
import { RadioGroup, ReversedRadioButton, RadioButton } from 'react-radio-buttons'

import { updatedCreditPDF } from './creditNoteUpdate';

window.$ = window.jQuery = require('jquery');

require('../../_assets/jss/sms_counter.js');

class Edit_CreditVendor extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this._onButtonClick = this._onButtonClick.bind(this);
        this.onChangeOption = this.onChangeOption.bind(this);

    }

    locale = 'en-US';
    currency = CURRENCY;

    state = {
        taxRate: 0.00,
        credit_no: 'IN-2399399',
        loaded: true,
        credit_date: new Date(),
        hideComponent: false,
        showComponent: false,
        editedInvoice: {},
        returnHome: false,
        lineItems: [
            {
                id: 'initial', // react-beautiful-dnd unique key
                name: '',
                description: '',
                quantity: 2,
                discount_percentage: 0,
                tax_percentage: 0,
                price: 10.00,
            }
        ],
    };
    async componentDidMount() {
        this.getData();

        $('#message').countSms('#sms-counter');
        $('#messages').countSms('#sms-counters');



        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = `${PROFILE_URL}?canvas=true`;
        }


    }

    _onButtonClick() {
        this.setState({
            returnHome: true,
            hideComponent: true,
        });
    }
    handleLineItemChange = elementIndex => event => {

        console.log(this.state.lineItems);

        let lineItems = this.state.lineItems.map((item, i) => {
            if (elementIndex !== i) return item;
            // alert(elementIndex);
            return { ...item, [event.target.name]: event.target.value };
        });
        this.setState({ lineItems });
    };
    Customer() {
        return (
            this.state.customerResponse &&
            (this.state.customerResponse.length > 0 || this.state.customerResponse.length === 0) &&
            this.state.customerResponse.map((countyItem, i) => ({
                label: countyItem.customer_display_name + " (" + countyItem.email + ")",
                customer_email: countyItem.email,
                customer_nameinvoice: countyItem.customer_display_name,
                value: countyItem.id,
            }))
        );
    }

    handleAddLineItem = event => {
        alert("You cant add new line. Is disabled")
    };

    handleRemoveLineItem = elementIndex => event => {
        alert("You cant delete. Is disabled")
    };

    handleReorderLineItems = newLineItems => {
        this.setState({
            lineItems: newLineItems,
        });
    };
    handleChangeInvoiceMessage = event => {
        this.setState({ customer_note: event.target.value }, function () { });
        //alert(event.target.value)
    };

    handleChangeStatementMessage = event => {
        this.setState({ terms_condition: event.target.value }, function () { });
        //alert(event.target.value)
    };

    handleFocusSelect = event => {
        event.target.select();
    };

    handlePayButtonClick = () => {
        alert('Not implemented');
    };


    formatCurrency = amount => {
        return new Intl.NumberFormat(this.locale, {
            style: 'currency',
            currency: "ABS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount).replaceAll('ABS', CURRENCY);
    };
    calcTaxAmount = c => {
        return c * (localStorage.getItem('valuestax') / 100);
    };

    calcLineItemsTotal = () => {
        return this.state.lineItems.reduce(
            (prev, cur) => prev + cur.quantity * cur.price,
            0
        );
    };

    calcLineItemDiscounts = () => {
        return this.state.lineItems.reduce(
            (prev, cur) => prev + cur.discount_percentage / 100 * (cur.quantity * cur.price),
            0
        );
    };


    calcTaxTotal = () => {
        if (this.state.showtax == true) {
            return this.state.lineItems.reduce(
                (prev, cur) => prev + ((cur.tax_percentage / 100) * (cur.quantity * cur.price)),
                0
            );
        } else {
            return this.state.lineItems.reduce(
                (prev, cur) => 0 + (0 * 0),
                0
            );
        }
    };
    calcGrandTotal = () => {
        return (
            this.calcLineItemsTotal() +
            this.calcTaxTotal() -
            this.calcLineItemDiscounts()
        );
    };

    onSelectChangesShort = value => {
        if (value != null) {

            this.setState(
                {
                    ...this.state.editedInvoice,
                    customer_id: value.value.toString(),
                    customer_name: value.label.toString(),
                    customer_nameinvoice2: value.customer_nameinvoice.toString(),
                    customer_email: value.customer_email != null ?  value.customer_email.toString(): '',

                });
        }
    };

    handleChange = e => {
        this.setState({
            ...this.state.editedInvoice,
            [e.target.name]: e.target.value,
        });

        console.log('updates', this.state.editedInvoice);
    };


    getData = (queryString = "") => {
        // alert(localStorage.getItem("Credit_No_Item"))
        this.setState({
            isLoading: false,
            isPageLoad: true
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `creditvendor?page_size=1000&page_number=1&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID + `&&filter_value=` + localStorage.getItem("Credit_No_Item")
        let url2 = baseURL + `creditvendorItem?filter_value=` + localStorage.getItem("Credit_No_Item")

        axios.all([
            axios.get(url, axiosConfig),
            axios.get(url2, axiosConfig),
        ]).then(axios.spread((invoiceResponse, invoiceResponse2) => {
            alert(JSON.stringify(invoiceResponse))
            this.setState({
                invoice: invoiceResponse.data.data,
                lineItems: invoiceResponse2.data.data,
            },
                function () {
                    this.setState({
                        returnHome: false,
                        credit_no: this.state.invoice[0].credit_no,
                        credit_date: this.state.invoice[0].credit_date,
                        // terms: [{ label: this.state.invoice[0].terms, value: this.state.invoice[0].terms }],
                        reference: this.state.invoice[0].reference,
                        tax_exclusive: this.state.invoice[0].tax_exclusive.toString(),

                        terms_condition: this.state.invoice[0].terms_condition,
                        customer_note: this.state.invoice[0].notes,
                        customer_email: this.state.invoice[0].email,
                        amount: this.state.invoice[0].due_amount,
                        due_amount: this.state.invoice[0].due_amount,
                        tax_amount: this.state.invoice[0].tax_amount,
                        status: this.state.invoice[0].status,
                        discount_amount: this.state.invoice[0].discount_amount,
                        currency: CURRENCY,
                        customer_name: [{ label: this.state.invoice[0].vendor_display_name, value: this.state.invoice[0].vendor_display_name }],
                        customer_nameUpdate: this.state.invoice[0].vendor_display_name,
                        tax: this.state.invoice[0].tax_amount,
                        subtoal: this.state.invoice[0].sub_total,
                        total: this.state.invoice[0].due_amount,
                        loaded: false,
                        isPageLoad: false
                    }, function () {
                        if (this.state.tax_exclusive == 1) {

                            this.setState({
                                showtax: true
                            })
                        } else {
                            this.setState({
                                showtax: false
                            })
                        }
                    })
                })
        })).catch(error => {
            console.log('bayoo', error.response)
        })
        // alert(JSON.stringify(this.state.lineItems))

    }
    formatCurrency1 = amount => {
        return new Intl.NumberFormat(this.locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };

    onSubmit(e) {
        e.preventDefault();
        // eslint-disable-next-line
        this.state.editedInvoice = {

            credit_date: this.state.credit_date,
            credit_no: this.state.credit_no,
            notes: this.state.customer_note,
            customer_name: this.state.customer_nameinvoice2,
            tax_exclusive: this.state.tax_exclusive,
            customer_email: this.state.customer_email,
            amount1: this.formatCurrency1(this.calcGrandTotal()),
            amount: this.formatCurrency(this.calcGrandTotal()),
            due_amount: this.formatCurrency(this.calcGrandTotal()),
            tax_amount: this.formatCurrency(this.calcTaxTotal()),
            discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
            lineItems: this.state.lineItems,
            currency: CURRENCY,
            tax: this.formatCurrency(this.calcTaxTotal()),
            subtoal: this.formatCurrency(this.calcLineItemsTotal()),
            total: this.formatCurrency(this.calcGrandTotal()),

        };

        let formData = {
            credit_date: this.state.credit_date,

            tax_exclusive: this.state.tax_exclusive,
            notes: this.state.customer_note,
            credit_no: this.state.credit_no,
            email: this.state.customer_email,
            sub_total: this.formatCurrency(this.calcLineItemsTotal()),
            amount: this.formatCurrency(this.calcGrandTotal()),
            due_amount: this.formatCurrency(this.calcGrandTotal()),
            tax_amount: this.formatCurrency(this.calcTaxTotal()),
            discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
            created_by: USER_ID,
            vendor_id: this.state.invoice[0].vendor_id,
        };
        // alert(JSON.stringify(this.state.editedInvoice))

        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios
            .put(baseURL + `creditvendor`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: TOKEN,
                },
            })
            .then(response => {
                if (response.data.status) {
                    this.setState({
                        statusMessage: response.data.message,
                        showError: false,
                        isShowError: true,
                    });

                    let datas = this.state.lineItems;
                    let data = [];
                    for (let i = 0; i < datas.length; i++) {
                        let innvoiceNo = { invoice_no: this.state.invoice_no };
                        data.push(Object.assign(innvoiceNo, datas[i]));
                        this.setState({
                            data: data,
                        });
                        // console.log("DATA", datas[i])
                    }
                    // console.log("brian", data)
                    axios
                        .post(baseURL_2 + 'updatecredit_item.php', data)
                        .then(response => {
                            // alert(JSON.stringify(response))
                            // console.log("bayo", response)
                            updatedCreditPDF(this.state.editedInvoice);

                            this.setState({
                                statusMessage: 'Credit note has been updated successfully',
                                isShowError: true,
                                isLoading: false,
                                showError: false,
                            });
                            window.setTimeout(() => {
                                this.setState({ isShowError: false, returnHome: true });
                                this._onButtonClick();
                            }, 2000);
                            window.scrollTo(0, 0);
                        })
                        .catch(error => {
                            console.log("Vccc", error)
                            this.setState({
                                statusMessage: error.response === undefined
                                    ? (window.location.href = '/log_in')
                                    : 'Something went wrong',
                                showError: true,
                                isShowError: false,
                                isLoading: false,
                            });
                            window.scrollTo(0, 0);
                            // console.error('Error during service worker registration:', error);
                        });
                } else {
                    this.setState({
                        statusMessage: response.data.message,
                        showError: true,
                        isShowError: false,
                        isLoading: false,
                    });
                    window.scrollTo(0, 0);
                }
            })
            .catch(error => {
                console.log('bayoo', error.response);
                this.setState({
                    statusMessage: error.response.data.message,
                    showError: true,
                    isShowError: false,
                    isLoading: false,
                });
                window.scrollTo(0, 0);
            });
    }

    loadOptions = inputValue => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                Authorization: TOKEN,
            },
        };
        this.setState({
            isLoading: true,
        });
        let url =
            baseURL +
            `customer?branch_id=${BRANCH_ID}&&client_id=` +
            USER_ID +
            `&&filter_value=${inputValue}`;
        axios.get(url, axiosConfig).then(itemResponse => {
            this.setState(
                {
                    item: itemResponse.data.data,
                },
                function () {
                    this.setState({
                        isLoading: false,
                        bayo: this.state.item &&
                            (this.state.item.length > 0 || this.state.item.length === 0) &&
                            this.state.item.map((countyItem, i) => ({
                                label: countyItem.customer_display_name,
                                customer_email: countyItem.email,
                                value: countyItem.id,
                            })),
                    });
                }
            );
        });
    };


    onChangeOption(value) {
        console.log(value);
        if (value == 1) {
            this.setState({
                showtax: true,
                tax_inclusive: '0',
                tax_exclusive: 1,
            })
        } else {
            this.setState({
                showtax: false,
                tax_inclusive: '1',
                tax_exclusive: 0

            })
        }
        // alert(value)
    }

    handleCreate = inputValue => {
        const newValue = { value: inputValue.toLowerCase(), label: inputValue };
        this.setState({ newValue })
        // setOptions([...options, newValue]);
        // setValue(newValue);

    };
    render = () => {
        // alert(JSON.stringify(this.state.editedInvoice))

        // console.log('ITEMS',);
        const ref = React.createRef();
        const { showComponent, returnHome, hideComponent } = this.state;

        return (
            <div style={{ marginTop: "-20px" }} >
                {returnHome === true ? <Navigate to='/vendor-credit' /> : null}
                {!hideComponent && (
                    <div>
                        {/* <Loader loaded={this.state.loaded}> */}

                        <div ref={ref}>
                            <Card>
                                {this.state.isPageLoad ? "Loading..." :

                                    <CardBody>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4><b>Edit Credit Note</b></h4>
                                            </div>
                                            <div className="col-md-6">
                                                <Link to="/vendor-credit">
                                                    <Button className="pull-right" onClick={this._onButtonClick}
                                                        color="primary" outline><i className="fa  fa-arrow-left"></i> Credit Notes
                                                     </Button>
                                                </Link>

                                            </div>
                                        </div>

                                        {this.state.showError
                                            ? <div>

                                                <Alert color="danger" className="alert--colored" icon>
                                                    <p>
                                                        <span className="bold-text">
                                                            {this.state.statusMessage}
                                                        </span>
                                                    </p>
                                                </Alert>

                                            </div>
                                            : null}<br />

                                        {this.state.isShowError
                                            ? <div>

                                                <Alert color="success" className="alert--colored" icon>
                                                    <p>
                                                        <span className="bold-text">
                                                            {this.state.statusMessage}
                                                        </span>
                                                    </p>
                                                </Alert>

                                            </div>
                                            : null}<br />
                                        <form className="form" onSubmit={this.onSubmit}>
                                            <br /><br />
                                            <div className="col-md-8 offset-2">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <label className="form-label">Supplier</label>
                                                        </div>
                                                        <div className="col-md-10">

                                                            <Select
                                                                isClearable
                                                                options={this.Customer()}
                                                                onInputChange={this.loadOptions}
                                                                placeholder="Search Supplier"
                                                                autosize={true}
                                                                onChange={this.onSelectChangesShort}
                                                                isLoading={this.state.isLoading}
                                                                value={this.state.customer_name}
                                                                // noOptionsMessage={() => 'nothing found'}
                                                                // loadingMessage={() => 'searching...'}   //minor type-O here
                                                                menuPortalTarget={document.body}
                                                                name="namffe"
                                                            /><br />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-8 offset-2">
                                                <br />
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <label className="form-label">Credit#</label>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <input
                                                                id="input"
                                                                type="text"
                                                                className="form-control"
                                                                readOnly
                                                                name="credit_no"
                                                                value={this.state.credit_no}
                                                                required
                                                                placeholder="Credit No"
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8 offset-2">
                                                <br />
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <label className="form-label">Reference#.</label>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <input
                                                                id="input"
                                                                type="text"
                                                                className="form-control"
                                                                readOnly
                                                                name="reference"
                                                                value={this.state.reference}
                                                                required
                                                                placeholder="Reference No"
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8 offset-2">
                                                <br />
                                                <Form.Group className="Focus-line">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <Form.Label>Credit date</Form.Label>
                                                        </div>
                                                        <div className="col-md-10 customDatePickerWidth">
                                                            <DatePicker
                                                                // selected={this.state.invoice_date}
                                                                label="DatePicker Label"
                                                                dateFormat="DD MMM yyyy"
                                                                required
                                                                minDate={moment().toDate()}
                                                                value={moment(this.state.credit_date).format("DD MMM yyyy")}
                                                                className="form-control"
                                                                id="input"
                                                                onChange={value =>
                                                                    this.setState({ credit_date: value })}
                                                            />

                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </div>


                                            <div className="col-md-10 offset-1">
                                                <div className="col-md-12">
                                                    <br />
                                                    <div className="col-md-3" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>

                                                        <RadioGroup onChange={this.onChangeOption} value={this.state.tax_exclusive} horizontal>
                                                            <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                                                Tax Exclusive
                                                    </RadioButton>
                                                            <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                                                Tax Inclusive
                                                   </RadioButton>

                                                        </RadioGroup>
                                                    </div>
                                                    <br />
                                                    {/* <LineItems
                                                        items={this.state.lineItems}
                                                        currencyFormatter={this.formatCurrency}
                                                        showtax={this.state.showtax}
                                                        addHandler={this.handleAddLineItem}
                                                        changeHandler={this.handleLineItemChange}
                                                        focusHandler={this.handleFocusSelect}
                                                        deleteHandler={this.handleRemoveLineItem}
                                                        reorderHandler={this.handleReorderLineItems}
                                                    /> */}


                                                    <div className="col-md-12">

                                                        <div className={styles.totalContainer}>

                                                            <form></form>
                                                            <form>
                                                                <div className={styles.valueTable}>
                                                                    <div className={styles.row}>
                                                                        <div className={styles.label}>Subtotal</div>
                                                                        <div
                                                                            className={`${styles.value} ${styles.currency}`}
                                                                        >
                                                                            {this.formatCurrency(
                                                                                this.calcLineItemsTotal()
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className={styles.row}>
                                                                        <div className={styles.label}>Discount</div>
                                                                        <div
                                                                            className={`${styles.value} ${styles.currency}`}
                                                                        >
                                                                            {this.formatCurrency(
                                                                                this.calcLineItemDiscounts()
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className={styles.row}>
                                                                        <div className={styles.label}>
                                                                            Tax
                                </div>
                                                                        <div
                                                                            className={`${styles.value} ${styles.currency}`}
                                                                        >
                                                                            {this.formatCurrency(this.calcTaxTotal())}
                                                                        </div>
                                                                    </div>
                                                                    <div className={styles.row}>
                                                                        <div className={styles.label}>Total Due</div>
                                                                        <div
                                                                            className={`${styles.value} ${styles.currency}`}
                                                                        >
                                                                            {this.formatCurrency(this.calcGrandTotal())}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>

                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8 offset-2">
                                                <br />
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-2">

                                                            <label className="form-label">
                                                                Customer Notes
                                                              </label>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <textarea
                                                                name="customer_note"
                                                                
                                                                value={this.state.customer_note}
                                                                style={{
                                                                    paddingTop: '20px',
                                                                    borderRadius: '5px',
                                                                    borderColor: 'hsl(0,0%,80%)',
                                                                }}
                                                                onChange={this.handleChangeInvoiceMessage}
                                                                className="col-md-12"
                                                                placeholder="Hint: This will show up on the invoice"
                                                                id="message"
                                                                cols="col-md-1"
                                                                rows="1"
                                                            />
                                                        </div>


                                                    </div>
                                                </div>
                                                <br />
                                            </div>

                                            <div className="col-md-8 offset-2">
                                                <br />
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-2">

                                                            <label className="form-label">
                                                                Terms & Conditions
                                                               </label>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <textarea
                                                                name="terms_condition"
                                                                
                                                                u value={this.state.terms_condition}

                                                                style={{
                                                                    paddingTop: '20px',
                                                                    borderRadius: '5px',
                                                                    borderColor: 'hsl(0,0%,80%)',
                                                                }}
                                                                onChange={this.handleChangeStatementMessage}
                                                                className="col-md-12"
                                                                placeholder="Hint: If you send statements to customers, this will show up as their description for this invoice"
                                                                id="messages"
                                                                cols="col-md-1"
                                                                rows="1"
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <div className="col-md-12"><br />
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <Link to="/credit-note">
                                                                <Button outline color="primary" onClick={this._onButtonClick}>
                                                                    Cancel
                                                                     </Button>
                                                            </Link>

                                                        </div>
                                                        <div className="col-md-10">
                                                            <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                                {this.state.isLoading ? "Please Wait..." : "Save"}

                                                            </Button> &nbsp;&nbsp;&nbsp;
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                        <br />

                                    </CardBody>
                                }
                            </Card>
                        </div>
                    </div>
                )
                }
                {
                    showComponent && (
                        <div>
                            <Link to='invoices' />

                            <invoice_view />
                        </div>
                    )
                }
            </div >

        );
    };
}

export default Edit_CreditVendor;
