
import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { CONFIG, CURRENCY, PROFILE_URL, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link, Navigate } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import { viewCreditPDF } from './creditView';
import { saveCreditPDF } from './creditPdfService';
import CreditNote from './credit_note';
import Edit_Credit from './edit_credit';
import CreditNoteBill from './credit_bills';
import { Credit_ReceivedBills } from './creditnotepaybill';


export class Credit_note_bill extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);

        this.columns = [
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "credit_no",
                TrOnlyClassName: 'tsc',
                text: "Credit#",
                className: "tsc",
                align: "left"
            },
            {
                key: "reference",
                TrOnlyClassName: 'tsc',
                text: "Reference#",
                className: "tsc",
                align: "left"
            },
            {
                key: "vendor_display_name",
                TrOnlyClassName: 'tsc',
                text: "Supplier",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "status",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.status === 0 ?
                                <div>
                                    <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                        CLOSE
                                    </span>
                                </div>
                                : null}
                            {record.status === 1 ?
                                <span class="badge-info" style={{ borderRadius: "5px", padding: "2px" }}>
                                    OPEN
                                </span>
                                : null}

                        </Fragment >
                    );
                }
            },


            {
                key: "amount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "due_amount",
                TrOnlyClassName: 'tsc',
                text: "Balance",
                className: "tsc",
                align: "left"
            },
            {
                key: "date_modified2",
                TrOnlyClassName: 'tsc',
                text: "Last Updated",
                className: "tsc",
                align: "left"
            },

            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-success  btn-sm"
                                title="View"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick(record) }} >
                                View
                            </button>
                            {record.status === 1 ?
                                <div>
                                    <Link to="/edit-credit-vendor">
                                        <button className="btn btn-primary btn-sm"
                                            title="Edit Invoice"
                                            style={
                                                { marginRight: '10px' }}
                                            onClick={
                                                () => { this.onEditInvoice(record) }} >
                                            Edit
                                        </button>
                                    </Link>

                                    <button className="btn btn-primary btn-sm"
                                        title="Edit Invoice"
                                        style={
                                            { marginRight: '10px' }}
                                        onClick={
                                            () => { this.onApplyCredit(record) }}
                                    >
                                        Apply to Bills
                                    </button>
                                </div>
                                : null}

                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            button: {
                excel: true,
                csv: true
            },
            filename: "credit_note",
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Invoice no...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showapply: false,
            showComponent: false,
            onEdit: false,
            returnHome: false,
            _onShow: false,
            hideComponent: false,
            data: [],
        };
    }
    async handleOnClick1(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onEditInvoice(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }
    async handleOnClick(record) {
        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })


        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        this.setState({
            returnHome: true
        })
        let url = baseURL + `creditvendorItem?filter_value=` + record.credit_no
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            this.setState({
                lineItems: invoiceResponse.data.data,
                returnHome: false
            })


            this.state.editedInvoice = {
                credit_no: record.credit_no,
                credit_date: record.credit_date,
                due_date: record.dates1,
                customer_note: record.vendor_note,
                terms_condition: record.terms_condition,
                customer_name: record.vendor_display_name,
                customer_email: record.email,
                amount: record.due_amount,
                due_amount: record.due_amount,
                lineItems: this.state.lineItems,
                tax_amount: record.tax_amount,
                due_period: record.due_period,
                status: record.status,
                discount_amount: record.discount_amount,
                currency: CURRENCY,
                tax: record.tax_amount,
                subtoal: record.sub_total,
                total: record.due_amount,
            };
            viewCreditPDF(Object.assign(footer, this.state.editedInvoice));

        })).catch(error => {
            console.log('bayoo', error.response)

        })


        // console.log("hoge!");
        // if (await confirm("Y")) {
        // this._onButtonClick2(record)
        // } else {
        //     this.setState({
        //         message: "No!"
        //     });
        // }
    }


    onApplyCredit(record) {

        localStorage.setItem("Credit_No_Item1", record.credit_no)

        localStorage.setItem("Credit_No_Customer1", record.vendor_display_name)
        localStorage.setItem("Credit_No_Customer_ID1", record.vendor_id)
        localStorage.setItem("Credit_No_Due_Amout1", record.due_amount)

        this.setState({
            showComponent: false,
            hideComponent: true,
            onEdit: false,
            showapply: true,
            showModal: false,
            showModals: false
        });
    }

    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {
        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = `${PROFILE_URL}?canvas=true`;
        }
        this.getData();
    }
    getData = async (queryString = "") => {

        let footer;
        const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
            if (response.data.data.length > 0) {
                footer = { footer: response.data.data[0].footer_notes };
            } else {
                footer = { footer: "" };
            }
        }).catch(error => {
            footer = { footer: "" };
        })


        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `creditvendor?page_size=1000&page_number=1&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID + "&&" + queryString
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            console.log("BUGggg", invoiceResponse)
            this.setState({
                invoice: invoiceResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];

                    this.setState({
                        total: invoiceResponse.data.total
                    })
                    let datas = JSON.parse(localStorage.getItem("CREDIT_CREATEPEAKD"))
                    // let editedInvoice = [];
                    var data3 = [];
                    if (datas != null) {
                        let datasinvoice = JSON.parse(localStorage.getItem("CREDIT_SENDEMAILD"))
                        let innvoiceNo = { credit_no: invoiceResponse.data.data[0].credit_no };

                        saveCreditPDF(Object.assign(footer, innvoiceNo, datasinvoice));
                        for (let i = 0; i < datas.length; i++) {
                            let innvoiceNo = { credit_no: invoiceResponse.data.data[0].credit_no };
                            let client_id = { client_id: USER_ID };
                            data3.push(Object.assign(innvoiceNo, client_id, datas[i]));
                            this.setState({
                                data3: data3,
                            });
                        }
                        axios
                            .post(baseURL_2 + 'creditvendor.php', data3)
                            .then(response => {
                                localStorage.setItem("CREDIT_CREATEPEAKD", null)
                                console.log("bayo", response)
                            })
                            .catch(error => {
                                // this.setState({
                                //     statusMessage: error.response === undefined
                                //         ? (window.location.href = '/log_in')
                                //         : 'Something went wrong',
                                //     showError: true,
                                //     isShowError: false,
                                //     isLoading: false,
                                // });
                                window.scrollTo(0, 0);
                                // console.error('Error during service worker registration:', error);
                            });

                        for (let i = 0; i < this.state.invoice.length; i++) {
                            let due_date = moment(this.state.invoice[i].due_date);
                            let cur_date = moment();
                            // let curDateFormated = cur_date.format('DD MMM, YYYY');
                            let dueDateFormated = due_date.format('DD MMM, YYYY');
                            const diff = cur_date.diff(due_date, 'days');
                            let mperiod = { due_period: diff }
                            let date1 = { dates1: dueDateFormated };
                            let date = { dates: (moment.utc(this.state.invoice[i].invoice_date).format('DD MMM, YYYY')) };
                            let date_modified = { date_modified2: (moment(this.state.invoice[i].date_modified).format('DD MMM, YYYY HH:mm')) };

                            data.push(Object.assign(date1, date_modified, date, mperiod, this.state.invoice[i]));
                            this.setState({
                                data: data
                            })
                        }
                    } else {

                        for (let i = 0; i < this.state.invoice.length; i++) {
                            let due_date = moment(this.state.invoice[i].due_date);
                            let cur_date = moment();
                            // let curDateFormated = cur_date.format('DD MMM, YYYY');
                            let dueDateFormated = due_date.format('DD MMM, YYYY');
                            const diff = cur_date.diff(due_date, 'days');
                            let mperiod = { due_period: diff }
                            let date1 = { dates1: dueDateFormated };
                            let date_modified = { date_modified2: (moment(this.state.invoice[i].date_modified).format('DD MMM, YYYY HH:mm')) };
                            let date = { dates: (moment.utc(this.state.invoice[i].invoice_date).format('DD MMM, YYYY')) };
                            data.push(Object.assign(date1, date, date_modified, mperiod, this.state.invoice[i]));
                            this.setState({
                                data: data
                            })
                        }
                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            onEdit: false,
            showModal: false,
            showapply: false,
            showModals: false
        });
    }

    onEditInvoice(record) {
        localStorage.setItem("Credit_No_Item", record.credit_no)
        this.setState({
            showComponent: false,
            hideComponent: true,
            showapply: false,
            onEdit: true,
            showModal: false,
            showModals: false
        });
    }


    _onButtonClick2(record) {

        var byteCharacters = atob(record.pdf);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    onDelete(record) {

        console.log("Delete Record", record);
        axios.delete(baseURL + `question/` + record.question_id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 2000);
        });
    }

    _onShow(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            onEdit: false,
            acceptable_answers: record.acceptable_answers,
            answer: record.answer,
            answer_id: record.id,
        });
    }

    render() {
        const { showComponent, showapply, onEdit } = this.state;
        const { hideComponent, returnHome } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
                    <div>
                        < Card >
                            {returnHome ? "Navigateing please wait..." :

                                <CardBody >
                                    <div className="panel-body" >
                                        <div className="row">

                                            <div className="col-md-6">
                                                <h4><b>List of Credit Notes</b></h4><br />
                                            </div>
                                            <div className="col-md-6">
                                                <Link to="/vendor-credit-create">
                                                    <Button className="pull-right" color="primary
                                                    " onClick={this._onButtonClick} outline>Add Credit Note  <i className="fa fa-plus"></i>
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Total Credit Notes are now at: <b>{this.state.total}</b></h4>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="col-md-10 offset-1">
                                                    <button className="pull-right" title="refresh" style={{ background: "white", border: "0px" }} onClick={this.getData}>
                                                        <RefreshIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        {this.state.showError ? <div >

                                            <Alert color="danger" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>


                                        </div> : null}

                                        {this.state.isShowError ? (
                                            <div>

                                                <Alert color="success" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>

                                            </div>

                                        ) : null}

                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />

                                    </div>
                                </CardBody>
                            }
                        </Card>
                    </div>
                )
                }
                {
                    showComponent && (
                        <div>
                            <CreditNoteBill />
                        </div>
                    )
                }

                {
                    showapply && (
                        <div>
                            <Navigate to='/credit_apply' />
                            <Credit_ReceivedBills />
                        </div>
                    )
                }

                {/* {
                    onEdit && (
                        // eslint-disable-next-line 
                        <Edit_Credit />
                    )
                } */}
            </div >
        )
    }
}
