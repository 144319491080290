import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../../_helpers';
import { TOKEN, USER_ID } from '../../../_helpers/exports';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { confirm } from "../../../shared/components/confirm";
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import Alert from '../../../shared/components/Alert';
import AddSalesPerson from './AddSalesPerson';
import EditCurrency from './EditCurrency';

export class CurrencyView extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick3 = this._onButtonClick3.bind(this);
        this._onButtonClick5 = this._onButtonClick5.bind(this);

        this.handleOnClick = this.handleOnClick.bind(this);

        this.columns = [
            {
                key: "name",
                TrOnlyClassName: 'tsc',
                text: "Country",
                className: "tsc",
                align: "left"
            },
            {
                key: "currency",
                TrOnlyClassName: 'tsc',
                text: "Currency",
                className: "tsc",
                align: "left"
            },

            {
                key: "agnaist_ksh",
                TrOnlyClassName: 'tsc',
                text: "Against Kenyan Shilling",
                className: "tsc",
                align: "left"
            },

            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        // eslint-disable-next-line
                        <Fragment className="center" >
                            <button className="btn btn-primary  btn-sm"
                                title="Edit details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this._onButtonClick(record) }} >
                                Edit
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 30, 40, 50],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 1000,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search.. Item Name",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {

            isLoading: true,
            showError: false,
            isShowError: false,
            showComponent: false,
            _onShow: false,
            showEdit: false,
            hideComponent: false,
            data: [],
        };
    }

    async handleOnClick(record) {
        if (await confirm("Are your sure?")) {
            this.onDelete(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }
    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        // alert(USER_ID)
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `currency`
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((itemResponse) => {
            this.setState({
                item: itemResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];

                    this.setState({
                        data: itemResponse.data.data
                    })
                })
        })).catch(error => {
            console.log('bayoo', error.response)
        })
    }



    _onButtonClick(record) {

        localStorage.setItem("selectedCurrencyType", record.currency)

        localStorage.setItem("selectedCurrency", record.agnaist_ksh)
        localStorage.setItem("selectedid", record.id)


        this.setState({
            showComponent: true,
            hideComponent: true,
            showEdit: false,
        });
    }

    _onButtonClick5() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showEdit: false,
        });
    }

    _onButtonClick3() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            _onShow: false,
            showModals: false
        });
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
                    <div>
                        < Card >
                            <CardBody >
                                <div className="panel-body" >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <h4><b>Currencies</b></h4><br />
                                        </div>
                                        <div className="col-md-4">
                                            {/* <Link to="addsalesperson">
                                                <Button className="pull-right btn-sm" color="primary
                                                    " onClick={this._onButtonClick} outline>Add Sales Person
                                                </Button>
                                            </Link> */}
                                        </div>
                                    </div>
                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}
                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}

                                    <br />


                                    <ReactDatatable
                                        // config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        id="tsc"
                                        loading={this.state.isLoading} />

                                </div>
                            </CardBody>

                        </Card>
                    </div>
                )
                }{
                    showComponent && (
                        <div>
                            <EditCurrency />
                        </div>
                    )
                }
            </div >
        )
    }
}