
import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, Table, CardBody, Col } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { BRANCH_ID, BUSINESS_TYPE, CONFIG, CURRENCY, PROFILE_URL, successToast, errorToast, ToastTable, TOKEN, USER_ID, COMPANY_NAME, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import { useEffect } from 'react';
import UseFetch from '../../_helpers/useFetch';
import DeleteHooks from '../../_helpers/deleteHooks';
import PostHooks from '../../_helpers/postHooks';

import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";
import PostHookPdf from '../../_helpers/postHooksPdf';


export const Employees = () => {
    const [data, setData] = React.useState([]);
    const [total, setTotal] = React.useState();
    const [queryString, setQueryString] = React.useState('');

    const [open, isOpen] = React.useState(false)
    const [redirect, setRirect] = React.useState(false);

    const [edit_payslip, setEditPaySlip] = React.useState(false);

    const [redirect_data, setRedirectData] = React.useState({});
    const [loading, fetch, callback] = UseFetch(`employee?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&branch_id=${BRANCH_ID}&${queryString}`); // custom hook
    const [check_del, setCheckDel] = React.useState(0);

    const [check_activate, setCheckActivate] = React.useState(0);

    const [check, setCheck] = React.useState(0);
    const navigate = useNavigate();

    const [employee_id, setEmployeeID] = React.useState('')
    const [isLoading, deleteCall] = DeleteHooks(`employee?id=${employee_id}`); // custom hook

    const [props, setProps] = React.useState({});

    const [load, res, postRequest] = PostHookPdf(props); // custom hook

    useEffect(() => {
        if (check === 0) {
            callback()
            setCheck(1)
        }
        if (fetch && fetch.data.length > 0) {
            let data_set = [];
            setTotal(fetch.total);
            for (let i = 0; i < fetch.data.length; i++) {
                let m_date = { m_date: moment(fetch.data[i].created_at).format('DD-MM-YYYY') };
                data_set.push(Object.assign(fetch.data[i], m_date));
            }
            setData(data_set);
            if (check_del === 1) {
                deleteCall()
                setCheckDel(0)
                callback()
            }

            if (res != null && res.success) {
                if (check_activate === 1) {
                    setCheckActivate(0)
                    callback()
                    isOpen(false)
                }
            }


        }
    }, [loading, check_del, load, res, check_activate, isLoading, queryString, check])


    const columns = [

        {
            key: "name",
            TrOnlyClassName: 'tsc',
            text: "Employee",
            className: "tsc",
            align: "left"
        },
        {
            key: "disability",
            TrOnlyClassName: 'tsc',
            text: "PWD",
            className: "tsc",
            altrueign: "left"
        },
        {
            key: "msisdn",
            TrOnlyClassName: 'tsc',
            text: "Phone No",
            className: "tsc",
            altrueign: "left"
        },
        {
            key: "email",
            TrOnlyClassName: 'tsc',
            text: "Email",
            className: "tsc",
            align: "left"
        },
        {
            key: "id_no",
            TrOnlyClassName: 'tsc',
            text: "ID No.",
            className: "tsc",
            altrueign: "left"
        },

        {

            key: "staff_number",
            TrOnlyClassName: 'tsc',
            text: "Staff No.",
            className: "tsc",
            altrueign: "left"
        },
        {

            key: "department_name",
            TrOnlyClassName: 'tsc',
            text: "Department",
            className: "tsc",
            altrueign: "left"
        },
        {

            key: "kra_pin",
            TrOnlyClassName: 'tsc',
            text: "KRA PIN",
            className: "tsc",
            altrueign: "left"
        },
        {

            key: "nssf_no",
            TrOnlyClassName: 'cell',
            text: "NSSF No.",
            className: "cell",
            altrueign: "left"
        },
        {

            key: "nhif_no",
            TrOnlyClassName: 'cell',
            text: "NHIF No.",
            className: "cell",
            altrueign: "left"
        },
        {

            key: "payment_mode",
            TrOnlyClassName: 'cell',
            text: "Payment Mode",
            className: "cell",
            altrueign: "left"
        },

        {

            key: "bank_name",
            TrOnlyClassName: 'cell',
            text: "Bank Name",
            className: "cell",
            altrueign: "left"
        },

        {

            key: "account_number",
            TrOnlyClassName: 'cell',
            text: "Account No.",
            className: "cell",
            altrueign: "left"
        },
        {
            key: "status",
            TrOnlyClassName: 'cell',
            text: "Status",
            className: "cell",
            align: "left",
            cell: record => {
                return (
                    <Fragment className="center"  >
                        {record.status == 0 ?
                            <div>
                                <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Awaiting Approval
                                </span>
                            </div>
                            : null}
                        {record.status == 1 ?
                            <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                Approved
                            </span>
                            : null}
                    </Fragment >
                );
            }
        },
        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    // eslint-disable-next-line
                    <Fragment className="center" >

                        <button className="btn btn-primary  btn-sm"
                            title="Eith details"
                            style={
                                { marginRight: '10px' }}
                            onClick={
                                () => {
                                    isOpen(true)
                                    setRedirectData(record)
                                }} >
                            More <i className='fa fa-plus'></i>
                        </button>


                        {/* <button className="btn btn-success  btn-sm"
                            title="View success"
                            style={
                                { marginRight: '10px' }}
                        >
                            View
                        </button> */}



                    </Fragment>
                );
            }
        }
    ]

    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            excel: true,
            csv: true
        },
        filename: BUSINESS_TYPE === 'business' ? "employee" : "employee",
        page_size: 100,
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here",
            no_data_text: "Oooops no employee found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }

    }


    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' +
                    encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');
        setQueryString(queryString);
        setCheck(0)
    }

    const closeModal = () => {
        isOpen(false)
    }

    return (
        <div style={{ marginTop: "-20px" }} >

            {ToastTable()}

            {edit_payslip ?
                <>
                    {navigate('/staff_edit', {
                        state: {
                            gross: 0,
                            other_deductions: 0,
                            other_benefits: 0,
                            nssf: 0,
                            name: "",
                            disability: redirect_data.disability,
                            period: "",
                            employ_id: redirect_data.id
                        }, replace: true
                    })}
                </> : null}


            {redirect ?
                <>
                    {navigate('/employee_edit', { state: redirect_data, replace: true })}
                    {/* <Navigate
                        to={'/employee_edit'}
                        state={redirect_data}
                    /> */}
                </> : null}
            < Card >
                <CardBody >

                    <Modal
                        isOpen={open}
                        onRequestClose={closeModal}
                        contentLabel="My dialog"
                        className="mymodal"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        <MDBCloseIcon onClick={closeModal} />

                        <h4><b>{redirect_data.name}</b></h4><br />

                        <div>

                            {redirect_data.status == 0 ?
                                <button className="btn btn-success  btn-sm"
                                    title="approve"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => {
                                            if (window.confirm(`Are you sure you want to approve this employee`)) {
                                                setEmployeeID(redirect_data.id)
                                                // alert(JSON.stringif(redirect_data))
                                                const formData = {
                                                    id: redirect_data.id,
                                                    company_name: "ekekek",
                                                    email: redirect_data.email,
                                                    name: redirect_data.name,
                                                    image: localStorage.getItem("image_urls")
                                                }

                                                console.log("fkkkfkf ", JSON.stringify(formData))   
                                                postRequest(formData)

                                                setTimeout(() => {
                                                    setCheckActivate(1)
                                                }, 4000)



                                                // const customerProps = { // post body and the url
                                                //     url: 'approve_customer',
                                                //     body: formData
                                                // }
                                                // setProps(customerProps)
                                            }
                                        }
                                    }>
                                    {load ? "Please wait..." : "Approve"}
                                </button>
                                :
                                <button className="btn btn-danger  btn-sm"
                                    title="in activate"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => {
                                            if (window.confirm(`Are you sure you want to deactivate this employee`)) {
                                                setEmployeeID(redirect_data.id)


                                                setTimeout(() => {
                                                    setCheckActivate(1)
                                                }, 2000)
                                                // alert(JSON.stringif(redirect_data))
                                                const formData = {
                                                    url: "deactivate_customer",
                                                    body: {
                                                        id: redirect_data.id,
                                                    }
                                                }
                                                postRequest(formData)
                                            }
                                        }
                                    }>
                                    {load ? "Please wait..." : "Deactivate"}
                                </button>
                            }
                            <br /> <br />


                            <button className="btn btn-primary  btn-sm"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => {
                                        isOpen(false)
                                        setRirect(true)
                                        setRedirectData(redirect_data)
                                    }} >
                                Edit Employee Info
                            </button>
                            {/* <br /> <br />

                            <button className="btn btn-primary  btn-sm"
                                title="edit payslip"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => {
                                        isOpen(false)
                                        setEditPaySlip(true)
                                        setEmployeeID(redirect_data.id)
                                        setRedirectData(redirect_data)
                                        // if (window.confirm(`Are you sure you want to delete this employee`)) {
                                        // setEmployeeID(redirect_data.id)
                                        // setCheckDel(1)
                                        // }
                                    }
                                }>
                                Edit PaySlip
                            </button> */}
                            <br /> <br />

                            <button className="btn btn-danger  btn-sm"
                                title="Delete customer"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => {
                                        if (window.confirm(`Are you sure you want to delete this employee`)) {
                                            setEmployeeID(redirect_data.id)
                                            isOpen(false)
                                            setCheckDel(1)
                                        }
                                    }
                                }>
                                Delete
                            </button>
                        </div>


                    </Modal>

                    <div className="panel-body" >
                        <div className="row">
                            <div className="col-md-6">
                                <h4><b>Employees</b></h4><br />
                            </div>
                            <div className="col-md-6 float-right">
                                <Link to="/employee_create">
                                    <Button className="pull-right btn-sm" color="primary" outline>Create Employee
                                    </Button>
                                </Link>

                            </div>

                        </div>

                        <div className="col-12 float-right">
                            <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}

                                onClick={e => {
                                    setCheck(0)
                                    setQueryString(require("randomstring").generate({
                                        length: 1,
                                        charset: 'alphanumeric',
                                        capitalization: 'lowercase'
                                    }))
                                }}>
                                Refresh <RefreshIcon />

                            </button>

                        </div>
                        <br />
                        <br />

                        <ReactDatatable
                            config={config}
                            records={data}
                            columns={columns}
                            dynamic={true}
                            id="tsc"
                            loading={loading}
                            total_record={total}
                            onChange={tableChangeHandler} />
                    </div>
                </CardBody>

            </Card>

        </div >
    )

}