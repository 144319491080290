import React from 'react'

import { FaWhatsapp } from 'react-icons/fa'
import EmailIcon from '@material-ui/icons/Email'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import LanguageIcon from '@material-ui/icons/Language';


export default {
    socials: {
        whatsapp: {
            link: 'https://api.whatsapp.com/send?phone=254736105920',
            text: 'Email',
            icon: < FaWhatsapp / > ,
        },
        email: {
            link: 'mailto:peakbooks@peakbooks.biz',
            text: 'Email',
            icon: < EmailIcon / > ,
        },
        facebook: {
            link: 'https://www.facebook.com/PeakBooksKE',
            text: 'Friend us on Facebook',
            icon: < FacebookIcon / > ,
        },
        twitter: {
            link: 'https://twitter.com/PeakBooksKE',
            text: 'Follow us on Twitter',
            icon: < TwitterIcon / > ,
        },
        linkedin: {
            link: 'https://www.linkedin.com/company/peakinsights/?x=ke',
            text: 'Follow us on Linked In',
            icon: < LinkedInIcon / > ,
        },
        website: {
            link: 'https://www.peakbooks.biz/',
            text: 'Find us',
            icon: < LanguageIcon / > ,
        },
    },
}