import React from 'react';
import { baseURL, baseURL_2 } from '../../../_helpers';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { TOKEN } from '../../../_helpers/exports';
import logo from '../../../shared/img/logo/3.png';
import * as moment from 'moment';
import { currency } from '../Settings/datacurrency';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { Link } from 'react-router-dom';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeMeans = this.onChangeMeans.bind(this);
    this.state = {
      email_address: "",
      showPassword: false,
      setShowPassword: false,
      channel_type: "portal",
      error: "",
      errorMsg: "",
      role: "",
      type: [{
        name: 'super_admin'
      }, {
        name: 'infotrace_admin'
      }, {
        name: 'client_admin'
      }, {
        name: 'client_staff'
      }],

      id: "",
      user: [],
      statusMessage: "",
      isShowSuccess: false,
      submitted: false,
      alert_error_color: "",
      isLoggedIn: false,
      isChecked: false,
      errorShow: false,
      successShow: false,
      currencylabel: 'KES',
      agnaist_ksh: '1',
      selectedGender: '',
      branch_name: '',
      branch_description: '',
      // postal_address: '',
      role_id: '4',
      alert_color: '',
      isLoading: false,
      isShowError: false,
      entity_id: '',
      password: '',
      entity_count: '',
      countryList: [],
      countiesList: [],
      showError: false,
      statis: [],
      industry: [{
        name: 'Professional activities'
      }, {
        name: 'Wholesale & Retail'
      }, {
        name: 'Manufacturing'
      }, {
        name: 'Hospitality'
      }, {
        name: 'Financial services'
      }, {
        name: 'Education'
      }, {
        name: 'Not for profit'
      }, {
        name: 'Agri-Business'
      }, {
        name: 'Transportation'
      }, {
        name: 'Health services'
      }, {
        name: 'ICT'
      }, {
        name: 'Construction'
      }, {
        name: 'Creative & Arts'
      }, {
        name: 'Other'
      }
      ],
      basic: "1",
      type: 'business',
      company_name_lebel: "Enter Company Name",
      industry_label: "Select Industry",

    };
  }

  onChangeMeans(value) {
    //basic config
    if (value === "1") {
      this.setState({
        basic: "1",
        type: 'business',
        company_name_lebel: "Enter Company Name",
        industry_label: "Select Industry",
      })
    }
    else {
      this.setState({
        basic: "0",
        type: 'NGO',
        company_name_lebel: "Enter name of the NGO",
        industry_label: "Select NGO Industry",
      })
    }
    // alert(value)
  }


  componentDidMount() {

    // const { rtl } = this.props;
    // NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
    // setTimeout(() => showNotification(rtl.direction), 9000);
    // alert(moment(new Date(new Date().setDate(new Date().getDate() + 30))).format('YYYY-MM-DD HH:mm:ss'))
    axios.get(baseURL + `display_companies`).then(res => {
      this.setState({
        admins: res.data,
        isLoading: false,
      },
        function () {
          console.log("recrd", this.state.admins);
        });
    });


    var data = [];
    for (let i = 0; i < currency.length; i++) {
      let mlabel = { label: currency[i].name + " (" + currency[i].currency + ")" };
      let mvalue = { value: currency[i].agnaist_ksh };
      data.push(Object.assign(mlabel, mvalue, currency[i]));
      this.setState({
        data: data
      })
    }

  }


  handleChangeEmail = event => {
    this.setState({ email_address: event.target.value });
  };
  handleChangePassword = event => {
    this.setState({ password: event.target.value });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  // componentWillUnmount() {
  //   notification.destroy();
  // }


  showPasswordToggle = () => {
    // eslint-disable-next-line
    if (this.state.showPassword == true) {
      this.setState({
        showPassword: false,
      })
    } else {
      this.setState({
        showPassword: true,
      })
    }
  };

  Companies() {
    return (this.state.admins && this.state.admins.length > 0 &&
      this.state.admins.map((countyItem, i) =>
        ({ label: countyItem.corporate_name, value: countyItem.corporate_name })))

    // console.log("recrd", this.state.admins);
  }

  handleChangeComanies = (value: { value: { toString: () => any; }; }) => {
    this.setState({ corporate_name: value.value.toString() });
  };


  handleChangeCurrency = value => {
    if (value != null) {
      this.setState(
        {
          agnaist_ksh: value.value.toString(),
          currencylabel: value.currency.toString(),
        });

        // alert(value.value.toString())
    }
  };


  handleChangeCounty = (event) => {
    this.setState({ selectedRole: event.target.value });
  };


  onSubmit = e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    var months = moment(new Date(new Date().setDate(new Date().getDate() + 15))).format('YYYY-MM-DD HH:mm:ss');

    const mreference = require("randomstring").generate({
      length: 6,
      charset: 'numeric',
      capitalization: 'uppercase'
    })
    // if(this.state.company_name === "" || this.state.postal_address == ""){
    let formData =
    {
      "first_name": this.state.first_name,
      "last_name": this.state.last_name,
      "industry": this.state.industrys,
      "email": this.state.email,
      "name": this.state.first_name + " " + this.state.last_name,
      "msisdn": this.state.msisdn,
      "username": this.state.username,
      "first_time": "1",
      "our_client": "1",
      "admin_role": "1",
      "inventory": "1",
      "investment": "1",
      "sales": "1",
      "purchase": "1",
      "documents": "1",
      "reports": "1",
      "accountant": "1",
      "bank": "1",
      "paid": "0",
      "approval": "1",
      "subscription": months,
      "shortcode_id": this.state.shortcode_id,
      "currency": this.state.currencylabel,
      "currency_against_kenya": this.state.agnaist_ksh,
      "type": this.state.type,
      //____"postal_address": this.state.postal_address,
      "company_name": this.state.company_name,
      "password": mreference,
      "role_id": "2"
    }
    console.log("DATA", JSON.stringify(formData))
    this.setState({ isLoading: true });
    axios.post(baseURL + `user`, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': TOKEN
      },
    }).then((response) => {
      console.log('bayoo', response.data)
      if (response.data.status) {
        console.log("bayo", response)
        window.scrollTo(0, 0)

        this.setState({ statusMessage: "Created Successfully! Email has been sent", showError: false, isShowError: true, isLoading: false });
        window.setTimeout(() => {
          // this._onButtonClick();
          window.location.href = "/log_in";

          this.setState({ isShowError: false })
        }, 5000);
        window.scrollTo(0, 0)

        if (response.data.data && response.data.data.length > 0) {
          for (let i = 0; i < response.data.data.length; i++) {
            let formDataEmail = {
              "email": this.state.email,
              "name": this.state.first_name + " " + this.state.last_name,
              "account_id": response.data.data[i].id
            }
            axios
              .post(baseURL_2 + 'emailconfirmation.php', formDataEmail)
              .then(response => {
              }).catch(error => {
                window.scrollTo(0, 0);
              });
          }
        }
      } else {
        window.scrollTo(0, 0)

        this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
        window.setTimeout(() => {
          // this._onButtonClick();
          this.setState({ showError: false })
        }, 6000);
      }
    })
      .catch((error) => {
        window.scrollTo(0, 0)

        console.log('bayoo', error)
        this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
      })
  };

  industry() {
    return (this.state.industry && (this.state.industry.length > 0 || this.state.industry.length === 0) &&
      this.state.industry.map((countyItem, i) =>
        ({ label: countyItem.name, value: countyItem.name })))

  }
  onSelectChanges = value => {
    this.setState({ industrys: value.value.toString() });
  };



  render() {

    return (
      <div className="account account">
        <div className="account__wrapper">
          <div className="account__card" style={{ height: "100%" }} >
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
            <Form className="form login-form" onSubmit={this.onSubmit}>
              {this.state.showError ?
                <div style={{ color: 'red' }}>
                  {this.state.statusMessage}
                </div> : null} <br></br>
              {this.state.isShowError ? (
                <div
                  color="success"
                  style={{ fontSize: "13px", color: "green" }}>
                  {this.state.statusMessage}
                </div>
              ) : null}
              <div className="col-md-12">
                <br></br>
                <div className="form-group">
                  <div className="col-md-12">
                    <input id="input" type="text" className="form-control" name="first_name"
                      required placeholder="FirstName"
                      value={this.state.first_name}
                      onChange={this.handleChange} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <div className="col-md-12">
                    <input id="input" type="text" className="form-control" name="last_name"
                      required placeholder="LastName"
                      value={this.state.last_name}
                      onChange={this.handleChange} />
                  </div>
                </div>
              </div>

              {/* <div className="col-md-12">
                <div className="form-group">
                  <div className="col-md-12">
                    <input id="input" type="text" className="form-control" name="username"
                      required placeholder="Username (NO SPACES)"
                      value={this.state.username}
                      pattern="^\S+$"
                      onChange={this.handleChange} />
                  </div>
                </div>
              </div> */}

              <div className="col-md-12">
                <div className="form-group">
                  <div className="col-md-12">

                    <PhoneInput
                      flags={flags}
                      defaultCountry="KE"
                      placeholder="Enter phone number"
                      name="msisdn"
                      maxLength={15}
                      id="input"
                      value={this.state.msisdn}
                      onChange={value => this.setState({ msisdn: value })} />

                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <div className="col-md-12">
                    <input id="input" type="email" className="form-control" name="email"
                      required placeholder="Enter Email"
                      value={this.state.email}
                      onChange={this.handleChange} />
                  </div>
                </div>
              </div>
              <div className="col-md-12"
                style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>

                <div className="form-group">
                  <label className="form-label">Select type</label>
                  <RadioGroup onChange={this.onChangeMeans} value={this.state.basic} horizontal>
                    <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                      Business
                    </RadioButton>
                    <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                      NGO
                    </RadioButton>
                  </RadioGroup>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <div className="col-md-12">
                    <input id="input" type="text" className="form-control" name="company_name"
                      required placeholder={this.state.company_name_lebel} required
                      value={this.state.company_name}
                      onChange={this.handleChange} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <div className="col-md-12">
                    {/* <Select
                      options={this.industry()}
                      onChange={this.onSelectChanges}
                      placeholder={this.state.industry_label}
                      tabSelectsValue={false}
                      value={this.state.onSelectChanges}
                      className='drop'
                    /> */}
                    {/* <input id="input" type="text" className="form-control" name="industry"
                      required placeholder="Industry"
                      value={this.state.industry}
                      onChange={this.handleChange} /> */}
                  </div>

                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <div className="col-md-12">

                    <Select
                      isClearable
                      options={this.state.data}
                      placeholder="Search Your Currency"
                      noOptionsMessage={() => 'No currrency found'}
                      autosize={true}
                      required
                      onChange={this.handleChangeCurrency}
                      openOnFocus={true}
                      menuPortalTarget={document.body}
                      name="currency"
                    />
                    <p>N/B: <b style={{ color: "red" }}>Currency is one off. You won't be able to change it again</b></p>

                  </div>

                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group"><br />
                  <div className="col-md-12">
                    {
                      <Button className="account__btn" type='submit' outline color="primary"> {
                        this.state.isLoading ? "Please wait..." : "Submit"
                      }</Button>
                    }
                  </div>
                </div>
              </div>
              <div className="account__have-account" style={{ marginBottom: "10px" }}>
                <p>Already have an account? <NavLink to="/log_in">Login</NavLink></p> <br />
              </div>



            </Form>

            <div className="account__have-" >
              <p className="text-center">Our contact</p>
              <p className="text-center"><b style={{ color: "blue" }}>+254736105920</b></p>

              <p className="text-center"><b style={{ color: "blue" }}>peakbooks@peakbooks.biz</b></p>
            </div>


          </div>
        </div>
      </div >
    );
  }
}

export default Register;
