import React from 'react';
import { baseURL, currentUserSubject } from '../../../_helpers';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { Dot } from "react-animated-dots";
import logo from '../../../shared/img/logo/3.png'
import { Link, NavLink } from 'react-router-dom';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import Alert from '../../../shared/components/Alert';
import * as moment from 'moment';
import { errorToast, successToast, ToastTable } from '../../../_helpers/exports';
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";

class LogInMobile extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      email_address: "",
      password: "",
      showPassword: false,
      accounts: [],
      setShowPassword: false,
      isOpen: false,
      channel_type: "portal",
      error: "",
      errorMsg: "",
      role: "",
      account_id: 0,
      id: "",
      user: [],
      statusMessage: "",
      isShowSuccess: false,
      submitted: false,
      alert_error_color: "",
      isLoggedIn: false,
      isChecked: false,
      errorShow: false,
      successShow: false,
      statis: []
    };


    // window.localStorage.clear(); //try this to clear all local storage


  }

  handleChangeEmail = event => {
    this.setState({ email_address: event.target.value });
  };
  handleChangePassword = event => {
    this.setState({ password: event.target.value });
  };

  componentDidMount() {
    window.localStorage.clear(); //try this to clear all local storage

  }


  showPasswordToggle = () => {
    // eslint-disable-next-line
    if (this.state.showPassword == true) {
      this.setState({
        showPassword: false,
      })
    } else {
      this.setState({
        showPassword: true,
      })
    }
  };

  onSubmit = e => {
    // e.preventDefault();
    this.setState({ isLoading: true });
    // eslint-disable-next-line
    let formData = {
      "email": this.state.email_address,
      "account_id": e.id,
      "password": this.state.password,
    }
    console.log("DATA", formData)

    this.setState({ isLoading: true });
    axios.post(baseURL + `login`, formData
    ).then((response) => {
      console.log("token", response.data);
      // eslint-disable-next-line
      if (response.data.status) {
        localStorage.setItem("name", response.data.user.first_name + " " + response.data.user.last_name)
        localStorage.setItem("user_role", response.data.user.role_id)
        localStorage.setItem("phonemsisdn_msisdn", response.data.user.msisdn)

        localStorage.setItem("clientemail", response.data.user.email)


        localStorage.setItem("user_id_client", response.data.user.client_id)
        localStorage.setItem("user_id_user", response.data.user.user_id)
        localStorage.setItem("company_name_client", response.data.user.company_name)

        localStorage.setItem("address_client", response.data.user.postal_address)

        localStorage.setItem("our_client", response.data.user.our_client)

        localStorage.setItem("currency", response.data.user.currency)

        localStorage.setItem("payment_plan_user", response.data.user.payment_plan)
        localStorage.setItem("username", response.data.user.username)

        localStorage.setItem("invoice_status", response.data.user.invoice_format)


        localStorage.setItem("business_pin", response.data.user.business_pin)
        localStorage.setItem("profile_url", response.data.user.url)
        localStorage.setItem("financial_year", response.data.user.financial_year)
        localStorage.setItem("currency_against", response.data.user.agnaist_ksh)
        localStorage.setItem("admin_role", response.data.user.admin_role)
        localStorage.setItem("inventory", response.data.user.inventory)
        localStorage.setItem("bank", response.data.user.bank)
        localStorage.setItem("sales", response.data.user.sales)
        localStorage.setItem("purchase", response.data.user.purchase)
        localStorage.setItem("investment", response.data.user.investment)
        localStorage.setItem("accountant", response.data.user.accountant)
        localStorage.setItem("reports", response.data.user.reports)
        localStorage.setItem("documents", response.data.user.documents)
        localStorage.setItem("industry", response.data.user.industry)
        localStorage.setItem("first_time", response.data.user.first_time)

        localStorage.setItem("approvals_owner", response.data.user.approval)

        localStorage.setItem("type_business", response.data.user.type)


        localStorage.setItem("subscription_expiry", response.data.user.subscription)
        window.localStorage.setItem("num", "ABC000001")
        currentUserSubject.next(response.data);
        window.user = response.data;
        window.scrollTo(0, 0);



        if (response.data.user.paid == 0 && response.data.user.first_time == 0) {
          successToast("Login Success! Redirecting....")
          this.setState({
            isShowError: true,
            errorShow: false,
            submitted: true,
            isLoading: false,
            isLoggedIn: true
          });

          var daily = moment(new Date(new Date()));
          if (response.data.user.check_trial == 0) {
            // if (((((new Date(response.data.user.subscription).getTime()) / 1000) - 
            // ((new Date(daily).getTime()) / 1000)) / 86400) < 0) {
            //   window.setTimeout(function () {
            //     window.location.href = "/payments";
            //     this.setState({ isLoading: false });
            //   }, 100);
            //   alert("here")
            // } else {
            window.setTimeout(function () {
              window.location.href = "/extend_period";
              this.setState({ isLoading: false });
            }, 100);
            // }
          }
          else {
            window.setTimeout(function () {
              window.location.href = "/payments";
              this.setState({ isLoading: false });
            }, 100);
          }
          // alert("paid")
        } else {

          successToast("Login Success! Redirecting....")
          this.setState({
            isShowError: true,
            errorShow: false,
            submitted: true,
            isLoggedIn: true
          });
          if (response.data.user.login_status == 1) {
            localStorage.setItem("token", response.data.token)
            window.setTimeout(function () {
              window.location.href = "/dashboard";
              this.setState({ isLoading: false });
            }, 100);

          } else {

            localStorage.setItem("token", response.data.token)
            window.setTimeout(function () {
              window.location.href = "/dashboard";
              this.setState({ isLoading: false });
            }, 100);

            // localStorage.setItem("invalid_token", response.data.token)

            // window.setTimeout(function () {
            //   window.location.href = "/dashboard";
            //   this.setState({ isLoading: false });
            // }, 100);
          }
        }
      }
      else {

        errorToast(response.data.message)
        console.log("bayoo", response.data)
        this.setState({
          // alert_error_color: "alert alert-danger",
          errorShow: true,
          submitted: true,
          isOpen: false,
          isLoading: false
        }, function () {
          console.log('data', response.data.message)
        });
      }
    }).catch(error => {
      errorToast(error.response === undefined ? "Check your internet" : error.response.data.message)
      this.setState({
        errorShow: true,
        submitted: true,
        isOpen: false,
        isLoading: false
      });
      this.setState({ password: "", email_address: this.state.email_address });
    })
  };

  closeModal = () => {
    this.setState({
      isOpen: false
    })
  }



  onSubmitEmail = e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    // eslint-disable-next-line
    if (this.state.email_address == "") {
      errorToast("Email Address is Mandatory");
      this.setState({
        isLoading: false,
        isOpen: false
      });
    }
    else if (this.state.password == "") {
      errorToast("Password is Mandatory");
      this.setState({
        isLoading: false,
        isOpen: false
      });
    }
    else {
      let formData = {
        "email": this.state.email_address,
        "password": this.state.password,
      }
      console.log("DATA", formData)
      this.setState({ isLoading: true });
      axios.post(baseURL + `check_email_account`, formData
      ).then((response) => {
        // eslint-disable-next-line
        if (response.data.success) {
          if (response.data.data && response.data.data.length > 0) {
            this.setState({
              isOpen: true,
              isLoading: false,
              accounts: response.data.data
            })
          } else {
            errorToast("Email not found!")
            this.setState({
              isLoading: false,
              isOpen: false
            });
          }
        }
        else {
          errorToast(response.data.message)
          this.setState({
            isLoading: false,
            isOpen: false
          });
        }
      }).catch(error => {
        errorToast(error.response === undefined ? "Check your internet" : error.response.data.message)
        this.setState({
          isLoading: false
        });
        this.setState({ password: "", email_address: this.state.email_address });
      })
    }
  };
  render() {
    const { submitted } = this.state;
    const { showPassword } = this.state;

    const { errorShow } = this.state;

    return (
      <div className="account account">

        <Modal
          isOpen={this.state.isOpen}
          onRequestClose={e => {
            this.closeModal(e);
          }}
          contentLabel="My dialog"
          className="mymodal_outside"
          onAfterOpen={() => {
            document.body.style.overflow = 'hidden';
          }}
          onAfterClose={() => {
            document.body.removeAttribute('style');
          }}
          overlayClassName="myoverlay"
          closeTimeoutMS={500} >

          <MDBCloseIcon onClick={this.closeModal} />


          <h4><b>Select your company to proceed</b></h4><br />
          {this.state.accounts && this.state.accounts.length > 0 && this.state.accounts.map((e, i) =>
            <>
              <Button color="primary" className="btn-paypal btn-lg" onClick={eh => {
                // this.setState({ account_id: e.id })
                this.onSubmit(e)
              }
              }>
                {this.state.isLoading ? "Saving..." : e.company_name}
              </Button> <br />
              <br />
            </>
          )}


        </Modal>

        <div className="account__wrapper">

          {ToastTable()}
          <div className="account__card" >

            <Link to="/">
              <img src={logo} alt="" />
            </Link>

            {/* <h5><b style={{color: "red"}}>N/B: For users who had already registered use your email as your username</b></h5> */}

            <Form className="form login-form" onSubmit={this.onSubmitEmail}>
              <div className="col-10 offset-1" >

                <div className="form__form-group">
                  <br />
                  <span className="form__form-group-label">Email</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <AlternateEmailIcon />
                    </div>
                    <Form.Control
                      autoFocus
                      type="email"
                      name="email_address"
                      style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                      placeholder="Email Address"
                      className="input-without-border-radius"
                      value={this.state.email_address}
                      onChange={this.handleChangeEmail}
                    />

                  </div>
                </div>
                <div className="form__form-group"><br></br>
                  <span className="form__form-group-label">Password</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <KeyVariantIcon />
                    </div>
                    <Form.Control
                      value={this.state.password}
                      placeholder="Password"
                      name="password"
                      style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                      type={showPassword ? 'text' : 'password'}
                      className="input-without-border-radius"
                      onChange={this.handleChangePassword}
                    />
                    <button
                      type="button"
                      className={`sm form__form-group-button${showPassword ? 'active' : ''}`}
                      onClick={this.showPasswordToggle}
                    >
                      <EyeIcon />
                    </button>
                    <div className="account__forgot-username">
                      {/* <Link to="/forgot_username">Forgot Username?</Link>
                    &nbsp;&nbsp;&nbsp;&nbsp; */}
                      <Link to="/forgot_password">Forgot Password?</Link>
                    </div>

                    {/* <div className="account__forgot-password">
                    <br /><br />

                  </div> */}


                  </div>

                </div>

                <div className="form__form-group">
                  <br></br>
                  <br></br>
                  {
                    <Button className="account__btn" style={{ color: "" }} type='submit' outline color="primary"> {
                      this.state.isLoading ? "Please wait..." : "Sign In"
                    }</Button>
                  }
                  <NavLink className="btn btn-outline-primary account__btn" to="/register">Sign Up
                  </NavLink>
                </div>
                <div className="account__have-" >
                  <p className="text-center">Need Help? <a href="/">Contact us</a></p>
                  <p className="text-center"><b style={{ color: "blue" }}>+254736105920</b></p>

                  <p className="text-center"><b style={{ color: "blue" }}>peakbooks@peakbooks.biz</b></p>
                </div>

                <div className="account__have-account" style={{ marginBottom: "10px" }}>
                  {/* <p>Backs to homepage? <NavLink to="/">click here</NavLink></p> <br /> */}
                </div>

              </div>


            </Form>

          </div>
        </div>
      </div >
    );
  }
}

export default LogInMobile;
