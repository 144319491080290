import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import Home from '../../Account/Landing/index';
import LogIn from '../../Account/LogIn/index';
import Register from '../../Account/Register/index';
import ResetPassword from '../../Account/ResetPassword/index';
// import WrappedRoutes from './WrappedRoutes';
import ForgotPassword from '../../Account/LogIn/forgotPassword';
import NewPassword from '../../Account/LogIn/newPassword';
// import UserReset from '../../Users/UserResetPassword';
import OTP from '../../Account/LogIn/otp';
import Payments from '../../Account/Register/payment';
import UserResetPassword from '../../Users/UserResetPassword';
import ForgotUsername from '../../Account/LogIn/forgotUsername.jsx'
import ExtendPayments from '../../Account/Register/extendPayments';
import LogInMobile from '../../Account/LogIn/loginMobile';
import WrappedRoutes from './WrappedRoutes';
// import HomePage from '../../../home';


const Router = () => (
  <MainWrapper>
    <main>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/log_in" element={<LogIn />} />
        <Route path="/log_in_mobile" element={<LogInMobile />} />

        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/reset_password_save" element={<NewPassword />} />
        <Route path='/payments' element={<Payments />} />
        <Route path='/forgot_username' element={<ForgotUsername />} />
        <Route path='/extend_period' element={<ExtendPayments />} />
        <Route path="/password" element={<UserResetPassword />} />
        <Route path="/verify" element={<OTP />} />
        <Route path="/*" element={<WrappedRoutes />} />

        {/* <Route path='/home' element={<HomePage />} /> */}

      </Routes>


    </main>
  </MainWrapper>
);

export default Router;