
export const currentassets = [
    {
        label: 'Advance Tax',
        value: 'Advance Tax',
    },
    {
        label: 'Employee Advance',
        value: 'Employee Advance',
    },
    {
        label: 'Prepaid Expenses',
        value: 'Prepaid EXpenses',
    },
];


// export const mcash = 
// [
//     {
//         label: 'Petty Cash',
//         value: 'Petty Cash',
//     },
//     {
//         label: 'Undeposit Funds',
//         value: 'Undeposited Funds',
//     },

// ];




export const fixedasset = [
    {
        label: 'Land',
        value: 'Land',
    },
    {
        label: 'Furniture Fittings',
        value: 'Furniture Fittings',
    },
    {
        label: 'Equipments',
        value: 'Equipments',
    },
    {
        label: 'Computers & peripherals',
        value: 'Computers & peripherals',
    },
    {
        label: 'Motor Vehicles',
        value: 'Motor Vehicles',
    },
    {
        label: 'Revaluation Reserves',
        value: 'Revaluation Reserves',
    },

];


export const othercurrentlibiality = [

    {
        label: 'Employee Reimbursements',
        value: 'Employee Reimbursements',
    },
];

export const otherassetasset = [
    {
        label: 'Advance Tax',
        value: 'Advance Tax',
    },
    {
        label: 'Employee Advance',
        value: 'Employee Advance',
    },
    {
        label: "Other current assets",
        value: "Other current assets",
    }
];


export const equity = [

    {
        label: 'Drawings',
        value: 'Drawings',
    },
    {
        label: 'Share Capital',
        value: 'Opening balance offset',
    },
    {
        label: "Owner's Equity",
        value: "Owner's Equity",
    },
    {
        label: 'Revaluation Reserves',
        value: 'Furniture and Equipment',
    },
];
export const optionLength = [
    { value: 1, label: 'general' },
    {
        value: 2,
        label:
            'Evil is the moment when I lack the strength to be true to the Good that compels me.',
    },
    {
        value: 3,
        label:
            "It is now an easy matter to spell out the ethic of a truth: 'Do all that you can to persevere in that which exceeds your perseverance. Persevere in the interruption. Seize in your being that which has seized and broken you.",
    },
];

export const dogOptions = [
    { id: 1, label: 'Chihuahua' },
    { id: 2, label: 'Bulldog' },
    { id: 3, label: 'Dachshund' },
    { id: 4, label: 'Akita' },
];

// let bigOptions = [];
// for (let i = 0; i < 10000; i++) {
// 	bigOptions = bigOptions.concat(colourOptions);
// }

export const groupedOptions_two = [
    {
        label: 'Current Assets',
        options: currentassets,
    },
    {
        label: 'Cash',
        options: JSON.parse(localStorage.getItem("paidthrough")),
    },
    {
        label: 'Bank',
        options: JSON.parse(localStorage.getItem("bankaccounts")),
    },
    {
        label: 'Credit Card',
        options: JSON.parse(localStorage.getItem("creditaccounts")),
    },
    {
        label: 'Fixed Asset',
        options: fixedasset,
    },
    {
        label: 'Other current liability',
        options: othercurrentlibiality,
    },

    {
        label: 'Equity',
        options: equity,
    }

];
