import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from '.';
import { CONFIG, errorToast, successToast } from './exports';
import { useCallback } from 'react';
const PutHooks = (props) => {  // custom hook post request

    const [data, setData] = useState(null);
    const [isLoading, setLoad] = useState(false);

    const callAPI = useCallback(() => {
        setLoad(true)
        // alert("here")
        axios.put(`${baseURL + props.url}`, props.body, CONFIG).then(res => {
            setData(res.data);
            successToast(res.data.message);
            setLoad(false);
        }).catch(err => {
            setData(err);
            setLoad(false)
            errorToast(err.response.data.message);
        });
    }, [props])

    return [isLoading, data, callAPI];
}
export default PutHooks;