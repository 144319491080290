import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { CURRENCY, TOKEN, USER_ID, ROLE, USER_ID_ACC, formatCurrency } from '../../_helpers/exports';
import * as moment from 'moment';
// import CustomerCreate from './createItem';
import { Link } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import createItem from './createinvestment';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import Alert from '../../shared/components/Alert';
import createinvestment from './createinvestment';

// import CustomerEdit from './customer_edit';



export class InvestmentView extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick3 = this._onButtonClick3.bind(this);
        this._onButtonClick5 = this._onButtonClick5.bind(this);

        this.handleOnClick = this.handleOnClick.bind(this);

        this.columns = [
            {
                key: "investment_type",
                TrOnlyClassName: 'tsc',
                text: "Investment Type",
                className: "tsc",
                align: "left"
            },

            {
                key: "reference",
                TrOnlyClassName: 'tsc',
                text: "Reference",
                className: "tsc",
                align: "left"
            },

            {
                key: "amountn",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },

            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date Created",
                className: "tsc",
                align: "left"
            }
            // },
            // {
            //     key: "action",
            //     text: "Options",
            //     TrOnlyClassName: 'cell',
            //     className: "cell",
            //     width: 250,
            //     sortable: false,
            //     cell: record => {
            //         return (
            //             // eslint-disable-next-line
            //             <Fragment className="center" >
            //                 {/* <button className="btn btn-primary  btn-sm"
            //                     title="Edit details"
            //                     style={
            //                         { marginRight: '10px' }}
            //                     onClick={
            //                         () => { this.onEdit(record) }} >
            //                     Edit
            //                 </button>
            //                 <button className="btn btn-danger  btn-sm"
            //                     title="Delete details"
            //                     style={
            //                         { marginRight: '10px' }}
            //                     onClick={
            //                         () => { this.handleOnClick(record) }} >
            //                     Delete
            //                 </button> */}
            //             </Fragment>
            //         );
            //     }
            // }
        ];

        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 30, 40, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            button: {
                excel: true,
                csv: true
            },
            filename: "investing_activities",
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search.. Item Name",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {

            isLoading: true,
            showError: false,
            isShowError: false,
            showComponent: false,
            _onShow: false,
            showEdit: false,
            hideComponent: false,
            data: [],
        };
    }

    async handleOnClick(record) {
        if (await confirm("Are your sure?")) {
            this.onDelete(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }



    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {
        this.getData();
    }



    getData = (queryString = "") => {

        // alert(USER_ID)
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `investment?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + "&&" + queryString
        // alert(JSON.stringify(queryString))
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((itemResponse) => {
            this.setState({
                item: itemResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];

                    this.setState({
                        total: itemResponse.data.total
                    })

                    for (let i = 0; i < this.state.item.length; i++) {
                        let date = { dates: (moment.utc(this.state.item[i].created_at).format('DD MMM, YYYY')) };

                        let amountn = { amountn: formatCurrency(this.state.item[i].amount) };
                        data.push(Object.assign(date, amountn, this.state.item[i]));
                        this.setState({
                            data: data
                        })

                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showEdit: false,
        });
    }

    _onButtonClick5() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showEdit: false,
        });
    }

    _onButtonClick3() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            _onShow: false,
            showModals: false
        });
    }


    onEdit(record) {
        localStorage.setItem("item_name", record.item_name)
        localStorage.setItem("item_id", record.id)

        this.setState({
            showComponent: false,
            hideComponent: true,
            _onShow: false,
            showEdit: true,
        }, function () {
        });

    }
    onDelete(record) {

        axios.delete(baseURL + `item/` + record.id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 2000);
        });
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        const { showEdit } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
                    <div>
                        < Card >
                            <CardBody >
                                <div className="panel-body" >
                                    <div className="row">

                                        <div className="col-md-6">
                                            <h4><b>List of Investments</b></h4><br />
                                        </div>
                                        <div className="col-md-6">
                                            <Link to="/create-investment">
                                                <Button className="pull-" color="primary
                                                    " onClick={this._onButtonClick} outline>Add Investment
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}
                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4>Items as at Now: <b>{this.state.total}</b></h4>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="col-md-10 offset-1">
                                                <button className="pull-right" title="refresh" style={{ background: "white", border: "0px" }} onClick={this.getData}>
                                                    <RefreshIcon />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <br />


                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} />

                                </div>
                            </CardBody>

                        </Card>
                    </div>
                )
                }
                {
                    showComponent && (
                        <div>
                            <createinvestment />
                        </div>
                    )
                }
                {/*

                {
                    showEdit && (
                        <div>
                            <ItemEdit />
                        </div>

                    )
                } */}
            </div >
        )
    }
}