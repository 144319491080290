
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Button } from 'reactstrap'
import { COMPANY_NAME, CURRENCY, formatCurrency, LOADER, NAME, ROLE, USER_ID_ACC } from '../../_helpers/exports'
import html2PDF from 'jspdf-html2canvas'
import * as moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import { TOKEN, USER_ID } from '../../_helpers/exports';
import axios from 'axios';
import { baseURL, baseURL_2 } from '../../_helpers';



export class NetCashFlow extends React.Component {
  constructor(props) {
    super(props)
    this.onclickss = this.onclickss.bind(this);
    this.applyCallback = this.applyCallback.bind(this);

    let now = new Date();

    let start = moment(new Date(new Date().getFullYear(), 0, 1));
    let end = moment(new Date());
    this.state = {
      start: start,
      end: end,
      download: false,
      isPageLoad: true,
    }
  }
  onclickss = () => {
    // alert("Done")
    this.setState({
      download: true,
    })
    var self = this;

    const page = document.getElementById('page')
    html2PDF(page, {
      html2canvas: {
        scrollX: 0,
        scale: 10,
        imageTimeout: 15000,
        logging: true,
        scrollY: -window.scrollY,
      },
      filename: 'profitloss.pdf',
      watermark({ pdf, pageNumber, totalPageNumber }) {
        pdf.setTextColor('#00000');
        pdf.text(50, pdf.internal.pageSize.height - 30, "PeakInsight");
      },
      margin: {
        top: 15,
        right: 0,
        bottom: 0,
        left: 0,
      },
      jsPDF: {
        unit: 'px',
        format: 'a4'
      },
      imageType: 'image/jpeg',
      // watermark: 'PeakInsight',
      output: 'netcashflow.pdf',
      init: function () {

      },
      success: function (pdf) {
        pdf.save(this.output);

        self.setState({
          download: false
        })
        // alert("bayobayo")

      }
    })

  }
  componentDidMount() {
    this.setState({
      startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('DD MMM, YYYY'),
      isPageLoad: true,
      valuedate: "This year"
    })

    this.getData(moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
  }

  getData(startDate, endDate) {
    this.setState({
      isLoading: true,
    })
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": TOKEN
      }
    };
    let url = baseURL + `invoiceamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url2 = baseURL + `custom_balance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url3 = baseURL + `getbillamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url4 = baseURL + `expensesamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url5 = baseURL + `operationexpense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url6 = baseURL + `vendorbalance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url7 = baseURL + `getamountreceived?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url8 = baseURL + `advancetax?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url9 = baseURL + `taxpayable?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url10 = baseURL + `employeeadvance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url11 = baseURL + `rembursement?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url12 = baseURL + `prepaid?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url13 = baseURL + `payablepaid?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url14 = baseURL + `furtinure?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url15 = baseURL + `investmentreport?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url16 = baseURL + `vendorcreditreport?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`
    let url17 = baseURL + `paymentamountmade?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`



    axios.all([
      axios.get(url, axiosConfig),
      axios.get(url2, axiosConfig),
      axios.get(url3, axiosConfig),
      axios.get(url4, axiosConfig),
      axios.get(url5, axiosConfig),
      axios.get(url6, axiosConfig),
      axios.get(url7, axiosConfig),
      axios.get(url8, axiosConfig),
      axios.get(url9, axiosConfig),
      axios.get(url10, axiosConfig),
      axios.get(url11, axiosConfig),
      axios.get(url12, axiosConfig),
      axios.get(url13, axiosConfig),
      axios.get(url14, axiosConfig),
      axios.get(url15, axiosConfig),
      axios.get(url16, axiosConfig),
      axios.get(url17, axiosConfig)


    ]).then(axios.spread((invoiceResponse, balanceResponse, billResponse, goodsResponse, expenseResponse, vendorBalance,
      amountResponse, advanceResponse, payableResponse,
      employeeResponse, rembursementResponse,
      prepaidResponse, prepaidpaidResponse,
      furnitureResponse, investmentResponse, creditVendorRespone, pettydepositResponse

    ) => {
      console.log("balance", employeeResponse)
      this.setState({
        invoice: invoiceResponse.data.data,
        balance: balanceResponse.data.data,
        bills: billResponse.data.data,
        goods: goodsResponse.data.data,
        expense: expenseResponse.data.data,
        vendor: vendorBalance.data.data,
        amount: amountResponse.data.data,
        advance: advanceResponse.data.data,
        payable: payableResponse.data.data,
        employeee: employeeResponse.data.data,
        rembursement: rembursementResponse.data.data,
        prepaid: prepaidResponse.data.data,
        payablepaid: prepaidpaidResponse.data.data,
        furniture: furnitureResponse.data.data,
        investment: investmentResponse.data.data,
        vendorcredit: creditVendorRespone.data.data,
        pettycreditpayment: pettydepositResponse.data.data,
        isLoading: false
      }, function () {
        var data = [];
        // let editedInvoice = [];





        if (this.state.invoice.length === 0 && this.state.balance.length === 0) {
          this.setState({
            operating_cost: "0.00",
            isPageLoad: false
          })
        }
        else {
          let valueAdded = 0
          let count = 0
          let valueAdded1 = 0
          let count1 = 0
          let valueOutofBalance = 0
          let countOutofBalance = 0

          for (let i = 0; i < this.state.invoice.length; i++) {
            let str = this.state.invoice[i].amount;
            count = parseFloat(str.replaceAll(",", ""))
            valueAdded += count
          }
          for (let j = 0; j < this.state.balance.length; j++) {
            let b;
            if (this.state.balance[j].out_of_balance === null) {
              b = 0;
            } else {
              let str1 = this.state.balance[j].out_of_balance.toString();
              countOutofBalance = parseFloat(str1.replaceAll(",", ""))
              valueOutofBalance += countOutofBalance
            }
          }
          this.setState({
            operating_cost: (countOutofBalance + valueAdded).toFixed(2),
            isPageLoad: false
          })
        }



        if (this.state.bills.length === 0 && this.state.goods.length === 0 && this.state.vendorcredit.length === 0) {

          this.setState({
            cost_of_goods: "0.00",
            isPageLoad: false
          })
        }

        else {
          let valueAddedGoods = 0
          let countGoods = 0
          let valueBillsBalance = 0
          let countBillsBalance = 0

          let valueVendorCredit = 0
          let countVendorCredit = 0

          for (let i = 0; i < this.state.bills.length; i++) {
            let str = this.state.bills[i].amount.toString();
            countBillsBalance = parseFloat(str.replaceAll(",", ""))
            valueBillsBalance += countBillsBalance
          }

          for (let i = 0; i < this.state.vendorcredit.length; i++) {
            let str = this.state.vendorcredit[i].due_amount;
            countVendorCredit = parseFloat(str.replaceAll(",", ""))
            valueVendorCredit += countVendorCredit
          }
          for (let j = 0; j < this.state.goods.length; j++) {
            let str1
            if (this.state.goods[j].amount === null) {
              valueAddedGoods = 0;
            } else {
              let str1 = this.state.goods[j].amount.toString();
              countGoods = parseFloat(str1.replaceAll(",", ""))
              valueAddedGoods += countGoods
            }
          }
          console.log('bayoo', (valueAddedGoods + valueBillsBalance).toFixed(2))

          this.setState({
            cost_of_goods: ((valueBillsBalance + valueAddedGoods) - (valueVendorCredit)).toFixed(2),
            isPageLoad: false
          })
        }
        if (this.state.expense.length === 0) {

          this.setState({
            cost_of_operation: "0.00",
            isPageLoad: false
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.expense.length; j++) {
            let a;
            if (this.state.expense[j].amount == null) {
              a = 0;
            } else {
              let str2 = this.state.expense[j].amount.toString()

              // alert(this.state.expense[].amount)
              countExpense = parseFloat(str2.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
          }
          this.setState({
            cost_of_operation: valueAddedExpenses,
            isPageLoad: false
          })
        }




        if (this.state.investment.length === 0) {

          this.setState({
            cost_of_non_operation: "0.00",
            isPageLoad: false
          })
        }
        else {
          let valueAddedInvestment = 0
          let countInvestment = 0
          for (let j = 0; j < this.state.investment.length; j++) {
            let a;
            if (this.state.investment[j].amount == null) {
              a = 0;
            } else {
              let str2 = this.state.investment[j].amount.toString()

              // alert(this.state.expense[].amount)
              countInvestment = parseFloat(str2.replaceAll(",", ""))
              valueAddedInvestment += countInvestment
            }
          }
          this.setState({
            cost_of_non_operation: valueAddedInvestment,
            isPageLoad: false
          })
        }
        if (this.state.furniture.length === 0) {
          this.setState({
            furniture_cost: 0,
            isPageLoad: false
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.furniture.length; j++) {
            let a;
            if (this.state.furniture[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.furniture[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            furniture_cost: valueAddedExpenses,
            isPageLoad: false
          })
        }

        if (this.state.vendor.length === 0 || this.state.bills.length === 0 || this.state.vendorcredit.length === 0 || this.state.pettycreditpayment.length === 0) {
          this.setState({
            vendor_balance: 0,
          })
        }
        else {
          let valueAddedVendor = 0
          let countVendor = 0
          let valueBillsBalance = 0
          let countBillsBalance = 0
          let valueVendorCredit = 0
          let countVendorCredit = 0

          let valueAddedExpenses = 0
          let countExpense = 0



          for (let i = 0; i < this.state.bills.length; i++) {
            let str = this.state.bills[i].amount;
            countBillsBalance = parseFloat(str.replaceAll(",", ""))
            valueBillsBalance += countBillsBalance


          }


          for (let i = 0; i < this.state.vendorcredit.length; i++) {
            let str = this.state.vendorcredit[i].due_amount;
            countVendorCredit = parseFloat(str.replaceAll(",", ""))
            valueVendorCredit += countVendorCredit


          }
          for (let j = 0; j < this.state.vendor.length; j++) {
            let a;
            if (this.state.vendor[j].opening_balance === null) {
              a = 0;
            } else {
              let str1 = this.state.vendor[j].opening_balance.toString();
              countVendor = parseFloat(str1.replaceAll(",", ""))
              valueAddedVendor += countVendor

            }
          }


          for (let j = 0; j < this.state.pettycreditpayment.length; j++) {
            let a;
            if (this.state.pettycreditpayment[j].amount === null) {
              a = 0;
            } else {

              let str1 = this.state.pettycreditpayment[j].amount;
              console.log("bayo2", str1)
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
          }
          this.setState({
            vendor_balance: ((valueAddedExpenses + valueVendorCredit) - (valueBillsBalance + valueAddedVendor)),
          })


        }


        if (this.state.amount.length === 0) {
          this.setState({
            amount_received: 0.00,
            isPageLoad: false
          })
        }
        else {
          let valueAddedAmount = 0
          let countAmount = 0
          for (let j = 0; j < this.state.amount.length; j++) {
            let a;
            if (this.state.amount[j].amount_received === null) {
              a = 0;
            } else {
              let str1 = this.state.amount[j].amount_received;
              valueAddedAmount += countAmount
            }
          }
          this.setState({
            amount_received: valueAddedAmount,
            isPageLoad: false
          })
        }





        if (this.state.advance.length === 0) {
          this.setState({
            advance_tax: 0,
            isPageLoad: false
          })
        }
        else {
          let valueAddedAdvance = 0
          let countAdvance = 0
          for (let j = 0; j < this.state.advance.length; j++) {
            let a;
            if (this.state.advance[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.advance[j].amount.toString();
              countAdvance = parseFloat(str1.replaceAll(",", ""))
              valueAddedAdvance += countAdvance
            }
          }
          this.setState({
            advance_tax: valueAddedAdvance,
            isPageLoad: false
          })
        }



        if (this.state.payable.length === 0) {
          this.setState({
            payable_tax: 0,
            isPageLoad: false
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.payable.length; j++) {
            let a;
            if (this.state.payable[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.payable[j].amount.toString();
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
          }
          this.setState({
            payable_tax: valueAddedExpenses,
            isPageLoad: false
          })
        }


        if (this.state.employeee.length === 0) {
          this.setState({
            employee_cost: 0,
            isPageLoad: false
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.employeee.length; j++) {
            let a;
            if (this.state.employeee[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.employeee[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)


          }
          this.setState({
            employee_cost: valueAddedExpenses,
            isPageLoad: false
          })
        }



        if (this.state.rembursement.length === 0) {
          this.setState({
            rembursement_cost: 0,
            isPageLoad: false
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.rembursement.length; j++) {
            let a;
            if (this.state.rembursement[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.rembursement[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)


          }
          this.setState({
            rembursement_cost: valueAddedExpenses,
            isPageLoad: false
          })
        }


        if (this.state.prepaid.length === 0) {
          this.setState({
            prepaid_cost: 0,
            isPageLoad: false
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.prepaid.length; j++) {
            let a;
            if (this.state.prepaid[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.prepaid[j].amount.toString();
              console.log("bayo2", str1)

              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }

            console.log("bayo2", valueAddedExpenses)


          }
          this.setState({
            prepaid_cost: valueAddedExpenses,
            isPageLoad: false
          })
        }


        if (this.state.payablepaid.length === 0) {
          this.setState({
            payablepaid_cost: 0,
            isPageLoad: false
          })
        }
        else {
          let valueAddedExpenses = 0
          let countExpense = 0
          for (let j = 0; j < this.state.payablepaid.length; j++) {
            let a;
            if (this.state.payablepaid[j].amount === null) {
              a = 0;
            } else {
              let str1 = this.state.payablepaid[j].amount.toString();
              console.log("bayo2", str1)
              countExpense = parseFloat(str1.replaceAll(",", ""))
              valueAddedExpenses += countExpense
            }
            console.log("bayo2", valueAddedExpenses)
          }
          this.setState({
            payablepaid_cost: valueAddedExpenses,
            isPageLoad: false
          })
        }

        this.setState({
          gross: (this.state.operating_cost - this.state.cost_of_goods),
          isPageLoad: false
        })

      })
    })).catch(error => {
      // alert(JSON.stringify(error.response))
      console.log('bayoo', error.response)
    })
  }

  applyCallback(startDate, endDate) {
    // localStorage.setItem("STARTDATE", startDate)
    // localStorage.setItem("ENDDATE", endDate)
    let Sdate1 = moment(startDate).format('DD MMM, YYYY');
    let Edate2 = moment(endDate).format('DD MMM, YYYY');
    this.setState({
      valuedate: Sdate1 + " " + Edate2,
      startDate: Sdate1,
      isPageLoad: true,
      endDate: Edate2
    })

    this.getData(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
  }

  locale = 'en-US';
  currency = CURRENCY;

  formatCurrency = amount => {
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: "ABS",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount).replaceAll('ABS', "");
  };


  render() {

    let ranges = {
      "Today Only": [moment(this.state.start), moment(this.state.end)],
      "Yesterday Only": [
        moment(this.state.start).subtract(1, "days"),
        moment(this.state.end).subtract(1, "days")
      ],
      "3 Days": [moment(this.state.start).subtract(3, "days"), moment(this.state.end)],
      "5 Days": [moment(this.state.start).subtract(5, "days"), moment(this.state.end)],
      "1 Week": [moment(this.state.start).subtract(7, "days"), moment(this.state.end)],
      "2 Weeks": [moment(this.state.start).subtract(14, "days"), moment(this.state.end)],
      "1 Month": [moment(this.state.start).subtract(1, "months"), moment(this.state.end)],
      "1 Year": [moment(this.state.start).subtract(1, "years"), moment(this.state.end)]
    };
    let local = {
      "format": "DD-MM-YYYY",
      "sundayFirst": false
    }

    let netincome;

    return (
      <div style={{ marginTop: "-20px" }} >
        {this.state.isPageLoad ?
          <div className='d-flex justify-content-center align-items-center'>
            <div className="loader">
              {LOADER}
            </div>
          </div> :
          <Card>
            {this.state.download ?
              <div className='d-flex justify-content-center align-items-center'>
                <div className="loader">
                  {LOADER}
                </div>
              </div> :

              <CardBody>
                <div className='col-12'>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-6">
                            <DateTimeRangeContainer
                              ranges={ranges}
                              start={this.state.start}
                              end={this.state.end}
                              local={local}
                              applyCallback={this.applyCallback}>
                              <FormControl
                                id="formControlsTextB"
                                type="text"
                                value={this.state.valuedate}
                                label="Text"
                                placeholder="Filter by Date"
                              />
                            </DateTimeRangeContainer>
                          </div>
                          <div className="col-6 float-left">

                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {
                              this.getData(moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                              this.setState({
                                startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
                                endDate: moment(new Date()).format('DD MMM, YYYY'),
                                isPageLoad: true,
                                valuedate: "This Year"

                              })
                            }} >Clear</button>
                            {/* <input id="input" type="text" readOnly /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pull-right">
                      <button className="float-right btn btn-primary btn-sm" onClick={() => this.onclickss()}>Export PDF</button>
                    </div>
                  </div>

                  <div id='page'>
                    <br />

                    <div className='text-center' p='3'>
                      <h4>{COMPANY_NAME}</h4>
                      <h3><strong>Cash Flow Statement</strong></h3>
                      <h4>Date</h4>
                      <h4>From {this.state.startDate} To {this.state.endDate}</h4>

                      <br /><br /><br />
                    </div>

                    <div className='row col-8 offset-2'>

                      <div className='col-5'>
                        <hr style={{ marginTop: '0px' }} />
                        <h4>Account</h4>
                        <hr style={{ marginTop: '0px' }} />
                        <h4><span class="badge-info" style={{ borderRadius: "5px", padding: "5px" }}> 1 </span><b>Beginning Cash Balance	</b></h4>
                        <hr style={{ marginTop: '0px' }} />

                        <h4>A. Cash Flow from Operating Activities</h4>

                        <h4><Link to="/profitloss"> Net Income</Link></h4>
                        <h4 style={{ color: "#9BDDFF" }}><div> Accounts Payable</div></h4>
                        <h4 style={{ color: "#9BDDFF" }}><div> Accounts Receivable</div></h4>
                        <h4 style={{ color: "#9BDDFF" }}> <div> Advance Tax</div></h4>

                        <h4 style={{ color: "#9BDDFF" }}> <div> Employee Advance</div></h4>


                        <h4 style={{ color: "#9BDDFF" }}> <div> Employee Reimbursements</div></h4>

                        <h4 style={{ color: "#9BDDFF" }}> <div> Prepaid Expenses</div></h4>
                        <h4 style={{ color: "#9BDDFF" }}>  <div> Tax Payable</div></h4>

                        <hr style={{ marginTop: '0px', marginBottom: '5px' }} />
                        <br />
                        <h4 style={{ marginBottom: '7px' }}> <b>Net cash provided by Operating Activities	</b></h4>
                        <br />
                        <h4 >B. Cash Flow from Investing Activities	</h4>
                        <hr style={{ marginTop: '0px', marginBottom: '5px' }} />
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}>  <div>Furniture and Equipment</div></h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> Insurance</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> Bank Interests</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '10px' }}> Other Investments</h4>


                        <br />

                        <h4 style={{ marginBottom: '7px' }}> <b>Net cash provided by Investing Activities	</b></h4>
                        <br />
                        <h4 >C. Cash Flow from Financing Activities		</h4>
                        <hr style={{ marginTop: '0px', marginBottom: '5px' }} />
                        <br />
                        <h4 style={{ marginBottom: '7px' }}> <b>Net cash provided by Financing Activities	</b></h4>
                        <br />
                        <hr style={{ marginTop: '0px' }} />
                        <h4><span class="badge-info" style={{ borderRadius: "5px", padding: "5px" }}> 2 </span>Net Change in cash (A) + (B) + (C)	</h4>
                        <hr style={{ marginTop: '0px' }} />
                        <br />
                        <hr style={{ marginTop: '0px' }} />
                        <br />
                        <h4>Ending Cash Balance <span class="badge-info" style={{ borderRadius: "5px", padding: "5px" }}> 1 </span> + <span class="badge-info" style={{ borderRadius: "5px", padding: "5px" }}> 2 </span>	</h4>
                        <hr style={{ marginTop: '0px' }} />

                      </div>
                      <div className='col-7 text-right'>
                        <hr style={{ marginTop: '0px' }} />
                        <h4><strong>Total</strong></h4>
                        <hr style={{ marginTop: '0px' }} />
                        <h4><b>0.00</b></h4>
                        <hr style={{ marginTop: '0px' }} />
                        <h4 style={{ visibility: 'hidden' }}>space</h4>
                        <h4><Link to="/profitloss"> {
                          ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods))) - parseFloat(this.state.cost_of_operation)) < 0 ?
                            <div>
                              {formatCurrency((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                - parseFloat(this.state.cost_of_operation))}
                            </div>
                            :
                            <div>
                              {formatCurrency(

                                ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                  - parseFloat(this.state.cost_of_operation))
                                -
                                (0.3 * ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                  - parseFloat(this.state.cost_of_operation))))
                              }
                            </div>

                        }

                        </Link></h4>
                        <h4 style={{ color: "#9BDDFF" }}><div> {formatCurrency(this.state.vendor_balance)}</div></h4>
                        <h4 style={{ color: "#9BDDFF" }}><div>{formatCurrency((parseFloat(this.state.operating_cost) - parseFloat(this.state.amount_received)))}</div></h4>
                        <h4 style={{ color: "#9BDDFF" }}>
                          <div>{

                            formatCurrency((parseFloat(this.state.payable_tax) - parseFloat(this.state.advance_tax)))


                          }</div></h4>
                        <h4 style={{ color: "#9BDDFF" }}><div>{formatCurrency(this.state.employee_cost)}</div></h4>
                        <h4 style={{ color: "#9BDDFF" }}><div>{formatCurrency(this.state.rembursement_cost)}</div></h4>

                        <h4 style={{ color: "#9BDDFF" }}><div> {formatCurrency(this.state.prepaid_cost)}</div></h4>
                        <h4 style={{ color: "#9BDDFF" }}><div>{formatCurrency(this.state.payablepaid_cost)}</div></h4>

                        <hr style={{ marginTop: '0px' }} />
                        <h4><b>

                          {


                            ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods))) - parseFloat(this.state.cost_of_operation)) < 0 ?
                              <div>
                                {formatCurrency(
                                  ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                    - parseFloat(this.state.cost_of_operation))
                                  +
                                  parseFloat(this.state.vendor_balance)
                                  +
                                  (parseFloat(this.state.operating_cost) - parseFloat(this.state.amount_received))
                                  +
                                  (parseFloat(this.state.payable_tax) - parseFloat(this.state.advance_tax))
                                  +
                                  parseFloat(this.state.employee_cost)
                                  +
                                  parseFloat(this.state.rembursement_cost)
                                  +
                                  parseFloat(this.state.prepaid_cost)
                                  +
                                  parseFloat(this.state.payablepaid_cost)

                                )}
                              </div>
                              :
                              <div>
                                {formatCurrency(

                                  (((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                    - parseFloat(this.state.cost_of_operation))
                                    -
                                    (0.3 * ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                      - parseFloat(this.state.cost_of_operation))))

                                  +

                                  parseFloat(this.state.vendor_balance)
                                  +
                                  (parseFloat(this.state.operating_cost) - parseFloat(this.state.amount_received))
                                  +
                                  (parseFloat(this.state.payable_tax) - parseFloat(this.state.advance_tax))
                                  +
                                  parseFloat(this.state.employee_cost)
                                  +
                                  parseFloat(this.state.rembursement_cost)
                                  +
                                  parseFloat(this.state.prepaid_cost)
                                  +
                                  parseFloat(this.state.payablepaid_cost)
                                )

                                }
                              </div>
                          }

                        </b></h4>
                        <hr style={{ marginTop: '0px' }} />
                        <br />
                        <h4 style={{ visibility: 'hidden' }}>space</h4>
                        <hr style={{ marginTop: '0px' }} />
                        <h4 style={{ color: "#9BDDFF" }}><div> {formatCurrency(this.state.furniture_cost)}</div></h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}>{formatCurrency(this.state.investment[1].amount)}</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '5px' }}> {formatCurrency(this.state.investment[0].amount)}</h4>
                        <h4 style={{ color: "#9BDDFF", marginBottom: '10px' }}> {formatCurrency(this.state.investment[2].amount)}</h4>
                        <br />





                        <h4><b>

                          {formatCurrency(
                            parseFloat(this.state.furniture_cost) +
                            parseFloat(this.state.investment[1].amount)
                            +
                            parseFloat(this.state.investment[0].amount)
                            +
                            parseFloat(this.state.investment[2].amount)
                          )
                          }
                        </b></h4>
                        <hr style={{ marginTop: '0px' }} />
                        <h4 style={{ visibility: 'hidden' }}>space</h4>
                        <hr style={{ marginTop: '0px' }} />

                        <h4><b>0.00</b></h4>
                        <hr style={{ marginTop: '0px' }} />

                        <hr style={{ marginTop: '33px' }} />
                        <h4><b>0.00</b>	</h4>

                        <hr style={{ marginTop: '0px' }} />

                        <hr style={{ marginTop: '35px' }} />
                        <br />
                        <h4><b>

                          {
                            ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods))) - parseFloat(this.state.cost_of_operation)) < 0 ?
                              <div>
                                {formatCurrency(
                                  ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                    - parseFloat(this.state.cost_of_operation))
                                  +
                                  parseFloat(this.state.vendor_balance)
                                  +
                                  (parseFloat(this.state.operating_cost) - parseFloat(this.state.amount_received))
                                  +
                                  (parseFloat(this.state.payable_tax) - parseFloat(this.state.advance_tax))
                                  +
                                  parseFloat(this.state.employee_cost)
                                  +
                                  parseFloat(this.state.rembursement_cost)
                                  +
                                  parseFloat(this.state.prepaid_cost)
                                  +
                                  parseFloat(this.state.payablepaid_cost)
                                  +
                                  parseFloat(this.state.furniture_cost)
                                  +
                                  parseFloat(this.state.investment[1].amount)
                                  +
                                  parseFloat(this.state.investment[0].amount)
                                  +
                                  parseFloat(this.state.investment[2].amount)

                                )}
                              </div>
                              :
                              <div>
                                {formatCurrency(

                                  (((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                    - parseFloat(this.state.cost_of_operation))
                                    -
                                    (0.3 * ((parseFloat(this.state.operating_cost) - (parseFloat(this.state.cost_of_goods)))
                                      - parseFloat(this.state.cost_of_operation))))

                                  +

                                  parseFloat(this.state.vendor_balance)
                                  +
                                  (parseFloat(this.state.operating_cost) - parseFloat(this.state.amount_received))
                                  +
                                  (parseFloat(this.state.payable_tax) - parseFloat(this.state.advance_tax))
                                  +
                                  parseFloat(this.state.employee_cost)
                                  +
                                  parseFloat(this.state.rembursement_cost)
                                  +
                                  parseFloat(this.state.prepaid_cost)
                                  +
                                  parseFloat(this.state.payablepaid_cost)
                                  +
                                  parseFloat(this.state.furniture_cost) +
                                  parseFloat(this.state.investment[1].amount)
                                  +
                                  parseFloat(this.state.investment[0].amount)
                                  +
                                  parseFloat(this.state.investment[2].amount)
                                )

                                }
                              </div>
                          }

                        </b>	</h4>

                        <hr style={{ marginTop: '0px' }} />
                        {/* <hr style={{ marginTop: '0px' }} /> */}

                        {/* <h4><strong>Operating Profit</strong><i style={{ visibility: 'hidden' }}>______________</i>0.0</h4>

                    <br /> */}
                        {/* <h4 style={{ visibility: 'hidden' }}>space</h4> */}

                        {/* <hr style={{ marginTop: '20px' }} /> */}

                        {/* <h4 style={{ visibility: 'hidden' }}>space</h4> */}
                        <br />
                        {/* <h4>0.00</h4>
                    <hr style={{ marginTop: '0px' }} />
                    <h4><strong>Net Profit/Loss</strong><i style={{ visibility: 'hidden' }}>______________</i>0.0</h4> */}
                      </div>

                    </div>
                  </div>
                </div>


              </CardBody>

            }
          </Card>
        }


      </div >

    )
  }
}
