import React from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes, { func } from 'prop-types';
// import "../../../_assets/css/csv.css";
import axios from 'axios';

import { withTranslation } from 'react-i18next';
import renderDropZoneField from '../../../shared/components/form/DropZone';
import { TOKEN } from '../../../_helpers/exports';
import Select from 'react-select';
import { baseURL } from '../../../_helpers';

const handleChangeCSV = event => {
  console.log("FETCHER", event.target.files);
  this.setState({
    csvfile: event.target.files[0]
  });
};


class FileUploadDefault extends React.Component {
  constructor(props) {
    super(props)


    this.updateData = this.updateData.bind(this)

    this.state = {
      isLoading: false,
      showError: false,
      isShowError: false,

    };
  }

  componentDidMount() {
    axios.all([
      axios.get(baseURL + `customers/viewGrp.php`),
    ]).then(axios.spread((branchResponse) => {
      this.setState({
        admins: branchResponse.data,
        isLoading: false
      });
    }))
  }



  Companies() {

    return (this.state.admins && this.state.admins.length > 0 &&
      this.state.admins.map((countyItem, i) =>
        ({ label: countyItem.groupName, value: countyItem.id })))

  }
  handleChangeChange = value => {
    this.setState({ groupID: value.value.toString() });
  };


  importCSV = event => {
    event.preventDefault();
    this.setState({ loading: true });
    console.log("fileformat", this.state.csvfile)
    var data = new FormData();
    data.append("file", this.state.csvfile);
    this.setState({ isLoading: true });
    var Papa = require("papaparse/papaparse.min.js");
    Papa.parse(this.state.csvfile, {
      header: true,
      download: true,
      skipEmptyLines: true,
      // Here this is also available. So we can call our custom class method
      complete: this.updateData
    });

  }
  async updateData(result) {
    const datas = result.data;
    let data = [];
    let group_id;
    for (let i = 0; i < 100; i++) {
      group_id = { groupId: "bayo" };
      data.push(Object.assign(group_id, datas[i]));
      this.setState({
        data: data
      })
    }



    console.log("DATA", JSON.stringify(data))
    console.log("brian", data)


    alert(JSON.stringify(data))


    // axios.post(baseURL + `customers/contactBulk.php', JSON.stringify(data))
    //   .then((response) => {
    //     console.log("bayo", response)
    //     this.setState({
    //       statusMessage: "success!! sms are being sent",
    //       isShowError: true, isLoading: false, showError: false,
    //     });


    //   }).catch(error => {
    //     this.setState({ statusMessage: "Unable to send sms", showError: true, isShowError: false, isLoading: false });

    //     console.error('Error during service worker registration:', error);
    //   });
  }


  handleChangeCSV = event => {
    console.log("FETCHER", event.target.files);
    this.setState({
      csvfile: event.target.files[0]
    });
  };
  render() {
    return (
      <Col md={12} lg={12}>
        <Card className="card--not-full-height">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">File Upload</h5>
            </div>

            {this.state.showError ? <div style={{ color: 'red' }}>
              {this.state.statusMessage}

            </div> : null}<br></br>


            {this.state.isShowError ? (
              <div
                color="success"
                style={{ fontSize: "13px", color: "green" }}>
                {this.state.statusMessage}
              </div>

            ) : null}<br></br>
            <div className="wrapper text-center">
              <div className="section1">
                <div className="contain">

                  <br /><br />
                  <div className="form-group col-6 offset-3">
                    <label className="form-label">Group</label>
                    <Select
                      options={this.Companies()}
                      onChange={this.handleChangeChange}
                      placeholder="Select Group "
                      tabSelectsValue={false}
                      required
                      value={this.state.handleChangeChange}
                      id="country_name"

                      className='drop'
                    />
                  </div>
                  <br />
                  <div className="form-group col-6 offset-3">

                    <input
                      className="csv-input"
                      type="file"
                      required
                      ref={input => {
                        this.filesInput = input;
                      }}
                      name="file"
                      customHeight
                      placeholder={null}
                      onChange={this.handleChangeCSV}
                    />
                  </div>
                  <p />
                  <Button color="primary" outline className="btn-paypal btn-lg " onClick={this.importCSV} >
                    {this.state.isLoading ? "Uploading..." : "Import now!"}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;

                  <br /><b />
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }
}


FileUploadDefault.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'file_upload_default', // a unique identifier for this form
})(withTranslation('common')(FileUploadDefault));

