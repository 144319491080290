import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { COMPANY_NAME, CURRENCY, NAME, formatCurrency, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
// import { saveInvoiceView } from './pdfview';
import Payment_Received from './payment_received';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'


export class Receivables extends React.Component {
    constructor(props) {
        super(props)
        this.applyCallback = this.applyCallback.bind(this);


        this.columns = [
            {
                key: "customer_name",
                TrOnlyClassName: 'tsc',
                text: "COMPANY NAME",
                className: "cell",
                altrueign: "left"
            },
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "DATE",
                className: "tsc",
                align: "left"
            },


            {
                key: "transaction",
                TrOnlyClassName: 'tsc',
                text: "TRANSACTION#",
                className: "tsc",
                align: "left"
            },

            {
                key: "status",
                TrOnlyClassName: 'tsc',
                text: "STATUS",
                className: "tsc",
                align: "left"
            },

            {
                key: "transaction_type",
                TrOnlyClassName: 'tsc',
                text: "TRANSACTION TYPE",
                className: "tsc",
                align: "left"
            },

            {
                key: "invoice_balance",
                TrOnlyClassName: 'tsc',
                text: "TOTAL",
                className: "tsc",
                align: "left"
            },

            {
                key: "balance_amount",
                TrOnlyClassName: 'tsc',
                text: "BALANCE",
                className: "tsc",
                align: "left"
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [100, 150, 200, 500],
            show_filter: false,
            button: {
                excel: true,
                csv: true
            },
            show_pagination: true,
            pagination: 'advance',
            page_size: 100,
            filename: "receivables",

            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Invoice no...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showComponent: false,
            hideComponent: false,
            data: [],

        };

        let now = new Date();
        let start = moment(new Date(new Date().getFullYear(), 0, 1));
        let end = moment(new Date());
        this.state = {
            start: start,
            end: end,
            isPageLoad: true,
        }
    }
    locale = 'en-US';
    currency = CURRENCY;
    async handleOnClick1(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onEditInvoice(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {

        this.setState({
            startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
            endDate: moment(new Date()).format('DD MMM, YYYY'),
            isPageLoad: true,
            valuedate: "This year"
        })

        this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))

    }


    getData = (queryString = "", startDate, endDate) => {

        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `receivables?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&estimate=0&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"&${queryString}`

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((paymentResponse) => {
            this.setState({
                Payment_Received: paymentResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];

                    this.setState({
                        total: paymentResponse.data.total
                    })
                    let valueAddedExpenses = 0
                    let countExpense = 0

                    let valueAddedExpenses1 = 0
                    let countExpense1 = 0

                    for (let i = 0; i < this.state.Payment_Received.length; i++) {

                        let invoice_balance = { invoice_balance: formatCurrency(this.state.Payment_Received[i].amount) };

                        let date = { dates: (moment.utc(this.state.Payment_Received[i].date_created).format('DD MMM, YYYY')) };

                        // let credit_amount = { credit_amount: formatCurrency(this.state.Payment_Received[i].credit_amount) };

                        let balance_amount = { balance_amount: formatCurrency(this.state.Payment_Received[i].balance) };

                        let str1 = this.state.Payment_Received[i].amount;
                        countExpense = parseFloat(str1.replaceAll(",", ""))
                        valueAddedExpenses += countExpense


                        let str2 = this.state.Payment_Received[i].balance;
                        countExpense1 = parseFloat(str2.replaceAll(",", ""))
                        valueAddedExpenses1 += countExpense1

                        data.push(Object.assign(invoice_balance, date, balance_amount, this.state.Payment_Received[i]));
                        console.log("bugs", data);
                        this.setState({
                            data: data
                        })
                    }

                    this.setState({
                        total_cost: valueAddedExpenses.toFixed(2),

                        mbalance: valueAddedExpenses1.toFixed(2),
                        isPageLoad: false
                    })



                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }


    getData2 = (startDate, endDate, queryString = "") => {

        let Sdate = moment(startDate).format('YYYY-MM-DD');
        let Edate = moment(endDate).format('YYYY-MM-DD');



        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `receivables?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&estimate=0&startDate="${Sdate} 00:00:00"&endDate="${Edate} 23:59:59"&${queryString}`
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((paymentResponse) => {
            this.setState({
                Payment_Received: paymentResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];


                    this.setState({
                        total: paymentResponse.data.total
                    })
                    let valueAddedExpenses = 0
                    let countExpense = 0

                    let valueAddedExpenses1 = 0
                    let countExpense1 = 0


                    if (this.state.Payment_Received.length > 0) {

                        for (let i = 0; i < this.state.Payment_Received.length; i++) {


                            let invoice_balance = { invoice_balance: formatCurrency(this.state.Payment_Received[i].amount) };

                            // let credit_amount = { credit_amount: formatCurrency(this.state.Payment_Received[i].credit_amount) };
                            let date = { dates: (moment.utc(this.state.Payment_Received[i].date_created).format('DD MMM, YYYY')) };

                            let balance_amount = { balance_amount: formatCurrency(this.state.Payment_Received[i].balance) };

                            let str1 = this.state.Payment_Received[i].amount;
                            countExpense = parseFloat(str1.replaceAll(",", ""))
                            valueAddedExpenses += countExpense


                            let str2 = this.state.Payment_Received[i].balance;
                            countExpense1 = parseFloat(str2.replaceAll(",", ""))
                            valueAddedExpenses1 += countExpense1

                            data.push(Object.assign(invoice_balance, date, balance_amount, this.state.Payment_Received[i]));
                            console.log("bugs", data);
                            this.setState({
                                data: data
                            })

                        }


                    } else {
                        this.setState({
                            data: data
                        })
                    }

                    this.setState({
                        total_cost: valueAddedExpenses.toFixed(2),
                        mbalance: valueAddedExpenses1.toFixed(2),
                        isPageLoad: false
                    })




                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');
        this.getData2(localStorage.getItem("STARTDATE"), localStorage.getItem("ENDDATE"), queryString)

    }
    getDataUpdate() {
        let queryString = ("filter_value=&page_number=0&page_size=10&sort_order=false")

        this.getData2(moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
    }


    applyCallback(startDate, endDate) {
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)
        let Sdate1 = moment(startDate).format('DD MMM, YYYY');
        let Edate2 = moment(endDate).format('DD MMM, YYYY');
        this.setState({
            valuedate: Sdate1 + " " + Edate2,
            startDate: Sdate1,
            isPageLoad: true,
            endDate: Edate2
        })

        this.getData2(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent, returnHome } = this.state;

        let ranges = {
            "Today Only": [moment(this.state.start), moment(this.state.end)],
            "Yesterday Only": [
                moment(this.state.start).subtract(1, "days"),
                moment(this.state.end).subtract(1, "days")
            ],
            "3 Days": [moment(this.state.start).subtract(3, "days"), moment(this.state.end)],
            "5 Days": [moment(this.state.start).subtract(5, "days"), moment(this.state.end)],
            "1 Week": [moment(this.state.start).subtract(7, "days"), moment(this.state.end)],
            "2 Weeks": [moment(this.state.start).subtract(14, "days"), moment(this.state.end)],
            "1 Month": [moment(this.state.start).subtract(1, "months"), moment(this.state.end)],
            "1 Year": [moment(this.state.start).subtract(1, "years"), moment(this.state.end)]
        };
        let local = {
            "format": "DD-MM-YYYY",
            "sundayFirst": false
        }
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
                    <div>
                        < Card >
                            <CardBody >
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-6">
                                                    <DateTimeRangeContainer
                                                        ranges={ranges}
                                                        start={this.state.start}
                                                        end={this.state.end}
                                                        local={local}
                                                        applyCallback={this.applyCallback}>
                                                        <FormControl
                                                            id="formControlsTextB"
                                                            type="text"
                                                            value={this.state.valuedate}
                                                            label="Text"
                                                            placeholder="Filter by Date"
                                                        />
                                                    </DateTimeRangeContainer>
                                                </div>
                                                <div className="col-6 float-left">

                                                    <button className=" float-left btn btn-primary btn-sm" onClick={e => {
                                                        this.getDataUpdate();
                                                        this.setState({
                                                            startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
                                                            endDate: moment(new Date()).format('DD MMM, YYYY'),
                                                            valuedate: "This year"
                                                        })
                                                    }} >Clear</button>
                                                    {/* <input id="input" type="text" readOnly /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <Link to="/report">
                                            <Button className="pull-right" outline color="primary">
                                                Back to reports
                                            </Button>
                                        </Link>

                                    </div>
                                </div><br />

                                <div className="panel-body" >

                                    <div className='text-center' p='3'>
                                        <h4>{COMPANY_NAME}</h4>
                                        <h4><strong>Receivables Summary</strong></h4>
                                        <h4>From {this.state.startDate} To {this.state.endDate}</h4>
                                        <br /><br />
                                    </div>

                                    <ReactDatatable
                                        config={this.config}

                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} />
                                    <br />
                                    <div className="float-right">
                                        <h4 >Total  <strong>{formatCurrency(this.state.total_cost)}</strong></h4>

                                        <h4 >Total Balance <strong>{formatCurrency(this.state.mbalance)}</strong></h4>

                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                )
                }
            </div >
        )
    }
}
