import { COMPANY_NAME, COMPANY_POSTAL_ADDRESS, CURRENCY, } from '../../_helpers/exports';
import { baseURL_2 } from '../../_helpers';
import axios from 'axios';
import * as moment from 'moment';
import pdfMake from "pdfmake/build/pdfmake";

export function saveCreditPDF(params) {
    createPDFFromParams(params);
}

// // Private functions
// function onDataURLLoaded(params, fileReader) {
//     // Set imageLogo to data URI of file
//     params.imageLogo = fileReader.result;
//     createPDFFromParams(params);
// }

function createPDFFromParams(params) {
    let docDefinition = buildDocDefinition(params);
    const pdfGen = pdfMake.createPdf(docDefinition);

    pdfGen.getBase64((data) => {
        console.log("datafile", data)
        let formData = {
            "email": params.customer_email,
            "customer_name": params.customer_name,
            "company_name": COMPANY_NAME,
            "currency": CURRENCY,
            "company_address": COMPANY_POSTAL_ADDRESS,
            "credit_no": params.credit_no,
            "credit_date": moment(params.invoice_date).format('DD, MMM YYYY'),
            "amount": params.amount1,
            "filesend": data
        }
        let url = baseURL_2 + "emailsendcredit.php"
        axios.post(url, formData).then((response) => {
            console.log("UPDATED", response.data)
        }).catch(error => {
            console.log("ERROR", error)
        })

        // let formData2 = {
        //     "invoice_no": params.invoice_no,
        //     "pdf": data,
        // }
        // axios.put(baseURL + `invoice`, formData2, {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json',
        //         'Authorization': TOKEN
        //     },
        // }).then((response) => {
        //     console.log("UPDATED", response.data)
        // }).catch((error) => {
        //     console.log('bayoo', error.response)
        //     // this.setState({ statusMessage: error.response.data.message });
        // })
    })
}

function buildDocDefinition(params) {
    let notesAndTerms = buildNotesAndTerms(params);
    console.log('notesAndTerms', notesAndTerms);
    return {
       
        // userPassword: params.credit_no,
        // ownerPassword: params.credit_no,
        permissions: {
            printing: 'highResolution', //'lowResolution'
            modifying: false,
            copying: false,
            annotating: true,
            fillingForms: true,
            contentAccessibility: true,
            documentAssembly: true
        },
        footer: function () {
            return [
                { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 595 - 10, y2: 10, lineWidth: 0.5 }] },
                {
                    columns: [
                        { text: `${params.footer}`, bold: true, color: 'black', fontSize: 9, alignment: 'center', marginTop: 5 }
                    ],

                },
            ];
        },

        background: [
            {
                image: localStorage.getItem("image_urls"),
                alignment: 'center',
                width: 800,
                height: 810,
                opacity: 0.1,
                borderRadius: 25,
            },

        ],
        content: [
            buildHeaderInformation(params),
            buildLineItemsTable(params),
            buildTotal(params),
            ...buildNotesAndTerms(params),
        ],
    };
}



function buildHeaderInformation(params) {

    let date2 = moment(params.due_date).format('DD, MMM YYYY');
    const optionalDataKeys = [];
    const optionalDataValues = [];
    let tax_exclusive

    if (params.tax_exclusive === 1) {
        tax_exclusive = "Tax Exclusive"
    } else {
        tax_exclusive = "Tax Inclusive"
    }
    // let total = params.lineItems.reduce((sum, lineItem) => {
    //     return sum + lineItem.quantity * lineItem.price;
    // }, 0);
    Object.entries({

        'Credit Date:': date2,
    }).forEach(([key, value]) => {
        if (value) {
            optionalDataKeys.push(key);
            optionalDataValues.push(value);
        }
    });

    return {
        columns: [{
            stack: [
                {
                    image: localStorage.getItem("image_urls"),
                    width: 200,
                    height: 150,
                    margin: [-30, -30, 0, 0],

                },

                {
                    text: COMPANY_NAME,
                    color: "black",
                    margin: [0, 0, 0, 90],
                    // background: '#eee',
                },

                {
                    text: "Bill to",
                    color: "black",

                    // background: '#eee',
                },
                {
                    text: params.customer_name,
                    color: "black",
                    margin: [0, 0, 0, 20],
                    bold: true
                    // background: '#eee',
                },

                // {
                //     text: tax_exclusive,
                //     color: "black",
                //     bold: true
                //     // background: '#eee',
                // }
            ],
        },
        {
            stack: [{
                text: 'CREDIT NOTE',
                bold: true,
                fontSize: 20,
            },
            {
                text: `# ${params.credit_no}`,
                fontSize: 12,
                bold: true,
                margin: [0, 0, 0, 20],
            },

            {
                text: `Credit Remaining`,
                fontSize: 10,
                bold: true,
            },
            {
                text: params.total,
                fontSize: 12,
                bold: true,
                margin: [0, 0, 0, 40],
            },
            {
                columns: [{
                    width: 50,
                    text: '',
                },
                {
                    width: '*',
                    columns: [{
                        stack: optionalDataKeys,
                        alignment: 'right',
                    },
                    {
                        stack: optionalDataValues,
                        alignment: 'right',
                    },
                    ],
                },
                ],
            },
            ],
            alignment: 'right',
        },
        ],
        styles: {
            subheader: {
                fontSize: 9,
                color: '#FFFFFF',
                background: '#2361AE',
                margin: [0, 15, 0, 5]
            }
        },
        // optional space between columns
        columnGap: 10,
        margin: [0, 0, 0, 30],
    };
}



function buildLineItemsTable(params) {
    let lineItemRows = params.lineItems.map(buildLineItem(params));
    return {
        table: {
            widths: ['*', '9%', '24%', '25%'],

            headerRows: 1,
            body: [
                [
                    {
                        text: 'Items & Description',
                        color: '#FFFFFF',
                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },

                    {
                        text: 'Qty',
                        color: '#FFFFFF',
                        alignment: 'right',

                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },
                    {
                        text: 'Rate',
                        color: '#FFFFFF',
                        alignment: 'right',

                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },
                    {
                        text: 'Amount',
                        color: '#FFFFFF',
                        alignment: 'right',
                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },
                ],
                ...lineItemRows,
            ],
        },
        layout: 'lightHorizontalLines',
    };
}

function buildTotal(params) {
    // let total = params.lineItems.reduce((sum, lineItem) => {
    //     return sum + lineItem.quantity * lineItem.price;
    // }, 0);

    // let subtotal = params.lineItems.reduce((sum, lineItem) => {
    //     return sum + (lineItem.quantity * lineItem) - params.tax;
    // }, 0);
    return {

        table: {
            widths: ['*', '40%'],
            body: [

                [{
                    text: 'Subtotal',
                    marginTop: 20,
                    alignment: 'right',
                },
                {
                    text: params.subtoal,
                    marginTop: 20,
                    alignment: 'right',
                },
                ],
                [
                    {
                        text: 'Tax Amount',
                        marginTop: 20,
                        alignment: 'right',
                    },
                    {
                        text: `${params.tax}`,
                        marginTop: 20,
                        alignment: 'right',
                    },
                ],
                [{
                    text: 'Discount',
                    marginTop: 20,
                    alignment: 'right',
                },
                {
                    text: params.discount_amount,
                    marginTop: 20,
                    marginBottom: 15,
                    alignment: 'right',
                },
                ],
                [{
                    text: 'Total',
                    margin: [5, 5, 5, 5],
                    color: '#0000',
                    fillColor: '#CCCCCC',
                    alignment: 'right',
                },
                {
                    text: `${params.total}`,
                    margin: [5, 5, 5, 5],

                    color: '#00000',
                    fillColor: '#CCCCCC',
                    alignment: 'right',
                },
                ],
            ],
        },
        layout: 'headerLineOnly',
        style: 'noBorders',
        // layout: {
        //     fillColor: function (rowIndex, node, columnIndex) {
        //         return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
        //     }
        // },

        margin: [160, 20, 0, 10],
    };
}



// Returns an array
function buildNotesAndTerms(params) {
    let result = [];
    console.log('params', params);

    result = result.concat([
        { text: params.customer_note, background: '#eee', margin: [0, 50, 0, 15] },
        { text: params.terms_condition, background: '#eee', margin: [0, 0, 0, 30] },
    ]);

    // if (params.terms) {
    //     result = result.concat([
    //         { text: '' },
    //         { text: params.stat, margin: [0, 0, 0, 30] },
    //     ]);
    // }
    return result;
}


// this.state.editedInvoice = {
//     invoice_no: this.state.invoice_no,
//     invoice_date: this.state.invoice_date,
//     terms: this.state.terms,
//     due_date: this.state.due_date,
//     notes: this.state.invoice_message,
//     stat: this.state.statement_message,
//     customer_id: this.state.customer_id,
//     lineItems: this.state.lineItems
//   }


function buildLineItem(params) {
    return function buildLineItemCurried(lineItem) {
        return [
            {
                text: lineItem.name + " " + lineItem.description,
                marginTop: 10, marginLeft: 5
            },
            {
                text: String(lineItem.quantity), marginTop: 10, alignment: 'right',
            },
            {
                text: `${parseFloat(lineItem.price).toFixed(2).replaceAll(/\d(?=(\d{3})+\.)/g, '$&,')}`, marginTop: 10, alignment: 'right',
            },
            // { text: `${lineItem.discount_percentage}`, marginTop: 10 },
            {
                alignment: 'right',
                marginTop: 10,
                text: `${parseFloat(Math.round((((lineItem.quantity * lineItem.price) -
                    (lineItem.discount_percentage / 100 * (lineItem.quantity * lineItem.price))) +
                    (lineItem.tax_percentage / 100 * (lineItem.quantity * lineItem.price))), 2)).toFixed(2).replaceAll(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            },
        ];
    };
}