import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Button } from 'reactstrap'
import Select from 'react-select';

import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";

import './style.css'
import { currency } from './datacurrency';
import { ADMIN_ROLE, CONFIG, CURRENCY, CURRENCY_AGAINST_KE, PROFILE_URL, successToast, ToastTable, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../../_helpers/exports';
import { baseURL, baseURL_2 } from '../../../_helpers';
import axios from 'axios';
import Avatar, { ConfigProvider } from 'react-avatar';
import "../../../_assets/css/csv.css";
import Alert from '../../../shared/components/Alert';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import PlacesAutocomplete from 'react-places-autocomplete';
import flags from 'react-phone-number-input/flags';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import { Form } from "react-bootstrap";

// import img from '../../../_assets/img/invoice_one.pdf';

// import img2 from '../../../_assets/img/invoice_two.pdf';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import { Email } from '@material-ui/icons';

export class Settings extends React.Component {
  constructor(props) {
    super(props)
    //binding data
    this.toggleModal = this.toggleModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCurrency = this.handleCurrency.bind(this);
    this.uploadProfile = this.uploadProfile.bind(this);

    this.toggleModalCreateInvoice = this.toggleModalCreateInvoice.bind(this);
    this.closeModalInvocie = this.closeModalInvocie.bind(this);

    this.handleSubmitInvoice = this.handleSubmitInvoice.bind(this);






    this.toggleModalCreate = this.toggleModalCreate.bind(this);
    this.state = {
      isLoading: false,
      isOpen: false,
      isOpenInvoice: false,
      currencyDialogue: false,

      financial_year: 'January to December',
      terms3: [
        {
          label: 'January to December',
          value: 'January to December',
        },
        {
          label: 'February to January',
          value: 'February to January',
        },
        {
          label: 'March to February',
          value: 'March to February',
        },
        {
          label: 'April to March',
          value: 'April to March',
        },
        {
          label: 'May to April',
          value: 'May to April',
        },
        {
          label: 'June to May',
          value: 'June to May',
        },
        {
          label: 'July to June',
          value: 'July to June',
        },
        {
          label: 'August to July',
          value: 'August to July',
        },
        {
          label: 'September to August',
          value: 'September to August',
        },
        {
          label: 'October to September',
          value: 'October to September',
        },
        {
          label: 'November to October',
          value: 'November to October',
        },
        {
          label: 'December to November',
          value: 'December to November',
        }
      ]
    }
  }

  toggleModalCreate = e => {
    this.setState({
      isOpen: true,
    });
  };

  toggleModalCreateInvoice = e => {
    this.setState({
      isOpenInvoice: true,
    });
  };


  //handle profile picture upload
  handleProfile = event => {

    // alert(JSON.stringify(event.target.files))
    this.setState({
      imagefile: event.target.files[0]
    });
  };


  uploadProfile = event => {
    event.preventDefault();

    var s = localStorage.getItem("profile_url");

    var final = s.substr(s.lastIndexOf('/') + 1);

    let img = new Image()

    let imgHeight;
    let imgWidth;


    img.src = window.URL.createObjectURL(this.state.imagefile)
    img.onload = () => {

      imgHeight = img.height;
      imgWidth = img.width;
      // alert(final);



      // alert(this.state.imagefile.size)

      // if (this.state.imagefile.size < 5242880 && imgWidth < 512 && imgHeight < 512) {

      this.setState({ loading: true });
      var data = new FormData();
      data.append("filename", this.state.imagefile);
      data.append("profile_url", final);
      data.append("user_id", USER_ID);
      this.setState({ isLoading1: true });
      axios.post(baseURL_2 + 'logoupload.php', data).then((response) => {
        // eslint-disable-next-line
        if (response.data.status = true) {
          this.setState({ statusMessage: response.data.message, isShowError: true, loading: false, isLoading1: false },
            function () {
              console.log(response)
              setTimeout(() => {
                this.setState({ statusMessage: "", isShowError: false, loading: false, isLoading1: false })
                this.closeModalUpload();

                this.getData();
              }, 2000);
            }
          );
        } else {
          this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, loading: false, isLoading1: false },
            function () {
              console.log("bayoddd", data)
              setTimeout(() => {
                this.setState({ statusMessage: "", isShowError: false, showError: false, isLoading1: false })
              }, 2000);
            }
          );
        }
      }).catch((error) => {
        console.log('bayoo', error.response)
        this.setState({ isShowError: false, showError: false, statusMessage: error.response.data.message, showError: true, isLoading1: false },
          function () {
            setTimeout(() => {
              this.setState({ statusMessage: "", isShowError: false, showError: false, isLoading1: false })
            }, 2000);
          }
        );
      })
      // }
      //  else {
      //   alert("Your logo doesnt fit our dimensions. Kindly edit it")
      // }
    }
  }
  handleCurrency = () => {
    this.setState({
      isLoading: true
    })
    // alert(new_currency)
    let formData = {
      "company_name": this.state.company_name,
      "business_pin": this.state.business_pin,
      "msisdn": this.state.msisdn,
      "postal_address": this.state.address,
      "physical_address": this.state.physical_address,
      "financial_year": this.state.financial_year,
      "client_id": USER_ID,
      "company_email": this.state.company_email,
      "currency": CURRENCY,
    }
    // alert(JSON.stringify(formData))
    let url = baseURL + `profile`;
    axios.put(url, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': TOKEN
      }
    }).then((response) => {
      localStorage.setItem("currency", CURRENCY)
      // localStorage.setItem("phonemsisdn", this.state.company_name)
      localStorage.setItem("company_name_client", this.state.company_name)
      localStorage.setItem("business_pin", this.state.business_pin)
      localStorage.setItem("financial_year", this.state.financial_year)
      localStorage.setItem("profile_url", this.state.url)
      localStorage.setItem("physical_address", this.state.physical_address)
      localStorage.setItem("address_client", this.state.address)
      localStorage.setItem("clientemail", this.state.company_email)

      setTimeout(() => {
        window.location.reload();
      }, 2000)
      this.setState({
        isLoading: false
      })
      alert("Successfully")

    }).catch(error => {

      // alert(JSON.stringify(error))
      console.log("ERROR")
    })
  };

  componentDidMount() {

    this.getData();
  }

  getData() {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": TOKEN
      }
    };
    // alert(moment(new Date(new Date().setDate(new Date().getDate() + 30))).format('YYYY-MM-DD HH:mm:ss'))
    axios.get(baseURL + `client?filter_value=` + USER_ID, axiosConfig).then(res => {
      localStorage.setItem("profile_url", res.data.data[0].url)

      this.setState({
        company_name: res.data.data[0].company_name,
        business_pin: res.data.data[0].business_pin,
        url: res.data.data[0].url,
        msisdn: res.data.data[0].msisdn,
        address: res.data.data[0].postal_address,
        physical_address: res.data.data[0].physical_address,

        company_email: res.data.data[0].company_email,
        financial_year: res.data.data[0].financial_year,
        isLoading: false,
      });

    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }


  // handleChange = value => {
  //   if (value != null) {
  //     this.setState(
  //       {
  //         agnaist_ksh: value.value.toString(),
  //         currencylabel: value.currency.toString(),
  //       });
  //   }
  // };


  handleSelect = address => {
    this.setState({ address: address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => { this.setState({ latitude: latLng.lat.toString(), longitude: latLng.lng.toString() }) })
      .catch(error => console.error('Error', error));
  };
  handleChangeAddress = address => {

    this.setState({ address });
  };

  toggleModal = e => {
    this.setState({
      currencyDialogue: true
    });
  };
  closeModal = e => {
    this.setState({
      currencyDialogue: false
    });
  };





  closeModalInvocie = e => {
    this.setState({
      isOpenInvoice: false
    });
  };

  closeModalUpload = e => {
    this.setState({
      isOpen: false,
    });
  };

  onSelectFlag(country) {
    console.log(country)
  }



  handleChangeFinancial = value => {

    if (value != null) {

      this.setState(
        {
          financial_year: value.value.toString(),
        });
    }
    else {
      this.setState(
        {
          financial_year: "January to December",
        });
    }
  };


  handleSubmitInvoice = e => {

    axios.get(baseURL + `update_invocie_status?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + '&status=' + e, CONFIG).then(res => {

      successToast("Successfully Updated")


      localStorage.setItem("invoice_status", e)

      this.closeModalInvocie();

      window.location.reload();

    }).catch(error => {

      // alert(JSON.stringify(error))
      console.log("ERROR")
    })
  }

  render() {
    return (
      <div style={{ marginTop: "-20px" }} >

        <ToastTable />
        <Modal
          isOpen={this.state.currencyDialogue}
          onRequestClose={e => {
            this.closeModal(e);
          }}
          contentLabel="My dialog"
          className="mymodal"
          onAfterOpen={() => {
            document.body.style.overflow = 'hidden';
          }}
          onAfterClose={() => {
            document.body.removeAttribute('style');
          }}
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >

          <div className="col-md-12 text-center">
            <h4>Organization Profile: <b>#{USER_ID}</b></h4><br />
            <Avatar round={true} size="150" onClick={this.toggleModalCreate} src={this.state.url} name={this.state.company_name} /><br /><br />
            <button
              onClick={this.toggleModalCreate}
              className="btn btn-success btn-sm">
              Change logo
            </button>
          </div>
          <label className="form-label">Company Name</label>

          <input id="input" type="text" className="form-control" name="company_name"
            required placeholder="EnteR Company Name"
            value={this.state.company_name}
            onChange={this.handleChange} />
          <br />
          <label className="form-label">Company Phone</label>

          <PhoneInput
            flags={flags}
            defaultCountry="KE"
            placeholder="Enter phone number"
            className="form-control"
            name="msisdn"
            id="input"
            maxLength={15}
            value={this.state.msisdn}
            onChange={value => this.setState({ msisdn: value })} />
          <br />



          <label className="form-label">Company Email</label>
          <input type="text" className="form-control" name="company_email"
            required placeholder="Enter Your Company Email"
            value={this.state.company_email}
            onChange={this.handleChange} />

          <br />

          <Form.Group className="Focus-line" >
            <Form.Label>Financial Year: {this.state.financial_year}</Form.Label>
            <Select
              isClearable
              options={this.state.terms3}
              placeholder="Select Account Type"
              autosize={true}
              required
              onChange={this.handleChangeFinancial}
              menuPortalTarget={document.body}
              name="investment_type"
            /><br />
          </Form.Group>
          {/* <div className="col-md-12">

            <DatePicker
              selected={this.state.financial}
              label="DatePicker Label"
              dateFormat="DD, MMM"
              required
              id="input"
              // minDate={moment().toDate()}
              value={moment().format('DD, MMM')}
              className="form-control"
              onChange={value => {

                //  alert(value)
                this.setState({ financial_year: value })

              }
              }
            />
          </div> */}
          <br />

          <label className="form-label">Postal Adderess</label>
          <input type="text" className="form-control" name="address"
            required placeholder="Enter Your Postal Address"
            value={this.state.address}
            onChange={this.handleChange} />

          {/* <label className="form-label">Business Location</label>
          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChangeAddress}
            onSelect={this.handleSelect}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <input
                  id="input"
                  className="form-control"
                  value={this.state.address}
                  {...getInputProps({
                    placeholder: 'Search Your Location ...',
                    className: 'location-search-input',
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete> */}

          {/*           
          <input id="input" type="text" className="form-control" name="state"
            required placeholder="Enter Your Location"
            value={this.state.address}
            onChange={this.handleChange} /> */}
          <br />


          <label className="form-label">Physical Address</label>
          <input type="text" className="form-control" name="physical_address"
            required placeholder="Enter Your Physical Address"
            value={this.state.physical_address}
            onChange={this.handleChange} />

          <br />


          <label className="form-label">Currency</label>
          <input type="text" disabled className="form-control" name="currency"
            required placeholder="Enter Your Currency"
            value={CURRENCY}
            onChange={this.handleChange} />
          <br />
          <label className="form-label">KRA PIN</label>
          <input id="input" type="text" className="form-control" name="business_pin"
            required placeholder="Set Kra PIN"
            value={this.state.business_pin}
            onChange={this.handleChange} />
          <br />
          <div className="row">
            <div className="col-md-12 text-center">
              <button
                onClick={this.handleCurrency}
                className="btn btn-primary btn-sm">
                {this.state.isLoading ? "Please Wait..." : "Submit"}
              </button>
            </div>
          </div>
        </Modal>


        <Modal
          isOpen={this.state.isOpen}
          onRequestClose={e => {
            this.closeModalUpload(e);
          }}
          contentLabel="My dialog"
          className="mymodal"
          onAfterOpen={() => {
            document.body.style.overflow = 'hidden';
          }}
          onAfterClose={() => {
            document.body.removeAttribute('style');
          }}
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          <MDBCloseIcon onClick={this.closeModalUpload} />
          <h4><b>Upload Logo</b></h4><br />

          <p>Maximum Dimensions: Width <b>512px</b> Height <b>512px</b></p>


          {this.state.showError ? <div >

            <Alert color="danger" className="alert--colored" icon>
              <p><span className="bold-text">{this.state.statusMessage}</span>
              </p>
            </Alert>


          </div> : null}<br></br>

          {this.state.isShowError ? (
            <div>

              <Alert color="success" className="alert--colored" icon>
                <p><span className="bold-text">{this.state.statusMessage}</span>
                </p>
              </Alert>
            </div>
          ) : null}
          <div className="wrapper text-center">
            <div className="section1">
              <div className="contain">
                <input
                  className="csv-input"
                  type="file"
                  required
                  ref={input => {
                    this.filesInput = input;
                  }}
                  name="file"
                  customHeight
                  placeholder={null}
                  onChange={this.handleProfile}
                />
                <br />
                <Button color="primary" className="btn-paypal btn-lg " onClick={this.uploadProfile} >
                  {this.state.isLoading1 ? "Uploading..." : "Upload now!"}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;

                <br />
              </div>
            </div>
          </div>

        </Modal>




        <Modal
          isOpen={this.state.isOpenInvoice}
          onRequestClose={e => {
            this.closeModalInvocie(e);
          }}
          contentLabel="My dialog"
          className="mymodal"
          onAfterOpen={() => {
            document.body.style.overflow = 'hidden';
          }}
          onAfterClose={() => {
            document.body.removeAttribute('style');
          }}
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          <MDBCloseIcon onClick={this.closeModalInvocie} />
          <h4><b>Select Invoice Format</b></h4><br />

          <div className="wrapper text-center">
            <div className="section1">
              <div className='col-md-12'>
                <b style={{ fontSize: "25px" }}> <a className="nav-link"
                  activeClass="active" href={'https://api.peakbooks.biz:9000/insightphp/invoice_one.pdf'} target="_blank">
                  View Format 1
                </a></b>
                <h4><Link className='Links' to="" onClick={() => this.handleSubmitInvoice(0)}>SET Invoice Format 1</Link>
                </h4>
                <hr />
              </div>
              <div className='col-md-12'>
                <b style={{ fontSize: "25px" }}> <a className="nav-link"
                  activeClass="active" href={'https://api.peakbooks.biz:9000/insightphp/invoice_two.pdf'} target="_blank">
                  View Format 2
                </a></b>
                <h4><Link className='Links' to="" onClick={() => this.handleSubmitInvoice(1)}>SET Invoice Format 2</Link></h4>
                <hr />
              </div>

            </div>
          </div>

        </Modal>

        <Card>
          <CardBody>
            <div className='col-12'>
              <h4><b>Settings</b></h4><br /><br /> <br />
              <div className='row col-12'>
                <div className='col-md-8 col-sm-8 col-xs-8' p='3'>

                  {ADMIN_ROLE == 1 ?
                    <div className='col-md-8 col-sm-8 col-xs-8'>
                      <h4><a className='Links' style={{ cursor: 'pointer' }} onClick={this.toggleModal}>Top Up</a></h4>
                      <hr />
                    </div>
                    : null}

                  {ADMIN_ROLE == 1 ?
                    <div className='col-md-8 col-sm-8 col-xs-8'>
                      <h4><a className='Links' style={{ cursor: 'pointer' }} onClick={this.toggleModal}>Set Company Profile</a></h4>
                      <hr />
                    </div> : null}


                  <div className='col-md-8 col-sm-8 col-xs-8'>
                    <h4><Link className='Links' to='/salesperson'>Sales Person  (Add Sales Person, Delete, Edit)</Link></h4>
                    <hr />
                  </div>
                  <div className='col-md-8 col-sm-8 col-xs-8'>
                    <h4> <Link className='Links' to='/tax'>Taxes (Set Tax, Delete, Edit)</Link></h4>
                    <hr />
                  </div>

                  {ADMIN_ROLE == 1 ?
                    <div className='col-md-8 col-sm-8 col-xs-8'>
                      <h4> <Link className='Links' to='/your-users'>Users  (Add, Activate, Deactivate, Edit)</Link></h4>
                      <hr />
                    </div>
                    : null}
                  <div className='col-md-8 col-sm-8 col-xs-8'>
                    <h4><Link className='Links' to='/notes'>Set Invoice Notes</Link></h4>
                    <hr />
                  </div>
                  <div className='col-md-8 col-sm-8 col-xs-8'>
                    <h4><Link className='Links' to='/receipt_notes'>Set Receipt Reference</Link></h4>
                    <hr />
                  </div>
                  <div className='col-md-8 col-sm-8 col-xs-8'>
                    <h4><Link className='Links' to='/footer'>Set Footer Ads</Link></h4>
                    <hr />
                  </div>

                  <div className='col-md-8 col-sm-8 col-xs-8'>
                    <h4> <a className='Links' style={{ cursor: 'pointer' }} onClick={this.toggleModalCreateInvoice}>Select Invoice Format </a></h4>
                    <hr />
                  </div>

                  <div className='col-md-8 col-sm-8 col-xs-8'>
                    <h4><Link className='Links' to='/add_`budget'>Set Business Goals</Link></h4>
                    <hr />
                  </div>



                </div>


              </div>

            </div>
          </CardBody>
        </Card>
      </div>
    )
  }
}
