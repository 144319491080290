
import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { BRANCH_ID, CURRENCY, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import Select from 'react-select';
import { Form } from "react-bootstrap";
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";



export class Recurring_viewexpense extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);

        this.columns = [
            {
                key: "expense_ref",
                TrOnlyClassName: 'tsc',
                text: "Reference",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "frequency_type",
                TrOnlyClassName: 'tsc',
                text: "Frequency",
                className: "tsc",
                align: "left"
            },

            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Last Expense Date",
                className: "tsc",
                align: "left"
            },

            {
                key: "dates_next",
                TrOnlyClassName: 'tsc',
                text: "Next Expense Date",
                className: "tsc",
                align: "left"
            },
            {
                key: "status",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.status === 0 ?
                                <div>
                                    <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                        InActive
                                    </span>
                                </div>
                                : null}
                            {record.status === 1 ?
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Active
                                </span>
                                : null}
                        </Fragment >
                    );
                }
            },

            // {
            //     key: "date_modified2",
            //     TrOnlyClassName: 'tsc',
            //     text: "Last Updated",
            //     className: "tsc",
            //     align: "left"
            // },

            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (

                        <Fragment className="center"  >
                            <button className="btn btn-primary  btn-sm"
                                title="Delete"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick2(record) }
                                } >
                                Edit Frequency
                            </button>

                            {record.status === 0 ?
                                <button className="btn btn-primary btn-sm"
                                    title="Edit Invoice"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleOnClick3(record) }} >
                                    Enable Recurring
                                </button>
                                : null}
                            {record.status === 1 ?
                                <button className="btn btn-danger btn-sm"
                                    title="Edit Invoice"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleOnClick1(record) }} >
                                    Disable Recurring
                                </button>
                                : null}
                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: true,
                csv: true
            },
            filename: "recurring_expenses",
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Invoice no...",
                no_data_text: "There are no recurring expenses at the moment",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showComponent: false,
            onEdit: false,
            returnHome: false,
            isOpenFrequncy: false,
            _onShow: false,
            invoice_date: new Date(),
            hideComponent: false,
            data: [],
            frequency_load: [
                {
                    label: 'Daily',
                    value: 'Daily',
                },
                {
                    label: 'Weekly',
                    value: 'Weekly',
                },
                {
                    label: 'After 2 Weeks',
                    value: 'After 2 Weeks',
                },
                {
                    label: 'Monthly',
                    value: 'Monthly',
                },
                {
                    label: 'After 2 Months',
                    value: 'After 2 Months',
                },
                {
                    label: 'After 3 Months',
                    value: 'After 3 Months',
                },
                {
                    label: 'After 6 Months',
                    value: 'After 6 Months',
                },
                {
                    label: 'Yearly',
                    value: 'Yearly',
                },
                {
                    label: 'After 2 Years',
                    value: 'After 2 Years',
                },

            ],
        };
    }


    handleChangeFrequency = value => {

        // alert(this.state.invoice_id)
        if (value != null) {
            // var newDate = new Date(this.state.invoice_date).add(Date.DAY, +1).format('Y-m-d');
            var daily = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 1))).format('YYYY-MM-DD HH:mm:ss');
            var weekly = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 7))).format('YYYY-MM-DD HH:mm:ss');
            var monthly = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 30))).format('YYYY-MM-DD HH:mm:ss');
            var yearly = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 365))).format('YYYY-MM-DD HH:mm:ss');
            var two_weeks = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 14))).format('YYYY-MM-DD HH:mm:ss');
            var two_months = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 60))).format('YYYY-MM-DD HH:mm:ss');
            var three_months = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 90))).format('YYYY-MM-DD HH:mm:ss');
            var two_years = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 730))).format('YYYY-MM-DD HH:mm:ss');
            var six_months = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 180))).format('YYYY-MM-DD HH:mm:ss');
            // alert(newDate)
            if (value.value.toString() === "Daily") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: daily,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "Weekly") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: weekly,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "After 2 Weeks") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: two_weeks,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "Monthly") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: monthly,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "After 2 Months") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: two_months,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "After 3 Months") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: three_months,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "After 6 Months") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: six_months,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "Yearly") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: yearly,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "After 2 Years") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: two_years,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
        }

        else {
            this.setState(
                {
                    frequency_type: "",
                    frequencyValue: [{ value: "", label: "" }]
                });
        }
    };

    closeModalFrequency = e => {
        this.setState({
            isOpenFrequncy: false,
        });
    };


    async handleOnClick1(record) {
        if (await confirm("Update status?")) {
            this.onEditInvoice(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    async handleOnClick3(record) {
        if (await confirm("Update status?")) {
            this.onEditInvoice2(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }
    async handleOnClick(record) {

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        this.setState({
            returnHome: true
        })
        let url = baseURL + `invoiceitem?filter_value=` + record.invoice_no
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            console.log("BUGggg", invoiceResponse)
            this.setState({
                lineItems: invoiceResponse.data.data,
                returnHome: false
            })
            this.state.editedInvoice = {
                invoice_no: record.invoice_no,
                invoice_date: record.dates,
                terms: record.terms,
                due_date: record.dates1,
                message_invoice: record.invoice_message,
                statement_invoice: record.statement_message,
                customer_name: record.customer_display_name,
                customer_email: record.email,
                amount: record.due_amount,
                due_amount: record.due_amount,
                lineItems: this.state.lineItems,
                tax_amount: record.tax_amount,
                due_period: record.due_period,
                status: record.status,
                discount_amount: record.discount_amount,
                currency: CURRENCY,
                tax: record.tax_amount,
                subtoal: record.sub_total,
                total: record.due_amount,
            };

        })).catch(error => {
            console.log('bayoo', error.response)

        })

        // console.log("hoge!");
        // if (await confirm("Y")) {
        // this._onButtonClick2(record)
        // } else {
        //     this.setState({
        //         message: "No!"
        //     });
        // }
    }
    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };



    componentDidMount() {
        this.load();
        this.getData();
    }

    load() {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url3 = baseURL + `deposit_to`
        let url4 = baseURL + `bank?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&account_type=Bank`
        let url5 = baseURL + `bank?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&account_type=Credit Card`
        let url6 = baseURL + `expense_account?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID

        axios.all([
            axios.get(url3, axiosConfig),
            axios.get(url4, axiosConfig),
            axios.get(url5, axiosConfig),
            axios.get(url6, axiosConfig)

        ]).then(axios.spread((paymentResponse, bankResponse, creditResponse, expenseResponse) => {
            this.setState({
                paymentResponse: paymentResponse.data.data,
                bankResponse: bankResponse.data.data,
                creditResponse: creditResponse.data.data,
                expenseResponse: expenseResponse.data
            }, function () {
                var datasss = [];
                if (this.state.paymentResponse.length > 0) {
                    for (let i = 0; i < this.state.paymentResponse.length; i++) {
                        let label = { label: this.state.paymentResponse[i].name };
                        let value = { value: this.state.paymentResponse[i].name };
                        datasss.push(Object.assign(label, value));
                        this.setState({
                            datasss: datasss
                        })
                    }
                } else {
                    this.setState({
                        datasss: datasss
                    })
                }

                localStorage.setItem("expenseaccountcustomers", JSON.stringify(this.state.expenseResponse))

                localStorage.setItem("paidthrough", JSON.stringify(datasss))
                var data_bank = [];
                if (this.state.bankResponse.length > 0) {
                    for (let j = 0; j < this.state.bankResponse.length; j++) {
                        let label = { label: this.state.bankResponse[j].account_name + ` (${this.state.bankResponse[j].bank_name})-` + this.state.bankResponse[j].currency };
                        let value = { value: this.state.bankResponse[j].account_name };
                        data_bank.push(Object.assign(label, value));
                        this.setState({
                            data_bank: data_bank
                        })
                    }
                } else {
                    this.setState({
                        data_bank: data_bank
                    })
                }
                localStorage.setItem("bankaccounts", JSON.stringify(data_bank))
                var data_credit = [];
                if (this.state.creditResponse.length > 0) {
                    for (let j = 0; j < this.state.creditResponse.length; j++) {
                        let label = { label: this.state.creditResponse[j].account_name + `${(this.state.creditResponse[j].bank_name)} -` + this.state.creditResponse[j].currency };
                        let value = { value: this.state.creditResponse[j].account_name };
                        data_credit.push(Object.assign(label, value));
                        this.setState({
                            data_credit: data_credit
                        })
                    }
                } else {
                    this.setState({
                        data_credit: data_credit
                    })
                }
                localStorage.setItem("creditaccounts", JSON.stringify(data_credit))
            })
        })).catch(error => {
            console.log('bayoo', error.response)
        })
    }
    getData = (queryString = "") => {

        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `expense_recurring?branch_id=${BRANCH_ID}&&client_id=` + USER_ID + "&&" + queryString
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            this.setState({
                invoice: invoiceResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];

                    this.setState({
                        total: invoiceResponse.data.total
                    })
                    // let editedInvoice = [];
                    // let datess = moment.unix(this.state.invoice[0].frequecy).format('DD MMM, YYYY')
                    // alert(JSON.stringify(datess))

                    for (let i = 0; i < this.state.invoice.length; i++) {
                        let date_next = { dates_next: (moment.unix(this.state.invoice[i].frequecy).format('DD/MM/YYYY')) };
                        let date_last = { dates: (moment.utc(this.state.invoice[i].start_time).format('DD/MM/YYYY')) };
                        let date_modified = { date_modified2: (moment(this.state.invoice[i].modified).format('DD/MM/YYYY HH:mm')) };
                        let opening_balance = { payables: CURRENCY + this.state.invoice[i].amount };
                        let opening_balance2 = { payables_tax: CURRENCY + this.state.invoice[i].tax_amount };

                        data.push(Object.assign(date_last, date_next, opening_balance2, date_modified, opening_balance, this.state.invoice[i]));
                        this.setState({
                            data: data
                        })
                    }
                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        // this.setState({
        //     showComponent: true,
        //     hideComponent: true,
        //     onEdit: false,
        //     showModal: false,
        //     showModals: false
        // });

        window.location.href = "create-recuring-expense"

    }

    onEditInvoice(record) {
        let formData = {
            expense_ref: record.expense_ref,
        };
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        axios.put(baseURL + `recurringexpense`,
            formData, axiosConfig).then(response => {
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                        window.setTimeout(() => {
                            this.getData();
                            this.setState({ isShowError: false })
                        }, 2000);
                    });
                } else {
                    this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                }
            }, error => {
                this.setState({ statusMessage: JSON.stringify(error), isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            });
    }
    onEditInvoice2(record) {
        let formData = {
            expense_ref: record.expense_ref,
        };
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        axios.put(baseURL + `recurringexpense2`,
            formData, axiosConfig).then(response => {
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                        window.setTimeout(() => {
                            this.getData();
                            this.setState({ isShowError: false })
                        }, 2000);
                    });
                } else {
                    this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                }
            }, error => {
                this.setState({ statusMessage: JSON.stringify(error), isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            });
    }



    onEditFrequency() {

        // alert(this.state.frequency)
        let formData = {
            expense_ref: this.state.expense_ref,
            // frequency: this.state.frequency,
            frequecy: (new Date(this.state.frequency).getTime()) / 1000,

            frequency_type: this.state.frequency_type
        };
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };

        // alert(JSON.stringify(formData))

        this.setState({
            submit_frequency: true
        })
        axios.put(baseURL + `recurringfrequency`,
            formData, axiosConfig).then(response => {

                // alert(JSON.stringify(response))

                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, submit_frequency: false, isShowError: true, isLoading: false }, function () {
                        window.setTimeout(() => {
                            this.getData();
                            this.closeModalFrequency();
                            this.setState({ isShowError: false })
                        }, 2000);
                    });
                } else {
                    this.setState({ statusMessage: response.data.message, isShowError: false, submit_frequency: false, showError: true, isLoading: false });
                    window.setTimeout(() => {
                        this.getData();
                        this.closeModalFrequency();
                        this.setState({ isShowError: false })
                    }, 2000);
                }
            }, error => {
                this.setState({ statusMessage: JSON.stringify(error), isShowError: false, submit_frequency: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.closeModalFrequency();
                    this.setState({ isShowError: false })
                }, 2000);
            });
    }


    _onButtonClick2(record) {

        var byteCharacters = atob(record.pdf);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    async handleOnClick2(record) {

        // alert(record.frequecy)
        this.setState({
            isOpenFrequncy: true,
            expense_ref: record.expense_ref,
            frequency_type: record.frequency_type,
            frequency: moment.unix(record.frequecy).format('YYYY-MM-DD HH:mm:ss'),
            invoice_id: record.invoice_no,
            frequencyValue: [{ label: record.frequency_type, value: record.frequency_type }]

        })


        // this.onEditInvoice2(record)

    }

    onDelete(record) {

        console.log("Delete Record", record);
        axios.delete(baseURL + `question/` + record.question_id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 2000);
        });
    }

    _onShow(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            onEdit: false,
            acceptable_answers: record.acceptable_answers,
            answer: record.answer,
            answer_id: record.id,
        });
    }

    render() {
        // const { showComponent, onEdit } = this.state;
        const { hideComponent, returnHome } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >

                {!hideComponent && (
                    <div>
                        < Card >

                            <Modal
                                isOpen={this.state.isOpenFrequncy}
                                onRequestClose={e => this.closeModalFrequency(e)}
                                contentLabel="My dialog"
                                className="mymodal"
                                onAfterOpen={() => {
                                    document.body.style.overflow = 'hidden';
                                }}
                                onAfterClose={() => {
                                    document.body.removeAttribute('style');
                                }}
                                overlayClassName="myoverlay"
                                closeTimeoutMS={500}
                            >
                                <MDBCloseIcon onClick={this.closeModalEmail} />
                                <h4><b>Do you wish to edit frequency?</b> Click done</h4><br />

                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>


                                </div> : null}

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>
                                    </div>
                                ) : null}

                                <div>

                                    <br />
                                    <Form.Group className="Focus-line">
                                        <Form.Label>Frequency</Form.Label>

                                        <Select
                                            isClearable
                                            options={this.state.frequency_load}
                                            // onInputChange={this.loadOptions}
                                            placeholder="Select frequency"
                                            autosize={true}
                                            onChange={this.handleChangeFrequency}
                                            isLoading={this.state.isLoading}
                                            value={this.state.frequencyValue}
                                            // noOptionsMessage={() => 'nothing found'}
                                            // loadingMessage={() => 'searching...'}   //minor type-O here
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        />

                                    </Form.Group>
                                    <br />

                                    <div className="col-8 offset-4" style={{ marginTop: '10px' }}>
                                        <button className="btn btn-success  btn-sm"
                                            title="Enable"
                                            style={
                                                { marginRight: '10px' }}
                                            onClick={
                                                () => { this.onEditFrequency() }}
                                        >
                                            {this.state.submit_frequency ? "Please Wait..." : "Done!"}
                                        </button>
                                    </div>
                                    <br />

                                </div>


                            </Modal>

                            {returnHome ? "Redirecting please wait..." :

                                <CardBody >
                                    <div className="panel-body" >
                                        <div className="row">

                                            <div className="col-md-6">
                                                <h4><b>Recurring Expense</b></h4><br />
                                            </div>
                                            <div className="col-md-6">
                                                {/* <Link to="create-recuring-expense"> */}
                                                <Button className="pull-right btn-sm" color="primary
                                                    " onClick={this._onButtonClick} outline>Create Recurring Expense <i className="fa fa-plus"></i>
                                                </Button>
                                                {/* </Link> */}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Total Recurring Expenses are now at: <b>{this.state.total}</b></h4>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="col-md-10 offset-1">
                                                    <button className="pull-right btn-sm" title="refresh" style={{ background: "white", border: "0px" }} onClick={this.getData}>
                                                        <RefreshIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        {this.state.showError ? <div >

                                            <Alert color="danger" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>


                                        </div> : null}

                                        {this.state.isShowError ? (
                                            <div>

                                                <Alert color="success" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>

                                            </div>

                                        ) : null}

                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />

                                    </div>
                                </CardBody>
                            }
                        </Card>
                    </div>
                )
                }
                {/* {
                    showComponent && (
                        < >
                            <Invoice />
                        </div>
                    )
                } */}
                {/* {
                    onEdit && (
                        // eslint-disable-next-line 
                        <Invoice_Edit />
                    )
                } */}
            </div >
        )
    }
}


// import React from "react";
// import Modal from 'react-modal';
// import "./style.css";

// // import { facebookConfig, googleConfig } from "social-config";

// export class invoice_view extends React.Component {

//     constructor(props) {
//         super(props);
//         // this.toggleModal = this.toggleModal.bind(this);

//         this.state = {
//             isOpen: false,
//             error: null
//         };
//     }

//     toggleModal = e => {
//         this.setState({
//             isOpen: true
//         });
//     };

//     closeModal = e => {
//         this.setState({
//             isOpen: false
//         });
//     };
//     render() {
//         return (
//             <div>
//                 <button >Open Modal</button>
//                 <Modal
//                     isOpen={this.state.isOpen}
//                     onRequestClose={e => {
//                         this.closeModal(e);
//                     }}
//                     contentLabel="My dialog"
//                     className="mymodal"
//                     overlayClassName="myoverlay"
//                     closeTimeoutMS={500}
//                   >
//                     <div>My modal dialog.</div>
//                     <h2 >Hello</h2>
//                       <button onClick={this.closeModal}>close</button>
//                       <div>I am a modal</div>
//                       <form>
//                         <input />
//                         <button>tab navigation</button>
//                         <button>stays</button>
//                         <button>inside</button>
//                         <button>the modal</button>
//                       </form>
//                     <button >Close modal</button>
//                 </Modal>
//             </div>
//         );
//     }
// }