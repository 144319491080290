import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Button } from "reactstrap";
import {
  BUSINESS_TYPE,
  COMPANY_NAME,
  CURRENCY,
  LOADER,
  ROLE, USER_ID_ACC,
  formatCurrency,
} from "../../_helpers/exports";
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
import * as moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl } from "react-bootstrap";
import { TOKEN, USER_ID, CONFIG } from "../../_helpers/exports";
import axios from "axios";
import { baseURL, baseURL_2 } from "../../_helpers";
import XLSX from 'xlsx';


export class GeneralLedger extends React.Component {
  constructor(props) {
    super(props);
    this.applyCallback = this.applyCallback.bind(this);

    this.state = {
      isShowError: false,

      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        download: false,
        key: "selection",
      },
    };
    let now = new Date();
    let start = moment(new Date(new Date().getFullYear(), 0, 1));
    let end = moment(new Date());
    this.state = {
      start: start,
      end: end,
      isPageLoad: true,
    };
  }

  exportData = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(document.getElementById("page"));
    XLSX.utils.book_append_sheet(wb, ws, 'General Ledger');
    XLSX.writeFile(wb, 'general_ledger.xlsx');
  }


  componentDidMount() {
    this.setState({
      startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format(
        "DD MMM, YYYY"
      ),
      endDate: moment(new Date()).format("DD MMM, YYYY"),
      isPageLoad: true,
      valuedate: "This year",
    });
    this.getData(
      moment(new Date(new Date().getFullYear(), 0, 1)).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD")
    );
  }

  getData(startDate, endDate) {
    this.setState({
      isLoading: true,
    });
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: TOKEN,
      },
    };
    let url = baseURL + `invoiceamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url2 = baseURL + `custom_balance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url3 = baseURL + `getbillamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url4 = baseURL + `expensesamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url5 = baseURL + `operationexpense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url6 = baseURL + `vendorcreditreport?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;

    // start of new api call
    let url7 = baseURL + `total-advancetax?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url8 = baseURL + `total-advertising-and-marketing?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url9 = baseURL + `total-automatic-expense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url10 = baseURL + `total-bad-debt?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url11 = baseURL + `total-bank-fees-and-charges?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url12 = baseURL + `total-consultant-expense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url13 = baseURL + `total-cost-of-goods-sold?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url14 = baseURL + `total-credit-card-charges?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url15 = baseURL + `total-depreciation-expense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url16 = baseURL + `total-employee-advance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url17 = baseURL + `total-employee-reimbursements?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url18 = baseURL + `total-IT-and-internet-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url19 = baseURL + `total-janitorial-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url20 = baseURL + `total-lodging-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url21 = baseURL + `total-meals-and-entertainment-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url22 = baseURL + `total-office-supplies-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url23 = baseURL + `total-other-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url24 = baseURL + `total-postage-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url25 = baseURL + `total-printing-and-stationery?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url26 = baseURL + `total-rent-expense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url27 = baseURL + `total-repair-and-maintenance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url28 = baseURL + `total-salaries-and-employees-wages?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url29 = baseURL + `total-telephone-expense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url30 = baseURL + `total-travel-expense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url31 = baseURL + `total-tax-payable?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;

    let url32 = baseURL + `total-bill-amount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url33 = baseURL + `total-unpaid-bill?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;


    let url35 = baseURL + `paid-through-advance-tax?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url36 = baseURL + `paid-through-employee-advance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url37 = baseURL + `paid-through-prepaid-expenses?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url38 = baseURL + `paid-through-petty-cash?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url39 = baseURL + `paid-through-furniture-and-fittings?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url40 = baseURL + `paid-through-employee-reimbursements?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url41 = baseURL + `paid-through-drawings?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url42 = baseURL + `paid-through-owners-equity?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url43 = baseURL + `paid-through-undeposited-funds?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    // end of new api call

    axios
      .all([
        axios.get(url, axiosConfig),
        axios.get(url2, axiosConfig),
        axios.get(url3, axiosConfig),
        axios.get(url4, axiosConfig),
        axios.get(url5, axiosConfig),
        axios.get(url6, axiosConfig),

        axios.get(url7, axiosConfig),
        axios.get(url8, axiosConfig),
        axios.get(url9, axiosConfig),
        axios.get(url10, axiosConfig),
        axios.get(url11, axiosConfig),
        axios.get(url12, axiosConfig),
        axios.get(url13, axiosConfig),
        axios.get(url14, axiosConfig),
        axios.get(url15, axiosConfig),
        axios.get(url16, axiosConfig),
        axios.get(url17, axiosConfig),
        axios.get(url18, axiosConfig),
        axios.get(url19, axiosConfig),
        axios.get(url20, axiosConfig),
        axios.get(url21, axiosConfig),
        axios.get(url22, axiosConfig),
        axios.get(url23, axiosConfig),
        axios.get(url24, axiosConfig),
        axios.get(url25, axiosConfig),
        axios.get(url26, axiosConfig),
        axios.get(url27, axiosConfig),
        axios.get(url28, axiosConfig),
        axios.get(url29, axiosConfig),
        axios.get(url30, axiosConfig),
        axios.get(url31, axiosConfig),

        axios.get(url32, axiosConfig),
        axios.get(url33, axiosConfig),


        axios.get(url35, axiosConfig),
        axios.get(url36, axiosConfig),
        axios.get(url37, axiosConfig),
        axios.get(url38, axiosConfig),
        axios.get(url39, axiosConfig),
        axios.get(url40, axiosConfig),
        axios.get(url41, axiosConfig),
        axios.get(url42, axiosConfig),
        axios.get(url43, axiosConfig),
      ])
      .then(
        axios.spread(
          (
            invoiceResponse,
            balanceResponse,
            billResponse,
            goodsResponse,
            expenseResponse,
            vendorCreditReponse,

            advanceTaxResponse,
            advertisingnMarketingRes,
            automaticExpenseResponse,
            badDebtResponse,
            bankFeeAndChargesRes,
            consultantExpenseResponse,
            costOfGoodsSoldResponse,
            creditCardChargesResponse,
            depreciationExpenseResponse,
            employeeAdvanceResponse,
            employeeReimbursementResponse,
            ITandInternetExpensesResponse,
            JanitorialExpensesResponse,
            LodgingExpensesResponse,
            MealsnEntertainmentRes,
            OfficeSuppliesResponse,
            OtherExpensesResponse,
            PostageExpensesResponse,
            PrintingAndStationeryRes,
            RentExpenseResponse,
            RepairAndMaintenanceRes,
            SalariesNemployeeWagesRes,
            TelephoneExpenseResponse,
            TravelExpenseResponse,
            TaxPayableResponse,

            TotalBillAmountResponse,
            TotalUnpaidBillResponse,


            PaidThroughAdvanceTaxRes,
            PaidThroughEmployeeAdvanceRes,
            PaidThroughPrepaidExpensesRes,
            PaidThroughPettyCashRes,
            PaidThroughFurnitureRes,
            PaidThroughEmployeeReimbursementsRes,
            PaidThroughDrawingsRes,
            PaidThroughOwnersEquityRes,
            PaidThroughUndepositedFundsRes,

          ) => {
            console.log("adtax", advanceTaxResponse);
            this.setState(
              {
                invoice1: invoiceResponse.data.data,
                balance1: balanceResponse.data.data,
                bills1: billResponse.data.data,
                goods1: goodsResponse.data.data,
                expense1: expenseResponse.data.data,
                vendorcredit1: vendorCreditReponse.data.data,

                advanceTax1: advanceTaxResponse.data.data[0].total_amount,
                advertisingnMarketing1: advertisingnMarketingRes.data.data[0].total_amount,
                automaticExpense1: automaticExpenseResponse.data.data[0].total_amount,
                badDebt1: badDebtResponse.data.data[0].total_amount,
                bankFeeAndCharges1: bankFeeAndChargesRes.data.data[0].total_amount,
                consultantExpense1: consultantExpenseResponse.data.data[0].total_amount,
                costOfGoodsSold1: costOfGoodsSoldResponse.data.data[0].total_amount,
                creditCardCharges1: creditCardChargesResponse.data.data[0].total_amount,
                depreciationExpense1: depreciationExpenseResponse.data.data[0].total_amount,
                employeeAdvance1: employeeAdvanceResponse.data.data[0].total_amount,
                employeeReimbursement1: employeeReimbursementResponse.data.data[0].total_amount,
                ITandInternetExpense1: ITandInternetExpensesResponse.data.data[0].total_amount,
                JanitorialExpense1: JanitorialExpensesResponse.data.data[0].total_amount,
                LodgingExpense1: LodgingExpensesResponse.data.data[0].total_amount,
                MealsEntertainmentExpense1: MealsnEntertainmentRes.data.data[0].total_amount,
                OfficeSuppliesExpense1: OfficeSuppliesResponse.data.data[0].total_amount,
                OtherExpense1: OtherExpensesResponse.data.data[0].total_amount,
                PostageExpense1: PostageExpensesResponse.data.data[0].total_amount,
                PrintingAndStationery1: PrintingAndStationeryRes.data.data[0].total_amount,
                RentExpense1: RentExpenseResponse.data.data[0].total_amount,
                RepairNMaintenance1: RepairAndMaintenanceRes.data.data[0].total_amount,
                SalariesNWages1: SalariesNemployeeWagesRes.data.data[0].total_amount,
                TelephoneExpense1: TelephoneExpenseResponse.data.data[0].total_amount,
                TravelExpense1: TravelExpenseResponse.data.data[0].total_amount,
                TaxPayable1: TaxPayableResponse.data.data[0].total_amount,

                TotalBillAmount1: TotalBillAmountResponse.data.data[0].total_amount,
                TotalUnPaidBill1: TotalUnpaidBillResponse.data.data[0].total_amount,

                PaidThAdvanceTax1: PaidThroughAdvanceTaxRes.data.data[0].total_amount,
                PaidThEmployeeAdvance1: PaidThroughEmployeeAdvanceRes.data.data[0].total_amount,
                PaidThPrepaidExpense1: PaidThroughPrepaidExpensesRes.data.data[0].total_amount,
                PaidThPettyCash1: PaidThroughPettyCashRes.data.data[0].total_amount,
                PaidThFurniture1: PaidThroughFurnitureRes.data.data[0].total_amount,
                PaidThEmployeeRe1: PaidThroughEmployeeReimbursementsRes.data.data[0].total_amount,
                PaidThDrawings: PaidThroughDrawingsRes.data.data[0].total_amount,
                PaidThOwnerEq1: PaidThroughOwnersEquityRes.data.data[0].total_amount,
                PaidThUndeposited1: PaidThroughUndepositedFundsRes.data.data[0].total_amount,

                isLoading: false,
              },

              function () {
                var data = [];
                // let editedInvoice = [];

                if (
                  this.state.invoice1.length === 0 &&
                  this.state.balance1.length === 0
                ) {
                  this.setState({
                    operating_cost: "0.00",
                    isPageLoad: false,
                  });
                } else {
                  let valueAdded = 0;
                  let count = 0;
                  let valueAdded1 = 0;
                  let count1 = 0;
                  let valueOutofBalance = 0;
                  let countOutofBalance = 0;

                  for (let i = 0; i < this.state.invoice1.length; i++) {
                    let str = this.state.invoice1[i].amount;
                    count = parseFloat(str.replaceAll(",", ""));
                    valueAdded += count;
                  }
                  for (let j = 0; j < this.state.balance1.length; j++) {
                    let b;
                    if (this.state.balance1[j].out_of_balance === null) {
                      b = 0;
                    } else {
                      let str1 =
                        this.state.balance1[j].out_of_balance.toString();
                      countOutofBalance = parseFloat(str1.replaceAll(",", ""));
                      valueOutofBalance += countOutofBalance;
                    }
                  }
                  this.setState({
                    operating_cost1: (countOutofBalance + valueAdded).toFixed(
                      2
                    ),
                  });
                }

                this.getBalanceSheet(startDate, endDate);

                if (
                  this.state.bills1.length === 0 &&
                  this.state.goods1.length === 0 &&
                  this.state.vendorcredit1.length === 0
                ) {
                  this.setState({
                    cost_of_goods1: "0.00",
                  });
                } else {
                  let valueAddedGoods = 0;
                  let countGoods = 0;
                  let valueBillsBalance = 0;
                  let countBillsBalance = 0;

                  let valueVendorCredit = 0;
                  let countVendorCredit = 0;

                  for (let i = 0; i < this.state.bills1.length; i++) {
                    let str = this.state.bills1[i].amount.toString();
                    countBillsBalance = parseFloat(str.replaceAll(",", ""));
                    valueBillsBalance += countBillsBalance;
                  }

                  for (let i = 0; i < this.state.vendorcredit1.length; i++) {
                    let str = this.state.vendorcredit1[i].due_amount;
                    countVendorCredit = parseFloat(str.replaceAll(",", ""));
                    valueVendorCredit += countVendorCredit;
                  }
                  for (let j = 0; j < this.state.goods1.length; j++) {
                    let str1;
                    if (this.state.goods1[j].amount === null) {
                      valueAddedGoods = 0;
                    } else {
                      let str1 = this.state.goods1[j].amount.toString();
                      countGoods = parseFloat(str1.replaceAll(",", ""));
                      valueAddedGoods += countGoods;
                    }
                  }
                  console.log(
                    "bayoo",
                    (valueAddedGoods + valueBillsBalance).toFixed(2)
                  );

                  this.setState({
                    cost_of_goods1: (
                      valueBillsBalance +
                      valueAddedGoods -
                      valueVendorCredit
                    ).toFixed(2),
                  });
                }

                if (this.state.expense1.length === 0) {
                  this.setState({
                    cost_of_operation1: "0.00",
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.expense1.length; j++) {
                    let a;
                    if (this.state.expense1[j].amount == null) {
                      a = 0;
                    } else {
                      let str2 = this.state.expense1[j].amount.toString();

                      // alert(this.state.expense[].amount)
                      countExpense = parseFloat(str2.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }
                  }
                  this.setState({
                    cost_of_operation1: valueAddedExpenses.toFixed(2),
                  });
                }

                this.setState({
                  gross1:
                    this.state.operating_cost1 - this.state.cost_of_goods1,
                });
              }
            );
          }
        )
      )
      .catch((error) => {
        console.log("bayoo", error.response);
      });
  }

  applyCallback(startDate, endDate) {
    let Sdate1 = moment(startDate).format("DD MMM, YYYY");
    let Edate2 = moment(endDate).format("DD MMM, YYYY");
    this.setState({
      valuedate: Sdate1 + " " + Edate2,
      startDate: Sdate1,
      isPageLoad: true,
      endDate: Edate2,
    });

    this.getData(
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD")
    );
  }

  getBalanceSheet(startDate, endDate) {
    this.setState({
      isLoading: true,
    });
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: TOKEN,
      },
    };
    let url =
      baseURL +
      `pettycashdebit?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url2 =
      baseURL +
      `pettycashcredit?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url32 =
      baseURL +
      `paymentamountmade?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url3 =
      baseURL +
      `undepositeddebit?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url4 =
      baseURL +
      `undepositedcredit?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url5 =
      baseURL +
      `operationexpense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url6 =
      baseURL +
      `vendorbalance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url7 =
      baseURL +
      `getamountreceived?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url9 =
      baseURL +
      `taxpayable?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url11 =
      baseURL +
      `rembursement?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url12 =
      baseURL +
      `prepaid?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url13 =
      baseURL +
      `payablepaid?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url14 =
      baseURL +
      `furtinure?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url15 =
      baseURL +
      `invoiceamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url16 =
      baseURL +
      `custom_balance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url19 =
      baseURL +
      `furniture2?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url21 =
      baseURL +
      `getbillamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url22 =
      baseURL +
      `rembursementcredit?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url23 =
      baseURL +
      `taxamountexpense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url24 =
      baseURL +
      `invoicetaxamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url25 =
      baseURL +
      `drawings?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url26 =
      baseURL +
      `offsetbalance?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url27 =
      baseURL +
      `othercurrentassets?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url28 =
      baseURL +
      `intangibleasset?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url29 =
      baseURL +
      `othernoncurrent?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url30 =
      baseURL +
      `vendorcreditreport?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url34 =
      baseURL +
      `creditvendortaxamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url35 =
      baseURL +
      `credittaxamount?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;
    let url36 =
      baseURL +
      `liabilities?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&startDate="${startDate} 00:00:00"&endDate="${endDate} 23:59:59"`;

    axios
      .all([
        axios.get(url, axiosConfig),
        axios.get(url2, axiosConfig),
        axios.get(url32, axiosConfig),
        axios.get(url3, axiosConfig),
        axios.get(url4, axiosConfig),
        axios.get(url5, axiosConfig),
        axios.get(url6, axiosConfig),
        axios.get(url7, axiosConfig),
        axios.get(url9, axiosConfig),
        axios.get(url11, axiosConfig),
        axios.get(url12, axiosConfig),
        axios.get(url13, axiosConfig),
        axios.get(url14, axiosConfig),
        axios.get(url15, axiosConfig),
        axios.get(url16, axiosConfig),
        axios.get(url19, axiosConfig),
        axios.get(url21, axiosConfig),
        axios.get(url22, axiosConfig),
        axios.get(url23, axiosConfig),
        axios.get(url24, axiosConfig),
        axios.get(url25, axiosConfig),
        axios.get(url26, axiosConfig),
        axios.get(url27, axiosConfig),
        axios.get(url28, axiosConfig),
        axios.get(url29, axiosConfig),
        axios.get(url30, axiosConfig),
        axios.get(url34, axiosConfig),
        axios.get(url35, axiosConfig),
        axios.get(url36, axiosConfig),
      ])
      .then(
        axios.spread(
          (
            pettydebitResponse,
            pettycreditResponse,
            pettydepositResponse,
            undepositedfunddebbitResponse,
            undepositedfundcreditResponse,
            expenseResponse,
            vendorBalance,
            amountResponse,
            payableResponse,
            rembursementResponse,
            prepaidResponse,
            prepaidpaidResponse,
            furnitureResponse,
            invoiceResponse,
            balanceResponse,
            furnitureResponse2,
            billResponse,
            rembursementCreditResponse,
            taxExpenseCreditResponse,
            invoiceTacResponse,
            drawingResponse,
            offsetbalanceResponse,
            othercurrentResponse,
            intangibleResponse,
            othernoncurrentResponse,
            vendorCreditReponse,
            vendorCreditTaxReponse,
            invoiceCreditTaxReponse,
            liabilityReponse
          ) => {
            this.setState(
              {
                pettydebit: pettydebitResponse.data.data,
                pettycredit: pettycreditResponse.data.data,
                pettycreditpayment: pettydepositResponse.data.data,
                undepositeddebit: undepositedfunddebbitResponse.data.data,
                undepositedcredit: undepositedfundcreditResponse.data.data,
                expense: expenseResponse.data.data,
                vendor: vendorBalance.data.data,
                amount: amountResponse.data.data,
                payable: payableResponse.data.data,
                rembursement: rembursementResponse.data.data,
                prepaid: prepaidResponse.data.data,
                payablepaid: prepaidpaidResponse.data.data,
                furniture: furnitureResponse.data.data,
                invoice: invoiceResponse.data.data,
                balance: balanceResponse.data.data,
                furniture2: furnitureResponse2.data.data,
                bills: billResponse.data.data,
                rembursementcredit: rembursementCreditResponse.data.data,
                expensetax: taxExpenseCreditResponse.data.data,
                invoicetax: invoiceTacResponse.data.data,
                drawings: drawingResponse.data.data,
                offsetbalance: offsetbalanceResponse.data.data,
                otherassets: othercurrentResponse.data.data,
                intangibleassets: intangibleResponse.data.data,
                othernonassets: othernoncurrentResponse.data.data,
                vendorcredit: vendorCreditReponse.data.data,
                vendortaxcredit: vendorCreditTaxReponse.data.data,
                invoicetaxcredit: invoiceCreditTaxReponse.data.data,
                liability: liabilityReponse.data.data,

                isLoading: false,
              },
              function () {
                var data = [];

                this.budget();
                // let editedInvoice = [];
                // alert(JSON.stringify(vendorCreditReponse.data.data))

                if (this.state.liability.length === 0) {
                  this.setState({
                    total_liablity: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.liability.length; j++) {
                    let a;
                    if (this.state.liability[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.liability[j].amount.toString();
                      console.log("bayo2", str1);

                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    total_liablity: valueAddedExpenses,
                  });
                }

                if (this.state.vendortaxcredit.length === 0) {
                  this.setState({
                    tax_vendor_credit: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.vendortaxcredit.length; j++) {
                    let a;
                    if (this.state.vendortaxcredit[j].tax_amount === null) {
                      a = 0;
                    } else {
                      let str1 =
                        this.state.vendortaxcredit[j].tax_amount.toString();
                      console.log("bayo2", str1);

                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    tax_vendor_credit: valueAddedExpenses,
                  });
                }

                if (this.state.invoicetaxcredit.length === 0) {
                  this.setState({
                    tax_credit_note: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.invoicetaxcredit.length; j++) {
                    let a;
                    if (this.state.invoicetaxcredit[j].tax_amount === null) {
                      a = 0;
                    } else {
                      let str1 =
                        this.state.invoicetaxcredit[j].tax_amount.toString();
                      console.log("bayo2", str1);

                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    tax_credit_note: valueAddedExpenses,
                  });
                }

                if (this.state.intangibleassets.length === 0) {
                  this.setState({
                    intangible_cost: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.intangibleassets.length; j++) {
                    let a;
                    if (this.state.intangibleassets[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 =
                        this.state.intangibleassets[j].amount.toString();
                      console.log("bayo2", str1);

                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    intangible_cost: valueAddedExpenses,
                  });
                }

                if (this.state.othernonassets.length === 0) {
                  this.setState({
                    non_asset_cost: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.othernonassets.length; j++) {
                    let a;
                    if (this.state.othernonassets[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.othernonassets[j].amount.toString();
                      console.log("bayo2", str1);

                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    non_asset_cost: valueAddedExpenses,
                  });
                }

                if (this.state.otherassets.length === 0) {
                  this.setState({
                    other_current_asset: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.otherassets.length; j++) {
                    let a;
                    if (this.state.otherassets[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.otherassets[j].amount.toString();
                      console.log("bayo2", str1);

                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    other_current_asset: valueAddedExpenses,
                  });
                }

                if (this.state.offsetbalance.length === 0) {
                  this.setState({
                    offsetbalance_cost: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.offsetbalance.length; j++) {
                    let a;
                    if (this.state.offsetbalance[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.offsetbalance[j].amount.toString();
                      console.log("bayo2", str1);

                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    offsetbalance_cost: valueAddedExpenses,
                  });
                }

                if (this.state.drawings.length === 0) {
                  this.setState({
                    drawing_cost: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.drawings.length; j++) {
                    let a;
                    if (this.state.drawings[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.drawings[j].amount.toString();
                      console.log("bayo2", str1);

                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    drawing_cost: valueAddedExpenses,
                  });
                }

                if (this.state.furniture.length === 0) {
                  this.setState({
                    furniture_cost: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.furniture.length; j++) {
                    let a;
                    if (this.state.furniture[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.furniture[j].amount.toString();
                      console.log("bayo2", str1);

                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    furniture_cost: valueAddedExpenses,
                  });
                }

                if (this.state.furniture2.length === 0) {
                  this.setState({
                    furniture_cost2: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.furniture2.length; j++) {
                    let a;
                    if (this.state.furniture2[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.furniture2[j].amount.toString();
                      console.log("bayo2", str1);

                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    furniture_cost2: valueAddedExpenses,
                  });
                }

                if (this.state.pettycredit.length === 0) {
                  this.setState({
                    petty_credit: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.pettycredit.length; j++) {
                    let a;
                    if (this.state.pettycredit[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.pettycredit[j].amount.toString();
                      console.log("bayo2", str1);

                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    petty_credit: valueAddedExpenses,
                  });
                }

                if (this.state.pettydebit.length === 0) {
                  this.setState({
                    petty_debit: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.pettydebit.length; j++) {
                    let a;
                    if (this.state.pettydebit[j].amount_received === null) {
                      a = 0;
                    } else {
                      let str1 =
                        this.state.pettydebit[j].amount_received.toString();
                      console.log("bayo2", str1);
                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    petty_debit: valueAddedExpenses,
                  });
                }

                if (this.state.pettycreditpayment.length === 0) {
                  this.setState({
                    petty_paymenymade: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (
                    let j = 0;
                    j < this.state.pettycreditpayment.length;
                    j++
                  ) {
                    let a;
                    if (this.state.pettycreditpayment[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.pettycreditpayment[j].amount;
                      console.log("bayo2", str1);
                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    petty_paymenymade: valueAddedExpenses,
                  });
                }

                if (
                  this.state.invoice.length === 0 &&
                  this.state.balance.length === 0
                ) {
                  this.setState({
                    operating_cost: 0,
                  });
                } else {
                  let valueAdded = 0;
                  let count = 0;
                  let valueAdded1 = 0;
                  let count1 = 0;
                  let valueOutofBalance = 0;
                  let countOutofBalance = 0;

                  for (let i = 0; i < this.state.invoice.length; i++) {
                    let str = this.state.invoice[i].amount;
                    count = parseFloat(str.replaceAll(",", ""));
                    valueAdded += count;
                  }
                  for (let j = 0; j < this.state.balance.length; j++) {
                    let b;
                    if (this.state.balance[j].out_of_balance === null) {
                      b = 0;
                    } else {
                      let str1 =
                        this.state.balance[j].out_of_balance.toString();
                      countOutofBalance = parseFloat(str1.replaceAll(",", ""));
                      valueOutofBalance += countOutofBalance;
                    }
                  }
                  this.setState({
                    operating_cost: valueAdded + valueOutofBalance,
                  });
                }

                if (this.state.undepositedcredit.length === 0) {
                  this.setState({
                    undeposited_credit: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (
                    let j = 0;
                    j < this.state.undepositedcredit.length;
                    j++
                  ) {
                    let a;
                    if (this.state.undepositedcredit[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 =
                        this.state.undepositedcredit[j].amount.toString();
                      console.log("bayo2", str1);
                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    undeposited_credit: valueAddedExpenses,
                  });
                }

                if (this.state.undepositeddebit.length === 0) {
                  this.setState({
                    undeposited_debit: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.undepositeddebit.length; j++) {
                    let a;
                    if (
                      this.state.undepositeddebit[j].amount_received === null
                    ) {
                      a = 0;
                    } else {
                      let str1 =
                        this.state.undepositeddebit[
                          j
                        ].amount_received.toString();
                      console.log("bayo2", str1);
                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }
                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    undeposited_debit: valueAddedExpenses,
                  });
                }

                if (this.state.expense.length === 0) {
                  this.setState({
                    cost_of_operation: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.expense.length; j++) {
                    let a;
                    if (this.state.expense[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.expense[j].amount.toString();
                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }
                  }
                  this.setState({
                    cost_of_operation: valueAddedExpenses,
                  });
                }

                if (
                  this.state.vendor.length === 0 ||
                  this.state.bills.length === 0 ||
                  this.state.vendorcredit.length === 0 ||
                  this.state.pettycreditpayment.length === 0
                ) {
                  this.setState({
                    vendor_balance: 0,
                  });
                } else {
                  let valueAddedVendor = 0;
                  let countVendor = 0;
                  let valueBillsBalance = 0;
                  let countBillsBalance = 0;
                  let valueVendorCredit = 0;
                  let countVendorCredit = 0;

                  let valueAddedExpenses = 0;
                  let countExpense = 0;

                  for (let i = 0; i < this.state.bills.length; i++) {
                    let str = this.state.bills[i].amount;
                    countBillsBalance = parseFloat(str.replaceAll(",", ""));
                    valueBillsBalance += countBillsBalance;
                  }

                  for (let i = 0; i < this.state.vendorcredit.length; i++) {
                    let str = this.state.vendorcredit[i].due_amount;
                    countVendorCredit = parseFloat(str.replaceAll(",", ""));
                    valueVendorCredit += countVendorCredit;
                  }
                  for (let j = 0; j < this.state.vendor.length; j++) {
                    let a;
                    if (this.state.vendor[j].opening_balance === null) {
                      a = 0;
                    } else {
                      let str1 =
                        this.state.vendor[j].opening_balance.toString();
                      countVendor = parseFloat(str1.replaceAll(",", ""));
                      valueAddedVendor += countVendor;
                    }
                  }

                  for (
                    let j = 0;
                    j < this.state.pettycreditpayment.length;
                    j++
                  ) {
                    let a;
                    if (this.state.pettycreditpayment[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.pettycreditpayment[j].amount;
                      console.log("bayo2", str1);
                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }
                  }
                  this.setState({
                    vendor_balance:
                      valueAddedExpenses +
                      valueVendorCredit -
                      (valueBillsBalance + valueAddedVendor),
                  });
                }

                if (this.state.amount.length === 0) {
                  this.setState({
                    amount_received: 0.0,
                  });
                } else {
                  let valueAddedAmount = 0;
                  let countAmount = 0;
                  for (let j = 0; j < this.state.amount.length; j++) {
                    let a;
                    if (this.state.amount[j].amount_received === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.amount[j].amount_received;
                      valueAddedAmount += countAmount;
                    }
                  }
                  this.setState({
                    amount_received: valueAddedAmount,
                  });
                }

                if (this.state.payable.length === 0) {
                  this.setState({
                    payable_tax: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.payable.length; j++) {
                    let a;
                    if (this.state.payable[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.payable[j].amount.toString();
                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }
                  }
                  this.setState({
                    payable_tax: valueAddedExpenses,
                  });
                }

                if (this.state.expensetax.length === 0) {
                  this.setState({
                    expense_tax_amount: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.expensetax.length; j++) {
                    let a;
                    if (this.state.expensetax[j].tax_amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.expensetax[j].tax_amount.toString();
                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }
                  }
                  this.setState({
                    expense_tax_amount: valueAddedExpenses,
                  });
                }

                if (this.state.invoicetax.length === 0) {
                  this.setState({
                    invoice_tax_amount: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.invoicetax.length; j++) {
                    let a;
                    if (this.state.invoicetax[j].tax_amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.invoicetax[j].tax_amount.toString();
                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }
                  }
                  this.setState({
                    invoice_tax_amount: valueAddedExpenses,
                  });
                }

                if (this.state.rembursement.length === 0) {
                  this.setState({
                    rembursement_cost: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.rembursement.length; j++) {
                    let a;
                    if (this.state.rembursement[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.rembursement[j].amount.toString();
                      console.log("bayo2", str1);

                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    rembursement_cost: valueAddedExpenses,
                  });
                }

                if (this.state.rembursementcredit.length === 0) {
                  this.setState({
                    rembursement_credit_cost: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (
                    let j = 0;
                    j < this.state.rembursementcredit.length;
                    j++
                  ) {
                    let a;
                    if (this.state.rembursementcredit[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 =
                        this.state.rembursementcredit[j].amount.toString();
                      console.log("bayo2", str1);
                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }
                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    rembursement_credit_cost: valueAddedExpenses,
                  });
                }

                if (this.state.prepaid.length === 0) {
                  this.setState({
                    prepaid_cost: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.prepaid.length; j++) {
                    let a;
                    if (this.state.prepaid[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.prepaid[j].amount.toString();
                      console.log("bayo2", str1);

                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }

                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    prepaid_cost: valueAddedExpenses,
                  });
                }

                if (this.state.payablepaid.length === 0) {
                  this.setState({
                    payablepaid_cost: 0,
                  });
                } else {
                  let valueAddedExpenses = 0;
                  let countExpense = 0;
                  for (let j = 0; j < this.state.payablepaid.length; j++) {
                    let a;
                    if (this.state.payablepaid[j].amount === null) {
                      a = 0;
                    } else {
                      let str1 = this.state.payablepaid[j].amount.toString();
                      console.log("bayo2", str1);
                      countExpense = parseFloat(str1.replaceAll(",", ""));
                      valueAddedExpenses += countExpense;
                    }
                    console.log("bayo2", valueAddedExpenses);
                  }
                  this.setState({
                    payablepaid_cost: valueAddedExpenses,
                  });
                }

                this.setState({
                  gross: this.state.operating_cost - this.state.cost_of_goods,
                });
              }
            );
          }
        )
      )
      .catch((error) => {
        alert(JSON.stringify(error));
        console.log("bayoo", error);
      });
  }

  budget() {
    let url = baseURL + `budget?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID;
    axios
      .all([axios.get(url, CONFIG)])
      .then(
        axios.spread((budgetResponse) => {
          var data = [];
          this.setState({
            isPageLoad: false,
          });

          if (budgetResponse.data.data.length > 0) {
            this.setState({
              budget: budgetResponse.data.data,
              sales: budgetResponse.data.data[0].sales,
              cost_of_goods_2: budgetResponse.data.data[0].cost_of_goods,

              profession_expe: budgetResponse.data.data[0].profession_expe,
              admin_exp: budgetResponse.data.data[0].admin_exp,
              staff_exp: budgetResponse.data.data[0].staff_exp,
              asset_imp: budgetResponse.data.data[0].asset_imp,
              finance_cost: budgetResponse.data.data[0].finance_cost,
              est_exp: budgetResponse.data.data[0].est_exp,
              other_exp: budgetResponse.data.data[0].other_exp,

              cash_bank: budgetResponse.data.data[0].cash_bank,
              account_receivable:
                budgetResponse.data.data[0].account_receivable,

              closing_stock: budgetResponse.data.data[0].closing_stock,
              other_current: budgetResponse.data.data[0].other_current,

              furniture: budgetResponse.data.data[0].furniture,
              intangible_asset: budgetResponse.data.data[0].intangible_asset,
              other_non_current: budgetResponse.data.data[0].other_non_current,

              account_payable: budgetResponse.data.data[0].account_payable,
              short_term_loans: budgetResponse.data.data[0].short_term_loans,
              short_term_related:
                budgetResponse.data.data[0].short_term_related,

              tax_payable: budgetResponse.data.data[0].tax_payable,
              other_current_libility:
                budgetResponse.data.data[0].other_current_libility,

              long_term: budgetResponse.data.data[0].long_term,
              long_term_related: budgetResponse.data.data[0].long_term_related,
              non_current_liability:
                budgetResponse.data.data[0].non_current_liability,
              share_capital: budgetResponse.data.data[0].share_capital,
              related_earnings: budgetResponse.data.data[0].related_earnings,

              direct_account: budgetResponse.data.data[0].direct_account,
              other_equities: budgetResponse.data.data[0].other_equities,
            });
          } else {
            this.setState({
              sales: 0,
              cost_of_goods_2: 0,
              profession_expe: 0,
              admin_exp: 0,
              staff_exp: 0,
              asset_imp: 0,
              finance_cost: 0,
              other_exp: 0,
              est_exp: 0,

              cash_bank: 0,
              account_receivable: 0,
              closing_stock: 0,
              other_current: 0,
              furniture: 0,
              intangible_asset: 0,
              other_non_current: 0,
              account_payable: 0,
              short_term_loans: 0,
              short_term_related: 0,
              tax_payable: 0,
              other_current_libility: 0,
              long_term: 0,
              long_term_related: 0,
              non_current_liability: 0,

              share_capital: 0,
              related_earnings: 0,
              direct_account: 0,
              other_equities: 0,
            });
          }
        })
      )
      .catch((error) => {
        console.log("bayoo", error.response);
      });
  }
  render() {
    let ranges = {
      "Today Only": [moment(this.state.start), moment(this.state.end)],
      "Yesterday Only": [
        moment(this.state.start).subtract(1, "days"),
        moment(this.state.end).subtract(1, "days"),
      ],
      "3 Days": [
        moment(this.state.start).subtract(3, "days"),
        moment(this.state.end),
      ],
      "5 Days": [
        moment(this.state.start).subtract(5, "days"),
        moment(this.state.end),
      ],
      "1 Week": [
        moment(this.state.start).subtract(7, "days"),
        moment(this.state.end),
      ],
      "2 Weeks": [
        moment(this.state.start).subtract(14, "days"),
        moment(this.state.end),
      ],
      "1 Month": [
        moment(this.state.start).subtract(1, "months"),
        moment(this.state.end),
      ],
      "1 Year": [
        moment(this.state.start).subtract(1, "years"),
        moment(this.state.end),
      ],
    };
    let local = {
      format: "DD-MM-YYYY",
      sundayFirst: false,
    };
    return (
      <div style={{ marginTop: "-20px" }}>
        {this.state.isPageLoad ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="loader">{LOADER}</div>
          </div>
        ) : (
          <Card>
            {this.state.download ? (
              <div className="d-flex justify-content-center align-items-center">
                <div className="loader">{LOADER}</div>
              </div>
            ) : (
              <CardBody>
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-6">
                            <DateTimeRangeContainer
                              ranges={ranges}
                              start={this.state.start}
                              end={this.state.end}
                              local={local}
                              applyCallback={this.applyCallback}
                            >
                              <FormControl
                                id="formControlsTextB"
                                type="text"
                                value={this.state.valuedate}
                                label="Text"
                                placeholder="Filter by Date"
                              />
                            </DateTimeRangeContainer>
                          </div>

                          <div className="col-6 float-left">
                            <button
                              className=" float-left btn btn-primary btn-sm"
                              onClick={(e) => {
                                // this.getData();
                                this.getData(
                                  moment(
                                    new Date(new Date().getFullYear(), 0, 1)
                                  ).format("YYYY-MM-DD"),
                                  moment(new Date()).format("YYYY-MM-DD")
                                );

                                this.setState({
                                  startDate: moment(
                                    new Date(new Date().getFullYear(), 0, 1)
                                  ).format("DD MMM, YYYY"),
                                  endDate: moment(new Date()).format(
                                    "DD MMM, YYYY"
                                  ),
                                  isPageLoad: true,
                                  valuedate: "This year",
                                });
                              }}
                            >
                              Clear
                            </button>
                            {/* <input id="input" type="text" readOnly /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pull-right">
                      <button
                        className="float-right btn btn-primary btn-sm"
                        onClick={() => this.exportData()}
                      >
                        Export Excel
                      </button>
                    </div>
                  </div>

                  <div id="page">
                    <br />
                    <div class="text-center" p="3">
                      <h4>{COMPANY_NAME}</h4>
                      <h3>
                        <strong>General Ledger</strong>
                      </h3>
                      <h4>
                        From {this.state.startDate} To {this.state.endDate}
                      </h4>
                      <br />
                      <br />
                      <br />
                    </div>

                    <table class="table">
                      <thead>
                        <tr>
                          <th>Account</th>
                          <th class="text-right">Debit</th>
                          <th class="text-right">Credit</th>
                          <th class="text-right">Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Accounts Payable</td>
                          <td class="text-right">
                            {formatCurrency(
                              (
                                parseFloat(this.state.TotalBillAmount1) -
                                parseFloat(this.state.TotalUnPaidBill1)
                              ).toFixed(2)
                            )}
                          </td>
                          <td class="text-right">
                            {formatCurrency(this.state.TotalBillAmount1)}
                          </td>
                          <td class="text-right">
                            {formatCurrency(this.state.TotalUnPaidBill1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Accounts Receivable</td>
                          <td class="text-right">
                            {formatCurrency(this.state.operating_cost1)}
                          </td>
                          <td class="text-right">
                            {formatCurrency(this.state.cost_of_goods_2)}
                          </td>
                          <td class="text-right">
                            {formatCurrency(this.state.operating_cost1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Advance Tax</td>
                          <td class="text-right">
                            {formatCurrency(this.state.advanceTax1)}
                          </td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThAdvanceTax1)}
                          </td>
                          <td class="text-right">
                            {formatCurrency(
                              Math.abs(
                                this.state.advanceTax1 -
                                this.state.PaidThAdvanceTax1
                              )
                            )}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Advertising And Marketing</td>
                          <td class="text-right">
                            {formatCurrency(this.state.advertisingnMarketing1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.advertisingnMarketing1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Automatic Expense</td>
                          <td class="text-right">
                            {formatCurrency(this.state.automaticExpense1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.automaticExpense1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Bad Debt</td>
                          <td class="text-right">
                            {formatCurrency(this.state.badDebt1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.badDebt1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Bank Fees and Charges</td>
                          <td class="text-right">
                            {formatCurrency(this.state.bankFeeAndCharges1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.bankFeeAndCharges1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Consultant Expense</td>
                          <td class="text-right">
                            {formatCurrency(this.state.consultantExpense1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.consultantExpense1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Cost of Goods Sold</td>
                          <td class="text-right">
                            {formatCurrency(this.state.costOfGoodsSold1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.costOfGoodsSold1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Credit Card Charges</td>
                          <td class="text-right">
                            {formatCurrency(this.state.creditCardCharges1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.creditCardCharges1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Depreciation Expense</td>
                          <td class="text-right">
                            {formatCurrency(this.state.depreciationExpense1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.depreciationExpense1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Discount</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Drawings</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThDrawings)}
                          </td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThDrawings)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Employee Advance</td>
                          <td class="text-right">
                            {formatCurrency(this.state.employeeAdvance1)}
                          </td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThEmployeeAdvance1)}
                          </td>
                          <td class="text-right">
                            {formatCurrency(
                              Math.abs(
                                this.state.employeeAdvance1 -
                                this.state.PaidThEmployeeAdvance1
                              )
                            )}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Employee Reimbursements</td>
                          <td class="text-right">
                            {formatCurrency(this.state.employeeReimbursement1)}
                          </td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThEmployeeRe1)}
                          </td>
                          <td class="text-right">
                            {formatCurrency(
                              Math.abs(
                                this.state.employeeReimbursement1 -
                                this.state.PaidThEmployeeRe1
                              )
                            )}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Furniture and Fittings</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThFurniture1)}
                          </td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThFurniture1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Income Interest</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Inventory Assets</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>IT and Internet Expenses</td>
                          <td class="text-right">
                            {formatCurrency(this.state.ITandInternetExpense1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.ITandInternetExpense1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Late Fee Charges</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Janitorial Expenses</td>
                          <td class="text-right">
                            {formatCurrency(this.state.JanitorialExpense1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.JanitorialExpense1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Lodging</td>
                          <td class="text-right">
                            {formatCurrency(this.state.LodgingExpense1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.LodgingExpense1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Meals and Entertainment</td>
                          <td class="text-right">
                            {formatCurrency(
                              this.state.MealsEntertainmentExpense1
                            )}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(
                              this.state.MealsEntertainmentExpense1
                            )}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Office and Supplies</td>
                          <td class="text-right">
                            {formatCurrency(this.state.OfficeSuppliesExpense1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.OfficeSuppliesExpense1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Other Charges</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Other Expenses</td>
                          <td class="text-right">
                            {formatCurrency(this.state.OtherExpense1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.OtherExpense1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Owner's Equity</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThOwnerEq1)}
                          </td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThOwnerEq1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Petty Cash</td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThPettyCash1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThPettyCash1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Postage</td>
                          <td class="text-right">
                            {formatCurrency(this.state.PostageExpense1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.PostageExpense1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Prepaid Expenses</td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThPrepaidExpense1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThPrepaidExpense1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Printing and Stationery</td>
                          <td class="text-right">
                            {formatCurrency(this.state.PrintingAndStationery1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.PrintingAndStationery1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Rent Expense</td>
                          <td class="text-right">
                            {formatCurrency(this.state.RentExpense1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.RentExpense1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Repair and Maintenance</td>
                          <td class="text-right">
                            {formatCurrency(this.state.RepairNMaintenance1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.RepairNMaintenance1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Salaries and Employees wages</td>
                          <td class="text-right">
                            {formatCurrency(this.state.SalariesNWages1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.SalariesNWages1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Sales</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Shipping Expenses</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">{formatCurrency(0)}</td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Telephone Expense</td>
                          <td class="text-right">
                            {formatCurrency(this.state.TelephoneExpense1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.TelephoneExpense1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Travel Expense</td>
                          <td class="text-right">
                            {formatCurrency(this.state.TravelExpense1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.TravelExpense1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Tax Payable</td>
                          <td class="text-right">
                            {formatCurrency(this.state.TaxPayable1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.TaxPayable1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>

                        <tr>
                          <td>Undeposited Funds</td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThUndeposited1)}
                          </td>
                          <td class="text-right">{formatCurrency(0)}</td>
                          <td class="text-right">
                            {formatCurrency(this.state.PaidThUndeposited1)}
                          </td>
                        </tr>
                        <td colspan="6">
                          <hr class="my-0" />
                        </td>
                      </tbody>
                    </table>
                  </div>
                </div>
              </CardBody>
            )}
          </Card>
        )}
      </div>
    );
  }
}
