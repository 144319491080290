import React from 'react';
import { baseURL, currentUserSubject } from '../../../_helpers';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { Dot } from "react-animated-dots";
import logo from '../../../shared/img/logo/3.png'
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import Alert from '../../../shared/components/Alert';

class NewPassword extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        localStorage.setItem("token", null)
        this.state = {
            email: localStorage.getItem("email"),
            password: "",
            confirm_password: "",
            showPassword: false,
            setShowPassword: false,
            channel_type: "portal",
            error: "",
            errorMsg: "",
            role: "",
            id: "",
            user: [],
            statusMessage: "",
            isShowSuccess: false,
            submitted: false,
            alert_error_color: "",
            isLoggedIn: false,
            isChecked: false,
            errorShow: false,
            successShow: false,
            statis: []
        };

    }

    handleChangePassword = event => {
        this.setState({ password: event.target.value });
    };
    handleChangeConfirmPassword = event => {
        this.setState({ confirm_password: event.target.value });
    };


    showPasswordToggle = () => {
        // eslint-disable-next-line
        if (this.state.showPassword == true) {
            this.setState({
                showPassword: false,
            })
        } else {
            this.setState({
                showPassword: true,
            })
        }
    };

    onSubmit = e => {

        e.preventDefault();
        this.setState({ isLoading: true });
        // eslint-disable-next-line
        if (this.state.email_confirm == "" || this.state.password == "") {
            this.setState({
                isLoading: false,
                error: "Password and confirm password is required",
                errorShow: true,
                submitted: true,

                alert_error_color: "alert alert-danger"
            });
        }
        else if (this.state.password != this.state.confirm_password) {
            this.setState({
                isLoading: false,
                error: "Password and confirm password must match",
                errorShow: true,
                submitted: true,

                alert_error_color: "alert alert-danger"
            });
        } else {

            let formData = {
                "password": this.state.password,
                "confirm_password": this.state.confirm_password,
            }
            console.log("DATA", formData)
            this.setState({ isLoading: true });
            axios.post(baseURL + `reset-password-save`, formData
            ).then((response) => {
                console.log("token", response.data);
                // eslint-disable-next-line
                if (response.data.status) {
                    localStorage.setItem("name", response.data.user.first_name + " " + response.data.user.last_name)
                    localStorage.setItem("user_role", response.data.user.role_id)
                    localStorage.setItem("invalid_token", response.data.token)
                    localStorage.setItem("phonemsisdn_msisdn", response.data.user.msisdn)

                    localStorage.setItem("user_id_client", response.data.user.user_id)
                    localStorage.setItem("company_name_client", response.data.user.company_name)
                    localStorage.setItem("address_client", response.data.user.postal_address)
                    window.localStorage.setItem("num", "ABC000001")
                    currentUserSubject.next(response.data);
                    window.user = response.data;
                    this.setState({
                        statusMessage: "Login Success! Redirecting....",
                        isShowError: true,
                        errorShow: false,
                        submitted: true,
                        isLoggedIn: true
                    });
                    window.setTimeout(function () {
                        window.location.href = "/verify";
                        this.setState({ isLoading: false });
                    }, 100);
                }
                else {
                    console.log("bayoo", response.data)
                    this.setState({
                        // alert_error_color: "alert alert-danger",
                        errorShow: true,
                        error: response.data.message,
                        submitted: true,
                        isLoading: false
                    }, function () {
                        console.log('data', response.data.message)
                    });
                }
            }).catch(error => {
                console.log('bayoo', error.response)
                this.setState({
                    errorShow: true,
                    error: error.response === undefined ? "Check your internet" : error.response.data.message,
                    submitted: true,
                    isLoading: false
                });
                this.setState({ password: "", email_address: this.state.email_address });
            })
        }
    };


    render() {
        const { submitted } = this.state;
        const { showPassword } = this.state;

        const { errorShow } = this.state;

        return (
            <div className="account account">

                <div className="account__wrapper">

                    <div className="account__card" >

                        <img src={logo} alt="" />

                        <Form className="form login-form" onSubmit={this.onSubmit}>
                            {submitted ? (
                                <div>
                                    {this.state.isShowError ? (
                                        <Alert color="success" className="alert--colored" >
                                            <span>
                                                {this.state.statusMessage}
                                            </span>
                                        </Alert>
                                    ) : null}
                                    {errorShow && (

                                        <div>
                                            <div
                                                color="red"
                                                style={{ fontSize: "13px", color: "red" }}>
                                                {this.state.error}
                                            </div>
                                            {this.state.errorShow ? (
                                                <div
                                                >
                                                    <p
                                                        style={{ textAlign: "left", marginLeft: "20px" }}
                                                    >
                                                        {
                                                            // <Alert color="danger" className="alert--colored" icon>
                                                            //   <span className="bold-text">{this.state.statusMessage}</span>
                                                            // </Alert>

                                                        }{" "}
                                                        {this.state.isLoggedIn ? (
                                                            <span>
                                                                <Dot>.</Dot>
                                                                <Dot>.</Dot>
                                                                <Dot>.</Dot>
                                                            </span>
                                                        ) : null}
                                                    </p>

                                                </div>
                                            ) : null}
                                        </div>
                                    )}

                                </div>
                            ) : null}
                            <div className="form__form-group"><br></br>
                                <span className="form__form-group-label">Email Address</span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <AlternateEmailIcon />
                                    </div>
                                    <Form.Control
                                        autoFocus
                                        type="text"
                                        name="email_address"
                                        readonly
                                        style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                                        placeholder="Email Address"
                                        className="input-without-border-radius"
                                        value={this.state.email_address}
                                        onChange={this.handleChangeEmail}
                                    />

                                </div>
                                <span className="form__form-group-label">Password</span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <KeyVariantIcon />
                                    </div>
                                    <Form.Control
                                        value={this.state.password}
                                        placeholder="Password"
                                        name="password"
                                        style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                                        type={showPassword ? 'text' : 'password'}
                                        className="input-without-border-radius"
                                        onChange={this.handleChangePassword}
                                    />
                                    <button
                                        type="button"
                                        className={`sm form__form-group-button${showPassword ? 'active' : ''}`}
                                        onClick={this.showPasswordToggle}
                                    >
                                        <EyeIcon />
                                    </button>
                                </div>
                                <span className="form__form-group-label">Confirm Password</span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <KeyVariantIcon />
                                    </div>
                                    <Form.Control
                                        value={this.state.confirm_password}
                                        placeholder="Confirm Password"
                                        name="confirm-password"
                                        style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                                        type={showPassword ? 'text' : 'password'}
                                        className="input-without-border-radius"
                                        onChange={this.handleChangeConfirmPassword}
                                    />
                                    <button
                                        type="button"
                                        className={`sm form__form-group-button${showPassword ? 'active' : ''}`}
                                        onClick={this.showPasswordToggle}
                                    >
                                        <EyeIcon />
                                    </button>
                                </div>

                            </div>

                            <div className="form__form-group">
                                <br></br>
                                <br></br>
                                {
                                    <Button className="account__btn" style={{ color: "" }} type='submit' outline color="primary"> {
                                        this.state.isLoading ? "Please wait..." : "Reset"
                                    }</Button>
                                }
                            </div>
                        </Form>

                    </div>
                </div>
            </div>
        );
    }
}

export default NewPassword;
