import React, { PureComponent } from 'react';
import SearchIcon from 'mdi-react/SearchIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import classNames from 'classnames';
import CountdownTimer from "react-component-countdown-timer";

export default class TopbarSearch1 extends PureComponent {
  constructor() {
    super();
    this.state = { inputOpen: false };
  }

  onInputOpen = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ inputOpen: !prevState.inputOpen }));
  };

  render() {
    const { inputOpen } = this.state;
    const searchClass = classNames({
      'topbar__search-field': true,
      'topbar__search-field--open': inputOpen,
    });

    return (
      <form className="topbar__search">
        <div style={{ color: "white", fontSize: "16px", marginRight: "20px" }}></div>

      </form>
    );
  }
}
