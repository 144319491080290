import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MdCancel as DeleteIcon } from 'react-icons/md';
import style_business from './LineItem.module.scss';
import styles_ngo from './NGOLineItem.module.scss';
import moment from 'moment';

import axios from 'axios';
import { baseURL } from '../../_helpers';
import { BRANCH_ID, BUSINESS_TYPE, CURRENCY_AGAINST_KE, successToast, ToastTable, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import Select from 'react-select';
import Modal from 'react-modal';
import './style.css';
import CreatableSelect from 'react-select/creatable';

const options = [
  { value: '14', label: 'VAT (14%)' },
  { value: '2', label: 'KRA (2%)' },
];
class LineItem extends Component {
  constructor(props) {
    super(props);
    this._onButtonClick = this._onButtonClick.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModal1 = this.toggleModal1.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.selectRef = React.createRef();


    this.state = {
      itemValue: true,
      isLoading: false,
      isOpen1: false,
      isOpen12221: false,
      isOpenItem: false,
      showComponent: false,
      menuIsOpen: true,
      quantity: 0,
      item_id: 0,
      isOpenss: false,
      isLoading_tax: false,
      selectedValue: '',
      selectedValue2: '',
      taxValue: [{ label: 'Tax', value: '0' }]
    };
    localStorage.setItem('valuestax', 0);

    this.inputRef = React.createRef();
  }


  handleChangeTax = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChange = value => {



    if (value != null) {

      // alert(JSON.stringify(value.item_id))   

      this.setState(
        {
          selectedValue: value.label.toString(),
          selectedValueQuantity: value.quantity === undefined ? "1" : value.quantity.toString(),
          isOpenItem: false,
          rate: value.rate.toString(),
          item_id: value.item_id,
          itemvaluess: { value: value.value.toString(), label: value.label.toString() },
          isOpen: false
        },
        function () {
          this.setNativeValue(this.myinput5, this.state.selectedValue.toString());
          this.myinput5.dispatchEvent(new Event('input', {
            bubbles: true,
            cancelable: true,
          }))

          this.setState(
            {
              selectedValue: value.label.toString(),
              selectedValueQuantity: value.quantity === undefined ? "1" : value.quantity.toString(),
              isOpenItem: false,
            }, function () {
              this.setNativeValue(this.myinput2, value.rate.toString());
              this.myinput2.dispatchEvent(new Event('input', {
                bubbles: true,
                cancelable: true
              }));
            })

        }
      );


      this.setNativeValue(this.iteminput, value.item_id);
      this.iteminput.dispatchEvent(new Event('input', {
        bubbles: true,
        cancelable: true,
      }))





    }
    else {
      this.setState(
        {
          itemvaluess: { value: "", label: "Select item" },
        },
        function () {
          this.setNativeValue(this.myinput, '');
          this.myinput.dispatchEvent(new Event('input', { bubbles: true }));
        });

    }


  };


  // handleChangeValue = e => {

  // }

  handleChange2 = value => {
    if (value != null) {
      this.setState(
        {
          selectedValue2: value.label.toString(),
          selectedValue23: value.value.toString(),
          isOpen1: false,
          isOpen12221: false,
          taxValue: [{ label: value.label.toString(), value: value.value.toString() }]
        },
        function () {

          this.setNativeValue(this.myinput1, this.state.selectedValue23);

          this.myinput1.dispatchEvent(new Event('input', { bubbles: true }));
        }
      );
    } else {
      this.setState({
        taxValue: [{ label: 'Tax', value: '0' }],
      })
      this.setNativeValue(this.myinput1, '');
      this.myinput1.dispatchEvent(new Event('input', { bubbles: true }));
    }
  };


  setNativeValued(element, value) {
    let lastValue = element.value;
    element.value = value;
    let event = new Event("input", { target: element, bubbles: true });
    // React 15
    event.simulated = true;
    // React 16
    let tracker = element._valueTracker;
    if (tracker) {
      tracker.setValue(lastValue);
    }
    element.dispatchEvent(event);
  }



  setNativeValue(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value);
    } else {
      valueSetter.call(element, value);
    }
  }


  // setNativeValue(element, value) {
  //   const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
  //   const prototype = Object.getPrototypeOf(element);
  //   const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

  //   if (valueSetter && valueSetter !== prototypeValueSetter) {
  //     prototypeValueSetter.call(element, value);
  //   } else {
  //     valueSetter.call(element, value);
  //   }
  // }

  toggleModal = e => {
    this.setState({
      isOpen: true,
      isOpenItem: true,
    });
  };

  toggleModal1 = e => {
    this.setState({
      isOpen1: true,
      isOpen12221: true
    });
  };
  closeModal = e => {
    this.setState({
      isOpenss: false,
    });
  };

  check = e => {
    // eslint-disable-next-line
    if (this.state.selectedValue == '') {
      alert('Please select an item');
    } else {
      this.setState({
        isOpen: false,
      });
    }
  };


  check2 = e => {
    // eslint-disable-next-line
    if (this.state.selectedValue2 == '') {
      alert("You haven't select any tax");
    } else {
      this.setState({
        isOpen1: false,
      });
    }
  };
  closeModal1 = e => {
    this.setState({
      isOpen1: false,
    });
  };

  loadOptions = inputValue => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: TOKEN,
      },
    };
    this.setState({
      isLoading: true,
    });
    var encodeURISafe = require('encodeuri-safe');
    // alert()
    let url = `${baseURL}item?branch_id=${BRANCH_ID}&&client_id=${USER_ID}&filter_value=${encodeURISafe.encodeURIComponent(inputValue)}`

    // let url =
    //   baseURL + `item?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&filter_value=${inputValue}`;
    axios.get(url, axiosConfig).then(itemResponse => {
      this.setState(
        {
          item: itemResponse.data.data.filter(item => item.status === 1),
        },
        function () {
          // if (this.state.item.length > 0) {
          this.setState({
            isLoading: false,
            bayo: this.state.item &&
              (this.state.item.length > 0 || this.state.item.length === 0) &&
              this.state.item.map((countyItem, i) => ({
                label: countyItem.item_name,
                value: countyItem.id,
                rate: countyItem.rate,
                item_id: countyItem.id,
                quantity: countyItem.quantity,

              })),
          });
          // } else {
          //   // this.handleCreate(inputValue)
          // }
        }
      );
    });
  };

  componentDidMount() {

    const {
      // index,
      name,
      // description,
      // discount_percentage,
      // tax_percentage,
      // quantity,
      // price,
    } = this.props;
    // alert (this.props.name);
    this.setState({
      selectedValue: this.props.name,
      isOpenss: false,
      itemvaluess: { value: this.props.id, label: this.props.name },
      taxValue: { label: this.props.tax_percentage, value: this.props.tax_percentage },
      isLoading: true,
    });


    if (this.state.isOpen) {
      document.body.style.overflow = 'hidden';
    }
    Modal.setAppElement('body');
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: TOKEN,
      },
    };
    let url = baseURL + `item?branch_id=${BRANCH_ID}&&client_id=` + USER_ID;
    let urltax = baseURL + `tax?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID;

    axios
      .all([
        axios.get(url, axiosConfig),
        axios.get(urltax, axiosConfig),
      ])
      .then(
        axios.spread((itemResponse, taxResponse) => {
          // alert(JSON.stringify(itemResponse))
          this.setState(
            {
              item: itemResponse.data.data.filter(item => item.status === 1),
              tax: taxResponse.data.data
            },
            function () {
              this.setState({
                isLoading: false,
                bayo: this.state.item &&
                  (this.state.item.length > 0 ||
                    this.state.item.length === 0) &&
                  this.state.item.map((countyItem, i) => ({
                    label: countyItem.item_name,
                    value: countyItem.id,
                    rate: countyItem.rate,
                    item_id: countyItem.id,
                    quantity: countyItem.quantity
                  })),
                taxparams: this.state.tax &&
                  (this.state.tax.length > 0 ||
                    this.state.tax.length === 0) &&
                  this.state.tax.map((countyItem, i) => ({
                    label: countyItem.tax_name + "(" + countyItem.tax_value + "%)",
                    value: countyItem.tax_value
                  })),


              });

              localStorage.setItem(
                'DATAARRAY',
                JSON.stringify(this.state.item)
              );
            }
          );
        })
      )
      .catch(error => {
        console.log('bayoo', error.response);
      });
  }

  handleCreate = value => {
    const newValue = { value: value, label: value };
    // window.location.href = "/item-create"
    this.setState(
      {
        selectedValue: value.toString(),
        selectedValueQuantity: "1",
        isOpenItem: false,
        itemvaluess: newValue,
        isOpen: false
      },

      function () {
        this.setNativeValue(this.myinput5, this.state.selectedValue);
        this.myinput5.dispatchEvent(new Event('input', { bubbles: true }));
      }
    );


    let formData = {
      "client_id": USER_ID,
      "quantity": 1000000,
      "rate": 1000,
      "measurements": "",
      "reference": "",
      "check_expiry": 1,
      "branch_id": BRANCH_ID,
      "buying_price": 1000,
      "expiry_date": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      "item_name": value.replaceAll("'", ""),
    }
    let url = baseURL + `item`
    axios.post(url, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': TOKEN
      }
    }).then((response) => {

      successToast("Item Created Successfully");

    }).catch(error => {
      console.log("ERROR")
    })

  };


  handleCreateTax = value => {

    this.setState({

      isOpenss: true
    })

  };




  onSubmit(e) {

    e.preventDefault();

    let formData = {
      "created_by": USER_ID,
      "tax_name": this.state.tax_name,
      "tax_value": this.state.tax_value
    }
    console.log("DATA", JSON.stringify(formData))
    this.setState({ isLoading_tax: true });
    axios.post(baseURL + `tax`, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': TOKEN
      },
    })
      .then((response) => {
        console.log('bayoo', response.data)
        if (response.data.status) {

          successToast("Tax Added Successfully");
          this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading_tax: false });
          window.scrollTo(0, 0)
          this.closeModal();
          this.componentDidMount();

          window.setTimeout(() => {
            this.setState({ isShowError: false, sales_person: "" });
          });
        } else {
          this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading_tax: false });
        }
      })
      .catch((error) => {
        console.log('bayoo', error)
        this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading_tax: false });
      })
  }


  _onButtonClick() {
    // window.location.href = "/log_in";
  }

  render = () => {
    const {
      index,
      name,
      description,
      item_id,
      reference,
      discount_percentage,
      tax_percentage,
      quantity,
      price,
    } = this.props;

    const theme = (theme: Theme) => ({
      ...theme,
      spacing: {
        ...theme.spacing,
        controlHeight: 30,
        baseUnit: 1,
      }
    });

    let styles;
    BUSINESS_TYPE === 'business' ?
      styles = style_business
      : styles = styles_ngo


    // name = this.state.selectedValue;
    // let item = JSON.parse(localStorage.getItem("DATAARRAY"))
    return (
      <div>

        {ToastTable()}
        <Modal
          isOpen={this.state.isOpenss}
          onRequestClose={e => {
            this.closeModal(e);
          }}
          contentLabel="My dialog"
          className="mymodal"
          onAfterOpen={() => {
            document.body.style.overflow = 'hidden';
          }}
          onAfterClose={() => {
            document.body.removeAttribute('style');
          }}
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          <div className="row">

            <div className="col-md-6">
              <h4><b>Add Tax</b></h4>
            </div>

          </div>
          <form className="form" >

            <div className="col-md-12"><br />
              <div className="form-group">
                <input id="input" type="text" className="form-control"
                  name="tax_name" required placeholder='Tax Name'
                  value={this.state.tax_name} onChange={this.handleChangeTax} />
              </div>
              <br />
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <input id="input" type="number" className="form-control"
                  name="tax_value" required placeholder='Tax Value in Percentage)'
                  value={this.state.tax_value} onChange={this.handleChangeTax} />
              </div>
            </div>

            <br></br>

            <div className="col-md-12"><br />
              <div className="form-group">
                <button type="submit" color="primary" onClick={this.onSubmit} outline className="btn btn-primary btn-sm pull-right" >
                  {this.state.isLoading_tax ? "Please Wait..." : "Save"}

                </button> &nbsp;&nbsp;&nbsp;

              </div>
            </div>
          </form>

          <br />

          <div className="col-md-12 text-center">
            <button
              onClick={this.closeModal}
              className="btn btn-danger btn-sm"
            >
              close
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.isOpen21}
          onRequestClose={e => {
            this.closeModal1(e);
          }}
          contentLabel="My dialog"
          className="mymodal"
          onAfterOpen={() => {
            document.body.style.overflow = 'hidden';
          }}
          onAfterClose={() => {
            document.body.removeAttribute('style');
          }}
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          <div className="row">
            <div className="col-md-6">
              <h4><b>Select Tax</b></h4>
            </div>
            <div className="col-md-6" />
          </div>

          <br />
          <Select
            isClearable
            options={options}
            onInputChange={this.loadOptions}
            placeholder="Search Tax"
            autosize={true}
            onChange={this.handleChange2}
            value={this.state.taxValue}
            isLoading={this.state.isLoading}
            menuPortalTarget={document.body}
            name="name"
          />
          <br />
          <div className="row">
            <div className="col-md-12 text-center">
              <button
                onClick={this.closeModal1}
                className="btn btn-danger btn-sm"
              >
                close
              </button>
            </div>
          </div>
        </Modal>
        <div className={styles.lineItem}>

          <div>{index + 1}</div>

          {BUSINESS_TYPE === 'business' ?
            <div >
              {/* {this.state.isOpenItem === false ? */}
              <div style={{ width: '100%' }}>
                <CreatableSelect
                  options={this.state.bayo}
                  onInputChange={this.loadOptions}
                  value={this.state.itemvaluess}
                  autosize={true}
                  remove
                  placeholder="Select or type to add "
                  theme={theme}
                  onChange={e => {

                    this.handleChange(e);

                    // this.handleChangeValue(e);
                  }}
                  onCreateOption={this.handleCreate}
                  isLoading={this.state.isLoading}
                  menuPortalTarget={document.body}
                  name="namffe"
                />
              </div>


              <input
                ref={input => (this.myinput5 = input)}
                name="name"
                placeholder="Item Name"
                className="form-control"
                type="text"
                id="item_name"
                style={{ fontSize: "14px" }}
                onClick={() => this.setState({
                  isOpenItem: true
                })}
                readOnly
                hidden={true}
                visibility="hidden"
                required
                value={name}
                onChange={this.props.changeHandler(index)}
              />

              <input
                // ref={input => (this.myinput5 = input)}
                name="item_id"
                type="number"
                ref={e => this.iteminput = e}
                id="item_id"
                visibility="hidden"
                hidden={true}
                value={item_id}
                onChange={this.props.changeHandler(index)}
              />


            </div>

            : null}


          <div>


            <textarea
              name="description"
              placeholder="(Optional)"
              className="form-control"
              style={{ fontSize: "14px" }}
              type="text"
              value={description}
              onChange={this.props.changeHandler(index)}
            />
          </div>

          {/* <div className={styles.currency}>
            <input
              name="reference"
              placeholder="ref"
              className="form-control"
              // maxLength={2}
              style={{ fontSize: "14px" }}
              type="text"
              hidden={true}
              visibility="hidden"
          
              value={reference}
              onChange={this.props.changeHandler(index)}
              onFocus={this.props.focusHandler}
            />
          </div> */}


          {BUSINESS_TYPE === 'business' ?
            <div>
              <input
                name="quantity"
                type="number"
                className="form-control"
                style={{ fontSize: "14px" }}
                step="1"
                maxLength="1"
                value={quantity}
                max={this.state.selectedValueQuantity}
                onChange={this.props.changeHandler(index)}
                onFocus={this.props.focusHandler}
              />


            </div>
            : null}
          <div className={styles.currency}>
            <input
              name="price"
              ref={input2 => (this.myinput2 = input2)}
              className="form-control"
              type="number"
              id="price"
              step="0.01"
              style={{ fontSize: "14px" }}
              min="0.00"
              max="9999999.99"
              value={price}
              onChange={this.props.changeHandler(index)}
              onFocus={this.props.focusHandler}
            />
          </div>


          {BUSINESS_TYPE === 'business' ?
            <div>
              {/* {this.state.isOpen12221 === true ? */}
              <div style={{ width: '100%' }}>

                <CreatableSelect
                  isClearable
                  options={this.state.taxparams}
                  placeholder="Tax"
                  placeholder="Select or type to add "
                  noOptionsMessage={() => 'Please set your tax on setting tab'}
                  onCreateOption={this.handleCreateTax}
                  onChange={this.handleChange2}
                  openOnFocus={true}
                  theme={theme}
                  value={this.state.taxValue}
                  isLoading={this.state.isLoading}
                  menuPortalTarget={document.body}
                  name="name"
                />
              </div>


              <input
                name="tax_percentage"
                placeholder="Tax %"
                className="form-control"
                visi
                style={{ fontSize: "14px" }}
                type="text"
                hidden={true}
                ref={input => (this.myinput1 = input)}
                value={tax_percentage}
                readOnly
                onClick={e => {
                  this.toggleModal1(e);
                }}
                onChange={this.props.changeHandler(index)}
              />

            </div>
            : null
          }
          {BUSINESS_TYPE === 'business' ?
            <div className={styles.currency}>

              {this.props.showtax === true ?
                this.props.currencyFormatter(
                  (((quantity * price) - (discount_percentage / 100 * (quantity * price))))
                ) : this.props.currencyFormatter((quantity * price) / (1 + (tax_percentage / 100)))}
            </div>
            : null
          }

          <div>
            <button
              type="button"
              className={styles.deleteItem}
              onClick={this.props.deleteHandler(index)}
            >
              <DeleteIcon size="1.25em" />
            </button>
          </div>
        </div>

      </div>
    );
  };
}

export default LineItem;

LineItem.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  selected: PropTypes.string,
  reference: PropTypes.string,
  description: PropTypes.string,
  item_id: PropTypes.number,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
