import React from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/exports';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import CreatableSelect from 'react-select/creatable';
import Alert from '../../shared/components/Alert';
import * as moment from 'moment';
import { Customers } from '../Customers/customer_view';
require('../../_assets/jss/sms_counter.js')

class EditAddress extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {

            isLoading: false,
            isShowError: false,
            statusMessage: '',
            showError: false,
            isChecked: false,
            type: [{
                name: 'Cash.'
            }, {
                name: 'Cheque'
            },
            {
                name: 'Credit Card'
            },
            {
                name: 'Debit Card'
            },
            {
                name: 'Mpesa'
            }],

            priority: [{
                name: 'Print Later'
            }, {
                name: 'Send Later'
            },
            {
                name: 'None'
            }],
            terms3: [
                {
                    label: 'Due on Receipt',
                    value: 'Due on Receipt',
                },
                {
                    label: 'Due in 15 days',
                    value: 'Due in 15 days',
                },
                {
                    label: 'Due in 30 days',
                    value: 'Due in 30 days',
                },
            ],

        }
    }

    handleChangeRegion = (event) => {
        this.setState({ selectedRegion: event.target.value });
    };

    onSubmit(e) {
        e.preventDefault();
        let formData = {

            "street": this.state.street,
            "city_town": this.state.city_town,
            "state_province": this.state.state_province,
            "country": this.state.country,
            "street1": this.state.street1,
            "city_town1": this.state.city_town1,
            "state_province1": this.state.state_province1,
            "country1": this.state.country1,
            "notes": this.state.notes,
            "tax_info": this.state.tax_info,
            "payment_method": this.state.payment_method,
            "delivery_method": this.state.delivery_method,
            "terms": this.state.terms,
            "out_of_balance": this.state.out_of_balance,
            "customer_id": localStorage.getItem("customer_id_moreinfo")
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.put(baseURL + `customermore`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        })
            .then((response) => {
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                    window.scrollTo(0, 0)
                    window.setTimeout(() => {
                        this.setState({ isShowError: false });
                        this._onButtonClick();
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                    window.scrollTo(0, 0)

                }
            })
            .catch((error) => {
                window.scrollTo(0, 0)
                console.log('bayoo', error.response)
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
            })
    }

    async componentDidMount() {
        $('#message').countSms('#sms-counter');
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `customermore?customer_id=` + localStorage.getItem("customer_id_moreinfo")
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((customerResponse) => {
            this.setState({
                customer: customerResponse.data.data,
                isLoading: false,
            },
                function () {
                    this.setState({

                        street: this.state.customer[0].street,
                        city_town: this.state.customer[0].city_town,
                        state_province: this.state.customer[0].state_province,
                        country: this.state.customer[0].country,
                        notes: this.state.customer[0].notes,
                        tax_info: this.state.customer[0].tax_info,
                        payment_method: this.state.customer[0].payment_method,
                        delivery_method: this.state.customer[0].delivery_method,
                        terms: this.state.customer[0].terms,
                        out_of_balance: this.state.customer[0].out_of_balance
                    })
                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    onSelectChange = value => {
        this.setState({ entity_id: value.value.toString() });
    };
    handleChangeMessage = (event) => {
        this.setState({ notes: event.target.value }, function () {

        });
        //alert(event.target.value)
    };


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
        });
    }

    changeStatus() {
        this.setState({
            isShowError: false
        });
    }
    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        }, function () {
            if (this.state.isChecked === true) {
                this.setState({
                    street1: this.state.street,
                    city_town1: this.state.city_town,
                    state_province1: this.state.state_province,
                    country1: this.state.country
                })
            } else {
                this.setState({
                    street1: "",
                    city_town1: "",
                    state_province1: "",
                    country1: ""
                })
            }
        });
    }

    handleChangeRegion = (event) => {
        this.setState({ payment_method: event.target.value });
    };


    handleChangeType = value => {

        if (value != null) {
            this.setState(
                {
                    terms: value.value.toString(),
                });
        }
        else {
            this.setState(
                {
                    terms: "",
                });
        }
    };


    handleChangeDelivery = (event) => {
        this.setState({ delivery_method: event.target.value });
    };

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <Customers /> : null
                        }
                    </div>
                )}

                {!hideComponent && (
                    <div>
                        <Card>
                            <CardBody>
                                <div className="row">

                                    <div className="col-md-6">
                                        <h4><b>Edit Customer More Info</b></h4><br />
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/customers">
                                            <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                Customers
                                            </Button>
                                        </Link>

                                    </div>
                                </div>

                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>


                                </div> : null}<br></br>

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div>

                                ) : null}<br></br>
                                <form className="form" onSubmit={this.onSubmit}>
                                    <div className="col-md-12">
                                        <h4><b>Billing Address</b></h4>
                                    </div>
                                    <div className="col-md-12 row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Street</label>
                                                <input id="input" type="text" className="form-control input-md"
                                                    name="street" placeholder="Street"
                                                    value={this.state.street} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">City/Town</label>
                                                <input id="input" type="text" className="form-control"
                                                    name="city_town" placeholder="City/Town"
                                                    value={this.state.city_town} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">State/County/Province</label>
                                                <input id="input" type="text" className="form-control" name="state_province"
                                                    required placeholder="State/County"
                                                    value={this.state.state_province}
                                                    onChange={this.handleChange} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Country</label>
                                                <input id="input" type="text" className="form-control"
                                                    name="country" required placeholder="Country"
                                                    value={this.state.country} onChange={this.handleChange} />
                                                <br />
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-12">
                                        <br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">

                                                    <h4><b>Notes</b></h4>
                                                </div>
                                                <div className="col-md-8">
                                                    <textarea name="message" style={{
                                                        paddingTop: '20px',
                                                        borderRadius: "5px", borderColor: "hsl(0,0%,80%)"
                                                    }}
                                                        value={this.state.notes}
                                                        onChange={this.handleChangeMessage} className="col-md-12"
                                                        placeholder="Hint: Enter Notes here"
                                                        id="message" cols="col-md-1" rows="1"></textarea>
                                                </div>
                                                <div className="col-md-2">
                                                    <ul id="sms-counter">
                                                        <li>Length: <span class="length"></span></li>
                                                        {/* <li>SMS: <span class="messages"></span></li> */}
                                                        <li>Characters: <span class="remaining"></span>/<span class="per_message"></span></li>
                                                        {/* <li>Remaining: </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>

                                    <div className="col-md-12"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <h4 ><b>Tax Info</b></h4>
                                                </div>
                                                <div className="col-md-8">
                                                    <input id="input" type="type" className="form-control"
                                                        name="tax_info" placeholder='Tax Info'
                                                        value={this.state.tax_info} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <br />
                                        <h4 ><b>Payment</b></h4>
                                    </div>

                                    <div className="col-md-8 offset-2">
                                        <Form.Group className="Focus-line" >
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Preferred Delivery Method</Form.Label>
                                                </div>
                                                <div className="col-md-10">
                                                    <Form.Control
                                                        as="select"
                                                        className="form-control input-md"
                                                        placeholder="Select Delivery Method"
                                                        value={this.state.delivery_method}
                                                        onChange={this.handleChangeDelivery}
                                                        id="county_name"
                                                        option={this.delivery_method}>
                                                        <option>Select Delivery Method</option>
                                                        {
                                                            this.state.priority && this.state.priority.length > 0 &&
                                                            this.state.priority.map((regionItem, i) =>
                                                                <option key={i}
                                                                    value={regionItem.name}>{regionItem.name}</option>)
                                                        }
                                                    </Form.Control>
                                                </div>
                                            </div>

                                        </Form.Group>
                                    </div>

                                    <div className="col-md-8 offset-2">
                                        <Form.Group className="Focus-line" >
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Terms</Form.Label>
                                                </div>
                                                <div className="col-md-10">
                                                    <CreatableSelect
                                                        isClearable
                                                        options={this.state.terms3}
                                                        // onInputChange={this.loadOptions}
                                                        placeholder="Select terms or enter custom date"
                                                        autosize={true}
                                                        // value={this.state.terms}

                                                        onChange={this.handleChangeType}
                                                        isLoading={this.state.isLoading}
                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        menuPortalTarget={document.body}
                                                        name="namffe"
                                                    /><br />
                                                </div>
                                            </div>

                                        </Form.Group>
                                    </div>

                                    <div className="col-md-8 offset-2"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Starting Balance <br />{moment.utc(Date.now()).format('DD MMM, YYYY')}</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="first_name" className="form-control"
                                                        name="out_of_balance" placeholder='Starting Balance'
                                                        value={this.state.out_of_balance} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-12"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Link to="/customers">
                                                        <Button outline color="primary" onClick={this._onButtonClick}>
                                                            Cancel
                                                        </Button>
                                                    </Link>

                                                </div>
                                                <div className="col-md-8">
                                                    <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                        {this.state.isLoading ? "Please Wait..." : "Save"}

                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </CardBody>
                        </Card>
                    </div>
                )}
            </div>
        )
    }
}
export default withTranslation('common')(EditAddress);
