import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { BRANCH_ID, CONFIG, CURRENCY, EMAIL_ADDRESS, INVOICE_APPROVAL, LOADER, PROFILE_URL, TOKEN, USER_ID } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import Invoice from './Invoice';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import { saveInvoiceView } from './pdfview';
import Invoice_Edit from './Invoice edit';
import { saveInvoicePDF } from './PDFService';
import Loader from "react-loader-spinner";
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";
import { Form } from 'react-bootstrap';
import Select from 'react-select';

export default class RecurringView extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);

        this.onEditInvoice2 = this.onEditInvoice2.bind(this);

        this.columns = [
            {
                key: "customer_display_name",
                TrOnlyClassName: 'tsc',
                text: "Company Name",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "invoice_no",
                TrOnlyClassName: 'tsc',
                text: "Invoice#",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "frequency_type",
                TrOnlyClassName: 'tsc',
                text: "Frequency",
                className: "tsc",
                altrueign: "left"
            },

            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Last Invoice Date",
                className: "tsc",
                align: "left"
            },

            {
                key: "dates_next",
                TrOnlyClassName: 'tsc',
                text: "Next Invoice Date",
                className: "tsc",
                align: "left"
            },
            {
                key: "status",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.status === 0 ?
                                <div>
                                    <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                        InActive
                                    </span>
                                </div>
                                : null}
                            {record.status === 1 ?
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px" }}>
                                    Active
                                </span>
                                : null}
                        </Fragment >
                    );
                }
            },

            {
                key: "due_amount",
                TrOnlyClassName: 'tsc',
                text: "Due Amount",
                className: "tsc",
                align: "left"
            },
            // {
            //     key: "date_modified2",
            //     TrOnlyClassName: 'tsc',
            //     text: "Last Updated",
            //     className: "tsc",
            //     align: "left"
            // },

            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-success  btn-sm"
                                title="View"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.toggleModalMore(record) }} >
                                More <i className="fa fa-plus"></i>
                            </button>

                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            button: {
                excel: true,
                csv: true
            },
            filename: "recurring_invoice",
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Invoice no...",
                no_data_text: "No recurring invoices currently",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showComponent: false,
            onEdit: false,
            returnHome: false,
            _onShow: false,
            invoice_date: new Date(),
            loaded: true,
            isOpenFrequncy: false,
            isOpenMore: false,
            hideComponent: false,
            data: [],
            frequency_load: [
                {
                    label: 'Daily',
                    value: 'Daily',
                },
                {
                    label: 'Weekly',
                    value: 'Weekly',
                },
                {
                    label: 'After 2 Weeks',
                    value: 'After 2 Weeks',
                },
                {
                    label: 'Monthly',
                    value: 'Monthly',
                },
                {
                    label: 'After 2 Months',
                    value: 'After 2 Months',
                },
                {
                    label: 'After 3 Months',
                    value: 'After 3 Months',
                },
                {
                    label: 'After 6 Months',
                    value: 'After 6 Months',
                },
                {
                    label: 'Yearly',
                    value: 'Yearly',
                },
                {
                    label: 'After 2 Years',
                    value: 'After 2 Years',
                },

            ],
        };
    }

    toggleModalMore(record) {
        this.setState({
            isOpenMore: true,
            moreopen: record
        });

    };


    async handleOnClick1(record) {
        if (await confirm("Are you sure you want to disable this reccuring invoice?")) {
            this.onEditInvoice(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }


    goEditInvoice = (e) => {
        { localStorage.setItem("Invoice_No_Item", e.invoice_no) }

        window.location.href = "/invoice-edit";

    }

    async handleOnClick3(record) {
        if (await confirm("Are you sure you want to enable this recurring invoice?")) {

            let formData = {
                invoice_no: record.invoice_no,
                frequecy: record.frequecy,
                frequency_type: record.frequency_type
            };
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": TOKEN
                }
            };

            // alert(JSON.stringify(formData))

            this.setState({
                submit_frequency: true
            })
            axios.put(baseURL + `recurringinvoice2`,
                formData, axiosConfig).then(response => {
                    if (response.data.status) {
                        this.setState({ statusMessage: response.data.message, submit_frequency: false, isShowError: true, isLoading: false }, function () {
                            window.setTimeout(() => {
                                this.getData();
                                this.closeModalFrequency();
                                this.setState({ isShowError: false })
                            }, 2000);
                        });
                    } else {
                        this.setState({ statusMessage: response.data.message, isShowError: false, submit_frequency: false, showError: true, isLoading: false });
                        window.setTimeout(() => {
                            this.getData();
                            this.closeModalFrequency();
                            this.setState({ isShowError: false })
                        }, 2000);
                    }
                }, error => {
                    this.setState({ statusMessage: JSON.stringify(error), isShowError: false, submit_frequency: false, showError: true, isLoading: false });
                    window.setTimeout(() => {
                        this.getData();
                        this.closeModalFrequency();
                        this.setState({ isShowError: false })
                    }, 2000);
                });


            // this.onEditInvoice2(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }



    async handleOnClick2(record) {
        this.setState({
            isOpenFrequncy: true,
            frequency_type: record.frequency_type,
            frequency: moment.unix(record.frequecy).format('YYYY-MM-DD HH:mm:ss'),
            invoice_id: record.invoice_no,
            frequencyValue: [{ label: record.frequency_type, value: record.frequency_type }]

        })
        // this.onEditInvoice2(record)

    }
    onEditInvoice2() {

        // alert(this.state.frequency)
        let formData = {
            invoice_no: this.state.invoice_id,
            frequecy: (new Date(this.state.frequency).getTime()) / 1000,
            frequency_type: this.state.frequency_type
        };
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };

        // alert(JSON.stringify(formData))

        this.setState({
            submit_frequency: true
        })
        axios.put(baseURL + `recurringinvoice2`,
            formData, axiosConfig).then(response => {
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, submit_frequency: false, isShowError: true, isLoading: false }, function () {
                        window.setTimeout(() => {
                            this.getData();
                            this.closeModalFrequency();
                            this.setState({ isShowError: false })
                        }, 2000);
                    });
                } else {
                    this.setState({ statusMessage: response.data.message, isShowError: false, submit_frequency: false, showError: true, isLoading: false });
                    window.setTimeout(() => {
                        this.getData();
                        this.closeModalFrequency();
                        this.setState({ isShowError: false })
                    }, 2000);
                }
            }, error => {
                this.setState({ statusMessage: JSON.stringify(error), isShowError: false, submit_frequency: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.closeModalFrequency();
                    this.setState({ isShowError: false })
                }, 2000);
            });
    }


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {

        if (PROFILE_URL == "null") {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
        } else {
            localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image;
            img.crossOrigin = "*"
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
                canvas.toDataURL('image/jpeg', 1.0)
                var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
                localStorage.setItem("image_urls", fullQuality)
            };
            img.src = `${PROFILE_URL}?canvas=true`;
        }
        this.getData();
    }

    getData = async (queryString = "") => {


        // alert("here")
        let footer;
        // const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
        //     if (response.data.data.length > 0) {
        //         footer = { footer: response.data.data[0].footer_notes };
        //     } else {
        //         footer = { footer: "" };
        //     }
        // }).catch(error => {
        //     footer = { footer: "" };
        // })

        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `invoicerecurring?branch_id=${BRANCH_ID}&&created_by=` + USER_ID + "&&" + queryString
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            this.setState({
                invoice: invoiceResponse.data.data,
            }, function () {
                var data = [];
                this.setState({
                    total: invoiceResponse.data.total
                })
                let datas = JSON.parse(localStorage.getItem("INVOICE_CREATEPEAK"))
                // let editedInvoice = [];



                var data3 = [];
                if (datas != null) {


                    let datasinvoice = JSON.parse(localStorage.getItem("INVOICE_SENDEMAIL"))
                    let innvoiceNo = { invoice_no: invoiceResponse.data.data[0].invoice_no };

                    let customer_email_address = { customer_email_address: EMAIL_ADDRESS };

                    let customer_email = { customer_email: datasinvoice.customer_email };

                    if (invoiceResponse.data.data[0].approved == 1) {
                        saveInvoicePDF(Object.assign(footer, innvoiceNo, customer_email_address, customer_email, datasinvoice));
                    } else {
                    }

                    // alert("here")

                    for (let i = 0; i < datas.length; i++) {
                        let innvoiceNo = { invoice_no: invoiceResponse.data.data[0].invoice_no };
                        let client_id = { client_id: USER_ID };
                        data3.push(Object.assign(innvoiceNo, client_id, datas[i]));
                        this.setState({
                            data3: data3,
                        });
                    }
                    axios
                        .post(baseURL_2 + 'insight.php', data3)
                        .then(response => {
                            localStorage.setItem("INVOICE_CREATEPEAK", null)
                            console.log("bayo", response)
                            this.setState({
                                isLoading: false,
                                loaded: false,
                            })
                        })
                        .catch(error => {
                            this.setState({
                                showError: true,
                                isShowError: false,
                                loaded: false,

                                isLoading: false,
                            });
                            window.scrollTo(0, 0);
                            // console.error('Error during service worker registration:', error);
                        });



                    for (let i = 0; i < this.state.invoice.length; i++) {
                        var current = moment(moment.unix(this.state.invoice[i].frequecy)).format("DD");
                        var start_current = moment(this.state.invoice[i].start_time).format("DD");

                        var date_next;
                        let date_last;


                        if (current === "30" || start_current > "04" || current === "31" || current === "29" || current === "28") {


                            if (start_current > "06") {

                                date_next = { dates_next: moment(moment.unix(this.state.invoice[i].frequecy)).format("DD/MM/YYYY") }
                                date_last = { dates: moment(moment.utc(this.state.invoice[i].start_time)).endOf("month").add("1", 'days').format("DD/MM/YYYY") }
                            } else {
                                date_next = { dates_next: moment(moment.unix(this.state.invoice[i].frequecy)).endOf("month").add("1", 'days').format("DD/MM/YYYY") }
                                date_last = { dates: moment(moment.utc(this.state.invoice[i].start_time)).format("DD/MM/YYYY") }

                            }

                        }
                        else {

                            date_next = { dates_next: moment(moment.unix(this.state.invoice[i].frequecy)).format("DD/MM/YYYY") }
                            date_last = { dates: (moment.utc(this.state.invoice[i].start_time).format('DD/MM/YYYY')) };

                        }



                        let date_modified = { date_modified2: (moment(this.state.invoice[i].modified).format('DD/MM/YYYY HH:mm')) };

                        data.push(Object.assign(date_last, date_next, date_modified, this.state.invoice[i]));
                        this.setState({
                            data: data,
                        })
                    }
                } else {

                    if (this.state.invoice.length > 0) {
                        // if(moment(moment.unix(this.state.invoice[1].frequecy)).startOf("month").format("DD")) == "")
                        for (let i = 0; i < this.state.invoice.length; i++) {
                            var current = moment(moment.unix(this.state.invoice[i].frequecy)).format("DD");
                            var start_current = moment(this.state.invoice[i].start_time).format("DD");

                            var date_next;
                            let date_last;
                            // var start_current = moment(this.state.invoice[i].start_time).format("DD");


                            // alert(current)

                            // alert(start_current)


                            if (current === "30" || start_current > "04" || current === "31" || current === "29" || current === "28") {

                                if (start_current < "06") {

                                    // alert("here")
                                    date_next = { dates_next: moment(moment.unix(this.state.invoice[i].frequecy)).format("DD/MM/YYYY") }
                                    date_last = { dates: moment(moment.utc(this.state.invoice[i].start_time)).endOf("month").add("1", 'days').format("DD/MM/YYYY") }
                                } else {
                                    date_next = { dates_next: moment(moment.unix(this.state.invoice[i].frequecy)).endOf("month").add("1", 'days').format("DD/MM/YYYY") }
                                    date_last = { dates: moment(moment.utc(this.state.invoice[i].start_time)).format("DD/MM/YYYY") }

                                }
                            }
                            else {
                                date_next = { dates_next: moment(moment.unix(this.state.invoice[i].frequecy)).format("DD/MM/YYYY") }
                                date_last = { dates: (moment.utc(this.state.invoice[i].start_time).format('DD/MM/YYYY')) };
                            }

                            // let date_next = { dates_next: (moment.unix(this.state.invoice[i].frequecy).format('DD/MM/YYYY')) };
                            // let date_last = { dates: (moment.utc(this.state.invoice[i].start_time).format('DD/MM/YYYY')) };
                            let date_modified = { date_modified2: (moment(this.state.invoice[i].modified).format('DD/MM/YYYY HH:mm')) };

                            data.push(Object.assign(date_last, date_next, date_modified, this.state.invoice[i]));
                            this.setState({
                                data: data,
                                loaded: false,
                                isLoading: false
                            })
                        }
                    } else {

                        // alert("ffh")

                        this.setState({
                            data: data,
                            isLoading: false,
                            loaded: false,
                        })
                    }
                }

            })
        })).catch(error => {

            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            onEdit: false,
            showModal: false,
            showModals: false
        });
    }

    onEditInvoice(record) {
        let formData = {
            invoice_no: record.invoice_no,
        };
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        axios.put(baseURL + `recurringinvoice`,
            formData, axiosConfig).then(response => {
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                        window.setTimeout(() => {
                            this.getData();
                            this.setState({ isOpenMore: false, isShowError: false })
                        }, 2000);
                    });
                } else {
                    this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                }
            }, error => {
                this.setState({ statusMessage: JSON.stringify(error), isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            });
    }

    _onButtonClick2(record) {

        var byteCharacters = atob(record.pdf);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    onDelete(record) {

        // alert(record.id)

        axios.delete(baseURL + `recurringinvoice?id=` + record.id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 3000);
                });
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 4000);
            }
        }, error => {
            // alert(JSON.stringify(error))
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 4000);
        });
    }

    _onShow(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            onEdit: false,
            acceptable_answers: record.acceptable_answers,
            answer: record.answer,
            answer_id: record.id,
        });
    }

    handleChangeFrequency = value => {

        // alert(this.state.invoice_id)
        if (value != null) {
            // var newDate = new Date(this.state.invoice_date).add(Date.DAY, +1).format('Y-m-d');
            var daily = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 1))).format('YYYY-MM-DD HH:mm:ss');
            var weekly = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 7))).format('YYYY-MM-DD HH:mm:ss');
            var monthly = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 30))).format('YYYY-MM-DD HH:mm:ss');
            var yearly = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 365))).format('YYYY-MM-DD HH:mm:ss');
            var two_weeks = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 14))).format('YYYY-MM-DD HH:mm:ss');
            var two_months = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 60))).format('YYYY-MM-DD HH:mm:ss');
            var three_months = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 90))).format('YYYY-MM-DD HH:mm:ss');
            var two_years = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 730))).format('YYYY-MM-DD HH:mm:ss');
            var six_months = moment(new Date(new Date(this.state.invoice_date).setDate(new Date(this.state.invoice_date).getDate() + 180))).format('YYYY-MM-DD HH:mm:ss');
            // alert(newDate)
            if (value.value.toString() === "Daily") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: daily,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "Weekly") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: weekly,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "After 2 Weeks") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: two_weeks,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "Monthly") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: monthly,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "After 2 Months") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: two_months,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "After 3 Months") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: three_months,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "After 6 Months") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: six_months,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "Yearly") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: yearly,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
            if (value.value.toString() === "After 2 Years") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: two_years,
                        frequencyValue: [{ value: value.value.toString(), label: value.value.toString() }]
                    });
            }
        }

        else {
            this.setState(
                {
                    frequency_type: "",
                    frequencyValue: [{ value: "", label: "" }]
                });
        }
    };

    closeModalFrequency = e => {
        this.setState({
            isOpenFrequncy: false,
            isOpenMore: false,
        });
    };

    closeModalMore = e => {
        this.setState({
            isOpenMore: false,
        });
    };

    render() {
        const { showComponent, onEdit } = this.state;
        const { hideComponent, returnHome } = this.state;
        return (
            this.state.loaded == false ?

                <div style={{ marginTop: "-20px" }} >



                    <Modal
                        isOpen={this.state.isOpenMore}
                        onRequestClose={e => this.closeModalMore(e)}
                        contentLabel="My dialog"
                        className="mymodal"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        <MDBCloseIcon onClick={this.closeModalMore} />
                        <h4><b>Invoice #{this.state.moreopen == undefined ? null : this.state.moreopen.invoice_no}</b></h4><br />

                        {this.state.showError ? <div >

                            <Alert color="danger" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>
                        </div> : null}
                        {this.state.isShowError ? (
                            <div>

                                <Alert color="success" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>
                            </div>
                        ) : null}

                        <div>
                            {/* Type or paste email addresses and press `Add`/`Enter` to add more <br /> */}

                            <button className="btn btn-primary  btn-sm"
                                title="Delete"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick2(this.state.moreopen) }
                                } >
                                Edit Frequency
                            </button><br /><br />

                            <button className="btn btn-primary  btn-sm"
                                title="edit invoice"
                                onClick={this.goEditInvoice.bind(this, this.state.moreopen)}
                            >
                                Edit Invoice
                            </button><br /><br />
                            {this.state.moreopen && this.state.moreopen.status === 0 ?
                                <button className="btn btn-primary btn-sm"
                                    title="Edit Invoice"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleOnClick3(this.state.moreopen) }} >
                                    Enable
                                </button>
                                : null}
                            {this.state.moreopen && this.state.moreopen.status === 1 ?
                                <button className="btn btn-danger btn-sm"
                                    title="Edit Invoice"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.handleOnClick1(this.state.moreopen) }} >

                                    Disable
                                </button>
                                : null}<br /><br />
                            <button className="btn btn-danger  btn-sm"
                                title="Delete"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => {
                                        if (confirm("Are you sure you want to delete this recurring invoice?"))
                                            this.onDelete(this.state.moreopen)
                                    }} >
                                Delete
                            </button>
                            <br />

                        </div>


                    </Modal>

                    <Modal
                        isOpen={this.state.isOpenFrequncy}
                        onRequestClose={e => this.closeModalFrequency(e)}
                        contentLabel="My dialog"
                        className="mymodal"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        <MDBCloseIcon onClick={this.closeModalFrequency} />
                        <h4><b>Do you wish to edit frequency?</b> Click done</h4><br />

                        {this.state.showError ? <div >

                            <Alert color="danger" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>


                        </div> : null}

                        {this.state.isShowError ? (
                            <div>

                                <Alert color="success" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>
                            </div>
                        ) : null}

                        <div>

                            <br />
                            <Form.Group className="Focus-line">
                                <Form.Label>Frequency</Form.Label>

                                <Select
                                    isClearable
                                    options={this.state.frequency_load}
                                    // onInputChange={this.loadOptions}
                                    placeholder="Select frequency"
                                    autosize={true}
                                    onChange={this.handleChangeFrequency}
                                    isLoading={this.state.isLoading}
                                    value={this.state.frequencyValue}
                                    // noOptionsMessage={() => 'nothing found'}
                                    // loadingMessage={() => 'searching...'}   //minor type-O here
                                    menuPortalTarget={document.body}
                                    name="namffe"
                                />

                            </Form.Group>
                            <br />

                            <div className="col-8 offset-4" style={{ marginTop: '10px' }}>
                                <button className="btn btn-success  btn-sm"
                                    title="Enable"
                                    style={
                                        { marginRight: '10px' }}
                                    onClick={
                                        () => { this.onEditInvoice2() }}
                                >
                                    {this.state.submit_frequency ? "Please Wait..." : "Done!"}
                                </button>
                            </div>
                            <br />

                        </div>


                    </Modal>


                    {!hideComponent && (
                        <div>
                            < Card >
                                {returnHome ? "Redirecting please wait..." :

                                    <CardBody >
                                        <div className="panel-body" >
                                            <div className="row">

                                                <div className="col-md-6">
                                                    <h4><b>Recurring Invoice</b></h4><br />
                                                </div>
                                                <div className="col-md-6">
                                                    <Link to="/create-recurring-invoice">
                                                        <Button className="pull-right btn-sm" color="primary
                                                    " onClick={this._onButtonClick} outline>Create Recurring Invoice <i className="fa fa-plus"></i>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h4>Total Recurring Invoices are now at: <b>{this.state.total}</b></h4>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="col-md-10 offset-1">
                                                        <button className="pull-right" title="refresh" style={{ background: "white", border: "0px" }} onClick={this.getData}>
                                                            <RefreshIcon />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            {this.state.showError ? <div >

                                                <Alert color="danger" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>


                                            </div> : null}

                                            {this.state.isShowError ? (
                                                <div>

                                                    <Alert color="success" className="alert--colored" icon>
                                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                                        </p>
                                                    </Alert>

                                                </div>

                                            ) : null}

                                            <ReactDatatable
                                                config={this.config}
                                                records={this.state.data}
                                                columns={this.columns}
                                                dynamic={true}
                                                id="tsc"
                                                loading={this.state.isLoading}
                                                total_record={this.state.total}
                                                onChange={this.tableChangeHandler} />

                                        </div>
                                    </CardBody>
                                }
                            </Card>
                        </div>
                    )
                    }
                    {
                        showComponent && (
                            <div>
                                <Invoice />
                            </div>
                        )
                    }
                    {
                        onEdit && (
                            // eslint-disable-next-line 
                            <Invoice_Edit />
                        )
                    }
                </div >
                :
                <div className="loader">
                    {LOADER}
                </div>
        )
    }
}


// import React from "react";
// import Modal from 'react-modal';
// import "./style.css";

// // import { facebookConfig, googleConfig } from "social-config";

// export class invoice_view extends React.Component {

//     constructor(props) {
//         super(props);
//         // this.toggleModal = this.toggleModal.bind(this);

//         this.state = {
//             isOpen: false,
//             error: null
//         };
//     }

//     toggleModal = e => {
//         this.setState({
//             isOpen: true
//         });
//     };

//     closeModal = e => {
//         this.setState({
//             isOpen: false
//         });
//     };
//     render() {
//         return (
//             <div>
//                 <button >Open Modal</button>
//                 <Modal
//                     isOpen={this.state.isOpen}
//                     onRequestClose={e => {
//                         this.closeModal(e);
//                     }}
//                     contentLabel="My dialog"
//                     className="mymodal"
//                     overlayClassName="myoverlay"
//                     closeTimeoutMS={500}
//                   >
//                     <div>My modal dialog.</div>
//                     <h2 >Hello</h2>
//                       <button onClick={this.closeModal}>close</button>
//                       <div>I am a modal</div>
//                       <form>
//                         <input />
//                         <button>tab navigation</button>
//                         <button>stays</button>
//                         <button>inside</button>
//                         <button>the modal</button>
//                       </form>
//                     <button >Close modal</button>
//                 </Modal>
//             </div>
//         );
//     }
// }