import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import LineItem from './LineItem.jsx'

import { MdAddCircle as AddIcon } from 'react-icons/md'


import style_business from './LineItems.module.scss';
import styles_ngo from './ngo_listitems.module.scss';



import { BUSINESS_TYPE } from '../../_helpers/exports.jsx'


class LineItems extends Component {

  handleDragEnd = (result) => {
    if (!result.destination) return
    // helper function to reorder result (src: react-beautiful-dnd docs)
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      return result
    }

    // perform reorder
    const lineItems = reorder(
      this.props.items,
      result.source.index,
      result.destination.index
    )

    // call parent handler with new state representation
    this.props.reorderHandler(lineItems)

  }

  render = () => {

    const { items, addHandler, addNew, reorderHandler, ...functions } = this.props


    // alert(items)




    let styles;
    BUSINESS_TYPE === 'business' ?
      styles = style_business
      : styles = styles_ngo

    return (
      <form>

        <div className={styles.lineItems}>
          <div className={`${styles.gridTable}`}>

            <div className={`${styles.row} ${styles.header}`}>
              <div>#</div>
              {BUSINESS_TYPE === 'business' ?
                <div>Item</div>
                : null}
              <div>Description</div>
              {BUSINESS_TYPE === 'business' ?
                <div>Qty</div>
                : null}
              <div>{BUSINESS_TYPE === 'business' ? "Price" : "Amount"}</div>
              {BUSINESS_TYPE === 'business' ?
                <div>Tax (%)</div>
                : null}
              {BUSINESS_TYPE === 'business' ?

                <div>Total</div>
                : null}
              <div></div>
            </div>

            <DragDropContext onDragEnd={this.handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className={snapshot.isDraggingOver ? styles.listDraggingOver : ''}
                  >
                    {this.props.items.map((item, i) => (
                      <Draggable key={item.id} draggableId={item.id} index={i}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                            className={snapshot.isDragging ? styles.listItemDragging : ''}
                          >
                            <LineItem
                              style={{ color: 'red' }}
                              key={i + item.id}
                              index={i} 
                              name={item.name}
                              item_id = {item.item_id}
                              description={item.description} quantity={item.quantity}
                              discount_percentage={item.discount_percentage} tax_percentage={item.tax_percentage} price={item.price}
                              {...functions}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

          </div>

          <div className={styles.addItem}>

            {/* {alert(items.quantity)} */}
            <button type="button" onClick={addHandler}><AddIcon size="1em" className={styles.addIcon} />

              {addNew ? "Please Wait..." : "Add"}

            </button>
          </div>

        </div>
      </form>

    )
  }
}

export default LineItems

LineItems.propTypes = {
  items: PropTypes.array.isRequired,
  currencyFormatter: PropTypes.func.isRequired,
  showtax: PropTypes.func.isRequired,
  addHandler: PropTypes.func.isRequired,
  addNew: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
  focusHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  reorderHandler: PropTypes.func.isRequired,
}


