import React, { useState } from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import { Card, CardBody, Button } from 'reactstrap';
import { BUSINESS_TYPE, ToastTable, successToast, errorToast, CONFIG, TOKEN, USER_ID, BRANCH_ID, NHIF, PAYE, PAYE_ONE, formatCurrency, TAX_RELIEF, PAYE_ONE_DISABILITY, PAYE_DISABILITY, PAYE_DISABILITY_CALCULATOR } from '../../_helpers/exports';
import { Link, useLocation, Navigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import PostHooks from '../../_helpers/postHooks';
import Select from 'react-select';
import UseFetch from '../../_helpers/useFetch';
import PutHooks from '../../_helpers/putHooks';


const EditStaff = () => {
    const location = useLocation();

    const [gross, setGross] = useState(location.state.gross);
    const [other_deduction, setOtherDeductions] = useState(location.state.other_deductions);
    const [other_benefits, setOtherBenefits] = useState(location.state.other_benefits);
    const [nsisf, setNSSF] = useState(location.state.nssf);
    const [payslipd_id, setPaySlipID] = useState();

    const [employee, setEmployee] = useState("");

    const [employee_value, setEmployeeValue] = useState(location.state.employ_id);

    const [disability_status, setEmployeeDisabilityStatus] = useState(JSON.parse(location.state.disability));

    const [employee_label, setEmployeeLabel] = useState({ label: "Select Employee", value: "" })

    const [check, setCheck] = useState(0);

    const [employee_check, setCheckEmployee] = useState(1);


    const [props, setProps] = useState({});
    const [isLoading, res, postRequest] = PutHooks(props); // custom hook

    const [loading, fetch, callback] = UseFetch(`payslip_id?id=${location.state.employ_id}`); // custom hook
    const [gohome, setHome] = useState(false);
    const [period_value, setPeriodValue] = useState(location.state.period);
    const [period, setPeriod] = useState([
        {
            label: 'Daily',
            value: 'daily'
        },
        {
            label: 'Weekly',
            value: 'weekly'
        },
        {
            label: 'Monthly',
            value: 'monthly'
        },
        {
            label: 'Yearly',
            value: 'yearly'
        }
    ]);


    React.useEffect(() => {  // use effect call, detect change and call custom post hook
        if (res != null && res.status) {
            setTimeout(() =>
                setHome(true), 2000
            )
        }
        if (fetch && fetch.data.length > 0) {

            alert(fetch.data[0].other_benefits)
            setGross(fetch.data[0].gross)
            setOtherBenefits(fetch.data[0].other_benefits)
            setOtherDeductions(fetch.data[0].other_deductions)
            setNSSF(fetch.data[0].nssf)
            setPaySlipID(fetch.data[0].id)
            setEmployee(fetch.data[0].name)
            setPeriodValue(fetch.data[0].period)

            // setEmployee(fetch.data)
        }

        if (employee_check === 1) {
            setCheckEmployee(0)
            callback()
        }

        if (props.url === 'staff_expense' && check === 1) {
            postRequest();
            setCheck(0)
        }

    }, [loading, isLoading, employee_check, props, check])



    const submit = (e) => {
        e.preventDefault();
        const formData = {
            gross: gross,
            other_deductions: other_deduction,
            id: payslipd_id,
            other_benefits: other_benefits,
            nsisf: nsisf,
            period: period_value,
            employee_id: employee_value
        }
        const customerProps = { // post body and the url
            url: 'staff_expense',
            body: formData
        }
        setCheck(1)
        setProps(customerProps)
    }
    return (
        <div>
            {ToastTable()}
            <Card>
                {gohome ?
                    <Navigate to='/staff_expense' /> : null}

                <CardBody>
                    <div className="row">
                        <div className="col-md-6">
                            <h4><b>Edit PaySlip  ({location.state.name})</b></h4><br />
                        </div>
                        <div className="col-md-6">
                            <Link to="/staff_expense">
                                <Button className="pull-right  btn-sm" outline color="primary" >
                                    Payslips
                                </Button>
                            </Link>

                        </div>
                        <div className='col-md-6'>

                            <form onSubmit={submit}>

                                <div className="col-md-12"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Gross Income</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input id="input" type="text"  readOnly className="form-control"
                                                    name="gross" placeholder='Set Gross Income'
                                                    value={gross} onChange={e => setGross(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Other Benefits</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input id="input" type="text" className="form-control"
                                                    name="other_benefits" readOnly placeholder='Other Deduction'
                                                    value={other_benefits} onChange={e => setOtherBenefits(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-12"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">NSSF</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input id="input" type="text" className="form-control"
                                                    name="nssf" placeholder='NSSF'
                                                    value={nsisf} onChange={e => setNSSF(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-12"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="form-label">Other deductions</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input id="input" type="text" readOnly className="form-control"
                                                    name="other_deduction" placeholder='Other Deduction'
                                                    value={other_deduction} onChange={e => setOtherDeductions(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-md-12"> <br />
                                    <Form.Group className="Focus-line" >
                                        <div className="row">
                                            <div className="col-md-2">
                                                <Form.Label>Period</Form.Label>
                                            </div>
                                            <div className="col-md-10">
                                                <Form.Control
                                                    as="select"
                                                    className="form-control input-md"
                                                    placeholder="Select Period"
                                                    value={period_value}
                                                    onChange={e => setPeriodValue(e.target.value)}
                                                    id="period_value"
                                                >
                                                    {
                                                        period && period.length > 0 &&
                                                        period.map((regionItem, i) =>
                                                            <option key={i}
                                                                value={regionItem.value}>{regionItem.value}</option>)
                                                    }
                                                </Form.Control>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </div> */}




                                <br />
                                <br />
                                <div className="col-md-12"><br />
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <Link to="/staff_expense">
                                                    <Button outline color="primary" className="btn-sm">
                                                        Cancel
                                                    </Button>
                                                </Link>
                                            </div>
                                            <div className="col-md-10">
                                                <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                                    {isLoading ? "Please Wait..." : "Save"}
                                                </Button> &nbsp;&nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6">

                            <h4><b>PaySlip Details</b></h4>
                            <br />
                            <div className='row'>
                                <div className='col-6'>

                                    <h4 className='payslip'><b>Gross Pay</b></h4>
                                    <h4 className='payslip'><b>Other Benefits</b></h4>
                                    <h4><b>Total Gross</b></h4>

                                    <hr />
                                    <h4 className='payslip'>NSSF Contribution</h4>
                                    <h4 className='payslip'>Taxable Income</h4>
                                    <h4 className='payslip'>Tax Before Relief</h4>
                                    <h4 className='payslip'>Personal Relief	</h4>

                                    <h4 className='payslip'>Insurance/NHIF Relief</h4>

                                    <h4 className='payslip'>PAYE</h4>
                                    <h4 className='payslip'>NHIF Contribution</h4>
                                    <hr />
                                    <h4 className='payslip'>Net Pay</h4>

                                </div>

                                <div className='col-6'>

                                    <h4 className='payslip'><b>{formatCurrency(parseFloat(gross))}</b></h4>
                                    <h4 className='payslip'>{formatCurrency(parseFloat(other_benefits))}</h4>
                                    <h4 className='payslip'><b>{formatCurrency(parseFloat(gross) + parseFloat(other_benefits))}</b></h4>
                                    <hr />
                                    <h4 className='payslip'>{formatCurrency(parseFloat(nsisf))}</h4>
                                    <h4 className='payslip'>
                                        {
                                            disability_status ?

                                                (formatCurrency(
                                                    (parseFloat(gross) + parseFloat(other_benefits)) > 150000 ?
                                                        (parseFloat(gross) + parseFloat(other_benefits) - 150000) - parseFloat(nsisf)
                                                        :
                                                        0
                                                )
                                                )
                                                :
                                                (formatCurrency((parseFloat(gross) + parseFloat(other_benefits)) - parseFloat(nsisf)))

                                        }

                                    </h4>
                                    <h4 className='payslip'>{

                                        formatCurrency(
                                            disability_status ?
                                                PAYE_DISABILITY_CALCULATOR(((parseFloat(gross) + parseFloat(other_benefits)) - parseFloat(nsisf)), parseFloat(nsisf)) :


                                                (PAYE((parseFloat(gross) + parseFloat(other_benefits)) - parseFloat(nsisf), parseFloat(nsisf))) - parseFloat(TAX_RELIEF))



                                    }</h4>
                                    <h4 className='payslip'>{disability_status ?
                                        PAYE_ONE_DISABILITY(parseFloat(gross) + parseFloat(other_benefits))
                                        :
                                        PAYE_ONE(parseFloat(gross) + parseFloat(other_benefits)) > 0 ?
                                            formatCurrency(parseFloat(TAX_RELIEF)) : formatCurrency(0)}</h4>

                                    <h4 className='payslip'>

                                        {

                                            disability_status ?
                                                PAYE_ONE_DISABILITY(parseFloat(gross) + parseFloat(other_benefits)) > 0 ?

                                                    formatCurrency(
                                                        NHIF(parseFloat(gross) + parseFloat(other_benefits)) * 0.15) : formatCurrency(0)
                                                :
                                                PAYE_ONE(parseFloat(gross) + parseFloat(other_benefits)) > 0 ? formatCurrency(
                                                    NHIF(parseFloat(gross) + parseFloat(other_benefits)) * 0.15) : formatCurrency(0)
                                        }


                                    </h4>



                                    <h4 className='payslip'>{
                                        disability_status ?
                                            PAYE_ONE_DISABILITY(parseFloat(gross) + parseFloat(other_benefits)) > 0 ?

                                                formatCurrency(

                                                    PAYE_DISABILITY_CALCULATOR(((parseFloat(gross) + parseFloat(other_benefits)) - parseFloat(nsisf)), parseFloat(nsisf)) - parseFloat(TAX_RELIEF)
                                                )

                                                : formatCurrency(0)


                                            :

                                            PAYE_ONE(parseFloat(gross) + parseFloat(other_benefits)) > 0 ?
                                                formatCurrency(PAYE(((parseFloat(gross) + parseFloat(other_benefits)) - parseFloat(nsisf)), parseFloat(nsisf))
                                                    -
                                                    parseFloat(TAX_RELIEF)
                                                ) : formatCurrency(0)}</h4>


                                    <h4 className='payslip'>{formatCurrency(NHIF((parseFloat(gross) + parseFloat(other_benefits))))}</h4>

                                    <hr />

                                    <h4 className='payslip'>{



                                        disability_status ?
                                            PAYE_ONE_DISABILITY(parseFloat(gross) + parseFloat(other_benefits)) > 0 ?


                                                formatCurrency(

                                                    (
                                                        (parseFloat(gross) + parseFloat(other_benefits))
                                                        +
                                                        parseFloat(TAX_RELIEF)
                                                        +
                                                        (NHIF(parseFloat(gross) + parseFloat(other_benefits)) * 0.15)
                                                    ) -

                                                    ((PAYE_DISABILITY_CALCULATOR(((parseFloat(gross) + parseFloat(other_benefits)) - parseFloat(nsisf)),
                                                        parseFloat(nsisf)))

                                                        +
                                                        + parseFloat(nsisf) +
                                                        NHIF(parseFloat(gross) + parseFloat(other_benefits)))
                                                )
                                                :

                                                formatCurrency(

                                                    (
                                                        (parseFloat(gross) + parseFloat(other_benefits))

                                                    ) -
                                                    (parseFloat(nsisf) + parseFloat(other_deduction) + NHIF(parseFloat(gross) + parseFloat(other_benefits)))
                                                )
                                            :

                                            PAYE_ONE(parseFloat(gross) + parseFloat(other_benefits)) > 0 ?

                                                formatCurrency(

                                                    (
                                                        (parseFloat(gross) + parseFloat(other_benefits))
                                                        +
                                                        parseFloat(TAX_RELIEF)
                                                        +
                                                        (NHIF(parseFloat(gross) + parseFloat(other_benefits)) * 0.15)
                                                    ) -

                                                    (PAYE(parseFloat(gross) + parseFloat(other_benefits) - parseFloat(nsisf), parseFloat(nsisf))
                                                        -
                                                        parseFloat(TAX_RELIEF)

                                                        + parseFloat(nsisf) + parseFloat(other_deduction) +
                                                        parseFloat(nsisf) +
                                                        NHIF(parseFloat(gross) + parseFloat(other_benefits)))
                                                )
                                                :

                                                formatCurrency(

                                                    (
                                                        (parseFloat(gross) + parseFloat(other_benefits))

                                                    ) -
                                                    (parseFloat(nsisf) + parseFloat(other_deduction) + NHIF(parseFloat(gross) + parseFloat(other_benefits)))
                                                )

                                    }</h4>

                                </div>

                            </div>

                        </div>
                    </div>

                </CardBody>
            </Card>
        </div >
    )
}
export default EditStaff;