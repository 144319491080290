
import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { BRANCH_ID, BUSINESS_TYPE, CURRENCY, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link, Navigate } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import createVendors from './createExpense';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import ExpenseCreate from './createExpense';



export class Expenses_Specific extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this._onButtonClick3 = this._onButtonClick3.bind(this);
        this._onButtonClick5 = this._onButtonClick5.bind(this);
        this.onSubmitNewUpdatws = this.onSubmitNewUpdatws.bind(this);

        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnClick1 = this.handleOnClick1.bind(this);

        this.columns = [

            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                align: "left"
            },
            {
                key: "expense_account",
                TrOnlyClassName: 'tsc',
                text: "Expense Account",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "reference",
                TrOnlyClassName: 'tsc',
                text: "Ref#",
                className: "tsc",
                align: "left"
            },
            {
                key: "vendor_display_name",
                TrOnlyClassName: 'tsc',
                text: "Supplier",
                className: "tsc",
                align: "left"
            },
            {
                key: "paid_through",
                TrOnlyClassName: 'tsc',
                text: "Paid Through",
                className: "tsc",
                align: "left"
            },
            {
                key: "billable",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.billable === 0 ?
                                <div>
                                    NON-BILLABLE
                                </div>
                                : null}
                            {record.billable === 1 ?
                                <div>
                                    UNBILLED
                                </div>

                                : null}
                        </Fragment >
                    );
                }
            },

            {
                key: "recurring",
                TrOnlyClassName: 'tsc',
                text: "Recurring",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.recurring == 1 ?
                                <span style={{ borderRadius: "5px", padding: "2px" }}>
                                    YES
                                </span>
                                : null
                                // <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                //     NO
                                // </span>

                            }
                            {record.recurring == 0 ?
                                <span style={{ borderRadius: "5px", padding: "2px" }}>
                                    NO
                                </span>
                                : null

                            }
                        </Fragment >
                    );
                }
            },


            {
                key: "payables",
                TrOnlyClassName: 'tsc',
                text: "AMOUNT",
                className: "tsc",
                align: "left"
            },

            {
                key: "payables_tax",
                TrOnlyClassName: 'tsc',
                text: "TAX AMOUNT",
                className: "tsc",
                align: "left"
            },

            {
                key: "product",
                TrOnlyClassName: 'tsc',
                text: "Project",
                className: "tsc",
                align: "left"
            },

            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        // eslint-disable-next-line
                        <Fragment className="center" >


                            <button className="btn btn-success  btn-sm"
                                title="Edit Expenses"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this._onButtonClick2(record) }} >
                                Edit
                            </button>

                            <button className="btn btn-danger  btn-sm"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}

                                onClick={
                                    () => {
                                        if (window.confirm('Are you sure you want to delete this expense?'))

                                            this.onDelete(record)
                                    }} >
                                Delete
                            </button>



                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [100, 200, 500],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 100,
            button: {
                excel: true,
                csv: true
            },
            filename: "expenses",
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "No expense was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            showModals: false,
            editShow: false,
            _onShow: false,
            showEdit: false,
            type: [{
                name: 'Rating'
            }, {
                name: 'Closed-ended'
            }],

            hideComponent: false,
            data: [],
            priority: [{
                name: '1'
            }
            ],
        };
    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this._onButtonClick2(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    async handleOnClick1(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onApprove(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }



    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {
        this.getData();

        this.load()
    }


    onDelete(record) {

        // alert(record.id)
        axios.delete(baseURL + `expense?id=` + record.id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {


                axios.delete(baseURL + `expense_recurring?expense_ref=` + record.reference, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': TOKEN
                    },
                }).then(response => {
                    console.log("true", response.data)
                    if (response.data.status) {
                        this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                            window.setTimeout(() => {
                                this.getData();
                                this.setState({ isShowError: false })
                            }, 3000);
                        });
                    } else {
                        this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                        window.setTimeout(() => {
                            this.getData();
                            this.setState({ isShowError: false })
                        }, 4000);
                    }
                });

                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData()
                        // this.closeModalMore();
                        this.setState({ isShowError: false })





                    }, 3000);
                });
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData("", moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                    this.setState({ isShowError: false })
                }, 4000);
            }
        }, error => {
            // alert(JSON.stringify(error))
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData("", moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                this.setState({ isShowError: false })
            }, 4000);
        });
    }


    // localStorage.setItem("survey_id", record.survey_id)
    // localStorage.setItem("survey_name", record.name)

    getData = (queryString = "") => {


        // alert(JSON.stringify(this.props))
        // alert(USER_ID)
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `expense?branch_id=${BRANCH_ID}&&client_id=` + USER_ID + "&&" + queryString
        console.log("query", url);
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((customerResponse) => {
            console.log("BUGggg", customerResponse)

            this.setState({
                customer: customerResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];

                    this.setState({
                        total: customerResponse.data.total
                    })

                    for (let i = 0; i < this.state.customer.length; i++) {
                        let mname;

                        let mcompany_name;

                        let mail;
                        if (this.state.customer[i].status === 1) {
                            mail = { mail: "Active" };
                        } else {
                            mail = { mail: "Inactive" };
                        }

                        if (this.state.customer[i].company_name === null) {
                            mcompany_name = { company_mail: "_" };
                        } else {
                            mcompany_name = { company_mail: this.state.customer[i].company_name };
                        }

                        let date = { dates: (moment.utc(this.state.customer[i].date).format('DD/MM/YYYY HH:mm')) };
                        let opening_balance = { payables: CURRENCY + this.state.customer[i].amount };

                        let opening_balance_tax = { payables_tax: CURRENCY + this.state.customer[i].tax_amount };

                        data.push(Object.assign(date, mname, mail, opening_balance_tax, mcompany_name, opening_balance, this.state.customer[i]));
                        this.setState({
                            data: data
                        })
                        console.log("bugs", data);

                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    load() {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url3 = baseURL + `deposit_to`
        let url4 = baseURL + `bank?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&account_type=Bank`
        let url5 = baseURL + `bank?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&account_type=Credit Card`
        let url6 = baseURL + `expense_account?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID

        axios.all([
            axios.get(url3, axiosConfig),
            axios.get(url4, axiosConfig),
            axios.get(url5, axiosConfig),
            axios.get(url6, axiosConfig)

        ]).then(axios.spread((paymentResponse, bankResponse, creditResponse, expenseResponse) => {
            this.setState({
                paymentResponse: paymentResponse.data.data,
                bankResponse: bankResponse.data.data,
                creditResponse: creditResponse.data.data,
                expenseResponse: expenseResponse.data
            }, function () {
                var datasss = [];
                if (this.state.paymentResponse.length > 0) {
                    for (let i = 0; i < this.state.paymentResponse.length; i++) {
                        let label = { label: this.state.paymentResponse[i].name };
                        let value = { value: this.state.paymentResponse[i].name };
                        datasss.push(Object.assign(label, value));
                        this.setState({
                            datasss: datasss
                        })
                    }
                } else {
                    this.setState({
                        datasss: datasss
                    })
                }

                localStorage.setItem("expenseaccountcustomers", JSON.stringify(this.state.expenseResponse))

                localStorage.setItem("paidthrough", JSON.stringify(datasss))
                var data_bank = [];
                if (this.state.bankResponse.length > 0) {
                    for (let j = 0; j < this.state.bankResponse.length; j++) {
                        let label = { label: this.state.bankResponse[j].account_name + ` (${this.state.bankResponse[j].bank_name})-` + this.state.bankResponse[j].currency };
                        let value = { value: this.state.bankResponse[j].account_name };
                        data_bank.push(Object.assign(label, value));
                        this.setState({
                            data_bank: data_bank
                        })
                    }
                } else {
                    this.setState({
                        data_bank: data_bank
                    })
                }
                localStorage.setItem("bankaccounts", JSON.stringify(data_bank))
                var data_credit = [];
                if (this.state.creditResponse.length > 0) {
                    for (let j = 0; j < this.state.creditResponse.length; j++) {
                        let label = { label: this.state.creditResponse[j].account_name };
                        let value = { value: this.state.creditResponse[j].account_name };
                        data_credit.push(Object.assign(label, value));
                        this.setState({
                            data_credit: data_credit
                        })
                    }
                } else {
                    this.setState({
                        data_credit: data_credit
                    })
                }
                localStorage.setItem("creditaccounts", JSON.stringify(data_credit))
            })
        })).catch(error => {
            console.log('bayoo', error.response)
        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {

        window.location.href = "create-expense"
        // this.setState({
        //     showComponent: true,
        //     hideComponent: true,

        // });
    }

    _onButtonClick5() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showEdit: false,
            showModal: false,
            showModals: false
        });
    }

    _onButtonClick2(record) {
        localStorage.setItem("expense_id_for_edit", record.id)
        this.setState({
            showEdit: true
        });
    }

    _onButtonClick3() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showModal: false,
            _onShow: false,
            showEdit: false,
            showModals: false
        });
    }

    onEdit(record) {
        localStorage.setItem("email_customer", record.email)
        localStorage.setItem("id_customer", record.id)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: false,
            _onShow: false,
            showModals: false,
            showEdit: true,
        }, function () {
        });

    }

    onSubmitNewUpdatws(e) {
        e.preventDefault();
        let ttl_new = (this.state.ttl * 60)

        let formData = {
            "survey_id": localStorage.getItem("survey_id"),

            "ttl": ttl_new,
            "type": this.state.selectedType,
            "question": this.state.question,

        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.put(baseURL + `question/` + this.state.questionsss, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)

                if (response.data.status) {
                    this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                    window.setTimeout(() => {
                        this.setState({ isShowError: false });
                        this._onButtonClick5()
                        this.getData()
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.status_message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }



    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onApprove(record) {
        let body = {
            status: "APPROVED"
        }
        axios.put(baseURL + `question/` + record.question_id + "/approval", body, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 2000);
        });
    }


    onCustomerInfo(record) {
        localStorage.setItem("customer_id_moreinfo", record.id)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: true,
        }, function () {
        });

    }
    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };
    ReactTypes() {
        return (this.state.priority && (this.state.priority.length > 0 || this.state.priority.length === 0) &&
            this.state.priority.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.name })))
    }

    _onShow(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            editShow: true,
            acceptable_answers: record.acceptable_answers,
            answer: record.answer,
            answer_id: record.id,
            showModal: false,
        });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent, showEdit } = this.state;
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
                    <div>
                        < Card >
                            {showEdit == true ?
                                <Navigate to="/editexpense" />
                                : null}

                            <CardBody >
                                <div className="panel-body" >
                                    <div className="row">

                                        <div className="col-md-6">
                                            <h4><b>List of Expenses</b></h4><br />
                                        </div>
                                        <div className="col-md-6">
                                            {/* <Link to="create-expense"> */}
                                            <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>

                                                Create Expenses <i className="fa fa-plus"></i>
                                            </Button>
                                            {/* </Link> */}

                                        </div>
                                        <div className="col-12 float-right">
                                            <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}
                                                onClick={this.getData}>
                                                <RefreshIcon />
                                            </button>
                                        </div>
                                    </div>
                                    <br />
                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}

                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} />

                                </div>
                            </CardBody>

                        </Card>
                    </div>
                )
                }
                {/* {
                    showComponent && (
                        < >
                            <Navigate to="create-expense" />
                            <ExpenseCreate />
                        </div>
                    )
                } */}
                {/* 
                {
                    showModal && (
                        < >
                            <CreateAddress />
                        </div>
                    )
                } */}

                {/* {
                    showModals && (
                        < >
                            <EditAddress />
                        </div>
                    )
                }
                {
                    showEdit && (
                        <div>
                            <CustomerEdit />
                        </div>
                    )
                } */}
            </div >
        )
    }
}