import React, { PureComponent } from 'react';
import SearchIcon from 'mdi-react/SearchIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import classNames from 'classnames';
import CountdownTimer from "react-component-countdown-timer";
import { EMAIL_ADDRESS, TOKEN, USER_ID } from '../../../_helpers/exports';
import axios from 'axios';
import { baseURL } from '../../../_helpers';
import * as moment from 'moment';
import { Navigate } from 'react-router';

export default class TopbarSearch extends PureComponent {
  constructor() {
    super();
    this.state = { inputOpen: false, isLoading: false };
  }

  onInputOpen = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ inputOpen: !prevState.inputOpen }));
  };


  componentDidMount() {
    var daily = moment(new Date(new Date()));
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": TOKEN
      }
    };
    let url = baseURL + `client?filter_value=` + USER_ID
    axios.all([
      axios.get(url, axiosConfig),
    ]).then(axios.spread((customerResponse) => {
      console.log('user_dtails', customerResponse)


      // alert(customerResponse.data.data[0].first_time)
      let counter;
      this.setState({
        client: customerResponse.data.data,
        our_client: customerResponse.data.data[0].our_client,
        first_time: customerResponse.data.data[0].first_time,
        role_id: customerResponse.data.data[0].role_id,
        isLoading: true,
        counts: (((new Date(customerResponse.data.data[0].subscription).getTime()) / 1000) - ((new Date(daily).getTime()) / 1000))
      })



      // localStorage.setItem("itemnames", )
    })).catch(error => {
      console.log('bayoo', error.response)

    })
  }

  render() {
    const { inputOpen, our_client, first_time, role_id, isLoading } = this.state;
    const searchClass = classNames({
      'topbar__search-field': true,
      'topbar__search-field--open': inputOpen,
    });

    return (
      <form className="toolbar_help_message topbar__search">

        {(this.state.counts / 86400).toFixed(0) < 0 ?
          <Navigate to="/log_in" />
          : null
        }
        {role_id == 2 ?
          isLoading == true ?
            our_client == 1 ?
              first_time == 1 ?
                <h4 style={{ color: "white" }}>Trial Expire in: <b>{(this.state.counts / 86400).toFixed(0)}</b> Days</h4>
                // <CountdownTimer count={this.state.counts} hideHours ={true}  hideDays ={true}  size={25} border responsive={true} />
                :
                    <h4 style={{ color: "white" }}>Remaining: <b>{(this.state.counts / 86400).toFixed(0)}</b> Days</h4>
              : null
            : null
          :
          null}
      </form>
    );
  }
}
