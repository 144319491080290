import React from "react";

import { Link, animateScroll as scroll } from "react-scroll";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { NavLink } from "react-router-dom";
import logo from '../assets/img/logo.png'
import { MDBCloseIcon } from "mdbreact";
import { Modal } from 'reactstrap';
import '../assets/styles/style2.css'

const Header =
  () => {

    const [open, isOpen] = React.useState(false);

    const scrollTo = id => e => {
      e.preventDefault();
      scroll.scrollTo({
        duration: 1500,
        delay: 100,
        smooth: "easeInOutQuint",
        containerId: id
      });
    };

    const toggle = () => {
      isOpen(true);
    }

    const closeModal = () => {
      isOpen(false);
    }

    const url = process.env.PUBLIC_URL + '/documents/user_guide.pdf'


    return (
      <header>

        <Modal
          isOpen={open}
          onRequestClose={e => closeModal(e)}
          onAfterOpen={() => {
            document.body.style.overflow = 'hidden';
          }}
          onAfterClose={() => {
            document.body.removeAttribute('style');
          }}
          closeTimeoutMS={500}
        >


          <div className="row" style={{ marginTop: '-20px' }}>
            <div className="col-9" >
              <h4><b>User Guide</b></h4><br />
            </div>

            <div className="col-3 float-right" >
              <MDBCloseIcon onClick={closeModal} />
            </div>
          </div>

          <div>

            <ol>
              {/* <li><b> <a className="nav-link"
                activeClass="active" href={'static/media/create_items.sdddk.pdf'} target="_blank" rel="noopener noreferrer">
                Full User Guide
              </a></b>
              </li>
              
               <li><b> <ak className="nav-link"
                activeClass="active" href='static/media/create_items.pdf' target="_blank">
                How to create items
              </ak></b>
              </li> */}

              <li><b> <a className="nav-link"
                activeClass="active" href='static/media/user-guide.e4996fd4.pdf' target="_blank">
                Full User Guide
              </a></b>
              </li>

{/* 
              <li><b> <a className="nav-link"
                activeClass= "active" href='static/media/user-guide.e4996fd4.pdf' target="_blank">
                Full User Guide
              </a></b>
              </li>


              <li><b> <a className="nav-link"
                activeClass="active" href='static/media/user-guide.e4996fd4.pdf' target="_blank">
                Full User Guide
              </a></b>
              </li>


              <li><b> <a className="nav-link"
                activeClass="active" href='static/media/user-guide.e4996fd4.pdf' target="_blank">
                Full User Guide
              </a></b>
              </li>


              <li><b> <a className="nav-link"
                activeClass="active" href='static/media/user-guide.e4996fd4.pdf' target="_blank">
                Full User Guide
              </a></b>
              </li> */}
            </ol>


          </div>


        </Modal>

        <Navbar bg="none" expand="lg" fixed="top">
          <Container>
            <Navbar.Brand
              href="#home"
              onClick={scrollTo("home")}
              aria-label="Logo"
            >
              <img src={logo} class="landing-logo" alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ color: "black" }}>
              <span />
              <span />
              <span />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Link
                  href="#"
                  className="nav-link"
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={400}
                >
                  Home
                </Link>
                <Link
                  href="#"
                  className="nav-link"
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={400}
                >
                  Features
                </Link>
                <Link
                  href="#"
                  className="nav-link"
                  activeClass="active"
                  to="pricing"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={400}
                >
                  Our Plans
                </Link>
                <Link
                  href="#"
                  className="nav-link"
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={400}
                >
                  Contact Us
                </Link>
                <NavLink
                  href="/log_in"
                  className="nav-link"
                  activeClass="active"
                  to="log_in"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={400}
                >
                  Login
                </NavLink>
                <Link
                  href="#"
                  className="nav-link"
                  activeClass="active"
                  to="pricing"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={400}
                >
                  Sign Up
                </Link>

                <Link
                  href="#"
                  className="nav-link"
                  activeClass="active"
                  spy={true}
                  onClick={toggle}
                  smooth={true}
                  offset={0}
                  duration={400}
                >
                  User Guide
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    );
  };

export default Header;
