
import React, { Fragment, useState } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import { Button, Card, NavLink, NavItem, Nav, CardBody, TabPane, TabContent } from 'reactstrap';
import { BRANCH_ID, BUSINESS_TYPE, ToastTable, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import * as moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import { useEffect } from 'react';
import UseFetch from '../../_helpers/useFetch';
import DeleteHooks from '../../_helpers/deleteHooks';



export const JobTitle = () => {
    const [data, setData] = React.useState([]);
    const [total, setTotal] = React.useState();
    const [queryString, setQueryString] = React.useState('');
    const [redirect, setRirect] = React.useState(false);
    const [redirect_data, setRedirectData] = React.useState({});
    const [loading, fetch, callback] = UseFetch(`job_title?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&branch_id=${BRANCH_ID}&${queryString}`); // custom hook
    const [check_del, setCheckDel] = React.useState(0);


    const [check, setCheck] = React.useState(0);
    const navigate = useNavigate();

    const [employee_id, setEmployeeID] = React.useState('')
    const [isLoading, deleteCall] = DeleteHooks(`job_title?id=${employee_id}`); // custom hook

    useEffect(() => {
        if (check === 0) {
            callback()
            setCheck(1)
        }
        if (fetch && fetch.data.length > 0) {
            let data_set = [];
            setTotal(fetch.total);
            for (let i = 0; i < fetch.data.length; i++) {
                let m_date = { m_date: moment(fetch.data[i].created_at).format('DD-MM-YYYY') };
                data_set.push(Object.assign(fetch.data[i], m_date));
            }
            setData(data_set);
            if (check_del === 1) {
                deleteCall()
                setCheckDel(0)
                callback()
            }
        }
    }, [loading, check_del, isLoading, queryString, check])


    const columns = [

        {
            key: "name",
            TrOnlyClassName: 'tsc',
            text: "Job",
            className: "tsc",
            align: "left"
        },
        {

            key: "m_date",
            TrOnlyClassName: 'tsc',
            text: "Dates",
            className: "tsc",
            altrueign: "left"
        },
        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            className: "cell",
            sortable: false,
            cell: record => {
                return (
                    // eslint-disable-next-line
                    <Fragment className="center" >
                        <button className="btn btn-primary  btn-sm"
                            title="Eith details"
                            style={
                                { marginRight: '10px' }}
                            onClick={
                                () => {
                                    setRirect(true)
                                    setRedirectData(record)
                                }} >
                            Edit
                        </button>

                        <button className="btn btn-danger  btn-sm"
                            title="Delete job title"
                            style={
                                { marginRight: '10px' }}
                            onClick={
                                () => {
                                    if (window.confirm(`Are you sure you want to delete this job title`)) {
                                        setEmployeeID(record.id)
                                        setCheckDel(1)
                                    }
                                }
                            }>
                            Delete
                        </button>

                    </Fragment>
                );
            }
        }
    ]

    const config = {
        key_column: "tsc",
        length_menu: [100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            excel: true,
            csv: true
        },
        filename: BUSINESS_TYPE === 'business' ? "employee" : "employee",
        page_size: 100,
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here",
            no_data_text: "Oooops no job title found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }

    }


    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' +
                    encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');
        setQueryString(queryString);
        setCheck(0)
    }

    return (
        <div >

            {ToastTable()}

            {redirect ?
                <>
                    {navigate('/edit_job_title', { state: redirect_data, replace: true })}
                </> : null}
            < Card >
                <CardBody >
                    <div className="panel-body" >
                        <div className="row">
                            <div className="col-md-6">
                                {/* <h4><b>Departments</b></h4> */}
                            </div>
                            <div className="col-md-6 float-right">
                                <Link to="/create_job_title">
                                    <Button className="pull-right btn-sm" color="primary" outline> Create Job Title
                                    </Button>
                                </Link>

                            </div>

                        </div>

                        <div className="col-12 float-right">
                            <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}

                                onClick={e => {
                                    setCheck(0)
                                    setQueryString(require("randomstring").generate({
                                        length: 1,
                                        charset: 'alphanumeric',
                                        capitalization: 'lowercase'
                                    }))
                                }}>
                                Refresh <RefreshIcon />

                            </button>

                        </div>

                        <ReactDatatable
                            config={config}
                            records={data}
                            columns={columns}
                            dynamic={true}
                            id="tsc"
                            loading={loading}
                            total_record={total}
                            onChange={tableChangeHandler} />
                    </div>

                </CardBody>

            </Card>

        </div >
    )

}