import React from 'react';
import axios from 'axios';
// import { makeStyles } from "@material-ui/core";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as moment from 'moment';
import { BallTriangle } from 'react-loader-spinner'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export const TOK = localStorage.getItem('invalid_token');
export const TOKEN = localStorage.getItem('token');
export const ROLE = localStorage.getItem('user_role');
export const SHORTCODE = localStorage.getItem('shortc');
export const NAME = localStorage.getItem('name');
export const STATE = localStorage.getItem('state');
export const EMAIL_ADDRESS = localStorage.getItem('clientemail');
export const OUR_CLIENT = localStorage.getItem('our_client');
export const USER_ID = localStorage.getItem('user_id_client');

export const USER_ID_ACC = localStorage.getItem('user_id_user');

export const USERADMINCHECK = localStorage.getItem('useradminrole');



export const COMPANY_NAME                                                                                                                                                                                                         = localStorage.getItem('company_name_client');
export const COMPANY_POSTAL_ADDRESS = localStorage.getItem('address_client');
export const BUSINESS_PIN = localStorage.getItem('business_pin');
export const PROFILE_URL = localStorage.getItem('profile_url');
export const CURRENCY = localStorage.getItem('currency');
export const CURRENCY_AGAINST_KE = localStorage.getItem('currency_against');
export const FINANCIAL_YEAR = localStorage.getItem('currency_against');
export const USERNAME = localStorage.getItem('username');
export const BUSINESS_TYPE = localStorage.getItem('type_business');
export const PAYMENT_PLAN = localStorage.getItem('payment_plan_user');
export const PHONE_MSISDN = localStorage.getItem('phonemsisdn_msisdn');
export const INVOICE_STATUS = localStorage.getItem('invoice_status');
export const PHYSICAL_ADDRESS = localStorage.getItem('physical_address');


export const BALANCE = localStorage.getItem('balance');

export const ToastTable = () => {
    return (<ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="colored"
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
    )
}


// export const formatCurrency = amount => {
//     return new Intl.NumberFormat('en-US', {
//         style: 'currency',
//         currency: "ABS",
//         minimumFractionDigits: 2,
//         maximumFractionDigits: 2
//     }).format(amount).replace("ABS", "");
// };


export const formatCurrency = amount => {
    return (parseFloat(amount)).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        // style:"currency",
        currency: localStorage.getItem('currency'),
        maximumFractionDigits: 2
    }).replaceAll('ABS', localStorage.getItem('currency'));
}


export const errorToast = (statusMessage) => {
    return (
        toast.error(statusMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    )
}

export const successToast = (statusMessage) => {
    return (
        toast.success(statusMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    )
}



export const INDUSTRY = localStorage.getItem('industry');

export const SUBSCRIPTION = localStorage.getItem('subscription_expiry');

export const INVENTORY = localStorage.getItem('inventory');
export const BANK = localStorage.getItem('bank');
//roles management
export const SALES = localStorage.getItem('sales');
export const PURCHASES = localStorage.getItem('purchase');
export const INVESTMENT = localStorage.getItem('investment');
export const ACCOUNTANT = localStorage.getItem('accountant');
export const REPORTS = localStorage.getItem('reports');
export const DOCUMENTS = localStorage.getItem('documents');
export const FIRST_TIME = localStorage.getItem('first_time');

export const INVOICE_APPROVAL = localStorage.getItem('approvals_owner');

//admin roles
export const ADMIN_ROLE = localStorage.getItem('admin_role');

export const CONFIG = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": localStorage.getItem('token')
    }
};

export const BRANCH_ID = 0;

export const LOADER = <BallTriangle type="BallTriangle" height={80} width={80} arialLabel="loading-indicator" color="blue" />
export const LOADER_DASH = <BallTriangle type="BallTriangle" height={40} width={40} arialLabel="loading-indicator" color="blue" />


export const NHIF = (value) => {
    if (value < 6000) {
        return 150;
    } else if (value > 5999 && value < 8000) {
        return 300
    } else if (value > 7999 && value < 12000) {
        return 400
    } else if (value > 11999 && value < 15000) {
        return 500
    } else if (value > 14999 && value < 20000) {
        return 600
    } else if (value > 19999 && value < 25000) {
        return 750
    } else if (value > 24999 && value < 30000) {
        return 850
    } else if (value > 29999 && value < 35000) {
        return 900
    } else if (value > 34999 && value < 40000) {
        return 950
    } else if (value > 39999 && value < 45000) {
        return 1000
    } else if (value > 44999 && value < 50000) {
        return 1100
    } else if (value > 49999 && value < 60000) {
        return 1200
    } else if (value > 59999 && value < 70000) {
        return 1300
    } else if (value > 69999 && value < 80000) {
        return 1400
    } else if (value > 79999 && value < 90000) {
        return 1500
    } else if (value > 89999 && value < 100000) {
        return 1600
    } else {
        return 1700
    }

}


export const PAYE_ONE = (amount) => {


    if (amount > 23999) {
        return 0.1 * 24000
    } else {
        return 0
    }

}



export const PAYE_ONE_DISABILITY = (amount) => {


    let mnsisf_amount = 174000
    let mamount = (amount - 150000)


    if (mamount > 23999) {
        return 0.1 * 24000
    } else {
        return 0
    }
}


export const rangedds = (startDate, endDate) => ({
    "Today Only": [moment(startDate), moment(endDate)],
    "Yesterday Only": [
        moment(startDate).subtract(1, "days"),
        moment(endDate).subtract(1, "days")
    ],
    "3 Days": [moment(startDate).subtract(3, "days"), moment(endDate)],
    "5 Days": [moment(startDate).subtract(5, "days"), moment(endDate)],
    "1 Week": [moment(startDate).subtract(7, "days"), moment(endDate)],
    "2 Weeks": [moment(startDate).subtract(14, "days"), moment(endDate)],
    "1 Month": [moment(startDate).subtract(1, "months"), moment(endDate)],
    "1 Year": [moment(startDate).subtract(1, "years"), moment(endDate)]
});





export const ranges = (startDate, endDate) => ({
    "January": [moment(new Date().getFullYear() + "-01-01"), moment(new Date().getFullYear() + "-01-31")],
    "February": [moment(new Date().getFullYear() + "-02-01"), moment(new Date().getFullYear() + "-02-28")],
    "March": [moment(new Date().getFullYear() + "-03-01"), moment(new Date().getFullYear() + "-03-31")],
    "April": [moment(new Date().getFullYear() + "-04-01"), moment(new Date().getFullYear() + "-04-30")],
    "May": [moment(new Date().getFullYear() + "-05-01"), moment(new Date().getFullYear() + "-05-31")],
    "June": [moment(new Date().getFullYear() + "-06-01"), moment(new Date().getFullYear() + "-06-30")],
    "July": [moment(new Date().getFullYear() + "-07-01"), moment(new Date().getFullYear() + "-07-31")],
    "August": [moment(new Date().getFullYear() + "-08-01"), moment(new Date().getFullYear() + "-08-31")],
    "September": [moment(new Date().getFullYear() + "-09-01"), moment(new Date().getFullYear() + "-09-30")],
    "October": [moment(new Date().getFullYear() + "-10-01"), moment(new Date().getFullYear() + "-10-31")],
    "November": [moment(new Date().getFullYear() + "-11-01"), moment(new Date().getFullYear() + "-11-30")],
    "December": [moment(new Date().getFullYear() + "-12-01"), moment(new Date().getFullYear() + "-12-31")]
});

export let local = {
    "format": "DD-MM-YYYY",
    "sundayFirst": false
}



export const PAYE = (amount, nsisf) => {
    let paye_one = 0;
    let pay_two = 0;
    let paye_three = 0;



    if ((amount + nsisf) > 23999) {
        paye_one = 0.1 * (24000)
    } else {
        paye_one = 0
    }

    if ((amount + nsisf) > 23999 && (amount + nsisf) > 32333) {
        pay_two = 0.25 * (32333 - (24000))
    } else if ((amount + nsisf) > 24000 && (amount + nsisf) < 32333) {
        pay_two = 0.25 * (amount + nsisf - 24000)
    } else {
        pay_two = 0
    }

    if ((amount + nsisf) > 32333) {
        paye_three = 0.3 * (amount - 32333)
    } else {
        paye_three = 0
    }

    return (paye_one + pay_two + paye_three);
}




export const PAYE_DISABILITY_CALCULATOR = (amount, nsisf) => {
    let paye_one = 0;
    let pay_two = 0;
    let paye_three = 0;



    let mnsisf_amount = 174000 - nsisf
    let mamount = ((amount + nsisf) - 174000)

    let mamount_two = ((amount + nsisf) - 150000)
    if (mamount_two > 23999) {
        if (mamount_two > 23999) {
            paye_one = 0.1 * 24000
        } else {
            paye_one = 0
            // paye_one = 0.1 * (mamount - 150000)
        }


        // if (amount > 24000) {
        //     paye_one = 0.1 * 24000
        // } else {
        //     paye_one = 0
        // }

        if ((amount + nsisf) > 174000) {
            if (mamount_two > (24000) && (mamount_two) > 32333) {
                pay_two = 0.25 * (32333 - (24000))
            } else if (mamount_two > (24000) && (mamount_two) < 32333) {
                pay_two = 0.25 * (mamount_two - 24000)
            } else {
                pay_two = 0
            }

            if (mamount_two > 32333) {
                paye_three = 0.3 * ((mamount_two) - 32333)
            } else {
                paye_three = 0
            }

        } else {
            pay_two = 0;
            paye_three = 0;
        }

    }

    return (paye_one + pay_two + paye_three);
}





export const PAYE_DISABILITY = (amount, nsisf) => { //payee for disability ones

    let paye_one;


    // alert(amount)


    let mnsisf_amount = 174000 - nsisf
    let mamount = ((amount + nsisf) - 150000)

    if (mamount > (23999)) {

        if ((mamount) > 32333) {
            paye_one = 0.3 * ((mamount) - 32333)
        } else {
            paye_one = 0
        }

        // paye_one = 0.1 * (amount - (150000 - nsisf))
    } else {
        paye_one = 0
    }



    return paye_one;
}


export const TAX_RELIEF = 2400;

