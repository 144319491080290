import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import logo from '../../../shared/img/logo/3.png';
import { CONFIG, errorToast, SUBSCRIPTION, successToast, ToastTable, USER_ID } from '../../../_helpers/exports';
import axios from 'axios';
import { baseURL } from '../../../_helpers';
import * as moment from 'moment';

const ExtendPayments = () => {

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
    }, []);

    //  var months = moment(new Date(new Date().setDate(new Date().getDate() + 30))).format('YYYY-MM-DD HH:mm:ss')


    const onSubmit = (e) => {
        e.preventDefault();
        // alert("Gg")
        let formData = {
            "client_id": USER_ID,
            "subscription": moment(new Date(new Date().setDate(new Date().getDate() + 15))).format('YYYY-MM-DD HH:mm:ss')
        }
        setIsLoading(true);
        axios.put(baseURL + `extend_trial`, formData, CONFIG)
            .then((response) => {
                // console.log('bayoo', response.data)
                if (response.data.status) {
                    successToast(response.data.message);
                    setIsLoading(false);
                    window.scrollTo(0, 0)
                    window.setTimeout(() => {
                        window.location.href = "/log_in";
                    }, 3000);
                } else {
                    errorToast(response.data.message);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                // alert(JSON.stringify(error));
                errorToast("Something went wrong");
                setIsLoading(false);
            })
    }


    return (
        <div className="account account">
            <ToastTable />
            <div className="account__wrapper">
                <div className="account__card" >
                    <Link to="/">
                        <img src={logo} alt="" />
                    </Link>
                    <div className="col-md-12">
                        <div className="form-group"><br />
                            <div className="col-md-12">
                                {
                                    <Button className="account__btn" onClick={onSubmit} type='submit' outline color="primary"> {
                                        isLoading ? "Please wait..." : "Extend Trial Period"
                                    }</Button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Link to="/payments">
                            <div className="form-group">
                                <div className="col-md-12">
                                    {
                                        <Button className="account__btn" type='submit' outline color="primary"> {
                                            isLoading ? "Please wait..." : "Subscribe to a package"
                                        }</Button>
                                    }
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="account__have-" >
                        <p className="text-center">Our contact</p>
                        <p className="text-center"><b style={{ color: "blue" }}>+254736105920</b></p>

                        <p className="text-center"><b style={{ color: "blue" }}>peakbooks@peakbooks.biz</b></p>
                    </div>


                </div>
            </div>
        </div >

    )

}
export default ExtendPayments;