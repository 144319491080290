import React, { Fragment } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { errorToast, successToast, ToastTable, TOKEN, USER_ID } from '../../_helpers/exports';
import * as moment from 'moment';
// import CustomerCreate from './createItem';
import { Link } from 'react-router-dom';
import { confirm } from "../../shared/components/confirm";
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import Alert from '../../shared/components/Alert';
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";
// import CustomerEdit from './customer_edit';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'



export class Documents extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.applyCallback = this.applyCallback.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // this.handleCurrency = this.handleCurrency.bind(this);
        this.uploadProfile = this.uploadProfile.bind(this);

        this.toggleModalCreate = this.toggleModalCreate.bind(this);

        this.columns = [
            {
                key: "document_name",
                TrOnlyClassName: 'tsc',
                text: "Document Name",
                className: "tsc",
                align: "left"
            },


            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date Uploaded",
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        // eslint-disable-next-line
                        <Fragment className="center" >
                            <a href={record.url} target="_blank">
                                <button className="btn btn-primary  btn-sm"
                                    title="Edit details"
                                    style={
                                        { marginRight: '10px' }}
                                >
                                    View Document
                                </button>
                            </a>

                            <button className="btn btn-danger  btn-sm"
                                title="Delete details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick(record) }} >
                                Delete
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            key_column: "tsc",
            length_menu: [150, 200, 300, 400, 500],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 100,
            button: {
                excel: true,
                csv: true
            },
            filename: "document",
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search.. Document",
                no_data_text: "No document was uploaded",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        let now = new Date();
        let start = moment(new Date(new Date().getFullYear(), 0, 1));
        let end = moment(new Date());

        this.state = {

            isLoading: true,
            showError: false,
            isShowError: false,
            isOpen: false,
            start: start,
            end: end,
            showComponent: false,
            _onShow: false,
            showEdit: false,
            hideComponent: false,
            data: [],
        };
    }



    async handleOnClick(record) {
        if (await confirm("Are your sure?")) {
            this.onDelete(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    toggleModalCreate = e => {
        this.setState({
            isOpen: true,
        });
    };

    closeModalUpload = e => {
        this.setState({
            isOpen: false,
        });
    };

    //handle profile picture upload
    handleProfile = event => {
        this.setState({
            imagefile: event.target.files[0]
        });
    };

    uploadProfile = event => {
        event.preventDefault();
        var s = localStorage.getItem("profile_url");
        var final = s.substr(s.lastIndexOf('/') + 1);
        if (this.state.imagefile) {
            console.log("bsdkkd ",this.state.imagefile)
            // if (this.state.imagefile.size < 5242880) {
                this.setState({ loading: true });
                var data = new FormData();
                data.append("filename", this.state.imagefile);
                data.append("profile_url", final);
                data.append("user_id", USER_ID);
                this.setState({ isLoading1: true });
                axios.post(baseURL_2 + 'uploaddocument.php', data).then((response) => {
                    // eslint-disable-next-line
                    if (response.data.status = true) {

                        successToast(response.data.message)

                        this.setState({ loading: false, isLoading1: false },
                            function () {
                                console.log("bayo")
                                setTimeout(() => {
                                    this.setState({ loading: false, isLoading1: false })
                                    this.closeModalUpload();

                                    this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                                }, 2000);
                            }
                        );
                    } else {

                        errorToast(response.data.message)

                        this.setState({ showError: true, loading: false, isLoading1: false },
                            function () {
                                setTimeout(() => {
                                    this.setState({ statusMessage: "", isShowError: false, showError: false, isLoading1: false })
                                }, 2000);
                            }
                        );
                    }
                }).catch((error) => {
                    console.log('bayoo', error.response)

                    errorToast(error.response.data.message)
                    this.setState({ showError: true, isLoading1: false },
                        function () {
                            setTimeout(() => {
                                this.setState({ isLoading1: false })
                            }, 2000);
                        }
                    );
                })
            // } else {
            //     alert("Your logo should not be more than 5mbs")
            // }
        } else {
            errorToast("Please attach document")

        }
    }

    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {

        this.setState({
            startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
            endDate: moment(new Date()).format('DD MMM, YYYY'),
            isPageLoad: true,
            valuedate: "This Year"
        })

        this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
    }

    getData = (queryString = "", startDate, endDate) => {
        // alert(USER_ID)
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let startD = this.props.status === "staff" ? '2000-01-01' : startDate
        let endD = this.props.status === "staff" ? '2060-01-01' : endDate
        let url = baseURL + `document?id=${USER_ID}&startDate="${startD} 00:00:00"&endDate="${endD} 23:59:59"&${queryString}`
        // // let url = baseURL + `document?id=` + USER_ID + "&&" + queryString
        // alert(JSON.stringify(queryString))
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((itemResponse) => {
            this.setState({
                item: itemResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    this.setState({
                        total: itemResponse.data.total
                    })
                    if (this.state.item.length > 0) {
                        for (let i = 0; i < this.state.item.length; i++) {
                            let date = { dates: (moment.utc(this.state.item[i].date_created).format('DD/MM/YYYY')) };
                            data.push(Object.assign(date, this.state.item[i]));
                            this.setState({
                                data: data
                            })
                        }
                    }
                    else {
                        this.setState({
                            data: data
                        })
                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)
        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString, moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(localStorage.getItem("ENDDATE")).format('YYYY-MM-DD'))
    }
    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showEdit: false,
        });
    }

    onDelete(record) {

        var s = record.url;
        var final = s.substr(s.lastIndexOf('/') + 1);

        var data = new FormData();
        data.append("profile_url", final);
        data.append("user_id", record.id);

        axios.post(baseURL_2 + "deletedocument.php", data).then(response => {
            console.log("true", response.data)
            if (response.data.status) {

                successToast(response.data.message)
                this.setState({ isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {

                errorToast(response.data.message)
                this.setState({ isLoading: false });
                window.setTimeout(() => {
                    this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                    this.setState({ isShowError: false })
                }, 2000);
            }
        }, error => {
            errorToast(JSON.stringify(error))
            this.setState({ isLoading: false });
            window.setTimeout(() => {
                this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
                this.setState({ isShowError: false })
            }, 2000);
        });
    }


    getDataUpdate() {
        let queryString = ("filter_value=&page_number=0&page_size=10&sort_order=false")

        this.getData("", moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
    }


    applyCallback(startDate, endDate) {
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)
        let Sdate1 = moment(startDate).format('DD MMM, YYYY');
        let Edate2 = moment(endDate).format('DD MMM, YYYY');
        this.setState({
            valuedate: Sdate1 + " " + Edate2,
            startDate: Sdate1,
            isPageLoad: true,
            endDate: Edate2
        })

        this.getData("", moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
    }



    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        const { showEdit } = this.state;


        let ranges = {
            "Today Only": [moment(this.state.start), moment(this.state.end)],
            "Yesterday Only": [
                moment(this.state.start).subtract(1, "days"),
                moment(this.state.end).subtract(1, "days")
            ],
            "3 Days": [moment(this.state.start).subtract(3, "days"), moment(this.state.end)],
            "5 Days": [moment(this.state.start).subtract(5, "days"), moment(this.state.end)],
            "1 Week": [moment(this.state.start).subtract(7, "days"), moment(this.state.end)],
            "2 Weeks": [moment(this.state.start).subtract(14, "days"), moment(this.state.end)],
            "1 Month": [moment(this.state.start).subtract(1, "months"), moment(this.state.end)],
            "1 Year": [moment(this.state.start).subtract(1, "years"), moment(this.state.end)]
        };
        let local = {
            "format": "DD-MM-YYYY",
            "sundayFirst": false
        }
        return (
            <div >
                {!hideComponent && (
                    <div>
                        < Card >
                            {ToastTable()}

                            <Modal
                                isOpen={this.state.isOpen}
                                onRequestClose={e => {
                                    this.closeModalUpload(e);
                                }}
                                contentLabel="My dialog"
                                className="mymodal"
                                onAfterOpen={() => {
                                    document.body.style.overflow = 'hidden';
                                }}
                                onAfterClose={() => {
                                    document.body.removeAttribute('style');
                                }}
                                overlayClassName="myoverlay"
                                closeTimeoutMS={500}
                            >
                                <MDBCloseIcon onClick={this.closeModalUpload} />
                                <h4><b>Upload Files</b></h4><br />

                                <div className="wrapper text-center">
                                    <div className="section1">
                                        <div className="contain">
                                            <input
                                                className="csv-input"
                                                type="file"
                                                required
                                                ref={input => {
                                                    this.filesInput = input;
                                                }}
                                                name="file"
                                                customHeight
                                                placeholder={null}
                                                onChange={this.handleProfile}
                                            />
                                            <br />
                                            <Button color="primary" className="btn-paypal btn-lg " onClick={this.uploadProfile} >
                                                {this.state.isLoading1 ? "Uploading..." : "Upload now!"}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;

                                            <br />
                                        </div>
                                    </div>
                                </div>

                            </Modal>
                            <CardBody >
                                <div className="panel-body" >
                                    <div className="row">

                                        <div className="col-md-4">
                                            <h4><b>  {this.props.status === "staff" ? '' : 'Documents'}</b></h4>
                                        </div>
                                        {/* {this.props.status === "staff" ? */}
                                            <div className="col-md-6">
                                                <Button className="pull-right" color="primary
                                                    " onClick={this.toggleModalCreate} outline>Upload Document
                                                </Button>
                                            </div>
                                            {/* : null} */}
                                        <div className="col-md-8">
                                            <div className="col-md-8">
                                                {this.props.status !== "staff" ?
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <DateTimeRangeContainer
                                                                ranges={ranges}
                                                                start={moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1))}
                                                                end={this.state.end}
                                                                local={local}
                                                                applyCallback={this.applyCallback}>
                                                                <FormControl
                                                                    id="formControlsTextB"
                                                                    type="text"
                                                                    value={this.state.valuedate}
                                                                    label="Text"
                                                                    placeholder="Filter by Date"
                                                                />
                                                            </DateTimeRangeContainer>
                                                        </div>

                                                        <div className="col-6 float-left">

                                                            <button className=" float-left btn btn-primary btn-sm" onClick={e => {
                                                                // this.getData();
                                                                this.getData("", moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))

                                                                this.setState({
                                                                    startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
                                                                    endDate: moment(new Date()).format('DD MMM, YYYY'),
                                                                    isPageLoad: true,
                                                                    valuedate: "This Year"
                                                                })
                                                            }} >Clear</button>
                                                            {/* <input id="input" type="text" readOnly /> */}
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div><br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            {/* <h4>Your documents as at Now: <b>{this.state.total}</b></h4> */}
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="col-md-10 offset-1">
                                                <button className="pull-right" title="refresh" style={{ background: "white", border: "0px" }
                                                } onClick={this.getDataUpdate}>
                                                    <RefreshIcon />
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>


                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} />

                                </div>
                            </CardBody>

                        </Card>
                    </div>
                )
                }
            </div >
        )
    }
}