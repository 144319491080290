import { BUSINESS_TYPE, COMPANY_NAME, COMPANY_POSTAL_ADDRESS, CONFIG, FOOTER, res, USER_ID } from '../../_helpers/exports';
import pdfMake from "pdfmake/build/pdfmake";

export function savePaySlip(params) {
    createPDFFromParams(params);

}


function createPDFFromParams(params) {
    let docDefinition = buildDocDefinition(params);
    // alert(localStorage.getItem("image_urls"))

    pdfMake.createPdf(docDefinition).open();
    const pdfGen = pdfMake.createPdf(docDefinition);
    pdfGen.getBase64((data) => {
        console.log("datafile", data)
    })
}

function buildDocDefinition(params) {
    return {
        header: {
            columns: [
                headerNotes(BUSINESS_TYPE === 'business' ? params : "")
            ]
        },
        permissions: {
            printing: 'highResolution', //'lowResolution'
            modifying: false,
            copying: false,
            annotating: true,
            fillingForms: true,
            contentAccessibility: true,
            documentAssembly: true
        },
        background: [
            {
                image: localStorage.getItem("image_urls"),
                alignment: 'center',
                width: 270,
                height: 280,
                marginLeft: 100,
                marginTop: 360,
                opacity: 0.1,
                borderRadius: 25,
            },
        ],

        content: [
            buildHeaderInformation(params),
            headerLine(params),
            buildHeaderInformation3(params)
        ]
    };
}
// Returns an array
function headerNotes(params) {
    if (params.status === 0) {
        if (params.due_period < 0) {
            return [
                { text: `\t Due in ${params.due_period * -1} days\t\t`, alignment: 'left', background: '#5bc0de', color: '#FFFFFF', margin: [5, 15, 0, 5] }
            ]
        }
        if (params.due_period > 0) {
            return [
                { text: `\t Overdue by ${params.due_period} days\t\t`, alignment: 'left', background: '#FF0000', color: '#FFFFFF', margin: [5, 15, 0, 5] }
            ]
        }
        if (params.due_period === 0) {
            return [
                { text: '\t Due today\t\t', alignment: 'left', background: '#5bc0de', color: '#FFFFFF', margin: [5, 15, 0, 5] }
            ]
        }
    } else if (params.status === 1) {
        return [
            { text: `\tPAID\t\t`, alignment: 'left', background: '#5cb85c', color: '#FFFFFF', margin: [5, 15, 0, 5] }
        ]
    }
}

function buildHeaderInformation(params) {
    return {
        columns: [{
            stack: [
                {
                    image: localStorage.getItem("image_urls"),
                    width: 90,
                    height: 90,
                    margin: [210, 0, 0, 15],
                },
                {
                    text: COMPANY_NAME,
                    color: "black",
                    margin: [210, 0, 0, 0],
                    // background: '#eee',
                }
            ]
        },
        ],
    };
}


function buildHeaderInformation3(params) {
    return [
        {
            columns: [
                {
                    stack: [
                        {
                            text: "Name:",
                            color: "black",
                            fontSize: 13,
                            margin: [20, 10, 0, 5],
                        },
                        {
                            text: "Job Title:",
                            color: "black",
                            fontSize: 13,
                            margin: [20, 5, 0, 5],
                            // background: '#eee',
                        },

                        {
                            text: "Staff Number:",
                            color: "black",
                            fontSize: 13,
                            margin: [20, 5, 0, 5],
                            // background: '#eee',
                        },
                        {
                            text: "Department:",
                            color: "black",
                            fontSize: 13,
                            margin: [20, 10, 0, 10],
                            // background: '#eee',
                        },

                        {
                            text: "EARNINGS/BENEFITS:",
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [20, 5, 0, 10],
                            // background: '#eee',
                        },
                        {
                            text: "Basic Pay:",
                            color: "black",
                            fontSize: 13,
                            margin: [20, 5, 0, 5],
                            // background: '#eee',
                        },
                        {
                            text: "Other benefits:",
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [20, 5, 0, 5],
                        },
                        {
                            text: "Gross Pay:",
                            color: "black",
                            fontSize: 13,
                            margin: [20, 10, 0, 10],
                        },

                        {
                            text: "STATUTORY DEDUCTIONS:",
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [20, 5, 0, 10],
                        },

                        {
                            text: "Income Tax:",
                            color: "black",
                            fontSize: 13,
                            margin: [20, 5, 0, 5],
                        },
                        {
                            text: "Tax Relief:",
                            color: "black",
                            fontSize: 13,
                            margin: [20, 5, 0, 5],
                        },
                        {
                            text: "P.A.Y.E:",
                            color: "black",
                            fontSize: 13,
                            margin: [20, 5, 0, 5],
                        },
                        {
                            text: "N.S.S.F:",
                            color: "black",
                            fontSize: 13,
                            margin: [20, 5, 0, 5],
                        },

                        {
                            text: "N.H.I.F:",
                            color: "black",
                            fontSize: 13,
                            margin: [20, 5, 0, 5],
                        },
                        {
                            text: "Insurance Relief:",
                            color: "black",
                            fontSize: 13,
                            margin: [20, 5, 0, 5],
                        },

                        {
                            text: "OTHER DEDUCTIONS:",
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [20, 5, 0, 10],
                        }


                    ],

                },
                {
                    stack: [
                        {
                            text: params.name === null || params.name === "" ? 'N/A` ' : params.name,
                            margin: [0, 10, 0, 5],
                            color: "black",
                            bold: true,
                            fontSize: 13,
                        },
                        {
                            text: params.job_title === null || params.job_title === "" ? 'N/A` ' : params.job_title,
                            color: "black",
                            bold: true,
                            fontSize: 13,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: params.staff_number === null || params.staff_number === "" ? 'N/A` ' : params.staff_number,
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [0, 5, 0, 5],
                        },

                        {
                            text: params.department_name === null ? 'N/A' : params.department_name,
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [0, 10, 0, 20],
                        },
                        {
                            text: params.mgross,
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [0, 25, 0, 5],
                        },
                        {
                            text: params.mother_benefits,
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: params.mbenefits,
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [0, 10, 0, 35],
                        },

                        {
                            text: params.mincometax,
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [0, 10, 0, 5],
                        },


                        {
                            text: params.mtax,
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: params.mpaye,
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: params.mnsisf,
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: params.mnhif,
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: params.minsurance_relief,
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: params.motherdeduction,
                            color: "black",
                            fontSize: 13,
                            bold: true,
                            margin: [0, 5, 0, 5],
                        }
                    ],

                },

            ],
            styles: {
                subheader: {
                    fontSize: 9,
                    color: '#FFFFFF',
                    background: '#2361AE',
                    margin: [0, 0, 0, 0]
                }
            },
            // optional space between columns
        },
        { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 520 - 10, y2: 10, lineWidth: 2 }] },
        {
            columns: [
                {
                    stack: [
                        {
                            text: "Net Pay:",
                            color: "black",
                            bold: true,
                            fontSize: 13,
                            margin: [20, 0, 0, 0],
                        },
                    ],
                },
                {
                    stack: [
                        {
                            text: params.mnet_pay,
                            margin: [0, 0, 0, 0],
                            color: "black",
                            bold: true,
                            fontSize: 13,
                        },
                    ],
                }
            ]
        },
        { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 520 - 10, y2: 10, lineWidth: 2 }] },

        {
            columns: [
                { text: `PERSONAL INFO:`, bold: true, color: 'black', fontSize: 12, alignment: 'center', marginTop: 20 }
            ],
        },
        { canvas: [{ type: 'line', x1: 10, y1: 0, x2: 520 - 10, y2: 0, lineWidth: 2, }] },
        {
            columns: [
                {
                    stack: [
                        {
                            text: "Payment Mode",
                            margin: [20, 5, 0, 0],
                            color: "black",
                            fontSize: 13,
                        },
                    ],
                }, {
                    stack: [
                        {
                            text: params.payment_mode === null || params.payment_mode === "" ? '` ' : params.payment_mode,
                            margin: [0, 5, 0, 0],
                            color: "black",
                            fontSize: 13,
                        },
                    ],
                },
                {
                    stack: [
                        {
                            text: "ID:",
                            margin: [0, 5, 0, 0],
                            color: "black",
                            bold: true,
                            fontSize: 13,
                        },
                    ],
                },
                {
                    stack: [
                        {
                            text: params.id_no === null || params.id_no === "" ? '` ' : params.id_no,
                            margin: [0, 5, 0, 0],
                            color: "black",
                            bold: true,
                            fontSize: 13,
                        },
                    ],
                },
            ],
        },

        {
            columns: [
                {
                    stack: [
                        {
                            text: "",
                            margin: [20, 5, 0, 0],
                            color: "black",
                            fontSize: 13,
                        },
                    ],
                }, {
                    stack: [
                        {
                            text: params.bank_name === null || params.bank_name === "" ? '` ' : params.bank_name,
                            margin: [0, 5, 0, 0],
                            color: "black",
                            bold: true,
                            fontSize: 13,
                        },
                    ],
                },
                {
                    stack: [
                        {
                            text: "PIN:",
                            margin: [0, 5, 0, 0],
                            color: "black",
                            bold: true,
                            fontSize: 13,
                        },
                    ],
                },
                {
                    stack: [
                        {
                            text:  params.kra_pin === null || params.kra_pin === "" ? '` ' : params.kra_pin,
                            margin: [0, 5, 0, 0],
                            color: "black",
                            bold: true,
                            fontSize: 13,
                        },
                    ],
                },
            ],
        },

        {
            columns: [
                {
                    stack: [
                        {
                            text: "",
                            margin: [20, 5, 0, 0],
                            color: "black",
                            fontSize: 13,
                        },
                    ],
                }, {
                    stack: [
                        {
                            text: params.account_number === null || params.account_number === "" ? '` ' : params.account_number,
                            margin: [0, 5, 0, 0],
                            color: "black",
                            bold: true,
                            fontSize: 13,
                        },
                    ],
                },
                {
                    stack: [
                        {
                            text: "NSSF No.:",
                            margin: [0, 5, 0, 0],
                            color: "black",
                            bold: true,
                            fontSize: 13,
                        },
                    ],
                },
                {
                    stack: [
                        {
                            text: params.nssf_no === null || params.nssf_no === "" ? '` ' : params.nssf_no,
                            margin: [0, 5, 0, 0],
                            color: "black",
                            bold: true,
                            fontSize: 13,
                        },
                    ],
                },
            ],
        },

        {
            columns: [
                {
                    stack: [
                        {
                            text: "",
                            margin: [20, 5, 0, 0],
                            color: "black",
                            fontSize: 13,
                        },
                    ],
                }, {
                    stack: [
                        {
                            text: "",
                            margin: [0, 5, 0, 0],
                            color: "black",
                            bold: true,
                            fontSize: 13,
                        },
                    ],
                },
                {
                    stack: [
                        {
                            text: "NHIF No:",
                            margin: [0, 5, 0, 0],
                            color: "black",
                            bold: true,
                            fontSize: 13,
                        },
                    ],
                },
                {
                    stack: [
                        {
                            text: params.nhif_no === null || params.nhif_no === "" ? '` ' : params.nhif_no,
                            margin: [0, 5, 0, 0],
                            color: "black",
                            bold: true,
                            fontSize: 13,
                        },
                    ],
                },
            ],
        },
    ]
}

// line and date
function headerLine(params) {
    return [
        { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 520 - 10, y2: 10, lineWidth: 2 }] },
        {
            columns: [
                { text: `Pay Slip for Month of ${params.payment_date}`, bold: true, color: 'black', fontSize: 12, alignment: 'center', marginTop: 2 }
            ]
        },
    ];
}



