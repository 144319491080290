import React, { Fragment } from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import ReactDatatable from '@mkikets/react-datatable';
import { baseURL, baseURL_2 } from '../../_helpers';
import { TOKEN, USER_ID, CONFIG, BUSINESS_TYPE, BRANCH_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { withTranslation } from 'react-i18next';
import PropTypes, { func } from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import Alert from '../../shared/components/Alert';
import CreatableSelect from 'react-select/creatable';
import * as moment from 'moment';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import $ from 'jquery';
import { Expenses_View } from './expense_view';
import { costofgoodOptions, groupedOptions } from './data';
import { currentassets, groupedOptions_two } from './paidthrough';
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";

class ExpenseCreate extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
            isCostGoods: false,
            moreinfo: false,
        };
        this.uploadProfile = this.uploadProfile.bind(this);

        this.toggleModalCreate = this.toggleModalCreate.bind(this);

        this._onButtonClick = this._onButtonClick.bind(this);
        this._onMoreInfo = this._onMoreInfo.bind(this);
        this.handleChangeTax = this.handleChangeTax.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.config = {
            key_column: "tsc",
            length_menu: [100, 200, 500],
            show_filter: false,
            show_pagination: false,
            pagination: 'advance',
            // show
            page_size: 100,
            button: {
                excel: false,
                csv: false
            },
            filename: "expenses",
            show_length_menu: false,
            // language: {
            //     loading_text: "Please be patient while data loads...",
            //     filter: "Search...",
            //     no_data_text: "No expense was found",
            //     pagination: {
            //         next: <span>&#9658;</span>,
            //         previous: <span>&#9668;</span>
            //         // next
            //         // previous
            //     }
            // }

        }



        this.columns = [

            {
                key: "label",
                TrOnlyClassName: 'call',
                text: "Name",
                className: "call",
                align: "left"
            },

            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                sortable: false,
                cell: record => {
                    return (
                        // eslint-disable-next-line
                        <Fragment className="center" >
                            <button className="btn btn-danger  btn-sm"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}

                                onClick={
                                    () => {
                                        if (window.confirm(`Are you sure you want to delete this ${record.label} expense account?`))

                                            this.onDelete(record)
                                    }}

                            >
                                <i className='fa fa-close'></i>
                            </button>



                        </Fragment>
                    );
                }
            }
        ];
        this.state = {
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            tax_amount: 0,
            showError: false,
            date2: new Date(),

            type: [{
                name: 'Mr.'
            }, {
                name: 'Mrs.'
            },
            {
                name: 'Ms.'
            },
            {
                name: 'Miss.'
            },
            {
                name: 'Dr.'
            }],

            priority: [{
                name: 'Print Later'
            }, {
                name: 'Send Later'
            },
            {
                name: 'None'
            }],

            tax: [
                {
                    label: 'VAT (16%)',
                    value: 16
                }

            ],

            expense_account: [
                {
                    label: 'Professional Fees',
                    value: 'Professional Fees'
                },
                {
                    label: 'Administration Expenses',
                    value: 'Administration Expenses'
                },
                {
                    label: 'Staff Expenses',
                    value: 'Staff Expenses'
                },
                {
                    label: 'Allowances for Asset Impairements',
                    value: 'Allowances for Asset Impairements'
                },
                {
                    label: 'Finance Costs',
                    value: 'Finance Costs'
                },
                {
                    label: 'Establishment Expenses',
                    value: 'Establishment Expenses'
                },
                {
                    label: 'Other Expenses',
                    value: 'Other Expenses'
                }

            ],
            product: [
                // {
                //     label: 'VAT (16%)',
                //     value: 16
                // }
            ],

            avenue: '',
            assigned_to: '',
            product2: false,
            expense_category: '',
            expense_name: '',
            amount: 0,
            billing: 0,
            isCostGoods: false,
            bayo: [],
            isOpen: false,
            isOpenAccount: false,
            product_name: '',
            selectedCounty: 'Select County/State Name',
            expenseData: [],
            measurements: "",
            item_reference: "",
            item_id: 0,
            check_expiry: 0,
            expiry_date: new Date(),
            item_name: ""
        }
    }

    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    handleChangeTerms = value => {


        console.log("logs values", value)
        if (value != null || value.value != null) {

            if (value.value.toString() == "Land" || value.value.toString() == "Furniture Fittings" ||
                value.value.toString() == "Equipments" || value.value.toString() == "Computers & peripherals"
                || value.value.toString() == "Motor Vehicles" || value.value.toString() == "Revaluation Reserves") {

                this.setState(
                    {
                        terms: "Furniture and Equipment",
                    });
            } else {

                if (value.value.toString() == "Cost of Goods Sold") {
                    this.setState(
                        {

                            terms: value.value.toString(),
                            isCostGoods: true,
                        });

                } else {
                    this.setState(
                        {
                            terms: value.value.toString(),
                            isCostGoods: false,
                        });

                }
            }
        }
        else {
            this.setState(
                {
                    terms: "",
                });
        }

    };


    onDelete(record) {

        axios.delete(baseURL + `expense_account_one?id=` + record.id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {

                this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading1: false });
                window.scrollTo(0, 0)
                this.closeModalExpenseAccount();
                this.load();
                window.setTimeout(() => {
                    this.setState({ isShowError: false });
                }, 3000);
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 2000);
        });
    }
    toggleModalCreate = e => {
        this.setState({
            isOpen: true,
        });
    };

    closeModalUpload = e => {
        this.setState({
            isOpen: false,
        });
    };


    // modal expense account

    toggleModalOpen = e => {
        this.setState({
            expense_name: e,
            isOpenAccount: true,
        });
    };

    toggleModalOpenButton = (e) => {
        this.setState({
            isOpenAccount: true,
            isEditing: e
        });
    };

    closeModalExpenseAccount = e => {
        this.setState({
            isOpenAccount: false,
        });
    };

    //handle profile picture upload
    handleProfile = event => {
        this.setState({
            imagefile: event.target.files[0]
        });
    };

    uploadProfile = event => {
        event.preventDefault();
        var s = localStorage.getItem("profile_url");
        var final = s.substr(s.lastIndexOf('/') + 1);
        if (this.state.imagefile.size < 5242880) {
            this.setState({ loading: true });
            var data = new FormData();
            data.append("filename", this.state.imagefile);
            data.append("profile_url", final);
            data.append("user_id", USER_ID);
            this.setState({ isLoading1: true });
            axios.post(baseURL_2 + 'uploaddocument.php', data).then((response) => {
                // eslint-disable-next-line
                if (response.data.status = true) {
                    this.setState({ statusMessage: response.data.message, isShowError: true, loading: false, isLoading1: false },
                        function () {
                            console.log("bayo")
                            setTimeout(() => {
                                this.setState({ statusMessage: "", isShowError: false, loading: false, isLoading1: false })
                                this.closeModalUpload();

                                // this.getData();
                            }, 2000);
                        }
                    );
                } else {
                    this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, loading: false, isLoading1: false },
                        function () {
                            console.log("bayoddd", data)
                            setTimeout(() => {
                                this.setState({ statusMessage: "", isShowError: false, showError: false, isLoading1: false })
                            }, 2000);
                        }
                    );
                }
            }).catch((error) => {
                console.log('bayoo', error.response)
                this.setState({ isShowError: false, showError: false, statusMessage: error.response.data.message, showError: true, isLoading1: false },
                    function () {
                        setTimeout(() => {
                            this.setState({ statusMessage: "", isShowError: false, showError: false, isLoading1: false })
                        }, 2000);
                    }
                );
            })
        } else {
            alert("Your logo should not be more than 5mbs")
        }
    }

    // submit expense account
    saveExpenseAccount = event => {
        event.preventDefault();

        let formData = {
            "created_by": USER_ID,
            "name": this.state.expense_name,
            "category_type": this.state.expense_category,
            "category": "Expenses"
        }

        this.setState({ isLoading1: true });
        axios.post(baseURL + `expense_account`, formData, CONFIG)
            .then((response) => {
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading1: false });
                    window.scrollTo(0, 0)
                    this.closeModalExpenseAccount();
                    this.load();
                    window.setTimeout(() => {
                        this.setState({ isShowError: false, expense_name: "" });
                    }, 3000);
                } else {
                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading1: false });
                }
            })
            .catch((error) => {
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading1: false });
            })
    }

    handleChangePaid = value => {

        if (value != null) {

            if (value.value.toString() == "Land" || value.value.toString() == "Furniture Fittings" ||
                value.value.toString() == "Equipments" || value.value.toString() == "Computers & peripherals"
                || value.value.toString() == "Motor Vehicles" || value.value.toString() == "Revaluation Reserves") {

                this.setState(
                    {
                        paid_through: "Furniture and Equipment",
                    });

            } else {
                this.setState(
                    {
                        paid_through: value.value.toString(),
                    });
            }

        }
        else {
            this.setState(
                {
                    paid_through: "",
                });
        }
    };

    handleChangeExpenseAccount = value => {

        if (value != null) {
            // if(valu)
            this.setState(
                {
                    expense_category: value.value.toString(),
                });
        }
        else {
            this.setState(
                {
                    expense_category: "",
                });
        }
    };


    loadOptions = inputValue => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                Authorization: TOKEN,
            },
        };
        this.setState({
            isLoading: true,
        });
        var encodeURISafe = require('encodeuri-safe');
        // alert()
        let url = `${baseURL}item?branch_id=${BRANCH_ID}&&client_id=${USER_ID}&filter_value=${encodeURISafe.encodeURIComponent(inputValue)}`

        // let url =
        //   baseURL + `item?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&filter_value=${inputValue}`;
        axios.get(url, axiosConfig).then(itemResponse => {
            this.setState(
                {
                    item: itemResponse.data.data.filter(item => item.status === 1),
                },
                function () {
                    // if (this.state.item.length > 0) {
                    this.setState({
                        isLoading: false,
                        bayo: this.state.item &&
                            (this.state.item.length > 0 || this.state.item.length === 0) &&
                            this.state.item.map((countyItem, i) => ({
                                label: countyItem.item_name,
                                value: countyItem.id,
                                rate: countyItem.rate,
                                item_id: countyItem.id,
                                quantity: countyItem.quantity,
                                measurements: countyItem.measurements,
                                item_reference: countyItem.reference,
                                check_expiry: countyItem.expiry_status,
                                expiry_date: countyItem.end_date,
                                item_name: countyItem.item_name,

                            })),
                    });
                    // } else {
                    //   // this.handleCreate(inputValue)
                    // }
                }
            );
        });
    };





    handleChangeProduct = value => {

        if (value != null) {
            this.setState(
                {
                    product: value.value.toString(),
                });
        }
        else {
            this.setState(
                {
                    product: "",
                });
        }
    };



    onSubmitTax(e) {

        e.preventDefault();

        let formData = {
            "created_by": USER_ID,
            "tax_name": this.state.tax_name,
            "tax_value": this.state.tax_value
        }
        // console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading_tax: true });
        axios.post(baseURL + `tax`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)
                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading_tax: false });
                    window.scrollTo(0, 0)
                    this.closeModal();
                    this.componentDidMount();

                    window.setTimeout(() => {
                        this.setState({ isShowError: false, sales_person: "" });
                    });
                } else {
                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading_tax: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading_tax: false });
            })
    }

    handleChangeTax = value => {

        if (value != null) {
            if (this.state.amount == 0) {
                this.setState(
                    {
                        tax_amount: value.value.toString(),
                        total_amount: 0

                    });
            }
            else {
                this.setState(
                    {
                        tax_amount: value.value.toString(),
                        total_amount: parseFloat(this.state.amount.replace(",", "")) + ((value.value.toString() / 100) * parseFloat(this.state.amount.toString().replace(",", "")))

                    });
            }
        }
        else {


            this.componentDidMount();

            if (this.state.amount == 0) {
                this.setState(
                    {
                        tax_amount: 0,
                        total_amount: 0,
                        // taxparams: [{label: "Select Tax", value: 0}]

                    });
            } else {
                this.setState(
                    {
                        tax_amount: 0,
                        // taxparams: [{ label: "Select Tax", value: 0 }],
                        total_amount: parseFloat(this.state.amount.replace(",", "")) + 0

                    });
            }
        }
    };
    async componentDidMount() {
        $('#message').countSms('#sms-counter');


        // alert(groupedOptions)
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `vendor?branch_id=${BRANCH_ID}&&page_size=10000&page_number=1&client_id=` + USER_ID
        let url2 = baseURL + `customer?branch_id=${BRANCH_ID}&&filter_value=&page_number=1&page_size=1000&sort_order=false&client_id=` + USER_ID
        let url3 = baseURL + `deposit_to`
        let url4 = baseURL + `bank?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID
        let url_tax = baseURL + `tax?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID
        let url6 = baseURL + `expense_account?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID


        this.setState({
            isLoading: true
        })

        console.log("query", url);

        axios.all([
            axios.get(url, axiosConfig),
            axios.get(url2, axiosConfig),
            axios.get(url3, axiosConfig),
            axios.get(url4, axiosConfig),
            axios.get(url_tax, axiosConfig),
            axios.get(url6, axiosConfig)

        ]).then(axios.spread((vendorResponse, customerResponse, paymentResponse, bankResponse, taxResponse, expenseResponse) => {

            this.setState({
                customerResponse: customerResponse.data.data,
                vendorResponse: vendorResponse.data.data,
                paymentResponse: paymentResponse.data.data,
                bankResponse: bankResponse.data.data,
                tax: taxResponse.data.data,
                expenseData: expenseResponse.data


            }, function () {

                // alert(JSON.stringify(taxResponse.data.data))

                this.setState({
                    isLoading: false,

                    taxparams: this.state.tax &&
                        (this.state.tax.length > 0 ||
                            this.state.tax.length === 0) &&
                        this.state.tax.map((countyItem, i) => ({
                            label: countyItem.tax_name + "(" + countyItem.tax_value + "%)",
                            value: countyItem.tax_value
                        })),
                });

                var data = [];
                if (this.state.paymentResponse.length > 0) {

                    for (let i = 0; i < this.state.paymentResponse.length; i++) {
                        let label = { label: this.state.paymentResponse[i].name };
                        let value = { value: this.state.paymentResponse[i].name };
                        data.push(Object.assign(label, value));
                        this.setState({
                            data: data
                        })
                    }

                } else {
                    this.setState({
                        data: data
                    })
                }




                localStorage.setItem("paidthrough", JSON.stringify(data))


                var data_bank = [];

                if (this.state.bankResponse.length > 0) {
                    for (let j = 0; j < this.state.bankResponse.length; j++) {

                        let label = { label: this.state.bankResponse[j].account_name + ` (${this.state.bankResponse[j].bank_name})-` + this.state.bankResponse[j].currency };
                        let value = { value: this.state.bankResponse[j].account_name + " " + this.state.bankResponse[j].bank_name };

                        data_bank.push(Object.assign(label, value));
                        this.setState({
                            data_bank: data_bank
                        })
                    }
                } else {
                    this.setState({
                        data_bank: data_bank
                    })
                }
                localStorage.setItem("bankaccounts", JSON.stringify(data_bank))
            })
        })).catch(error => {
            console.log('bayoo', error.response)

        })


        this.setState({
            isLoading: true,
        });
        // alert()
        let url_item = `${baseURL}item?branch_id=${BRANCH_ID}&&client_id=${USER_ID}`
        // let url =
        //   baseURL + `item?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&filter_value=${inputValue}`;
        axios.get(url_item, axiosConfig).then(itemResponse => {

            console.log("djdkd kff", itemResponse.data.data)
            this.setState(
                {
                    item: itemResponse.data.data.filter(item => item.status === 1),
                },
                function () {
                    this.setState({
                        isLoading: false,
                        bayo: this.state.item &&
                            (this.state.item.length > 0 || this.state.item.length === 0) &&
                            this.state.item.map((countyItem, i) => ({
                                label: countyItem.item_name,
                                value: countyItem.id,
                                rate: countyItem.rate,
                                item_id: countyItem.id,
                                quantity: countyItem.quantity,
                                buying_price: countyItem.buying_price,
                                measurements: countyItem.measurements,
                                item_reference: countyItem.reference,
                                check_expiry: countyItem.expiry_status,
                                expiry_date: countyItem.end_date,
                                item_name: countyItem.item_name,


                            })),
                    });
                }
            );
        });
    }
    handleNotes = event => {
        this.setState({ notes: event.target.value }, function () { });
        //alert(event.target.value)
    };
    Customer() {

        // alert(JSON.stringify(this.state.customerResponse))
        return (
            this.state.customerResponse &&
            (this.state.customerResponse.length > 0 || this.state.customerResponse.length === 0) &&
            this.state.customerResponse.map((countyItem, i) => ({
                label: countyItem.customer_display_name + " (" + countyItem.email + ")",
                customer_email: countyItem.email,
                customer_nameinvoice: countyItem.customer_display_name,
                value: countyItem.id,
            }))
        );
    }

    Vendor() {
        return (
            this.state.vendorResponse &&
            (this.state.vendorResponse.length > 0 || this.state.vendorResponse.length === 0) &&
            this.state.vendorResponse.map((countyItem, i) => ({
                label: countyItem.vendor_display_name + " (" + countyItem.email + ")",
                vendor_email: countyItem.email,
                vendor_displayname: countyItem.vendor_display_name,
                value: countyItem.id,
            }))
        );
    }


    load() {

        let url6 = baseURL + `expense_account?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID
        axios.all([
            axios.get(url6, CONFIG)
        ]).then(axios.spread((expenseResponse) => {
            this.setState({
                expenseResponse: expenseResponse.data
            }, function () {
                localStorage.setItem("expenseaccountcustomers", JSON.stringify(this.state.expenseResponse))
                window.location.reload();
            })
        })).catch(error => {
            console.log('bayoo', error.response)
        })
    }

    onSelectChangesShort = value => {
        if (value != null) {

            this.setState(
                {
                    customer_id: value.value.toString(),
                    customer_name: value.label.toString(),
                    customer_nameinvoice2: value.customer_nameinvoice.toString(),
                    customer_email: value.customer_email != null ? value.customer_email.toString() : '',

                    product2: true
                });
        } else {
            this.setState({
                product2: false
            })
        }
    };
    onSelectChangesVendor = value => {
        if (value != null) {

            this.setState(
                {
                    vendor_id: value.value.toString(),
                    vendor_name: value.label.toString(),
                    vendor_display_name: value.vendor_displayname.toString(),
                    vendor_email: value.vendor_email.toString()
                });
        }
    };
    onSubmit(e) {
        e.preventDefault();

        let amount2 = parseFloat(this.state.amount.toString().replace(",", ""))


        this.setState({
            isLoading: true
        })

        let formData = {
            "client_id": USER_ID,
            "date": this.state.date2,
            "expense_account": this.state.terms,
            "amount": this.state.total_amount,
            "tax_amount": ((this.state.tax_amount / 100) * amount2).toFixed(2),
            "paid_through": this.state.paid_through,
            "customer_id": this.state.customer_id,
            "vendor_id": this.state.vendor_id,
            "branch_id": BRANCH_ID,
            "recurring": 0,
            "ref_no": this.state.ref_no,
            "notes": this.state.notes,
            "billable": this.state.billing,
            "product": this.state.product_name


        }
        // alert(JSON.stringify(formData))
        axios.post(baseURL + `expense`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then((response) => {
            console.log('bayoo resulet', response.data)
            if (response.data.status) {

                if (this.state.isCostGoods) {
                    this.onSubmitItem()


                } else {
                    this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                    window.scrollTo(0, 0)
                    window.setTimeout(() => {

                        this.setState({ isShowError: false });
                        this._onButtonClick();
                    }, 2000);
                }
            } else {
                this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
            }
        })
            .catch((error) => {
                window.scrollTo(0, 0);
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
            })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeamount = (e) => {
        this.setState({
            amount: e.target.value,
            total_amount: (parseFloat(e.target.value.replace(",", "")) + ((this.state.tax_amount / 100) * parseFloat(e.target.value.replace(",", "")))).toFixed(2)
        })


    }

    _onButtonClick() {

        this.setState({
            showComponent: true,
            hideComponent: true,
            moreinfo: false
        });
    }
    _onMoreInfo() {
        this.setState({
            showComponent: false,
            hideComponent: true,
            moreinfo: true
        });
    }

    changeStatus() {
        this.setState({
            isShowError: false
        });
    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        }, function () {
            if (this.state.isChecked === true) {
                this.setState({
                    billing: 1
                })
            } else {
                this.setState({
                    billing: 0
                })
            }
        });
    }


    loadOptions = inputValue => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                "Authorization": TOKEN,
            },
        };
        this.setState({
            isLoading2: true,
        });
        let url =
            baseURL +
            `customer?branch_id=${BRANCH_ID}client_id=` +
            USER_ID +
            `&&filter_value=${inputValue}`;
        axios.get(url, axiosConfig).then(itemResponse => {
            this.setState(
                {
                    item: itemResponse.data.data,
                },
                function () {
                    this.setState({
                        isLoading2: false,
                        bayo: this.state.item &&
                            (this.state.item.length > 0 || this.state.item.length === 0) &&
                            this.state.item.map((countyItem, i) => ({
                                label: countyItem.customer_display_name,
                                customer_email: countyItem.email,
                                value: countyItem.id,
                            })),
                    });
                }
            );
        });
    };


    loadOptions1 = inputValue => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                "Authorization": TOKEN,
            },
        };
        this.setState({
            isLoading2: true,
        });
        let url =
            baseURL +
            `vendor?branch_id=${BRANCH_ID}&&client_id=` +
            USER_ID +
            `&&filter_value=${inputValue}`;

        axios.get(url, axiosConfig).then(itemResponse => {
            this.setState(
                {
                    item: itemResponse.data.data,
                },
                function () {
                    this.setState({
                        isLoading2: false,
                        bayo: this.state.item &&
                            (this.state.item.length > 0 || this.state.item.length === 0) &&
                            this.state.item.map((countyItem, i) => ({
                                label: countyItem.customer_display_name,
                                customer_email: countyItem.email,
                                value: countyItem.id,
                            })),
                    });
                }
            );
        });
    };


    setNativeValue(element, value) {
        const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
        const prototype = Object.getPrototypeOf(element);
        const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

        if (valueSetter && valueSetter !== prototypeValueSetter) {
            prototypeValueSetter.call(element, value);
        } else {
            valueSetter.call(element, value);
        }
    }

    handleChangeItem = value => {




        console.log("one item selected", value)
        if (value != null) {

            this.setState(
                {
                    selectedValue: value.label.toString(),
                    selectedValueQuantity: value.quantity === undefined ? "1" : value.quantity.toString(),
                    isOpenItem: false,
                    rate: value.rate,
                    buying_price: value.buying_price,
                    amount: value.buying_price,

                    total_amount: (parseFloat(value.buying_price) + ((this.state.tax_amount / 100) * parseFloat(value.buying_price))).toFixed(2),


                    selling_price: value.rate,
                    quantity: value.quantity === undefined ? "1" : value.quantity,
                    item_id: value.item_id,
                    measurements: value.measurements === undefined ? "" : value.measurements,
                    item_reference: value.item_reference,
                    check_expiry: value.check_expiry,
                    expiry_date: value.expiry_date,
                    item_name: value.item_name,
                    isOpen: false
                },
                function () {
                    this.setNativeValue(this.myinput5, this.state.selectedValueQuantity.toString());
                    this.myinput5.dispatchEvent(new Event('input', {
                        bubbles: true,
                        cancelable: true,
                    }))

                    this.setState(
                        {
                            selectedValue: value.label.toString(),
                            selectedValueQuantity: value.quantity === undefined ? "1" : value.quantity.toString(),
                            isOpenItem: false,
                        }, function () {
                            this.setNativeValue(this.myinput2, value.rate.toString());
                            this.myinput2.dispatchEvent(new Event('input', {
                                bubbles: true,
                                cancelable: true
                            }));
                        })

                }
            );
            // this.setNativeValue(this.iteminput, value.buying_price);
            // this.iteminput.dispatchEvent(new Event('input', {
            //     bubbles: true,
            //     cancelable: true,
            // }))





        }
        else {
            this.setState(
                {
                    itemvaluess: { value: "", label: "Select item" },
                },
                function () {
                    this.setNativeValue(this.myinput, '');
                    this.myinput.dispatchEvent(new Event('input', { bubbles: true }));
                });

        }


    };


    onSubmitItem = (e) => {


        // e.preventDefault();


        let formData = {
            "client_id": USER_ID,
            "quantity": this.state.quantity,
            "rate": this.state.selling_price,
            "measurements": this.state.measurements,
            "reference": this.state.item_reference,
            "item_id": this.state.item_id,
            "branch_id": BRANCH_ID,
            "check_expiry": this.state.check_expiry,
            "buying_price": this.state.amount,
            "expiry_date": moment(this.state.expiry_date).format("YYYY-MM-DD HH:mm:ss"),
            "item_name": this.state.item_name.replaceAll("'", ""),

        }

        console.log("form data", formData)
        // setIsLoading(true)

        axios.put(baseURL + `item`, formData, CONFIG)
            .then((response) => {

                if (response.data.status) {
                    this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                    window.scrollTo(0, 0)
                    window.setTimeout(() => {
                        this.setState({ isShowError: false });
                        this._onButtonClick();
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {

                console.log("error item edit", error)
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
            })

    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent, product2 } = this.state;


        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            justifyContent: 'space-between',
        };
        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };

        const formatGroupLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );

        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <Expenses_View /> : null
                        }
                    </div>
                )}

                {!hideComponent && (
                    <div>
                        <Card>

                            <Modal
                                isOpen={this.state.isOpenAccount}
                                onRequestClose={e => {
                                    this.closeModalExpenseAccount(e);
                                }}
                                contentLabel="My dialog"
                                className="mymodal"
                                onAfterOpen={() => {
                                    document.body.style.overflow = 'hidden';
                                }}
                                onAfterClose={() => {
                                    document.body.removeAttribute('style');
                                }}
                                overlayClassName="myoverlay"
                                closeTimeoutMS={500}
                            >

                                <MDBCloseIcon onClick={this.closeModalExpenseAccount} />

                                {this.state.isEditing ?
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.expenseData}
                                        columns={this.columns}
                                        dynamic={false}
                                        id="tsc" />
                                    :
                                    <>
                                        <h4><b>Create Expense Category</b></h4><br />

                                        {this.state.showError ? <div >

                                            <Alert color="danger" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>


                                        </div> : null}<br></br>

                                        {this.state.isShowError ? (
                                            <div>

                                                <Alert color="success" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>
                                            </div>
                                        ) : null}
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">Expense Account</label>
                                                <input id="input" type="text" className="form-control"
                                                    name="expense_name" required placeholder='Name'
                                                    value={this.state.expense_name} onChange={this.handleChange} />
                                                <br />

                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <Form.Group className="Focus-line" >
                                                <Form.Label>Expense Account Category</Form.Label>
                                                <Select
                                                    isClearable
                                                    // onInputChange={this.loadOptions}
                                                    placeholder="Select Account Category"

                                                    // onInputChange={this.loadOptions}
                                                    autosize={true}
                                                    options={this.state.expense_account}

                                                    // value={this.state.terms}

                                                    onChange={this.handleChangeExpenseAccount}
                                                    isLoading={this.state.isLoading}
                                                    // noOptionsMessage={() => 'nothing found'}
                                                    // loadingMessage={() => 'searching...'}   //minor type-O here
                                                    menuPortalTarget={document.body}
                                                    required
                                                    name="paid_through"
                                                /><br />

                                            </Form.Group>
                                        </div>
                                        <Button color="primary" className="btn-paypal btn-lg pull-right" onClick={this.saveExpenseAccount} >
                                            {this.state.isLoading1 ? "Saving..." : "Save"}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;
                                        <br />
                                    </>
                                }
                            </Modal>

                            <Modal
                                isOpen={this.state.isOpen}
                                onRequestClose={e => {
                                    this.closeModalUpload(e);
                                }}
                                contentLabel="My dialog"
                                className="mymodal"
                                onAfterOpen={() => {
                                    document.body.style.overflow = 'hidden';
                                }}
                                onAfterClose={() => {
                                    document.body.removeAttribute('style');
                                }}
                                overlayClassName="myoverlay"
                                closeTimeoutMS={500}
                            >
                                <MDBCloseIcon onClick={this.closeModalUpload} />
                                <h4><b>Upload Files </b></h4><br />

                                {this.state.showError ? <div >


                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>

                                </div> : null}
                                <br></br>

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>
                                    </div>
                                ) : null}
                                <div className="wrapper text-center">
                                    <div className="section1">
                                        <div className="contain">
                                            <input
                                                className="csv-input"
                                                type="file"
                                                required
                                                ref={input => {
                                                    this.filesInput = input;
                                                }}
                                                name="file"
                                                customHeight
                                                placeholder={null}
                                                onChange={this.handleProfile}
                                            />
                                            <br />
                                            <Button color="primary" className="btn-paypal btn-lg " onClick={this.uploadProfile} >
                                                {this.state.isLoading1 ? "Uploading..." : "Upload now!"}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;

                                            <br />
                                        </div>
                                    </div>
                                </div>

                            </Modal>
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>Create Expense</b></h4><br />
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/expense">
                                            <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                                Expenses
                                            </Button>
                                        </Link>

                                    </div>
                                </div>


                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>


                                </div> : null}<br></br>

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div>

                                ) : null}<br></br>
                                <form className="form row " onSubmit={this.onSubmit}>


                                    <div className="col-md-6">

                                        <br />
                                        <Form.Group className="Focus-line">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Date</Form.Label>
                                                </div>
                                                <div className="col-md-10 customDatePickerWidth">
                                                    <DatePicker
                                                        selected={this.state.date2}
                                                        label="DatePicker Label"
                                                        dateFormat="dd MMM yyyy"
                                                        required
                                                        value={this.state.date2}
                                                        className="form-control"
                                                        id="input"
                                                        onChange={value =>
                                                            this.setState({ date2: value })}
                                                    />

                                                </div>
                                            </div>
                                        </Form.Group>

                                    </div>
                                    <div className="col-md-6">
                                        <br />
                                        <Form.Group className="Focus-line" >
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Expense Account</Form.Label>
                                                </div>
                                                <div className="col-md-5">
                                                    <CreatableSelect
                                                        isClearable
                                                        // defaultValue={costofgoodOptions[1]}
                                                        // onInputChange={this.loadOptions}
                                                        placeholder="Select Expense Account"
                                                        autosize={true}
                                                        options={groupedOptions}
                                                        formatGroupLabel={formatGroupLabel}
                                                        onCreateOption={this.toggleModalOpen}

                                                        // value={this.state.terms}
                                                        onChange={this.handleChangeTerms}
                                                        // isLoading={this.state.isLoading}

                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        menuPortalTarget={document.body}
                                                        name="expense_account"
                                                    /><br />
                                                </div>
                                                <div className="col-md-1">
                                                    <Button
                                                        onClick={
                                                            () => {
                                                                this.toggleModalOpenButton(true)
                                                            }}
                                                        style={{ color: '#fff' }}
                                                        className="btn btn-danger btn-refresh btn-sm pull-right" >
                                                        <i className='fa fa-close'></i>
                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                </div>

                                                <div className="col-md-4">
                                                    <Button
                                                        onClick={
                                                            () => {
                                                                this.toggleModalOpenButton(false)
                                                            }}
                                                        style={{ color: '#fff' }}
                                                        className="btn btn-primary btn-refresh btn-sm pull-right" >
                                                        Add New Expense Account
                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    {this.state.isCostGoods ?
                                        <>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <label className="form-label">Inventory Items </label>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">

                                                                <Select
                                                                    options={this.state.bayo}
                                                                    value={this.state.itemvaluess}
                                                                    autosize={true}
                                                                    remove
                                                                    placeholder="Select Inventory Item"
                                                                    onChange={e => {
                                                                        this.handleChangeItem(e);
                                                                    }}
                                                                    // onCreateOption={this.handleCreate}
                                                                    isLoading={this.state.isLoading}
                                                                    menuPortalTarget={document.body}
                                                                    name="namffe"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Inventory */}
                                            <>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                {/* <label className="form-label"> </label> */}
                                                            </div>


                                                            <div className="col-md-5">

                                                                <div className="form-group">
                                                                    <label className="form-label">Selling Price </label>

                                                                    <input
                                                                        name="selling_price"
                                                                        ref={input2 => (this.myinput2 = input2)}
                                                                        className="form-control"
                                                                        type="number"
                                                                        id="selling_price"
                                                                        step="0.01"
                                                                        style={{ fontSize: "14px" }}
                                                                        min="0.00"
                                                                        placeholder="Selling price"
                                                                        max="9999999.99"
                                                                        value={this.state.selling_price}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </div>
                                                            </div>



                                                            <div className="col-md-5">
                                                                <div className="form-group">
                                                                    <label className="form-label">Quantity </label>
                                                                    <input
                                                                        ref={input => (this.myinput5 = input)}
                                                                        name="quantity"
                                                                        placeholder="Quantity"
                                                                        className="form-control"
                                                                        type="text"
                                                                        id="quantity"
                                                                        style={{ fontSize: "14px" }}
                                                                        required
                                                                        value={this.state.quantity}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>

                                        </>
                                        : null}
                                    <div className="col-md-6">
                                        <br /><br />

                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Amount </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input id="input" type="text" className="form-control"
                                                        name="amount" required placeholder='Amount'
                                                        value={this.state.amount} onChange={this.handleChangeamount} />
                                                    <br />
                                                    <h5>{this.state.total_amount}</h5>
                                                </div>

                                                <div className="col-md-4">
                                                    <Form.Group className="Focus-line" >

                                                        <CreatableSelect
                                                            isClearable
                                                            options={this.state.taxparams}
                                                            placeholder="Select tax"
                                                            autosize={true}
                                                            onChange={this.handleChangeTax}
                                                            isLoading={this.state.isLoading}
                                                            menuPortalTarget={document.body}
                                                            name="namffe"
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Ref/Invoice No</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control"
                                                        name="ref_no" required placeholder='Invoice/Ref'
                                                        value={this.state.ref_no} onChange={this.handleChange} />
                                                    <br />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <Form.Group className="Focus-line" >
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Paid Through</Form.Label>
                                                </div>
                                                <div className="col-md-10">
                                                    <Select
                                                        isClearable
                                                        // onInputChange={this.loadOptions}
                                                        placeholder="Select Account"

                                                        // onInputChange={this.loadOptions}
                                                        autosize={true}
                                                        options={groupedOptions_two}
                                                        formatGroupLabel={formatGroupLabel}

                                                        // value={this.state.terms}

                                                        onChange={this.handleChangePaid}
                                                        isLoading={this.state.isLoading}
                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        menuPortalTarget={document.body}
                                                        required
                                                        name="paid_through"
                                                    /><br />
                                                </div>
                                            </div>

                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Supplier Name</label>
                                                </div>
                                                <div className="col-md-10">

                                                    <Select
                                                        isClearable
                                                        options={this.Vendor()}
                                                        onInputChange={this.loadOptions1}
                                                        placeholder="Search Supplier"
                                                        autosize={true}
                                                        onChange={this.onSelectChangesVendor}
                                                        isLoading={this.state.isLoading2}
                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        menuPortalTarget={document.body}
                                                        name="vendo_name"
                                                    /><br />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">

                                                    <label className="form-label">
                                                        Notes
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <textarea
                                                        name="invoice_message"

                                                        style={{
                                                            paddingTop: '20px',
                                                            borderRadius: '5px',
                                                            borderColor: 'hsl(0,0%,80%)',
                                                        }}
                                                        onChange={this.handleNotes}
                                                        className="col-md-12"
                                                        placeholder="Notes"
                                                        id="message"
                                                        cols="col-md-1"
                                                        rows="2"
                                                    />
                                                </div>

                                                <div className="col-md-2">
                                                    <ul id="sms-counter">
                                                        <li>Length: <span class="length" /></li>
                                                        {/* <li>SMS: <span class="messages"></span></li> */}
                                                        <li>
                                                            Message:
                                                            {' '}
                                                            <span class="remaining" />

                                                            <span class="per_message" />
                                                        </li>
                                                        {/* <li>Remaining: </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">{BUSINESS_TYPE === 'business' ? "Customer" : "Grantor"} Name</label>
                                                </div>
                                                <div className="col-md-8">

                                                    <Select
                                                        isClearable
                                                        options={this.Customer()}
                                                        onInputChange={this.loadOptions}
                                                        placeholder={BUSINESS_TYPE === 'business' ? "Select Customer" : "Select Grantor"}
                                                        autosize={true}
                                                        onChange={this.onSelectChangesShort}
                                                        isLoading={this.state.isLoading2}
                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        menuPortalTarget={document.body}
                                                        name="namffe"
                                                    /><br />
                                                </div>

                                                <div className="col-md-2">
                                                    <label>
                                                        <input type="checkbox"
                                                            checked={this.state.isChecked}
                                                            onChange={this.toggleChange}
                                                        />&nbsp;
                                                        Billable

                                                    </label>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    {product2 && (
                                        <div>
                                            <div className="col-md-12">
                                                <Form.Group className="Focus-line" >
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <Form.Label>Project</Form.Label>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <CreatableSelect
                                                                isClearable
                                                                options={this.state.product}
                                                                // onInputChange={this.loadOptions}
                                                                placeholder="Select Project"
                                                                autosize={true}
                                                                // value={this.state.terms}
                                                                onChange={this.handleChangeProduct}
                                                                isLoading={this.state.isLoading}
                                                                noOptionsMessage={() => 'No Result was Found'}
                                                                // loadingMessage={() => 'searching...'}   //minor type-O here
                                                                menuPortalTarget={document.body}
                                                                name="namffe"
                                                            />
                                                            <br />
                                                        </div>
                                                    </div>

                                                </Form.Group>
                                            </div>
                                        </div>
                                    )
                                    }

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="form-label">Upload Documents &nbsp;&nbsp;&nbsp;<a href="#" onClick={this.toggleModalCreate} >Click here</a></label>
                                                </div>
                                                {/* <div className="col-md-8">

                                                     
                                                    <br />
                                                </div> */}


                                            </div>


                                        </div>
                                    </div>
                                    <br></br>
                                    <br />

                                    <div className="col-md-12"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Link to="/expense">
                                                        <Button outline color="primary" className="btn-sm" onClick={this._onButtonClick}>
                                                            Cancel
                                                        </Button>
                                                    </Link>
                                                </div>
                                                <div className="col-md-8">
                                                    <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                                        {this.state.isLoading ? "Please Wait..." : "Save"}
                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </form>
                            </CardBody>
                        </Card>
                    </div>
                )
                }
            </div>
        )
    }
}
export default withTranslation('common')(ExpenseCreate);
