import React, { Fragment, useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import { Button, Card, CardBody, Col } from "reactstrap";
import {
  BRANCH_ID,
  BUSINESS_TYPE,
  CONFIG,
  CURRENCY,
  PROFILE_URL,
  successToast,
  errorToast,
  ToastTable,
  TOKEN,
  USER_ID,
  formatCurrency,
  ranges,
  local,
  ROLE, USER_ID_ACC,
} from "../../_helpers/exports";
import * as moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import RefreshIcon from "mdi-react/RefreshCircleIcon";
import { useEffect } from "react";
import UseFetch from "../../_helpers/useFetch";
import DeleteHooks from "../../_helpers/deleteHooks";
import { savePaySlip } from "./payslip";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl, Table } from "react-bootstrap";
import Accordion from "./accordion";
import "bootstrap/js/src/collapse.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Collapsible from "react-collapsible";
import { slideDown, slideUp } from "./anim";
import { useRef } from "react";

export const StaffExpenses = () => {
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState();

  const [data_label, setDataLable] = React.useState([]);

  let now = new Date();
  const start = moment(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [datevalue, setDateValue] = useState(moment(start).format("MMM, YYYY"));

  const [queryString, setQueryString] = React.useState("");

  const [set_check, setTrue] = React.useState(false);

  const ref = useRef("")

  const [redirect, setRirect] = React.useState(false);


  const [year, setYear] = React.useState("");

  const [year_status, setYearStatus] = React.useState(false);

  const [month_data, setMonth] = React.useState([
    {
      id: 1,
      month: "January"
    },
    {
      id: 2,
      month: "February"
    },
    {
      id: 3,
      month: "March"
    },
    {
      id: 4,
      month: "April"
    },
    {
      id: 5,
      month: "May"
    },
    {
      id: 6,
      month: "June"
    },
    {
      id: 7,
      month: "July"
    },
    {
      id: 8,
      month: "August"
    },
    {
      id: 9,
      month: "September"
    },
    {
      id: 10,
      month: "October"
    },
    {
      id: 11,
      month: "November"
    },
    {
      id: 12,
      month: "December"
    }
  ]
  );



  const [year_data, setTYear] = React.useState([
    {
      id: 47,
      month: "2024"
    },

    {
      id: 48,
      month: "2023"
    },
    {
      id: 49,
      month: "2022"
    },
    {
      id: 50,
      month: "2021"
    },
    {
      id: 51,
      month: "2020"
    },
    {
      id: 52,
      month: "2019"
    },
    {
      id: 53,
      month: "2018"
    },
    {
      id: 54,
      month: "2017"
    }

  ]
  );

  const [selected_year, setSelectedYear] = React.useState(2023);


  const [expanded, setExpanded] = React.useState(false);
  const [redirect_data, setRedirectData] = React.useState({});
  const [loading, fetch, callback] = UseFetch(
    `staff_expense?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=${USER_ID}&start_date=${moment(startDate).format(
      "YYYY-MM-DD"
    )}&end_date=${moment(endDate).format(
      "YYYY-MM-DD"
    )}&branch_id=${BRANCH_ID}&${queryString}`
  ); // custom hook
  const [check_del, setCheckDel] = React.useState(0);


  const [check, setCheck] = React.useState(0);

  const [previous_id, setPreviousID] = React.useState(0);
  const navigate = useNavigate();

  const [employee_id, setEmployeeID] = React.useState("");
  const [isLoading, deleteCall] = DeleteHooks(
    `staff_expense?id=${employee_id}`
  ); // custom hook

  const d = new Date();

  let month = d.getMonth();

  const [mmonth, setMonths] = React.useState(month + 1);

  useEffect(() => {
    let month = d.getMonth();

    setMonths(month + 1)


    setMonth(month_data.filter(function checkAdult(age) {
      return age.id <= mmonth;
    }))

    if (check === 0) {
      callback();
      setCheck(1);
    }
    if (fetch) {
      let data_set = [];
      setTotal(fetch.total);
      // alert(JSON.stringify(fetch.data))
      if (fetch.data.length > 0) {
        for (let i = 0; i < fetch.data.length; i++) {
          let m_date = {
            m_date: moment(fetch.data[i].created_on).format("MMM, YYYY"),
          };

          let mgross = { mgross: formatCurrency(fetch.data[i].gross) };

          let mother_benefits = {
            mother_benefits: formatCurrency(fetch.data[i].other_benefits),
          };

          let mbenefits = {
            mbenefits: formatCurrency(
              fetch.data[i].other_benefits + fetch.data[i].gross
            ),
          };

          let motherdeduction = {
            motherdeduction: formatCurrency(fetch.data[i].other_deductions),
          };

          let mnsisf = { mnsisf: formatCurrency(fetch.data[i].nssf) };
          let mnhif = { mnhif: formatCurrency(fetch.data[i].nhif) };
          let mpaye;
          let mincometax;
          let minsurance_relief;
          let mtax;
          let mnet_pay;

          setDataLable(fetch.dates);
          if (parseFloat(fetch.data[i].paye_one) > 0) {
            mtax = { mtax: formatCurrency(fetch.data[i].tax_relief) };

            mnet_pay = {
              mnet_pay: formatCurrency(
                fetch.data[i].net_pay + fetch.data[i].nhif * 0.15
              ),
            };

            mpaye = {
              mpaye: formatCurrency(
                parseFloat(fetch.data[i].paye_one) +
                fetch.data[i].paye_two +
                fetch.data[i].paye_three -
                fetch.data[i].tax_relief
              ),
            };

            minsurance_relief = {
              minsurance_relief: formatCurrency(fetch.data[i].nhif * 0.15),
            };

            mincometax = {
              mincometax: formatCurrency(
                parseFloat(fetch.data[i].paye_one) +
                fetch.data[i].paye_two +
                fetch.data[i].paye_three
              ),
            };
          } else {
            mtax = { mtax: formatCurrency(0) };
            mpaye = { mpaye: formatCurrency(0) };
            mincometax = { mincometax: formatCurrency(0) };
            minsurance_relief = { minsurance_relief: formatCurrency(0) };
            mnet_pay = {
              mnet_pay: formatCurrency(
                fetch.data[i].gross +
                fetch.data[i].other_benefits -
                (fetch.data[i].nssf +
                  fetch.data[i].nhif +
                  fetch.data[i].other_deductions)
              ),
            };
          }
          // let mdate = { mdate: formatCurrency(fetch.data[i].net_pay) }
          data_set.push(
            Object.assign(
              m_date,
              mbenefits,
              mother_benefits,
              mincometax,
              minsurance_relief,
              mnhif,
              mnsisf,
              mtax,
              mnet_pay,
              mpaye,
              motherdeduction,
              mgross,
              fetch.data[i]
            )
          );
        }
        setData(data_set);
      } else {
        setData(data_set);
      }
      if (check_del === 1) {
        deleteCall();
        setCheckDel(0);
        callback();
      }
    }
    if (PROFILE_URL == "null") {
      localStorage.setItem(
        "image_urls",
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC"
      );
    } else {
      localStorage.setItem(
        "image_urls",
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC"
      );
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      var img = new Image();
      img.crossOrigin = "*";
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0); // at this point the image is drawn to the canvas
        canvas.toDataURL("image/jpeg", 1.0);
        var fullQuality = canvas.toDataURL("image/jpeg", 1.0);
        localStorage.setItem("image_urls", fullQuality);
      };
      img.src = `${PROFILE_URL}?canvas=true`;
    }
  }, [loading, check_del, isLoading, queryString, check]);

  const columns = [
    {
      key: "name",
      TrOnlyClassName: "cell",
      text: "Employee",
      className: "cell",
      align: "left",
    },
    {
      key: "disability",
      TrOnlyClassName: "tsc",
      text: "PWD",
      className: "tsc",
      altrueign: "left",
    },
    {
      key: "department_name",
      TrOnlyClassName: "tsc",
      text: "Department",
      className: "tsc",
      align: "left",
    },
    {
      key: "period",
      TrOnlyClassName: "tsc",
      text: "Period",
      className: "tsc",
      align: "left",
    },

    {
      key: "mgross",
      TrOnlyClassName: "tsc",
      text: "Gross",
      className: "tsc",
      altrueign: "left",
    },
    {
      key: "mother_benefits",
      TrOnlyClassName: "tsc",
      text: "Other Benefits",
      className: "tsc",
      altrueign: "left",
    },

    {
      key: "motherdeduction",
      TrOnlyClassName: "tsc",
      text: "Other Deductions ",
      className: "tsc",
      align: "left",
    },
    {
      key: "mnsisf",
      TrOnlyClassName: "tsc",
      text: "NSSF",
      className: "tsc",
      altrueign: "left",
    },
    {
      key: "mnhif",
      TrOnlyClassName: "tsc",
      text: "NHIF",
      className: "tsc",
      altrueign: "left",
    },
    {
      key: "minsurance_relief",
      TrOnlyClassName: "tsc",
      text: "Insurance Relief",
      className: "tsc",
      altrueign: "left",
    },
    {
      key: "mtax",
      TrOnlyClassName: "tsc",
      text: "Tax Relief",
      className: "tsc",
      altrueign: "left",
    },
    {
      key: "mincometax",
      TrOnlyClassName: "tsc",
      text: "Income Tax",
      className: "tsc",
      altrueign: "left",
    },

    {
      key: "mpaye",
      TrOnlyClassName: "tsc",
      text: "PAYEE",
      className: "tsc",
      altrueign: "left",
    },
    {
      key: "mnet_pay",
      TrOnlyClassName: "tsc",
      text: "Net Pay",
      className: "tsc",
      altrueign: "left",
    },

    {
      key: "m_date",
      TrOnlyClassName: "tsc",
      text: "Date",
      className: "tsc",
      altrueign: "left",
    },
    {
      key: "action",
      text: "Options",
      TrOnlyClassName: "cell",
      className: "cell",
      sortable: false,
      cell: (record) => {
        return (
          // eslint-disable-next-line
          <Fragment className="center">
            <button
              className="btn btn-primary  btn-sm"
              title="Eith details"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setRirect(true);
                setRedirectData(record);
              }}
            >
              Edit
            </button>

            <button
              className="btn btn-success  btn-sm"
              title="View pdf payslip"
              style={{ marginRight: "10px" }}
              onClick={() => {
                Load(record);
              }}
            >
              View
            </button>

            <button
              className="btn btn-danger  btn-sm"
              title="Delete customer"
              style={{ marginRight: "10px" }}
              onClick={() => {
                if (
                  window.confirm(`Are you sure you want to delete this expense`)
                ) {
                  setEmployeeID(record.id);
                  // alert(record.id)
                  setCheckDel(1);
                }
              }}
            >
              Delete
            </button>
          </Fragment>
        );
      },
    },
  ];


  const toggleExpander = (e) => {

    if (!expanded) {

      setExpanded(true)
      // if (ref.expanderBody) {
      //   slideDown(ref.expanderBody);
      // }
    } else {
      // slideUp(ref.expanderBody, {

      // onComplete: () => {
      setExpanded(false)
      //   }
      // });
    }
  }

  const Load = (record) => {
    // alert(JSON.stringify(record))
    const footer = {
      payment_date: record.m_date,
    };
    savePaySlip(Object.assign(footer, record));
  };

  const config = {
    key_column: "tsc",
    length_menu: [100, 200, 500],
    show_filter: false,
    show_pagination: true,
    pagination: "advance",
    button: {
      excel: true,
      csv: true,
    },
    filename: BUSINESS_TYPE === "business" ? "employee" : "employee",
    page_size: 100,
    show_length_menu: true,
    language: {
      loading_text: "Please be patient while data loads...",
      filter: "Search here",
      no_data_text: "Oooops no payslip found",
      pagination: {
        next: <span>&#9658;</span>,
        previous: <span>&#9668;</span>,
      },
    },
  };

  const tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    setQueryString(queryString);
    setCheck(0);
  };

  const applyCallback = (startDate, endDate) => {
    // alert(startDate)
    const Sdate1 = moment(startDate).format("DD MMM, YYYY");
    const Edate2 = moment(endDate).format("DD MMM, YYYY");
    setDateValue(moment(startDate).format("MMM, YYYY"));
    setStartDate(startDate);
    setEndDate(endDate);
    setCheck(0);
  };

  const colum = () => {
    setTrue(!set_check);
  };


  const close = (id, month) => {

    setSelectedYear(month)

    for (let i = 0; i < year_data.length; i++) {
      if (year_data[i].id !== id) {
        if (!year_status) {
          setPreviousID(id)
          setYearStatus(true)
        }
      }
      // else if (year_data.length == (i + 1)) {
      //   if (year_data[i].id == id) {
      //     setYearStatus(true)
      //   } else {
      //     setYearStatus(false)
      //   }
      // }
      else if (year_data[i].id == id) {
        setYearStatus(false)
      }
    }
  };



  const closetwo = (id) => {
    const Sdate1 = moment(startDate).format("YYYY");
    const Sdate2 = moment(startDate).format("DD");
    const Sdate4 = moment(selected_year + " " + id + " " + Sdate2);
    const Sdate6 = moment(new Date(Sdate1, id, 0));


    // setSelectedYear()
    setStartDate(Sdate4);
    setEndDate(Sdate6);
    setCheck(0);

    // alert(id)

    for (let i = 0; i < month_data.length; i++) {
      let detail = document.getElementsByTagName("details");

      if (detail[i].open === "true") {
        detail[i].open = true;
      } else if (detail[i].open === "false") {
        detail[i].open = false;
      } else if (month_data[i].id !== id) {
        detail[i].open = false;
      }
    }
  };

  return (
    <div style={{ marginTop: "-20px" }}>
      {ToastTable()}

      {redirect ? (
        <>{navigate("/staff_edit", { state: redirect_data, replace: true })}</>
      ) : null}
      <Card>
        <CardBody>
          <>
          </>

          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <h4>
                  <b>PaySlips </b>
                </h4>
                <br />
              </div>
              <div className="col-md-6 float-">
                <Link to="/add_staff">
                  <Button className="pull- btn-sm" color="primary" outline>
                    Create Payroll
                  </Button>
                </Link>
              </div>
            </div>


            {
              year_data.map((y, i) => (
                <div style={{ margin: '30px' }}>
                  <ul>
                    <li className='fa fa-arrow-right' onClick={() => close(y.id, y.month)} style={{ cursor: 'pointer' }}>&nbsp;&nbsp;&nbsp; {y.month} </li>
                    {
                      year_status && previous_id == y.id ?
                        month_data.map((p, i) => (
                          <details onClick={() => closetwo(p.id)} style={{ margin: '30px' }}>

                            <summary>{p.month} </summary>

                            <div className="inner uk-grid" style={{ margin: '30px' }}>
                              <div className="uk-width-3-4">
                                <ReactDatatable
                                  config={config}
                                  records={data}
                                  columns={columns}
                                  dynamic={true}
                                  id="tsc"
                                  loading={loading}
                                  total_record={total}
                                  onChange={tableChangeHandler}
                                />
                              </div>
                            </div>
                          </details>
                        ))
                        : null
                    }
                  </ul>
                </div>

              ))
            }

            <br />
            <br />

          </div>
        </CardBody>
      </Card>
    </div >
  );
};
