import React, { useState, useEffect } from 'react';
import ReactDatatable from '@mkikets/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import {  CONFIG, LOADER } from '../../_helpers/exports';
import Loader from "react-loader-spinner";
import { baseURL } from '../../_helpers';

import "../../_assets/css/csv.css";
import * as moment from 'moment';
import {
    Card, CardBody, Table
} from 'reactstrap';
// import EditApplicant from './EditApplicant';



const SMSLogs = () => {
    const [isLoading, setLoad] = useState(true)
    const [datatotal, setDataTotal] = useState()
    const [queryString, setQueryString] = useState("")
    const [smsLog, setSMSLog] = useState([])



    // const [avatar, setAvatar] = useState("") // set profile picture


    useEffect(() => {
        // consuming API based on active tab
        let sms_url = baseURL + `sms?` + queryString;
        // setLoad(true)
        // tab 1 API call...
        axios.all([
            axios.get(sms_url, CONFIG),
        ]).then(axios.spread((smsResponse) => {
            let data = [];
            if (smsResponse.data.data.length > 0) {
                setLoad(false)
                for (let i = 0; i < smsResponse.data.data.length; i++) {

                    // format amount
                    let date = { dates: (moment.utc(smsResponse.data.data[i].created_on).format('DD/MM/YYYY HH:mm:ss')) };
                    data.push(Object.assign(date, smsResponse.data.data[i]));
                    setLoad(false)
                }
                setDataTotal(smsResponse.data.total)
                setSMSLog(data)
            } else {
                setLoad(false)
                setSMSLog(data)
            }
        })).catch(error => {
            // alert(JSON.stringify(error.response))
        })
    }, [queryString]);

    const config = {
        key_column: "tsc",
        length_menu: [20, 50, 100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 20,
        button: {
            csv: true
        },
        show_length_menu: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Filter here..",
            no_data_text: "There was no record found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }


    //sms columns list

    const columns_sms = [
        {
            key: "destination",
            TrOnlyClassName: 'cell',            text: "Destination",
           className: "tsc",
            align: "left"
        },

        {
            key: "origin",
            TrOnlyClassName: 'cell',            text: "Origin",
           className: "tsc",
            align: "left"
        },
        {
            key: "status",
            TrOnlyClassName: 'cell',            text: "Send",
           className: "tsc",
            align: "left"
        },
        {
            key: "message",
            TrOnlyClassName: 'cell',            text: "Message",
           className: "tsc",
            align: "left"
        },

        {
            key: "dates",
            TrOnlyClassName: 'cell',            text: "Date Created",
           className: "tsc",
            align: "left"
        }
    ];




    // handles paginations
    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column")
                    + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }


    //UI returned
    return (
        <div style={{ marginTop: "-20px" }} >

            <Card>
                <CardBody className="profile__card">
                    <br />
                    <div className="tabs tabs--bordered-top" >
                        <div className="tabs__wrap">

                            {isLoading === false ?

                                <Table>


                                    <div >
                                        Total SMS: <b>{datatotal}</b>
                                        <br /><br />
                                    </div>

                                    <ReactDatatable
                                        config={config}
                                        records={smsLog}
                                        columns={columns_sms}
                                        dynamic={true}
                                        id="tsc"
                                        loading={isLoading}
                                        total_record={datatotal}
                                        onChange={tableChangeHandler} />

                                </Table>

                                :
                                <div className="loader">
                                    {LOADER}
                                </div>
                            }


                        </div>
                    </div>
                </CardBody>

            </Card>


        </div>
    );
}

export default SMSLogs;
