import React, { Component } from 'react';
import styles from './Invoice.module.scss';
import { Card, CardBody, Col, Button } from 'reactstrap';
import axios from 'axios';
import { CURRENCY, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { baseURL, baseURL_2 } from '../../_helpers';
import Alert from '../../shared/components/Alert';
import { Link, Navigate } from 'react-router-dom';
import * as moment from 'moment';
import ReactDatatable from '@mkikets/react-datatable';
window.$ = window.jQuery = require('jquery');
require('../../_assets/jss/sms_counter.js');

export class Credit_ReceivedBills extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this._onButtonClick = this._onButtonClick.bind(this);
        this.columns = [
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "bill_no",
                TrOnlyClassName: 'tsc',
                text: "Bill#",
                className: "tsc",
                align: "left"
            },
            {
                key: "amount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "due_amount",
                TrOnlyClassName: 'tsc',
                text: "Due Amount",
                className: "tsc",
                align: "left"
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: false,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: false,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Invoice no...",
                no_data_text: "There are no unpaid Invoices for this customer, that can be applied for this credit",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
    }

    locale = 'en-US';
    currency = CURRENCY;

    state = {
        taxRate: 0.00,
        invoice_no: 'IN-29333',
        payment_date: new Date(),
        due_date: new Date(),
        editedInvoice: {},
        amount_received: 0.00,
        loaded: true,
        reference: "abc",
        isPageLoad: true,
        isSubmitted: false,
        isLoadCustomer: false,
        lineItems: [
            {
                id: 'initial', // react-beautiful-dnd unique key
                name: '',
                description: '',
                quantity: 0,
                discount_percentage: 0,
                tax_percentage: 0,
                price: 0.00,
            },
        ],


    };
    componentDidMount() {
        // alert(localStorage.getItem("Credit_No_Customer_ID1"))
        this.setState({
            returnHome: false,
            customer_id: localStorage.getItem("Credit_No_Customer_ID1")
        });
        this.getData(localStorage.getItem("Credit_No_Customer_ID1"))
    }

    handleLineItemChange = elementIndex => event => {
        let lineItems = this.state.lineItems.map((item, i) => {
            if (elementIndex !== i) return item;
            return { ...item, [event.target.name]: event.target.value };
        });
        this.setState({ lineItems });
    };

    handleFocusSelect = event => {
        event.target.select();
    };

    formatCurrency = amount => {
        return new Intl.NumberFormat(this.locale, {
            style: 'currency',
            currency: this.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };

    calcTaxAmount = c => {
        return c * (localStorage.getItem('valuestax') / 100);
    };

    calcLineItemsTotal = () => {
        return this.state.lineItems.reduce(
            (prev, cur) => prev + cur.quantity * cur.price,
            0
        );
    };

    calcLineItemDiscounts = () => {

        // alert(localStorage.getItem("DATAARRAY"))

        return JSON.parse(localStorage.getItem("DATAARRAY")).reduce(
            // var newString = Number(due_amount_updated) ;
            (prev, cur) => prev + cur.due_amount_updated,
            0
        );
    };

    calcTaxTotal = () => {
        return this.state.lineItems.reduce(
            (prev, cur) => prev + ((cur.tax_percentage / 100) * (cur.quantity * cur.price)),
            0
        );
    };

    calcGrandTotal = () => {
        return (
            this.formatCurrency(
                Math.round((this.state.amount_received -
                    this.calcLineItemDiscounts()), 2)

            )

        )

    };


    calcGrandTotalExcess = () => {
        return (
            Math.round((this.state.amount_received -
                this.calcLineItemDiscounts()), 2))
    };


    handleChange = e => {
        this.setState({
            ...this.state.editedInvoice,
            [e.target.name]: e.target.value,
        });

        console.log('updates', this.state.editedInvoice);
    };


    getData = (queryString) => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `billunpaid?filter_value="+queryString + "&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((invoiceResponse) => {
            this.setState({
                invoice: invoiceResponse.data.data,
                isLoading: false,
                isPageLoad: false
            },
                function () {

                    var data = [];
                    this.setState({
                        total: invoiceResponse.data.total
                    })
                    var names = this.state.invoice.map(function (item) {
                        return item['bill_no'];
                    });

                    var names_order = this.state.invoice.map(function (item) {
                        return item['order_no'];
                    });

                    var array = names
                    var array1 = array.toString();
                    var array3 = array1.replace(/,/g, ", ");

                    var array_order = names_order
                    var array1_order = array_order.toString();
                    var array3_order = array1_order.replace(/,/g, ", ");


                    if (this.state.invoice.length > 0) {
                        for (let i = 0; i < this.state.invoice.length; i++) {
                            localStorage.setItem("DATAARRAY", JSON.stringify(data))

                            let due_date = moment(this.state.invoice[i].due_date);
                            let dueDateFormated = due_date.format('DD MMM, YYYY');
                            let date1 = { dates1: dueDateFormated };
                            let due_amount_updated;
                            var str = this.state.invoice[i].due_amount;
                            // s = s.replace(/^KSH+/, "");
                            // var str = 
                            // eslint-disable-next-line
                            str = str.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                            var num = parseFloat(str);
                            due_amount_updated = { due_amount_updated: num }




                            let date = { dates: (moment.utc(this.state.invoice[i].bill_date).format('DD MMM, YYYY')) };
                            data.push(Object.assign(date1, date, due_amount_updated, this.state.invoice[i]));
                            this.setState({
                                data: data,
                                bill_no: array3,
                                order_no: array3_order

                            })

                            localStorage.setItem("DATAARRAY", JSON.stringify(data))

                            this.setState({
                                amount_received: JSON.parse(localStorage.getItem("DATAARRAY")).reduce((prev, cur) => prev + cur.due_amount_updated, 0)
                            })


                            var str1 = localStorage.getItem("Credit_No_Due_Amout1");

                            str1 = str1.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it

                            this.setState({
                                amount_received: str1
                            })
                            console.log("bugs", data);
                        }
                    } else {
                        this.setState({
                            data: data
                        })
                        // alert(JSON.stringify(this.state.invoice))
                        localStorage.setItem("DATAARRAY", JSON.stringify(data))
                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }


    onSubmit(e) {
        e.preventDefault();
        // eslint-disable-next-line
        let formData = {
            bill_no: this.state.invoice_no,
            amount_received: this.state.amount_received,
            payment_date: this.state.payment_date,
            payment_mode: this.state.payment_mode,
            deposit_to: this.state.deposit_to,
            reference: this.state.reference,
            notes: this.state.notes,
            amount_inexcess: this.calcGrandTotal(),
            vendor_id: this.state.customer_id
        };

        if (this.calcGrandTotalExcess() < -1) {
            let formData2 = {
                credit_no: localStorage.getItem("Credit_No_Item1"),
                due_amount: this.formatCurrency(0)
            };
            // alert(JSON.stringify(formData))
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": TOKEN
                }
            };
            this.setState({ isSubmitted: true });
            let datas = JSON.parse(localStorage.getItem("DATAARRAY"));
            let data2 = [];
            // let final_due_amount = this.calcLineItemDiscounts();        

            for (let i = 0; i < datas.length; i++) {
                let PAYMENT = { payment_id: 0 };
                let credit_no = { credit_no: localStorage.getItem("Credit_No_Item1") };
                let created_by = { created_by: USER_ID };
                data2.push(Object.assign(PAYMENT, credit_no, created_by, datas[i]));
                this.setState({
                    data2: data2,
                })
            }

            axios
                .post(baseURL_2 + 'updatecreditvendor.php', data2)
                .then(statusresponse => {

                    alert(JSON.stringify(data2))
                    console.log("phpinvoice", statusresponse)
                    // eslint-disable-next-line
                    this.setState({ statusMessage: "Successsfully", showError: false, isShowError: true, isSubmitted: false });
                    window.scrollTo(0, 0)
                    window.setTimeout(() => {
                        this.setState({ isShowError: false });
                        this._onButtonClick();
                    }, 2000);

                }).catch(error => {
                    this.setState({
                        statusMessage: error.statusresponse === undefined
                            ? (window.location.href = '/log_in')
                            : 'Something went wrong',
                        showError: true,
                        isShowError: false,
                        isSubmitted: false,
                    });
                    window.scrollTo(0, 0);
                    // console.error('Error during service worker registration:', error);
                });

        } else {
            let formData2 = {
                credit_no: localStorage.getItem("Credit_No_Item1"),
                due_amount: this.formatCurrency(this.calcGrandTotalExcess())
            };
            // alert(JSON.stringify(formData))
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": TOKEN
                }
            };
            this.setState({ isSubmitted: true });

            let datas = JSON.parse(localStorage.getItem("DATAARRAY"));
            let data2 = [];
            for (let i = 0; i < datas.length; i++) {
                let PAYMENT = { payment_id: 0 };
                data2.push(Object.assign(PAYMENT, datas[i]));
                this.setState({
                    data2: data2,
                });
            }
            console.log(JSON.stringify(data2))
            axios
                .post(baseURL_2 + 'updatebillpayment.php', data2)
                .then(statusresponse => {
                    console.log("phpinvoice", statusresponse)
                    axios.put(baseURL + `creditnotestatus`, formData2, axiosConfig).then((statusresponse) => {
                        if (statusresponse.data.status) {
                            // eslint-disable-next-line
                            this.setState({ statusMessage: statusresponse.data.message, showError: false, isShowError: true, isSubmitted: false });
                            window.scrollTo(0, 0)
                            window.setTimeout(() => {
                                this.setState({ isShowError: false });
                                this._onButtonClick();
                            }, 2000);
                        } else {
                            this.setState({ statusMessage: statusresponse.data.message, showError: true, isShowError: false, isSubmitted: false });
                        }
                    }).catch((error) => {
                        console.log('bayoo', error)
                        this.setState({ statusMessage: error.statusresponse.data.message, showError: true, isShowError: false, isSubmitted: false });
                    })
                }).catch(error => {
                    this.setState({
                        statusMessage: error.statusresponse === undefined
                            ? (window.location.href = '/log_in')
                            : 'Something went wrong',
                        showError: true,
                        isShowError: false,
                        isSubmitted: false,
                    });
                    window.scrollTo(0, 0);
                    // console.error('Error during service worker registration:', error);
                });
        }
    }


    _onButtonClick() {
        this.setState({
            returnHome: true
        });
    }
    render = () => {
        console.log('ITEMS', this.state.lineItems);
        const ref = React.createRef();
        const { returnHome } = this.state;

        return (
            <div style={{ marginTop: "-20px" }} >

                {returnHome === true ? <Navigate to='/vendor-credit' /> : null}

                <div ref={ref}>
                    {this.state.isPageLoad ? "Loading Please wait..." :
                        <div>
                            <Card>
                                {this.state.isSubmitted ? "Submitting ...Please wait!" :

                                    <CardBody>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4><b>{`Apply Credit Note # ${localStorage.getItem("Credit_No_Item1")} to ${localStorage.getItem("Credit_No_Customer1")}`}</b></h4>
                                            </div>
                                            <div className="col-md-6">
                                                <Link to="/credit-note">
                                                    <Button className="pull-right" onClick={this._onButtonClick}
                                                        color="primary" outline><i className="fa  fa-arrow-left"></i> Credit Notes
                                                    </Button>
                                                </Link>

                                            </div>
                                        </div>

                                        {this.state.showError
                                            ? <div>
                                                <Alert color="danger" className="alert--colored" icon>
                                                    <p>
                                                        <span className="bold-text">
                                                            {this.state.statusMessage}
                                                        </span>
                                                    </p>
                                                </Alert>

                                            </div>
                                            : null}<br />

                                        {this.state.isShowError
                                            ? <div>

                                                <Alert color="success" className="alert--colored" icon>
                                                    <p>
                                                        <span className="bold-text">
                                                            {this.state.statusMessage}
                                                        </span>
                                                    </p>
                                                </Alert>

                                            </div>
                                            : null}<br />
                                        <form className="form" onSubmit={this.onSubmit}>

                                            <div className="col-md-8 offset-2">
                                                <br />
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <label className="form-label">Credit Note Amount({CURRENCY})</label>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <input
                                                                id="input"
                                                                type="text"
                                                                className="form-control"
                                                                name="amount_received"
                                                                value={this.state.amount_received}
                                                                required
                                                                readOnly
                                                                placeholder="Amount Received "
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-10 offset-1">
                                                <br />
                                                <h4><b>Unpaid Bills</b></h4>
                                                <ReactDatatable
                                                    config={this.config}
                                                    records={this.state.data}
                                                    columns={this.columns}
                                                    dynamic={true}
                                                    id="tsc"
                                                    loading={this.state.isLoading}
                                                    total_record={this.state.total}
                                                    onChange={this.tableChangeHandler} />
                                            </div>

                                            <div className="col-md-10 offset-1">

                                                <div className="col-md-12">
                                                    <br />


                                                    <div className="col-md-12">

                                                        <div className={styles.totalContainer}>
                                                            <form></form>
                                                            <form>
                                                                <div className={styles.valueTable}>
                                                                    <div className={styles.row}>
                                                                        <div className={styles.label}>Amount Received</div>
                                                                        <div
                                                                            className={"${styles.value} ${styles.currency}"}
                                                                        >
                                                                            {this.formatCurrency(
                                                                                this.state.amount_received
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className={styles.row}>
                                                                        <div className={styles.label}>Amount used for payments</div>
                                                                        <div
                                                                            className={"${styles.value} ${styles.currency}"}
                                                                        >
                                                                            {this.formatCurrency(
                                                                                this.calcLineItemDiscounts()
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className={styles.row}>
                                                                        <div className={styles.label}>Credit Note Balance</div>
                                                                        <div
                                                                            className={"${styles.value} ${styles.currency}"}
                                                                        >
                                                                            {this.calcGrandTotal()}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>

                                                        <br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12"><br />
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <Link to="/vendor-credit">
                                                                <Button outline color="primary" onClick={this._onButtonClick}>
                                                                    Cancel
                                                                </Button>
                                                            </Link>

                                                        </div>
                                                        <div className="col-md-8">
                                                            <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                                {this.state.isLoading ? "Please Wait..." : "Apply"}

                                                            </Button> &nbsp;&nbsp;&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                        <br />

                                    </CardBody>}
                            </Card>
                        </div>
                    }
                </div>
            </div >
        );
    };
}

