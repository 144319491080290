import React from 'react';
import { baseURL } from '../../../_helpers';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import logo from '../../../shared/img/logo/3.png'
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";
import { errorToast, successToast, ToastTable } from '../../../_helpers/exports';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      email_address: "",
      password: "",
      accounts: [],
      isOpen: false,
      account_id: 0,
      showPassword: false,
      setShowPassword: false,
      channel_type: "portal",
      error: "",
      errorMsg: "",
      role: "",
      id: "",
      user: [],
      statusMessage: "",
      isShowSuccess: false,
      submitted: false,
      alert_error_color: "",
      isLoggedIn: false,
      isChecked: false,
      errorShow: false,
      successShow: false,
      statis: []
    };

  }

  handleChangeEmail = event => {
    this.setState({ email_address: event.target.value });
  };
  handleChangePassword = event => {
    this.setState({ password: event.target.value });
  };


  showPasswordToggle = () => {
    // eslint-disable-next-line
    if (this.state.showPassword == true) {
      this.setState({
        showPassword: false,
      })
    } else {
      this.setState({
        showPassword: true,
      })
    }
  };

  onSubmit = e => {
    this.setState({ isLoading: true });
    // eslint-disable-next-line
    if (this.state.email_address == "") {
      this.setState({
        isLoading: false,
        error: "Email is required",
        errorShow: true,
        submitted: true,

        alert_error_color: "alert alert-danger"
      });
    } else {

      let formData = {
        "email": this.state.email_address,
        "account_id": e.id
      }
      console.log("DATA", formData)
      this.setState({ isLoading: true });

      axios.post(baseURL + `reset-password-request`, formData
      ).then((response) => {
        console.log("token", response.data);
        // eslint-disable-next-line

        successToast(response.data.message)
        this.setState({
          statusMessage: response.data.message,
          isShowError: true,
          errorShow: false,
          submitted: true,
          email_address: "",
          isLoading: false,
          isLoggedIn: true
        }, function () {
          window.setTimeout(() => {
            window.location.href = "/log_in";
            this.setState({ isShowError: false })
          }, 3000);
        });

      }).catch(error => {
        console.log('bayoo', error.response)
        this.setState({
          alert_error_color: "alert alert-danger",
          errorShow: true,
          error: error.response === undefined ? "Check your internet" : error.response.data.message,
          submitted: true,
          isLoading: false
        });
        this.setState({ password: "", email_address: "" });
      })


    }
  };




  componentDidMount() {
    this.setState({ isChecked: true });
    axios.get('http://www.geoplugin.net/json.gp').then(res => {
      this.setState({ statusMessage: res.data.status_message, isShowError: false, isLoading: false });
      this.setState({
        students: res.data,
        isLoading: false,
      },
        function () {
          console.log("data", res.data);
        });
    });
  }




  onSubmitEmail = e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    // eslint-disable-next-line
    if (this.state.email_address == "") {
      errorToast("Email Address is Mandatory");
      this.setState({
        isLoading: false,
        isOpen: false
      });
    }
    else {
      let formData = {
        "email": this.state.email_address
            }
      console.log("DATA", formData)
      this.setState({ isLoading: true });
      axios.post(baseURL + `check_email_account`, formData
      ).then((response) => {
        // eslint-disable-next-line
        if (response.data.success) {
          if (response.data.data && response.data.data.length > 0) {
            this.setState({
              isOpen: true,
              isLoading: false,
              accounts: response.data.data
            })
          } else {
            errorToast("Email not found!")
            this.setState({
              isLoading: false,
              isOpen: false
            });
          }
        }
        else {
          errorToast(response.data.message)
          this.setState({
            isLoading: false,
            isOpen: false
          });
        }
      }).catch(error => {
        errorToast(error.response === undefined ? "Check your internet" : error.response.data.message)
        this.setState({
          isLoading: false
        });
        this.setState({ password: "", email_address: this.state.email_address });
      })
    }
  };

  render() {
    const { submitted } = this.state;
    const { errorShow } = this.state;

    return (
      <div className="account account">

        <div className="account__wrapper">
          {ToastTable()}

          <Modal
            isOpen={this.state.isOpen}
            onRequestClose={e => {
              this.closeModal(e);
            }}
            contentLabel="My dialog"
            className="mymodal_outside"
            onAfterOpen={() => {
              document.body.style.overflow = 'hidden';
            }}
            onAfterClose={() => {
              document.body.removeAttribute('style');
            }}
            overlayClassName="myoverlay"
            closeTimeoutMS={500} >

            <MDBCloseIcon onClick={this.closeModal} />


            <h4><b>Select your company to proceed</b></h4><br />
            {this.state.accounts && this.state.accounts.length > 0 && this.state.accounts.map((e, i) =>
              <>
                <Button color="primary" className="btn-paypal btn-lg" onClick={eh => {
                  // this.setState({ account_id: e.id })
                  this.onSubmit(e)
                }
                }>
                  {this.state.isLoading ? "Saving..." : e.company_name}
                </Button> <br />
                <br />
              </>
            )}


          </Modal>



          <div className="account__card" style={{ paddingTop: "30px", paddingBottom: "30px" }} >

            <Link to="/">
              <img src={logo} alt="" />
            </Link>

            <Form className="form login-form" onSubmit={this.onSubmitEmail}>
              {submitted ? (
                <div>
                  {this.state.isShowError ? (
                    <div
                      color="success"
                      style={{ fontSize: "13px", color: "green" }}>
                      {this.state.statusMessage}
                    </div>

                  ) : null}
                  {errorShow && (

                    <div>
                      <div
                        color="red"
                        style={{ fontSize: "13px", color: "red" }}>
                        {this.state.error}
                      </div>

                    </div>
                  )}

                </div>
              ) : null}
              <div className="form__form-group">
                <br></br>
                <span className="form__form-group-label" style={{ color: "white" }}>Email</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AlternateEmailIcon />
                  </div>
                  <Form.Control
                    autoFocus
                    type="email"
                    name="email"
                    style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                    placeholder="Email Addresss"
                    className="input-without-border-radius"
                    value={this.state.email_address}
                    onChange={this.handleChangeEmail}
                  />

                </div>
              </div>


              <div className="form__form-group">
                <br /><br />
                {
                  <Button className="account__btn" type='submit' style={{ color: "" }} outline color="primary"> {
                    this.state.isLoading ? "Please wait..." : "Submit"
                  }</Button>

                }
              </div>
              <div className="account__have-account">
                <p>Remember your password?  <NavLink to="/log_in">Login</NavLink></p>
              </div>
            </Form>

          </div>
        </div>
      </div >
    );
  }
}

export default ForgotPassword;
