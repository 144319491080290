import React, { Component } from 'react';
import styles from './Invoice.module.scss';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Form } from 'react-bootstrap';
import LineItems from './LineItems.jsx';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { uuid } from 'uuidv4';
import { BRANCH_ID, CURRENCY, PROFILE_URL, formatCurrency, TOKEN, USER_ID } from '../../_helpers/exports';
import { baseURL, baseURL_2 } from '../../_helpers';
import Alert from '../../shared/components/Alert';
import { Link, Navigate } from 'react-router-dom';
import $ from 'jquery';
import CreatableSelect from 'react-select/creatable';
import * as moment from 'moment';
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";
import LineItemsBills from './LineItemsBills';
import { RadioGroup, ReversedRadioButton, RadioButton } from 'react-radio-buttons'
// import RadioButtonGroup from 'react-radio-button';
window.$ = window.jQuery = require('jquery');
require('../../_assets/jss/sms_counter.js');
var invNum = require('invoice-number');

window.localStorage.setItem("num", "ABC000001")

class Bill_Recurring extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);
    this.uploadProfile = this.uploadProfile.bind(this);

    this.toggleModalCreate = this.toggleModalCreate.bind(this);

    this._onButtonClick = this._onButtonClick.bind(this);

  }


  locale = 'en-US';
  currency = CURRENCY;

  state = {
    taxRate: 0.00,
    invoice_no: invNum.InvoiceNumber.next(localStorage.getItem("num")),
    bill_date: new Date(),
    due_date: new Date(),
    selectedValue: "",
    discount_amount: 0,
    tax_inclusive: 0,
    tax_exclusive: 1,
    isOpen: false,
    showtax: true,
    radioOptions: [
      { value: '1', text: 'Tax Inclusive' },
      { value: '0', text: 'Tax Exclusive' }
    ],
    editedInvoice: {},
    returnHome: false,
    terms3: [
      {
        label: 'Due on Receipt',
        value: 'Due on Receipt',
      },
      {
        label: 'Due in 15 days',
        value: 'Due in 15 days',
      },
      {
        label: 'Due in 30 days',
        value: 'Due in 30 days',
      },
    ],


    frequency_load: [
      {
        label: 'Daily',
        value: 'Daily',
      },
      {
        label: 'Weekly',
        value: 'Weekly',
      },
      {
        label: 'After 2 Weeks',
        value: 'After 2 Weeks',
      },
      {
        label: 'Monthly',
        value: 'Monthly',
      },
      {
        label: 'After 2 Months',
        value: 'After 2 Months',
      },
      {
        label: 'After 3 Months',
        value: 'After 3 Months',
      },
      {
        label: 'After 6 Months',
        value: 'After 6 Months',
      },
      {
        label: 'Yearly',
        value: 'Yearly',
      },
      {
        label: 'After 2 Years',
        value: 'After 2 Years',
      },

    ],
    lineItems: [
      {
        id: 'initial', // react-beautiful-dnd unique key
        name: '',
        account: '',
        customer: '',
        quantity: 1,
        discount_percentage: 0,
        tax_percentage: 0,
        price: 0.00,
      },
    ],
  };

  toggleModalCreate = e => {
    this.setState({
      isOpen: true,
    });
  };

  closeModalUpload = e => {
    this.setState({
      isOpen: false,
    });
  };

  //handle profile picture upload
  handleProfile = event => {
    this.setState({
      imagefile: event.target.files[0]
    });
  };

  uploadProfile = event => {
    event.preventDefault();
    var s = localStorage.getItem("profile_url");
    var final = s.substr(s.lastIndexOf('/') + 1);
    if (this.state.imagefile.size < 5242880) {
      this.setState({ loading: true });
      var data = new FormData();
      data.append("filename", this.state.imagefile);
      data.append("profile_url", final);
      data.append("user_id", USER_ID);
      this.setState({ isLoading1: true });
      axios.post(baseURL_2 + 'uploaddocument.php', data).then((response) => {
        // eslint-disable-next-line
        if (response.data.status = true) {
          this.setState({ statusMessage: response.data.message, isShowError: true, loading: false, isLoading1: false },
            function () {
              console.log("bayo")
              setTimeout(() => {
                this.setState({ statusMessage: "", isShowError: false, loading: false, isLoading1: false })
                this.closeModalUpload();

                // this.getData();
              }, 2000);
            }
          );
        } else {
          this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, loading: false, isLoading1: false },
            function () {
              console.log("bayoddd", data)
              setTimeout(() => {
                this.setState({ statusMessage: "", isShowError: false, showError: false, isLoading1: false })
              }, 2000);
            }
          );
        }
      }).catch((error) => {
        console.log('bayoo', error.response)
        this.setState({ isShowError: false, showError: false, statusMessage: error.response.data.message, showError: true, isLoading1: false },
          function () {
            setTimeout(() => {
              this.setState({ statusMessage: "", isShowError: false, showError: false, isLoading1: false })
            }, 2000);
          }
        );
      })
    } else {
      alert("Your logo should not be more than 5mbs")
    }
  }
  async componentDidMount() {
    $('#message').countSms('#sms-counter');

    if (PROFILE_URL == "null") {
      localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
    } else {
      localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
      var img = new Image;
      img.crossOrigin = "*"
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
        canvas.toDataURL('image/jpeg', 1.0)
        var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
        localStorage.setItem("image_urls", fullQuality)
      };
      img.src = `${PROFILE_URL}?canvas=true`;
    }

    localStorage.setItem("num", invNum.InvoiceNumber.next(localStorage.getItem("num")))
    const [customerResponse] = await Promise.all([
      axios.get(baseURL + `vendor?branch_id=${BRANCH_ID}&&page_size=1000&page_number=1&client_id=` + USER_ID, {
        headers: { Authorization: TOKEN },
      }),
    ]);
    this.setState({
      isLoading: false,
      returnHome: false,
      invoice_no: invNum.InvoiceNumber.next(this.state.invoice_no),

      customerResponse: customerResponse.data.data,
    });

    console.log('customer', this.state.customerResponse);
  }
  handleLineItemChange = elementIndex => event => {

    if (this.state.tax_inclusive != null) {
      let lineItems = this.state.lineItems.map((item, i) => {
        if (elementIndex !== i) return item;
        return { ...item, [event.target.name]: event.target.value };
      });
      this.setState({ lineItems });
    } else {
      alert("Please select if the items are tax inclusive or exclusive first!")
    }
  };

  Customer() {
    return (
      this.state.customerResponse &&
      (this.state.customerResponse.length > 0 || this.state.customerResponse.length === 0) &&
      this.state.customerResponse.map((countyItem, i) => ({
        label: countyItem.vendor_display_name + " (" + countyItem.email + ")",
        customer_email: countyItem.email,
        customer_nameinvoice: countyItem.vendor_display_name,
        value: countyItem.id,
      }))
    );
  }

  handleChangeFrequency = value => {
    if (value != null) {
      // var newDate = new Date(this.state.invoice_date).add(Date.DAY, +1).format('Y-m-d');
      var daily = moment(new Date(new Date(this.state.bill_date).setDate(new Date(this.state.bill_date).getDate() + 1))).format('YYYY-MM-DD HH:mm:ss');
      var weekly = moment(new Date(new Date(this.state.bill_date).setDate(new Date(this.state.bill_date).getDate() + 7))).format('YYYY-MM-DD HH:mm:ss');
      var monthly = moment(new Date(new Date(this.state.bill_date).setDate(new Date(this.state.bill_date).getDate() + 30))).format('YYYY-MM-DD HH:mm:ss');
      var yearly = moment(new Date(new Date(this.state.bill_date).setDate(new Date(this.state.bill_date).getDate() + 365))).format('YYYY-MM-DD HH:mm:ss');
      var two_weeks = moment(new Date(new Date(this.state.bill_date).setDate(new Date(this.state.bill_date).getDate() + 14))).format('YYYY-MM-DD HH:mm:ss');
      var two_months = moment(new Date(new Date(this.state.bill_date).setDate(new Date(this.state.bill_date).getDate() + 60))).format('YYYY-MM-DD HH:mm:ss');
      var three_months = moment(new Date(new Date(this.state.bill_date).setDate(new Date(this.state.bill_date).getDate() + 90))).format('YYYY-MM-DD HH:mm:ss');
      var two_years = moment(new Date(new Date(this.state.bill_date).setDate(new Date(this.state.bill_date).getDate() + 730))).format('YYYY-MM-DD HH:mm:ss');
      var six_months = moment(new Date(new Date(this.state.bill_date).setDate(new Date(this.state.bill_date).getDate() + 180))).format('YYYY-MM-DD HH:mm:ss');
      // alert(newDate)
      if (value.value.toString() === "Daily") {
        this.setState(
          {
            frequency_type: value.value.toString(),
            frequency: daily
          });
      }
      if (value.value.toString() === "Weekly") {
        this.setState(
          {
            frequency_type: value.value.toString(),
            frequency: weekly
          });
      }
      if (value.value.toString() === "After 2 Weeks") {
        this.setState(
          {
            frequency_type: value.value.toString(),
            frequency: two_weeks
          });
      }
      if (value.value.toString() === "Monthly") {
        this.setState(
          {
            frequency_type: value.value.toString(),
            frequency: monthly
          });
      }
      if (value.value.toString() === "After 2 Months") {
        this.setState(
          {
            frequency_type: value.value.toString(),
            frequency: two_months
          });
      }
      if (value.value.toString() === "After 3 Months") {
        this.setState(
          {
            frequency_type: value.value.toString(),
            frequency: three_months
          });
      }
      if (value.value.toString() === "After 6 Months") {
        this.setState(
          {
            frequency_type: value.value.toString(),
            frequency: six_months
          });
      }
      if (value.value.toString() === "Yearly") {
        this.setState(
          {
            frequency_type: value.value.toString(),
            frequency: yearly
          });
      }
      if (value.value.toString() === "After 2 Years") {
        this.setState(
          {
            frequency_type: value.value.toString(),
            frequency: two_years
          });
      }
    }

    else {
      this.setState(
        {
          frequency_type: "",
        });
    }
  };

  handleAddLineItem = event => {


    for (let i = 0; i < this.state.lineItems.length; i++) {

      if (this.state.lineItems[i].name === '') {
        alert("Name of the item can not be null")
        this.setState({
          // use optimistic uuid for drag drop; in a production app this could be a database id
          lineItems: this.state.lineItems.concat([]),
        });
      } else {
        this.setState({
          // use optimistic uuid for drag drop; in a production app this could be a database id
          lineItems: this.state.lineItems.concat([
            {
              id: uuid(), // react-beautiful-dnd unique key
              name: '',
              account: '',
              customer: '',
              quantity: 1,
              discount_percentage: 0,
              tax_percentage: 0,
              price: 0.00,
            },
          ]),
        });
      }
    }


  };

  handleRemoveLineItem = elementIndex => event => {
    this.setState({
      lineItems: this.state.lineItems.filter((item, i) => {
        return elementIndex !== i;
      }),
    });
  };

  handleReorderLineItems = newLineItems => {
    this.setState({
      lineItems: newLineItems,
    });
  };
  handleChangeInvoiceMessage = event => {
    this.setState({ invoice_message: event.target.value }, function () { });
    //alert(event.target.value)
  };

  handleChangeStatementMessage = event => {
    this.setState({ notes: event.target.value }, function () { });
    //alert(event.target.value)
  };

  handleFocusSelect = event => {
    event.target.select();
  };

  handlePayButtonClick = () => {
    alert('Not implemented');
  };

  // formatCurrency = amount => {
  //   return new Intl.NumberFormat(this.locale, {
  //     style: 'currency',
  //     currency: "ABS",
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2
  //   }).format(amount).replaceAll('ABS', CURRENCY);
  // };

  formatCurrency1 = amount => {
    return new Intl.NumberFormat(this.locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };
  calcTaxAmount = c => {
    return c * (localStorage.getItem('valuestax') / 100);
  };
  calcLineItemsTotal = () => {

    if (this.state.showtax == true) {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + cur.quantity * cur.price,
        0
      );
    } else {

      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.quantity * cur.price) / (1 + (cur.tax_percentage / 100))),
        0
      );
    }


  };

  calcLineItemDiscounts = () => {
    return this.state.discount_amount;
  };


  calcGrandTotal = () => {
    return (
      this.calcLineItemsTotal() +
      this.calcTaxTotal() -
      this.calcLineItemDiscounts()
    );
  };

  onSelectChangesShort = value => {
    if (value != null) {

      this.setState(
        {
          ...this.state.editedInvoice,
          customer_id: value.value.toString(),
          customer_name: value.label.toString(),
          customer_nameinvoice2: value.customer_nameinvoice.toString(),
          customer_email: value.customer_email != null ? value.customer_email.toString() : '',
        });
    }
  };

  handleChange = e => {
    this.setState({
      ...this.state.editedInvoice,
      [e.target.name]: e.target.value,
    });

    console.log('updates', this.state.editedInvoice);
  };



  handleChangeType = value => {

    if (value != null) {
      this.setState(
        {
          terms: value.value.toString()
        });

      if (value.value.toString() === "Due in 15 days") {
        this.setState({
          due_date: moment(new Date(this.state.bill_date)).add(15, 'days').toDate()
        })
      }
      else if (value.value.toString() === "Due in 30 days") {
        this.setState({
          due_date: moment(new Date(this.state.bill_date)).add(30, 'days').toDate()
        })
      }

      else {
        this.setState({
          due_date: new Date()
        })
      }
    }
    else {
      this.setState(
        {
          terms: "",
        });
    }
  };


  calcTaxTotal = () => {
    if (this.state.showtax == true) {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.tax_percentage / 100) * (cur.quantity * cur.price)),
        0
      );
    } else {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.quantity * cur.price) - ((cur.quantity * cur.price) / (1 + (cur.tax_percentage / 100)))),
        0
      );
    }
  };


  onSubmit(e) {
    e.preventDefault();
    // eslint-disable-next-line
    // this.state.editedInvoice = {

    //   bill_date: this.state.bill_date,
    //   terms: this.state.terms,
    //   due_date: this.state.due_date,
    //   message_invoice: this.state.invoice_message,
    //   statement_invoice: this.state.statement_message,
    //   customer_name: this.state.customer_nameinvoice2,
    //   customer_email: this.state.customer_email,
    //   amount1: formatCurrency1(this.calcGrandTotal()),
    //   amount: formatCurrency(this.calcGrandTotal()),
    //   due_amount: formatCurrency(this.calcGrandTotal()),
    //   tax_amount: formatCurrency(this.calcTaxTotal()),
    //   discount_amount: formatCurrency(this.calcLineItemDiscounts()),
    //   lineItems: this.state.lineItems,
    //   currency: 'Ksh.',
    //   tax: formatCurrency(this.calcTaxTotal()),
    //   subtoal: formatCurrency(this.calcLineItemsTotal()),
    //   total: formatCurrency(this.calcGrandTotal()),

    // };

    let formData = {

      bill_date: this.state.bill_date,
      terms: this.state.terms,
      due_date: this.state.due_date,
      notes: this.state.notes,
      tax_inclusive: this.state.tax_inclusive,
      sub_total: formatCurrency(this.calcLineItemsTotal()),
      amount: formatCurrency(this.calcGrandTotal()),
      due_amount: formatCurrency(this.calcGrandTotal()),
      tax_amount: formatCurrency(this.calcTaxTotal()),
      discount_amount: formatCurrency(this.calcLineItemDiscounts()),
      created_by: USER_ID,
      recurring: "1",
      vendor_id: this.state.customer_id,
      start_time: moment(this.state.bill_date).format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment(this.state.due_date).format('YYYY-MM-DD HH:mm:ss'),
      frequecy: (new Date(this.state.frequency).getTime()) / 1000,
      frequency_type: this.state.frequency_type
    };

    // alert( JSON.stringify(formData))
    // console.log("DATA",)
    this.setState({ isLoading: true });
    axios
      .post(baseURL + `bill`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: TOKEN,
        },
      })
      .then(response => {
        if (response.data.status) {
          this.setState({
            statusMessage: response.data.message,
            isShowError: true,
            isLoading: false,
            showError: false
          });

          // localStorage.setItem("BILL_SENDEMAIL", JSON.stringify(this.state.editedInvoice))

          localStorage.setItem("BILL_CREATEPEAK", JSON.stringify(this.state.lineItems))
          window.setTimeout(() => {
            this.setState({ isShowError: false, returnHome: true });
            this._onButtonClick();
          }, 2000);
          window.scrollTo(0, 0);
        } else {
          this.setState({
            statusMessage: response.data.message,
            showError: true,
            isShowError: false,
            isLoading: false,
          });
          window.scrollTo(0, 0);
        }
      })
      .catch(error => {
        console.log('bayoo', error.response);
        this.setState({
          statusMessage: error.response.data.message,
          showError: true,
          isShowError: false,
          isLoading: false,
        });
        window.scrollTo(0, 0);
      });
  }


  _onButtonClick() {
    this.setState({
      returnHome: true
    });
  }

  handleSelection(value) {
    this.setState({ selectedValue: value });
  }

  loadOptions = inputValue => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: TOKEN,
      },
    };
    this.setState({
      isLoading: true,
    });
    let url =
      baseURL +
      `customer?branch_id=${BRANCH_ID}&&client_id=` +
      USER_ID +
      `&&filter_value=${inputValue}`;
    axios.get(url, axiosConfig).then(itemResponse => {
      this.setState(
        {
          item: itemResponse.data.data,
        },
        function () {
          this.setState({
            isLoading: false,
            bayo: this.state.item &&
              (this.state.item.length > 0 || this.state.item.length === 0) &&
              this.state.item.map((countyItem, i) => ({
                label: countyItem.customer_display_name,
                customer_email: countyItem.email,
                value: countyItem.id,
              })),
          });
        }
      );
    });
  };

  handleCreate = inputValue => {
    const newValue = { value: inputValue.toLowerCase(), label: inputValue };
    this.setState({ newValue })
    // setOptions([...options, newValue]);
    // setValue(newValue);

  };

  onChangeOption(value) {
    console.log(value);
    if (value == 1) {
      this.setState({
        showtax: false,
        tax_inclusive: '1',
        tax_amount: 0
      })
    } else {
      this.setState({
        showtax: true,
        tax_inclusive: '0'
      })
    }
    // alert(value)
  }


  onChangeOption(value) {
    console.log(value);
    if (value == 1) {
      this.setState({
        showtax: true,
        tax_inclusive: 0,
        tax_exclusive: 1,
      })
    } else {
      this.setState({
        showtax: false,
        tax_inclusive: 1,
        tax_exclusive: 0

      })
    }
    // alert(value)
  }

  handleChangeDisco = e => {
    this.setState({
      discount_amount: e.target.value,
    });

    console.log('updates', this.state.editedInvoice);
  };



  handleOptionChange = e => {
    this.setState({
      selectedOption: e.target.value
    });

    alert(this.state.selectedOption)
  }

  render = () => {

    console.log('ITEMS', this.state.lineItems);
    const ref = React.createRef();
    const { editedInvoice, returnHome } = this.state;
    return (
      <div style={{ marginTop: "-20px" }} >
        {returnHome === true ? <Navigate to='/recurring-bills' /> : null}

        <div ref={ref}>
          <Card>

            <Modal
              isOpen={this.state.isOpen}
              onRequestClose={e => {
                this.closeModalUpload(e);
              }}
              contentLabel="My dialog"
              className="mymodal"
              onAfterOpen={() => {
                document.body.style.overflow = 'hidden';
              }}
              onAfterClose={() => {
                document.body.removeAttribute('style');
              }}
              overlayClassName="myoverlay"
              closeTimeoutMS={500}
            >
              <MDBCloseIcon onClick={this.closeModalUpload} />
              <h4><b>Upload Files  </b></h4><br />

              {this.state.showError ? <div >

                <Alert color="danger" className="alert--colored" icon>
                  <p><span className="bold-text">{this.state.statusMessage}</span>
                  </p>
                </Alert>


              </div> : null}<br></br>

              {this.state.isShowError ? (
                <div>

                  <Alert color="success" className="alert--colored" icon>
                    <p><span className="bold-text">{this.state.statusMessage}</span>
                    </p>
                  </Alert>
                </div>
              ) : null}
              <div className="wrapper text-center">
                <div className="section1">
                  <div className="contain">
                    <input
                      className="csv-input"
                      type="file"
                      required
                      ref={input => {
                        this.filesInput = input;
                      }}
                      name="file"
                      customHeight
                      placeholder={null}
                      onChange={this.handleProfile}
                    />
                    <br />
                    <Button color="primary" className="btn-paypal btn-lg " onClick={this.uploadProfile} >
                      {this.state.isLoading1 ? "Uploading..." : "Upload now!"}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;

                    <br />
                  </div>
                </div>
              </div>

            </Modal>
            <CardBody>
              <div className="row">
                <div className="col-md-6">
                  <h4><b>Add Recurring Bill</b></h4>
                </div>
                <div className="col-md-6">
                  <Link to="/recurring-bills">
                    <Button className="pull-right" onClick={this._onButtonClick}
                      color="primary" outline><i className="fa  fa-arrow-left"></i> Recurring Bills
                    </Button>
                  </Link>

                </div>
              </div>

              {this.state.showError
                ? <div>

                  <Alert color="danger" className="alert--colored" icon>
                    <p>
                      <span className="bold-text">
                        {this.state.statusMessage}
                      </span>
                    </p>
                  </Alert>

                </div>
                : null}<br />

              {this.state.isShowError
                ? <div>

                  <Alert color="success" className="alert--colored" icon>
                    <p>
                      <span className="bold-text">
                        {this.state.statusMessage}
                      </span>
                    </p>
                  </Alert>

                </div>
                : null}<br />
              <form onSubmit={this.onSubmit}>
                <br /><br />
                <div className="col-10 offset-1 row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-2">
                          <label className="form-label">Supplier</label>
                        </div>
                        <div className="col-md-10">
                          <Select
                            isClearable
                            options={this.Customer()}
                            onInputChange={this.loadOptions}
                            placeholder="Search Supplier"
                            autosize={true}
                            onChange={this.onSelectChangesShort}
                            isLoading={this.state.isLoading}
                            // noOptionsMessage={() => 'nothing found'}
                            // loadingMessage={() => 'searching...'}   //minor type-O here
                            menuPortalTarget={document.body}
                            name="namffe"
                          /><br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <Form.Group className="Focus-line">
                      <div className="row">
                        <div className="col-md-2">
                          <Form.Label>Start date</Form.Label>
                        </div>
                        <div className="col-md-10 customDatePickerWidth">
                          <DatePicker
                            selected={this.state.bill_date}
                            label="DatePicker Label"
                            dateFormat="dd MMM yyyy"
                            required
                            minDate={moment().toDate()}
                            value={editedInvoice.bill_date}
                            className="form-control"
                            id="input"
                            onChange={value =>
                              this.setState({ bill_date: value })}
                          />

                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <Form.Group className="Focus-line">
                      <div className="row">
                        <div className="col-md-2">
                          <Form.Label>Payment Terms</Form.Label>
                        </div>
                        <div className="col-md-10">
                          <CreatableSelect
                            isClearable
                            options={this.state.terms3}
                            // onInputChange={this.loadOptions}
                            placeholder="Select terms or enter custom date"
                            autosize={true}
                            onChange={this.handleChangeType}
                            isLoading={this.state.isLoading}
                            // noOptionsMessage={() => 'nothing found'}
                            // loadingMessage={() => 'searching...'}   //minor type-O here
                            menuPortalTarget={document.body}
                            name="namffe"
                          /><br />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <Form.Group className="Focus-line">
                      <div className="row">
                        <div className="col-md-2">
                          <Form.Label>Frequency</Form.Label>
                        </div>
                        <div className="col-md-10">

                          <Select
                            isClearable
                            options={this.state.frequency_load}
                            // onInputChange={this.loadOptions}
                            placeholder="Select frequency"
                            autosize={true}
                            onChange={this.handleChangeFrequency}
                            isLoading={this.state.isLoading}
                            // noOptionsMessage={() => 'nothing found'}
                            // loadingMessage={() => 'searching...'}   //minor type-O here
                            menuPortalTarget={document.body}
                            name="namffe"
                          /><br />

                        </div>
                      </div>

                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <Form.Group className="Focus-line">
                      <div className="row">
                        <div className="col-md-2">
                          <Form.Label>Due date</Form.Label>
                        </div>
                        <div className="col-md-10 customDatePickerWidth">
                          <DatePicker
                            selected={this.state.due_date}
                            label="DatePicker Label"
                            dateFormat="dd MMM yyyy"
                            minDate={moment().toDate()}

                            className="form-control"
                            id="input"
                            onChange={value =>
                              this.setState({ due_date: value })}
                          />

                        </div>
                      </div>
                    </Form.Group>

                  </div>
                </div>

                <div className="col-md-12">

                  <div className="col-md-12">
                    <br />
                    <div className="col-md-3"   style={{ padding: 10, display: 'none', boxSizing: 'content-box', fontFamily: "bold" }}>

                      <RadioGroup onChange={this.onChangeOption} value={this.tax_exclusive} horizontal>
                        <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                          Tax Exclusive
                        </RadioButton>
                        <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                          Tax Inclusive
                        </RadioButton>

                      </RadioGroup>
                    </div>
                    <br />
                    <LineItemsBills
                      items={this.state.lineItems}
                      currencyFormatter={formatCurrency}
                      addHandler={this.handleAddLineItem}
                      showtax={this.state.showtax}
                      changeHandler={this.handleLineItemChange}
                      focusHandler={this.handleFocusSelect}
                      deleteHandler={this.handleRemoveLineItem}
                      reorderHandler={this.handleReorderLineItems}
                    />

                    <div className="col-md-12">

                      <div className={styles.totalContainer}>
                        {/* <form>
                            <div className={styles.valueTable}>
                              <div className={styles.row}>
                                <div className={styles.label}>Tax Rate (%)</div>
                                <div className={styles.value}>
                                  <input
                                    name="taxRate"
                                    type="text"
                                    id="#js-testInputs"
                                    value={localStorage.getItem('valuestax')}
                                    onChange={this.handleInvoiceChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </form> */}
                        <form></form>
                        <form>
                          <div className={styles.valueTable}>
                            <div className={styles.row}>
                              <div className={styles.label}>Subtotal</div>
                              <div
                                className={`${styles.value} ${styles.currency}`}
                              >
                                {formatCurrency(
                                  this.calcLineItemsTotal()
                                )}
                              </div>
                            </div>
                            <div className={styles.row}>
                              <div className={styles.label}>
                                Tax
                              </div>
                              <div
                                className={`${styles.value} ${styles.currency}`}
                              >
                                {formatCurrency(this.calcTaxTotal())}
                              </div>
                            </div>

                            <div className={styles.row}>
                              <div className={styles.label}>Discount</div>
                              <div
                                className={`${styles.value} ${styles.currency}`}
                              >
                                <input
                                  id="input"
                                  type="number"
                                  className="form-control"
                                  name="discount_amount"
                                  placeholder="Discount Amount"
                                  value={this.state.discount_amount}
                                  onChange={this.handleChangeDisco}
                                />
                                {/* {formatCurrency(
                                
                              )} */}
                              </div>
                            </div>
                            <div className={styles.row}>
                              <div className={styles.label}>Total Due</div>
                              <div
                                className={`${styles.value} ${styles.currency}`}
                              >
                                {formatCurrency(this.calcGrandTotal())}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* 
                  <div className={styles.pay}>
                    <button className={styles.payNow} onClick={this.handlePayButtonClick}>Pay Now</button>
                  </div> */}
                      <br />
                    </div>
                  </div>
                </div>


                <div className="col-md-6">
                  <br />
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-2">

                        <label className="form-label">
                          Notes
                        </label>
                      </div>
                      <div className="col-md-8">
                        <textarea
                          name="statement_message"
                          
                          style={{
                            paddingTop: '20px',
                            borderRadius: '5px',
                            borderColor: 'hsl(0,0%,80%)',
                          }}
                          onChange={this.handleChangeStatementMessage}
                          className="col-md-12"
                          placeholder="Hint: I"
                          id="message"
                          cols="col-md-1"
                          rows="1"
                        />
                      </div>
                      <div className="col-md-2">
                        <ul id="sms-counter">
                          <li>Length: <span class="length" /></li>
                          {/* <li>SMS: <span class="messages"></span></li> */}
                          <li>
                            Message:
                            {' '}
                            <span class="remaining" />
                            /
                            <span class="per_message" />
                          </li>
                          {/* <li>Remaining: </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label">Upload Documents &nbsp;&nbsp;&nbsp;<a href="#" onClick={this.toggleModalCreate} >Click here</a></label>
                      </div>
                      {/* <div className="col-md-8">

                                                     
                                                    <br />
                                                </div> */}


                    </div>


                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />

                <div className="col-md-12"><br />
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-2">
                        <Link to="/invoices">
                          <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                      <div className="col-md-10">
                        <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                          {this.state.isLoading ? "Please Wait..." : "Save"}
                        </Button> &nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <br />
            </CardBody>
          </Card>
        </div>
      </div>
    );
  };
}

export default Bill_Recurring;
