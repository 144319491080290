import React from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import Modal from 'react-modal';
import { MDBCloseIcon } from "mdbreact";
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { BRANCH_ID, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, Navigate } from 'react-router-dom';
import Alert from '../../shared/components/Alert';
import CreatableSelect from 'react-select/creatable';
import * as moment from 'moment';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import $ from 'jquery';
import { recurring_viewexpense } from './recurring_expensesview';
import { costofgoodOptions, groupedOptions } from './data';
import { currentassets, groupedOptions_two } from './paidthrough';

class RecurringExpenseCreate extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
            moreinfo: false,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onMoreInfo = this._onMoreInfo.bind(this);

        this.handleChangeTax = this.handleChangeTax.bind(this);
        this.uploadProfile = this.uploadProfile.bind(this);

        this.toggleModalCreate = this.toggleModalCreate.bind(this);

        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            tax_amount: 0,
            amount: 0,

            showError: false,
            date2: new Date(),
            date: new Date(),
            due_date: new Date(),
            type: [{
                name: 'Mr.'
            }, {
                name: 'Mrs.'
            },
            {
                name: 'Ms.'
            },
            {
                name: 'Miss.'
            },
            {
                name: 'Dr.'
            }],

            priority: [{
                name: 'Print Later'
            }, {
                name: 'Send Later'
            },
            {
                name: 'None'
            }],

            tax: [
                {
                    label: 'VAT (16%)',
                    value: 16
                }
            ],

            product: [
                // {
                //     label: 'VAT (14%)',
                //     value: 14
                // }
            ],
            frequency_load: [
                {
                    label: 'Daily',
                    value: 'Daily',
                },
                {
                    label: 'Weekly',
                    value: 'Weekly',
                },
                {
                    label: 'After 2 Weeks',
                    value: 'After 2 Weeks',
                },
                {
                    label: 'Monthly',
                    value: 'Monthly',
                },
                {
                    label: 'After 2 Months',
                    value: 'After 2 Months',
                },
                {
                    label: 'After 3 Months',
                    value: 'After 3 Months',
                },
                {
                    label: 'After 6 Months',
                    value: 'After 6 Months',
                },
                {
                    label: 'Yearly',
                    value: 'Yearly',
                },
                {
                    label: 'After 2 Years',
                    value: 'After 2 Years',
                },

            ],

            avenue: '',
            assigned_to: '',
            product2: false,
            billing: 0,
            amount: 0,
            isOpen: false,
            product_name: '',
            selectedCounty: 'Select County/State Name',
        }

    }

    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    toggleModalCreate = e => {
        this.setState({
            isOpen: true,
        });
    };

    closeModalUpload = e => {
        this.setState({
            isOpen: false,
        });
    };

    //handle profile picture upload
    handleProfile = event => {
        this.setState({
            imagefile: event.target.files[0]
        });
    };

    uploadProfile = event => {
        event.preventDefault();
        var s = localStorage.getItem("profile_url");
        var final = s.substr(s.lastIndexOf('/') + 1);
        if (this.state.imagefile.size < 5242880) {
            this.setState({ loading: true });
            var data = new FormData();
            data.append("filename", this.state.imagefile);
            data.append("profile_url", final);
            data.append("user_id", USER_ID);
            this.setState({ isLoading1: true });
            axios.post(baseURL_2 + 'uploaddocument.php', data).then((response) => {
                // eslint-disable-next-line
                if (response.data.status = true) {
                    this.setState({ statusMessage: response.data.message, isShowError: true, loading: false, isLoading1: false },
                        function () {
                            console.log("bayo")
                            setTimeout(() => {
                                this.setState({ statusMessage: "", isShowError: false, loading: false, isLoading1: false })
                                this.closeModalUpload();

                                // this.getData();
                            }, 2000);
                        }
                    );
                } else {
                    this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, loading: false, isLoading1: false },
                        function () {
                            console.log("bayoddd", data)
                            setTimeout(() => {
                                this.setState({ statusMessage: "", isShowError: false, showError: false, isLoading1: false })
                            }, 2000);
                        }
                    );
                }
            }).catch((error) => {
                console.log('bayoo', error.response)
                this.setState({ isShowError: false, showError: false, statusMessage: error.response.data.message, showError: true, isLoading1: false },
                    function () {
                        setTimeout(() => {
                            this.setState({ statusMessage: "", isShowError: false, showError: false, isLoading1: false })
                        }, 2000);
                    }
                );
            })
        } else {
            alert("Your logo should not be more than 5mbs")
        }
    }

    handleChangeTerms = value => {

        if (value != null) {
            if (value.value.toString() == "Land" || value.value.toString() == "Furniture Fittings" ||
                value.value.toString() == "Equipments" || value.value.toString() == "Computers & peripherals"
                || value.value.toString() == "Motor Vehicles" || value.value.toString() == "Revaluation Reserves") {

                this.setState(
                    {
                        terms: "Furniture and Equipment",
                    });

            } else {
                this.setState(
                    {
                        terms: value.value.toString(),
                    });
            }

        }
        else {
            this.setState(
                {
                    terms: "",
                });
        }
    };

    handleChangePaid = value => {

        if (value != null) {
            if (value.value.toString() == "Land" || value.value.toString() == "Furniture Fittings" ||
                value.value.toString() == "Equipments" || value.value.toString() == "Computers & peripherals"
                || value.value.toString() == "Motor Vehicles" || value.value.toString() == "Revaluation Reserves") {

                this.setState(
                    {
                        paid_through: "Furniture and Equipment",
                    });

            } else {
                this.setState(
                    {
                        paid_through: value.value.toString(),
                    });
            }
        }
        else {
            this.setState(
                {
                    paid_through: "",
                });
        }
    };


    handleChangeProduct = value => {

        if (value != null) {
            this.setState(
                {
                    product: value.value.toString(),
                });
        }
        else {
            this.setState(
                {
                    product: "",
                });
        }
    };
    handleChangeTax = value => {

        if (value != null) {
            if (this.state.amount == 0) {
                this.setState(
                    {
                        tax_amount: value.value.toString(),
                        total_amount: 0

                    });
            }
            else {
                this.setState(
                    {
                        tax_amount: value.value.toString(),
                        total_amount: parseFloat(this.state.amount.replace(",", "")) + ((value.value.toString() / 100) * parseFloat(this.state.amount.toString().replace(",", "")))

                    });
            }
        }
        else {
            if (this.state.amount == 0) {
                this.setState(
                    {
                        tax_amount: 0,
                        total_amount: 0

                    });
            } else {
                this.setState(
                    {
                        tax_amount: 0,
                        total_amount: parseFloat(this.state.amount.replace(",", "")) + 0

                    });
            }
        }
    };


    async componentDidMount() {
        $('#message').countSms('#sms-counter');

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + `vendor?branch_id=${BRANCH_ID}&&page_size=10000&page_number=1&client_id=` + USER_ID
        let url2 = baseURL + `customer?branch_id=${BRANCH_ID}&page_number=1&page_size=1000&sort_order=false&client_id=` + USER_ID
        let url3 = baseURL + `deposit_to`

        let url4 = baseURL + `bank?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID

        console.log("query", url);

        axios.all([
            axios.get(url, axiosConfig),
            axios.get(url2, axiosConfig),
            axios.get(url3, axiosConfig),
            axios.get(url4, axiosConfig)

        ]).then(axios.spread((vendorResponse, customerResponse, paymentResponse, bankResponse) => {

            this.setState({
                customerResponse: customerResponse.data.data,
                vendorResponse: vendorResponse.data.data,
                paymentResponse: paymentResponse.data.data,
                bankResponse: bankResponse.data.data

            }, function () {
                var data = [];
                if (this.state.paymentResponse.length > 0) {

                    for (let i = 0; i < this.state.paymentResponse.length; i++) {
                        let label = { label: this.state.paymentResponse[i].name };
                        let value = { value: this.state.paymentResponse[i].name };
                        data.push(Object.assign(label, value));
                        this.setState({
                            data: data
                        })
                    }

                } else {
                    this.setState({
                        data: data
                    })
                }

                localStorage.setItem("paidthrough", JSON.stringify(data))


                var data_bank = [];

                if (this.state.bankResponse.length > 0) {
                    for (let j = 0; j < this.state.bankResponse.length; j++) {

                        let label = { label: this.state.bankResponse[j].account_name + ` (${this.state.bankResponse[j].bank_name})-` + this.state.bankResponse[j].currency };
                        let value = { value: this.state.bankResponse[j].account_name + " " + this.state.bankResponse[j].bank_name };

                        data_bank.push(Object.assign(label, value));
                        this.setState({
                            data_bank: data_bank
                        })
                    }
                } else {
                    this.setState({
                        data_bank: data_bank
                    })
                }
                localStorage.setItem("bankaccounts", JSON.stringify(data_bank))



            })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }
    handleNotes = event => {
        this.setState({ notes: event.target.value }, function () { });
        //alert(event.target.value)
    };



    Customer() {
        return (
            this.state.customerResponse &&
            (this.state.customerResponse.length > 0 || this.state.customerResponse.length === 0) &&
            this.state.customerResponse.map((countyItem, i) => ({
                label: countyItem.customer_display_name + " (" + countyItem.email + ")",
                customer_email: countyItem.email,
                customer_nameinvoice: countyItem.customer_display_name,
                value: countyItem.id,
            }))
        );
    }

    Vendor() {
        return (
            this.state.vendorResponse &&
            (this.state.vendorResponse.length > 0 || this.state.vendorResponse.length === 0) &&
            this.state.vendorResponse.map((countyItem, i) => ({
                label: countyItem.vendor_display_name + " (" + countyItem.email + ")",
                vendor_email: countyItem.email,
                vendor_displayname: countyItem.vendor_display_name,
                value: countyItem.id,
            }))
        );
    }


    onSelectChangesShort = value => {
        if (value != null) {

            this.setState(
                {
                    customer_id: value.value.toString(),
                    customer_name: value.label.toString(),
                    customer_nameinvoice2: value.customer_nameinvoice.toString(),
                    customer_email: value.customer_email != null ? value.customer_email.toString() : '',
                    product2: true
                });
        } else {
            this.setState({
                product2: false
            })
        }
    };
    onSelectChangesVendor = value => {
        if (value != null) {

            this.setState(
                {
                    vendor_id: value.value.toString(),
                    vendor_name: value.label.toString(),
                    vendor_display_name: value.vendor_displayname.toString(),
                    vendor_email: value.vendor_email.toString()
                });
        }
    };
    onSubmit(e) {
        e.preventDefault();
        this.setState({
            isLoading: true
        })

        let amount2 = parseFloat(this.state.amount.toString().replace(",", ""))

        let formData = {
            "client_id": USER_ID,
            "date": this.state.date2,
            "expense_account": this.state.terms,
            "amount": this.state.total_amount,
            "tax_amount": ((this.state.tax_amount / 100) * amount2).toFixed(2),
            "paid_through": this.state.paid_through,
            "customer_id": this.state.customer_id,
            "vendor_id": this.state.vendor_id,
            "notes": this.state.notes,
            "branch_id": BRANCH_ID,
            'recurring': 1,
            "billable": this.state.billing,
            "product": this.state.product_name,
            'start_time': moment(this.state.date2).format('YYYY-MM-DD HH:mm:ss'),
            'end_time': moment(this.state.due_date).format('YYYY-MM-DD HH:mm:ss'),
            'frequecy': (new Date(this.state.frequency).getTime()) / 1000,
            'frequency_type': this.state.frequency_type

        }
        axios.post(baseURL + `expense`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then((response) => {
            console.log('bayoo', response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                window.scrollTo(0, 0)
                window.setTimeout(() => {
                    this.setState({ isShowError: false });
                    this._onButtonClick();
                }, 2000);
            } else {
                window.scrollTo(0, 0)

                this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
            }
        })
            .catch((error) => {
                window.scrollTo(0, 0)

                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.message, showError: true, isShowError: false, isLoading: false });
            })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            moreinfo: false
        });
    }

    _onMoreInfo() {
        this.setState({
            showComponent: false,
            hideComponent: true,
            moreinfo: true
        });
    }

    changeStatus() {
        this.setState({
            isShowError: false
        });
    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        }, function () {
            if (this.state.isChecked === true) {
                this.setState({
                    billing: 1
                })
            } else {
                this.setState({
                    billing: 0
                })
            }
        });
    }


    handleChangeFrequency = value => {
        if (value != null) {
            // var newDate = new Date(this.state.invoice_date).add(Date.DAY, +1).format('Y-m-d');
            var daily = moment(new Date(new Date(this.state.date2).setDate(new Date(this.state.date2).getDate() + 1))).format('YYYY-MM-DD HH:mm:ss');
            var weekly = moment(new Date(new Date(this.state.date2).setDate(new Date(this.state.date2).getDate() + 7))).format('YYYY-MM-DD HH:mm:ss');
            var monthly = moment(new Date(new Date(this.state.date2).setDate(new Date(this.state.date2).getDate() + 31))).format('YYYY-MM-DD HH:mm:ss');
            var yearly = moment(new Date(new Date(this.state.date2).setDate(new Date(this.state.date2).getDate() + 366))).format('YYYY-MM-DD HH:mm:ss');
            var two_weeks = moment(new Date(new Date(this.state.date2).setDate(new Date(this.state.date2).getDate() + 14))).format('YYYY-MM-DD HH:mm:ss');
            var two_months = moment(new Date(new Date(this.state.date2).setDate(new Date(this.state.date2).getDate() + 61))).format('YYYY-MM-DD HH:mm:ss');
            var three_months = moment(new Date(new Date(this.state.date2).setDate(new Date(this.state.date2).getDate() + 91))).format('YYYY-MM-DD HH:mm:ss');
            var two_years = moment(new Date(new Date(this.state.date2).setDate(new Date(this.state.date2).getDate() + 730))).format('YYYY-MM-DD HH:mm:ss');
            var six_months = moment(new Date(new Date(this.state.date2).setDate(new Date(this.state.date2).getDate() + 180))).format('YYYY-MM-DD HH:mm:ss');
            // alert(newDate)
            if (value.value.toString() === "Daily") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: daily
                    });
            }
            if (value.value.toString() === "Weekly") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: weekly
                    });
            }
            if (value.value.toString() === "After 2 Weeks") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: two_weeks
                    });
            }
            if (value.value.toString() === "Monthly") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: monthly
                    });
            }
            if (value.value.toString() === "After 2 Months") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: two_months
                    });
            }
            if (value.value.toString() === "After 3 Months") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: three_months
                    });
            }
            if (value.value.toString() === "After 6 Months") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: six_months
                    });
            }
            if (value.value.toString() === "Yearly") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: yearly
                    });
            }
            if (value.value.toString() === "After 2 Years") {
                this.setState(
                    {
                        frequency_type: value.value.toString(),
                        frequency: two_years
                    });
            }
        }

        else {
            this.setState(
                {
                    frequency_type: "",
                });
        }
    };

    handleChangeamount = (e) => {
        this.setState({
            amount: e.target.value,
            total_amount: (parseFloat(e.target.value.replace(",", "")) + ((this.state.tax_amount / 100) * parseFloat(e.target.value.replace(",", "")))).toFixed(2)
        })


    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent, product2 } = this.state;


        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            justifyContent: 'space-between',
        };
        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };

        const formatGroupLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );


        return (
            <div style={{ marginTop: "-20px" }} >
                {showComponent && (
                    <div>
                        <Navigate to="/recuring-expense" />
                        <recurring_viewexpenseview />

                    </div>
                )}
                {/* {moreinfo && (
                    <div>
                        {this.state.moreinfo ?
                            <CreateAddress /> : null
                        }
                    </div>
                )} */}

                {!hideComponent && (
                    <div>
                        <Card>


                            <Modal
                                isOpen={this.state.isOpen}
                                onRequestClose={e => {
                                    this.closeModalUpload(e);
                                }}
                                contentLabel="My dialog"
                                className="mymodal"
                                onAfterOpen={() => {
                                    document.body.style.overflow = 'hidden';
                                }}
                                onAfterClose={() => {
                                    document.body.removeAttribute('style');
                                }}
                                overlayClassName="myoverlay"
                                closeTimeoutMS={500}
                            >
                                <MDBCloseIcon onClick={this.closeModalUpload} />
                                <h4><b>Upload Files </b></h4><br />

                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>


                                </div> : null}<br></br>

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>
                                    </div>
                                ) : null}
                                <div className="wrapper text-center">
                                    <div className="section1">
                                        <div className="contain">
                                            <input
                                                className="csv-input"
                                                type="file"
                                                required
                                                ref={input => {
                                                    this.filesInput = input;
                                                }}
                                                name="file"
                                                customHeight
                                                placeholder={null}
                                                onChange={this.handleProfile}
                                            />
                                            <br />
                                            <Button color="primary" className="btn-paypal btn-lg " onClick={this.uploadProfile} >
                                                {this.state.isLoading1 ? "Uploading..." : "Upload now!"}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;

                                            <br />
                                        </div>
                                    </div>
                                </div>

                            </Modal>


                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4><b>Create Recurring Expense</b></h4><br />
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/recuring-expense">
                                            <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                Expenses
                                            </Button>
                                        </Link>

                                    </div>
                                </div>


                                {this.state.showError ? <div >

                                    <Alert color="danger" className="alert--colored" icon>
                                        <p><span className="bold-text">{this.state.statusMessage}</span>
                                        </p>
                                    </Alert>


                                </div> : null}<br></br>

                                {this.state.isShowError ? (
                                    <div>

                                        <Alert color="success" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div>

                                ) : null}<br></br>
                                <form className="form row" onSubmit={this.onSubmit}>



                                    <div className="col-md-6">
                                        <br />
                                        <Form.Group className="Focus-line">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Frequency</Form.Label>
                                                </div>
                                                <div className="col-md-10">

                                                    <Select
                                                        isClearable
                                                        options={this.state.frequency_load}
                                                        // onInputChange={this.loadOptions}
                                                        placeholder="Select frequency"
                                                        autosize={true}
                                                        onChange={this.handleChangeFrequency}
                                                        isLoading={this.state.isLoading}
                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        menuPortalTarget={document.body}
                                                        name="namffe"
                                                    /><br />

                                                </div>
                                            </div>

                                        </Form.Group>
                                    </div>

                                    <div className="col-md-6">
                                        <br />
                                        <Form.Group className="Focus-line">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Start Date</Form.Label>
                                                </div>
                                                <div className="col-md-10 customDatePickerWidth">
                                                    <DatePicker
                                                        selected={this.state.date2}
                                                        label="DatePicker Label"
                                                        dateFormat="dd MMM yyyy"
                                                        required
                                                        // minDate={moment().toDate()}

                                                        value={this.state.date2}
                                                        className="form-control"
                                                        id="input"
                                                        onChange={value =>
                                                            this.setState({ date2: value })}
                                                    />

                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <br />
                                        <Form.Group className="Focus-line">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>End date</Form.Label>
                                                </div>
                                                <div className="col-md-10 customDatePickerWidth">
                                                    <DatePicker
                                                        selected={this.state.due_date}
                                                        label="DatePicker Label"
                                                        dateFormat="dd MMM yyyy"

                                                        className="form-control"
                                                        id="input"
                                                        onChange={value =>
                                                            this.setState({ due_date: value })}
                                                    />

                                                </div>
                                            </div>
                                        </Form.Group>

                                    </div>

                                    <div className="col-md-6">
                                        <br />
                                        <Form.Group className="Focus-line" >
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Expense Account</Form.Label>
                                                </div>
                                                <div className="col-md-10">
                                                    <CreatableSelect
                                                        isClearable
                                                        // defaultValue={costofgoodOptions[1]}
                                                        // onInputChange={this.loadOptions}
                                                        placeholder="Select Expense Account"
                                                        autosize={true}
                                                        required
                                                        options={groupedOptions}
                                                        formatGroupLabel={formatGroupLabel}
                                                        // value={this.state.terms}
                                                        onChange={this.handleChangeTerms}
                                                        isLoading={this.state.isLoading}
                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        menuPortalTarget={document.body}
                                                        name="expense_account"
                                                    /><br />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Amount </label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control"
                                                        name="amount" required placeholder='Amount'
                                                        value={this.state.amount} onChange={this.handleChangeamount} />
                                                    <br />
                                                    <h5>{this.state.total_amount}</h5>
                                                </div>

                                                {/* <div className="col-md-5">
                                                    <Form.Group className="Focus-line" >

                                                        <CreatableSelect
                                                            isClearable
                                                            options={this.state.tax}
                                                            placeholder="Select tax"
                                                            autosize={true}
                                                            onChange={this.handleChangeTax}
                                                            isLoading={this.state.isLoading}
                                                            menuPortalTarget={document.body}
                                                            name="namffe"
                                                        />
                                                    </Form.Group>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <Form.Group className="Focus-line" >
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Paid Through</Form.Label>
                                                </div>
                                                <div className="col-md-10">
                                                    <CreatableSelect
                                                        isClearable
                                                        // onInputChange={this.loadOptions}
                                                        placeholder="Select Account"

                                                        // onInputChange={this.loadOptions}
                                                        autosize={true}
                                                        required
                                                        options={groupedOptions_two}
                                                        formatGroupLabel={formatGroupLabel}

                                                        // value={this.state.terms}

                                                        onChange={this.handleChangePaid}
                                                        isLoading={this.state.isLoading}
                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        menuPortalTarget={document.body}
                                                        required
                                                        name="paid_through"
                                                    /><br />
                                                </div>
                                            </div>

                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Supplier</label>
                                                </div>
                                                <div className="col-md-10">

                                                    <Select
                                                        isClearable
                                                        options={this.Vendor()}
                                                        onInputChange={this.loadOptions}
                                                        placeholder="Search Supplier"
                                                        autosize={true}
                                                        onChange={this.onSelectChangesVendor}
                                                        isLoading={this.state.isLoading}
                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        menuPortalTarget={document.body}
                                                        name="namffe"
                                                    /><br />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">

                                                    <label className="form-label">
                                                        Notes
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <textarea
                                                        name="invoice_message"

                                                        style={{
                                                            paddingTop: '20px',
                                                            borderRadius: '5px',
                                                            borderColor: 'hsl(0,0%,80%)',
                                                        }}
                                                        onChange={this.handleNotes}
                                                        className="col-md-12"
                                                        placeholder="Notes"
                                                        id="message"
                                                        cols="col-md-1"
                                                        rows="1"
                                                    />
                                                </div>

                                                <div className="col-md-2">
                                                    <ul id="sms-counter">
                                                        <li>Length: <span class="length" /></li>
                                                        {/* <li>SMS: <span class="messages"></span></li> */}
                                                        <li>
                                                            Message:
                                                            {' '}
                                                            <span class="remaining" />

                                                            <span class="per_message" />
                                                        </li>
                                                        {/* <li>Remaining: </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Customer Name</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <Link className='Links' to='/customer-create'>Create New Customer ?</Link>

                                                    <Select
                                                        isClearable
                                                        options={this.Customer()}
                                                        onInputChange={this.loadOptions}
                                                        placeholder="Search Customer"
                                                        autosize={true}
                                                        onChange={this.onSelectChangesShort}
                                                        isLoading={this.state.isLoading}
                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        menuPortalTarget={document.body}
                                                        name="namffe"
                                                    /><br />
                                                </div>

                                                <div className="col-md-2">
                                                    <label>
                                                        <input type="checkbox"
                                                            checked={this.state.isChecked}
                                                            onChange={this.toggleChange}
                                                        />&nbsp;
                                                        Billable

                                                    </label>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    {product2 && (
                                        <div>
                                            <div className="col-md-6">
                                                <Form.Group className="Focus-line" >
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <Form.Label>Product</Form.Label>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <CreatableSelect
                                                                isClearable
                                                                options={this.state.product}
                                                                // onInputChange={this.loadOptions}
                                                                placeholder="Select Product"
                                                                autosize={true}
                                                                // value={this.state.terms}

                                                                onChange={this.handleChangeProduct}
                                                                isLoading={this.state.isLoading}
                                                                noOptionsMessage={() => 'No Result was Found'}
                                                                // loadingMessage={() => 'searching...'}   //minor type-O here
                                                                menuPortalTarget={document.body}
                                                                name="namffe"
                                                            /><br />
                                                        </div>
                                                    </div>

                                                </Form.Group>
                                            </div>
                                        </div>
                                    )
                                    }
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="form-label">Upload Documents &nbsp;&nbsp;&nbsp;<a href="#" onClick={this.toggleModalCreate} >Click here</a></label>
                                                </div>
                                                {/* <div className="col-md-8">

                                                     
                                                    <br />
                                                </div> */}


                                            </div>


                                        </div>
                                    </div>

                                    <br></br>
                                    <br />

                                    <div className="col-md-12"><br />
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Link to="/recuring-expense">
                                                        <Button outline color="primary" className="btn-sm" onClick={this._onButtonClick}>
                                                            Cancel
                                                        </Button>
                                                    </Link>

                                                </div>
                                                <div className="col-md-8">
                                                    <Button type="submit" color="primary" outline className="btn-paypal btn-sm pull-right" >
                                                        {this.state.isLoading ? "Please Wait..." : "Save"}

                                                    </Button> &nbsp;&nbsp;&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </form>
                            </CardBody>
                        </Card>
                    </div>
                )
                }
            </div>
        )
    }
}
export default withTranslation('common')(RecurringExpenseCreate);
