import React, { Component } from 'react';
import styles from './Invoice.module.scss';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Form } from 'react-bootstrap';
import LineItems from './LineItems.jsx';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { uuid } from 'uuidv4';
import { BRANCH_ID, BUSINESS_TYPE, CONFIG, CURRENCY, errorToast, INVOICE_APPROVAL, PROFILE_URL, successToast, ToastTable, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { baseURL, baseURL_2 } from '../../_helpers';
import { Link, Navigate } from 'react-router-dom';
import $ from 'jquery';
import CreatableSelect from 'react-select/creatable';
import * as moment from 'moment';
// import { RadioGroup, ReversedRadioButton, RadioButton } from 'react-radio-buttons'
import { groupedOptions_banks } from './paidthrough_recevied';

window.$ = window.jQuery = require('jquery');
require('../../_assets/jss/sms_counter.js');
var invNum = require('invoice-number');

window.localStorage.setItem("num", "ABC000001")



class Invoice extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this._onButtonClick = this._onButtonClick.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleAddLineSubmit = this.handleAddLineSubmit.bind(this);
  }

  locale = 'en-US';

  state = {
    taxRate: 0.00,
    invoice_no: invNum.InvoiceNumber.next(localStorage.getItem("num")),
    invoice_date: new Date(),
    due_date: new Date(),
    tax_exclusive: 1,
    discount_amount: 0,
    delivery_amount: 0,
    deposit_to: null,
    sales_person_id: "0",
    invoice_no2: 0,
    addnew: false,

    iscashsales: false,
    // tax_inclusive: '1',
    showtax: true,
    editedInvoice: {},
    show_cash_sales: false,
    returnHome: false,
    agnaist_ksh: 1,
    terms: 'Due on Receipt',
    terms3: [
      {
        label: 'Due on Receipt',
        value: 'Due on Receipt',
      },
      {
        label: 'Due in 15 days',
        value: 'Due in 15 days',
      },
      {
        label: 'Due in 30 days',
        value: 'Due in 30 days',
      },
      {
        label: 'Due in 6 months',
        value: 'Due in 6 months',
      }

    ],
    lineItems: [
      {
        item_id: '', // react-beautiful-dnd unique key
        name: '',
        description: '',
        reference: '',
        quantity: 1,
        discount_percentage: 0,
        tax_percentage: 0,
        price: 0.00,
      },
    ],
  };
  async componentDidMount() {
    $('#message').countSms('#sms-counter');
    $('#messages').countSms('#sms-counters');

    // alert(INVOICE_APPROVAL)


    // alert(currency())

    this.setState({
      isLoading: true,
      invoice_currency: CURRENCY
    })

    if (PROFILE_URL == "null") {
      localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
    } else {
      localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
      var img = new Image;
      img.crossOrigin = "*"
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
        canvas.toDataURL('image/jpeg', 1.0)
        var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
        localStorage.setItem("image_urls", fullQuality)
      };
      img.src = "${PROFILE_URL}?canvas=true";
    }

    localStorage.setItem("num", invNum.InvoiceNumber.next(localStorage.getItem("num")))
    const [customerResponse, salespersonResponse, invoiceResponse, currency] = await Promise.all([
      axios.get(baseURL + `customer?branch_id=${BRANCH_ID}&&filter_value=&page_number=1&page_size=1000&sort_order=false&client_id=` + USER_ID, {
        headers: { Authorization: TOKEN },
      }),
      axios.get(baseURL + `salesperson?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, {
        headers: { Authorization: TOKEN },
      }),
      axios.get(baseURL + `newinvoice?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, {
        headers: { Authorization: TOKEN },
      }),
      axios.get(baseURL + `currency`, {
        headers: { Authorization: TOKEN },
      }),
    ]);



    var data = [];
    for (let i = 0; i < currency.data.data.length; i++) {
      let mlabel = { label: currency.data.data[i].currency };
      let mvalue = { value: currency.data.data[i].agnaist_ksh };
      data.push(Object.assign(mlabel, mvalue, currency.data.data[i]));
      this.setState({
        data: data,
        isLoading: false,
      })
    }


    this.getInvoiceData();
    // alert(baseURL + `newinvoice?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID)





    this.setState({
      isLoading: false,
      returnHome: false,
      invoice_no2: invoiceResponse.data.totals,
      salespersonResponse: salespersonResponse.data.data,
      invoice_no: invNum.InvoiceNumber.next(this.state.invoice_no),

      customerResponse: customerResponse.data.data,
    });



    console.log('customer', this.state.customerResponse);
  }
  handleLineItemChange = elementIndex => event => {
    console.log(this.state.lineItems);


    let lineItems = this.state.lineItems.map((item, i) => {
      if (elementIndex !== i) return item;
      return { ...item, [event.target.name]: event.target.value };
    });
    this.setState({ lineItems });
    // alert(JSON.stringify(lineItems));
  };
  Customer() {
    return (
      this.state.customerResponse &&
      (this.state.customerResponse.length > 0 || this.state.customerResponse.length === 0) &&
      this.state.customerResponse.map((countyItem, i) => ({
        label: countyItem.customer_display_name,
        customer_email: countyItem.email,
        customer_nameinvoice: countyItem.customer_display_name,
        value: countyItem.id,
      }))
    );
  }


  // summar on invoices
  getInvoiceData = () => {
    this.setState({
      isLoading: true,
    })
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": TOKEN
      }
    };
    let url = baseURL + `notes?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID
    axios.all([
      axios.get(url, axiosConfig),
    ]).then(axios.spread((itemResponse) => {
      this.setState({
        notes: itemResponse.data.data,
        isLoading: false,
      }, function () {
        var data = [];
        if (this.state.notes.length > 0) {
          this.setState({ invoice_message: this.state.notes[0].invoice_notes });
        } else {
          this.setState({
            invoice_message: "",
          })
        }
      })
    })).catch(error => {
      console.log('bayoo', error.response)
    })
  }

  handleAddLineItem = event => {

    let length = this.state.lineItems.length - 1;
    this.setState({
      addnew: true
    })

    if (BUSINESS_TYPE === 'business') {
      if (length > -1) {
        if (this.state.lineItems[length].name === '') {
          alert("Name of the item cannot be empty")
          this.setState({
            addnew: false,
            // use optimistic uuid for drag drop; in a production app this could be a database id
            lineItems: this.state.lineItems.concat(),
          });
        } else {
          this.setState({
            addnew: true,
          })
          let axiosConfig = {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
              "Authorization": TOKEN
            }
          };

          var encodeURISafe = require('encodeuri-safe');
          // alert()

          // alert(this.state.lineItems[length].id)
          let url = `${baseURL}item?branch_id=${BRANCH_ID}&&client_id=${USER_ID}&filter_value=${this.state.lineItems[length].name}`


          // alert(url)
          // let url = baseURL + `item?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + "&&filter_value=` + this.state.lineItems[length].name
          axios.all([
            axios.get(url, axiosConfig),
          ]).then(axios.spread((itemResponse) => {


            this.setState({
              item: itemResponse.data.data.filter(item => item.status === 1),

              isLoading: false,

            },
              function () {
                var data = [];
                //filter json object

                // if (this.state.item.length> 0) {
                let filter_items = this.state.lineItems.filter(item => item.name === this.state.item[0].item_name)
                let accumulative = filter_items.reduce(
                  (prev, cur) =>
                    (Number(prev) + Number(cur.quantity)),
                  0)
                if ((this.state.item[0].quantity - accumulative) < 0) {
                  this.setState({
                    addnew: false,
                  })
                  this.handleRemove(length)
                  this.onSubmit(event)

                  // alert("You current stock for item ${this.state.item[0].item_name} is not enough. Kindly restock")
                } else {
                  this.setState({
                    addnew: false,
                    // use optimistic uuid for drag drop; in a production app this could be a database id
                    lineItems: this.state.lineItems.concat([
                      {
                        item_id: '', // react-beautiful-dnd unique key
                        name: '',
                        description: '',
                        reference: '',
                        quantity: 1,
                        discount_percentage: 0,
                        tax_percentage: 0,
                        price: 0.00,
                      },
                    ]),
                  })
                }
                // }
                // else{
                //   errorToast("Error check your items if exist in inventory")
                // }

              })

          })).catch(error => {
            console.log('bayoo', error.response)
          })
        }
      } else {
        this.setState({
          addnew: false,
          // use optimistic uuid for drag drop; in a production app this could be a database id
          lineItems: this.state.lineItems.concat([
            {
              id: uuid(), // react-beautiful-dnd unique key
              name: '',
              description: '',
              reference: '',
              quantity: 1,
              discount_percentage: 0,
              tax_percentage: 0,
              price: 0.00,
            },
          ]),
        })

      }
    } else {
      this.setState({
        addnew: false,
        // use optimistic uuid for drag drop; in a production app this could be a database id
        lineItems: this.state.lineItems.concat([
          {
            item_id: '', // react-beautiful-dnd unique key
            name: '',
            description: '',
            reference: '',
            quantity: 1,
            discount_percentage: 0,
            tax_percentage: 0,
            price: 0.00,
          },
        ]),
      })

    }
  };


  handleAddLineSubmit = event => {
    // event.preventDefault();

    // alert(event)

    let length = this.state.lineItems.length - 1;
    this.setState({
      addnew: true,
      isLoading: true
    })
    if (BUSINESS_TYPE === 'business') {
      if (length > -1) {
        if (this.state.lineItems[length].name === '') {
          alert("Name of the item cannot be empty")
          this.setState({
            addnew: false,
            isLoading: false,

            // use optimistic uuid for drag drop; in a production app this could be a database id
            lineItems: this.state.lineItems.concat(),
          });
        } else {
          let axiosConfig = {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
              "Authorization": TOKEN
            }
          };
          var encodeURISafe = require('encodeuri-safe');
          // alert()
          let url = `${baseURL}item?branch_id=${BRANCH_ID}&&client_id=${USER_ID}&filter_value=${this.state.lineItems[length].name}`
          // let url = baseURL + `item?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + "&&filter_value=` + this.state.lineItems[length].name
          axios.all([
            axios.get(url, axiosConfig),
          ]).then(axios.spread((itemResponse) => {
            this.setState({
              item: itemResponse.data.data.filter(item => item.status === 1)
            },
              function () {
                var data = [];
                let filter_items = this.state.lineItems.filter(item => item.name === this.state.lineItems[length].name)
                let accumulative = filter_items.reduce(
                  (prev, cur) =>
                    (Number(prev) + Number(cur.quantity)),
                  0)

                if (this.state.item.length > 0) {
                  if ((this.state.item[0].quantity - accumulative) < 0) {
                    this.setState({
                      addnew: false,
                      isLoading: false,

                    })
                    this.handleRemove(length)
                    this.onSubmit(event)

                    // alert("You current stock for item ${this.state.item[0].item_name} is not enough. Kindly restock")
                  } else {
                    this.setState({
                      addnew: false,

                    })
                    this.onSubmit(event)
                  }
                } else {
                  this.setState({
                    addnew: false,
                    isLoading: false
                  })
                  this.onSubmit(event)

                }
              })
          })).catch(error => {
            console.log('bayoo', error.response)
          })
        }
      } else {
        this.setState({
          addnew: false,
          // use optimistic uuid for drag drop; in a production app this could be a database id
          lineItems: this.state.lineItems.concat([
            {
              item_id: '', // react-beautiful-dnd unique key
              name: '',
              description: '',
              reference: '',
              quantity: 0,
              discount_percentage: 0,
              tax_percentage: 0,
              price: 0.00,

            },
          ]),
        })

      }
    } else {
      this.onSubmit(event)

    }
  };



  // handlesubmit = event => {


  // }

  handleRemove(bayo) {
    this.setState({
      lineItems: this.state.lineItems.filter((item, i) => {
        return bayo !== i;
      }),
    });
  };

  handleRemoveLineItem = elementIndex => event => {


    this.setState({
      lineItems: this.state.lineItems.filter((item, i) => {
        return elementIndex !== i;
      }),
    });
  };

  handleReorderLineItems = newLineItems => {
    this.setState({
      lineItems: newLineItems,
    });
  };
  handleChangeInvoiceMessage = event => {
    this.setState({ invoice_message: event.target.value }, function () { });
    //alert(event.target.value)
  };

  handleChangeStatementMessage = event => {
    this.setState({ statement_message: event.target.value }, function () { });
    //alert(event.target.value)
  };

  handleFocusSelect = event => {
    event.target.select();
  };

  handlePayButtonClick = () => {
    alert('Not implemented');
  };


  formatCurrency1 = amount => {
    return new Intl.NumberFormat(this.locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };
  calcTaxAmount = c => {
    return c * (localStorage.getItem('valuestax') / 100);
  };

  calcLineItemsTotal = () => {

    if (this.state.showtax == true) {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + cur.quantity * cur.price,
        0
      );
    } else {

      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.quantity * cur.price) / (1 + (cur.tax_percentage / 100))),
        0
      );
    }


  };

  calcLineItemDiscounts = () => {


    return this.state.discount_amount;
  };



  calcLineItemDelivery = () => {
    return parseFloat(this.state.delivery_amount);
  };

  calcTaxTotal = () => {
    if (this.state.showtax == true) {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.tax_percentage / 100) * (cur.quantity * cur.price)),
        0
      );
    } else {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.quantity * cur.price) - ((cur.quantity * cur.price) / (1 + (cur.tax_percentage / 100)))),
        0
      );
    }
  };

  calcGrandTotal = () => {

    return (
      (this.calcLineItemsTotal() +
        this.calcLineItemDelivery() +
        this.calcTaxTotal()) -
      this.calcLineItemDiscounts()
    );
  };

  onSelectChangesShort = value => {
    if (value != null) {
      this.setState(
        {
          ...this.state.editedInvoice,
          customer_id: value.value.toString(),
          customer_name: value.label.toString(),
          discount_amount: 0,
          delivery_amount: 0,
          customer_nameinvoice2: value.customer_nameinvoice.toString(),
          customer_email: value.customer_email != null ? value.customer_email.toString() : '',
        });
    }
  };

  handleChange = e => {
    this.setState({
      ...this.state.editedInvoice,
      [e.target.name]: e.target.value,
    });

    console.log('updates', this.state.editedInvoice);
  };

  handleChangeDisco = e => {
    this.setState({
      discount_amount: e.target.value,
    });

    console.log('updates', this.state.editedInvoice);
  };

  handleChangeDelivery = e => {
    this.setState({
      delivery_amount: e.target.value,
    });
  };


  handleChangeType = value => {

    if (value != null) {
      this.setState(
        {
          terms: value.value.toString()
        });

      if (value.value.toString() === "Due in 15 days") {
        this.setState({
          due_date: moment(new Date(this.state.invoice_date)).add(15, 'days').toDate()
        })
      }
      else if (value.value.toString() === "Due in 30 days") {
        this.setState({
          due_date: moment(new Date(this.state.invoice_date)).add(30, 'days').toDate()
        })
      }

      else if (value.value.toString() === "Due in 6 months") {
        this.setState({
          due_date: moment(new Date(this.state.invoice_date)).add(6, 'months').toDate()
        })
      }
      else {
        this.setState({
          due_date: new Date(this.state.invoice_date)
        })
      }
    }
    else {
      this.setState(
        {
          terms: "",
          due_date: new Date(this.state.invoice_date)

        });
    }
  };

  onSubmit(e) {
    // e.preventDefault();
    // eslint-disable-next-line
    // if ((this.formatCurrency1(this.calcGrandTotal())).replaceAll(",", "") > 0) {
    this.state.editedInvoice = {


      invoice_date: this.state.invoice_date,
      terms: this.state.terms,
      due_date: this.state.due_date,
      message_invoice: this.state.invoice_message,
      statement_invoice: this.state.statement_message,
      customer_name: this.state.customer_nameinvoice2,
      customer_email: this.state.customer_email,
      amount1: this.formatCurrency1(this.calcGrandTotal()),
      amount: this.formatCurrency(this.calcGrandTotal()),
      due_amount: this.formatCurrency(this.calcGrandTotal()),
      tax_amount: this.formatCurrency(this.calcTaxTotal()),
      discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),

      delivery_amount: this.calcLineItemDelivery(),

      lineItems: this.state.lineItems,
      tax_exclusive: this.state.tax_exclusive,
      currency: this.state.invoice_currency,
      reference: this.state.reference,
      tax: this.formatCurrency(this.calcTaxTotal()),
      subtoal: this.formatCurrency(this.calcLineItemsTotal()),
      total: this.formatCurrency(this.calcGrandTotal()),
      sales_person_id: this.state.sales_person_id,

    };


    console.log("invoice data ", this.state.invoice_date)

    let tempApproved = e;
    if (e == 3) {
      tempApproved = 1;
    }

    let formData = {
      invoice_no: this.state.invoice_no2 - 1,
      invoice_date: this.state.invoice_date,
      terms: this.state.terms,
      due_date: this.state.due_date,
      reference: this.state.reference,
      tax_exclusive: this.state.tax_exclusive,
      approved: tempApproved,
      currency_type: this.state.invoice_currency,
      agnaist_ksh: this.state.agnaist_ksh,
      message_invoice: this.state.invoice_message,
      statement_invoice: this.state.statement_message,
      sub_total: this.formatCurrency(this.calcLineItemsTotal()),
      amount: this.formatCurrency(this.calcGrandTotal()),
      due_amount: this.formatCurrency(this.calcGrandTotal()),
      tax_amount: this.formatCurrency(this.calcTaxTotal()),
      discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
      delivery_amount: this.calcLineItemDelivery(),

      created_by: USER_ID,
      estimate: '0',
      sales_order_no: '0',
      recurring: "0",
      sales_person_id: this.state.sales_person_id,
      customer_id: this.state.customer_id,
    };

    localStorage.setItem("approved_draft", e)

    // console.log("DATA", JSON.stringify(formData))
    this.setState({ isLoading: true });


    if (BUSINESS_TYPE === 'business') {



      // alert("here")
      if (this.state.show_cash_sales) {

        // 

        axios
          .post(baseURL + `invoice`, formData, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: TOKEN,
            },
          })
          .then(response => {
            if (response.data.status) {
              let data3 = [];
              // alert()
              for (let i = 0; i < this.state.lineItems.length; i++) {
                let innvoiceNo = { invoice_no: this.state.invoice_no2 };
                let client_id = { client_id: USER_ID };
                data3.push(Object.assign(innvoiceNo, client_id, this.state.lineItems[i]));
                this.setState({
                  data3: data3,
                  deposit_to: 'Petty Cash',
                  customer_id: require("randomstring").generate({
                    length: 8,
                    charset: 'alphanumeric',
                    capitalization: 'lowercase'
                  })
                });
              }

              localStorage.setItem("INVOICE_CREATEPEAK", JSON.stringify(this.state.lineItems))
              localStorage.setItem("INVOICE_SENDEMAIL", JSON.stringify(this.state.editedInvoice))
              axios
                .post(baseURL_2 + 'insight.php', data3)
                .then(response => {
                  // localStorage.setItem("INVOICE_CREATEPEAK", null)
                  // window.scrollTo(0, 0);


                  this.paymentReceived(e)
                  // this.setState({
                  //   isLoading: false,
                  //   loaded: false,
                  // })
                })
                .catch(error => {
                  errorToast("Error");
                  this.setState({
                    isLoading: false,
                    loaded: false,
                  });
                  window.scrollTo(0, 0);
                  // console.error('Error during service worker registration:', error);
                });
            } else {
              errorToast(response.data.message);
              this.setState({
                isLoading: false,
              });
              window.scrollTo(0, 0);
            }
          })
          .catch(error => {
            console.log('bayoo', error.response);
            errorToast(error.response.data.message);
            this.setState({
              isLoading: false,
            });
            window.scrollTo(0, 0);
          });

        // alert("here")
      } else {


        axios
          .post(baseURL + `invoice`, formData, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: TOKEN,
            },
          })
          .then(response => {
            if (response.data.status) {

              if (BUSINESS_TYPE === 'business') {
                successToast(response.data.message)
              }
              this.setState({
                isLoading: false,
              });
              let data3 = [];

              // alert()
              for (let i = 0; i < this.state.lineItems.length; i++) {
                let innvoiceNo = { invoice_no: this.state.invoice_no2 };
                let client_id = { client_id: USER_ID };
                data3.push(Object.assign(innvoiceNo, client_id, this.state.lineItems[i]));
                this.setState({
                  data3: data3
                });
              }

              localStorage.setItem("INVOICE_CREATEPEAK", JSON.stringify(this.state.lineItems))

              localStorage.setItem("INVOICE_SENDEMAIL", JSON.stringify(this.state.editedInvoice))



              // console.log("INSIGHT", data3)
              axios
                .post(baseURL_2 + 'insight.php', data3)
                .then(response => {

                  // localStorage.setItem("INVOICE_CREATEPEAK", null)
                  window.scrollTo(0, 0);

                  if (BUSINESS_TYPE === 'business') {
                    window.setTimeout(() => {
                      this.setState({ isShowError: false, returnHome: true });
                      // this._onButtonClick();
                    }, 1000)
                  } else {
                    this.paymentReceived(e)
                  }



                  console.log("bayo", response)
                  this.setState({
                    isLoading: false,
                    loaded: false,
                  })
                })
                .catch(error => {
                  errorToast("Error");
                  this.setState({
                    isLoading: false,
                    loaded: false,
                  });
                  window.scrollTo(0, 0);
                  // console.error('Error during service worker registration:', error);
                });
            } else {
              errorToast(response.data.message);
              this.setState({
                isLoading: false,
              });
              window.scrollTo(0, 0);
            }
          })
          .catch(error => {
            console.log('bayoo', error.response);
            errorToast(error.response.data.message);
            this.setState({
              isLoading: false,
            });
            window.scrollTo(0, 0);
          });

      }
    }


    else {

      if (this.state.deposit_to !== null) {

        axios
          .post(baseURL + `invoice`, formData, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: TOKEN,
            },
          })
          .then(response => {
            if (response.data.status) {

              if (BUSINESS_TYPE === 'business') {
                successToast(response.data.message)
              }
              // this.setState({
              //   isLoading: false,
              // });
              let data3 = [];

              // alert()
              for (let i = 0; i < this.state.lineItems.length; i++) {
                let innvoiceNo = { invoice_no: this.state.invoice_no2 };
                let client_id = { client_id: USER_ID };
                data3.push(Object.assign(innvoiceNo, client_id, this.state.lineItems[i]));
                this.setState({
                  data3: data3
                });
              }

              localStorage.setItem("INVOICE_CREATEPEAK", JSON.stringify(this.state.lineItems))
              localStorage.setItem("INVOICE_SENDEMAIL", JSON.stringify(this.state.editedInvoice))
              axios
                .post(baseURL_2 + 'insight.php', data3)
                .then(response => {
                  // localStorage.setItem("INVOICE_CREATEPEAK", null)
                  // window.scrollTo(0, 0);

                  if (BUSINESS_TYPE === 'business') {
                    window.setTimeout(() => {
                      this.setState({ isShowError: false, returnHome: true });
                      this._onButtonClick();
                    }, 1000)
                  } else {
                    this.paymentReceived(e)
                  }
                  console.log("bayo", response)
                  // this.setState({
                  //   isLoading: false,
                  //   loaded: false,
                  // })
                })
                .catch(error => {
                  errorToast("Error");
                  this.setState({
                    isLoading: false,
                    loaded: false,
                  });
                  window.scrollTo(0, 0);
                  // console.error('Error during service worker registration:', error);
                });
            } else {
              errorToast(response.data.message);
              this.setState({
                isLoading: false,
              });
              window.scrollTo(0, 0);
            }
          })
          .catch(error => {
            console.log('bayoo', error.response);
            errorToast(error.response.data.message);
            this.setState({
              isLoading: false,
            });
            window.scrollTo(0, 0);
          });

      } else {

        errorToast('Please select a deposit account');
        this.setState({
          isLoading: false,
          loaded: false,
        })
      }
    }
    // } else {
    //   errorToast("Invalid amount! check your inputs")
    //   this.setState({
    //     isLoading: false,
    //     loaded: false,
    //   })
    // }
  }

  PaymentMethod() {
    // alert(JSON.stringify(this.state.paymentResponse))

    return (
      this.state.salespersonResponse &&
      (this.state.salespersonResponse.length > 0 || this.state.salespersonResponse.length === 0) &&
      this.state.salespersonResponse.map((countyItem, i) => ({
        label: countyItem.sales_person,
        value: countyItem.id
      }))
    );
  }

  onChangeOption(value) {

    //  alert(value)
    console.log(value);
    if (value == 1) {
      this.setState({
        showtax: true,
        tax_inclusive: '0',
        tax_exclusive: 1,
      })
    } else {
      this.setState({
        showtax: false,
        tax_inclusive: '1',
        tax_exclusive: 0

      })
    }
    // alert(value)
  }


  _onButtonClick() {
    this.setState({
      returnHome: true
    });
  }
  loadOptions = inputValue => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: TOKEN,
      },
    };
    this.setState({
      isLoading: true,
    });
    let url =
      baseURL +
      `customer?branch_id=${BRANCH_ID}&&client_id=` +
      USER_ID +
      `&&filter_value=${inputValue}`;
    axios.get(url, axiosConfig).then(itemResponse => {
      this.setState(
        {
          item: itemResponse.data.data,
        },
        function () {
          this.setState({
            isLoading: false,
            bayo: this.state.item &&
              (this.state.item.length > 0 || this.state.item.length === 0) &&
              this.state.item.map((countyItem, i) => ({
                label: countyItem.customer_display_name,
                customer_email: countyItem.email,
                value: countyItem.id,
              })),
          });
        }
      );
    });
  };


  loadOptionssales_persone = inputValue => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: TOKEN,
      },
    };
    this.setState({
      isLoading: true,
    });
    let url =
      baseURL +
      `salesperson?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` +
      USER_ID +
      `&&filter_value=${inputValue}`;
    axios.get(url, axiosConfig).then(itemResponse => {
      this.setState(
        {
          sales: itemResponse.data.data,
        },
        function () {
          this.setState({
            isLoading: false,
            bayo: this.state.sales &&
              (this.state.sales.length > 0 || this.state.sales.length === 0) &&
              this.state.sales.map((countyItem, i) => ({
                label: countyItem.sales_persons,
                value: countyItem.id,
              })),
          });
        }
      );
    });
  };

  handleChangePayment = value => {
    if (value != null) {
      this.setState(
        {
          optionselected: value,
          sales_person_id: value.value.toString(),
        });
    }
    else {
      this.setState(
        {
          sales_person_id: "",
        });
    }
  };


  handleCreateCustomer = e => {
    window.location.href = "customer-create"
  }

  handleCreate = value => {
    const newValue = { value: value, label: value };
    this.setState({ optionselected: newValue, payment_mode: value })


    let formData = {
      "created_by": USER_ID,
      "sales_person": value,
    }
    let url = baseURL + `salesperson`
    axios.post(url, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': TOKEN
      }
    }).then((response) => {
    }).catch(error => {
      console.log("ERROR")
    })

  };


  handleChangeCurrency = value => {


    // alert(JSON.stringify(value.currency.toString()))
    // alert(JSON.stringify(<CurrencyConverter from={'USD'} to={'CAD'} value={29} precision={2} date={'2010-12-22'}/>))
    if (value != null) {
      this.setState(
        {
          agnaist_ksh: value.value.toString(),
          invoice_currency: value.currency.toString(),
          currencylabel: value.currency.toString(),
        });
    } else {
      this.setState(
        {
          agnaist_ksh: 1,
          invoice_currency: "",
          currencylabel: "",
        });
    }
  };


  formatCurrency = amount => {
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: "ABS",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount).replaceAll('ABS', this.state.invoice_currency);
  };


  onChangeInvoiceDate = value => {

    if (this.state.terms == "Due on Receipt") {
      this.setState({
        invoice_date: value,
        due_date: value,
      });
    }
    this.setState({
      invoice_date: value,
    });
  }









  paymentReceived = () => {


    let mbalance = this.calcGrandTotal();
    let invoice_no_new;
    if (mbalance > -1) {
      invoice_no_new = this.state.invoice_no2 - 1;
    } else {
      invoice_no_new = "Opening balance";
    }
    let formData = {
      invoice_no: invoice_no_new,
      amount_received: mbalance,
      payment_date: this.state.invoice_date,
      reference: this.state.invoice_message,
      notes: this.state.statement_message,
      amount_inexcess: 0,
      customer_id: this.state.customer_id,
      payment_mode: "cash",
      deposit_to: this.state.deposit_to,
    };

    console.log("payment received", formData)

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": TOKEN
      }
    };
    this.setState({ isSubmitted: true });

    axios
      .post(baseURL + `payment_received`, formData, axiosConfig)
      .then(response => {
        if (response.data.status) {
          axios.all([
            axios.get(baseURL + `payment_status?customer_id=` + this.state.customer_id, axiosConfig),
          ]).then(axios.spread((payResponse) => {
            this.setState({
              payResponseResult: payResponse.data.data,
              amount_received_paid: mbalance
            })
            if (payResponse.data.status) {
              let openbalance;
              let openbalance_received;

              if (mbalance > 0) {
                openbalance = 0
                openbalance_received = ((parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess)) - parseFloat(this.state.out_of_balance))
              } else {
                openbalance = (parseFloat(this.state.out_of_balance) - (parseFloat(this.state.amount_received) + parseFloat(this.state.amount_paid_excess)))
              }

              let datas = [
                {
                  invoice_no: invoice_no_new, // react-beautiful-dnd unique key
                  due_amount_updated: mbalance,
                  amount_paid: mbalance,
                }
              ]
              let data2 = [];
              for (let i = 0; i < datas.length; i++) {
                let PAYMENT = { payment_id: this.state.payResponseResult[0].id };
                let mclientid = { client_id: USER_ID };
                let mamount_paind = { amount_paid: mbalance };
                let currency_type = { currency_type: this.state.invoice_currency }
                let mpayment_amount;
                mpayment_amount = { payment_amount: mbalance }

                data2.push(Object.assign(PAYMENT, currency_type, mclientid, mamount_paind, mpayment_amount, datas[i]));
                this.setState({
                  data2: data2,
                });
              }

              axios
                .post(baseURL_2 + 'updateiNVOICEPAYMENTStatus.php', data2)
                .then(statusresponse => {
                  // console.log("phpinvoice", statusresponse)
                  var names = data2.filter(person => person.payment_amount != 0).map(function (item) {
                    return item['invoice_no'];
                  });
                  var array = names
                  var array1 = array.toString();
                  var array3 = array1.replace(/,/g, ", ");
                  // alert(array3)
                  let formDataUpdated = {
                    customer_id: this.state.customer_id,
                    payment_id: this.state.payResponseResult[0].id,
                    invoice_no: array3
                  };
                  // alert(JSON.stringify(formDataUpdated))
                  axios.put(baseURL + `payment_status`, formDataUpdated, axiosConfig)
                    .then((statusresponse) => {
                      // alert("alert")
                      if (statusresponse.data.status) {
                        let url_record = baseURL + `invoicepaid_record?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=`
                          + USER_ID + `&&filter_value=` + this.state.payResponseResult[0].id
                        axios.all([
                          axios.get(url_record, axiosConfig),
                        ]).then(axios.spread((invoiceResponse) => {
                          console.log("BUGggg", invoiceResponse)
                          this.setState({
                            lineItems: invoiceResponse.data.data,
                            amount_received_paid: mbalance,
                            returnHome: false
                          })
                          let data2 = [];
                          for (let i = 0; i < invoiceResponse.data.data.length; i++) {
                            let mpayment_amount;
                            var str = invoiceResponse.data.data[i].amount;

                            let minvoice_amount_5 = { mminvoice_amount: parseFloat(num2) + parseFloat(num) }


                            var stfr = invoiceResponse.data.data[i].due_amount;

                            stfr = stfr.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                            var num3 = parseFloat(stfr);

                            let mclientid = { client_id: USER_ID };

                            var str3 = invoiceResponse.data.data[i].due_amount;

                            str3 = str3.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                            var num2 = parseFloat(str3);
                            // let minvoice_amount = { invoice_amount: num2 }
                            let minvoice_amount_2 = { due_amount_updated: num3 }
                            let mamountcompared;
                            // s = s.replace(/^+/, "");
                            // var str = 
                            // eslint-disable-next-line
                            str = str.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
                            var num = parseFloat(str);
                            let minvoice_amount = { mminvoice_amount: parseFloat(num) }

                            mpayment_amount = { payment_amount: mbalance }
                            mamountcompared = mbalance;

                            // if (this.state.amount_received_paid > 0) {
                            data2.push(Object.assign(mpayment_amount,
                              mclientid,
                              minvoice_amount_2,
                              minvoice_amount_5,
                              minvoice_amount, invoiceResponse.data.data[i]));
                            this.setState({
                              data2: data2,
                            });
                            console.log("bugs_report", data2);
                          }
                          successToast("success!")
                          this.setState({
                            isLoading: false,
                            loaded: false,
                          })
                          // });
                          window.scrollTo(0, 0)
                          window.setTimeout(() => {
                            this.setState({ isShowError: false });
                            // window.location.reload()
                            this._onButtonClick();
                          }, 1000);

                        })).catch(error => {
                          console.log('bayoo', error.response)

                        })

                      } else {


                        successToast(statusresponse.data.message)
                        this.setState({
                          isLoading: false,
                          loaded: false,
                        })
                        // this.setState({
                        //   statusMessage: statusresponse.data.message,
                        //   showError: true, isShowError: false, isSubmitted: false
                        // });
                      }
                    }).catch((error) => {
                      console.log('helloss', JSON.stringify(error))
                      this.setState({
                        isLoading: false,
                        loaded: false,
                      })
                      errorToast(JSON.stringify(error))
                    })
                }).catch(error => {

                  errorToast("error!")
                  this.setState({
                    isLoading: false,
                    loaded: false,
                  })
                  window.scrollTo(0, 0);
                  console.error('CHECK ERROR:', error.response);
                });

            }
          }))
        }
      })
  }


  handleChangeDeposit = value => {
    // alert(JSON.stringify(value))
    if (value != null) {
      this.setState(
        {
          optionselected2: value,
          deposit_to: value.value.toString(),
        });
    } else if (value.options != null) {
      this.setState(
        {
          deposit_to: null,
        });
    }
    else {
      this.setState(
        {
          deposit_to: null,
        });
    }
  };


  toggleServiceChange = () => {
    this.setState({
      show_cash_sales: !this.state.show_cash_sales,
      iscashsales: !this.state.iscashsales,
    })
  }

  render = () => {
    console.log('ITEMS', this.state.lineItems);
    const ref = React.createRef();
    const { editedInvoice, returnHome } = this.state;
    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
      backgroundColor: '#EBECF0',
      borderRadius: '2em',
      color: '#172B4D',
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '1',
      minWidth: 1,
      padding: '0.16666666666667em 0.5em',
      textAlign: 'center',
    };

    const formatGroupLabel = data => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );

    return (
      <div style={{ marginTop: "-20px" }} >
        {returnHome === true ? <Navigate to='/invoices' /> : null}

        <div ref={ref}>
          {ToastTable()}
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-6">
                  <h4><b>Create {BUSINESS_TYPE === 'business' ? "Invoice" : "Income Receipt"}</b></h4>
                </div>
                <div className="col-md-6">
                  <Link to="/invoices">
                    <Button className="pull-right btn-sm" onClick={this._onButtonClick}
                      color="primary" outline><i className="fa  fa-arrow-left"></i> {BUSINESS_TYPE === 'business' ? "Invoices" : "Income Receipts"}
                    </Button>
                  </Link>

                </div>
              </div>
              <form >
                <br /><br />
                <div className="col-10 offset-1 row">
                  <div className="col-md-6"><br />
                    <div className="col-md-12">
                      <label>
                        <input type="checkbox"
                          checked={this.state.show_cash_sales}
                          onChange={this.toggleServiceChange}
                        />&nbsp;
                        Is a cash sales
                      </label>
                    </div>
                  </div>

                  {this.state.show_cash_sales ?
                    null :
                    <>

                      <div className="col-md-6">
                        <br />


                        <div className="form-group">

                          <div className="row">
                            <div className="col-md-2">
                              <label className="form-label">{BUSINESS_TYPE === 'business' ? "Customer" : "Grantor"} Name</label>
                            </div>
                            <div className="col-md-10">
                              <Link className='Links' to='/customer-create'>Create New Customer ?</Link>

                              <CreatableSelect
                                isClearable
                                options={this.Customer()}
                                onInputChange={this.loadOptions}
                                placeholder={BUSINESS_TYPE === 'business' ? "Select Customer" : "Select Grantor"}
                                autosize={true}
                                onChange={this.onSelectChangesShort}
                                onCreateOption={this.handleCreateCustomer}
                                isLoading={this.state.isLoading}
                                // noOptionsMessage={() => 'nothing found'}
                                // loadingMessage={() => 'searching...'}   //minor type-O here
                                menuPortalTarget={document.body}
                                name="name"
                              /><br />
                            </div>
                          </div>

                        </div>
                      </div>
                      {BUSINESS_TYPE === 'business' ?
                        null :
                        <div className="col-md-6">
                          <br />
                          <Form.Group className="Focus-line">
                            <div className="row">
                              <div className="col-md-2">
                                <Form.Label>Deposit to</Form.Label>
                              </div>
                              <div className="col-md-6">

                                <Select
                                  isClearable
                                  // onInputChange={this.loadOptions}
                                  placeholder="Select one"
                                  autosize={true}
                                  options={groupedOptions_banks}
                                  formatGroupLabel={formatGroupLabel}

                                  value={this.state.optionselected2}
                                  onChange={this.handleChangeDeposit}
                                  // isLoading={this.state.isLoading}
                                  // noOptionsMessage={() => 'nothing found'}
                                  // loadingMessage={() => 'searching...'}   //minor type-O here
                                  menuPortalTarget={document.body}
                                  name="deposit_to"
                                /><br />

                              </div>
                              <div className="col-md-4">
                                <Link to="/create-bank">
                                  <Button className="pull-right btn-sm" outline color="primary" >
                                    Add Bank
                                  </Button>
                                </Link>

                              </div>

                            </div>

                          </Form.Group>
                        </div>
                      }
                      <div className="col-md-6">
                        <br />
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-2">
                              <label className="form-label">{BUSINESS_TYPE === 'business' ? "Invoice" : "Reference"}#</label>
                            </div>
                            <div className="col-md-10">
                              {/* <p>Please enter your initial invoice No. Default <b>0 </b></p> */}
                              <input
                                id="input"
                                type="number"
                                className="form-control"
                                name="invoice_no2"
                                placeholder={BUSINESS_TYPE === 'business' ? "Invoice No" : "Reference No."}
                                value={this.state.invoice_no2}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <br />
                        <Form.Group className="Focus-line">
                          <div className="row">
                            <div className="col-md-2">
                              <Form.Label>{BUSINESS_TYPE === 'business' ? "Invoice" : ""} date</Form.Label>
                            </div>
                            <div className="col-md-10 customDatePickerWidth">
                              <DatePicker
                                selected={this.state.invoice_date}
                                label="DatePicker Label"
                                dateFormat="dd/MM/yyyy"
                                required
                                // minDate={moment().toDate()}

                                value={editedInvoice.invoice_date}
                                className="form-control"
                                id="input"
                                onChange={value => this.onChangeInvoiceDate(value)}
                              />

                            </div>
                          </div>
                        </Form.Group>
                      </div>
                      {BUSINESS_TYPE === 'business' ?
                        <>
                          <div className="col-md-6">
                            <br />
                            <Form.Group className="Focus-line">
                              <div className="row">
                                <div className="col-md-2">
                                  <Form.Label>Terms</Form.Label>
                                </div>
                                <div className="col-md-10">

                                  <CreatableSelect
                                    isClearable
                                    options={this.state.terms3}
                                    // onInputChange={this.loadOptions}
                                    placeholder="Select terms or enter custom date"
                                    autosize={true}

                                    onChange={this.handleChangeType}
                                    isLoading={this.state.isLoading}
                                    // noOptionsMessage={() => 'nothing found'}
                                    // loadingMessage={() => 'searching...'}   //minor type-O here

                                    menuPortalTarget={document.body}
                                    name="terms"
                                  /><br />

                                </div>
                              </div>

                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <br />
                            <Form.Group className="Focus-line">
                              <div className="row">
                                <div className="col-md-2">
                                  <Form.Label>Due date</Form.Label>
                                </div>
                                <div className="col-md-10 customDatePickerWidth">
                                  <DatePicker
                                    selected={this.state.due_date}
                                    label="DatePicker Label"
                                    dateFormat="dd/MM/yyyy"
                                    // minDate={moment().toDate()}

                                    className="form-control"
                                    id="input"
                                    onChange={value =>
                                      this.setState({ due_date: value })}
                                  />

                                </div>
                              </div>
                            </Form.Group>

                          </div>

                          <div className="col-md-6">
                            <br />
                            <Form.Group className="Focus-line">
                              <div className="row">
                                <div className="col-md-2">
                                  <Form.Label>Sales Person</Form.Label>
                                </div>
                                <div className="col-md-10">
                                  <Link className='Links' to='/addsalesperson'>Create New Sales Person ?</Link>

                                  <CreatableSelect
                                    isClearable
                                    options={this.PaymentMethod()}
                                    // onInputChange={this.loadOptions}
                                    placeholder="Select or create one"
                                    autosize={true}
                                    onInputChange={this.loadOptionssales_persone}
                                    onChange={this.handleChangePayment}
                                    onCreateOption={this.handleCreate}
                                    value={this.state.optionselected}
                                    isLoading={this.state.isLoading}
                                    noOptionsMessage={() => 'Type to create one'}
                                    // loadingMessage={() => 'searching...'}   //minor type-O here
                                    menuPortalTarget={document.body}
                                    name="payment_mode"
                                  /><br />

                                </div>
                              </div>

                            </Form.Group>
                          </div>
                        </>
                        : null}


                      <div className="col-md-6">
                        <br />
                        <Form.Group className="Focus-line">
                          <div className="row">
                            <div className="col-md-2">
                              <Form.Label>Currency</Form.Label>
                            </div>
                            <div className="col-md-10">


                              <Select
                                isClearable
                                options={this.state.data}
                                placeholder="Search Your Currency"
                                noOptionsMessage={() => 'No currrency found'}
                                autosize={true}
                                required
                                isLoading={this.state.isLoading}
                                onChange={this.handleChangeCurrency}
                                openOnFocus={true}
                                menuPortalTarget={document.body}
                                name="currency"
                              />
                              {/* <p>N/B: <b style={{ color: "red" }}>Currency is one off. You won't be able to change it again</b></p> */}

                            </div>

                          </div>
                        </Form.Group>
                      </div>

                    </>
                  }


                </div>




                {/* <div className="col-md-6"><br />
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-2">
                        <label className="form-label">KRA PIN</label>
                      </div>
                      <div className="col-md-10">
                        <input id="input" type="text" className="form-control"
                          name="reference" placeholder='Reference'
                          value={this.state.referen} onChange={this.handleChange} />
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* {BUSINESS_TYPE === 'business' ? */}
                <div className="col-md-12">
                  {/* <br /> */}
                  <div className="col-md-6" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>

                    {/* <RadioGroup onChange={this.onChangeOption} value={this.state.tax_exclusive} horizontal>
                      <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                        Tax Exclusive
                      </RadioButton>
                      <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                        Tax Inclusive
                      </RadioButton>

                    </RadioGroup> */}
                  </div>
                  {/* <br /> */}
                  <Link className='Links' to='/item-create'>Create New Item ?</Link>

                  <LineItems
                    items={this.state.lineItems}
                    currencyFormatter={this.formatCurrency}
                    showtax={this.state.showtax}
                    addHandler={this.handleAddLineItem}
                    addNew={this.state.addnew}
                    changeHandler={this.handleLineItemChange}
                    focusHandler={this.handleFocusSelect}
                    deleteHandler={this.handleRemoveLineItem}
                    reorderHandler={this.handleReorderLineItems}
                  />

                  <div className="col-md-12">

                    <div className={styles.totalContainer}>
                      {/* <form>
                            <div className={styles.valueTable}>
                              <div className={styles.row}>
                                <div className={styles.label}>Tax Rate (%)</div>
                                <div className={styles.value}>
                                  <input
                                    name="taxRate"
                                    type="text"
                                    id="#js-testInputs"
                                    value={localStorage.getItem('valuestax')}
                                    onChange={this.handleInvoiceChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </form> */}
                      <form></form>
                      <form>
                        <div className={styles.valueTable}>
                          <div className={styles.row}>
                            <div className={styles.label}>Subtotal</div>
                            <div
                              className={"${styles.value} ${styles.currency}"}
                            >
                              {this.formatCurrency(
                                this.calcLineItemsTotal()
                              )}
                            </div>
                          </div>
                          {BUSINESS_TYPE === 'business' ?
                            <div>
                              <div className={styles.row}>
                                <div className={styles.label}>
                                  Tax
                                </div>
                                <div
                                  className={"${styles.value} ${styles.currency}"}
                                >
                                  {this.formatCurrency(this.calcTaxTotal())}
                                </div>
                              </div>

                              <div className={styles.row}>
                                <div className={styles.label}>Delivery</div>
                                <div
                                  className={"${styles.value} ${styles.currency}"}
                                >
                                  <input
                                    id="input"
                                    type="number"
                                    className="form-control"
                                    name="delivery_amount"
                                    placeholder="Delivery"
                                    value={this.state.delivery_amount}
                                    onChange={this.handleChangeDelivery}
                                  />
                                </div>
                              </div>
                              <div className={styles.row}>
                                <div className={styles.label}>Discount</div>
                                <div
                                  className={"${styles.value} ${styles.currency}"}
                                >
                                  <input
                                    id="input"
                                    type="number"
                                    className="form-control"
                                    name="discount_amount"
                                    placeholder="Discount Amount"
                                    value={this.state.discount_amount}
                                    onChange={this.handleChangeDisco}
                                  />
                                </div>
                              </div>
                            </div>
                            : null}

                          <div className={styles.row}>
                            <div className={styles.label}>{BUSINESS_TYPE === 'business' ? "Total Due" : "Total Received"}</div>
                            <div
                              className={"${styles.value} ${styles.currency}"}
                            >
                              {this.formatCurrency(this.calcGrandTotal())}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* 
                  <div className={styles.pay}>
                    <button className={styles.payNow} onClick={this.handlePayButtonClick}>Pay Now</button>
                  </div> */}
                    <br />
                  </div>
                </div>
                {/* : null} */}
                <div className="col-md-8 offset-2">
                  <br />
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-2">

                        <label className="form-label">
                          Message on {BUSINESS_TYPE === 'business' ? "Invoice" : "Income Receipt"}
                        </label>
                      </div>
                      <div className="col-md-8">
                        <textarea
                          name="invoice_message"
                          // required
                          style={{
                            paddingTop: '20px',
                            borderRadius: '5px',
                            borderColor: 'hsl(0,0%,80%)',
                          }}
                          value={this.state.invoice_message}
                          onChange={this.handleChangeInvoiceMessage}
                          className="col-md-12"
                          placeholder={BUSINESS_TYPE === 'business' ? "Hint: This will show up on the invoice" : "Hint: This will show up on the income receipts"}
                          id="message"
                          cols="col-md-1"
                          rows="6"
                        />
                      </div>

                    </div>
                  </div>
                  <br />
                </div>

                <div className="col-md-8 offset-2">
                  <br />
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-2">

                        <label className="form-label">
                          Terms and Conditions
                        </label>
                      </div>
                      <div className="col-md-8">
                        <textarea
                          name="statement_message"
                          // required
                          style={{
                            paddingTop: '20px',
                            borderRadius: '5px',
                            borderColor: 'hsl(0,0%,80%)',
                          }}
                          onChange={this.handleChangeStatementMessage}
                          className="col-md-12"
                          placeholder="Hint: Terms and conditions"
                          id="messages"
                          cols="col-md-1"
                          rows="8"
                        />
                      </div>
                      <div className="col-md-2">
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-2">
                        <Link to="/invoices">
                          <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                            Cancel
                          </Button>
                        </Link>

                      </div>
                      <div className="col-md-2">
                        <Button type="button"
                          onClick={
                            () => { this.handleAddLineSubmit(2) }
                          } color="primary" outline className="btn-paypal btn-sm pull-right" >
                          {this.state.isLoading ? "Please Wait..." : "Save as Draft"}

                        </Button> &nbsp;&nbsp;&nbsp;
                      </div>
                      <div className="col-md-4">
                        <Button type="button" color="primary" onClick={() => { this.handleAddLineSubmit(1) }} outline className="btn-paypal btn-sm pull-right" >
                          {this.state.isLoading ? "Please Wait..." : "Save and Send"}
                        </Button> &nbsp;&nbsp;&nbsp;
                      </div>


                      <div className="col-md-2">
                        <Button type="button" color="primary" onClick={() => { this.handleAddLineSubmit(3) }} outline className="btn-paypal btn-sm pull-right" >
                          {this.state.isLoading ? "Please Wait..." : "Save"}
                        </Button> &nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <br />

            </CardBody>
          </Card>
        </div>
      </div>
    );
  };
}

export default Invoice;
