import React, { Component } from 'react';
import styles from './Invoice.module.scss';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Form } from 'react-bootstrap';
import LineItems from './LineItems.jsx';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { uuid } from 'uuidv4';
import { BRANCH_ID, CURRENCY, PROFILE_URL, TOKEN, formatCurrency, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { baseURL } from '../../_helpers';
import Alert from '../../shared/components/Alert';
import { Link, Navigate } from 'react-router-dom';
import $ from 'jquery';
import CreatableSelect from 'react-select/creatable';
import * as moment from 'moment';
import { RadioGroup, ReversedRadioButton, RadioButton } from 'react-radio-buttons'


window.$ = window.jQuery = require('jquery');
require('../../_assets/jss/sms_counter.js');
var invNum = require('invoice-number');

window.localStorage.setItem("num", "ABC000001")

class SalesOrder extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this._onButtonClick = this._onButtonClick.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);
    this.handleAddLineSubmit = this.handleAddLineSubmit.bind(this);

  }


  locale = 'en-US';
  currency = CURRENCY;

  state = {
    taxRate: 0.00,
    invoice_no: invNum.InvoiceNumber.next(localStorage.getItem("num")),
    invoice_date: new Date(),
    due_date: new Date(),
    tax_exclusive: 1,
    showtax: true,
    addnew: false,
    discount_amount: 0,
    editedInvoice: {},
    returnHome: false,
    terms3: [
      {
        label: 'Due on Receipt',
        value: 'Due on Receipt',
      },
      {
        label: 'Due in 15 days',
        value: 'Due in 15 days',
      },
      {
        label: 'Due in 30 days',
        value: 'Due in 30 days',
      },
    ],
    lineItems: [
      {
        id: 'initial', // react-beautiful-dnd unique key
        name: '',
        description: '',
        reference: '',
        quantity: 1,
        discount_percentage: 0,
        tax_percentage: 0,
        price: 0.00,
      },
    ]
  };
  async componentDidMount() {
    $('#message').countSms('#sms-counter');
    $('#messages').countSms('#sms-counters');

    this.setState({
      isLoading: true,
      invoice_currency: CURRENCY
    })


    if (PROFILE_URL == "null") {
      localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
    } else {
      localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
      var img = new Image;
      img.crossOrigin = "*"
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
        canvas.toDataURL('image/jpeg', 1.0)
        var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
        localStorage.setItem("image_urls", fullQuality)
      };
      img.src = `${PROFILE_URL}?canvas=true`;
    }


    this.getInvoiceData()


    localStorage.setItem("num", invNum.InvoiceNumber.next(localStorage.getItem("num")))
    const [customerResponse, currency] = await Promise.all([
      axios.get(baseURL + `customer?branch_id=${BRANCH_ID}&&filter_value=&page_number=1&page_size=1000&sort_order=false&client_id=` + USER_ID, {
        headers: { Authorization: TOKEN },
      }),
      axios.get(baseURL + `currency`, {
        headers: { Authorization: TOKEN },
      })
    ]);
    this.setState({
      isLoading: false,
      returnHome: false,
      invoice_no: invNum.InvoiceNumber.next(this.state.invoice_no),
      customerResponse: customerResponse.data.data,
    });

    console.log('customer', this.state.customerResponse);

    var data = [];
    for (let i = 0; i < currency.data.data.length; i++) {
      let mlabel = { label: currency.data.data[i].currency };
      let mvalue = { value: currency.data.data[i].agnaist_ksh };
      data.push(Object.assign(mlabel, mvalue, currency.data.data[i]));
      this.setState({
        data: data,
      })
    }
  }
  handleLineItemChange = elementIndex => event => {

    console.log(this.state.lineItems);

    let lineItems = this.state.lineItems.map((item, i) => {
      if (elementIndex !== i) return item;
      // alert(elementIndex);
      return { ...item, [event.target.name]: event.target.value };
    });
    this.setState({ lineItems });
  };
  Customer() {
    return (
      this.state.customerResponse &&
      (this.state.customerResponse.length > 0 || this.state.customerResponse.length === 0) &&
      this.state.customerResponse.map((countyItem, i) => ({
        label: countyItem.customer_display_name,
        customer_email: countyItem.email,
        customer_nameinvoice: countyItem.customer_display_name,
        value: countyItem.id,
      }))
    );
  }

  handleAddLineItem = event => {

    let length = this.state.lineItems.length - 1;
    this.setState({
      addnew: true
    })

    if (length > -1) {
      if (this.state.lineItems[length].name === '') {
        alert("Name of the item cannot be empty")
        this.setState({
          addnew: false,
          // use optimistic uuid for drag drop; in a production app this could be a database id
          lineItems: this.state.lineItems.concat(),
        });
      } else {
        this.setState({
          addnew: true,
        })
        let axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Authorization": TOKEN
          }
        };

        var encodeURISafe = require('encodeuri-safe');
        // alert()
        let url = `${baseURL}item?branch_id=${BRANCH_ID}&&client_id=${USER_ID}&filter_value=${encodeURISafe.encodeURIComponent(this.state.lineItems[length].name)}`

        // let url = baseURL + `item?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&filter_value=` + this.state.lineItems[length].name
        axios.all([
          axios.get(url, axiosConfig),
        ]).then(axios.spread((itemResponse) => {
          this.setState({
            item: itemResponse.data.data,
            isLoading: false,

          },
            function () {
              var data = [];
              //filter json object
              let filter_items = this.state.lineItems.filter(item => item.name === this.state.item[0].item_name)
              let accumulative = filter_items.reduce(
                (prev, cur) =>
                  (Number(prev) + Number(cur.quantity)),
                0)
              if ((this.state.item[0].quantity - accumulative) < 0) {
                this.setState({
                  addnew: false,
                })
                this.handleRemove(length)
                alert(`You current stock for item ${this.state.item[0].item_name} is not enough. Kindly restock`)
              } else {
                this.setState({
                  addnew: false,
                  // use optimistic uuid for drag drop; in a production app this could be a database id
                  lineItems: this.state.lineItems.concat([
                    {
                      id: uuid(), // react-beautiful-dnd unique key
                      name: '',
                      description: '',
                      reference: '',
                      quantity: 1,
                      discount_percentage: 0,
                      tax_percentage: 0,
                      price: 0.00,
                    },
                  ]),
                })
              }
            })
        })).catch(error => {
          console.log('bayoo', error.response)
        })
      }
    } else {
      this.setState({
        addnew: false,
        // use optimistic uuid for drag drop; in a production app this could be a database id
        lineItems: this.state.lineItems.concat([
          {
            id: uuid(), // react-beautiful-dnd unique key
            name: '',
            description: '',
            reference: '',
            quantity: 1,
            discount_percentage: 0,
            tax_percentage: 0,
            price: 0.00,
          },
        ]),
      })

    }
  };


  handleAddLineSubmit = event => {

    let length = this.state.lineItems.length - 1;
    this.setState({
      addnew: true,
      isLoading: true
    })
    if (length > -1) {
      if (this.state.lineItems[length].name === '') {
        alert("Name of the item cannot be empty")
        this.setState({
          addnew: false,
          isLoading: false,

          // use optimistic uuid for drag drop; in a production app this could be a database id
          lineItems: this.state.lineItems.concat(),
        });
      } else {
        let axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Authorization": TOKEN
          }
        };
        let url = baseURL + `item?branch_id=${BRANCH_ID}&&client_id=` + USER_ID + `&&filter_value=` + this.state.lineItems[length].name
        axios.all([
          axios.get(url, axiosConfig),
        ]).then(axios.spread((itemResponse) => {
          this.setState({
            item: itemResponse.data.data,
          },
            function () {
              var data = [];
              let filter_items = this.state.lineItems.filter(item => item.name === this.state.item[0].item_name)
              let accumulative = filter_items.reduce(
                (prev, cur) =>
                  (Number(prev) + Number(cur.quantity)),
                0)
              if ((this.state.item[0].quantity - accumulative) < 0) {
                this.setState({
                  addnew: false,
                  isLoading: false,

                })
                this.handleRemove(length)
                alert(`You current stock for item ${this.state.item[0].item_name} is not enough. Kindly restock`)
              } else {
                this.setState({
                  addnew: false,

                })
                this.onSubmit(event)
              }
            })
        })).catch(error => {
          console.log('bayoo', error.response)
        })
      }
    } else {
      this.setState({
        addnew: false,
        // use optimistic uuid for drag drop; in a production app this could be a database id
        lineItems: this.state.lineItems.concat([
          {
            id: uuid(), // react-beautiful-dnd unique key
            name: '',
            description: '',
            reference: '',
            quantity: 1,
            discount_percentage: 0,
            tax_percentage: 0,
            price: 0.00,
          },
        ]),
      })

    }
  };



  // handlesubmit = event => {


  // }

  handleRemove(bayo) {
    this.setState({
      lineItems: this.state.lineItems.filter((item, i) => {
        return bayo !== i;
      }),
    });
  };

  handleRemoveLineItem = elementIndex => event => {
    this.setState({
      lineItems: this.state.lineItems.filter((item, i) => {
        return elementIndex !== i;
      }),
    });
  };

  handleReorderLineItems = newLineItems => {
    this.setState({
      lineItems: newLineItems,
    });
  };
  handleChangeInvoiceMessage = event => {
    this.setState({ invoice_message: event.target.value }, function () { });
    //alert(event.target.value)
  };

  handleChangeStatementMessage = event => {
    this.setState({ statement_message: event.target.value }, function () { });
    //alert(event.target.value)
  };

  handleFocusSelect = event => {
    event.target.select();
  };

  handlePayButtonClick = () => {
    alert('Not implemented');
  };


  formatCurrency1 = amount => {
    return new Intl.NumberFormat(this.locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };
  calcTaxAmount = c => {
    return c * (localStorage.getItem('valuestax') / 100);
  };


  calcLineItemsTotal = () => {

    if (this.state.showtax == true) {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + cur.quantity * cur.price,
        0
      );
    } else {

      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.quantity * cur.price) / (1 + (cur.tax_percentage / 100))),
        0
      );
    }


  };
  calcLineItemDiscounts = () => {
    return this.state.discount_amount;
  };


  calcGrandTotal = () => {
    return (
      this.calcLineItemsTotal() +
      this.calcTaxTotal() -
      this.calcLineItemDiscounts()
    );
  };

  onSelectChangesShort = value => {
    if (value != null) {

      this.setState(
        {
          ...this.state.editedInvoice,
          customer_id: value.value.toString(),
          customer_name: value.label.toString(),
          discount_amount: 0,
          customer_nameinvoice2: value.customer_nameinvoice.toString(),
          customer_email: value.customer_email != null ? value.customer_email.toString() : ''
        });
    }
  };

  handleChange = e => {
    this.setState({
      ...this.state.editedInvoice,
      [e.target.name]: e.target.value,
    });

    console.log('updates', this.state.editedInvoice);
  };



  handleChangeType = value => {

    if (value != null) {
      this.setState(
        {
          terms: value.value.toString()
        });

      if (value.value.toString() === "Due in 15 days") {
        this.setState({
          due_date: moment(new Date(this.state.invoice_date)).add(15, 'days').toDate()
        })
      }
      else if (value.value.toString() === "Due in 30 days") {
        this.setState({
          due_date: moment(new Date(this.state.invoice_date)).add(30, 'days').toDate()
        })
      }

      else if (value.value.toString() === "Due in 6 months") {
        this.setState({
          due_date: moment(new Date(this.state.invoice_date)).add(6, 'months').toDate()
        })
      }
      else {
        this.setState({
          due_date: new Date()
        })
      }
    }
    else {
      this.setState(
        {
          terms: "",
        });
    }
  };


  onSubmit(e) {
    // eslint-disable-next-line
    // let tempApproved = e;
    // if (e == 3) {
    //   tempApproved = 3;
    // }

    this.state.editedInvoice = {
      invoice_date: this.state.invoice_date,
      terms: this.state.terms,
      due_date: this.state.due_date,
      message_invoice: this.state.invoice_message,
      statement_invoice: this.state.statement_message,
      customer_name: this.state.customer_nameinvoice2,
      customer_email: this.state.customer_email,
      amount1: this.formatCurrency1(this.calcGrandTotal()),
      amount: this.formatCurrency(this.calcGrandTotal()),
      due_amount: this.formatCurrency(this.calcGrandTotal()),
      tax_amount: this.formatCurrency(this.calcTaxTotal()),
      discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
      lineItems: this.state.lineItems,
      tax_exclusive: this.state.tax_exclusive,
      currency: CURRENCY,
      currency_type: this.state.invoice_currency,
      tax: this.formatCurrency(this.calcTaxTotal()),
      subtoal: this.formatCurrency(this.calcLineItemsTotal()),
      total: this.formatCurrency(this.calcGrandTotal()),
    };

    let formData = {

      invoice_no: 0,
      invoice_date: this.state.invoice_date,
      terms: this.state.terms,
      due_date: this.state.due_date,
      reference: this.state.reference,
      tax_exclusive: this.state.tax_exclusive,
      approved: e,
      currency_type: this.state.invoice_currency,
      agnaist_ksh: this.state.agnaist_ksh,
      message_invoice: this.state.invoice_message,
      statement_invoice: this.state.statement_message,
      sub_total: this.formatCurrency(this.calcLineItemsTotal()),
      amount: this.formatCurrency(this.calcGrandTotal()),
      due_amount: this.formatCurrency(this.calcGrandTotal()),
      tax_amount: this.formatCurrency(this.calcTaxTotal()),
      discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
      created_by: USER_ID,
      estimate: '0',
      sales_order_no: '1',
      recurring: "0",
      sales_person_id: this.state.sales_person_id,
      customer_id: this.state.customer_id,


      // invoice_no: 0,
      // invoice_date: this.state.invoice_date,
      // terms: this.state.terms,
      // due_date: this.state.due_date,
      // message_invoice: this.state.invoice_message,
      // tax_exclusive: this.state.tax_exclusive,
      // statement_invoice: this.state.statement_message,
      // sub_total: this.formatCurrency(this.calcLineItemsTotal()),
      // estimate: 0,
      // sales_order_no: 1,
      // currency_type: this.state.invoice_currency,
      // approved: e,
      // amount: this.formatCurrency(this.calcGrandTotal()),
      // due_amount: this.formatCurrency(this.calcGrandTotal()),
      // tax_amount: this.formatCurrency(this.calcTaxTotal()),
      // discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
      // created_by: USER_ID,
      // recurring: "0",
      // customer_id: this.state.customer_id,
    };
    // console.log("DATA", JSON.stringify(formData))
    this.setState({ isLoading: true });
    axios
      .post(baseURL + `invoice`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: TOKEN,
        },
      })
      .then(response => {
        if (response.data.status) {
          this.setState({
            statusMessage: response.data.message,
            isShowError: true,
            isLoading: false,
            showError: false
          });

          localStorage.setItem("SALESORDER_SENDEMAIL", JSON.stringify(this.state.editedInvoice))

          localStorage.setItem("SALESORDER_CREATEPEAK", JSON.stringify(this.state.lineItems))
          window.setTimeout(() => {
            this.setState({ isShowError: false, returnHome: true });
            this._onButtonClick();
          }, 2000);
          window.scrollTo(0, 0);
        } else {
          this.setState({
            statusMessage: response.data.message,
            showError: true,
            isShowError: false,
            isLoading: false,
          });
          window.scrollTo(0, 0);
        }
      })
      .catch(error => {
        console.log('bayoo', error.response);
        this.setState({
          statusMessage: error.response.data.message,
          showError: true,
          isShowError: false,
          isLoading: false,
        });
        window.scrollTo(0, 0);
      });
  }



  formatCurrency = amount => {
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: "ABS",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount).replaceAll('ABS', this.state.invoice_currency);
  };



  onChangeOption(value) {
    console.log(value);
    if (value == 1) {
      this.setState({
        showtax: true,
        tax_inclusive: '0',
        tax_exclusive: 1,
      })
    } else {
      this.setState({
        showtax: false,
        tax_inclusive: '1',
        tax_exclusive: 0

      })
    }
    // alert(value)
  }
  calcTaxTotal = () => {
    if (this.state.showtax == true) {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.tax_percentage / 100) * (cur.quantity * cur.price)),
        0
      );
    } else {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.quantity * cur.price) - ((cur.quantity * cur.price) / (1 + (cur.tax_percentage / 100)))),
        0
      );
    }
  };


  // summar on invoices
  getInvoiceData = () => {
    this.setState({
      isLoading: true,
    })
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": TOKEN
      }
    };
    let url = baseURL + `notes?acc_id=${USER_ID_ACC}&&role=${ROLE}&&acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID
    axios.all([
      axios.get(url, axiosConfig),
    ]).then(axios.spread((itemResponse) => {
      this.setState({
        notes: itemResponse.data.data,
        isLoading: false,
      }, function () {
        var data = [];
        if (this.state.notes.length > 0) {
          this.setState({ invoice_message: this.state.notes[0].invoice_notes });
        } else {
          this.setState({
            invoice_message: "",
          })
        }
      })
    })).catch(error => {
      console.log('bayoo', error.response)
    })
  }

  _onButtonClick() {
    this.setState({
      returnHome: true
    });
  }
  loadOptions = inputValue => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: TOKEN,
      },
    };
    this.setState({
      isLoading: true,
    });
    let url =
      baseURL +
      `customer?branch_id=${BRANCH_ID}&&client_id=` +
      USER_ID +
      `&&filter_value=${inputValue}`;
    axios.get(url, axiosConfig).then(itemResponse => {
      this.setState(
        {
          item: itemResponse.data.data,
        },
        function () {
          this.setState({
            isLoading: false,
            bayo: this.state.item &&
              (this.state.item.length > 0 || this.state.item.length === 0) &&
              this.state.item.map((countyItem, i) => ({
                label: countyItem.customer_display_name,
                customer_email: countyItem.email,
                value: countyItem.id,
              })),
          });
        }
      );
    });
  };

  handleChangeDisco = e => {
    this.setState({
      discount_amount: e.target.value,
    });

    console.log('updates', this.state.editedInvoice);
  };


  handleCreate = inputValue => {
    const newValue = { value: inputValue.toLowerCase(), label: inputValue };
    this.setState({ newValue })
    // setOptions([...options, newValue]);
    // setValue(newValue);

  };


  handleChangeCurrency = value => {


    // alert(JSON.stringify(value.value.toString()))
    // alert(JSON.stringify(<CurrencyConverter from={'USD'} to={'CAD'} value={29} precision={2} date={'2010-12-22'}/>))
    if (value != null) {
      this.setState(
        {
          agnaist_ksh: value.value.toString(),
          invoice_currency: value.currency.toString(),
          currencylabel: value.currency.toString(),
        });
    }
  };

  render = () => {
    console.log('ITEMS', this.state.lineItems);
    const ref = React.createRef();
    const { editedInvoice, returnHome } = this.state;
    return (
      <div style={{ marginTop: "-20px" }} >
        {returnHome === true ? <Navigate to='/salesorderview' /> : null}

        <div ref={ref}>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-6">
                  <h4><b>Create Sales Order</b></h4>
                </div>
                <div className="col-md-6">
                  <Link to="/salesorderview">
                    <Button className="pull-right" onClick={this._onButtonClick}
                      color="primary" outline><i className="fa  fa-arrow-left"></i> Sales Order
                    </Button>
                  </Link>

                </div>
              </div>

              {this.state.showError
                ? <div>

                  <Alert color="danger" className="alert--colored" icon>
                    <p>
                      <span className="bold-text">
                        {this.state.statusMessage}
                      </span>
                    </p>
                  </Alert>

                </div>
                : null}<br />

              {this.state.isShowError
                ? <div>

                  <Alert color="success" className="alert--colored" icon>
                    <p>
                      <span className="bold-text">
                        {this.state.statusMessage}
                      </span>
                    </p>
                  </Alert>

                </div>
                : null}<br />
              <form onSubmit={this.handleAddLineSubmit}>
                <br /><br />
                <div className="col-10 offset-1 row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-2">
                          <label className="form-label">Customer Name</label>
                        </div>
                        <div className="col-md-10">
                          <Link className='Links' to='/customer-create'>Create New Customer ?</Link>

                          <Select
                            isClearable
                            options={this.Customer()}
                            onInputChange={this.loadOptions}
                            placeholder="Search Customer"
                            autosize={true}
                            onChange={this.onSelectChangesShort}
                            isLoading={this.state.isLoading}
                            // noOptionsMessage={() => 'nothing found'}
                            // loadingMessage={() => 'searching...'}   //minor type-O here
                            menuPortalTarget={document.body}
                            name="namffe"
                          /><br />
                        </div>
                      </div>

                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <br />
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-2">
                          <label className="form-label">Invoice No.</label>
                        </div>
                        <div className="col-md-10">
                          <input
                            id="input"
                            type="text"
                            className="form-control"
                            name="invoice_no"
                            required
                            placeholder="Invoice No"
                            value={this.state.invoice_no}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <br />
                    <Form.Group className="Focus-line">
                      <div className="row">
                        <div className="col-md-2">
                          <Form.Label>Sales order date</Form.Label>
                        </div>
                        <div className="col-md-10 customDatePickerWidth">
                          <DatePicker
                            selected={this.state.invoice_date}
                            label="DatePicker Label"
                            dateFormat="dd/MM/yyyy"
                            required

                            value={editedInvoice.invoice_date}
                            className="form-control"
                            id="input"
                            onChange={value =>
                              this.setState({ invoice_date: value })}
                          />

                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <Form.Group className="Focus-line">
                      <div className="row">
                        <div className="col-md-2">
                          <Form.Label>Terms</Form.Label>
                        </div>
                        <div className="col-md-10">
                          {/* <Link className='Links' to='/addsalesperson'>Create New Sales Person ?</Link> */}

                          <CreatableSelect
                            isClearable
                            options={this.state.terms3}
                            // onInputChange={this.loadOptions}
                            placeholder="Select terms or enter custom date"
                            autosize={true}
                            onChange={this.handleChangeType}
                            isLoading={this.state.isLoading}
                            // noOptionsMessage={() => 'nothing found'}
                            // loadingMessage={() => 'searching...'}   //minor type-O here

                            menuPortalTarget={document.body}
                            name="namffe"
                          /><br />

                        </div>
                      </div>

                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <Form.Group className="Focus-line">
                      <div className="row">
                        <div className="col-md-2">
                          <Form.Label>Due date</Form.Label>
                        </div>
                        <div className="col-md-10 customDatePickerWidth">
                          <DatePicker
                            selected={this.state.due_date}
                            label="DatePicker Label"
                            dateFormat="dd/MM/yyyy"

                            className="form-control"
                            id="input"
                            onChange={value =>
                              this.setState({ due_date: value })}
                          />

                        </div>
                      </div>
                    </Form.Group>

                  </div>

                  <div className="col-md-6">
                    <br />
                    <Form.Group className="Focus-line">
                      <div className="row">
                        <div className="col-md-2">
                          <Form.Label>Currency</Form.Label>
                        </div>
                        <div className="col-md-10">


                          <Select
                            isClearable
                            options={this.state.data}
                            placeholder="Search Your Currency"
                            noOptionsMessage={() => 'No currrency found'}
                            autosize={true}
                            required
                            onChange={this.handleChangeCurrency}
                            openOnFocus={true}
                            menuPortalTarget={document.body}
                            name="currency"
                          />
                          {/* <p>N/B: <b style={{ color: "red" }}>Currency is one off. You won't be able to change it again</b></p> */}

                        </div>

                      </div>
                    </Form.Group>
                  </div>


                </div>
                <div className="col-md-12">

                  <div className="col-md-12">
                    <br />
                    {/* <div className="col-md-6" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>

                      <RadioGroup onChange={this.onChangeOption} value={this.state.tax_exclusive} horizontal>
                        <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                          Tax Exclusive
                        </RadioButton>
                        <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                          Tax Inclusive
                        </RadioButton>

                      </RadioGroup>
                    </div> */}
                    <br />
                    <LineItems
                      items={this.state.lineItems}
                      currencyFormatter={formatCurrency}
                      addHandler={this.handleAddLineItem}
                      showtax={this.state.showtax}
                      addNew={this.state.addnew}
                      changeHandler={this.handleLineItemChange}
                      focusHandler={this.handleFocusSelect}
                      deleteHandler={this.handleRemoveLineItem}
                      reorderHandler={this.handleReorderLineItems}
                    />

                    <div className="col-md-12">

                      <div className={styles.totalContainer}>
                        {/* <form>
                            <div className={styles.valueTable}>
                              <div className={styles.row}>
                                <div className={styles.label}>Tax Rate (%)</div>
                                <div className={styles.value}>
                                  <input
                                    name="taxRate"
                                    type="text"
                                    id="#js-testInputs"
                                    value={localStorage.getItem('valuestax')}
                                    onChange={this.handleInvoiceChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </form> */}
                        <form></form>
                        <form>
                          <div className={styles.valueTable}>
                            <div className={styles.row}>
                              <div className={styles.label}>Subtotal</div>
                              <div
                                className={`${styles.value} ${styles.currency}`}
                              >
                                {this.formatCurrency(
                                  this.calcLineItemsTotal()
                                )}
                              </div>
                            </div>
                            <div className={styles.row}>
                              <div className={styles.label}>
                                Tax
                              </div>
                              <div
                                className={`${styles.value} ${styles.currency}`}
                              >
                                {this.formatCurrency(this.calcTaxTotal())}
                              </div>
                            </div>
                            <div className={styles.row}>
                              <div className={styles.label}>Discount</div>
                              <div
                                className={`${styles.value} ${styles.currency}`}
                              >
                                <input
                                  id="input"
                                  type="number"
                                  className="form-control"
                                  name="discount_amount"
                                  placeholder="Discount Amount"
                                  value={this.state.discount_amount}
                                  onChange={this.handleChangeDisco}
                                />
                                {/* {this.formatCurrency(
                                
                              )} */}
                              </div>
                            </div>

                            <div className={styles.row}>
                              <div className={styles.label}>Total Due</div>
                              <div
                                className={`${styles.value} ${styles.currency}`}
                              >
                                {this.formatCurrency(this.calcGrandTotal())}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* 
                  <div className={styles.pay}>
                    <button className={styles.payNow} onClick={this.handlePayButtonClick}>Pay Now</button>
                  </div> */}
                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 offset-2">
                  <br />
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-2">

                        <label className="form-label">
                          Message on Sales Order
                        </label>
                      </div>
                      <div className="col-md-8">
                        <textarea
                          name="invoice_message"


                          style={{
                            paddingTop: '20px',
                            borderRadius: '5px',
                            borderColor: 'hsl(0,0%,80%)',
                          }}
                          value={this.state.invoice_message}
                          onChange={this.handleChangeInvoiceMessage}
                          className="col-md-12"
                          placeholder="Hint: This will show up on the sales order"
                          id="message"
                          cols="col-md-1"
                          rows="1"
                        />
                      </div>

                      <div className="col-md-2">
                        <ul id="sms-counter">
                          <li>Length: <span class="length" /></li>
                          {/* <li>SMS: <span class="messages"></span></li> */}
                          <li>
                            Message:
                            {' '}
                            <span class="remaining" />
                            /
                            <span class="per_message" />
                          </li>
                          {/* <li>Remaining: </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>

                <div className="col-md-8 offset-2">
                  <br />
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-2">

                        <label className="form-label">
                          Terms and Conditions
                        </label>
                      </div>
                      <div className="col-md-8">
                        <textarea
                          name="statement_message"
                          style={{
                            paddingTop: '20px',
                            borderRadius: '5px',
                            borderColor: 'hsl(0,0%,80%)',
                          }}
                          onChange={this.handleChangeStatementMessage}
                          className="col-md-12"
                          placeholder="Hint: Terms and conditions for your business"
                          id="messages"
                          cols="col-md-1"
                          rows="1"
                        />
                      </div>
                      <div className="col-md-2">
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />

                <div className="col-md-10 offset-1"><br />
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-2">
                        <Link to="/salesorderview">
                          <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                            Cancel
                          </Button>
                        </Link>

                      </div>
                      <div className="col-md-4">
                        <Button type="button"
                          onClick={
                            () => { this.handleAddLineSubmit(2) }
                          } color="primary" outline className="btn-paypal btn-sm pull-right" >
                          {this.state.isLoading ? "Please Wait..." : "Save as Draft"}

                        </Button> &nbsp;&nbsp;&nbsp;
                      </div>

                      <div className="col-md-2">
                        <Button type="button" color="primary" onClick={() => { this.handleAddLineSubmit(1) }} outline className="btn-paypal btn-sm pull-right" >
                          {this.state.isLoading ? "Please Wait..." : "Save and Send"}

                        </Button> &nbsp;&nbsp;&nbsp;
                      </div>
                      <div className="col-md-2">
                        <Button type="button" color="primary" onClick={() => { this.handleAddLineSubmit(3) }} outline className="btn-paypal btn-sm pull-right" >
                          {this.state.isLoading ? "Please Wait..." : "Save"}
                        </Button> &nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <br />

            </CardBody>
          </Card>
        </div>
      </div>
    );
  };
}

export default SalesOrder;
