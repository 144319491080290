import { COMPANY_NAME, COMPANY_POSTAL_ADDRESS, EMAIL_ADDRESS, PHONE_MSISDN, PHYSICAL_ADDRESS, PROFILE_URL } from '../../_helpers/exports';
import * as moment from 'moment';
import pdfMake from "pdfmake/build/pdfmake";

// import LOGO from '../../_assets/img/bg.jpg'

export function saveEstimateview(params) {
    createPDFFromParams(params);
}

// // Private functions
// function onDataURLLoaded(params, fileReader) {
//     // Set imageLogo to data URI of file
//     params.imageLogo = fileReader.result;
//     createPDFFromParams(params);
// }

function createPDFFromParams(params) {
    let docDefinition = buildDocDefinition(params);
    pdfMake.createPdf(docDefinition).open();
}

function buildDocDefinition(params) {
    let notesAndTerms = buildNotesAndTerms(params);


    console.log('notesAndTerms', notesAndTerms);
    return {
        header: {
            columns: [

                headerNotes(params)
            ]
        },
        // background: function(currentPage, pageSize) {
        //     return `page ${currentPage} with size ${pageSize.width} x ${pageSize.height}`
        //   },

        // userPassword: params.invoice_no,
        // ownerPassword: params.invoice_no,
        permissions: {
            printing: 'highResolution', //'lowResolution'
            modifying: false,
            copying: false,
            annotating: true,
            fillingForms: true,
            contentAccessibility: true,
            documentAssembly: true
        },
        footer: function () {
            return [
                { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 595 - 10, y2: 10, lineWidth: 0.5 }] },
                {
                    columns: [
                        { text: `${params.footer}`, bold: true, color: 'black', fontSize: 9, alignment: 'center', marginTop: 5 }
                    ],

                },
            ];
        },

        background: [
            {
                image: localStorage.getItem("image_urls"),
                alignment: 'center',
                width: 800,
                height: 810,
                opacity: 0.1,
                borderRadius: 25,
            },

        ],
        content: [
            buildHeaderInformation(params),
            buildLineItemsTable(params),
            buildTotal(params),
            ...buildNotesAndTerms(params),
        ],
    };
}
// Returns an array
function headerNotes(params) {

    if (params.status === 0) {

        if (params.due_period < 0) {
            return [
                { text: `\t Due in ${params.due_period * -1} days\t\t`, alignment: 'left', background: '#5bc0de', color: '#FFFFFF', margin: [5, 15, 0, 5] }
            ]
        }
        if (params.due_period > 0) {
            return [
                { text: `\t Over Due by ${params.due_period} days\t\t`, alignment: 'left', background: '#FF0000', color: '#FFFFFF', margin: [5, 15, 0, 5] }
            ]
        }
        if (params.due_period === 0) {
            return [
                { text: '\t Due today\t\t', alignment: 'left', background: '#5bc0de', color: '#FFFFFF', margin: [5, 15, 0, 5] }
            ]
        }
    } else if (params.status === 1) {
        return [
            { text: `\tPAID\t\t`, alignment: 'left', background: '#5cb85c', color: '#FFFFFF', margin: [5, 15, 0, 5] }
        ]
    }
}

function buildHeaderInformation(params) {

    // alert(params.invoice_date)
    let date1 = moment(params.invoice_date).format('DD, MMM YYYY');

    let date2 = moment(params.due_date).format('DD, MMM YYYY');
    const optionalDataKeys = [];
    const optionalDataValues = [];
    let tax_exclusive


    if (params.tax_exclusive === 1) {

        tax_exclusive = "Tax Exclusive"

    } else {
        tax_exclusive = "Tax Inclusive"

    }
    // let total = params.lineItems.reduce((sum, lineItem) => {
    //     return sum + lineItem.quantity * lineItem.price;
    // }, 0);
    Object.entries({
        "Date :": date1,
        'Terms :': params.terms,
        'Due Date :': date2,
    }).forEach(([key, value]) => {
        if (value) {
            optionalDataKeys.push(key);
            optionalDataValues.push(value);
        }
    });

    return {
        columns: [{
            stack: [



                {
                    image: localStorage.getItem("image_urls"),
                    width: 90,
                    height: 90,
                    margin: [0, 0, 0, 10],

                },
                {
                    text: COMPANY_NAME,
                    color: "black",
                    margin: [0, 0, 0, 10],
                    // background: '#eee',
                },
                {
                    text: COMPANY_POSTAL_ADDRESS,
                    color: "black",
                    margin: [0, 0, 0, 10],
                    // background: '#eee',
                },
                {
                    text: `Tel: ${PHONE_MSISDN}`,
                    color: "steelblue",
                    margin: [0, 0, 0, 0],
                    // background: '#eee',
                },

                {
                    text: `Email: ${EMAIL_ADDRESS}`,
                    color: "steelblue",
                    margin: [0, 0, 0, 0],
                    // background: '#eee',
                },
                {
                    text: `${PHYSICAL_ADDRESS}`,
                    color: "steelblue",
                    margin: [0, 0, 0, 70],
                    // background: '#eee',
                },

                {
                    text: "Bill to",
                    color: "black",

                    // background: '#eee',
                },

                {
                    text: params.customer_name,
                    color: "black",
                    margin: [0, 0, 0, 20],
                    bold: true
                    // background: '#eee',
                },

                // {
                //     text: tax_exclusive,
                //     color: "black",
                //     bold: true
                //     // background: '#eee',
                // },
            ],
        },
        {
            stack: [{
                text: 'QUOTATION',
                bold: true,
                fontSize: 20,
            },
            {
                text: `# ${params.invoice_no}`,
                fontSize: 12,
                bold: true,
                margin: [0, 0, 0, 20],
            },

            {
                text: `Balance Due`,
                fontSize: 15,
                bold: true,
            },

            {
                text: params.total,
                fontSize: 12,
                bold: true,
                margin: [0, 10, 0, 70],
            },

            {
                columns: [{
                    width: 50,
                    text: '',
                },
                {
                    width: '*',
                    columns: [{
                        stack: optionalDataKeys,
                        alignment: 'right',
                    },
                    {
                        stack: optionalDataValues,
                        alignment: 'right',
                    },
                    ],
                },
                ],
            },
            ],
            alignment: 'right',
        },
        ],
        styles: {
            subheader: {
                fontSize: 9,
                color: '#FFFFFF',
                background: '#2361AE',
                margin: [0, 15, 0, 5]
            }
        },
        // optional space between columns
        columnGap: 10,
        margin: [0, 0, 0, 30],
    };
}




function buildLineItemsTable(params) {
    var n = 0
    let params_two = params.lineItems.map(obj => (Object.assign(obj, { index: ++n })))
    let lineItemRows = params_two.map(buildLineItem(params));

    return {
        table: {
            widths: ['2%', '*', '9%', '20%', '21%'],

            headerRows: 1,
            body: [
                [
                    {
                        text: "#",
                        color: '#FFFFFF',
                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },
                    {
                        text: 'Items & Description',
                        color: '#FFFFFF',
                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },

                    // {
                    //     text: 'Ref',
                    //     color: '#FFFFFF',
                    //     alignment: 'right',
                    //     margin: [5, 5, 5, 5],
                    //     fillColor: '#00000'
                    // },


                    {
                        text: 'Qty',
                        color: '#FFFFFF',
                        alignment: 'right',

                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },
                    {
                        text: 'Rate',
                        color: '#FFFFFF',
                        alignment: 'right',

                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },
                    {
                        text: 'Amount',
                        color: '#FFFFFF',
                        alignment: 'right',
                        margin: [5, 5, 5, 5],
                        fillColor: '#00000'
                    },
                ],
                ...lineItemRows,
            ],
        },
        layout: 'lightHorizontalLines',
    };
}


function buildTotal(params) {
    // let total = params.lineItems.reduce((sum, lineItem) => {
    //     return sum + lineItem.quantity * lineItem.price;
    // }, 0);

    // let subtotal = params.lineItems.reduce((sum, lineItem) => {
    //     return sum + (lineItem.quantity * lineItem) - params.tax;
    // }, 0);
    return {

        table: {
            widths: ['*', '40%'],
            body: [

                [{
                    text: 'Subtotal',
                    marginTop: 20,
                    alignment: 'right',
                },
                {
                    text: params.subtoal,
                    marginTop: 20,
                    alignment: 'right',
                },
                ],
                [
                    {
                        text: 'Tax Amount',
                        marginTop: 20,
                        alignment: 'right',
                    },
                    {
                        text: `${params.tax}`,
                        marginTop: 20,
                        alignment: 'right',
                    },
                ],
                [{
                    text: 'Discount',
                    marginTop: 20,
                    alignment: 'right',
                },
                {
                    text: params.discount_amount,
                    marginTop: 20,
                    marginBottom: 15,
                    alignment: 'right',
                },
                ],
                [{
                    text: 'Total',
                    margin: [5, 5, 5, 5],
                    color: '#0000',
                    fillColor: '#CCCCCC',
                    alignment: 'right',
                },
                {
                    text: `${params.total}`,
                    margin: [5, 5, 5, 5],

                    color: '#00000',
                    fillColor: '#CCCCCC',
                    alignment: 'right',
                },
                ],
            ],
        },
        layout: 'headerLineOnly',
        style: 'noBorders',
        // layout: {
        //     fillColor: function (rowIndex, node, columnIndex) {
        //         return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
        //     }
        // },

        margin: [160, 20, 0, 10],
    };
}



// Returns an array
function buildNotesAndTerms(params) {
    let result = [];
    console.log('params', params);

    // alert(JSON.stringify(params))

    result = result.concat([
        { text: params.message_invoice, background: '#eee', margin: [0, 50, 0, 15] },
        { text: params.statement_invoice, background: '#eee', margin: [0, 0, 0, 15] },
        { text: params.notesAndTerms, background: '#eee', margin: [0, 0, 0, 30] },
    ]);

    // if (params.terms) {
    //     result = result.concat([
    //         { text: '' },
    //         { text: params.stat, margin: [0, 0, 0, 30] },
    //     ]);
    // }
    return result;
}


// this.state.editedInvoice = {
//     invoice_no: this.state.invoice_no,
//     invoice_date: this.state.invoice_date,
//     terms: this.state.terms,
//     due_date: this.state.due_date,
//     notes: this.state.invoice_message,
//     stat: this.state.statement_message,
//     customer_id: this.state.customer_id,
//     lineItems: this.state.lineItems
//   }

function buildLineItem(params) {

    // alert(JSON.stringify(params));

    return function buildLineItemCurried(lineItem) {
        return [
            {
                text: lineItem.index,
                marginTop: 10, marginLeft: 5
            },

            {
                text: lineItem.name + " " + lineItem.description,
                marginTop: 10, marginLeft: 5
            },
            // {
            //     text: `${lineItem.reference}`, marginTop: 10, alignment: 'right'
            // },
            {
                text: String(lineItem.quantity), marginTop: 10, alignment: 'right',
            },
            
            {
                text: `${parseFloat(lineItem.price).toFixed(2).replaceAll(/\d(?=(\d{3})+\.)/g, '$&,')}`, marginTop: 10, alignment: 'right',
            },
            // { text: `${lineItem.discount_percentage}`, marginTop: 10 },
            {
                alignment: 'right',
                marginTop: 10,
                text: `${parseFloat(Math.round((((lineItem.quantity * lineItem.price) -
                    (lineItem.discount_percentage / 100 * (lineItem.quantity * lineItem.price))) +
                    (lineItem.tax_percentage / 100 * (lineItem.quantity * lineItem.price))), 2)).toFixed(2).replaceAll(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            },

        ];
    };
}