import React from 'react';
import { baseURL, baseURL_2, currentUserSubject } from '../../../_helpers';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { Dot } from "react-animated-dots";
import logo from '../../../shared/img/logo/3.png'
import { NavLink } from 'react-router-dom';
import CardIcon from 'mdi-react/CardIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import Alert from '../../../shared/components/Alert';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { INVESTMENT, TOK, USER_ID } from '../../../_helpers/exports';
import { RadioGroup, ReversedRadioButton, RadioButton } from 'react-radio-buttons'

class OTP extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.changetologin = this.changetologin.bind(this);
    this.onVeried = this.onVeried.bind(this);
    this.onSubmitEmail = this.onSubmitEmail.bind(this);

    this.onChangeMeans = this.onChangeMeans.bind(this);


    localStorage.setItem("token", null)
    this.timer = 0;

    this.state = {
      password: "",
      time: {},
      seconds: 120,
      showPassword: false,
      verify: false,
      phone_number: "2",
      setShowPassword: false,
      error: "",
      errorMsg: "",
      expiry: false,
      role: "",
      id: "",
      user: [],
      statusMessage: "",
      isShowSuccess: false,
      submitted: false,
      alert_error_color: "",
      isLoggedIn: false,
      isChecked: false,
      errorShow: false,
      successShow: false,
      statis: []
    };


  }

  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }

  secondsToTime(secs) {

    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };


    return obj;
  }


  changetologin = () => {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.timer = 0;

    this.setState({
      expiry: false,
      time: {},
      seconds: 120,
      verify: false
    })
  }
  componentDidMount() {

    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar, msisdn: localStorage.getItem("phonemsisdn_msisdn"), email_address: localStorage.getItem("clientemail") });

  }

  handleChangeEmail = event => {
    this.setState({ email_address: event.target.value });
  };

  handleChangePhone = event => {
    this.setState({ msisdn: event.target.value });
  };
  handleChangePassword = event => {
    this.setState({ code: event.target.value });
  };


  onSubmitEmail = e => {
    e.preventDefault();
    const mreference = require("randomstring").generate({
      length: 6,
      charset: 'numeric',
      capitalization: 'uppercase'
    })

    this.setState({
      isLoading: true
    });
    this.startTimer()
    var daily = moment(new Date(new Date())).add(120, 'seconds');
    // eslint-disable-next-line
    let formData = {
      "msisdn": this.state.msisdn,
      "code": mreference,
      "email": this.state.email_address,
      "expired": ((new Date(daily).getTime()) / 1000)
    }
    this.setState({ isLoading: true });

    axios.post(baseURL + `otpemail`, formData
    ).then((response) => {
      console.log("token", response.data);
      if (response.data.status) {
        localStorage.setItem("phonemsisdn_msisdn", this.state.msisdn)
        localStorage.setItem("mreference", mreference)
        this.setState({
          statusMessage: response.data.message,
          isShowError: true,
          errorShow: false,
          submitted: true,
          verify: true,
          time: {},
          seconds: 120,
          // code: "",
          expiry: true,
          isLoading: false,
          isLoggedIn: true
        });
        setTimeout(() => this.setState({ isShowError: false }), 2000);
      }
      else {
        console.log("bayoo", response.data)
        this.setState({
          errorShow: true,
          error: response.data.message,
          submitted: true,
          isLoading: false
        }, function () {
          console.log('data', response.data.message)
        });
      }
    }).catch(error => {
      console.log('bayoo', error.response)
      this.setState({
        errorShow: true,
        error: error.response === undefined ? "Check your internet" : error.response.data.message,
        submitted: true,
        isLoading: false
      });
      this.setState({ password: "" });
    })
  }




  onSubmit = e => {
    e.preventDefault();
    const mreference = require("randomstring").generate({
      length: 6,
      charset: 'numeric',
      capitalization: 'uppercase'
    })

    this.setState({
      isLoading: true
    });
    this.startTimer()
    var daily = moment(new Date(new Date())).add(120, 'seconds');
    // eslint-disable-next-line
    if (this.state.msisdn == "") {
      this.setState({
        isLoading: false,
        error: "Phone required",
        errorShow: true,
        submitted: true,
        alert_error_color: "alert alert-danger"
      });
    } else {




      var str = this.state.msisdn;
      var res = str.substring(0, 6);
      var res_2 = str.substring(0, 7);
      let formData = {
        "msisdn": this.state.msisdn,
        "code": mreference,
        "expired": ((new Date(daily).getTime()) / 1000)
      }
      this.setState({ isLoading: true });
      // var res_3 = str.substring(0, 6);
      // 070X, 071X, 072X, 079X
      // 0112, 0113, 0114, and 0115
      // eslint-disable-next-line
      if (res == +25470 || res == +25471 || res == +25472 || res == +25479) {
        axios.post(baseURL + `otp`, formData
        ).then((response) => {
          console.log("token", response.data);
          if (response.data.status) {
            localStorage.setItem("phonemsisdn_msisdn", this.state.msisdn)
            localStorage.setItem("mreference", mreference)
            this.setState({
              statusMessage: response.data.message,
              isShowError: true,
              errorShow: false,
              submitted: true,
              verify: true,
              time: {},
              seconds: 120,
              code: "",
              expiry: true,
              isLoading: false,
              isLoggedIn: true
            });
            setTimeout(() => this.setState({ isShowError: false }), 2000);
          }
          else {
            console.log("bayoo", response.data)
            this.setState({
              // alert_error_color: "alert alert-danger",
              errorShow: true,
              error: response.data.message,
              submitted: true,
              isLoading: false
            }, function () {
              console.log('data', response.data.message)
            });
          }
        }).catch(error => {
          console.log('bayoo', error.response)
          this.setState({
            // alert_error_color: "alert alert-danger",
            errorShow: true,
            error: error.response === undefined ? "Check your internet" : error.response.data.message,
            submitted: true,
            isLoading: false
          });
          this.setState({ password: "" });
        })

      } else if (res_2 == +254112 || res_2 == +254113 || res_2 == +254114 || res_2 == +254115 || res_2 == +254740 || res_2 == +254741 || res_2 == +254742 || res_2 == +254743 || res_2 == +254745 || res_2 == +254746 || res_2 == +254748 || res_2 == +254757 || res_2 == +254758 || res_2 == +254759 || res_2 == +254768 || res_2 == +254769) {
        axios.post(baseURL + `otp`, formData
        ).then((response) => {
          console.log("token", response.data);
          if (response.data.status) {
            localStorage.setItem("phonemsisdn_msisdn", this.state.msisdn)
            localStorage.setItem("mreference", mreference)
            this.setState({
              statusMessage: response.data.message,
              isShowError: true,
              errorShow: false,
              submitted: true,
              verify: true,
              time: {},
              seconds: 120,
              code: "",
              expiry: true,
              isLoading: false,
              isLoggedIn: true
            });
            setTimeout(() => this.setState({ isShowError: false }), 2000);
          }
          else {
            console.log("bayoo", response.data)
            this.setState({
              // alert_error_color: "alert alert-danger",
              errorShow: true,
              error: response.data.message,
              submitted: true,
              isLoading: false
            }, function () {
              console.log('data', response.data.message)
            });
          }
        }).catch(error => {
          console.log('bayoo', error.response)
          this.setState({
            // alert_error_color: "alert alert-danger",
            errorShow: true,
            error: error.response === undefined ? "Check your internet" : error.response.data.message,
            submitted: true,
            isLoading: false
          });
          this.setState({ password: "" });
        })
      } else {
        var str = this.state.msisdn;
        var res = str.substring(1);

        let formData2 = {
          "msisdn": res,
          "code": mreference,
          "expired": ((new Date(daily).getTime()) / 1000)
        }
        axios.post(baseURL + `otpotherno`, formData2
        ).then((response) => {
          console.log("token", response.data);
          if (response.data.status) {
            localStorage.setItem("phonemsisdn_msisdn", this.state.msisdn)
            localStorage.setItem("mreference", mreference)
            this.setState({
              statusMessage: response.data.message,
              isShowError: true,
              errorShow: false,
              submitted: true,
              verify: true,
              time: {},
              seconds: 120,
              code: "",
              expiry: true,
              isLoading: false,
              isLoggedIn: true
            });
            setTimeout(() => this.setState({ isShowError: false }), 2000);
          }
          else {
            console.log("bayoo", response.data)
            this.setState({
              // alert_error_color: "alert alert-danger",
              errorShow: true,
              error: response.data.message,
              submitted: true,
              isLoading: false
            }, function () {
              console.log('data', response.data.message)
            });
          }
        }).catch(error => {
          console.log('bayoo', error.response)
          this.setState({
            // alert_error_color: "alert alert-danger",
            errorShow: true,
            error: error.response === undefined ? "Check your internet" : error.response.data.message,
            submitted: true,
            isLoading: false
          });
          this.setState({ password: "" });
        })
      }
    }
  };

  onVeried = e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    var daily = moment(new Date(new Date())).add(24, 'hours');

    let formData = {
      "msisdn": localStorage.getItem("phonemsisdn_msisdn"),
      "client_id": localStorage.getItem("user_id_user"),
      "login_expiry": ((new Date(daily).getTime()) / 1000),
      "code": this.state.code
    }


    // alert(JSON.stringify(formData))
    axios.post(baseURL + `verifycode`, formData
    ).then((response) => {

      //alert(JSON.stringify(response.data))
      if (response.data.status) {

        localStorage.setItem("token", TOK)

        this.setState({
          statusMessage: response.data.message,
          isShowError: true,
          errorShow: false,
          submitted: true,
          isLoading: false,
          isLoggedIn: true
        });
        setTimeout(() => {
          this.setState({ isShowError: false, isLoading: false })
          window.location.href = "/dashboard";
        }, 1000);
      }
      else {
        console.log("bayoo", response.data)
        this.setState({
          // alert_error_color: "alert alert-danger",
          errorShow: true,
          error: response.data.message,
          submitted: true,
          isLoading: false
        }, function () {
          console.log('data', response.data.message)
        });
      }
    }).catch(error => {
      console.log('bayoo', error.response)
      this.setState({
        // alert_error_color: "alert alert-danger",
        errorShow: true,
        error: error.response === undefined ? "Check your internet" : error.response.data.message,
        submitted: true,
        isLoading: false
      });
      this.setState({ password: "" });
    })

  };


  onChangeMeans(value) {
    console.log(value);
    if (value == 1) {
      this.setState({
        phone_number: "1",
        email_send: "0"
      })
    } else {
      this.setState({
        phone_number: "0",
        email_send: "1"

      })
    }
    // alert(value)
  }


  render() {
    const { submitted, showPassword, phone_number, errorShow } = this.state;

    return (
      <div className="account account">

        <div className="account__wrapper">

          <div className="account__card" >


            {submitted ? (
              <div>
                {this.state.isShowError ? (
                  <Alert color="success" className="alert--colored" >
                    <span>
                      {this.state.statusMessage}
                    </span>
                  </Alert>
                ) : null}
                {errorShow && (

                  <div>
                    <div
                      color="red"
                      style={{ fontSize: "13px", color: "red" }}>
                      {this.state.error}
                    </div>
                    {this.state.errorShow ? (
                      <div
                      >
                        <p
                          style={{ textAlign: "left", marginLeft: "20px" }}
                        >
                          {

                          }{" "}
                          {this.state.isLoggedIn ? (
                            <span>
                              <Dot>.</Dot>
                              <Dot>.</Dot>
                              <Dot>.</Dot>
                            </span>
                          ) : null}
                        </p>

                      </div>
                    ) : null}
                  </div>
                )}

              </div>
            ) : null}
            {this.state.expiry == true ?
              this.state.time.s === 0 ?
                <h4 style={{ color: "red" }}>Code has already Expired. <i onClick={this.changetologin}>Resend?</i> </h4>
                :
                <h4>Expire in {this.state.time.m} Minute & {this.state.time.s} Seconds</h4>
              : null}

            <Link to="/">
              <img src={logo} alt="" />

            </Link>

            <br />
            <br />
            {this.state.verify == false ?
              <div>


                <div className="col-md-12">
                  <label className="form-label">Send OTP Via</label>
                  <div className="col-md-12" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>
                    <RadioGroup onChange={this.onChangeMeans} value={this.state.phone_number} horizontal>
                      <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                        Phone
                      </RadioButton>
                      <RadioButton value="2" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                        Email
                      </RadioButton>
                    </RadioGroup>
                  </div>
                </div>
                <br />
                {phone_number === "1" ?
                  <form className="form" onSubmit={this.onSubmit}>
                    <div className="form__form-group">

                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <PhoneIcon />
                        </div>
                        <Form.Control
                          autoFocus
                          type="text"
                          name="msisdn"
                          style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                          placeholder="Your Number?"
                          className="input-without-border-radius"
                          value={this.state.msisdn}
                          readOnly

                          onChange={this.handleChangePhone}
                        />

                      </div>
                      <div className="col-6 offset-3 form__form-group">
                        <br></br><br />
                        {
                          <Button className="account__btn" style={{ color: "" }} type='submit' outline color="primary"> {
                            this.state.isLoading ? "Please wait..." : "Send OTP"
                          }</Button>
                        }
                      </div>
                    </div>
                  </form>
                  :
                  <form className="form" onSubmit={this.onSubmitEmail}>
                    <div className="form__form-group">

                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <CardIcon />
                        </div>
                        <Form.Control
                          autoFocus
                          type="text"
                          name="email_address"
                          style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                          placeholder="Your Email Address?"
                          className="input-without-border-radius"
                          value={this.state.email_address}
                          readOnly
                          onChange={this.handleChangeEmail}
                        />

                      </div>
                      <div className="col-6 offset-3 form__form-group">
                        <br></br><br />
                        {
                          <Button className="account__btn" style={{ color: "" }} type='submit' outline color="primary"> {
                            this.state.isLoading ? "Please wait..." : "Send OTP"
                          }
                          </Button>

                        }

                      </div>
                    </div>
                  </form>
                }


                <p>Go back to login? <a href="log_in">Click here</a></p>

              </div>

              :
              <div>
                <form className="form login-form" onSubmit={this.onVeried}>
                  <div className="form__form-group">

                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <KeyVariantIcon />
                      </div>
                      <Form.Control
                        value={this.state.code}
                        placeholder="Enter verification code"
                        name="code"
                        style={{ color: "black", borderColor: "hsl(0,0%,80%)" }}
                        type="text"
                        className="input-without-border-radius"
                        onChange={this.handleChangePassword}
                      />

                    </div>
                  </div>
                  <div className="col-6 offset-3 form__form-group">
                    <br></br><br />
                    {
                      <Button className="account__btn" style={{ color: "" }} type='submit' outline color="primary"> {
                        this.state.isLoading ? "Please wait..." : "Verify"
                      }</Button>
                    }

                    {this.state.expiry == true ?
                      this.state.time.s === 0 ?
                        <Button className="account__btn" style={{ color: "" }} onClick={this.changetologin} type='submit' outline color="primary">
                          Resend OTP
                        </Button>
                        :
                        null
                      : null}

                  </div>
                </form>

              </div>


            }


          </div>
        </div >
      </div >
    );
  }
}

export default OTP;
