import React, { Component } from 'react';
import styles from './Invoice.module.scss';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Form } from 'react-bootstrap';
import LineItems from './LineItems.jsx';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { BRANCH_ID, CONFIG, CURRENCY, PROFILE_URL, TOKEN, USER_ID, ROLE, USER_ID_ACC } from '../../_helpers/exports';
import { baseURL, baseURL_2 } from '../../_helpers';
import Alert from '../../shared/components/Alert';
import { Link, Navigate } from 'react-router-dom';
import $ from 'jquery';
import CreatableSelect from 'react-select/creatable';
import * as moment from 'moment';
import { updateEstimatePDF } from './pdfUpdateestimate';
import { Estimate_View } from './estimate_view';
import { RadioGroup, ReversedRadioButton, RadioButton } from 'react-radio-buttons'
import { uuid } from 'uuidv4';

window.$ = window.jQuery = require('jquery');

require('../../_assets/jss/sms_counter.js');

class ESTIMATE_EDIT extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this._onButtonClick = this._onButtonClick.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);


  }


  locale = 'en-US';
  currency = CURRENCY;

  state = {
    taxRate: 0.00,
    invoice_no: 'IN-2399399',
    loaded: true,
    invoice_date: new Date(),
    due_date: new Date(),
    hideComponent: false,
    showComponent: false,
    editedInvoice: {},
    terms3: [
      {
        label: '1-15 days',
        value: '1-15 days',
      },
      {
        label: '15-30 days',
        value: '15-30 days',
      },
      {
        label: '30-45 days',
        value: '30-45 days',
      },
      {
        label: 'Above 45 days',
        value: 'Above 45 days',
      },
    ],
    returnHome: false,
    lineItems: [
      {
        id: 'initial', // react-beautiful-dnd unique key
        name: '',
        description: '',
        quantity: 2,
        discount_percentage: 0,
        tax_percentage: 0,
        price: 10.00,
      }
    ],
  };
  async componentDidMount() {

    // alert(JSON.stringify(this.props))

    this.getData();
    $('#message').countSms('#sms-counter');
    $('#messages').countSms('#sms-counters');

    if (PROFILE_URL == "null") {
      localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
    } else {
      localStorage.setItem("image_urls", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC")
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
      var img = new Image;
      img.crossOrigin = "*"
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);// at this point the image is drawn to the canvas  
        canvas.toDataURL('image/jpeg', 1.0)
        var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
        localStorage.setItem("image_urls", fullQuality)
      };
      img.src = `${PROFILE_URL}?canvas=true`;
    }

  }

  _onButtonClick() {
    this.setState({
      returnHome: true,
      hideComponent: true,
    });
  }
  handleLineItemChange = elementIndex => event => {

    console.log(this.state.lineItems);

    let lineItems = this.state.lineItems.map((item, i) => {
      if (elementIndex !== i) return item;
      // alert(elementIndex);
      return { ...item, [event.target.name]: event.target.value };
    });
    this.setState({ lineItems });
  };
  Customer() {
    return (
      this.state.customerResponse &&
      (this.state.customerResponse.length > 0 || this.state.customerResponse.length === 0) &&
      this.state.customerResponse.map((countyItem, i) => ({
        label: countyItem.customer_display_name + " (" + countyItem.email + ")",
        customer_email: countyItem.email,
        customer_nameinvoice: countyItem.customer_display_name,
        value: countyItem.id,
      }))
    );
  }

  handleAddLineItem = event => {

    let length = this.state.lineItems.length - 1;
    this.setState({
      addnew: true
    })

    if (length > -1) {
      if (this.state.lineItems[length].name === '') {
        alert("Name of the item cannot be empty")
        this.setState({
          addnew: false,
          // use optimistic uuid for drag drop; in a production app this could be a database id
          lineItems: this.state.lineItems.concat(),
        });
      } else {
        this.setState({
          addnew: true,
        })
        let axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Authorization": TOKEN
          }
        };
        var encodeURISafe = require('encodeuri-safe');
        // alert()
        let url = `${baseURL}item?branch_id=${BRANCH_ID}&&client_id=${USER_ID}&filter_value=${encodeURISafe.encodeURIComponent(this.state.lineItems[length].name)}`

        // let url = baseURL + `item?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&filter_value=` + this.state.lineItems[length].name
        axios.all([
          axios.get(url, axiosConfig),
        ]).then(axios.spread((itemResponse) => {
          this.setState({
            item: itemResponse.data.data,
            isLoading: false,

          },
            function () {
              var data = [];
              //filter json object
              let filter_items_original = this.state.lineItems_update.filter(item => item.name === this.state.item[0].item_name)
              let accumulative_original = filter_items_original.reduce(
                (prev, cur) =>
                  (Number(prev) + Number(cur.quantity)),
                0)
              // new state item
              let filter_items = this.state.lineItems.filter(item => item.name === this.state.item[0].item_name)
              let accumulative = filter_items.reduce(
                (prev, cur) =>
                  (Number(prev) + Number(cur.quantity)),
                0)

              if ((this.state.item[0].quantity - (accumulative - accumulative_original)) < 0) {
                this.setState({
                  addnew: false,
                })
                this.handleRemove(length)
                alert(`You current stock for item ${this.state.item[0].item_name} is not enough. Kindly restock`)
              } else {
                this.setState({
                  addnew: false,
                  // use optimistic uuid for drag drop; in a production app this could be a database id
                  lineItems: this.state.lineItems.concat([
                    {
                      id: 0, // react-beautiful-dnd unique key
                      name: '',
                      description: '',
                      reference: '',
                      quantity: 1,
                      client_id: USER_ID,
                      item_id: uuid(),
                      discount_percentage: 0,
                      tax_percentage: 0,
                      price: 0.00,
                    },
                  ]),
                })
              }
            })
        })).catch(error => {
          console.log('bayoo', error.response)
        })
      }
    } else {
      this.setState({
        addnew: false,
        // use optimistic uuid for drag drop; in a production app this could be a database id
        lineItems: this.state.lineItems.concat([
          {
            id: uuid(), // react-beautiful-dnd unique key
            name: '',
            description: '',
            reference: '',
            quantity: 1,
            discount_percentage: 0,
            tax_percentage: 0,
            price: 0.00,
          },
        ]),
      })

    }
  };




  handleRemoveLineItem = elementIndex => event => {
    // alert("You cant delete. Is disabled")
    this.setState({
      lineItems: this.state.lineItems.filter((item, i) => {
        return elementIndex !== i;
      }),
    });
    // alert(this.state.lineItems.filter((item, i) => { return elementIndex === i })[0].quantity)
    // updating invoice items ahd quantity for the items'
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: TOKEN,
      },
    };
    let url =
      baseURL +`invoiceitemdelete?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID +
      `&&filter_value=${this.state.lineItems.filter((item, i) => { return elementIndex === i })[0].id}&&item_name=` +
      this.state.lineItems.filter((item, i) => { return elementIndex === i })[0].name.toString()
      + "&&quantity=" + this.state.lineItems.filter((item, i) => { return elementIndex === i })[0].quantity.toString();
    axios.delete(url, axiosConfig).then(itemResponse => {
    });
  };

  handleReorderLineItems = newLineItems => {
    this.setState({
      lineItems: newLineItems,
    });
  }

  handleChangeInvoiceMessage = event => {
    this.setState({ message_invoice: event.target.value }, function () { });
    //alert(event.target.value)
  };

  handleChangeStatementMessage = event => {
    this.setState({ statement_message: event.target.value }, function () { });
    //alert(event.target.value)
  };

  handleFocusSelect = event => {
    event.target.select();
  };

  handlePayButtonClick = () => {
    alert('Not implemented');
  };


  formatCurrency = amount => {
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: "ABS",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount).replaceAll('ABS', CURRENCY);
  };
  calcTaxAmount = c => {
    return c * (localStorage.getItem('valuestax') / 100);
  };

  calcLineItemsTotal = () => {

    if (this.state.showtax == true) {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + cur.quantity * cur.price,
        0
      );
    } else {

      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.quantity * cur.price) / (1 + (cur.tax_percentage / 100))),
        0
      );
    }


  };

  calcLineItemDiscounts = () => {
    return this.state.lineItems.reduce(
      (prev, cur) => prev + cur.discount_percentage / 100 * (cur.quantity * cur.price),
      0
    );
  };
  onChangeOption(value) {
    console.log(value);
    if (value == 1) {
      this.setState({
        showtax: true,
        tax_inclusive: '0',
        tax_exclusive: 1,
      })
    } else {
      this.setState({
        showtax: false,
        tax_inclusive: '1',
        tax_exclusive: 0

      })
    }
    // alert(value)
  }

  calcTaxTotal = () => {
    if (this.state.showtax == true) {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.tax_percentage / 100) * (cur.quantity * cur.price)),
        0
      );
    } else {
      return this.state.lineItems.reduce(
        (prev, cur) => prev + ((cur.quantity * cur.price) - ((cur.quantity * cur.price) / (1 + (cur.tax_percentage / 100)))),
        0
      );
    }
  };

  calcGrandTotal = () => {
    return (
      this.calcLineItemsTotal() +
      this.calcTaxTotal() -
      this.calcLineItemDiscounts()
    );
  };

  onSelectChangesShort = value => {
    if (value != null) {

      this.setState(
        {
          ...this.state.editedInvoice,
          customer_id: value.value.toString(),

          customer_name: value.label.toString(),
          customer_nameinvoice2: value.customer_nameinvoice.toString(),
          customer_email: value.customer_email != null ? value.customer_email.toString() : '',
        });
    }
  };

  handleChange = e => {
    this.setState({
      ...this.state.editedInvoice,
      [e.target.name]: e.target.value,
    });

    console.log('updates', this.state.editedInvoice);
  };


  handleChangeType = value => {

    if (value != null) {
      this.setState(
        {
          terms: value.value.toString()
        });

      if (value.value.toString() === "1-15 days") {
        this.setState({
          due_date: moment(new Date(this.state.invoice_date)).add(15, 'days').toDate()
        })
      }
      else if (value.value.toString() === "15-30 days") {
        this.setState({
          due_date: moment(new Date(this.state.invoice_date)).add(30, 'days').toDate()
        })
      }

      else if (value.value.toString() === "30-45 days") {
        this.setState({
          due_date: moment(new Date(this.state.invoice_date)).add(30, 'days').toDate()
        })
      }
      else if (value.value.toString() === "Above 45 days") {
        this.setState({
          due_date: moment(new Date(this.state.invoice_date)).add(40, 'days').toDate()
        })
      }
      else {
        this.setState({
          due_date: new Date()
        })
      }
    }
    else {
      this.setState(
        {
          terms: "",
        });
    }
  };
  getData = (queryString = "") => {
    this.setState({
      isLoading: false,
      isPageLoad: true
    })
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Authorization": TOKEN
      }
    };
    let endDate = moment(new Date()).format('YYYY-MM-DD')

    let url = baseURL + `estimateupdate?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=${USER_ID}&&filter_value=${localStorage.getItem("Invoice_No_Item_EStimate")}`

    let url2 = baseURL + `invoiceitem?filter_value=` + localStorage.getItem("Invoice_No_Item") + `&client_id=` + USER_ID


    // alert(url)
    axios.all([
      axios.get(url, axiosConfig),
      axios.get(url2, axiosConfig),
    ]).then(axios.spread((invoiceResponse, invoiceResponse2) => {
      // alert(JSON.stringify(invoiceResponse2))

      this.setState({
        invoice: invoiceResponse.data.data,
        lineItems: invoiceResponse2.data.data,
        lineItems_update: invoiceResponse2.data.data,
      },
        function () {
          this.setState({
            returnHome: false,
            invoice_no: this.state.invoice[0].estimate_no,
            invoice_date: this.state.invoice[0].invoice_date,
            // terms: [{ label: this.state.invoice[0].terms, value: this.state.invoice[0].terms }],
            terms: this.state.invoice[0].terms,
            due_date: this.state.invoice[0].due_date,
            message_invoice: this.state.invoice[0].message_invoice,
            statement_message: this.state.invoice[0].statement_invoice,
            customer_email: this.state.invoice[0].email,
            amount: this.state.invoice[0].due_amount,
            due_amount: this.state.invoice[0].due_amount,
            tax_amount: this.state.invoice[0].tax_amount,
            tax_exclusive: this.state.invoice[0].tax_exclusive.toString(),

            due_period: this.state.invoice[0].due_period,
            status: this.state.invoice[0].status,
            discount_amount: this.state.invoice[0].discount_amount,
            currency: this.state.invoice[0].currency_type,

            customer_name: [{ label: this.state.invoice[0].customer_display_name, value: this.state.invoice[0].customer_display_name }],
            customer_nameUpdate: this.state.invoice[0].customer_display_name,
            tax: this.state.invoice[0].tax_amount,
            subtoal: this.state.invoice[0].sub_total,
            total: this.state.invoice[0].due_amount,
            loaded: false,
            isPageLoad: false
          }, function () {
            if (this.state.tax_exclusive == 1) {

              this.setState({
                showtax: true
              })
            } else {
              this.setState({
                showtax: false
              })
            }
          })
        })
    })).catch(error => {
      console.log('bayoo', error.response)
    })
    // alert(JSON.stringify(this.state.lineItems))

  }
  formatCurrency1 = amount => {
    return new Intl.NumberFormat(this.locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  async onSubmit(e) {

    let footer;
    const d = await axios.get(baseURL + `footer_adwwws?acc_id=${USER_ID_ACC}&&role=${ROLE}&&created_by=` + USER_ID, CONFIG).then((response) => {
      if (response.data.data.length > 0) {
        footer = { footer: response.data.data[0].footer_notes };
      } else {
        footer = { footer: "" };
      }
    }).catch(error => {
      footer = { footer: "" };
    })

    // eslint-disable-next-line
    this.state.editedInvoice = {
      invoice_no: "Q" + this.state.invoice_no,
      invoice_date: this.state.invoice_date,
      terms: this.state.terms,
      due_date: this.state.due_date,
      message_invoice: this.state.message_invoice,
      statement_invoice: this.state.statement_message,
      customer_name: this.state.customer_nameUpdate,
      tax_exclusive: this.state.tax_exclusive,
      approved: e,
      customer_email: this.state.invoice[0].email,
      amount1: this.formatCurrency1(this.calcGrandTotal()),
      amount: this.formatCurrency(this.calcGrandTotal()),
      due_amount: this.formatCurrency(this.calcGrandTotal()),
      tax_amount: this.formatCurrency(this.calcTaxTotal()),
      discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
      lineItems: this.state.lineItems,
      currency: CURRENCY,
      tax: this.formatCurrency(this.calcTaxTotal()),
      subtoal: this.formatCurrency(this.calcLineItemsTotal()),
      total: this.formatCurrency(this.calcGrandTotal()),
    };


    // alert(JSON.stringify(this.state.editedInvoice) )

    let formData = {
      invoice_no: this.state.invoice_no,
      invoice_date: this.state.invoice_date,
      terms: this.state.terms,
      due_date: this.state.due_date,
      approved: e,
      message_invoice: this.state.message_invoice,
      statement_invoice: this.state.statement_message,
      customer_name: this.state.customer_nameUpdate,
      customer_email: this.state.invoice[0].email,
      tax_exclusive: this.state.tax_exclusive,
      sub_total: this.formatCurrency(this.calcLineItemsTotal()),
      amount: this.formatCurrency(this.calcGrandTotal()),
      due_amount: this.formatCurrency(this.calcGrandTotal()),
      tax_amount: this.formatCurrency(this.calcTaxTotal()),
      discount_amount: this.formatCurrency(this.calcLineItemDiscounts()),
      created_by: USER_ID,
      customer_id: this.state.invoice[0].customer_id,
    };

    console.log("DATA", JSON.stringify(formData))
    this.setState({ isLoading: true });
    axios
      .put(baseURL + `estimate`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: TOKEN,
        },
      })
      .then(response => {
        if (response.data.status) {
          this.setState({
            statusMessage: response.data.message,
            showError: false,
            isShowError: true,
          });

          let datas = this.state.lineItems;
          let data = [];
          for (let i = 0; i < datas.length; i++) {
            let innvoiceNo = { invoice_no: datas[0].invoice_no };
            // let item_id = { item_id: datas[i].id }

            // let mclient = { client_id: USER_ID }
            data.push(Object.assign(innvoiceNo, datas[i]));
            this.setState({
              data: data,
            });
            // console.log("DATA", datas[i])
          }

          // alert(JSON.stringify(data))

          // console.log("brian", data)

          axios
            .post(baseURL_2 + 'updateinvoiceitems.php', this.state.lineItems_update)
            .then(response => {
              axios
                .post(baseURL_2 + 'invoiceupdate.php', data)
                .then(response => {
                  // alert(JSON.stringify(data))
                  console.log("bayo", response)

                  if (e === 1) {
                    updateEstimatePDF(Object.assign(footer, this.state.editedInvoice));
                  } else { }


                  this.setState({
                    statusMessage: 'Quotation has been updated successfully',
                    isShowError: true,
                    isLoading: false,
                    showError: false,
                  });
                  window.setTimeout(() => {
                    this.setState({ isShowError: false, returnHome: true });
                    this._onButtonClick();
                  }, 2000);
                  window.scrollTo(0, 0);
                })
                .catch(error => {
                  console.log("Vccc", error)
                  window.scrollTo(0, 0);
                  // console.error('Error during service worker registration:', error);
                });
            })
            .catch(error => {
              console.log("Vccc", error)
              window.scrollTo(0, 0);
              // console.error('Error during service worker registration:', error);
            });



        } else {
          this.setState({
            statusMessage: response.data.message,
            showError: true,
            isShowError: false,
            isLoading: false,
          });
          window.scrollTo(0, 0);
        }
      })
      .catch(error => {
        console.log('bayoo', error.response);
        this.setState({
          statusMessage: error.response.data.message,
          showError: true,
          isShowError: false,
          isLoading: false,
        });
        window.scrollTo(0, 0);
      });
  }

  loadOptions = inputValue => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: TOKEN,
      },
    };
    this.setState({
      isLoading: true,
    });
    let url =
      baseURL +
      `customer?branch_id=${BRANCH_ID}&&client_id=` +
      USER_ID +
      `&&filter_value=${inputValue}`;
    axios.get(url, axiosConfig).then(itemResponse => {
      this.setState(
        {
          item: itemResponse.data.data,
        },
        function () {
          this.setState({
            isLoading: false,
            bayo: this.state.item &&
              (this.state.item.length > 0 || this.state.item.length === 0) &&
              this.state.item.map((countyItem, i) => ({
                label: countyItem.customer_display_name,
                customer_email: countyItem.email,
                value: countyItem.id,
              })),
          });
        }
      );
    });
  };

  handleCreate = inputValue => {
    const newValue = { value: inputValue.toLowerCase(), label: inputValue };
    this.setState({ newValue })
    // setOptions([...options, newValue]);
    // setValue(newValue);

  };

  handleAddLineSubmit = event => {

    // alert(event)

    let length = this.state.lineItems.length - 1;
    this.setState({
      addnew: true,
      isLoading: true
    })
    if (length > -1) {
      if (this.state.lineItems[length].name === '') {
        alert("Name of the item cannot be empty")
        this.setState({
          addnew: false,
          isLoading: false,

          // use optimistic uuid for drag drop; in a production app this could be a database id
          lineItems: this.state.lineItems.concat(),
        });
      } else {
        let axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Authorization": TOKEN
          }
        };
        var encodeURISafe = require('encodeuri-safe');
        // alert()
        let url = `${baseURL}item?branch_id=${BRANCH_ID}&&client_id=${USER_ID}&filter_value=${encodeURISafe.encodeURIComponent(this.state.lineItems[length].name)}`

        // let url = baseURL + `item?acc_id=${USER_ID_ACC}&&role=${ROLE}&&client_id=` + USER_ID + `&&filter_value=` + this.state.lineItems[length].name
        axios.all([
          axios.get(url, axiosConfig),
        ]).then(axios.spread((itemResponse) => {
          this.setState({
            item: itemResponse.data.data,
          },
            function () {
              var data = [];
              let filter_items_original = this.state.lineItems_update.filter(item => item.name === this.state.item[0].item_name)
              let accumulative_original = filter_items_original.reduce(
                (prev, cur) =>
                  (Number(prev) + Number(cur.quantity)),
                0)
              // new state item
              let filter_items = this.state.lineItems.filter(item => item.name === this.state.item[0].item_name)
              let accumulative = filter_items.reduce(
                (prev, cur) =>
                  (Number(prev) + Number(cur.quantity)),
                0)

              if ((this.state.item[0].quantity - (accumulative - accumulative_original)) < 0) {
                this.setState({
                  addnew: false,
                  isLoading: false,

                })
                this.handleRemove(length)
                alert(`You current stock for item ${this.state.item[0].item_name} is not enough. Kindly restock`)
              } else {
                this.setState({
                  addnew: false,

                })
                this.onSubmit(event)
              }
            })
        })).catch(error => {
          console.log('bayoo', error.response)
        })
      }
    } else {
      this.setState({
        addnew: false,
        // use optimistic uuid for drag drop; in a production app this could be a database id
        lineItems: this.state.lineItems.concat([
          {
            id: uuid(), // react-beautiful-dnd unique key
            name: '',
            description: '',
            reference: '',
            quantity: 0,
            discount_percentage: 0,
            tax_percentage: 0,
            price: 0.00,

          },
        ]),
      })

    }
  };



  render = () => {
    // alert(JSON.stringify(this.state.editedInvoice))

    // console.log('ITEMS',);
    const ref = React.createRef();
    const { showComponent, returnHome, hideComponent } = this.state;

    return (
      <div style={{ marginTop: "-20px" }} >
        {returnHome === true ? <Navigate to='/estimates' /> : null}
        {!hideComponent && (
          <div>
            {/* <Loader loaded={this.state.loaded}> */}

            <div ref={ref}>

              <Card>
                {this.state.isPageLoad ? "Loading..." :

                  <CardBody>
                    <div className="row">
                      <div className="col-md-6">
                        <h4><b>Edit Quotation</b></h4>
                      </div>
                      <div className="col-md-6">
                        <Link to="/estimates">
                          <Button className="pull-right btn-sm" onClick={this._onButtonClick}
                            color="primary" outline><i className="fa  fa-arrow-left"></i> Quotations
                          </Button>
                        </Link>

                      </div>
                    </div>

                    {this.state.showError
                      ? <div>

                        <Alert color="danger" className="alert--colored" icon>
                          <p>
                            <span className="bold-text">
                              {this.state.statusMessage}
                            </span>
                          </p>
                        </Alert>

                      </div>
                      : null}<br />

                    {this.state.isShowError
                      ? <div>

                        <Alert color="success" className="alert--colored" icon>
                          <p>
                            <span className="bold-text">
                              {this.state.statusMessage}
                            </span>
                          </p>
                        </Alert>

                      </div>
                      : null}<br />
                    <form className="form" onSubmit={this.handleAddLineSubmit}>

                      <br /><br />
                      <div className="row col-10 offset-1">

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-2">
                                <label className="form-label">Customer Name</label>
                              </div>
                              <div className="col-md-10">
                                <Link className='Links' to='/customer-create'>Create New Customer ?</Link>

                                <Select
                                  isClearable
                                  options={this.Customer()}
                                  onInputChange={this.loadOptions}
                                  placeholder="Search Customer"
                                  autosize={true}
                                  onChange={this.onSelectChangesShort}
                                  isLoading={this.state.isLoading}
                                  value={this.state.customer_name}
                                  // noOptionsMessage={() => 'nothing found'}
                                  // loadingMessage={() => 'searching...'}   //minor type-O here
                                  menuPortalTarget={document.body}
                                  name="namffe"
                                /><br />
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="col-md-6">
                          <br />
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-2">
                                <label className="form-label">Quotation No.</label>
                              </div>
                              <div className="col-md-10">
                                <input
                                  id="input"
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  name="invoice_no"
                                  value={this.state.invoice_no}
                                  placeholder="Invoice No"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <br />
                          <Form.Group className="Focus-line">
                            <div className="row">
                              <div className="col-md-2">
                                <Form.Label>Quotation date</Form.Label>
                              </div>
                              <div className="col-md-10 customDatePickerWidth">
                                <DatePicker
                                  // selected={this.state.invoice_date}
                                  label="DatePicker Label"
                                  dateFormat="DD MMM yyyy"
                                  required
                                  // minDate={moment().toDate()}
                                  value={moment(this.state.invoice_date).format("DD MMM yyyy")}
                                  className="form-control"
                                  id="input"
                                  onChange={value =>
                                    this.setState({ invoice_date: value })}
                                />

                              </div>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <br />
                          <Form.Group className="Focus-line">
                            <div className="col-md-2">
                              <Form.Label>Validity Period</Form.Label>
                            </div>
                            <div className="col-md-10">

                              <CreatableSelect
                                isClearable
                                options={this.state.terms3}
                                // onInputChange={this.loadOptions}
                                placeholder="Select validity Period"
                                autosize={true}
                                onChange={this.handleChangeType}
                                isLoading={this.state.isLoading}
                                // noOptionsMessage={() => 'nothing found'}
                                // loadingMessage={() => 'searching...'}   //minor type-O here

                                menuPortalTarget={document.body}
                                name="namffe"
                              /><br />
                            </div>

                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <br />
                          <Form.Group className="Focus-line">
                            <div className="row">
                              <div className="col-md-2">
                                <Form.Label>Due date</Form.Label>
                              </div>
                              <div className="col-md-10 customDatePickerWidth">
                                <DatePicker
                                  // selected={this.state.due_date}
                                  label="DatePicker Label"
                                  dateFormat="DD MMM yyyy"
                                  minDate={moment().toDate()}
                                  value={moment(this.state.due_date).format("DD- MMM yyyy")}
                                  className="form-control"
                                  id="input"
                                  onChange={value =>
                                    this.setState({ due_date: value })}
                                />

                              </div>
                            </div>
                          </Form.Group>

                        </div>
                      </div>

                      <div className="col-md-12">

                        <div className="col-md-12">
                          <br />
                          <div className="col-md-3" style={{ padding: 10, boxSizing: 'content-box', fontFamily: "bold" }}>

                            {/* <RadioGroup onChange={this.onChangeOption} value={this.state.tax_exclusive} horizontal>
                              <RadioButton value="1" rootColor={"skyblue"} pointColor={"blue"} >
                                Tax Exclusive
                              </RadioButton>
                              <RadioButton value="0" iconSize={10} rootColor={"skyblue"} pointColor={"blue"}>
                                Tax Inclusive
                              </RadioButton>

                            </RadioGroup> */}
                          </div>
                          <br />
                          <LineItems
                            items={this.state.lineItems}
                            currencyFormatter={this.formatCurrency}
                            addHandler={this.handleAddLineItem}
                            showtax={this.state.showtax}
                            changeHandler={this.handleLineItemChange}
                            focusHandler={this.handleFocusSelect}
                            deleteHandler={this.handleRemoveLineItem}
                            reorderHandler={this.handleReorderLineItems}
                          />

                          <div className="col-md-12">

                            <div className={styles.totalContainer}>
                              {/* <form>
                            <div className={styles.valueTable}>
                              <div className={styles.row}>
                                <div className={styles.label}>Tax Rate (%)</div>
                                <div className={styles.value}>
                                  <input
                                    name="taxRate"
                                    type="text"
                                    id="#js-testInputs"
                                    value={localStorage.getItem('valuestax')}
                                    onChange={this.handleInvoiceChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </form> */}
                              <form></form>
                              <form>
                                <div className={styles.valueTable}>
                                  <div className={styles.row}>
                                    <div className={styles.label}>Subtotal</div>
                                    <div
                                      className={`${styles.value} ${styles.currency}`}
                                    >
                                      {this.formatCurrency(
                                        this.calcLineItemsTotal()
                                      )}
                                    </div>
                                  </div>
                                  <div className={styles.row}>
                                    <div className={styles.label}>Discount</div>
                                    <div
                                      className={`${styles.value} ${styles.currency}`}
                                    >
                                      {this.formatCurrency(
                                        this.calcLineItemDiscounts()
                                      )}
                                    </div>
                                  </div>
                                  <div className={styles.row}>
                                    <div className={styles.label}>
                                      Tax
                                    </div>
                                    <div
                                      className={`${styles.value} ${styles.currency}`}
                                    >
                                      {this.formatCurrency(this.calcTaxTotal())}
                                    </div>
                                  </div>
                                  <div className={styles.row}>
                                    <div className={styles.label}>Total Due</div>
                                    <div
                                      className={`${styles.value} ${styles.currency}`}
                                    >
                                      {this.formatCurrency(this.calcGrandTotal())}
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            {/* 
                  <div className={styles.pay}>
                    <button className={styles.payNow} onClick={this.handlePayButtonClick}>Pay Now</button>
                  </div> */}
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 offset-2">
                        <br />
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-2">

                              <label className="form-label">
                                Message on Estimate
                              </label>
                            </div>
                            <div className="col-md-10">
                              <textarea
                                name="message_invoice"

                                value={this.state.message_invoice}
                                style={{
                                  paddingTop: '20px',
                                  borderRadius: '5px',
                                  borderColor: 'hsl(0,0%,80%)',
                                }}
                                onChange={this.handleChangeInvoiceMessage}
                                className="col-md-12"
                                placeholder="Hint: This will show up on the estimate"
                                id="message"
                                cols="col-md-1"
                                rows="1"
                              />
                            </div>


                          </div>
                        </div>
                        <br />
                      </div>

                      <div className="col-md-8 offset-2">
                        <br />
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-2">

                              <label className="form-label">
                                Message on Statement
                              </label>
                            </div>
                            <div className="col-md-10">
                              <textarea
                                name="statement_message"

                                u value={this.state.statement_message}

                                style={{
                                  paddingTop: '20px',
                                  borderRadius: '5px',
                                  borderColor: 'hsl(0,0%,80%)',
                                }}
                                onChange={this.handleChangeStatementMessage}
                                className="col-md-12"
                                placeholder="Hint: If you send statements to customers, this will show up as their description for this estimate"
                                id="messages"
                                cols="col-md-1"
                                rows="1"
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />

                      <div className="col-md-10 offset-1"><br />
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-2">
                              <Link to="/estimates">
                                <Button className="pull-right btn-sm" outline color="primary" onClick={this._onButtonClick}>
                                  Cancel
                                </Button>
                              </Link>

                            </div>
                            <div className="col-md-4">
                              <Button type="button"
                                onClick={
                                  () => { this.handleAddLineSubmit(2) }
                                } color="primary" outline className="btn-paypal btn-sm pull-right" >
                                {this.state.isLoading ? "Please Wait..." : "Save as Draft"}

                              </Button> &nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="col-md-4">
                              <Button type="button" color="primary" onClick={() => { this.handleAddLineSubmit(1) }} outline className="btn-paypal btn-sm pull-right" >
                                {this.state.isLoading ? "Please Wait..." : "Update and Send"}

                              </Button> &nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                    <br />

                  </CardBody>
                }
              </Card>
            </div>
          </div>
        )
        }
        {
          showComponent && (
            // eslint-disable-next-line
            <Estimate_View />
            // {/* <Link to='estimates' /> */}
          )
        }
      </div >

    );
  };
}

export default ESTIMATE_EDIT;
